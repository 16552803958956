import React, { useContext, useEffect, useState } from 'react';
import './DietAndNutrition.scss';
import { PreferencesContext } from '../../PreferenceContext';
import { useLocation, useNavigate } from 'react-router-dom';
import { GetDietNutritionDetailList } from '../../services/DietAndNutritionService';
import { AppBar, Box, Collapse, Dialog, DialogContent, IconButton, Skeleton, Slide, Stack, Toolbar, Typography } from '@mui/material';
import backIcon from '../../assets/icons/back.svg';
import noData from "../../assets/icons/no-data.svg";
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import KeyboardArrowUpRoundedIcon from '@mui/icons-material/KeyboardArrowUpRounded';
import CustomPopup from '../CustomPopup/CustomPopup';
import { TransitionProps } from 'react-transition-group/Transition';
import CloseIcon from '@mui/icons-material/Close';
import { GetInstruction } from '../../services/InstructionService';
import _ from 'lodash';

let init: any;
const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function DietAndNutrition() {
    const { userContext, updateUserContext } = useContext(PreferencesContext);
    const [loading, setLoading] = useState(false);
    const [dietAndNutritionHeaderData, setDietAndNutritionHeaderData] = useState(init);
    const [dietAndNutritionSubData, setDietAndNutritionSubData] = useState(init);
    const [platformOfOS, setPlatformOfOS] = useState('');
    const [moduleName, setModuleName] = useState();
    const [instructionData, setInstructionData] = useState(init);
    const [showAlert, setAlertMessage] = useState({
        isSuccess: false,
        message: "",
    });
    const [openFeed, setOpenFeed] = useState(false);

    const { detect } = require("detect-browser");
    const browser = detect();

    const location = useLocation();
    const navigate = useNavigate();

    function backClick() {
        navigate("/diet-nutrition");
    }

    const handleClickForPhysicalGrowth = () => {
        navigate("/physical-growth");
    };


    async function fetchInstruction(descriptionCode: any) {
        setLoading(true);
        try {
            let params = new URLSearchParams();
            params.append("code", descriptionCode)
            const result: any = await GetInstruction(params);
            let instructionDetail = result.data.data;
            setInstructionData(instructionDetail);
            setOpenFeed(true);
        } catch (error) {
            if (error) {
                setAlertMessage({
                    isSuccess: true,
                    message: error as string,
                });
            }
        }
        setLoading(false);
    }

    const handleClickOpenFeed = (descriptionCode: any) => {
        if (descriptionCode === "SEE_THE_DETAILS") {
            fetchInstruction(descriptionCode);
            // setOpenFeed(true);
        }
    };

    const gotoPhysicalGrowth = () => {
        navigate("/physical-growth");
    }

    const handleCloseFeed = () => {
        setOpenFeed(false);
    };

    function onHeaderClose(index: any) {
        let newData: any = dietAndNutritionHeaderData;
        let data: any = newData[index];
        data.isExpanded = !data.isExpanded;
        newData[index] = data;
        setDietAndNutritionHeaderData([...newData]);
    }

    async function fetchData(page: any) {
        setLoading(true);
        let deviceOs = browser.os;

        let pageName: any;
        if (page !== undefined && page.includes('-')) {
            pageName = page.replace(/-/g, "_");
        } else {
            pageName = page;
        }
        try {
            let params = new URLSearchParams();
            let curModule = userContext.moduleConfiguration.find(
                (x: any) => x.moduleCode === "DIET_NUTRITION" && x.submodule.find((y: any) => y.submoduleCode === pageName.toUpperCase())
            );
            if (curModule !== null && curModule !== undefined) {
                let curSubmodule = curModule.submodule.find((y: any) => y.submoduleCode === pageName.toUpperCase());
                setModuleName(curSubmodule.submoduleName);
                params.append("submoduleId", curSubmodule.submoduleId)
                params.append("subModuleName", curSubmodule.submoduleCode);
                const result: any = await GetDietNutritionDetailList(params);
                let dietNutritionHeader: any;
                let dietNutritionsubDataDetail: any;
                dietNutritionHeader = result.data.data.headers;
                dietNutritionsubDataDetail = result.data.data.subDetails;
                let newData: any = dietNutritionHeader;
                let data: any = newData[0];
                data.isExpanded = !data.isExpanded;
                newData[0] = data;
                setDietAndNutritionHeaderData([...newData]);

                // setDietAndNutritionHeaderData(dietNutritionHeader);
                setDietAndNutritionSubData(dietNutritionsubDataDetail)
                setPlatformOfOS(deviceOs)
            }
        }
        catch (error) {
            if (error) {
                setAlertMessage({
                    isSuccess: true,
                    message: error as string,
                });
            }
        }

        setLoading(false);
    }
    useEffect(() => {
        if (userContext.memberDetail !== undefined && userContext.memberDetail.familyMemberId > 0) {
            const pageName = window.location.pathname.replace('/', '')
            if (pageName !== '' && pageName !== undefined && pageName !== null) {
                fetchData(pageName)
            }
        }
    }, []);

    return (
        <Box className="diet-nutrition-main-box">
            <Box className="head-box">
                <Box className="back-box">
                    <IconButton
                        aria-label="back"
                        className="back-icon"
                        disableRipple
                        onClick={() => backClick()}>
                        <img src={backIcon} alt="logo" />
                    </IconButton>
                </Box>
                <Box className="title-box">
                    <Typography className="title-text">{moduleName}</Typography>
                </Box>
                <Box className="profile-box">
                    <></>
                </Box>
            </Box>
            <Box className="body-box">
                {loading ?
                    (_.times(2, (i) => (
                        <Stack spacing={1} key={i} sx={{ marginBottom: '15px' }}>
                            <Skeleton animation="wave" variant="rounded" width='auto' height={45} />
                            <Skeleton animation="wave" variant="rounded" width='auto' height={75} />
                        </Stack>
                    )))
                    :
                    (dietAndNutritionHeaderData !== undefined && dietAndNutritionHeaderData.length > 0 ?
                        dietAndNutritionHeaderData.map((row: any, index: any) => (
                            <Box key={index} className="main-container">
                                <Box className="header-container" >
                                    <Box className={row.isExpanded === true ? "header-box" : "header-box header-box-close"}
                                        onClick={() => onHeaderClose(index)}>
                                        <Typography className="header-text">{row.dietModule !== "" ? row.dietModule : moduleName}</Typography>
                                        <IconButton disableRipple>{row.isExpanded === true ? <KeyboardArrowUpRoundedIcon /> : <KeyboardArrowDownRoundedIcon />}</IconButton>
                                    </Box>
                                    <Box className="detail-box">
                                        <Collapse in={row.isExpanded}>
                                            <Box className='info-box'>
                                                {dietAndNutritionSubData !== undefined && dietAndNutritionSubData !== null && dietAndNutritionSubData.length > 0
                                                    && dietAndNutritionSubData.map((subrow: any, i: any) => (
                                                        <React.Fragment key={i}>
                                                            {subrow.dietId === row.dietId ?
                                                                <Box>
                                                                    <Typography className='info-text text-heading'>
                                                                        {subrow.dietModuleHeader}
                                                                    </Typography>
                                                                    {subrow.submodule !== undefined && subrow.submodule !== null && subrow.submodule.length > 0 &&
                                                                        subrow.submodule.map((innerrow: any, ind: any) => (
                                                                            <Box key={ind}>
                                                                                <Typography className='info-text text-heading'
                                                                                    dangerouslySetInnerHTML={{
                                                                                        __html: innerrow.dietmModuleDetail
                                                                                    }}>
                                                                                </Typography>
                                                                                {/* <Typography
                                                                                    className={innerrow.dietmModuleDetail !== "" ? 'inner-text-list text-show-less' : (innerrow.descriptionCode === "SEE_THE_DETAILS" ? 'underline-inner-text' : 'text-show-less')}
                                                                                    onClick={() => handleClickOpenFeed(innerrow.descriptionCode)}>
                                                                                    {innerrow.description}
                                                                                </Typography> */}
                                                                                {innerrow.description !== undefined && innerrow.description !== null && innerrow.description !== '' ?
                                                                                    (innerrow.description.includes('click here') ?
                                                                                        (
                                                                                            <Typography>{innerrow.description.split('click here')[0]}
                                                                                                <span onClick={handleClickForPhysicalGrowth} style={{ color: 'blue', textDecoration: 'underline', cursor: 'pointer' }}>
                                                                                                    click here
                                                                                                </span></Typography>
                                                                                        )
                                                                                        :
                                                                                        (<Typography
                                                                                            className={innerrow.dietmModuleDetail !== "" ? 'inner-text-list text-show-less' : (innerrow.descriptionCode === "SEE_THE_DETAILS" ? 'underline-inner-text' : 'text-show-less')}
                                                                                            onClick={() => handleClickOpenFeed(innerrow.descriptionCode)}>
                                                                                            {innerrow.description}
                                                                                        </Typography>)
                                                                                    ) : <></>
                                                                                }
                                                                            </Box>
                                                                        ))}
                                                                </Box>
                                                                : <></>}
                                                        </React.Fragment>
                                                    ))}
                                            </Box>
                                        </Collapse>
                                    </Box>
                                </Box>
                            </Box>
                        )) :
                        <Box className="no-summary-box">
                            <Box className="upper-box"></Box>
                            <Box className="bottom-box">
                                <Box className="img-box">
                                    <img src={noData} alt="logo" />
                                </Box>
                                <Box>
                                    <Box className="no-data-box">
                                        <Typography className="no-summary-text">
                                            No data available
                                        </Typography>
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                    )
                }
            </Box >
            <Dialog
                fullScreen
                open={openFeed}
                onClose={handleCloseFeed}
                TransitionComponent={Transition}
                className='custom-popup-diet'>
                {instructionData !== undefined && instructionData !== null && instructionData.map((row: any, index: any) => (
                    <React.Fragment key={index}>
                        <AppBar sx={{ position: 'relative' }} color='secondary'>
                            <Toolbar className='custom-popup-header'>
                                <Box className='header-empty-box'></Box>
                                <Box className='header-text-box'>
                                    <Typography variant="h6" component="div">
                                        {row.title}
                                    </Typography>
                                </Box>
                                <Box className='close-box'>
                                    <IconButton
                                        edge="start"
                                        color="inherit"
                                        onClick={handleCloseFeed}
                                        aria-label="close"
                                        className='close-button'>
                                        <CloseIcon />
                                    </IconButton>
                                </Box>
                            </Toolbar>
                        </AppBar>
                        <DialogContent>
                            <CustomPopup descriptionData={row.description} />
                        </DialogContent>
                    </React.Fragment>
                ))}
            </Dialog>
        </Box >
    )
}