import { GET, POST } from "./common/http";
import { BASE_URL } from "./common/const";

export const CreateMaternalDetail = (data: any) =>
    POST(`${BASE_URL}/api/MaternalDetail/Create`, data);

export const UpdateMaternalDetail = (data: any) =>
    POST(`${BASE_URL}/api/MaternalDetail/Update`, data);

export const UpdateParent = (data: any) =>
    POST(`${BASE_URL}/api/FamilyMember/Update`, data);

export const GetIsMaternalDetailExist = (parmas: any) =>
    GET(`${BASE_URL}/api/MaternalDetail/GetIsMaternalDetailExist?` + parmas, null);

export const GetMaternalDetail = (params: any) =>
    GET(`${BASE_URL}/api/MaternalDetail/GetDetail?` + params, null);