import React from 'react';
import './CustomButton.scss';
import LoadingButton from '@mui/lab/LoadingButton';

export default function CustomButton(props: any) {
    return (
        <LoadingButton
            color="primary"
            fullWidth
            variant="contained"
            type="submit"
            sx={{ marginTop: 4, borderRadius: 2, fontSize: '14px' }}
            loading={props.isLoading}
            disabled={props.disabled}
            className={props.class}>
            {props.title}
        </LoadingButton>
    )
}