import React, { useContext, useEffect, useState } from 'react';
import './MaternalDetails.scss';
import { Box, Dialog, DialogActions, DialogContent, DialogContentText, Fade, FormControl, Grid, IconButton, Skeleton, TextField, Typography } from '@mui/material';
import { Form, Formik } from 'formik';
import Select from 'react-select';
import backIcon from '../../assets/icons/back.svg';
import * as Yup from "yup";
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import dayjs from 'dayjs';
import { useNavigate } from 'react-router-dom';
import { PreferencesContext } from '../../PreferenceContext';
import { GetFamilyMemberDetail, GetMotherList } from '../../services/ParentService';
import { AlertType } from '../../shared/AlertType';
import infoIcon from '../../assets/icons/info.svg';
import { ageInWeeks, getSingleSelected } from '../../shared/CommonFuctions';
import { CreateMaternalDetail, GetIsMaternalDetailExist, GetMaternalDetail, UpdateMaternalDetail } from '../../services/MaternalDetailService';
import CustomButton from '../../components/CustomButton/CustomButton';
import { LoadingButton } from '@mui/lab';
import { MemberDetail } from '../../services/models/Context';
import CreatableSelect from 'react-select/creatable';
import { GetMotherModuleConfiguration } from '../../services/DashboardService';

const formInitialValues = {
    maternalDetailId: 0,
    motherId: "",
    expectingChildNo: "",
    lastManstrualPeriodDate: "",
    expectingDateOfDelivery: "",
    gestationalAge: "",
    complicationsDetails: "",
    familyRelationName: 0,
    firstName: "",
    lastName: "",
    age: 0
};

export default function MaternalDetails() {

    const [loading, setLoading] = useState(false);
    const [mothetLoadingData, setMothetLoadingData] = useState(false);
    const [submitLoading, setSubmitLoading] = useState(false);
    const { userContext, updateUserContext } = useContext(PreferencesContext);
    const [familyMemberinitial, setFamilyMemberInitial] = useState(formInitialValues);
    const [isBabyNotDelivered, setIsBabyNotDelivered] = useState(false);
    const [visibleDashboardCardData, setVisibleDashboardCardData] = useState([]);
    const [motherData, setMotherData] = useState([]);
    const navigate = useNavigate();

    const valildationSchema = Yup.object().shape({
        motherId: Yup.string().required("Mother selection is required."),
        expectingChildNo: Yup.string().required("Expecting child number is required."),
        lastManstrualPeriodDate: Yup.string().required("Date is required."),
        expectingDateOfDelivery: Yup.string().required("Date is required."),
        gestationalAge: Yup.string().required("Age is required.")
    });

    const options = [
        { value: 'First Child', label: 'First Child' },
        { value: 'Second Child', label: 'Second Child' }
    ]

    const gestationalAgeOptions = [
        { value: 4, label: '4 Weeks' }, { value: 5, label: '5 Weeks' }, { value: 6, label: '6 Weeks' },
        { value: 7, label: '7 Weeks' }, { value: 8, label: '8 Weeks' }, { value: 9, label: '9 Weeks' },
        { value: 10, label: '10 Weeks' }, { value: 11, label: '11 Weeks' }, { value: 12, label: '12 Weeks' },
        { value: 13, label: '13 Weeks' }, { value: 14, label: '14 Weeks' }, { value: 15, label: '15 Weeks' },
        { value: 16, label: '16 Weeks' }, { value: 17, label: '17 Weeks' }, { value: 18, label: '18 Weeks' },
        { value: 19, label: '19 Weeks' }, { value: 20, label: '20 Weeks' }, { value: 21, label: '21 Weeks' },
        { value: 22, label: '22 Weeks' }, { value: 23, label: '23 Weeks' }, { value: 24, label: '24 Weeks' },
        { value: 25, label: '25 Weeks' }, { value: 26, label: '26 Weeks' }, { value: 27, label: '27 Weeks' },
        { value: 28, label: '28 Weeks' }, { value: 29, label: '29 Weeks' }, { value: 30, label: '30 Weeks' },
        { value: 31, label: '31 Weeks' }, { value: 32, label: '32 Weeks' }, { value: 33, label: '33 Weeks' },
        { value: 34, label: '34 Weeks' }, { value: 35, label: '35 Weeks' }, { value: 36, label: '36 Weeks' },
        { value: 37, label: '37 Weeks' }, { value: 38, label: '38 Weeks' }, { value: 39, label: '39 Weeks' },
        { value: 40, label: '40 Weeks' }, { value: 41, label: '41 Weeks' }, { value: 42, label: '42 Weeks' },
    ]

    function backClick() {
        if (userContext.loginDetail !== null && userContext.loginDetail.userId > 0 && userContext.isAddMember === true) {
            navigate("/stage-selection");
        } else if (userContext.isEditMaternalDetail === true) {
            navigate("/parent");
        } else if (userContext.isComeFromStageSelection === true) {
            navigate("/stage-selection");
        } else if (userContext.isAddMaternalDetail === true) {
            navigate("/parent");
        }
        else {
            navigate("/spouse");
        }
    }

    const goToDashboard = () => {
        setIsBabyNotDelivered(false);
        navigate('/dashboard');
    }
    async function fetchData() {
        setLoading(true);
        try {
            let params = new URLSearchParams();
            params.append("maternalDetailId", userContext.curMaternalDetailId.toString());
            const result: any = await GetMaternalDetail(params);
            let editDetails = result.data.data;
            if (result.data !== undefined && result.data !== null && result.data.data !== null) {
                let updatedValue = {
                    maternalDetailId: editDetails.maternalDetailId,
                    motherId: editDetails.familyMemberId,
                    complicationsDetails: editDetails.complicationsDetails,
                    familyRelationName: editDetails.familyRelationName,
                    firstName: editDetails.firstName,
                    lastName: editDetails.lastName,
                    age: editDetails.age,
                    gestationalAge: editDetails.gestationalAge === 0 ? "" : editDetails.gestationalAge,
                    lastManstrualPeriodDate: editDetails.lastManstrualPeriodDate,
                    expectingDateOfDelivery: editDetails.expectingDateOfDelivery,
                    expectingChildNo: editDetails.expectingChildNo === "0" ? '' : editDetails.expectingChildNo
                };
                setFamilyMemberInitial(updatedValue);
            } else {
                let updatedValue = {
                    maternalDetailId: 0,
                    motherId: userContext.currFamilyMemberId,
                    complicationsDetails: '',
                    familyRelationName: 'MOTHER',
                    firstName: '',
                    lastName: '',
                    age: '',
                    gestationalAge: '',
                    lastManstrualPeriodDate: '',
                    expectingDateOfDelivery: '',
                    expectingChildNo: ''
                };
                setFamilyMemberInitial(updatedValue);
            }
        }
        catch (error) {
            if (error) {
                updateUserContext({
                    ...userContext,
                    isAlert: true,
                    alertMessage: 'Something went wrong',
                    // alertMessage: error.response.message,
                    alertType: AlertType.Error,
                });
            }
        }
        setLoading(false);
    }

    // const expectingChildCreateOption = (label: string, id: any) => ({
    //     label,
    //     value: id.toString(),
    // });

    // function handleExpectingChildCountCreate(inputValue: any, props: any) {
    //     let max: any = options.reduce(function (prev: any, current: any) {
    //         if (+current.value > +prev.value) {
    //             return current;
    //         } else {
    //             return prev;
    //         }
    //     });

    //     let sendNewVaccineValue = max.value;
    //     let maxVaccineValue = sendNewVaccineValue++;

    //     const newOption = expectingChildCreateOption(inputValue, sendNewVaccineValue);

    //     options.push(newOption);

    //     props.setFieldValue(
    //         "expectingChildNo",
    //         "-1",
    //         true
    //     );
    // };

    async function fetchMotherData() {
        setMothetLoadingData(true);
        try {
            let params = new URLSearchParams();
            params.append("familyId", userContext.loginDetail.familyId.toString());
            params.append("familyRelationName", "MOTHER");
            if (userContext.isEditMaternalDetail === true) {
                params.append("mode", "CHILD");
            } else {
                params.append("mode", "MATERNITY");
            }
            const result: any = await GetMotherList(params);
            setMotherData(result.data.data);
            let formValues: any;
            if (result.data.data.length > 1) {
                formValues = {
                    maternalDetailId: 0,
                    motherId: "",
                    expectingChildNo: "",
                    lastManstrualPeriodDate: "",
                    expectingDateOfDelivery: "",
                    gestationalAge: "",
                    complicationsDetails: "",
                    familyRelationName: 0,
                    firstName: "",
                    lastName: "",
                    age: 0
                }
            } else {
                formValues = {
                    maternalDetailId: 0,
                    motherId: result.data.data[0].value,
                    expectingChildNo: "",
                    lastManstrualPeriodDate: "",
                    expectingDateOfDelivery: "",
                    gestationalAge: "",
                    complicationsDetails: "",
                    familyRelationName: 0,
                    firstName: "",
                    lastName: "",
                    age: 0
                }
            }
            setFamilyMemberInitial(formValues);
            if (userContext.isEditMaternalDetail === true) {
                if (userContext.spouseFamilyMemberId > 0) {
                    fetchData();
                }
            }
            if (userContext.isAddMaternalDetail === true) {
                if (userContext.currFamilyMemberId > 0) {
                    fetchData();
                }
            }
        }
        catch (error) {
            if (error) {
                updateUserContext({
                    ...userContext,
                    isAlert: true,
                    alertMessage: 'Something went wrong',
                    // alertMessage: error.response.message,
                    alertType: AlertType.Error,
                });
            }
        }
        setMothetLoadingData(false);
    }

    async function SubmitMaternalDetail(value: any) {
        setSubmitLoading(true);
        let parentData = value;
        let SaveUpdateParent = {
            familyId: userContext.loginDetail.familyId,
            familyMemberId: parentData.motherId,
            maternalDetailId: parentData.maternalDetailId,
            expectingChildNo: parentData.expectingChildNo,
            isPragnentExpectingChild: false,
            gestationalAge: parentData.gestationalAge,
            gestationalAgeTime: "Weeks",
            lastManstrualPeriodDate: dayjs(parentData.lastManstrualPeriodDate).format('MM/DD/YYYY'),
            expectingDateOfDelivery: dayjs(parentData.expectingDateOfDelivery).format('MM/DD/YYYY'),
            complicationsDetails: parentData.complicationsDetails,
            dataManagedBy: userContext.loginDetail.userId
        };
        if (parentData.maternalDetailId > 0) {
            try {
                const resultmaternalDetailSave: any = await UpdateMaternalDetail(SaveUpdateParent);
                let maternalDetail = resultmaternalDetailSave.data
                setSubmitLoading(false);
                navigate("/dashboard");
            } catch (error) {
                setSubmitLoading(false);
                if (error) {
                    updateUserContext({
                        ...userContext,
                        isAlert: true,
                        alertMessage: error as string,
                        alertType: AlertType.Error,
                    });
                }
            }
        } else {
            // when pervious child not born
            let params = new URLSearchParams();
            params.append("familyId", userContext.loginDetail.familyId.toString());
            params.append("familyMemberId", parentData.motherId.toString());
            const maternalDetailExist: any = await GetIsMaternalDetailExist(params);
            if (maternalDetailExist.data.data !== null) {
                if (maternalDetailExist.data.data.isChildBorn === false && maternalDetailExist.data.data.isTermination === false) {
                    setIsBabyNotDelivered(true);
                } else {
                    try {
                        const resultmaternalDetailSave: any = await CreateMaternalDetail(SaveUpdateParent);
                        setSubmitLoading(false);
                        let maternalDetail = resultmaternalDetailSave.data
                        let memberDetail = new MemberDetail();
                        memberDetail.familyId = maternalDetail.familyId;
                        memberDetail.familyMemberId = maternalDetail.familyMemberId;
                        memberDetail.familyRelationName = 'MOTHER';
                        memberDetail.firstName = maternalDetail.firstName;
                        memberDetail.lastName = maternalDetail.lastName;
                        memberDetail.gender = 'FEMALE';
                        memberDetail.isPrematureChild = maternalDetail.isPrematureChild;
                        memberDetail.isSpecialChild = maternalDetail.isSpecialChild;
                        memberDetail.maternalDetailId = maternalDetail.maternalDetailId;
                        memberDetail.complicationsDetails = maternalDetail.complicationsDetails;
                        memberDetail.expectingChildNo = maternalDetail.expectingChildNo;
                        memberDetail.expectingDateOfDelivery = maternalDetail.expectingDateOfDelivery;
                        memberDetail.gestationalAge = maternalDetail.gestationalAge;
                        memberDetail.gestationalAgeTime = maternalDetail.gestationalAgeTime;
                        memberDetail.isChildBorn = maternalDetail.isChildBorn;
                        memberDetail.isTermination = maternalDetail.isTermination;
                        memberDetail.isPragnentExpectingChild = maternalDetail.isPragnentExpectingChild;
                        memberDetail.lastManstrualPeriodDate = dayjs(maternalDetail.lastManstrualPeriodDate).format('DD/MM/YYYY');
                        memberDetail.profileImageBase = maternalDetail.profileImageBase;


                        updateUserContext({
                            ...userContext,
                            memberDetail: memberDetail,
                            isGoToMaternalDetail: true,
                        });
                        navigate("/dashboard");
                    } catch (error) {
                        setSubmitLoading(false);
                        if (error) {
                            updateUserContext({
                                ...userContext,
                                isAlert: true,
                                alertMessage: error as string,
                                alertType: AlertType.Error,
                            });
                        }
                    }
                }
            } else {
                try {
                    let moduleConfiguration: any
                    const resultmaternalDetailSave: any = await CreateMaternalDetail(SaveUpdateParent);
                    setSubmitLoading(false);
                    let maternalDetail = resultmaternalDetailSave.data
                    let memberDetail = new MemberDetail();
                    memberDetail.familyId = maternalDetail.familyId;
                    memberDetail.familyMemberId = maternalDetail.familyMemberId;
                    memberDetail.familyRelationName = maternalDetail.familyRelationName !== undefined ? maternalDetail.familyRelationName : 'MOTHER';
                    memberDetail.firstName = maternalDetail.firstName;
                    memberDetail.lastName = maternalDetail.lastName;
                    memberDetail.gender = maternalDetail.gender !== undefined ? maternalDetail.gender : 'FEMALE';
                    memberDetail.isPrematureChild = maternalDetail.isPrematureChild;
                    memberDetail.isSpecialChild = maternalDetail.isSpecialChild;
                    memberDetail.maternalDetailId = maternalDetail.maternalDetailId;
                    memberDetail.complicationsDetails = maternalDetail.complicationsDetails;
                    memberDetail.expectingChildNo = maternalDetail.expectingChildNo;
                    memberDetail.expectingDateOfDelivery = maternalDetail.expectingDateOfDelivery;
                    memberDetail.gestationalAge = maternalDetail.gestationalAge;
                    memberDetail.gestationalAgeTime = maternalDetail.gestationalAgeTime;
                    memberDetail.isChildBorn = maternalDetail.isChildBorn;
                    memberDetail.isPragnentExpectingChild = maternalDetail.isPragnentExpectingChild;
                    memberDetail.lastManstrualPeriodDate = maternalDetail.lastManstrualPeriodDate;
                    memberDetail.profileImageBase = maternalDetail.profileImageBase;

                    let week = ageInWeeks(dayjs(maternalDetail.lastManstrualPeriodDate).format('DD/MM/YYYY'));
                    if (+week > 13) {
                        let params = new URLSearchParams();
                        params.append("lmpDate", userContext.memberDetail.lastManstrualPeriodDate);
                        params.append("gender", userContext.memberDetail.gender);
                        const result: any = await GetMotherModuleConfiguration(params);
                        let dashboardCard = result.data.data
                        setVisibleDashboardCardData(dashboardCard.sort());
                        moduleConfiguration = userContext.moduleConfiguration;
                        moduleConfiguration = dashboardCard;
                    }

                    updateUserContext({
                        ...userContext,
                        memberDetail: memberDetail,
                        moduleConfiguration: moduleConfiguration,
                        isGoToMaternalDetail: true,
                    });
                    navigate("/dashboard");
                } catch (error) {
                    setSubmitLoading(false);
                    if (error) {
                        updateUserContext({
                            ...userContext,
                            isAlert: true,
                            alertMessage: error as string,
                            alertType: AlertType.Error,
                        });
                    }
                }
            }
        }
    }

    function diff_weeks(dt2, dt1) {
        var diff = (dt2.getTime() - dt1.getTime()) / 1000;
        diff /= (60 * 60 * 24 * 7);
        return Math.abs(Math.round(diff));
    }

    useEffect(() => {
        if (userContext.loginDetail.userId > 0) {
            fetchMotherData();
        }

        // if (userContext.isEditMaternalDetail === true) {
        //     if (userContext.spouseFamilyMemberId > 0) {
        //         fetchData();
        //     }
        // }
    }, []);


    return (
        <Fade in={true} timeout={{ enter: 500 }}>
            <Box className="maternal-main-box">
                <Box className="head-box">
                    <Box className="back-box">
                        <IconButton aria-label="back" className='back-icon' disableRipple
                            onClick={() => backClick()}>
                            <img src={backIcon} alt='logo' />
                        </IconButton>
                    </Box>
                    <Box className="title-box">
                        <Typography className="title-text">Maternal Details</Typography>
                    </Box>
                    <Box className="skip-box">
                        <></>
                        {/* <Typography>skip</Typography> */}
                    </Box>

                </Box>
                <Formik
                    enableReinitialize
                    initialValues={familyMemberinitial}
                    onSubmit={(values: any) => {
                        SubmitMaternalDetail(values);
                    }}
                    validationSchema={valildationSchema}>
                    {(props) => {
                        return (
                            <Form>
                                <Box className="body-box">
                                    <Grid
                                        container
                                        spacing={2}
                                        padding={2}>

                                        <Grid item xs={12} md={12} sm={12} zIndex={99}>
                                            <FormControl fullWidth style={{ width: '100%' }}>
                                                {loading ?
                                                    <Skeleton animation="wave" variant="rounded" width='auto' height={45} /> :
                                                    <Select
                                                        id="motherId"
                                                        classNamePrefix="select"
                                                        styles={
                                                            props.values.motherId === "" &&
                                                                props.errors.motherId &&
                                                                props.touched.motherId
                                                                ? selectStyles
                                                                : undefined
                                                        }
                                                        isClearable={true}
                                                        isSearchable={true}
                                                        placeholder={"Mother"}
                                                        theme={(theme) => ({
                                                            ...theme,
                                                            colors: {
                                                                ...theme.colors,
                                                                primary: "#0B7CAA",
                                                                primary25: "#F1F6FE",
                                                                primary50: "#e1dfea",
                                                            },
                                                        })}
                                                        value={getSingleSelected(
                                                            props.values.motherId.toString(),
                                                            motherData
                                                        )}
                                                        onChange={(e, value) => {
                                                            props.setFieldValue(
                                                                "motherId",
                                                                e !== null ? e.value : "",
                                                                false
                                                            );
                                                        }}
                                                        options={motherData}
                                                        className={
                                                            props.values.motherId === "" &&
                                                                props.errors.motherId &&
                                                                props.touched.motherId
                                                                ? "text-input"
                                                                : "text-input"
                                                        }
                                                    />
                                                }
                                                {props.values.motherId === "" &&
                                                    props.errors.motherId &&
                                                    props.touched.motherId && (
                                                        <Typography
                                                            className={
                                                                props.values.motherId === "" &&
                                                                    props.errors.motherId &&
                                                                    props.touched.motherId
                                                                    ? "validation-message text-input"
                                                                    : "text-input"
                                                            }
                                                        >
                                                            {props.errors.motherId}
                                                        </Typography>
                                                    )}
                                            </FormControl>
                                        </Grid>

                                        <Grid item xs={12} md={12} sm={12} zIndex={9}>
                                            <FormControl fullWidth style={{ width: '100%' }}>
                                                {loading ?
                                                    <Skeleton animation="wave" variant="rounded" width='auto' height={45} /> :
                                                    // <Select
                                                    //     id="expectingChildNo"
                                                    //     classNamePrefix="select"
                                                    //     styles={
                                                    //         props.values.expectingChildNo === "" &&
                                                    //             props.errors.expectingChildNo &&
                                                    //             props.touched.expectingChildNo
                                                    //             ? selectStyles
                                                    //             : undefined
                                                    //     }
                                                    //     isDisabled={false}
                                                    //     // isLoading={isLoading}
                                                    //     isClearable={true}
                                                    //     isSearchable={true}
                                                    //     placeholder={"Expecting"}
                                                    //     theme={(theme) => ({
                                                    //         ...theme,
                                                    //         colors: {
                                                    //             ...theme.colors,
                                                    //             primary: "#0B7CAA",
                                                    //             primary25: "#ecebef",
                                                    //             primary50: "#e1dfea",
                                                    //         },
                                                    //     })}
                                                    //     value={getSingleSelected(
                                                    //         props.values.expectingChildNo,
                                                    //         options
                                                    //     )}
                                                    //     onChange={(e, value) => {
                                                    //         props.setFieldValue(
                                                    //             "expectingChildNo",
                                                    //             e !== null ? e.value : "",
                                                    //             false
                                                    //         );
                                                    //     }}
                                                    //     options={options}
                                                    //     className={
                                                    //         props.values.expectingChildNo === "" &&
                                                    //             props.errors.expectingChildNo &&
                                                    //             props.touched.expectingChildNo
                                                    //             ? "text-input"
                                                    //             : "text-input"
                                                    //     }
                                                    // />

                                                    <TextField
                                                        // required
                                                        id="expectingChildNo"
                                                        type="number"
                                                        label="Expecting child number"
                                                        fullWidth
                                                        size="small"
                                                        onChange={(e: any) => {
                                                            props.setFieldValue(
                                                                "expectingChildNo",
                                                                e.target.value !== null ? e.target.value : "",
                                                                true
                                                            );
                                                        }}
                                                        onBlur={props.handleBlur}
                                                        value={props.values.expectingChildNo}
                                                        inputProps={{ maxLength: 200 }}
                                                        variant="outlined"
                                                        error={
                                                            props.values.expectingChildNo === "" &&
                                                                props.errors.expectingChildNo &&
                                                                props.touched.expectingChildNo
                                                                ? true
                                                                : false
                                                        }
                                                        className={
                                                            props.values.expectingChildNo === "" && props.errors.expectingChildNo &&
                                                                props.touched.expectingChildNo
                                                                ? "text-input validation-message"
                                                                : "text-input"
                                                        }
                                                    />}
                                                {props.values.expectingChildNo === "" && props.errors.expectingChildNo &&
                                                    props.touched.expectingChildNo && (
                                                        <Typography
                                                            className={
                                                                props.values.age === "" &&
                                                                    props.errors.age &&
                                                                    props.touched.age
                                                                    ? "validation-message"
                                                                    : "validation-message"
                                                            }
                                                        >
                                                            {props.errors.expectingChildNo}
                                                        </Typography>
                                                    )}

                                            </FormControl>
                                        </Grid>

                                        <Grid item xs={12} md={12} sm={12}>
                                            {loading ?
                                                <Skeleton animation="wave" variant="rounded" width='auto' height={45} /> :
                                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                    <DemoContainer components={['MobileDatePicker']}>
                                                        <MobileDatePicker
                                                            label="Last Menstrual Period (LMP) Date"
                                                            className={
                                                                props.values.lastManstrualPeriodDate === "" &&
                                                                    props.errors.lastManstrualPeriodDate &&
                                                                    props.touched.lastManstrualPeriodDate
                                                                    ? "datepicker validation-message"
                                                                    : "datepicker"
                                                            }
                                                            sx={{ width: '100%' }}
                                                            format='DD/MM/YYYY'
                                                            maxDate={dayjs(new Date(new Date().getTime() - (28 * 24 * 60 * 60 * 1000)))}
                                                            disableFuture
                                                            value={dayjs(props.values.lastManstrualPeriodDate)}
                                                            onChange={(e: any, value) => {
                                                                props.setFieldValue(
                                                                    "lastManstrualPeriodDate",
                                                                    e !== null ? e.toDate() : "",
                                                                    false
                                                                );
                                                                if (e.toDate() !== null) {
                                                                    // props.setFieldValue(
                                                                    //     "expectingDateOfDelivery",
                                                                    //     dayjs(e.toDate().setMonth(e.toDate().getMonth() + 9)),
                                                                    //     true
                                                                    // );
                                                                    props.setFieldValue(
                                                                        "expectingDateOfDelivery",
                                                                        dayjs(e.toDate().setDate(e.toDate().getDate() + 282)),
                                                                        true
                                                                    )
                                                                    let dt1 = new Date();
                                                                    let dt2 = new Date(e.toDate());
                                                                    let week = diff_weeks(dt1, dt2);
                                                                    props.setFieldValue(
                                                                        "gestationalAge",
                                                                        week,
                                                                        true
                                                                    );

                                                                }
                                                            }}
                                                        />
                                                    </DemoContainer>
                                                </LocalizationProvider>
                                            }
                                            {props.values.lastManstrualPeriodDate === "" &&
                                                props.errors.lastManstrualPeriodDate &&
                                                props.touched.lastManstrualPeriodDate && (
                                                    <Typography
                                                        className={
                                                            props.values.lastManstrualPeriodDate === "" &&
                                                                props.errors.lastManstrualPeriodDate &&
                                                                props.touched.lastManstrualPeriodDate
                                                                ? "validation-message text-input"
                                                                : "text-input"
                                                        }>
                                                        {props.errors.lastManstrualPeriodDate}
                                                    </Typography>
                                                )}
                                        </Grid>

                                        <Grid item xs={12} md={12} sm={12}>
                                            {loading ?
                                                <Skeleton animation="wave" variant="rounded" width='auto' height={45} /> :
                                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                    <DemoContainer components={['MobileDatePicker']}>
                                                        <MobileDatePicker
                                                            label="Expected Date of Delivery"
                                                            className={
                                                                props.values.expectingDateOfDelivery === "" &&
                                                                    props.errors.expectingDateOfDelivery &&
                                                                    props.touched.expectingDateOfDelivery
                                                                    ? "datepicker validation-message"
                                                                    : "datepicker"
                                                            }
                                                            value={dayjs(props.values.expectingDateOfDelivery)}
                                                            sx={{ width: '100%' }}
                                                            format='DD/MM/YYYY'
                                                            // value={(props.values.expectingDateOfDelivery)}
                                                            onChange={(e) =>
                                                                props.setFieldValue(
                                                                    "expectingDateOfDelivery",
                                                                    e !== null ? e.toDate() : "",
                                                                    true
                                                                )
                                                            }
                                                        />
                                                    </DemoContainer>
                                                </LocalizationProvider>
                                            }
                                            {props.values.expectingDateOfDelivery === "" &&
                                                props.errors.expectingDateOfDelivery &&
                                                props.touched.expectingDateOfDelivery && (
                                                    <Typography
                                                        className={
                                                            props.values.expectingDateOfDelivery === "" &&
                                                                props.errors.expectingDateOfDelivery &&
                                                                props.touched.expectingDateOfDelivery
                                                                ? "validation-message text-input"
                                                                : "text-input"
                                                        }>
                                                        {props.errors.expectingDateOfDelivery}
                                                    </Typography>
                                                )}
                                        </Grid>

                                        <Grid item xs={12} md={12} sm={12} className="age-main-grid">
                                            <Grid item xs={10.5} md={10.5} sm={10.5} zIndex={9}>
                                                <FormControl fullWidth>
                                                    {loading ?
                                                        <Skeleton animation="wave" variant="rounded" width='auto' height={45} /> :
                                                        <Select
                                                            id="gestationalAge"
                                                            classNamePrefix="select"
                                                            styles={
                                                                props.values.gestationalAge === "" &&
                                                                    props.errors.gestationalAge &&
                                                                    props.touched.gestationalAge
                                                                    ? selectStyles
                                                                    : undefined
                                                            }
                                                            isDisabled={false}
                                                            isClearable={true}
                                                            isSearchable={true}
                                                            placeholder={"Gestational Age"}
                                                            theme={(theme) => ({
                                                                ...theme,
                                                                colors: {
                                                                    ...theme.colors,
                                                                    primary: "#0B7CAA",
                                                                    primary25: "#ecebef",
                                                                    primary50: "#e1dfea",
                                                                },
                                                            })}
                                                            value={getSingleSelected(
                                                                props.values.gestationalAge,
                                                                gestationalAgeOptions
                                                            )}
                                                            onChange={(e, value) => {
                                                                props.setFieldValue(
                                                                    "gestationalAge",
                                                                    e !== null ? e.label : "",
                                                                    false
                                                                );
                                                            }}
                                                            options={gestationalAgeOptions}
                                                            className={
                                                                props.values.gestationalAge === "" &&
                                                                    props.errors.gestationalAge &&
                                                                    props.touched.gestationalAge
                                                                    ? "text-input"
                                                                    : "text-input"
                                                            }
                                                        />}
                                                    {props.values.gestationalAge === "" && props.errors.gestationalAge &&
                                                        props.touched.gestationalAge && (
                                                            <Typography
                                                                className={
                                                                    props.values.gestationalAge === "" &&
                                                                        props.errors.gestationalAge &&
                                                                        props.touched.gestationalAge
                                                                        ? "validation-message text-input"
                                                                        : "text-input"
                                                                }
                                                            >
                                                                {props.errors.gestationalAge}
                                                            </Typography>
                                                        )}
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={1.5} md={1.5} sm={1.5} className="information-icon-grid">
                                                <IconButton aria-label="info" className='info-icon' disableRipple>
                                                    <img src={infoIcon} alt='logo' />
                                                </IconButton>
                                            </Grid>
                                        </Grid>

                                        <Grid item xs={12} md={12} sm={12}>
                                            <FormControl fullWidth>
                                                {loading ?
                                                    <Skeleton animation="wave" variant="rounded" width='auto' height={90} /> :
                                                    <TextField
                                                        id="complicationsDetails"
                                                        label="Complications in pregnancy"
                                                        fullWidth
                                                        size="small"
                                                        multiline={true}
                                                        rows={3}
                                                        onChange={(e: any) => {
                                                            props.setFieldValue(
                                                                "complicationsDetails",
                                                                e.target.value !== null ? e.target.value : "",
                                                                true
                                                            );
                                                        }}
                                                        onBlur={props.handleBlur}
                                                        value={props.values.complicationsDetails}
                                                        inputProps={{ maxLength: 200 }}
                                                        variant="outlined"
                                                        error={
                                                            props.errors.complicationsDetails &&
                                                                props.touched.complicationsDetails
                                                                ? true
                                                                : false
                                                        }
                                                        className={
                                                            props.errors.complicationsDetails &&
                                                                props.touched.complicationsDetails
                                                                ? "text-input validation-message"
                                                                : "text-input"
                                                        }
                                                    />}
                                                {props.errors.complicationsDetails &&
                                                    props.touched.complicationsDetails && (
                                                        <Typography
                                                            className={
                                                                props.errors.complicationsDetails &&
                                                                    props.touched.complicationsDetails
                                                                    ? "validation-message"
                                                                    : "validation-message"
                                                            }
                                                        >
                                                            {props.errors.complicationsDetails}
                                                        </Typography>
                                                    )}
                                            </FormControl>
                                        </Grid>

                                        <Box className="bottom-box">
                                            <CustomButton
                                                disabled={false}
                                                class="add-button"
                                                isLoading={submitLoading}
                                                title='Save'
                                            />
                                        </Box>

                                    </Grid>
                                </Box>
                            </Form>
                        )
                    }}</Formik>

                <Dialog
                    maxWidth="md"
                    open={isBabyNotDelivered}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    className="birthdate-confirm-dialog-box">
                    <Box className="confirm-box">
                        <DialogContent className="dialog-subcontent">
                            <DialogContentText
                                id="alert-dialog-description"
                                className="birthdate-text">
                                Your First child not delivered yet.
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions className="dialog-action-button">
                            <LoadingButton
                                color="primary"
                                disableRipple
                                variant="contained"
                                className="confirm-btn"
                                onClick={() => goToDashboard()}>
                                Ok
                            </LoadingButton>
                        </DialogActions>
                    </Box>
                </Dialog>
            </Box>
        </Fade>
    );
}

const selectStyles = {
    control: (provided: any, state: any) => ({
        ...provided,
        border: state.isFocused ? "1px solid #0B7CAA" : "1px solid #d32f2f",
    }),
    placeholder: (provided, state) => ({
        ...provided,
        color: state.isFocused ? state.selectProps.color : "#d32f2f",
    }),
    valueContainer: base => ({
        ...base,
        paddingLeft: 24
    })
};