import React, { useContext, useEffect, useState } from "react";
import './DietAndNutritionList.scss';
import { PreferencesContext } from "../../PreferenceContext";
import { Alert, AlertTitle, AppBar, Box, Card, Dialog, DialogContent, Fade, Grid, IconButton, Toolbar, Typography } from "@mui/material";
import backIcon from '../../assets/icons/back.svg';
import dietAndNutritionIcon from "../../assets/icons/diet-nutritionIcon.svg";
import heightGainIcon from "../../assets/icons/height_gain.svg";
import alertIcon from "../../assets/icons/alert.svg";
import dontEatIcon from "../../assets/icons/dont-eat.svg";
import weightIcon from '../../assets/icons/weight_gain.svg';
import { useNavigate } from "react-router-dom";
import CloseIcon from '@mui/icons-material/Close';

export default function DietAndNutritionList() {
    const { userContext, updateUserContext } = useContext(PreferencesContext);
    const [submoduleLoading, setSubmoduleLoading] = useState(false);
    const [submoduleData, setSubmoduleData] = useState([]);
    const [openEmergencyConditionDialog, setOpenEmergencyConditionDialog] = useState(false);

    const navigate = useNavigate();

    function backClick() {
        navigate("/dashboard");
    }

    function goToFoodItem(submoduleId, subModuleName: any) {
        // navigate('/food-item',
        //     { state: { submoduleId: submoduleId, subModuleName: subModuleName } }
        // );
        navigate('/food-item');
    }

    function goToWeightGain(submoduleId, subModuleName: any) {
        // navigate('/weight-gain',
        //     { state: { submoduleId: submoduleId, subModuleName: subModuleName } }
        // );
        navigate('/expected-weight-gain');
    }

    function goToHeightGain(submoduleId, subModuleName: any) {
        // navigate('/expected-height-gain',
        //     { state: { submoduleId: submoduleId, subModuleName: subModuleName } }
        // );


        navigate('/expected-height-gain');
    }

    function goToWhatToBeGiven(submoduleId, subModuleName: any) {
        // navigate('/what-to-be-given',
        //     { state: { submoduleId: submoduleId, subModuleName: subModuleName } }
        // );
        navigate('/what-to-be-given');
    }

    function goToWhatShouldNotBeGiven(submoduleId, subModuleName: any) {
        // navigate('/what-should-not-be-given',
        //     { state: { submoduleId: submoduleId, subModuleName: subModuleName } }
        // );
        navigate('/what-should-not-be-given');
    }

    function goToFoodRestricted(submoduleId, subModuleName: any) {
        // navigate('/food-restricted',
        //     { state: { submoduleId: submoduleId, subModuleName: subModuleName } }
        // );
        navigate('/food-restricted');
    }

    function goToAlert(submoduleId, subModuleName: any) {
        // navigate('/alert',
        //     { state: { submoduleId: submoduleId, subModuleName: subModuleName } }
        // );
        navigate('/alert');
    }

    function goToSpecialConcern(submoduleId, subModuleName: any) {
        // navigate('/special-concern',
        //     { state: { submoduleId: submoduleId, subModuleName: subModuleName } }
        // );
        navigate('/special-concern');
    }

    const handleCloseEmergencyConditionDialog = () => {
        setOpenEmergencyConditionDialog(false);
    };

    async function fetchSubModules() {
        setSubmoduleLoading(true);
        if (userContext.moduleConfiguration !== undefined) {
            let data = userContext.moduleConfiguration
            let curSubmodule: any = userContext.moduleConfiguration.find(
                (x: any) => x.moduleCode === "DIET_NUTRITION"
            );
            setSubmoduleData(curSubmodule.submodule.sort())
            setSubmoduleLoading(false);
        }
    }

    useEffect(() => {
        if (userContext.memberDetail !== undefined && userContext.memberDetail.familyMemberId > 0) {
            fetchSubModules();
        }
    }, []);
    return (
        <Fade in={true} timeout={{ enter: 500 }}>
            <Box className="diet-nutrition-list-main-box">
                <Box className="head-box">
                    <Box className="back-box">
                        <IconButton aria-label="back" className='back-icon' disableRipple
                            onClick={() => backClick()}>
                            <img src={backIcon} alt='logo' />
                        </IconButton>
                    </Box>
                    <Box className="title-box">
                        <Typography className="title-text">Diet and Nutrition </Typography>
                    </Box>
                    <Box className="profile-box">
                        <></>
                    </Box>
                </Box>

                <Box className="body-box">
                    <Grid container spacing={2} className='main-container-grid'
                        sx={{
                            display: 'flex',
                            flexWrap: {
                                xs: 'wrap',
                                sm: 'wrap',
                                md: 'nowrap',
                                lg: 'nowrap',
                                xl: 'nowrap'
                            }
                        }}>
                        {submoduleData.length > 0 &&
                            submoduleData.map((row: any, index: any) => (
                                ["FOOD_ITEM", "EXPECTED_WEIGHT_GAIN", "EXPECTED_HEIGHT_GAIN", "WHAT_TO_BE_GIVEN",
                                    "WHAT_SHOULD_NOT_BE_GIVEN", "FOOD_RESTRICTED", "ALERT", "SPECIAL_CONCERN",
                                ].includes(row.submoduleCode) && (
                                    <Grid className='sub-item-grid'
                                        item key={index} xs={6} sm={3} md={12} lg={12} xl={12}>
                                        <Card className='inner-card' onClick={() => {
                                            if (row.submoduleCode === "FOOD_ITEM") goToFoodItem(row.submoduleId, row.submoduleCode);
                                            if (row.submoduleCode === "EXPECTED_WEIGHT_GAIN") goToWeightGain(row.submoduleId, row.submoduleCode);
                                            if (row.submoduleCode === "EXPECTED_HEIGHT_GAIN") goToHeightGain(row.submoduleId, row.submoduleCode);
                                            if (row.submoduleCode === "WHAT_TO_BE_GIVEN") goToWhatToBeGiven(row.submoduleId, row.submoduleCode);
                                            if (row.submoduleCode === "WHAT_SHOULD_NOT_BE_GIVEN") goToWhatShouldNotBeGiven(row.submoduleId, row.submoduleCode);
                                            if (row.submoduleCode === "FOOD_RESTRICTED") goToFoodRestricted(row.submoduleId, row.submoduleCode);
                                            if (row.submoduleCode === "ALERT") goToAlert(row.submoduleId, row.submoduleCode);
                                            if (row.submoduleCode === "SPECIAL_CONCERN") goToSpecialConcern(row.submoduleId, row.submoduleCode);
                                        }}>
                                            <Box className='middle-img'>
                                                <img src={
                                                    row.submoduleCode === "FOOD_ITEM" ? dietAndNutritionIcon :
                                                        row.submoduleCode === "EXPECTED_WEIGHT_GAIN" ? weightIcon :
                                                            row.submoduleCode === "EXPECTED_HEIGHT_GAIN" ? heightGainIcon :
                                                                row.submoduleCode === "WHAT_TO_BE_GIVEN" ? dietAndNutritionIcon :
                                                                    row.submoduleCode === "WHAT_SHOULD_NOT_BE_GIVEN" ? dontEatIcon :
                                                                        row.submoduleCode === "FOOD_RESTRICTED" ? dietAndNutritionIcon :
                                                                            row.submoduleCode === "ALERT" ? alertIcon :
                                                                                row.submoduleCode === "SPECIAL_CONCERN" ? dietAndNutritionIcon : dietAndNutritionIcon
                                                } alt='logo' />
                                            </Box>
                                            <Box className='card-upper-box'>
                                                <Typography className='upper-text'>
                                                    {row.submoduleCode === "FOOD_ITEM" ? row.submoduleName : null}
                                                    {row.submoduleCode === "EXPECTED_WEIGHT_GAIN" ? row.submoduleName : null}
                                                    {row.submoduleCode === "EXPECTED_HEIGHT_GAIN" ? row.submoduleName : null}
                                                    {row.submoduleCode === "WHAT_TO_BE_GIVEN" ? row.submoduleName : null}
                                                    {row.submoduleCode === "WHAT_SHOULD_NOT_BE_GIVEN" ? row.submoduleName : null}
                                                    {row.submoduleCode === "FOOD_RESTRICTED" ? row.submoduleName : null}
                                                    {row.submoduleCode === "ALERT" ? row.submoduleName : null}
                                                    {row.submoduleCode === "SPECIAL_CONCERN" ? row.submoduleName : null}
                                                </Typography>
                                            </Box>
                                        </Card>
                                    </Grid>
                                )
                            ))}
                    </Grid>

                    <Box sx={{ pt: '10px', width: '100%' }} className="disclaimer-box">
                        <Alert severity="warning">
                            <AlertTitle>Disclaimer:</AlertTitle>
                            We are just giving you the rough outline regarding the health, growth and development of your child just to sensitize you to seek the medical care earlier if needed. Every child has to be examined individually by a registered medical practitioner before confirmation of status of health, growth and development of any child and A Doctor is the Final authority to decide regarding further management and intervention needed as felt necessary by the Doctor.
                            <Box className="source-link-ref-box-title" >Source: <span className="source-link-ref" onClick={() => setOpenEmergencyConditionDialog(true)}>https://iapindia.org/guidelines-for-parents/</span></Box>
                        </Alert>
                    </Box>
                </Box>



                <Dialog
                    fullScreen
                    open={openEmergencyConditionDialog}
                    onClose={handleCloseEmergencyConditionDialog}
                    className="custom-popup-header">
                    <React.Fragment >
                        <AppBar sx={{ position: 'relative' }} color='primary'>
                            <Toolbar className='custom-popup-header' >
                                <Box className='header-empty-box'></Box>
                                <Box className='close-box'>
                                    <IconButton
                                        edge="start"
                                        color="inherit"
                                        onClick={handleCloseEmergencyConditionDialog}
                                        aria-label="close"
                                        className='close-button'>
                                        <CloseIcon />
                                    </IconButton>
                                </Box>
                            </Toolbar>
                        </AppBar>
                        <DialogContent style={{ padding: 0 }}>
                            <iframe width="100%" height="100%" src="https://iapindia.org/guidelines-for-parents/" title="Pediaverse" ></iframe>
                        </DialogContent>
                    </React.Fragment>
                </Dialog>
            </Box>
        </Fade>
    );
}