import * as React from "react";
import { useNavigate } from "react-router-dom";
import "./PrivacyAndPolicy.scss"
import { Box, IconButton, Typography } from "@mui/material";
import backIcon from "../../assets/icons/back.svg";

export default function PrivacyAndPolicy(props: any) {
    const navigate = useNavigate();

    function backClick() {
        navigate('/about-us');
    }

    function openTerms() {
        navigate('/terms-condition');
    }

    function openDeclaimer() {
        navigate('/declaimer');
    }

    return (
        <Box className='privacy-policy-main-box'>
            <Box className='head-box'>
                <Box className="back-box">
                    <IconButton
                        aria-label="back"
                        className="back-icon"
                        disableRipple
                        onClick={() => backClick()}>
                        <img src={backIcon} alt="logo" />
                    </IconButton>
                </Box>
                <Box className="title-box">
                    {/* <Typography className="title-text">Privacy policy</Typography> */}
                </Box>
                <Box className="profile-box">
                    <></>
                </Box>
            </Box>
            <Box className='body-box'>
                <Box className='body-head'>
                    {/* <h1>Terms of Service</h1> */}
                    <Typography variant="h4">Privacy Policy</Typography>
                </Box>
                <Box>
                    Effective from – January 01, 2024
                </Box>
                <Box>
                    <p>The privacy policy is based on all the products/services that are being provided and dealt with by
                        PEDIAVERSE SOLUTIONS PRIVATE LIMITED to their existing customers. However, the same shall also be applicable for any and/or
                        all the products/services that are provided to the customers of PEDIAVERSE SOLUTIONS PRIVATE LIMITED in the future based on
                        the development, software updates, market trends, etc.</p>

                    <p>You are therefore required to go through all the terms and conditions as mentioned hereunder
                        based on which you may make your decision to continue or discontinue your venture with
                        PEDIAVERSE SOLUTIONS PRIVATE LIMITED. Upon acceptance of the said document it shall be understood by PEDIAVERSE SOLUTIONS PRIVATE LIMITED that you
                        have unconditionally accepted any and/or all the terms and conditions as mentioned hereunder and
                        that you have and shall not raise any contradiction, legal issues, questions, queries, etc. about the
                        same for all points of time in the future and that PEDIAVERSE SOLUTIONS PRIVATE LIMITED shall not be liable under any set of
                        circumstances either to you and/or your subordinates, sister companies, third parties associated
                        with your, either directly and/or indirectly for any amounts including any charges, fees, payments,
                        duties, costs, etc. Further, it must be clearly noted, understood and accepted by you that upon
                        breach of any of the terms and conditions as mentioned hereunder PEDIAVERSE SOLUTIONS PRIVATE LIMITED shall have any
                        and/or all the legal right(s) to be initiated against you personally or your organization and/or any
                        other person(s) either associated and/or connected with you either directly and/or indirectly and
                        that such persons shall be jointly and severally be liable for the costs and consequences arising out
                        of such legal actions which may be in the form of Civil and/or Criminal remedies including the costs,
                        expenses as well as lawyer’s fees; which may be clearly understood by you.</p>

                    <p>The terms and conditions as mentioned as follows:</p>

                    <Box id="el1" >
                        <h4>A. BASIC TERMS</h4>
                    </Box>
                    <p>It will be your responsibility to ensure the security and confidentiality of usernames and passwords
                        which will be used to access this website. Any breach, compromise or unauthorised use should be
                        immediately notified to PEDIAVERSE SOLUTIONS PRIVATE LIMITED.</p>

                    <p>The security or integrity of the computing system, network, and the website of PEDIAVERSE SOLUTIONS PRIVATE LIMITED shall not
                        be compromised in any way by you as well as the organisation in which you are associated.</p>

                    <p>Must not use any form of abuse over the Website and/or Applications or other software products of
                        PEDIAVERSE SOLUTIONS PRIVATE LIMITED in any form or way that will adversely impact the functionality of the website or the
                        ability of any user to use the website of PEDIAVERSE SOLUTIONS PRIVATE LIMITED. No kind of any derogatory remarks shall be
                        made by you as mentioned above.</p>

                    <p>Any type of activity resulting in unauthorized access to the computer system on server of the
                        website or to any materials other than those to which access or permission has been granted will
                        amount to a violation or a breach of the policy.</p>

                    <p>Any type of act resulting in transmission of files, contents that may be offensive, materials, data in
                        violation of any laws of the land (included materials protected by copyright or trade secrets which
                        restricts the right to use it) on the website, damaging any other person’s computing devices or
                        software will be amount to a violation or a breach of the policy.</p>

                    <p>Any act resulting in modifying, copying, adapting, editing, reproducing, disassembling, decompiling,
                        or reverse engineering the website or the applications of the Company, except as is strictly
                        necessary to use for formal operation will be regarded as a violation or a breach of the policy.</p>

                    <p>The website of the Company, i.e. PEDIAVERSE SOLUTIONS PRIVATE LIMITED; should be accessed manually. Any act resulting into
                        usage of automated agents, software other than the search engine to view the website is forbidden</p>

                    <p>If you use any communication tools available through the Website of the Company such as Blogs,
                        support portals, messaging centre, you unconditionally agree to use such communication tools for
                        lawful and legitimate purposes only. You must not use these tools to post offensive content, any
                        material in violation of intellectual property rights or any offers of goods or services for sale.</p>

                    <Box id="el2">
                        <h4>B. INTELLECTUAL PROPERTY RIGHTS</h4>
                    </Box>
                    <p>The Intellectual Property Rights in all the constituents comprising the website including the
                        structure, design &amp;layout including graphical images along with the applied source codes vests with
                        PEDIAVERSE SOLUTIONS PRIVATE LIMITED.</p>

                    <p>It is recognized that all the Intellectual Property Rights of PEDIAVERSE SOLUTIONS PRIVATE LIMITED portal, the website as well as
                        all the computer aided or mobile aided applications; shall vest with PEDIAVERSE SOLUTIONS PRIVATE LIMITED.</p>

                    <p>No kind of any royalty-free, perpetual, irrevocable, non-exclusive right and license is granted to any
                        of the Customers to use, reproduce, modify, adapt, publish, translate, create, derivative work from,
                        distribute, communicate, perform and display the content, in whole or in part, worldwide and to
                        incorporate it in other works in any form, media or technology knows or later developed for full
                        term of any rights that may exist by submitting any blogs, messages boards or forums of
                        PEDIAVERSE SOLUTIONS PRIVATE LIMITED.</p>

                    <p>You shall not provide any content to PEDIAVERSE SOLUTIONS PRIVATE LIMITED that does not belong to you or if you do not have
                        the intellectual property rights for that content.</p>

                    <p>Building or copying or encrypting any of the data PEDIAVERSE SOLUTIONS PRIVATE LIMITED will be a breach of our intellectual
                        property.</p>

                    <Box id="el3">
                        <h4>C. DISCLAIMERS AND WARRANTIES</h4>
                    </Box>
                    <p>You fully acknowledge to the Company the following:</p>
                    <p>That you are authorised to access and use the Website.</p>
                    <p>That in case you are using the website on behalf of any organization, you would still be liable for
                        your actions along with the organization for any breach of terms.</p>
                    <p>The provision of, access to, and use of, the Website is on an “as is, where is” basis and at your own
                        risk.</p>
                    <p>t is not guaranteed that the website or the applications of PEDIAVERSE SOLUTIONS PRIVATE LIMITED will be error free and that
                        PEDIAVERSE SOLUTIONS PRIVATE LIMITED will not be responsible for any disruptions caused due to any applications, gadgets
                        including internet services used by yourself to access the website. No kind of damages and/or losses
                        shall be cast upon PEDIAVERSE SOLUTIONS PRIVATE LIMITED based on the non-performance of any of their websites and/or
                        applications for any reasons of whatsoever nature.</p>
                    <p>It shall be your sole responsibility to determine that the Website or the applications of PEDIAVERSE SOLUTIONS PRIVATE LIMITED
                        meets the needs of your family and health. No kind of repayment and/or refund shall be made by
                        PEDIAVERSE under any set of circumstances, reasons, technical hitches, etc. of whatsoever nature
                        which may be claimed by you.</p>
                    <p>Without limiting the foregoing, PEDIAVERSE SOLUTIONS PRIVATE LIMITED does not warrant that their website as well as their
                        applications will meet your requirements or that it will be suitable for your purposes.</p>
                    <p>To avoid doubt, all implied conditions or warranties are excluded in so far as is permitted by law
                        including, without limitation, warranties of merchantability, fitness for purpose, title, and non-
                        infringement.</p>

                    <Box id="el4" >
                        <h4>D. PERFORMANCE OF THE WEBSITE AND APPLICATIONS</h4>
                    </Box>
                    <p>PEDIAVERSE SOLUTIONS PRIVATE LIMITED denies all liability for the timely operation of the Website or any of its applications
                        when used within an Internet environment, where you or a third party is providing the computer
                        equipment upon which the product is to reside or depend upon for any part of its functionality.</p>
                    <p>By accepting these Terms, you confirm your understanding that the timely operation of the Internet
                        and the World Wide Web is governed by constraints beyond the control of PEDIAVERSE SOLUTIONS PRIVATE LIMITED and that it is
                        not liable for the perceived slow operation of the Website or any of its applications.</p>
                    <p>The client must maintain a copy of all their data inputted into the website or the applications of
                        PEDIAVERSE SOLUTIONS PRIVATE LIMITED who although shall take a regular backup of all the data but however does not
                        guarantee that there will no loss of data. PEDIAVERSE SOLUTIONS PRIVATE LIMITED excludes liability for any loss of data no matter
                        how it was caused.</p>
                    <p>To the maximum extent permitted by law, PEDIAVERSE SOLUTIONS PRIVATE LIMITED excludes all liability and responsibility to you
                        (or any other person) in contract, tort (including negligence), or otherwise, for any loss (including
                        loss of information, Data, profits, and savings) or damage resulting, directly or indirectly, from any
                        use of, or reliance on, the Service or Website or applications.</p>

                    <Box id="el5" >
                        <h4>E. THIRD PARTY LINKS</h4>
                    </Box>
                    <p>This Privacy Policy applies only to the use and disclosure of Personal Data that is collected by
                        PEDIAVERSE SOLUTIONS PRIVATE LIMITED.</p>
                    <p>Provision of a link to any other website or location is for your convenience and does not signify our
                        endorsement of such other website or location or its contents.</p>
                    <p>When you click on such a link, you will leave the Services of PEDIAVERSE SOLUTIONS PRIVATE LIMITED available on whether its
                        website and/or its applications and would go to another site. During this process, a third party may
                        collect Personal Data from you. PEDIAVERSE SOLUTIONS PRIVATE LIMITED has no control over, dose not review, does not endorse,
                        and cannot be responsible for, these outside websites or their content.</p>
                    <p>You may thus be fully aware that the terms of this Privacy Policy do not apply to these outside
                        websites or content, or to any collection of data after you click on a link to a third party. In case of
                        submission of any Personal Data by you to any of those sites, your information is governed by their
                        privacy policies.</p>

                    <Box id="el6" >
                        <h4>F. COOKIE POLICY AND USE OF TRACKING</h4>
                    </Box>
                    <p>Upon any interaction over the Website or the Applications of PEDIAVERSE SOLUTIONS PRIVATE LIMITED, an effort is made for
                        accessing and using the same in a simple manner by PEDIAVERSE SOLUTIONS PRIVATE LIMITED so that the contents mentioned
                        therein are easily understood by the Client/Customer.</p>
                    <p>PEDIAVERSE SOLUTIONS PRIVATE LIMITED as well as other such organizations use industry standard identifiers, such as cookies or
                        other similar technologies wherein the term cookies, web beacons, flash cookies and pixels would
                        be collectively referred as “cookies”, “tracking technology” or “identifiers” in this policy. By using
                        our Services, you are unconditionally agreeing that PEDIAVERSE SOLUTIONS PRIVATE LIMITED can use cookies and other tracking
                        technologies described in this Cookie Policy.</p>

                    <Box id="el7" >
                        <h4>G. NOTICE FOR TERMINATION AND REMEDIES</h4>
                    </Box>
                    <p>PEDIAVERSE SOLUTIONS PRIVATE LIMITED reserves the right to cancel your contract or restrict your access to any of the websites
                        as well as applications of PEDIAVERSE SOLUTIONS PRIVATE LIMITED without any prior notice in any unlikely event of a breach of
                        this contract.</p>
                    <p>In case of any legal dispute PEDIAVERSE SOLUTIONS PRIVATE LIMITED shall be entitled to share your user details, data, passwords,
                        identity and/or any other information, if requested by the court or any other law enforcement
                        authorities or agencies.</p>
                    <p>Your data will be retained on our database for period of 90 days from the expiry date of your
                        subscription. After this time period all your data will be purged from the database of PEDIAVERSE SOLUTIONS PRIVATE LIMITED.</p>
                    <p>Noncompliance with any of the terms and conditions as mentioned hereinabove shall lead to an
                        immediate termination of the services from either of the parties to the other. However, in case
                        where an error and/or dispute can be resolved, a prior intimation for the same shall be sent by the
                        party affected to the other who shall try to resolve the same at the immediate and earliest possible
                        date and to the best of their abilities. Such intimation can be given by the party affected to the other
                        over the email and/or through a registered post only. No kind of intimation over any of the
                        communicating applications shall be valid.</p>
                    <p>If you are not satisfied with the Services of PEDIAVERSE SOLUTIONS PRIVATE LIMITED, your sole and exclusive remedy is to
                        terminate these Terms.</p>

                    <Box id="el8" >
                        <h4>H. WAIVER AND ENFORCEABILITY</h4>
                    </Box>
                    <p>Any failure or delay by PEDIAVERSE SOLUTIONS PRIVATE LIMITED to enforce any of the terms or to exercise any right under the
                        terms will not be considered as a waiver to any extent of any other rights as per the Law that are
                        available to PEDIAVERSE SOLUTIONS PRIVATE LIMITED.</p>
                    <p>If either party waives any breach of these terms in entire agreement, this will not constitute a
                        waiver of any other breach. No waiver will be effective unless made in writing.</p>

                    <Box id="el9" >
                        <h4>I. USAGE OF PERSONAL DATA</h4>
                    </Box>
                    <p>The data used, shared, transferred, or downloaded by the Client shall be used by PEDIAVERSE SOLUTIONS PRIVATE LIMITED in the
                        following manner:</p>
                    <p>For creating your account on our network and securing the same and to recognise your identity.</p>
                    <p>For administering and improving the services provided by PEDIAVERSE SOLUTIONS PRIVATE LIMITED including updates,
                        advertisements, email communications, chat and its notifications, security and support, payment
                        gateways, resolution of issues, bugs, errors, interactive interface, surveys, events or promotions,
                        data analysis, notices, changes as well as updating in the terms of the Policy legal processes,
                        discontinuation of services, etc..</p>
                    <p>For prevention of any unlawful, unethical, illegally actionable and/or fraudulent activity.</p>
                    <p>For monitoring and enforcement of compliance with the applicable Terms of mentioned in the Policy
                        document.</p>
                    <p>The feedback provided by you would be stored, analysed, discussed, and used for our internal
                        administration in order to further assist you in providing our services through our website as well as
                        applications.</p>
                    <p>Any information, including your personal data, which you specifically elect to make publicly available
                        will be available to other Members or the public. If you remove information that you have made
                        public, copies/data may remain viewable in cached and archived pages of PEDIAVERSE SOLUTIONS PRIVATE LIMITED, or if other
                        Members have copied or saved that information.</p>
                    <p>If you no longer wish to receive these types of communications, you turn them off at the device
                        level. Thus, in order to receive proper notifications, we will need to collect certain information about
                        your device such as operating system and user identification information.</p>
                    <p>The data provided by you may be changed and/or altered and/or edited as per your requirement as
                        and when required by you. However, a specific request shall be made by you for the same. Further,
                        you may note that we may be permitted or required (by law or otherwise) to keep your
                        information/data intact and not delete or change it (or to keep this information for a certain time, in
                        which case we will comply with your deletion request only after we have fulfilled such
                        requirements). If you request deletion, subject to our rights to retain the Personal Data as set out in
                        this Privacy Policy, we will respond to your request within 1 month or more subject to any legal
                        requirement(s). We will retain your information for as long as your account is active or as needed to
                        provide you services. We will retain and use your information as necessary to comply with our legal
                        obligations, resolve disputes and enforce our agreements.</p>

                    <Box id="el10" >
                        <h4>J. BLOG / FORUM</h4>
                    </Box>
                    <p>The Website as well as applications of PEDIAVERSE SOLUTIONS PRIVATE LIMITED may offer publicly accessible blogs or community
                        forums.</p>
                    <p>You should be aware that any information you provide in these areas may be read, collected, and
                        used by others who access them, as well as by our third-party service providers such as Open AI,
                        L.L.C., to ensure compliance with our Acceptable Use Policy and Content Standards through our
                        Enforcement Policy.</p>
                    <p>To request removal of your Personal Data from our blog or community forum, you would have to
                        raise a specific request for the same to PEDIAVERSE SOLUTIONS PRIVATE LIMITED.</p>
                    <p>In some cases, PEDIAVERSE SOLUTIONS PRIVATE LIMITED may not be able to remove your Personal Data, which shall be
                        communicated to you along with reasons.</p>

                    <Box id="el11" >
                        <h4>K. SECURITY OF YOUR APPLICATION AND PERSONAL DATA</h4>
                    </Box>
                    <p>We are committed to protect and secure your Personal Data.</p>

                    <p>PEDIAVERSE SOLUTIONS PRIVATE LIMITED uses varied industry-standard security technologies and procedures to help protect
                        your data from unauthorized access, use, or disclosure. Therefore, whenever any sensitive
                        information in entered and/or used by you (such as a credit card number, debit card details, identity
                        details including pictures, photos, etc.) the same is encrypted using various technologies.</p>

                    <p>You are thus required to enter your password and/or other sign-on mechanism to access your
                        account information. Such details should not be shared and/or disclosed by you to any third parties
                        or with unauthorized people.</p>

                    <p>However, you are well aware that, PEDIAVERSE SOLUTIONS PRIVATE LIMITED would not be able to fully eliminate security risks
                        associated with Personal Data.</p>

                    <Box id="el12" >
                        <h4>L. OVERSEAS DISCLOSURE OF YOUR DATA</h4>
                    </Box>
                    <p>Unless and until it has been specifically stated by you, we may host, transfer, and process data,
                        including personal data in other countries through our website or our applications.</p>
                    <p>These countries may have data protection laws that are different from those of your country of
                        residence which is well within your knowledge, and you accept the same.</p>
                    <p>PEDIAVERSE SOLUTIONS PRIVATE LIMITED would take appropriate measures to ensure such transfers are following applicable laws
                        of those countries.</p>

                    <Box id="el13" >
                        <h4>M. DATA RETENTION</h4>
                    </Box>
                    <p>Other than in aggregated or de-identified form as permitted under the terms and except as required
                        by applicable law, we will delete or otherwise destroy your personal data as soon as practicably
                        possible following your termination or cancellation of your use of our services.</p>
                    <p>PEDIAVERSE SOLUTIONS PRIVATE LIMITED will retain and use this information as necessary to comply with our legal obligations,
                        resolve disputes, and enforce our agreements.</p>
                    <p>PEDIAVERSE SOLUTIONS PRIVATE LIMITED ensures that your personal data disposed of is de-identified or destroyed in a secure
                        fashion.</p>

                    <Box id="el14" >
                        <h4>N. JURISDICTION AND GOVERNING LAWS</h4>
                    </Box>
                    <p>The Hon’ble Courts located at Ahmedabad shall have the jurisdiction on any and/or all matters
                        arising out of the dispute(s) concerning to the adherence to the terms and conditions and the
                        breach thereof.</p>
                    <p>The terms and conditions as mentioned under this Privacy Policy document shall be construed and
                        enforced according to the laws prevailing in India including the amendments made thereto.</p>

                    <Box id="el15" >
                        <h4>O. PERSONAL INFORMATION OF MINORS</h4>
                    </Box>
                    <p>The services provided by PEDIAVERSE SOLUTIONS PRIVATE LIMITED over their website as well as applications are not intended for
                        children or minors under the age of 18 years old under any set of circumstances.</p>
                    <p>No kind of information and/or data is thus either collected, stored and/or sold by PEDIAVERSE SOLUTIONS PRIVATE LIMITED about
                        minors under the age of 18.</p>
                    <p>In case you believe that PEDIAVERSE SOLUTIONS PRIVATE LIMITED has unintentionally received personal information/data about a
                        minor under the age of 18 years old, you are requested to immediately contact us through any
                        means of communication so that corrective actions may be initiated by PEDIAVERSE SOLUTIONS PRIVATE LIMITED at the earliest
                        date possible.</p>

                    <Box id="el16" >
                        <h4>P. SALE OF PERSONAL INFORMATION</h4>
                    </Box>
                    <p>PEDIAVERSE SOLUTIONS PRIVATE LIMITED does not engage in any activity either directly and/or indirectly wherein your personal
                        information is sold to third parties against any monetary amounts.</p>
                    <p>The same applies to the personal information of minors under the age of 16 or 18 as the case may
                        be; if any.</p>

                    <Box id="el17" >
                        <h4>Q. QUERIES, CONCERNS, AND COMPLAINTS</h4>
                    </Box>
                    <p>We value your queries, concerns, feedback, and complaints.</p>
                    <p>In case of any queries, concerns, or complaints about the manner in which we have collected,
                        stored, used, or disclosed your personal information, you are requested to contact our concerned
                        Administrative Head over the email.</p>
                    <p>Your complaint shall be treated confidentially.</p>
                    <p>Any and/or all ways for resolving your complaint shall be discussed so as to provide you with the
                        speediest remedy possible by PEDIAVERSE SOLUTIONS PRIVATE LIMITED.</p>
                    <p>PEDIAVERSE SOLUTIONS PRIVATE LIMITED will ensure that your complaint is resolved within a reasonable time (and in any event
                        within the time required by applicable law).</p>
                </Box>
            </Box>

            <Box className="bottom-part">
                <Typography className="left-part">Copyrights &copy; {new Date().getFullYear()} - PEDIAVERSE SOLUTIONS PRIVATE LIMITED</Typography>
                <Typography className="right-part" onClick={() => openTerms()}>Terms of Service &nbsp; <span>Terms and condition</span></Typography>
                <Typography className="right-part" onClick={() => openDeclaimer()}><span>Declaimer</span></Typography>
            </Box>
        </Box >
    );
}
