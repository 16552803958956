import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import SignIn from "../pages/SignIn/SignIn";
import OtpVerification from "../pages/OtpVerification/OtpVerification";
import StageSelection from "../pages/StageSelection/StageSelection";
import Parent from "../pages/Parent/Parent";
import MaternalDetails from "../pages/MaternalDetails/MaternalDetails";
import Dashboard from "../pages/Dashboard/Dashboard";
import ChildDetail from "../pages/ChildDetail/ChildDetail";
import { PreferencesContext } from "../PreferenceContext";
import SignUp from "../pages/SignUp/SignUp";
import Spouse from "../pages/Spouse/Spouse";
import PhysicalGrowth from "../pages/PhysicalGrowth/PhysicalGrowth";
import Height from "../pages/Height/Height";
import Weight from "../pages/Weight/Weight";
import HeadCircumference from "../pages/HeadCircumference/HeadCircumference";
import MidArmCircumference from "../pages/MidArmCircumference/MidArmCircumference";
import BMI from "../pages/BMI/BMI";
import CommonDiseases from "../pages/CommonDiseases/CommonDiseases";
import MilestoneDevelopment from "../pages/MilestoneDevelopment/MilestoneDevelopment";
import MilestoneDevelopmentQuestions from "../pages/MilestoneDevelopmentQuestions/MilestoneDevelopmentQuestions";
import Vaccination from "../pages/Vaccination/Vaccination";
import VaccinationDetail from "../pages/VaccinationDetail/VaccinationDetail";
import FamilyMemberList from "../pages/FamilyMember/FamilyMemberList";
import NewBornCareList from "../pages/NewBornCareList/NewBornCareList";
import NewBornCare from "../components/NewBornCare/NewBornCare";
import DietAndNutritionList from "../pages/DietAndNutritionList/DietAndNutritionList";
import DietAndNutrition from "../components/DietAndNutrition/DietAndNutrition";
import MedicalHistory from "../pages/MedicalHistory/MedicalHistory";
import SymptomsCategory from "../components/SymptomsCategory/SymptomsCategory";
import SymptomsList from "../components/SymptomsList/SymptomsList";
import SymptomsQuestions from "../pages/SymptomsQuestions/SymptomsQuestions";
import CustomPopup from "../components/CustomPopup/CustomPopup";
import Declaimer from "../pages/Declaimer/Declaimer";
import PrivacyAndPolicy from "../pages/PrivacyAndPolicy/PrivacyAndPolicy";
import TermsAndConditions from "../pages/TermsAndConditions/TermsAndConditions";
import AboutUs from "../components/AboutUs/AboutUs";
import FetalGrowth from "../pages/FetalGrowth/FetalGrowth";
import Trimester from "../pages/Trimester/Trimester";
import PregnancyOutcome from "../pages/PregnancyOutcome/PregnancyOutcome";
import MidParentalHeight from "../pages/MidParentalHeight/MidParentalHeight";
import NotificationSend from "../pages/NotificationSend/NotificationSend";
import Notifications from "../pages/Notifications/Notifications";
import Settings from "../components/Settings/Settings";
import Languages from "../pages/Languages/Languages";

export default function RootNavigation({ colorScheme }: { colorScheme: any }) {

    return (
        <RootNavigator colorScheme={colorScheme} />
    );
}

function AuthRoute(props: any) {
    const { userContext, updateUserContext } = React.useContext(PreferencesContext);
    // if (userContext.userId > 0) {
    if (userContext.mobileNumber !== "") {
        return props.children;
    } else {
        return <Navigate to="/" replace />;
    }

}

function RootNavigator({ colorScheme }: { colorScheme: any }) {
    return (
        <Routes>
            <Route element={<SignIn />} path="/" />
            <Route element={<SignUp />} path="/sign-up" />
            <Route element={<OtpVerification />} path="/otp-verification" />
            <Route element={<StageSelection />} path="/stage-selection" />
            <Route element={
                <AuthRoute>
                    <Parent />
                </AuthRoute>
            } path="/parent" />
            <Route element={
                <AuthRoute>
                    <Spouse />
                </AuthRoute>
            } path="/spouse" />
            <Route element={
                <AuthRoute>
                    <MaternalDetails />
                </AuthRoute>
            } path="/maternal-detail" />
            <Route element={
                <AuthRoute>
                    <ChildDetail />
                </ AuthRoute>
            } path="/child-detail" />
            <Route element={
                <AuthRoute>
                    <Dashboard />
                </AuthRoute>
            } path="/dashboard" />
            <Route element={
                <AuthRoute>
                    <FetalGrowth />
                </AuthRoute>
            } path="/fetal-growth" />
            <Route element={
                <AuthRoute>
                    <PregnancyOutcome />
                </AuthRoute>
            } path="/pregnancy-outcome" />
            <Route element={
                <AuthRoute>
                    <Trimester />
                </AuthRoute>
            } path="/trimester" />
            <Route element={
                <AuthRoute>
                    <PhysicalGrowth />
                </AuthRoute>
            } path="/physical-growth" />

            <Route element={
                <AuthRoute>
                    <MidParentalHeight />
                </AuthRoute>
            } path="/mid-parental-height" />
            <Route element={
                <AuthRoute>
                    <Height />
                </AuthRoute>
            } path="/height" />
            <Route element={
                <AuthRoute>
                    <Weight />
                </AuthRoute>
            } path="/weight" />
            <Route element={
                <AuthRoute>
                    <HeadCircumference />
                </AuthRoute>
            } path="/head-circumference" />
            <Route element={
                <AuthRoute>
                    <MidArmCircumference />
                </AuthRoute>
            } path="/mid-arm-circumferencee" />
            <Route element={
                <AuthRoute>
                    <BMI />
                </AuthRoute>
            } path="/bmi" />
            <Route element={
                <AuthRoute>
                    <NewBornCareList />
                </AuthRoute>
            } path="/born-care" />
            <Route element={
                <AuthRoute>
                    <DietAndNutritionList />
                </AuthRoute>
            } path="/diet-nutrition" />
            <Route element={
                <AuthRoute>
                    <NewBornCare />
                </AuthRoute>
            } path="/temperature" />
            <Route element={
                <AuthRoute>
                    <DietAndNutrition />
                </AuthRoute>
            } path="/food-item" />
            <Route element={
                <AuthRoute>
                    <DietAndNutrition />
                </AuthRoute>
            } path="/expected-weight-gain" />
            <Route element={
                <AuthRoute>
                    <DietAndNutrition />
                </AuthRoute>
            } path="/expected-height-gain" />
            <Route element={
                <AuthRoute>
                    <DietAndNutrition />
                </AuthRoute>
            } path="/what-to-be-given" />
            <Route element={
                <AuthRoute>
                    <DietAndNutrition />
                </AuthRoute>
            } path="/what-should-not-be-given" />
            <Route element={
                <AuthRoute>
                    <DietAndNutrition />
                </AuthRoute>
            } path="/food-restricted" />
            <Route element={
                <AuthRoute>
                    <DietAndNutrition />
                </AuthRoute>
            } path="/alert" />
            <Route element={
                <AuthRoute>
                    <DietAndNutrition />
                </AuthRoute>
            } path="/special-concern" />
            <Route element={
                <AuthRoute>
                    <NewBornCare />
                </AuthRoute>
            } path="/care-of-skin" />
            <Route element={
                <AuthRoute>
                    <NewBornCare />
                </AuthRoute>
            } path="/feeding" />
            <Route element={
                <AuthRoute>
                    <NewBornCare />
                </AuthRoute>
            } path="/immunity" />
            <Route element={
                <AuthRoute>
                    <NewBornCare />
                </AuthRoute>
            } path="/special-senses" />
            <Route element={
                <AuthRoute>
                    <NewBornCare />
                </AuthRoute>
            } path="/care-of-premature" />
            <Route element={
                <AuthRoute>
                    <NewBornCare />
                </AuthRoute>
            } path="/normal-findings-newborns" />
            <Route element={
                <AuthRoute>
                    <NewBornCare />
                </AuthRoute>
            } path="/red-alert-signs-newborn" />
            <Route element={
                <AuthRoute>
                    <NewBornCare />
                </AuthRoute>
            } path="/safe-practices" />
            <Route element={
                <AuthRoute>
                    <MilestoneDevelopment />
                </AuthRoute>
            } path="/milestone-development" />
            <Route element={
                <AuthRoute>
                    <MilestoneDevelopmentQuestions />
                </AuthRoute>
            } path="/milestone-questions" />
            <Route element={<AuthRoute>
                <Vaccination />
            </AuthRoute>} path="/vaccination" />
            <Route element={<AuthRoute>
                <VaccinationDetail />
            </AuthRoute>} path="/vaccination-detail" />

            <Route element={
                <AuthRoute>
                    <CommonDiseases />
                </AuthRoute>
            } path="/common-diseases" />
            <Route element={
                <AuthRoute>
                    <MedicalHistory />
                </AuthRoute>
            } path="/medical-history" />

            <Route element={
                <AuthRoute>
                    <SymptomsCategory />
                </AuthRoute>
            } path="/symptoms" />

            <Route element={
                <AuthRoute>
                    <SymptomsList />
                </AuthRoute>
            } path="symptoms-list" />

            <Route element={
                <AuthRoute>
                    <SymptomsQuestions />
                </AuthRoute>
            } path="symptoms-questions" />

            <Route element={
                <AuthRoute>
                    <FamilyMemberList />
                </AuthRoute>
            } path="/member-list" />

            <Route element={
                <AuthRoute>
                    <AboutUs />
                </AuthRoute>
            } path="/about-us" />
            <Route element={
                <AuthRoute>
                    <Settings />
                </AuthRoute>
            } path="/settings" />
            <Route element={
                <AuthRoute>
                    <Languages />
                </AuthRoute>
            } path="/language" />
            <Route element={
                <AuthRoute>
                    <Declaimer />
                </AuthRoute>
            } path="/declaimer" />
            <Route element={
                <TermsAndConditions />
            } path="/terms-condition" />
            <Route element={
                <PrivacyAndPolicy />
            } path="/privacy-policy" />

            <Route
                element={
                    <AuthRoute>
                        <NotificationSend />
                    </AuthRoute>
                } path="/notification-send" />



            <Route
                element={
                    <AuthRoute>
                        <Notifications />
                    </AuthRoute>
                } path="/notifications" />

        </Routes>
    )
}