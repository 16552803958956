import React, { useContext, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './CommonDiseases.scss';
import {
    Alert,
    Avatar, Box, Card, CardContent, Dialog, DialogTitle, Grid, IconButton, Input, InputAdornment, InputBase, Paper, Skeleton, Slide,
    Table, TableBody, TableCell, TableRow, Typography, createTheme, useMediaQuery, useTheme
} from '@mui/material';
import backIcon from '../../assets/icons/back.svg';
import boyIcon from "../../assets/images/boy.svg";
import girlIcon from "../../assets/images/girl.svg";
import womanIcon from "../../assets/images/woman.svg";
import { PreferencesContext } from '../../PreferenceContext';
import SearchIcon from '@mui/icons-material/Search';
import infoIcon from '../../assets/icons/info.svg';
import { TransitionProps } from '@mui/material/transitions';
import { GetCommonDiseasesList } from '../../services/CommonDiseasesService';
import { AlertType } from '../../shared/AlertType';
import ClearIcon from "@mui/icons-material/Clear";

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const commonDiseases: any = {
    commonDiseasesCollection: [],
};
const parameters: any = {
    pageIndex: 1,
    pageSize: 1,
    sortColumn: "",
    sortDirection: "",
    searchValue: "",
};
export default function CommonDiseases() {
    // const [loading, setLoading] = useState(false);
    const [loading, setLoading] = useState(true);
    const [param, setParam] = useState({
        pageIndex: 1,
        pageSize: 1,
        sortColumn: "",
        sortDirection: "",
        searchValue: "",
    });
    const { userContext, updateUserContext } = useContext(PreferencesContext);
    const [selectedIndex, setSelectedIndex] = React.useState(1);
    const [openModal, setModal] = useState(false);
    const [dataForDescription, setDataForDescription] = useState(commonDiseases);
    const [resultData, setResultData] = useState([]);
    const [commonDiseasesInitial, setCommonDiseasesInitial] = useState(commonDiseases);
    const [page_Index, setPage_Index] = useState(1);
    const [value, setValue] = useState("");
    const [converDesciption, setConverDesciption] = useState("");
    const counter = useRef(0);
    // const [search, setNewSearch] = useState("");

    // const theme = useTheme();
    const theme = createTheme({
        breakpoints: {
            values: {
                xs: 0,
                sm: 600,
                md: 900,
                lg: 1200,
                xl: 2000,
            },
        },
    });
    const fullScreen = useMediaQuery(theme.breakpoints.down('xl'));

    const handleListItemClick = (
        event: React.MouseEvent<HTMLDivElement, MouseEvent>,
        index: number,
    ) => {
        setSelectedIndex(index);
    };

    const handleOpendescriptionModal = (props: any) => {

        setDataForDescription(props);
        setModal(true);
    }
    async function fetchCommonDiseasesData() {
        setLoading(true);
        try {
            let params = new URLSearchParams();
            // params.append("pageIndex", param.page_Index);
            // params.append("pageSize", param.pageSize);
            params.append("sortColumn", param.sortColumn);
            params.append("sortDirection", param.sortDirection);
            params.append("searchValue", param.searchValue);
            const result: any = await GetCommonDiseasesList(params);
            let commonDiseasesCollection = result.data.data.commonDiseasesCollection;
            let Collections: any = [];
            for (let i = 65; i <= 90; i++) {
                let listtitle: any = commonDiseasesCollection.filter((item) => item.commonDiseasesname[0].toString().toUpperCase().charCodeAt() === i);
                if (listtitle !== null && listtitle.length > 0) {
                    Collections.push(listtitle)
                }
            }
            setResultData(Collections);

            // var results = [];
            // if(value.length > 0){
            //   var rgxp = new RegExp("(\\S*)?("+ value +")(\\S*)?", "ig");
            //   return Collections[0][0].commonDiseasesname.replace(rgxp, (match) => "<b style=\"color: red;\">"+ match +"</b>")
            // }
            // return results;
            // console.log("ar--->",results);
        }
        catch (error) {
            if (error) {
                updateUserContext({
                    ...userContext,
                    isAlert: true,
                    alertMessage: 'Something went wrong',
                    // alertMessage: error.response.message,
                    alertType: AlertType.Error,
                });
            }
        }
        setLoading(false);
    }
    let navigate = useNavigate();
    function backClick() {
        // if (userContext.loginDetail !== null && userContext.loginDetail.userId > 0) {
        navigate("/dashboard");
        // } else {
        //     navigate("/");
        // }
    }
    const imageLoaded = () => {
        counter.current += 1;
        if (counter.current >= dataForDescription.commonDiseasesImage.length) {
            setLoading(false);
        }
    }

    function searchValue(val: any) {

        if (val === "") {
            setValue("");
        }
        // setPage_Index(1);
        setParam({ ...param, searchValue: val.toString() });
    }
    function getText(html: any) {
        var divContainer = document.createElement("div");
        divContainer.innerHTML = html;
        return divContainer.textContent || divContainer.innerText || "";
    }
    // var htmlString = dataForDescription.commonDiseasesDescription;
    // setConverDesciption( htmlString.replace(/<[^>]+>/g, ''));

    // var yourString= dataForDescription.commonDiseasesDescription;
    // setConverDesciption(getText(yourString));


    //----Search list
    // const handleSearchChange = (e) => {
    //     setNewSearch(e.target.value);
    // };
    // const filtered = !search
    //     ? resultData
    //     : resultData.filter((person:any) =>
    //         person.commonDiseasesname.toLowerCase().includes(search.toLowerCase())
    //     );
    //---------------
    // useEffect(() => {
    //     // if (userContext.loginDetail.userId > 0) {
    //     fetchCommonDiseasesData();
    //     // }
    // }, [param]);
    useEffect(() => {
        fetchCommonDiseasesData();
    }, [param]);


    return (
        <Box className="common-diseases-main-box">
            <Box className="main-head-box" >
                <Box className="head-box">
                    <Box className="back-box">
                        <IconButton aria-label="back" className='back-icon' disableRipple
                            onClick={() => backClick()}>
                            <img src={backIcon} alt='logo' />
                        </IconButton>
                    </Box>

                    <Box className="title-box">
                        <Typography className="title-text">Health Assistant</Typography>
                    </Box>
                    <Box className="profile-box">
                        <></>
                    </Box>
                </Box>

                <Box className='main-container-grid'>
                    <Paper
                        // component="form"
                        className='search-box-paper'
                    >
                        <InputBase
                            onKeyDown={(e: any) => {
                                searchValue(value);
                            }}
                            sx={{ ml: 1, flex: 1 }}
                            placeholder="Search..."
                            // value={search}
                            // onChange={handleSearchChange}
                            onChange={(e) => setValue(e.target.value)}
                            value={value}
                        />
                        <IconButton type="button" sx={{ p: '10px' }} aria-label="search">
                            <SearchIcon onClick={() => searchValue(value)} />
                        </IconButton>
                    </Paper>
                </Box>
            </Box>
            <Box className="body-box">
                <Box className='common-diseases-list'>
                    <Table aria-label="caption table">
                        <TableBody>
                            {resultData.map((item: any, index: number) => (
                                <>
                                    <Typography className='data-title' >
                                        <TableRow key={index}>
                                            {String.fromCharCode(index + 65)}
                                        </TableRow>
                                    </Typography>

                                    {item.map((item: any, ind: number) => (
                                        <TableCell onClick={() => handleOpendescriptionModal(item)} className='common-diseases-list-name' component="th" scope="row">
                                            <Typography>
                                                <TableRow key={ind}>
                                                    {item.commonDiseasesname.replace(`/${value}/`, `<b style=\"color: red;\">${value}</span>`)}
                                                    {/* {item.commonDiseasesname.replace((value) => "<b style=\"color: red;\">"+ value +"</b>")} */}

                                                </TableRow>
                                            </Typography>

                                        </TableCell>
                                    ))}

                                </>
                            ))}
                        </TableBody>
                    </Table>

                    <Dialog
                        fullScreen={fullScreen}
                        open={openModal}
                        aria-labelledby="responsive-dialog-title"
                        // ref={containerRef}
                        scroll={"paper"}
                        className='dialog-container'
                    >
                        {/* <DialogTitle
                            className="dialog-title-container"
                            sx={{ paddingBottom: "0px !important" }}> */}
                        {/* <Grid container className="head-dialog-box-container">
                                <Box className="head-box">
                                    <Box className="back-box">
                                        <IconButton aria-label="back" className='back-icon' disableRipple
                                            onClick={() => setModal(false)}>
                                            <img src={backIcon} alt='logo' />
                                        </IconButton>
                                    </Box>

                                    <Box className="title-box">
                                    </Box>
                                    <Box className="profile-box">

                                    </Box>
                                </Box>
                                <Box className="instruction-box">
                                    <Grid item xs={12} sm={12} md={12} lg={12} className='item-grid'>
                                        <Typography className="title-text">{dataForDescription.commonDiseasesname}</Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} lg={12} className='item-grid'>
                                        <Card elevation={0} className="card-box">
                                            <img src={dataForDescription.commonDiseasesImage} onLoad={imageLoaded} alt="logo" />
                                        </Card>
                                    </Grid>
                                    <Grid container className='instruction-container-grid'>
                                        <Grid item xs={12}>
                                            <Paper elevation={6} />
                                            <Typography
                                                variant="body1"
                                                dangerouslySetInnerHTML={{
                                                    __html:
                                                        dataForDescription.commonDiseasesDescription
                                                }}
                                            ></Typography>
                                            <Paper />
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Grid> */}
                        <Box className="dialog-head-box">
                            <Box className="back-box">
                                <IconButton aria-label="back" className='back-icon' disableRipple
                                    onClick={() => setModal(false)}>
                                    <img src={backIcon} alt='logo' />
                                </IconButton>
                            </Box>

                            <Box className="title-box">
                                {/* <Typography className="title-text">Common Diseases</Typography> */}
                            </Box>
                            <Box className="profile-box">
                                <></>
                            </Box>
                        </Box>
                        <Box className="dialog-body-box">
                            <Box className="title-box">
                                <Typography className="title-text">{dataForDescription.commonDiseasesname}</Typography>
                            </Box>
                            <Box className="img-box">
                                <Card elevation={0} className="card-box">
                                    <img src={dataForDescription.commonDiseasesImage} onLoad={imageLoaded} alt="logo" />
                                </Card>
                            </Box>
                            <Box className="detail-box">
                                <Typography
                                    variant="body1"
                                    dangerouslySetInnerHTML={{
                                        __html:
                                            dataForDescription.commonDiseasesDescription
                                    }}
                                ></Typography>
                            </Box>
                        </Box>
                        {/* </DialogTitle> */}
                    </Dialog>
                </Box>
            </Box>

        </Box>
    )
}