import React, { useContext, useEffect, useState } from "react";
import "./Trimester.scss";
import { PreferencesContext } from "../../PreferenceContext";
import { useLocation, useNavigate } from "react-router-dom";
import { useDrag } from "@use-gesture/react";
import { a, useSpring, config } from "@react-spring/web";
import { Backdrop, Box, Fade, IconButton, Typography, Link, Grid, FormControl, Skeleton, InputLabel, OutlinedInput, InputAdornment } from "@mui/material";
import { Form, Formik } from "formik";
import backIcon from "../../assets/icons/back.svg";
import CustomTabs from "../../components/CustomTabs/CustomTabs";
import styles from "../FetalGrowth/FetalGrowthBottomSheetStyles.module.css";
import CustomButton from "../../components/CustomButton/CustomButton";
import weightIcon from '../../assets/icons/weight.svg';
import { ageInWeeks } from "../../shared/CommonFuctions";
import { CreateFetalWeightDetail, GetFetalWeightDetailForChart, GetMemberFetalWeightDetailList } from "../../services/FetalGrowthService";
import { AlertType } from "../../shared/AlertType";
import dayjs from "dayjs";
import * as Yup from "yup";

const formInitialValues = {
    weightDetailId: 0,
    weight: "",
};

export default function Trimester() {
    const { userContext, updateUserContext } = useContext(PreferencesContext);
    const [loading, setLoading] = useState(false);
    const [fetalWeightData, setFetalWeightData] = useState(formInitialValues);
    const [submitLoading, setSubmitLoading] = useState(false);
    const [isBottomSheetOpen, setIsBottomSheetOpen] = useState(false);
    const [memberFetalWeightDetailData, setMemberFetalWeightDetailData] = useState([]);
    const [fetalWeightChartData, setFetalWeightChartData] = useState([]);
    const [ageCountData, setAgeCountData] = useState(-1);
    const [showAlert, setAlertMessage] = useState({
        isSuccess: false,
        message: "",
    });

    const location = useLocation();
    const navigate = useNavigate();

    const valildationSchema = Yup.object().shape({
        weight: Yup.number().required("Fetal weight is required."),
    });

    function backClick() {
        if (userContext.isGoToMaternalDetail === true) {
            navigate("/maternal-detail")
        } else {
            navigate("/fetal-growth");
        }
    }

    const height = 175;

    const [{ y }, api] = useSpring(() => ({ y: height }));

    const openWeightSheet = (data: any) => {
        setIsBottomSheetOpen(true);
        api.start({
            y: 0,
            immediate: false,
        });
    };

    function goToDashboard() {
        navigate('/dashboard');
    }
    const close = (velocity = 0) => {
        setIsBottomSheetOpen(false);
        api.start({
            y: height,
            immediate: false,
            config: { ...config.stiff, velocity },
        });
    };

    const bind = useDrag(
        ({
            last,
            velocity: [, vy],
            direction: [, dy],
            offset: [, oy],
            cancel,
            canceled,
        }) => {
            if (oy < -70) cancel();
            if (last) {
                oy > height * 0.5 || (vy > 0.5 && dy > 0)
                    ? close(vy)
                    : openWeightSheet({ canceled });
            }
            else api.start({ y: oy, immediate: true });
        },
        {
            from: () => [0, y.get()],
            filterTaps: true,
            bounds: { top: 0 },
            rubberband: true,
        }
    );

    const display = y.to((py) => (py < height ? "block" : "none"));

    async function fetchData() {
        setLoading(true);
        let trimester = location.state.trimester === 'SECOND_TRIMESTER' ? 2 : (location.state.trimester === 'THIRD_TRIMESTER' ? 3 : 0);
        try {
            let params = new URLSearchParams();
            params.append("trimester", trimester.toString());
            params.append("familyMemberId", userContext.memberDetail.familyMemberId.toString());
            const result: any = await GetFetalWeightDetailForChart(params);
            let chartdata = result.data.data;
            // if (userContext.memberDetail.age === 0) {
            //     setAgeCountData(0);
            // }
            setFetalWeightChartData(chartdata);

        } catch (error) {
            setLoading(false);
            if (error) {
                setAlertMessage({
                    isSuccess: true,
                    message: error as string,
                });
            }
        }
        setLoading(false);
    }

    const fetalWeightOptions = {
        color: [

            //red
            "#FF0000",

            // orange
            "#FF8000",

            // lemon
            "#FFED00",

            //  green
            "#16FF00", "#16FF00", "#16FF00",

            // lemon
            "#FFED00",

            // orange
            "#FF8000",

            // red
            "#FF0000",

            "#000000",
        ],
        grid: {
            left: "2%",
            containLabel: true,
            bottom: 20,
            top: '3%',
            right: "2%"
        },
        xAxis: [
            {
                type: "category",
                boundaryGap: false,
                // name: "Week",
                nameLocation: "middle",
                // nameGap: 15,
                data:
                    fetalWeightChartData.length !== null &&
                    fetalWeightChartData.length > 0 &&
                    fetalWeightChartData.map((row: any, index: any) => row.gestationalAge),
            },
        ],
        yAxis: [
            {
                type: "value",
                interval: location.state.trimester === 'SECOND_TRIMESTER' ? 100 : 425,
                // name: "Weight in gm",
                // nameGap: 5,
                axisLine: {
                    onZero: true,
                },
                // nameTextStyle: {
                //     padding: [0, 0, 0, 20],
                // },
                min: fetalWeightChartData.length !== null &&
                    fetalWeightChartData.length > 0 ?
                    Math.floor(fetalWeightChartData[0].p2_5) : 0
                ,
            },
        ],
        series: [
            {
                name: "P2.5",
                type: "line",
                silent: true,
                stack: 'Total',
                smooth: true,
                lineStyle: {
                    width: 0,
                },
                showSymbol: false,
                label: {
                    show: true,
                    position: "top",
                },
                areaStyle: {
                    opacity: 0.8,
                },
                emphasis: {
                    focus: "series",
                },
                data:
                    fetalWeightChartData.length !== null &&
                    fetalWeightChartData.length > 0 &&
                    fetalWeightChartData.map((row: any, index: any) => row.p2_5),
            },
            {
                name: "P5",
                type: "line",
                silent: true,
                stack: 'Total',
                smooth: true,
                lineStyle: {
                    width: 0,
                },
                showSymbol: false,
                label: {
                    show: true,
                    position: "top",
                },
                areaStyle: {
                    opacity: 0.8,
                },
                emphasis: {
                    focus: "series",
                },
                data:
                    fetalWeightChartData.length !== null &&
                    fetalWeightChartData.length > 0 &&
                    fetalWeightChartData.map((row: any, index: any) => row.p5),
            },
            {
                name: "P10",
                type: "line",
                silent: true,
                stack: 'Total',
                smooth: true,
                lineStyle: {
                    width: 0,
                },
                showSymbol: false,
                label: {
                    show: true,
                    position: "top",
                },
                areaStyle: {
                    opacity: 0.8,
                },
                emphasis: {
                    focus: "series",
                },
                data:
                    fetalWeightChartData.length !== null &&
                    fetalWeightChartData.length > 0 &&
                    fetalWeightChartData.map((row: any, index: any) => row.p10),
            },
            {
                name: "P25",
                type: "line",
                silent: true,
                stack: 'Total',
                smooth: true,
                lineStyle: {
                    width: 0,
                },
                showSymbol: false,
                label: {
                    show: true,
                    position: "top",
                },
                areaStyle: {
                    opacity: 0.8,
                },
                emphasis: {
                    focus: "series",
                },
                data:
                    fetalWeightChartData.length !== null &&
                    fetalWeightChartData.length > 0 &&
                    fetalWeightChartData.map((row: any, index: any) => row.p25),
            },
            {
                name: "P50",
                type: "line",
                silent: true,
                stack: 'Total',
                smooth: true,
                lineStyle: {
                    width: 0,
                },
                showSymbol: false,
                label: {
                    show: true,
                    position: "top",
                },
                areaStyle: {
                    opacity: 0.8,
                },
                emphasis: {
                    focus: "series",
                },
                data:
                    fetalWeightChartData.length !== null &&
                    fetalWeightChartData.length > 0 &&
                    fetalWeightChartData.map((row: any, index: any) => row.p50),
            },
            {
                name: "P75",
                type: "line",
                silent: true,
                stack: 'Total',
                smooth: true,
                lineStyle: {
                    width: 0,
                },
                showSymbol: false,
                label: {
                    show: true,
                    position: "top",
                },
                areaStyle: {
                    opacity: 0.8,
                },
                emphasis: {
                    focus: "series",
                },
                data:
                    fetalWeightChartData.length !== null &&
                    fetalWeightChartData.length > 0 &&
                    fetalWeightChartData.map((row: any, index: any) => row.p75),
            },
            {
                name: "P90",
                type: "line",
                silent: true,
                stack: 'Total',
                smooth: true,
                lineStyle: {
                    width: 0,
                },
                showSymbol: false,
                label: {
                    show: true,
                    position: "top",
                },
                areaStyle: {
                    opacity: 0.8,
                },
                emphasis: {
                    focus: "series",
                },
                data:
                    fetalWeightChartData.length !== null &&
                    fetalWeightChartData.length > 0 &&
                    fetalWeightChartData.map((row: any, index: any) => row.p90),
            },
            {
                name: "P95",
                type: "line",
                silent: true,
                stack: 'Total',
                smooth: true,
                lineStyle: {
                    width: 0,
                },
                showSymbol: false,
                label: {
                    show: true,
                    position: "top",
                },
                areaStyle: {
                    opacity: 0.8,
                },
                emphasis: {
                    focus: "series",
                },
                data:
                    fetalWeightChartData.length !== null &&
                    fetalWeightChartData.length > 0 &&
                    fetalWeightChartData.map((row: any, index: any) => row.p95),
            },
            {
                name: "P97.5",
                type: "line",
                silent: true,
                stack: 'Total',
                smooth: true,
                lineStyle: {
                    width: 0,
                },
                showSymbol: false,
                label: {
                    show: true,
                    position: "top",
                },
                areaStyle: {
                    opacity: 0.8,
                },
                emphasis: {
                    focus: "series",
                },
                data:
                    fetalWeightChartData.length !== null &&
                    fetalWeightChartData.length > 0 &&
                    fetalWeightChartData.map((row: any, index: any) => row.p97_5),
            },

            {
                name: "user",
                type: "line",
                connectNulls: true,
                lineStyle: {
                    width: 2,
                },
                label: {
                    show: true,
                    position: "top",
                },
                emphasis: {
                    focus: "series",
                },
                data:
                    fetalWeightChartData.length !== null &&
                    fetalWeightChartData.length > 0 &&
                    fetalWeightChartData.map((row: any, index: any) => row.fetalWeight),
            },
        ],
    }

    async function fetchFetalWeightData() {
        setLoading(true);
        try {
            let params = new URLSearchParams();
            params.append("familyId", userContext.loginDetail.familyId.toString());
            params.append("familyMemberId", userContext.memberDetail.familyMemberId.toString());
            const result: any = await GetMemberFetalWeightDetailList(params);
            let memberFetalWeightData = result.data.data;
            setMemberFetalWeightDetailData(memberFetalWeightData);
        } catch (error) {
            setLoading(false);
            if (error) {
                setAlertMessage({
                    isSuccess: true,
                    message: error as string,
                });
            }
        }
        setLoading(false);
    }

    async function SubmitFetalWeight(value: any) {
        let weeks = ageInWeeks(userContext.memberDetail.lastManstrualPeriodDate);
        setSubmitLoading(true);
        try {
            let fetalWeightInitData = value;
            let SaveUpdateFetalWeightData = {
                fetalWeightDetailId: fetalWeightInitData.weightDetailId,
                familyId: userContext.loginDetail.familyId,
                familyMemberId: userContext.memberDetail.familyMemberId,
                age: weeks,
                userData: fetalWeightInitData.weight,
                entryDate: dayjs(new Date()).format('MM/DD/YYYY'),
                dataManagedBy: userContext.loginDetail.userId
            };
            const resultFetalWeightSave: any = await CreateFetalWeightDetail(SaveUpdateFetalWeightData);
            setSubmitLoading(false);
            setIsBottomSheetOpen(false);
            updateUserContext({
                ...userContext,
                isAlert: true,
                alertMessage: "Fetal Weight Added",
                alertType: AlertType.Success,
            });
            fetchFetalWeightData();
        }
        catch (e: any) {
            if (e) {
                updateUserContext({
                    ...userContext,
                    isAlert: true,
                    alertMessage: e.message,
                    alertType: AlertType.Error,
                });
            }
        }
        setSubmitLoading(false);
    }
    useEffect(() => {
        if (userContext.memberDetail !== undefined && userContext.memberDetail.familyMemberId > 0) {
            fetchFetalWeightData();
        }
    }, []);

    useEffect(() => {
        if (userContext.memberDetail !== undefined && userContext.memberDetail.familyMemberId > 0) {
            if (location.state.trimester !== undefined && location.state.trimester !== null && location.state.trimester !== "") {
                fetchData();
            }
        }
    }, [memberFetalWeightDetailData]);

    return (<>
        <Backdrop
            sx={{ color: "#fff", zIndex: 4 }}
            invisible={true}
            open={isBottomSheetOpen}
            onClick={() => close()}
        ></Backdrop>
        <Fade in={true} timeout={{ enter: 500 }}>
            <Box className="trimester-main-box">
                <Box className="head-box">
                    <Box className="back-box">
                        <IconButton
                            aria-label="back"
                            className="back-icon"
                            disableRipple
                            onClick={() => backClick()}
                        >
                            <img src={backIcon} alt="logo" />
                        </IconButton>
                    </Box>
                    <Box className="title-box">
                        <Typography className="title-text">Fetal Weight</Typography>
                    </Box>
                    <Box className="add-box">
                        {memberFetalWeightDetailData.length > 0 ? (
                            <Link
                                component="button"
                                variant="body2"
                                className="add-text"
                                onClick={openWeightSheet}
                            >
                                Add
                            </Link>
                        ) : (
                            userContext.isGoToMaternalDetail === true ?
                                (<Link
                                    component="button"
                                    variant="body2"
                                    className="add-text"
                                    onClick={goToDashboard}>
                                    Skip
                                </Link>) : <></>
                        )}
                    </Box>
                </Box>
                <Box className="body-box">
                    <CustomTabs
                        icon={weightIcon}
                        option={fetalWeightOptions}
                        growthData={memberFetalWeightDetailData}
                        onClick={openWeightSheet}
                        dataLength={fetalWeightChartData.length}
                        ageCount={ageCountData}
                        yAxisLabel='Weight in gms'
                        xAxisLabel='Week'
                        page="Fetal Weight"
                    />
                </Box>

                {isBottomSheetOpen === true ? (
                    <Box
                        className="trimester-bottom-sheet-box"
                        style={{ overflow: "hidden" }}>
                        <a.div
                            className={styles.sheet}
                            {...bind()}
                            style={{ display, bottom: `calc(-100vh + ${height}px)`, y }}>
                            <Box className="top-bar"></Box>
                            <Formik
                                enableReinitialize
                                initialValues={fetalWeightData}
                                onSubmit={(values) => {
                                    SubmitFetalWeight(values);
                                }}
                                validationSchema={valildationSchema}
                            >
                                {(props) => {
                                    return (
                                        <Form>
                                            <Box className="form-box">
                                                <Grid container spacing={2} padding={2}>
                                                    <Grid item xs={12} md={12} sm={12}>
                                                        <Box className="input-box">
                                                            <FormControl fullWidth>
                                                                {loading ? (
                                                                    <Skeleton
                                                                        animation="wave"
                                                                        variant="rounded"
                                                                        width="auto"
                                                                        height={45}
                                                                    />
                                                                ) : (
                                                                    <>
                                                                        <InputLabel htmlFor="outlined-adornment-password">
                                                                            Fetal weight
                                                                        </InputLabel>
                                                                        <OutlinedInput
                                                                            id="weight"
                                                                            label="Weight"
                                                                            fullWidth
                                                                            type="number"
                                                                            endAdornment={
                                                                                <InputAdornment position="end">
                                                                                    <Typography>gms</Typography>
                                                                                </InputAdornment>
                                                                            }
                                                                            onChange={(e: any) => {
                                                                                props.setFieldValue(
                                                                                    "weight",
                                                                                    e.target.value !== null
                                                                                        ? e.target.value
                                                                                        : "",
                                                                                    true
                                                                                );
                                                                            }}
                                                                            onBlur={props.handleBlur}
                                                                            value={props.values.weight}
                                                                            inputProps={{ maxLength: 200 }}
                                                                            // variant="outlined"
                                                                            error={
                                                                                props.values.weight === "" &&
                                                                                    props.errors.weight &&
                                                                                    props.touched.weight
                                                                                    ? true
                                                                                    : false
                                                                            }
                                                                            className={
                                                                                props.values.weight === "" &&
                                                                                    props.errors.weight &&
                                                                                    props.touched.weight
                                                                                    ? "text-input validation-message"
                                                                                    : "text-input"
                                                                            }
                                                                        />

                                                                        {props.values.weight === "" &&
                                                                            props.errors.weight &&
                                                                            props.touched.weight && (
                                                                                <Typography
                                                                                    className={
                                                                                        props.values.weight === "" &&
                                                                                            props.errors.weight &&
                                                                                            props.touched.weight
                                                                                            ? "validation-message"
                                                                                            : "validation-message"
                                                                                    }>
                                                                                    {props.errors.weight}
                                                                                </Typography>
                                                                            )}
                                                                    </>
                                                                )}
                                                            </FormControl>
                                                        </Box>
                                                    </Grid>

                                                    <Grid
                                                        item
                                                        xs={12}
                                                        md={12}
                                                        sm={12}
                                                        className="button-grid">
                                                        <Box className="bottom-box">
                                                            <CustomButton
                                                                disabled={false}
                                                                class="add-button"
                                                                isLoading={submitLoading}
                                                                title="Add"
                                                            />
                                                        </Box>
                                                    </Grid>
                                                </Grid>
                                            </Box>
                                        </Form>
                                    );
                                }}
                            </Formik>
                        </a.div>
                    </Box>
                ) : (
                    <></>
                )}
            </Box>
        </Fade>
    </>)
}