import React, { useContext, useEffect, useState } from "react";
import './ChildDetail.scss';
import { Avatar, Box, Checkbox, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Fade, FormControl, Grid, IconButton, Skeleton, TextField, Typography, styled } from "@mui/material";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import Select from 'react-select';
import dayjs from "dayjs";
import info from '../../assets/icons/info.svg';
import backIcon from '../../assets/icons/back.svg';
import { useNavigate } from "react-router-dom";
import { PreferencesContext } from "../../PreferenceContext";
import { CreateParent, GetFamilyMemberDetail, GetMotherList, UpdateParent } from "../../services/ParentService";
import { AlertType } from "../../shared/AlertType";
import { MobileDatePicker } from "@mui/x-date-pickers";
import cameraIcon from '../../assets/images/camera.svg';
import CustomButton from "../../components/CustomButton/CustomButton";
import { ageInDays, getSingleSelected } from "../../shared/CommonFuctions";
import { MemberDetail } from "../../services/models/Context";
import { LoadingButton } from "@mui/lab";
import { GetModuleConfiguration } from "../../services/DashboardService";

const formInitialValues = {
    motherId: "",
    familyMemberId: 0,
    name: "",
    birthDate: "",
    gender: "",
    isSpecial: false
};

const SmallAvatar = styled(Avatar)(({ theme }) => ({
    width: 38,
    height: 38,
    cursor: 'pointer'
}));
let intData: any = {
    preview: "", raw: ""
}
export default function ChildDetail() {
    const { userContext, updateUserContext } = useContext(PreferencesContext);
    const [loading, setLoading] = useState(false);
    const [submitLoading, setSubmitLoading] = useState(false);
    const [checked, setChecked] = useState(false);
    const [childData, setChildData] = useState(formInitialValues);
    const [motherData, setMotherData] = useState([]);
    const [profileImageFile, setProfileImageFile] = useState<any>();
    const [visibleDashboardCardData, setVisibleDashboardCardData] = useState([]);
    const [image, setImage] = useState(intData);
    const [serverImage, setServerImage] = useState('');
    const [profileImageName, setProfileImageName] = useState('');
    const [childBirthDate, setChildBirthDate] = useState('');
    const [birthDateChange, setBirthDateChange] = useState(false);
    const [birthDateConfirmPopup, setBirthDateConfirmPopup] = useState(false);
    const [isConfirm, setConfirm] = useState(false);
    const [showAlert, setAlertMessage] = useState({
        isSuccess: false,
        message: "",
    });

    const valildationSchema = Yup.object().shape({
        motherId: Yup.string().required("Mother selection is required."),
        name: Yup.string().required("Name is required."),
        birthDate: Yup.string().required("Birthdate is required."),
        gender: Yup.string().required("Gender is required.")
    });

    const options = [
        { value: "MALE", label: 'Male' },
        { value: "FEMALE", label: 'Female' },
        { value: "OTHER", label: 'Other' }
    ]

    let navigate = useNavigate();

    const goToDashboard = () => {
        navigate('/dashboard');
    }

    const goToFamilyMemberList = () => {
        navigate('/member-list');
    }

    const goToStageSelection = () => {
        navigate('/stage-selection');
    }

    const goToParent = () => {
        navigate('/parent');
    }

    const goToSpouse = () => {
        navigate('/spouse');
    }

    function backClick() {
        if (userContext.isAddMember === true) {
            goToStageSelection();
        } else if (userContext.isAddMemberFromList === true) {
            goToFamilyMemberList();
        }
        else if (userContext.isEditMemberFromBottomSheet === true) {
            goToDashboard();
        } else if (userContext.isEditMemberFromList === true) {
            goToFamilyMemberList();
        } else if (userContext.isComeFromStageSelection === true) {
            goToStageSelection();
        } else {
            goToSpouse();
        }
    }

    const handleChange = (e: any) => {
        if (e.target.files.length) {
            setImage({
                preview: URL.createObjectURL(e.target.files[0]),
                raw: e.target.files[0]
            });
        }
        setProfileImageName(e.target.files[0].name);
        setProfileImageFile(e.target.files[0])
    };

    const handleChangeCheckbox = () => {
        if (checked === false) {
            setChecked(true);
        } else {
            setChecked(false);
        }
    };

    async function fetchMotherData() {        
        setLoading(true);
        try {
            let params = new URLSearchParams();
            params.append("familyId", userContext.loginDetail.familyId.toString());
            params.append("familyRelationName", "MOTHER");
            params.append("mode", "CHILD");
            const result: any = await GetMotherList(params);
            setMotherData(result.data.data);
            let formValues: any;
            if (result.data.data.length > 1) {
                formValues = {
                    motherId: "",
                    familyMemberId: 0,
                    name: "",
                    birthDate: "",
                    gender: "",
                    isSpecial: false
                }
            } else {
                formValues = {
                    motherId: result.data.data[0].value,
                    familyMemberId: 0,
                    name: "",
                    birthDate: "",
                    gender: "",
                    isSpecial: false
                }
            }

            setChildData(formValues)
            if (userContext.isAddChildDetail === true) {
                if (userContext.currFamilyMemberId > 0) {
                    fetchData();
                }
            }
            else if (userContext.isEditMemberFromList === true) {
                if (userContext.currFamilyMemberId > 0) {
                    fetchData();
                }
            }
        }
        catch (error) {
            if (error) {
                updateUserContext({
                    ...userContext,
                    isAlert: true,
                    alertMessage: 'Something went wrong',
                    alertType: AlertType.Error,
                });
            }
        }
        setLoading(false);
    }

    async function fetchData() {
        setLoading(true);
        try {
            let params = new URLSearchParams();
            params.append("familyMemberId", userContext.currFamilyMemberId.toString());
            const result: any = await GetFamilyMemberDetail(params);
            let editDetails: any;
            editDetails = result.data.data;
            let updatedValue = {
                familyMemberId: userContext.isBabyDelivered === true ? 0 : editDetails.familyMemberId,
                name: userContext.isAddChildDetail === true ? 'baby of ' + editDetails.firstName : userContext.isBabyDelivered === true ? 'baby of ' + editDetails.firstName : editDetails.firstName,
                birthDate: userContext.isBabyDelivered === true ? dayjs(userContext.dateOfDelivery).format('DD/MM/YYYY') : editDetails.birthDate,
                gender: userContext.isBabyDelivered === true ? '' : userContext.isEditMemberFromList ? editDetails.gender : '',
                isSpecial: editDetails.isSpecialChild,
                motherId: userContext.isAddChildDetail === true ? editDetails.familyMemberId : userContext.isBabyDelivered === true ? editDetails.familyMemberId : editDetails.motherId
            };
            setServerImage(editDetails.serverImage)
            setProfileImageName(editDetails.profileName)
            setImage({ preview: editDetails.profileImageBase });
            setChildBirthDate(editDetails.birthDate);
            setChildData(updatedValue);
        }
        catch (error) {
            updateUserContext({
                ...userContext,
                isAlert: true,
                alertMessage: error.message,
                alertType: AlertType.Error,
            });
        }
        setLoading(false);
    }

    const handleCloseBirthDatePopup = () => {
        setBirthDateConfirmPopup(false);
        setBirthDateChange(false);
    };

    const handaleOpenBirthDatePopUp = (props: any) => {
        if (props.initialValues.birthDate !== "") {
            setBirthDateConfirmPopup(true);
        }
    }

    async function submitChildForm(value: any) {
        setSubmitLoading(true);
        const formData = new FormData();
        const childData = value;
        let firstFamilyMember: any = userContext.loginDetail.lastName === '' && userContext.loginDetail.firstName === '' ? true : false;
        let maternalDetailId: any = userContext.isBabyDelivered === true ? userContext.memberDetail.maternalDetailId : 0;

        formData.append('motherId', childData.motherId);
        formData.append('maternalDetailId', maternalDetailId);
        formData.append('familyMemberId', childData.familyMemberId);
        formData.append('firstName', childData.name);
        formData.append('lastName', userContext.loginDetail.lastName);
        formData.append('age', '0');
        formData.append('birthdate', dayjs(childData.birthDate).format('MM/DD/YYYY'));
        formData.append('gender', childData.gender === "MALE" ? 'MALE' : childData.gender === "FEMALE" ? 'FEMALE' : "MALE");
        formData.append('isSpecialChild', childData.isSpecial);
        formData.append('familyRelationName', childData.gender === "MALE" ? 'SON' : childData.gender === "FEMALE" ? 'DAUGHTER' : "SON",);
        formData.append('familyId', userContext.loginDetail.userId.toString());
        formData.append('dataManagedBy', userContext.loginDetail.userId.toString());
        formData.append('profileImage', profileImageFile);
        formData.append('profileName', profileImageName);
        formData.append('serverImage', serverImage);
        formData.append('firstFamilyMember', firstFamilyMember);

        if (childData.familyMemberId > 0) {
            try {
                const resultParentSave: any = await UpdateParent(formData);
                let data = resultParentSave.data
                setSubmitLoading(false);
                let memberDetail: any = new MemberDetail();
                memberDetail = {
                    // age: data.age,
                    age: ageInDays(data.birthDate),
                    birthDate: data.birthDate,
                    familyId: data.familyId,
                    familyMemberId: data.familyMemberId,
                    familyRelationName: data.familyRelationName,
                    firstName: data.firstName,
                    lastName: data.lastName,
                    gender: data.gender,
                    profileImageBase: data.profileImageBase
                }
                if (userContext.currFamilyMemberId === userContext.memberDetail.familyMemberId) {
                    updateUserContext({
                        ...userContext,
                        memberDetail: memberDetail,
                        isAlert: true,
                        alertMessage: resultParentSave.message,
                        alertType: AlertType.Success,
                        curMemberSelected: resultParentSave.data.firstName
                    });
                }
                setSubmitLoading(false);
                goToDashboard();
            }
            catch (error) {
                updateUserContext({
                    ...userContext,
                    isAlert: true,
                    alertMessage: error.response.message,
                    alertType: AlertType.Error,
                });
                setSubmitLoading(false);
            }
        }
        else {
            try {
                const resultParentSave: any = await CreateParent(formData);
                if (resultParentSave && resultParentSave.data !== null) {
                    let curMemberDetail = resultParentSave.data.familyData;

                    let params = new URLSearchParams();
                    params.append("birthDate", curMemberDetail.birthDate);
                    params.append("gender", curMemberDetail.gender);
                    params.append("language", userContext.memberDetail.curMemberSelectedLanguage);
                    const result: any = await GetModuleConfiguration(params);
                    let dashboardCard = result.data.data;
                    setVisibleDashboardCardData(dashboardCard.sort());


                    let memberDetail = new MemberDetail();
                    // memberDetail.age = curMemberDetail.age;
                    memberDetail.age = ageInDays(curMemberDetail.birthDate)
                    memberDetail.birthDate = curMemberDetail.birthDate;
                    memberDetail.familyId = curMemberDetail.familyId;
                    memberDetail.familyMemberId = curMemberDetail.familyMemberId;
                    memberDetail.familyRelationName = curMemberDetail.familyRelationName;
                    memberDetail.firstName = curMemberDetail.firstName;
                    memberDetail.lastName = curMemberDetail.lastName;
                    memberDetail.gender = curMemberDetail.gender;
                    memberDetail.isPrematureChild = curMemberDetail.isPrematureChild;
                    memberDetail.isSpecialChild = curMemberDetail.isSpecialChild;
                    memberDetail.maternalDetailId = curMemberDetail.maternalDetailId;
                    memberDetail.complicationsDetails = curMemberDetail.complicationsDetails;
                    memberDetail.expectingChildNo = curMemberDetail.expectingChildNo;
                    memberDetail.expectingDateOfDelivery = curMemberDetail.expectingDateOfDelivery;
                    memberDetail.gestationalAge = curMemberDetail.gestationalAge;
                    memberDetail.gestationalAgeTime = curMemberDetail.gestationalAgeTime;
                    memberDetail.isChildBorn = curMemberDetail.isChildBorn;
                    memberDetail.isPragnentExpectingChild = curMemberDetail.isPragnentExpectingChild;
                    memberDetail.lastManstrualPeriodDate = curMemberDetail.lastManstrualPeriodDate;
                    memberDetail.profileImageBase = curMemberDetail.profileImageBase;

                    let moduleConfiguration = userContext.moduleConfiguration;
                    moduleConfiguration = dashboardCard;
                    updateUserContext({
                        ...userContext,
                        memberDetail: memberDetail,
                        curMemberSelected: curMemberDetail.firstName,
                        isAlert: true,
                        alertMessage: resultParentSave.message,
                        alertType: AlertType.Success,
                        moduleConfiguration: moduleConfiguration,
                        moduleConfigurationLoading: false
                    });

                    goToDashboard();
                }
                setLoading(false);
            }
            catch (error) {
                setLoading(false);
                if (error) {
                    setAlertMessage({
                        isSuccess: true,
                        message: error as string,
                    });
                }
            }
        }
    }

    useEffect(() => {
        if (userContext.currFamilyMemberId > 0) {
            // fetchData();
        }
        if (userContext.loginDetail.userId > 0) {
            fetchMotherData();
        }
    }, []);

    return (
        <Fade in={true} timeout={{ enter: 500 }}>
            <Box className="child-main-box">
                <Box className="head-box">
                    <Box className="back-box">
                        {userContext.isBabyDelivered === true ? <></> :
                            <IconButton aria-label="back" className='back-icon' disableRipple
                                onClick={() => backClick()}>
                                <img src={backIcon} alt='logo' />
                            </IconButton>}
                    </Box>
                    <Box className="title-box">
                        <Typography className="title-text">Child details</Typography>
                    </Box>
                    <Box className="skip-box">
                        <></>
                        {/* <Typography>skip</Typography> */}
                    </Box>
                </Box>

                <Formik
                    enableReinitialize
                    initialValues={childData}
                    onSubmit={(values) => {
                        submitChildForm(values);
                    }}
                    validationSchema={valildationSchema}>
                    {(props) => {
                        return (
                            <Form>
                                <Box className="body-box">
                                    <Grid container spacing={2} padding={2}>
                                        <Grid item xs={12} className='profile-grid'>
                                            {loading ?
                                                <Skeleton animation="wave" variant="rounded" width={160} height={160} sx={{ borderRadius: "50%" }} /> :
                                                <Box className="img-box">
                                                    <Box className="set-img-box">
                                                        <Avatar className='img-preview' alt="logo" src={image.preview} />
                                                    </Box>
                                                    <Box className='icon-box'>
                                                        <input
                                                            type="file"
                                                            id="upload-button"
                                                            style={{ display: "none" }}
                                                            onChange={handleChange} />
                                                        <label htmlFor="upload-button" className='img-lable'>
                                                            <SmallAvatar alt="logo" src={cameraIcon} className='camera-icon' />
                                                        </label>
                                                    </Box>
                                                </Box>
                                            }
                                        </Grid>
                                        <Grid item xs={12} md={12} sm={12} zIndex={99}>
                                            <FormControl fullWidth style={{ width: '100%' }}>
                                                {loading ?
                                                    <Skeleton animation="wave" variant="rounded" width='auto' height={45} /> :
                                                    <Select
                                                        id="motherId"
                                                        classNamePrefix="select"
                                                        styles={
                                                            props.values.motherId === "" &&
                                                                props.errors.motherId &&
                                                                props.touched.motherId
                                                                ? selectStyles
                                                                : undefined
                                                        }
                                                        isClearable={true}
                                                        isSearchable={true}
                                                        placeholder={"Mother"}
                                                        theme={(theme) => ({
                                                            ...theme,
                                                            colors: {
                                                                ...theme.colors,
                                                                primary: "#0B7CAA",
                                                                primary25: "#F1F6FE",
                                                                primary50: "#e1dfea",
                                                            },
                                                        })}
                                                        value={getSingleSelected(
                                                            props.values.motherId.toString(),
                                                            motherData
                                                        )}
                                                        onChange={(e, value) => {
                                                            props.setFieldValue(
                                                                "motherId",
                                                                e !== null ? e.value : "",
                                                                false
                                                            );
                                                        }}
                                                        options={motherData}
                                                        className={
                                                            props.values.motherId === "" &&
                                                                props.errors.motherId &&
                                                                props.touched.motherId
                                                                ? "text-input"
                                                                : "text-input"
                                                        } />
                                                }
                                                {props.values.motherId === "" &&
                                                    props.errors.motherId &&
                                                    props.touched.motherId && (
                                                        <Typography
                                                            className={
                                                                props.values.motherId === "" &&
                                                                    props.errors.motherId &&
                                                                    props.touched.motherId
                                                                    ? "validation-message text-input"
                                                                    : "text-input"
                                                            }>
                                                            {props.errors.motherId}
                                                        </Typography>
                                                    )}
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={12}>
                                            <FormControl fullWidth>
                                                {loading ?
                                                    <Skeleton animation="wave" variant="rounded" width='auto' height={45} /> :
                                                    <TextField
                                                        id="name"
                                                        label="Name"
                                                        fullWidth
                                                        size="small"
                                                        onChange={(e: any) => {
                                                            props.setFieldValue(
                                                                "name",
                                                                e.target.value !== null ? e.target.value : "",
                                                                true
                                                            );
                                                        }}
                                                        onBlur={props.handleBlur}
                                                        value={props.values.name}
                                                        inputProps={{ maxLength: 200 }}
                                                        variant="outlined"
                                                        error={
                                                            props.values.name === "" &&
                                                                props.errors.name &&
                                                                props.touched.name
                                                                ? true
                                                                : false
                                                        }
                                                        className={
                                                            props.values.name === "" &&
                                                                props.errors.name &&
                                                                props.touched.name
                                                                ? "text-input validation-message"
                                                                : "text-input"
                                                        } />
                                                }
                                                {props.values.name === "" && props.errors.name &&
                                                    props.touched.name && (
                                                        <Typography
                                                            className={
                                                                props.values.name === "" &&
                                                                    props.errors.name &&
                                                                    props.touched.name
                                                                    ? "validation-message"
                                                                    : "validation-message"
                                                            }>
                                                            {props.errors.name}
                                                        </Typography>
                                                    )}
                                            </FormControl>
                                        </Grid>

                                        <Grid item xs={12} sm={12} md={12}>
                                            {loading ?
                                                <Skeleton animation="wave" variant="rounded" width='auto' height={45} /> :
                                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                    <DemoContainer components={['MobileDatePicker']}>
                                                        <MobileDatePicker
                                                            id="birthDate"
                                                            label="Date of Birth"
                                                            className={
                                                                props.values.birthDate === "" &&
                                                                    props.errors.birthDate &&
                                                                    props.touched.birthDate
                                                                    ? "datepicker validation-message"
                                                                    : "datepicker"
                                                            }
                                                            sx={{ width: '100%' }}
                                                            format='DD/MM/YYYY'
                                                            disableFuture
                                                            // disabled={childBirthDate !== '' ? true : false}
                                                            value={dayjs(props.values.birthDate)}
                                                            onChange={(e: any) => {
                                                                props.setFieldValue(
                                                                    "birthDate",
                                                                    e !== null ? e.toDate() : "",
                                                                    true
                                                                );
                                                                setChildBirthDate(e.toDate());
                                                                handaleOpenBirthDatePopUp(props);

                                                            }} />
                                                    </DemoContainer>
                                                </LocalizationProvider>}
                                            {props.values.birthDate === "" &&
                                                props.errors.birthDate &&
                                                props.touched.birthDate && (
                                                    <Typography
                                                        className={
                                                            props.values.birthDate === "" &&
                                                                props.errors.birthDate &&
                                                                props.touched.birthDate
                                                                ? "validation-message text-input"
                                                                : "text-input"
                                                        }>
                                                        {props.errors.birthDate}
                                                    </Typography>
                                                )}
                                        </Grid>

                                        <Grid item xs={12} md={12} sm={12} zIndex={9}>
                                            <FormControl fullWidth style={{ width: '100%' }}>
                                                {loading ?
                                                    <Skeleton animation="wave" variant="rounded" width='auto' height={45} /> :
                                                    <Select
                                                        id="gender"
                                                        classNamePrefix="select"
                                                        styles={
                                                            props.values.gender === "" &&
                                                                props.errors.gender &&
                                                                props.touched.gender
                                                                ? selectStyles
                                                                : undefined
                                                        }
                                                        isDisabled={false}
                                                        // isLoading={isLoading}
                                                        isClearable={true}
                                                        isSearchable={true}
                                                        placeholder={"Gender"}
                                                        theme={(theme) => ({
                                                            ...theme,
                                                            colors: {
                                                                ...theme.colors,
                                                                primary: "#0B7CAA",
                                                                primary25: "#ecebef",
                                                                primary50: "#e1dfea",
                                                            },
                                                        })}
                                                        onChange={(e, value) => {
                                                            props.setFieldValue(
                                                                "gender",
                                                                e !== null ? e.value : "",
                                                                false
                                                            );
                                                        }}
                                                        options={options}
                                                        value={getSingleSelected(props.values.gender, options)}
                                                        className={
                                                            props.values.gender === "" &&
                                                                props.errors.gender &&
                                                                props.touched.gender
                                                                ? "text-input validation-message"
                                                                : "text-input"
                                                        } />}
                                                {props.values.gender === "" &&
                                                    props.errors.gender &&
                                                    props.touched.gender && (
                                                        <Typography
                                                            className={
                                                                props.values.gender === "" &&
                                                                    props.errors.gender &&
                                                                    props.touched.gender
                                                                    ? "validation-message text-input"
                                                                    : "text-input"
                                                            }>
                                                            {props.errors.gender}
                                                        </Typography>
                                                    )}
                                            </FormControl>
                                        </Grid>

                                        <Grid item xs={12} md={12} sm={12}>
                                            <FormControl fullWidth className="checkbox-element">
                                                <Box className="label-box">
                                                    <Typography variant="inherit" className="label-text">
                                                        Is Special?</Typography>
                                                    <img src={info} alt="logo" />
                                                </Box>
                                                <Box>
                                                    <Checkbox
                                                        color="secondary"
                                                        value={props.values.isSpecial}
                                                        checked={checked}
                                                        // onChange={props.handleChange}
                                                        onChange={(e, value) => {
                                                            props.setFieldValue(
                                                                "isSpecial",
                                                                value !== null ? value : "",
                                                                false
                                                            );
                                                        }}
                                                        onClick={handleChangeCheckbox} />
                                                </Box>
                                            </FormControl>
                                        </Grid>

                                        <Box className="bottom-box">
                                            <CustomButton
                                                disabled={false}
                                                class="add-button"
                                                isLoading={submitLoading}
                                                title='Save' />
                                        </Box>
                                    </Grid>
                                </Box>
                            </Form>)
                    }}
                </Formik>

                <Dialog
                    maxWidth="md"
                    open={birthDateConfirmPopup}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    className="birthdate-confirm-dialog-box">
                    <Box className="confirm-box">
                        <DialogTitle className="dialog-title">
                            Please make sure your child birthdate is correct.
                        </DialogTitle>
                        <DialogContent className="dialog-subcontent">
                            <DialogContentText
                                id="alert-dialog-description"
                                className="birthdate-text">
                                This will clear your previous data.
                            </DialogContentText>
                            <Typography className="birthdate-text">
                                {dayjs(childBirthDate).format('DD/MM/YYYY')}
                            </Typography>
                        </DialogContent>
                        <DialogActions className="dialog-action-button">
                            <LoadingButton
                                color="primary"
                                loading={isConfirm}
                                disableRipple
                                variant="contained"
                                className="confirm-btn"
                                onClick={handleCloseBirthDatePopup}>
                                I, Confirm
                            </LoadingButton>
                        </DialogActions>
                    </Box>
                </Dialog>
            </Box>
        </Fade>
    );
}
const selectStyles = {
    control: (provided: any, state: any) => ({
        ...provided,
        border: state.isFocused ? "1px solid #0B7CAA" : "1px solid #d32f2f",
    }),
    placeholder: (provided: any, state: any) => ({
        ...provided,
        color: state.isFocused ? state.selectProps.color : "#d32f2f",
    }),
};