import React, { useContext, useEffect, useState } from 'react';
import './FetalGrowth.scss';
import { PreferencesContext } from '../../PreferenceContext';
import { Box, Card, Fade, Grid, IconButton, Typography } from '@mui/material';
import backIcon from '../../assets/icons/back.svg';
import { useNavigate } from 'react-router-dom';
import weightIcon from '../../assets/icons/weight.svg';

export default function FetalGrowth() {
    const { userContext, updateUserContext } = useContext(PreferencesContext);
    const [loading, setLoading] = useState(false);
    const [submoduleLoading, setSubmoduleLoading] = useState(false);
    const [submoduleData, setSubmoduleData] = useState([]);
    const [showAlert, setAlertMessage] = useState({
        isSuccess: false,
        message: "",
    });

    const navigate = useNavigate();

    function backClick() {
        navigate("/dashboard");
    }

    function goToSecondTrimester(code: any) {
        updateUserContext({
            ...userContext,
            isGoToMaternalDetail: false
        });
        navigate('/trimester',
            { state: { trimester: code } }
        );

    }

    function goToThirdTrimester(code: any) {
        updateUserContext({
            ...userContext,
            isGoToMaternalDetail: false
        });
        navigate('/trimester',
            { state: { trimester: code } }
        );
    }

    async function fetchData() {
        setLoading(true);
        try {
            let params = new URLSearchParams();
            params.append("familyId", userContext.loginDetail.familyId.toString());
            params.append("familyMemberId", userContext.memberDetail.familyMemberId.toString());
            // const result: any = await GetMemberPhysicalGrowthDetailList(params);
            // let physicalGrowthDataList = result.data.data;
            // setPhysicalGrowthData(physicalGrowthDataList);
        }
        catch (error) {
            setLoading(false);
            if (error) {
                setAlertMessage({
                    isSuccess: true,
                    message: error as string,
                });
            }
        }
        setLoading(false);
    }

    async function fetchSubModules() {
        setSubmoduleLoading(true);
        if (userContext.moduleConfiguration !== undefined) {
            let data = userContext.moduleConfiguration
            let curSubmodule: any = userContext.moduleConfiguration.find(
                (x: any) => x.moduleCode === "FETAL_GROWTH"
            );
            setSubmoduleData(curSubmodule.submodule.sort())
            setSubmoduleLoading(false);
        }
    }

    useEffect(() => {
        if (userContext.memberDetail !== undefined && userContext.memberDetail.familyMemberId > 0) {
            fetchData();
            fetchSubModules();
        }
    }, []);
    return (<Fade in={true} timeout={{ enter: 500 }}>
        <Box className="fetal-growth-main-box">
            <Box className="head-box">
                <Box className="back-box">
                    <IconButton aria-label="back" className='back-icon' disableRipple
                        onClick={() => backClick()}>
                        <img src={backIcon} alt='logo' />
                    </IconButton>
                </Box>
                <Box className="title-box">
                    <Typography className="title-text">Fetal Growth </Typography>
                </Box>
                <Box className="profile-box">
                    <></>
                </Box>
            </Box>
            <Box className="body-box">
                <Grid container spacing={2} p={1} className='main-container-grid'>
                    {submoduleData.length > 0 &&
                        submoduleData.map((row: any, index: any) => (row.submoduleCode === "SECOND_TRIMESTER" ?
                            <Grid key={index} item xs={6} sm={4} md={4} className='subcontainer-grid'>
                                <Card className='inner-card' onClick={() => goToSecondTrimester(row.submoduleCode)}>
                                    <Box className='card-upper-box'><Typography className='upper-text'>{row.submoduleName}</Typography></Box>
                                    <Box className='middle-img'><img src={weightIcon} alt='logo' /></Box>
                                    <Box className='bottom-box'>
                                        {/* {physicalGrowthData.length > 0 &&
                                            physicalGrowthData.map((row: any, index: any) => (
                                                <Typography className='bottom-text' key={index}>{row.tableName === 'MemberWeightDetail' ? row.userData + ' ' + row.userUnit : ''}</Typography>
                                            ))} */}
                                    </Box>
                                </Card>
                            </Grid>
                            : null))}
                    {submoduleData.length > 0 &&
                        submoduleData.map((row: any, index: any) => (row.submoduleCode === "THIRD_TRIMESTER" ?
                            <Grid key={index} item xs={6} sm={4} md={4} className='subcontainer-grid'>
                                <Card className='inner-card' onClick={() => goToThirdTrimester(row.submoduleCode)}>
                                    <Box className='card-upper-box'><Typography className='upper-text'>{row.submoduleName}</Typography></Box>
                                    <Box className='middle-img'><img src={weightIcon} alt='logo' /></Box>
                                    <Box className='bottom-box'>
                                        {/* {physicalGrowthData.length > 0 &&
                                            physicalGrowthData.map((row: any, index: any) => (
                                                <Typography className='bottom-text' key={index}>{row.tableName === 'MemberWeightDetail' ? row.userData + ' ' + row.userUnit : ''}</Typography>
                                            ))} */}
                                    </Box>
                                </Card>
                            </Grid>
                            : null))}
                </Grid>
            </Box>
        </Box>
    </Fade>)
}