import { GET, POST } from "./common/http";
import { BASE_URL } from "./common/const";

export const GetSymptomsList = (parmas: any) =>
    GET(`${BASE_URL}/api/MedicalHistory/GetList?` + parmas, null);

export const GetSymptomsDetail = (parmas: any) =>
    GET(`${BASE_URL}/api/MedicalHistory/GetDetail?` + parmas, null);

export const GetMemberMedicalHistoryList = (parmas: any) =>
    GET(`${BASE_URL}/api/MedicalHistory/GetHistoryList?` + parmas, null);

export const CreateSymptomsQuestionAnswer = (data: any) =>
    POST(`${BASE_URL}/api/MedicalHistory/Create`, data);

export const GetMemberMedicalHistoryById = (parmas: any) =>
    GET(`${BASE_URL}/api/MedicalHistory/GetMemberHistoryById?` + parmas, null);