import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './PhysicalGrowth.scss';
import { Alert, AlertTitle, AppBar, Box, Card, CardContent, Dialog, DialogContent, Fade, Grid, IconButton, Skeleton, Toolbar, Typography } from '@mui/material';
import heightIcon from '../../assets/icons/physical-growth.svg';
import bmiIcon from '../../assets/icons/bmi.svg';
import hcIcon from '../../assets/icons/baby-hc.svg';
import backIcon from '../../assets/icons/back.svg';
import weightIcon from '../../assets/icons/weight.svg';
import CloseIcon from '@mui/icons-material/Close';
import { PreferencesContext } from '../../PreferenceContext';
import { GetMemberPhysicalGrowthDetailList } from '../../services/PhysicalGrowthService';

export default function PhysicalGrowth() {
    const { userContext, updateUserContext } = useContext(PreferencesContext);
    const [loading, setLoading] = useState(false);
    const [physicalGrowthData, setPhysicalGrowthData] = useState([]);
    const [submoduleLoading, setSubmoduleLoading] = useState(false);
    const [submoduleData, setSubmoduleData] = useState([]);
    const [openEmergencyConditionDialog, setOpenEmergencyConditionDialog] = useState(false);
    const [showAlert, setAlertMessage] = useState({
        isSuccess: false,
        message: "",
    });
    const navigate = useNavigate();

    function backClick() {
        navigate("/dashboard");
    }

    function goToWeight() {
        navigate("/weight");
    }

    function goToHeight(submoduleName: any) {
        // navigate("/height");
        navigate('/height',
            { state: { submoduleName: submoduleName } }
        );
    }

    function goToHC() {
        navigate("/head-circumference");
    }

    function goToMAC() {
        navigate("/mid-arm-circumferencee");
    }

    function goToBMI() {
        navigate("/bmi");
    }

    const goToMidParentalHeight = () => {
        navigate("/mid-parental-height");
    }

    async function fetchData() {

        setLoading(true);
        try {
            let params = new URLSearchParams();
            params.append("familyId", userContext.loginDetail.familyId.toString());
            params.append("familyMemberId", userContext.memberDetail.familyMemberId.toString());
            const result: any = await GetMemberPhysicalGrowthDetailList(params);
            let physicalGrowthDataList = result.data.data;
            setPhysicalGrowthData(physicalGrowthDataList);
        }
        catch (error) {
            setLoading(false);
            if (error) {
                setAlertMessage({
                    isSuccess: true,
                    message: error as string,
                });
            }
        }
        setLoading(false);
    }

    async function fetchSubModules() {
        setSubmoduleLoading(true);
        if (userContext.moduleConfiguration !== undefined) {
            let data = userContext.moduleConfiguration
            let curSubmodule: any = userContext.moduleConfiguration.find(
                (x: any) => x.moduleCode === "PHYSICAL_GROWTH"
            );
            setSubmoduleData(curSubmodule.submodule.sort())
            setSubmoduleLoading(false);
        }
    }

    const handleCloseEmergencyConditionDialog = () => {
        setOpenEmergencyConditionDialog(false);
    };

    useEffect(() => {
        if (userContext.memberDetail !== undefined && userContext.memberDetail.familyMemberId > 0) {
            fetchData();
            fetchSubModules();
        }
    }, []);
    const cards = [1, 2, 3, 4, 5, 6];

    return (
        <Fade in={true} timeout={{ enter: 500 }}>
            <Box className="physical-growth-main-box">
                <Box className="head-box">
                    <Box className="back-box">
                        <IconButton aria-label="back" className='back-icon' disableRipple
                            onClick={() => backClick()}>
                            <img src={backIcon} alt='logo' />
                        </IconButton>
                    </Box>
                    <Box className="title-box">
                        <Typography className="title-text">Physical Growth </Typography>
                    </Box>
                    <Box className="profile-box">
                        <></>
                        {/* <Box className={userContext.memberDetail.gender === "FEMALE" ? "set-img-box female-selected" : "set-img-box male-selected"}>
                        <Avatar className='img-preview' alt="logo" src={getCurMemberIcon()} />
                    </Box> */}
                    </Box>
                </Box>
                <Box className="body-box">
                    <Grid container spacing={2} className='main-container-grid'
                        sx={{
                            display: 'flex',
                            flexWrap: {
                                xs: 'wrap', // Wrap on extra small screens
                                sm: 'wrap', // Wrap on small screens
                                md: 'nowrap', // No wrap on medium screens
                                lg: 'nowrap', // No wrap on large screens
                                xl: 'nowrap'  // No wrap on extra large screens
                            }
                        }}>
                        {submoduleData.length > 0 &&
                            submoduleData.map((row: any, index: any) => (
                                ["WEIGHT", "HEIGHT", "HEAD_CIRCUMFERENCE", "BMI", "MID_PARENTAL_HEIGHT"].includes(row.submoduleCode) && (
                                    <Grid className='sub-item-grid'
                                        item key={index} xs={6} sm={3} md={12} lg={12} xl={12}>
                                        <Card className='inner-card' onClick={() => {
                                            if (row.submoduleCode === "WEIGHT") goToWeight();
                                            if (row.submoduleCode === "HEIGHT") goToHeight(row.submoduleName);
                                            if (row.submoduleCode === "HEAD_CIRCUMFERENCE") goToHC();
                                            if (row.submoduleCode === "BMI") goToBMI();
                                            if (row.submoduleCode === "MID_PARENTAL_HEIGHT") goToMidParentalHeight();
                                        }}>
                                            <Box className='middle-img'>
                                                <img src={
                                                    row.submoduleCode === "WEIGHT" ? weightIcon :
                                                        row.submoduleCode === "HEIGHT" ? heightIcon :
                                                            row.submoduleCode === "HEAD_CIRCUMFERENCE" ? hcIcon :
                                                                row.submoduleCode === "BMI" ? bmiIcon : heightIcon
                                                } alt='logo' />
                                            </Box>
                                            <Box className='card-upper-box'>
                                                {physicalGrowthData.length > 0 ?
                                                    physicalGrowthData.findIndex((x: any) => x.tableName === (
                                                        row.submoduleCode === "WEIGHT" ? 'MemberWeightDetail' :
                                                            row.submoduleCode === "HEIGHT" ? 'MemberHeightDetail' :
                                                                row.submoduleCode === "HEAD_CIRCUMFERENCE" ? 'MemberHCDetail' :
                                                                    row.submoduleCode === "BMI" ? 'MemberBMIDetail' : ''
                                                    )) > -1 ?
                                                        physicalGrowthData.map((pyrow: any, idx: any) => (
                                                            pyrow.tableName === (
                                                                row.submoduleCode === "WEIGHT" ? 'MemberWeightDetail' :
                                                                    row.submoduleCode === "HEIGHT" ? 'MemberHeightDetail' :
                                                                        row.submoduleCode === "HEAD_CIRCUMFERENCE" ? 'MemberHCDetail' :
                                                                            row.submoduleCode === "BMI" ? 'MemberBMIDetail' : ''
                                                            ) && <Typography className='upper-text' key={idx}>{row.submoduleName} - {pyrow.userData + ' ' + pyrow.userUnit}</Typography>
                                                        )) : <Typography className='upper-text'>{row.submoduleName}</Typography>
                                                    :
                                                    <Typography className='upper-text'>{row.submoduleName}</Typography>}
                                            </Box>
                                        </Card>
                                    </Grid>
                                )
                            ))}
                    </Grid>

                    <Box sx={{ pt: '10px', width: '100%' }} className="disclaimer-box">
                        <Alert severity="warning">
                            <AlertTitle>Disclaimer:</AlertTitle>
                            We are just giving you the rough outline regarding the health, growth and development of your child just to sensitize you to seek the medical care earlier if needed. Every child has to be examined individually by a registered medical practitioner before confirmation of status of health, growth and development of any child and A Doctor is the Final authority to decide regarding further management and intervention needed as felt necessary by the Doctor.
                            <Box className="source-link-ref-box-title" >Source: <span className="source-link-ref" onClick={() => setOpenEmergencyConditionDialog(true)}>https://iapindia.org/guidelines-for-parents/</span></Box>
                        </Alert>
                    </Box>
                </Box>



                <Dialog
                    fullScreen
                    open={openEmergencyConditionDialog}
                    onClose={handleCloseEmergencyConditionDialog}
                    className="custom-popup-header">
                    <React.Fragment >
                        <AppBar sx={{ position: 'relative' }} color='primary'>
                            <Toolbar className='custom-popup-header' >
                                <Box className='header-empty-box'></Box>
                                <Box className='close-box'>
                                    <IconButton
                                        edge="start"
                                        color="inherit"
                                        onClick={handleCloseEmergencyConditionDialog}
                                        aria-label="close"
                                        className='close-button'>
                                        <CloseIcon />
                                    </IconButton>
                                </Box>
                            </Toolbar>
                        </AppBar>
                        <DialogContent style={{ padding: 0 }}>
                            <iframe width="100%" height="100%" src="https://iapindia.org/guidelines-for-parents/" title="Pediaverse" ></iframe>
                        </DialogContent>
                    </React.Fragment>
                </Dialog>
            </Box>
        </Fade >
    )
}