import React, { useContext, useEffect, useState } from "react";
import "./SymptomsList.scss";
import { Box, Checkbox, Fade, FormControlLabel, IconButton, Typography } from "@mui/material";
import backIcon from '../../assets/icons/back.svg';
import noData from "../../assets/icons/no-data.svg";
import { useLocation, useNavigate } from "react-router-dom";
import { PreferencesContext } from "../../PreferenceContext";
import { GetSymptomsList } from "../../services/MedicalHistoryService";
import LoadingButton from "@mui/lab/LoadingButton/LoadingButton";

let init: any;

export default function SymptomsList() {
    const { userContext, updateUserContext } = useContext(PreferencesContext);
    const [loading, setLoading] = useState(false);
    const [isCheckedSymptoms, setIsCheckedSymptoms] = useState(init);
    const [isSymptomsChecked, setIsSymptomsChecked] = useState([]);
    const [symptomsList, setSymptomsList] = useState(init);
    const [headerName, setHeaderName] = useState('');
    const [showAlert, setAlertMessage] = useState({
        isSuccess: false,
        message: "",
    });
    const navigate = useNavigate();
    const location = useLocation();

    function backClick() {
        navigate('/symptoms',
            { state: { submoduleCode: userContext.symptomsCategoryCode, submoduleName: userContext.symptomsCategoryName } }
        );
    }

    async function fetchSymptomsList() {
        setLoading(true);
        try {
            let params = new URLSearchParams();
            params.append("code", location.state.symptomsCode)
            const result: any = await GetSymptomsList(params);
            let symptoms: any;
            symptoms = result.data.data;
            setSymptomsList(symptoms);
            if (location.state.symptomsId.length > 0) {
                setIsSymptomsChecked(location.state.symptomsId)
            }
        }
        catch (error) {
            if (error) {
                setAlertMessage({
                    isSuccess: true,
                    message: error as string,
                });
            }
        }
        setLoading(false);
    }

    const handleSymptomsCheckbox = (e) => {
        const { value, checked }: any = e.target;
        if (checked) {
            setIsCheckedSymptoms([...isCheckedSymptoms, value]);
        } else {
            setIsCheckedSymptoms(isCheckedSymptoms.filter(e => e !== value));
        }
    };

    async function submitSymptoms() {
        if (isCheckedSymptoms !== null) {
            navigate('/symptoms-questions',
                { state: { symptomsId: isCheckedSymptoms, memberSymptomsId: 0 } }
            );
        }
    }

    useEffect(() => {
        if (location.state.symptomsCode !== undefined && location.state.symptomsCode !== null
            && location.state.symptomsName !== undefined && location.state.symptomsName !== null
            && location.state.symptomsId !== undefined && location.state.symptomsId !== null) {
            setHeaderName(location.state.symptomsName);
            fetchSymptomsList();
        }
    }, []);

    return (
        <Fade in={true} timeout={{ enter: 500 }}>
            <Box className="symptoms-list-main-box">
                <Box className="head-box">
                    <Box className="back-box">
                        <IconButton aria-label="back" className='back-icon' disableRipple
                            onClick={() => backClick()}>
                            <img src={backIcon} alt='logo' />
                        </IconButton>
                    </Box>
                    <Box className="title-box">
                        <Typography className="title-text">{headerName}</Typography>
                    </Box>
                    <Box className="profile-box">
                        <></>
                    </Box>
                </Box>

                <Box className="body-box">
                    <Box className="upper-box">
                        {symptomsList !== undefined && symptomsList.length > 0 ?
                            symptomsList.map((row: any, index: any) => (
                                <Box key={index} className='subcontainer-grid'>
                                    <FormControlLabel
                                        value={row.symptomsId}
                                        control={<Checkbox
                                            onChange={(e) => handleSymptomsCheckbox(e)} />}
                                        label={row.symptomsTitle}
                                        labelPlacement="end" />
                                </Box>)) :
                            <Box className="no-data-box">
                                <Box className="upper-box"></Box>
                                <Box className="bottom-box">
                                    <Box className="img-box"><img src={noData} alt="logo" /></Box>
                                    <Box className="no-summary-box">
                                        <Typography className="no-summary-text">
                                            {location.state.symptomsCode !== undefined && location.state.symptomsName !== undefined ? "Will update soon" : "No data available"}
                                        </Typography>
                                    </Box>
                                </Box>
                            </Box>}
                    </Box>
                    {symptomsList !== undefined && symptomsList.length > 0 ?
                        <Box className="bottom-box">
                            <LoadingButton
                                color="primary"
                                fullWidth
                                variant="contained"
                                loading={loading}
                                disabled={false}
                                className="add-button"
                                onClick={submitSymptoms}>
                                Next
                            </LoadingButton>
                        </Box> : <></>}
                </Box>
            </Box>
        </Fade>
    );
}
