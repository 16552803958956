import React, { useContext, useEffect, useState } from 'react';
import './Languages.scss';
import { useNavigate } from "react-router-dom";
import { Backdrop, Box, Fade, IconButton, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Typography } from '@mui/material';
import { PreferencesContext } from '../../PreferenceContext';
import backIcon from '../../assets/icons/back.svg';
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { AlertType } from '../../shared/AlertType';
import { useTranslation } from 'react-i18next';
import { GetLanguageListForTranslation, UpdateCurMemberLanguage } from '../../services/AuthService';

export default function Languages() {
    const { userContext, updateUserContext } = useContext(PreferencesContext);
    const [languageList, setLanguageList] = useState([]);
    // const [curSelectedLanguage, setCurSelectedLanguage] = useState('');
    const navigate = useNavigate();

    const { i18n, t } = useTranslation();

    function backClick() {
        navigate('/settings');
    }

    async function fetchLanguagesForTranslation() {
        try {
            const languageData: any = await GetLanguageListForTranslation();
            if (languageData && languageData.data !== null && languageData.data.data !== null) {
                let result = languageData.data.data;
                setLanguageList(result);
            }
            // setCurSelectedLanguage(userContext.memberDetail.curMemberSelectedLanguage);
        }
        catch (error) {
            if (error) {
                updateUserContext({
                    ...userContext,
                    isAlert: true,
                    alertMessage: error as string,
                    alertType: AlertType.Error,
                });
            }
        }
    }

    async function handleChangeLanguage(selectedLanguage) {
        // setCurSelectedLanguage(selectedLanguage.code);

        try {
            i18n.changeLanguage(selectedLanguage.code)
            let updateLanguageData = {
                familyId: userContext.memberDetail.familyId,
                familyMemberid: userContext.memberDetail.familyMemberId,
                selectedLanguage: selectedLanguage.code
            }

            updateUserContext({
                ...userContext,
                memberDetail: {
                    ...userContext.memberDetail,
                    curMemberSelectedLanguage: selectedLanguage.code
                }
            });
            const languageData: any = await UpdateCurMemberLanguage(updateLanguageData);
        }
        catch (error) {
            if (error) {
                updateUserContext({
                    ...userContext,
                    isAlert: true,
                    alertMessage: error as string,
                    alertType: AlertType.Error,
                });
            }
        }
    };

    useEffect(() => {
        fetchLanguagesForTranslation();
    }, [])

    return (
        <Fade in={true} timeout={{ enter: 500 }}>
            <Box className="language-main-box">
                <Box className="head-box">
                    <Box className="back-box">
                        <IconButton aria-label="back" className='back-icon' disableRipple
                            onClick={() => backClick()}>
                            <img src={backIcon} alt='logo' />
                        </IconButton>
                    </Box>
                    <Box className="title-box">
                        <Typography className="title-text">{t('Settings.Languages.Languages')}</Typography>
                    </Box>
                    <Box className="skip-box">
                        <></>
                    </Box>
                </Box>
                <Box className="language-body-box">
                    <Box className="main-box">
                        <List className='language-list'>
                            {languageList.map((x: any, i: any) => {
                                return (
                                    <ListItem disablePadding
                                        key={i}>
                                        <ListItemButton
                                            className='list-item-button'
                                            selected={x.code === userContext.memberDetail.curMemberSelectedLanguage}
                                            onClick={(event) => handleChangeLanguage(x)}>
                                            <ListItemText
                                                primary={x.label} />

                                            <ListItemIcon className='list-item-icon'>
                                                {x.code === userContext.memberDetail.curMemberSelectedLanguage ? (
                                                    <CheckCircleIcon color="primary" />
                                                ) : (
                                                    <RadioButtonUncheckedIcon />
                                                )}
                                            </ListItemIcon>
                                        </ListItemButton>
                                    </ListItem>
                                );
                            })}
                        </List>
                    </Box>

                </Box>
            </Box>
        </Fade>)
}