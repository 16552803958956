import { GET, POST } from "./common/http";
import { BASE_URL } from "./common/const";

export const GetMphSummaryDetail = (parmas: any) =>
    GET(`${BASE_URL}/api/MidParentalHeight/GetMphSummary?` + parmas, null);

export const GetMphDetailById = (parmas: any) =>
    GET(`${BASE_URL}/api/MidParentalHeight/GetMphDetailById?` + parmas, null);

export const UpdateMidParentalHeight = (data: any) =>
    POST(`${BASE_URL}/api/MidParentalHeight/UpdateMidParentalHeight`, data);