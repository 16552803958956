import React, { useContext, useEffect, useState } from 'react';
import './NewBornCareList.scss';
import { PreferencesContext } from '../../PreferenceContext';
import { Alert, AlertTitle, AppBar, Box, Card, Dialog, DialogContent, Fade, Grid, IconButton, Toolbar, Typography } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import backIcon from '../../assets/icons/back.svg';
import temperatureIcon from "../../assets/icons/temperature.svg";
// import feedingIcon from "../../assets/icons/feeding.svg";
import motherhoodIcon from "../../assets/icons/breastfeeding.svg";
import immunityIcon from "../../assets/icons/immunity.svg";
import specialSenseIcon from "../../assets/icons/special-sense.svg";
import umbilicalCordIcon from "../../assets/icons/umbilical-cord.svg";
import prematureIcon from "../../assets/icons/premature.svg";
import normalFindingIcon from "../../assets/icons/normal-finding.svg";
import redAlertsIcon from "../../assets/icons/red-alert.svg";
import safePracticeIcon from "../../assets/icons/safe-practice.svg";
import { useTranslation } from 'react-i18next';
import CloseIcon from '@mui/icons-material/Close';

export default function NewBornCareList() {
    const { userContext, updateUserContext } = useContext(PreferencesContext);
    const [submoduleLoading, setSubmoduleLoading] = useState(false);
    const [submoduleData, setSubmoduleData] = useState([]);
    const [openEmergencyConditionDialog, setOpenEmergencyConditionDialog] = useState(false);

    const navigate = useNavigate();

    const { i18n, t } = useTranslation();

    const location = useLocation();
    function backClick() {
        navigate("/dashboard");
    }
    function goToTemperature(submoduleId, subModuleName: any) {
        navigate('/temperature');
    }

    function goToFeeding(submoduleId, subModuleName: any) {
        navigate('/feeding');
    }

    function goToImmunity(submoduleId, subModuleName: any) {
        navigate('/immunity');
    }

    function goToSpecialSenses(submoduleId, subModuleName: any) {
        navigate('/special-senses');
    }

    function goToSkinCare(submoduleId, subModuleName: any) {
        navigate('/care-of-skin');
    }


    function goToCareOfPremature(submoduleId, subModuleName: any) {
        navigate('/care-of-premature');
    }


    function goToNormalFindingsNewBorn(submoduleId, subModuleName: any) {
        navigate('/normal-findings-newborns');
    }

    function goToRedAlertSignsNewBorn(submoduleId, subModuleName: any) {
        navigate('/red-alert-signs-newborn');
    }
    function goToSafePractices(submoduleId, subModuleName: any) {
        navigate('/safe-practices');
    }

    const handleCloseEmergencyConditionDialog = () => {
        setOpenEmergencyConditionDialog(false);
    };

    async function fetchSubModules() {
        setSubmoduleLoading(true);
        if (userContext.moduleConfiguration !== undefined) {
            let data = userContext.moduleConfiguration
            let curSubmodule: any = userContext.moduleConfiguration.find(
                (x: any) => x.moduleCode === "CARE_OF_NEWBORN"
            );
            setSubmoduleData(curSubmodule.submodule.sort())
            setSubmoduleLoading(false);
        }
    }
    useEffect(() => {
        if (userContext.memberDetail !== undefined && userContext.memberDetail.familyMemberId > 0) {
            fetchSubModules();
        }
    }, []);
    return (
        <Fade in={true} timeout={{ enter: 500 }}>
            <Box className="new-born-main-box">
                <Box className="head-box">
                    <Box className="back-box">
                        <IconButton aria-label="back" className='back-icon' disableRipple
                            onClick={() => backClick()}>
                            <img src={backIcon} alt='logo' />
                        </IconButton>
                    </Box>
                    <Box className="title-box">
                        <Typography className="title-text">{t('Care of new born.Care of new born')}</Typography>
                    </Box>
                    <Box className="profile-box">
                        <></>
                    </Box>
                </Box>

                <Box className="body-box">
                    <Grid container spacing={2} className='main-container-grid'
                        sx={{
                            display: 'flex',
                            flexWrap: {
                                xs: 'wrap', // Wrap on extra small screens
                                sm: 'wrap', // Wrap on small screens
                                md: 'wrap', // No wrap on medium screens
                                lg: 'wrap', // No wrap on large screens
                                xl: 'nowrap'  // No wrap on extra large screens
                            }
                        }}>
                        {submoduleData.length > 0 &&
                            submoduleData.map((row: any, index: any) => (
                                ["TEMPERATURE", "FEEDING", "IMMUNITY", "SPECIAL_SENSES", "CARE_OF_SKIN", "CARE_OF_PREMATURE", "NORMAL_FINDINGS_NEWBORNS", "RED_ALERT_SIGNS_NEWBORN", "SAFE_PRACTICES"].includes(row.submoduleCode) && (
                                    <Grid className='sub-item-grid'
                                        item key={index} xs={6} sm={3} md={3} lg={3} xl={12}>
                                        <Card className='inner-card' onClick={() => {
                                            if (row.submoduleCode === "TEMPERATURE") goToTemperature(row.submoduleId, row.submoduleCode);
                                            if (row.submoduleCode === "FEEDING") goToFeeding(row.submoduleId, row.submoduleCode);
                                            if (row.submoduleCode === "IMMUNITY") goToImmunity(row.submoduleId, row.submoduleCode);
                                            if (row.submoduleCode === "SPECIAL_SENSES") goToSpecialSenses(row.submoduleId, row.submoduleCode);
                                            if (row.submoduleCode === "CARE_OF_SKIN") goToSkinCare(row.submoduleId, row.submoduleCode);
                                            if (row.submoduleCode === "CARE_OF_PREMATURE") goToCareOfPremature(row.submoduleId, row.submoduleCode);
                                            if (row.submoduleCode === "NORMAL_FINDINGS_NEWBORNS") goToNormalFindingsNewBorn(row.submoduleId, row.submoduleCode);
                                            if (row.submoduleCode === "RED_ALERT_SIGNS_NEWBORN") goToRedAlertSignsNewBorn(row.submoduleId, row.submoduleCode);
                                            if (row.submoduleCode === "SAFE_PRACTICES") goToSafePractices(row.submoduleId, row.submoduleCode);
                                        }}>

                                            <Box className='middle-img'>
                                                <img src={
                                                    row.submoduleCode === "TEMPERATURE" ? temperatureIcon :
                                                        row.submoduleCode === "FEEDING" ? motherhoodIcon :
                                                            row.submoduleCode === "IMMUNITY" ? immunityIcon :
                                                                row.submoduleCode === "SPECIAL_SENSES" ? specialSenseIcon :
                                                                    row.submoduleCode === "CARE_OF_SKIN" ? umbilicalCordIcon :
                                                                        row.submoduleCode === "CARE_OF_PREMATURE" ? prematureIcon :
                                                                            row.submoduleCode === "NORMAL_FINDINGS_NEWBORNS" ? normalFindingIcon :
                                                                                row.submoduleCode === "RED_ALERT_SIGNS_NEWBORN" ? redAlertsIcon : safePracticeIcon
                                                } alt='logo' />
                                            </Box>
                                            <Box className='card-upper-box'>
                                                <Box className='card-upper-box'>
                                                    <Typography className='upper-text'>
                                                        {row.submoduleCode === "TEMPERATURE" ? row.submoduleName : null}
                                                        {row.submoduleCode === "FEEDING" ? row.submoduleName : null}
                                                        {row.submoduleCode === "IMMUNITY" ? row.submoduleName : null}
                                                        {row.submoduleCode === "SPECIAL_SENSES" ? row.submoduleName : null}
                                                        {row.submoduleCode === "CARE_OF_SKIN" ? row.submoduleName : null}
                                                        {row.submoduleCode === "CARE_OF_PREMATURE" ? row.submoduleName : null}
                                                        {row.submoduleCode === "NORMAL_FINDINGS_NEWBORNS" ? row.submoduleName : null}
                                                        {row.submoduleCode === "RED_ALERT_SIGNS_NEWBORN" ? row.submoduleName : null}
                                                        {row.submoduleCode === "SAFE_PRACTICES" ? row.submoduleName : null}
                                                    </Typography>
                                                </Box>
                                            </Box>
                                        </Card>
                                    </Grid>)
                            ))}
                    </Grid>

                    <Box sx={{ pt: '10px', width: '100%' }} className="disclaimer-box">
                        <Alert severity="warning">
                            <AlertTitle>Disclaimer:</AlertTitle>
                            We are just giving you the rough outline regarding the health, growth and development of your child just to sensitize you to seek the medical care earlier if needed. Every child has to be examined individually by a registered medical practitioner before confirmation of status of health, growth and development of any child and A Doctor is the Final authority to decide regarding further management and intervention needed as felt necessary by the Doctor.
                            <Box className="source-link-ref-box-title" >Source: <span className="source-link-ref" onClick={() => setOpenEmergencyConditionDialog(true)}>https://iapindia.org/guidelines-for-parents/</span></Box>
                        </Alert>
                    </Box>

                </Box>

                <Dialog
                    fullScreen
                    open={openEmergencyConditionDialog}
                    onClose={handleCloseEmergencyConditionDialog}
                    className="custom-popup-header">
                    <React.Fragment >
                        <AppBar sx={{ position: 'relative' }} color='primary'>
                            <Toolbar className='custom-popup-header' >
                                <Box className='header-empty-box'></Box>
                                <Box className='close-box'>
                                    <IconButton
                                        edge="start"
                                        color="inherit"
                                        onClick={handleCloseEmergencyConditionDialog}
                                        aria-label="close"
                                        className='close-button'>
                                        <CloseIcon />
                                    </IconButton>
                                </Box>
                            </Toolbar>
                        </AppBar>
                        <DialogContent style={{ padding: 0 }}>
                            <iframe width="100%" height="100%" src="https://iapindia.org/guidelines-for-parents/" title="Pediaverse" ></iframe>
                        </DialogContent>
                    </React.Fragment>
                </Dialog>
            </Box>
        </Fade>
    );

}