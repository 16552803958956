import React, { useContext, useEffect, useState } from "react";
import "./CustomBottomSheet.scss";
import styles from "./styles.module.css";
import { AppBar, Avatar, Backdrop, Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, Divider, Drawer, IconButton, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Skeleton, Slide, Stack, TextField, Toolbar, Typography } from "@mui/material";
import { PreferencesContext } from "../../PreferenceContext";
import { Context, MemberDetail } from "../../services/models/Context";
import { useNavigate } from "react-router-dom";
import { a, config, useSpring } from "@react-spring/web";
import { useDrag } from "@use-gesture/react";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { LoadingButton } from "@mui/lab";
import { ageInDays, ageInWeeks, convertInWords } from "../../shared/CommonFuctions";
import { DeleteFamilyMember, GetCurMemberProfilePic, GetFamilyMemberList, GetMemberList, GetMotherList } from "../../services/ParentService";
import { AlertType } from "../../shared/AlertType";
import { TransitionProps } from "@mui/material/transitions";
import CustomPopup from "../CustomPopup/CustomPopup";
import { GetInstruction } from "../../services/InstructionService";
import InfoRoundedIcon from '@mui/icons-material/InfoRounded';
import { GetModuleConfiguration, GetMotherModuleConfiguration } from "../../services/DashboardService";
import SortRoundedIcon from "@mui/icons-material/SortRounded";
import NotificationsRoundedIcon from '@mui/icons-material/NotificationsRounded';
import HelpRoundedIcon from '@mui/icons-material/HelpRounded';
import HomeRoundedIcon from '@mui/icons-material/HomeRounded';
import FamilyRestroomRoundedIcon from "@mui/icons-material/FamilyRestroomRounded";
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import SettingsRoundedIcon from "@mui/icons-material/SettingsRounded";
import PowerSettingsNewRoundedIcon from "@mui/icons-material/PowerSettingsNewRounded";
import CloseIcon from '@mui/icons-material/Close';
import womanIcon from "../../assets/images/woman.svg";
import girlIcon from "../../assets/images/girl.svg";
import manIcon from "../../assets/images/man.svg";
import boyIcon from "../../assets/images/boy.svg";
import addUserIcon from "../../assets/images/add-user.svg";
import sirenIcon from '../../assets/icons/siren.svg';
import { useTranslation } from "react-i18next";

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const drawerWidth = 240;

const thoughtFormInitialValues = {
    thoughts: "",
};

let init: any;
let height: any;
let addMemberheight: any;
export default function CustomBottomSheet(props: any) {
    const { window } = props;
    const { userContext, updateUserContext } = useContext(PreferencesContext);
    const [thoughtsData, setThoughtsData] = useState(thoughtFormInitialValues);
    const [visibleDashboardCardData, setVisibleDashboardCardData] = useState([]);
    const [visibleNotoficationData, setVisibleNotoficationData] = useState([]);
    const [mobileOpen, setMobileOpen] = useState(false);
    const [isHelpDialog, setIsHelpDialog] = useState(false);
    const [isBottomSheetOpen, setIsBottomSheetOpen] = useState(false);
    const [familyMemberDetail, setFamilyMemberDetail] = useState([]);
    const [allFamilyMemberDetail, setAllFamilyMemberDetail] = useState([]);
    const [memberDeleteId, setMemberDeleteId] = useState(0);
    const [memberDeleteModalOpen, setMemberDeleteModalOpen] = useState(false);
    const [deleteLoading, setDeleteLoading] = useState(false);
    const [loading, setLoading] = useState(false);
    const [openEmergencyConditionDialog, setOpenEmergencyConditionDialog] = useState(false);
    const [emergencyConditionData, setEmergencyConditionData] = useState(init);
    const [code, setCode] = useState('');
    const [countTap, setCountTap] = useState(0);
    const [errorCode, setErrorCode] = useState(false);
    const [openVerificationPopup, setOpenVerificationPopup] = useState(false);
    const [curMemberImage, setCurMemberImage] = useState(boyIcon)
    const [isProfileImage, setIsProfileImage] = useState(false);
    const [selectedMember, setSelectedMember] = useState([]);
    const [showAlert, setAlertMessage] = useState({
        isSuccess: false,
        message: "",
    });

    const valildationSchema = Yup.object().shape({
        thoughts: Yup.number().required("Thoughts is required."),
    });

    const drawerContainer = window !== undefined ? () => window().document.body : undefined;
    height = familyMemberDetail.length * 60 + 140;

    const [{ y }, api] = useSpring(() => ({ y: height }));

    const display = y.to((py) => (py < height ? "block" : "none"));

    const navigate = useNavigate();

    const { i18n, t } = useTranslation();

    const goToDashboard = () => {
        navigate("/dashboard");
    }

    const goToOpenVerifyCode = (i: any) => {
        if (i < 6) {
            setCountTap(i);
            if (i === 5) {
                setOpenVerificationPopup(true);
            }
        }
    }

    const closeVerifyPopup = () => {
        setOpenVerificationPopup(false);
        setCountTap(0);
        setCode('');
        setErrorCode(false);
    }

    const goToSendNotification = (text: any) => {
        if (text === '202399') {
            setCode('');
            navigate("/notification-send");
        } else {
            setErrorCode(true);
        }
    }

    const goToMemberList = () => {
        navigate("/member-list");
    }

    const goToAboutUs = () => {
        navigate("/about-us");
    }

    const goToSetting = () => {
        navigate("/settings");
    }

    const handleLogout = () => {
        var context: Context = new Context();
        updateUserContext(context);
        navigate("/");
    };

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
        setCountTap(0);
    };

    const goToSiren = () => {
        fetchInstruction('MANAGEMENT_IN_EMERGENCY_CONDITIONS');
        // setOpenEmergencyConditionDialog(true);
    }

    const goToOpenNotification = () => {
        updateUserContext({
            ...userContext,
            memberDetail: {
                ...userContext.memberDetail,
                notificationCount: 0
            }
        });
        navigate("/notifications");

    }

    const handleCloseEmergencyConditionDialog = () => {
        setOpenEmergencyConditionDialog(false);
    };

    const goToHelp = () => {
        setIsHelpDialog(false);
    }

    const handleCloseHelpDialog = () => {
        setIsHelpDialog(false);
    }



    function getCurLoginMemberIcon() {
        var icon = "";
        if (
            userContext.loginDetail.profileImageBase !== "" &&
            userContext.loginDetail.profileImageBase !== null
        ) {
            icon = userContext.loginDetail.profileImageBase;
        } else {
            if (userContext.loginDetail.gender === "FEMALE") {
                if (userContext.loginDetail.familyRelationName === "MOTHER") {
                    icon = womanIcon;
                } else {
                    icon = girlIcon;
                }
            } else {
                if (
                    userContext.loginDetail.familyRelationName === "FATHER" ||
                    userContext.loginDetail.familyRelationName === "GUARDIAN"
                ) {
                    icon = manIcon;
                } else {
                    icon = boyIcon;
                }
            }
        }
        return icon;
    }

    const close = (velocity = 0) => {
        setIsBottomSheetOpen(false);
        // setIsBottomSheetOpen(!isBottomSheetOpen);
        api.start({
            y: height,
            immediate: false,
            config: { ...config.stiff, velocity },
        });
    };

    const openMemberList = ({ canceled }) => {
        // applyHeight();
        setIsBottomSheetOpen(true);
        // setIsBottomSheetOpen(!isBottomSheetOpen);
        api.start({
            y: 0,
            immediate: false,
            config: canceled ? config.wobbly : config.stiff,
        });
    };

    const bind = useDrag(
        ({
            last,
            velocity: [, vy],
            direction: [, dy],
            offset: [, oy],
            cancel,
            canceled,
        }) => {
            if (oy < -70) cancel();
            if (last) {
                oy > height * 0.5 || (vy > 0.5 && dy > 0)
                    ? close(vy)
                    : openMemberList({ canceled });
            }
            else api.start({ y: oy, immediate: false });
        },
        {
            from: () => [0, y.get()],
            filterTaps: true,
            bounds: { top: 0 },
            rubberband: true,
        },

    );

    async function selectMember(id: any) {
        setIsProfileImage(false)
        getCurMemberIcon(id)

        setIsBottomSheetOpen(false);
        // setIsBottomSheetOpen(!isBottomSheetOpen);
        let moduleConfiguration = '';
        let curMemberDetail: any = familyMemberDetail.find(
            (x: any) => x.familyMemberId === id
        );
        let memberDetail = new MemberDetail();
        memberDetail.age = curMemberDetail.birthDate !== '' ? ageInDays(curMemberDetail.birthDate) : curMemberDetail.age;
        memberDetail.birthDate = curMemberDetail.birthDate;
        memberDetail.familyId = curMemberDetail.familyId;
        memberDetail.familyMemberId = curMemberDetail.familyMemberId;
        memberDetail.familyRelationName = curMemberDetail.familyRelationName;
        memberDetail.firstName = curMemberDetail.firstName;
        memberDetail.lastName = curMemberDetail.lastName;
        memberDetail.gender = curMemberDetail.gender;
        memberDetail.isPrematureChild = curMemberDetail.isPrematureChild;
        memberDetail.isSpecialChild = curMemberDetail.isSpecialChild;
        memberDetail.maternalDetailId = curMemberDetail.maternalDetailId;
        memberDetail.complicationsDetails = curMemberDetail.complicationsDetails;
        memberDetail.expectingChildNo = curMemberDetail.expectingChildNo;
        memberDetail.expectingDateOfDelivery = curMemberDetail.expectingDateOfDelivery;
        memberDetail.gestationalAge = curMemberDetail.gestationalAge;
        memberDetail.gestationalAgeTime = curMemberDetail.gestationalAgeTime;
        memberDetail.isChildBorn = curMemberDetail.isChildBorn;
        memberDetail.isPragnentExpectingChild = curMemberDetail.isPragnentExpectingChild;
        memberDetail.lastManstrualPeriodDate = curMemberDetail.lastManstrualPeriodDate;
        memberDetail.profileImageBase = curMemberDetail.profileImageBase;
        memberDetail.notificationCount = curMemberDetail.notificationCount;
        memberDetail.curMemberSelectedLanguage = curMemberDetail.selectedLanguage;
        updateUserContext({
            ...userContext,
            moduleConfiguration: moduleConfiguration,
            memberDetail: memberDetail,
            curMemberSelected: curMemberDetail.firstName
        });

        //set language
        i18n.changeLanguage(curMemberDetail.selectedLanguage)

    }

    const currMemberEdit = (id: any, familyRelationName: any) => {
        updateUserContext({
            ...userContext,
            currFamilyMemberId: id,
            isEditMemberFromBottomSheet: true,
            isEditMemberFromList: false,
            isAddMember: false,
            isEdit: true
        });

        if (familyRelationName === "SON" || familyRelationName === "DAUGHTER") {
            navigate("/child-detail");
        } else {
            navigate("/parent");
        }
    };

    const currMemberDelete = (id: any) => {
        setMemberDeleteId(id);
        setMemberDeleteModalOpen(true);
    };

    const addMemberClick = async () => {
        try {
            let params = new URLSearchParams();
            params.append("familyId", userContext.loginDetail.familyId.toString());
            params.append("familyRelationName", "MOTHER");
            params.append("mode", "MATERNITY");
            const result: any = await GetMotherList(params);
            if (result.data.data.length === 0) {
                updateUserContext({
                    ...userContext,
                    currFamilyMemberId: 0,
                    isAddMember: true,
                    isWifePregnant: true,
                });
            } else {
                updateUserContext({
                    ...userContext,
                    currFamilyMemberId: 0,
                    isAddMember: true,
                    isWifePregnant: false,
                });
            }
            navigate("/stage-selection");
        }
        catch (error) {
            if (error) {
                updateUserContext({
                    ...userContext,
                    isAlert: true,
                    alertMessage: 'Something went wrong',
                    // alertMessage: error.response.message,
                    alertType: AlertType.Error,
                });
            }
        }

        // updateUserContext({
        //     ...userContext,
        //     currFamilyMemberId: 0,
        //     isAddMember: true,
        //     // isWifePregnant: true,
        // });
        // navigate("/stage-selection");
    };

    const closeDeleteModal = () => {
        setMemberDeleteModalOpen(false);
    };

    async function deleteMember() {
        setDeleteLoading(true);
        try {
            let DeleteData = {
                familyMemberId: memberDeleteId.toString(),
                familyId: userContext.loginDetail.familyId,
                isDeleted: true,
                dataManagedBy: userContext.loginDetail.userId,
            };
            const result: any = await DeleteFamilyMember(DeleteData);
            let memberDetail = {
                age: 0,
                birthDate: "",
                familyId: userContext.loginDetail.familyId,
                familyMemberId: 0,
                familyRelationName: "",
                firstName: "",
                lastName: "",
                gender: "",
                isPrematureChild: false,
                isSpecialChild: false,
                maternalDetailId: 0,
                complicationsDetails: "",
                expectingChildNo: 0,
                expectingDateOfDelivery: "",
                gestationalAge: 0,
                gestationalAgeTime: "",
                isChildBorn: false,
                isPragnentExpectingChild: false,
                lastManstrualPeriodDate: "",
                profileImageBase: "",
            };
            updateUserContext({
                ...userContext,
                memberDetail: memberDetail,
            });
            fetchData(true);
        } catch (error) {
            setDeleteLoading(false);
            if (error) {
                setAlertMessage({
                    isSuccess: true,
                    message: error as string,
                });
            }
        }
        setDeleteLoading(false);
        setMemberDeleteModalOpen(false);
        setIsBottomSheetOpen(false);
        // setIsBottomSheetOpen(!isBottomSheetOpen);
    }

    async function getCurMemberIcon(cuMemberId) {

        var icon = "";
        let params = new URLSearchParams();
        params.append("familyMemberId", cuMemberId.toString());
        const result: any = await GetCurMemberProfilePic(params);
        let data = result.data.data;

        if (
            data !== undefined && data !== null &&
            data.profileImageBase !== undefined &&
            data.profileImageBase !== null &&
            data.profileImageBase !== "" &&
            data.familyMemberId > 0
        ) {
            icon = data.profileImageBase;
            setIsProfileImage(true);
        } else {
            if (data.gender !== undefined && data.gender !== null && data.gender === "FEMALE") {
                if (data.familyRelationName !== undefined && data.familyRelationName !== null && data.familyRelationName === "MOTHER") {
                    icon = womanIcon;
                } else {
                    icon = girlIcon;
                }
            } else {
                icon = boyIcon;
            }
        }
        setCurMemberImage(icon);
        // return icon;
    }

    async function fetchAllmembers() {
        try {
            let params = new URLSearchParams();
            params.append("familyId", userContext.loginDetail.familyId.toString());
            const result: any = await GetMemberList(params);
            let familyMemberCollection = result.data.data.familyMemberCollection;
            setAllFamilyMemberDetail(familyMemberCollection);
            updateUserContext({
                ...userContext,
                allFamilyMembersData: familyMemberCollection
            })
        } catch (error) {
            setLoading(false);
            if (error) {
                setAlertMessage({
                    isSuccess: true,
                    message: error as string,
                });
            }
        }
    }

    async function fetchData(isDeleted: any) {
        setLoading(true);
        try {
            updateUserContext({
                ...userContext,
                moduleConfigurationLoading: true
            });
            let params = new URLSearchParams();
            params.append("familyId", userContext.loginDetail.familyId.toString());

            const result: any = await GetFamilyMemberList(params);

            let familyMemberCollection = result.data.data.familyMemberCollection;
            if (familyMemberCollection.length > 0) {
                setFamilyMemberDetail(familyMemberCollection);
                if (userContext.memberDetail.familyMemberId === 0 || isDeleted === true) {
                    updateUserContext({
                        ...userContext,
                        memberDetailLoading: true
                    });
                    setSelectedMember(familyMemberCollection[0]);
                    let memberDetail = new MemberDetail();
                    memberDetail = {
                        // age: familyMemberCollection[0].age,
                        age: familyMemberCollection[0].birthDate !== '' ? ageInDays(familyMemberCollection[0].birthDate) : familyMemberCollection[0].age,
                        birthDate: familyMemberCollection[0].birthDate,
                        familyId: familyMemberCollection[0].familyId,
                        familyMemberId: familyMemberCollection[0].familyMemberId,
                        familyRelationName: familyMemberCollection[0].familyRelationName,
                        firstName: familyMemberCollection[0].firstName,
                        lastName: familyMemberCollection[0].lastName,
                        gender: familyMemberCollection[0].gender,
                        isPrematureChild: familyMemberCollection[0].isPrematureChild,
                        isSpecialChild: familyMemberCollection[0].isSpecialChild,
                        maternalDetailId: familyMemberCollection[0].maternalDetailId,
                        complicationsDetails: familyMemberCollection[0].complicationsDetails,
                        expectingChildNo: familyMemberCollection[0].expectingChildNo,
                        expectingDateOfDelivery: familyMemberCollection[0].expectingDateOfDelivery,
                        gestationalAge: familyMemberCollection[0].gestationalAge,
                        gestationalAgeTime: familyMemberCollection[0].gestationalAgeTime,
                        isChildBorn: familyMemberCollection[0].isChildBorn,
                        isTermination: familyMemberCollection[0].isTermination,
                        isPragnentExpectingChild: familyMemberCollection[0].isPragnentExpectingChild,
                        lastManstrualPeriodDate: familyMemberCollection[0].lastManstrualPeriodDate,
                        profileImageBase: familyMemberCollection[0].profileImageBase,
                        notificationCount: familyMemberCollection[0].notificationCount,
                        curMemberSelectedLanguage: familyMemberCollection[0].selectedLanguage
                    };
                    updateUserContext({
                        ...userContext,
                        memberDetail: memberDetail,
                        memberDetailLoading: false,
                        curMemberSelected: familyMemberCollection[0].firstName,
                        moduleConfigurationLoading: false
                    });
                    i18n.changeLanguage(familyMemberCollection[0].selectedLanguage)
                } else {
                    updateUserContext({
                        ...userContext,
                        curMemberSelected: '',
                        moduleConfigurationLoading: false
                    });
                }
            } else {
                navigate("/stage-selection");
            }
        } catch (error) {
            setLoading(false);
            if (error) {
                setAlertMessage({
                    isSuccess: true,
                    message: error as string,
                });
            }
        }
        setLoading(false);

        // fetchAllmembers();
    }

    async function SubmitThoughts(value: any) {
        console.log('----- submit thoughts -------')
        try {
            let thoughtInitData = value;
            console.log('thoughtInitData', thoughtInitData)

        } catch (e: any) {
            updateUserContext({
                ...userContext,
                isAlert: true,
                alertMessage: e.message,
                alertType: AlertType.Error,
            });
        }
    }

    async function fetchInstruction(descriptionCode: any) {
        setLoading(true);
        setOpenEmergencyConditionDialog(true);

        try {
            let params = new URLSearchParams();
            params.append("code", descriptionCode)
            const result: any = await GetInstruction(params);
            let emergencyConditionDetail = result.data.data;
            setEmergencyConditionData(emergencyConditionDetail);
        } catch (error) {
            if (error) {
                setAlertMessage({
                    isSuccess: true,
                    message: error as string,
                });
            }
        }
        setLoading(false);
    }

    async function fetchMotherRegardingModule() {
        try {
            updateUserContext({
                ...userContext,
                moduleConfigurationLoading: true
            });
            let params = new URLSearchParams();
            params.append("lmpDate", userContext.memberDetail.lastManstrualPeriodDate);
            params.append("gender", userContext.memberDetail.gender);
            const result: any = await GetMotherModuleConfiguration(params);
            let dashboardCard = result.data.data
            setVisibleDashboardCardData(dashboardCard.sort());
            let moduleConfiguration = userContext.moduleConfiguration;
            moduleConfiguration = dashboardCard;
            updateUserContext({
                ...userContext,
                moduleConfiguration: moduleConfiguration,
                moduleConfigurationLoading: false
            });
        } catch (error: any) {
            if (error) {
                setAlertMessage({
                    isSuccess: true,
                    message: error as string,
                });
            }
        }
    }

    async function fetchModule() {
        try {
            updateUserContext({
                ...userContext,
                moduleConfigurationLoading: true
            });
            let params = new URLSearchParams();
            params.append("birthDate", userContext.memberDetail.birthDate);
            params.append("gender", userContext.memberDetail.gender);
            params.append("language", userContext.memberDetail.curMemberSelectedLanguage);
            const result: any = await GetModuleConfiguration(params);
            let dashboardCard = result.data.data;
            setVisibleDashboardCardData(dashboardCard.sort());
            // let moduleConfiguration = userContext.moduleConfiguration;
            let moduleConfiguration = userContext.moduleConfiguration;
            moduleConfiguration = dashboardCard;
            updateUserContext({
                ...userContext,
                moduleConfiguration: moduleConfiguration,
                moduleConfigurationLoading: false
            });
        }
        catch (error: any) {
            if (error) {
                setAlertMessage({
                    isSuccess: true,
                    message: error as string,
                });
            }
        }
    }

    useEffect(() => {
        updateUserContext({
            ...userContext,
            isEditMemberFromBottomSheet: false,
            isAddMember: false,
            isAddMemberFromList: false,
            currFamilyMemberId: 0,
            isComeFromStageSelection: false,
            isAddMaternalDetail: false,
            isEditMaternalDetail: false,
            isGoToMaternalDetail: false,
            isBabyDelivered: false,
            isWifePregnant: false,
            curMaternalDetailId: -1,
        });
        if (userContext.loginDetail !== undefined && userContext.loginDetail.userId > 0) {
            fetchData(false);
        }
    }, []);

    useEffect(() => {
        if (userContext.memberDetail !== undefined && userContext.memberDetail.birthDate !== '') {
            let week = ageInDays(userContext.memberDetail.birthDate);
            fetchModule();
        }
        if (userContext.memberDetail !== undefined && userContext.memberDetail.lastManstrualPeriodDate !== null && userContext.memberDetail.lastManstrualPeriodDate !== '') {
            let week = ageInWeeks(userContext.memberDetail.lastManstrualPeriodDate);
            if (+week > 13) {
                fetchMotherRegardingModule();
            }
        }

        if (userContext.memberDetail !== undefined && userContext.memberDetail !== null && userContext.memberDetail.familyMemberId > 0 && userContext.memberDetail.gender !== null && userContext.memberDetail.familyRelationName !== null) {
            getCurMemberIcon(userContext.memberDetail.familyMemberId)
        }
    }, [userContext.memberDetail])

    useEffect(() => {
        if (familyMemberDetail.length > 0) {
            // fetchAllmembers();
        }
    }, [familyMemberDetail])
    return (
        <>
            <Backdrop sx={{ color: "#fff", zIndex: 4 }}
                invisible={true}
                open={isBottomSheetOpen}
                onClick={() => close()}></Backdrop>
            <Box className="custom-head">
                <Box className="menu-box">
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        onClick={handleDrawerToggle}>
                        <SortRoundedIcon />
                    </IconButton>
                </Box>
                <Box className="profile-box">
                    <Box className="siren-icon-box" onClick={goToSiren}>
                        <IconButton disableRipple className="siren-icon-button">
                            <img className="siren-icon" src={sirenIcon} alt="logo" />
                        </IconButton>
                    </Box>
                    {/* <Box className="help-icon-box" onClick={goToHelp}>
                        <HelpOutlineRoundedIcon
                            color="primary"
                            className="help-icon" />
                    </Box> */}
                    <Box className="notification-icon-box" onClick={goToOpenNotification}>
                        {userContext.memberDetail.notificationCount !== undefined && userContext.memberDetail.notificationCount !== null && userContext.memberDetail.notificationCount !== 0 ? <Box className="notification-count-box"><Typography className="count-text">{userContext.memberDetail.notificationCount}</Typography></Box> : <></>}
                        <NotificationsRoundedIcon
                            color="primary"
                            className="notification-icon" />
                    </Box>
                    <Box className={userContext.memberDetail.gender === "FEMALE"
                        ? "set-img-box female-selected"
                        : "set-img-box male-selected"}
                        onClick={(data: any) => openMemberList(data)}>
                        {userContext.memberDetailLoading === true ?
                            <Skeleton animation="wave" variant="rounded" width={30} height={30} sx={{ borderRadius: '50%' }} /> :
                            <Avatar
                                className="img-preview"
                                alt="logo"
                                src={isProfileImage === false ? boyIcon : curMemberImage}
                            />
                        }
                    </Box>
                </Box>
            </Box>
            {props.children}

            {/* sidebar  */}
            <Box className="sidebar-menu-box">
                <Box
                    component="nav"
                    sx={{
                        width: { sm: drawerWidth },
                        flexShrink: { sm: 0 },
                    }}
                    aria-label="mailbox folders"
                    className="sidebar-nav"
                >
                    <Drawer
                        container={drawerContainer}
                        variant="temporary"
                        open={mobileOpen}
                        onClose={handleDrawerToggle}
                        ModalProps={{
                            keepMounted: true,
                        }}
                        anchor="left"
                        sx={{
                            display: { xs: "block" },
                            "& .MuiDrawer-paper": {
                                boxSizing: "border-box",
                                width: drawerWidth,
                                overflowX: "hidden",
                            },
                            "& .MuiPaper-root": { left: 0 },
                        }}
                        className="menu-sidebar"
                    >
                        <div>
                            <Box role="presentation">
                                <Box className="login-profile">
                                    <Box className="profile-image-box">

                                        <img
                                            src={getCurLoginMemberIcon()}
                                            alt="logo"
                                            className={
                                                userContext.loginDetail.gender === "MALE"
                                                    ? "profile-image-male"
                                                    : "profile-image-female"
                                            }
                                        />
                                    </Box>
                                    <Box className='hidden-box' onClick={() => goToOpenVerifyCode(countTap + 1)}>
                                    </Box>
                                    <Box className="profile-text-box">
                                        <Typography className="profile-name" >
                                            {" "}
                                            {userContext.loginDetail.firstName +
                                                " " +
                                                userContext.loginDetail.lastName}
                                        </Typography>
                                        {/* <Typography className="profile-subtext">
                                            {userContext.loginDetail.familyRelationName}
                                        </Typography> */}
                                    </Box>
                                </Box>
                            </Box>
                            <Divider />
                            <Box className='menu-option-main-box'>
                                <List className="menu-option" disablePadding>
                                    <ListItem key="dashboard" disablePadding>
                                        <ListItemButton onClick={goToDashboard} disableRipple>
                                            <ListItemIcon>
                                                <HomeRoundedIcon />
                                            </ListItemIcon>
                                            <ListItemText
                                                primary={<Typography>{t('Sidebar.Home')}</Typography>}
                                            />
                                        </ListItemButton>
                                    </ListItem>
                                </List>
                                <List className="menu-option" disablePadding>
                                    <ListItem key="dashboard" disablePadding>
                                        <ListItemButton onClick={goToMemberList} disableRipple>
                                            <ListItemIcon>
                                                <FamilyRestroomRoundedIcon />
                                            </ListItemIcon>
                                            <ListItemText
                                                primary={<Typography>{t('Sidebar.Family members')}</Typography>}
                                            />
                                        </ListItemButton>
                                    </ListItem>
                                </List>
                                <List className="menu-option" disablePadding>
                                    <ListItem key="aboutus" disablePadding>
                                        <ListItemButton onClick={goToAboutUs} disableRipple>
                                            <ListItemIcon>
                                                <InfoRoundedIcon />
                                            </ListItemIcon>
                                            <ListItemText
                                                primary={<Typography>{t('Sidebar.About us')}</Typography>}
                                            />
                                        </ListItemButton>
                                    </ListItem>
                                </List>
                                {/* <List className="menu-option" disablePadding>
                                    <ListItem key="help" disablePadding>
                                        <ListItemButton onClick={goToHelp} disableRipple>
                                            <ListItemIcon>
                                                <HelpRoundedIcon />
                                            </ListItemIcon>
                                            <ListItemText
                                                primary={<Typography>{t('Sidebar.Help')}</Typography>}
                                            />
                                        </ListItemButton>
                                    </ListItem>
                                </List> */}
                                {/*<List className="menu-option">
                                    <ListItem key="setting" disablePadding>
                                        <ListItemButton onClick={goToSetting} disableRipple>
                                            <ListItemIcon>
                                                <SettingsRoundedIcon />
                                            </ListItemIcon>
                                            <ListItemText
                                                primary={<Typography>{t('Sidebar.Settings')}</Typography>}
                                            />
                                        </ListItemButton>
                                    </ListItem>
                                    </List>*/}
                                <List className="menu-option menu-last-option" disablePadding>
                                    <ListItem key="logOut" disablePadding>
                                        <ListItemButton onClick={handleLogout} disableRipple>
                                            <ListItemIcon>
                                                <PowerSettingsNewRoundedIcon />
                                            </ListItemIcon>
                                            <ListItemText
                                                primary={<Typography>{t('Sidebar.Logout')}</Typography>}
                                            />
                                        </ListItemButton>
                                    </ListItem>
                                </List>
                            </Box>
                            {/* <List className="menu-option menu-last-option" disablePadding>
                                <ListItem key="logOut" disablePadding>
                                    <ListItemButton onClick={handleLogout}>
                                        <ListItemIcon>
                                            <PowerSettingsNewRoundedIcon />
                                        </ListItemIcon>
                                        <ListItemText primary={<Typography>Logout</Typography>} />
                                    </ListItemButton>
                                </ListItem>
                            </List> */}
                        </div>
                    </Drawer>
                </Box>
            </Box>

            {/* bottomsheet */}
            {isBottomSheetOpen === true ? (
                <Box className="bottom-sheet-box" style={{ overflow: "hidden" }}>
                    <a.div
                        className={styles.sheet}
                        {...bind()} style={{ display, bottom: `calc(-100vh + ${height}px)`, y }}>
                        {/* {...bind()} style={{ display, bottom: `-30px`, y }}> */}

                        <Box className="top-bar"></Box>
                        {/* <Box className="member-list">
                                {familyMemberDetail.length > 0 &&
                                    familyMemberDetail.map((row: any, index: any) => (
                                        loading === true ? <Skeleton animation="wave" variant="rounded" width={400} height={40} sx={{ borderRadius: '8px', margin: '10px' }} />
                                            : <Box className="member-item" key={index}>
                                                <Box
                                                    className="left-part"
                                                    onClick={() => selectMember(row.familyMemberId)}>
                                                    <Box>
                                                        <img
                                                            src={
                                                                row.profileImageBase !== null
                                                                    ? row.profileImageBase
                                                                    : row.gender === "FEMALE"
                                                                        ? row.familyRelationName === "MOTHER"
                                                                            ? womanIcon
                                                                            : girlIcon
                                                                        : row.familyRelationName === "FATHER"
                                                                            ? manIcon
                                                                            : boyIcon
                                                            }
                                                            alt="logo"
                                                            className={
                                                                row.gender === "FEMALE"
                                                                    ? "female-item"
                                                                    : "male-item"
                                                            }
                                                        />
                                                    </Box>
                                                    <Box>
                                                        <Typography className={row.gender === "FEMALE" ? "member-name female" : "member-name male"}>
                                                            {row.firstName}
                                                        </Typography>
                                                        <Typography className="member-detail">
                                                            {row.familyRelationName +
                                                                " " +
                                                                (row.birthDate === ""
                                                                    ? "(" + row.age + "y)"
                                                                    : convertInWords(row.birthDate))}
                                                        </Typography>
                                                    </Box>
                                                </Box>
                                                <Box className="right-part">
                                                    <Box className="edit-box">
                                                        <IconButton
                                                            aria-label="back"
                                                            disableRipple
                                                            onClick={() =>
                                                                currMemberEdit(
                                                                    row.familyMemberId,
                                                                    row.familyRelationName
                                                                )
                                                            }>
                                                            <EditRoundedIcon className="edit-icon" />
                                                        </IconButton>
                                                    </Box>
                                                    <Box className="delete-box">
                                                        {userContext.loginDetail.familyMemberId ===
                                                            row.familyMemberId ? (
                                                            <></>
                                                        ) : (
                                                            <IconButton
                                                                aria-label="back"
                                                                disableRipple
                                                                onClick={() =>
                                                                    currMemberDelete(row.familyMemberId)
                                                                }>
                                                                <DeleteRoundedIcon className="delete-icon" />
                                                            </IconButton>
                                                        )}
                                                    </Box>
                                                </Box>
                                            </Box>
                                    ))}
                                <Box className="add-member-item" onClick={addMemberClick}>
                                    <img src={addUserIcon} alt="logo" />
                                    <Typography>Add member </Typography>
                                </Box>
                            </Box> */}


                        {/* {loading ?
                                (_.times(4, (i) => (
                                    <Stack spacing={1} key={i} sx={{ margin: '15px' }}>
                                        <Skeleton animation="wave" variant="rounded" sx={{ borderRadius: '8px', width: 'auto', height: '45px' }} />
                                    </Stack>
                                ))) : ( */}
                        <>
                            {loading ?
                                (_.times(4, (i) => (
                                    <Stack spacing={1} key={i} sx={{ margin: '15px' }}>
                                        <Skeleton animation="wave" variant="rounded" sx={{ borderRadius: '8px', width: 'auto', height: '45px' }} />
                                    </Stack>
                                ))) : (
                                    <Box className="custom-sheet-member-list" >
                                        {familyMemberDetail.length > 0 &&
                                            familyMemberDetail.map((row: any, index: any) => (
                                                <Box className="member-item" key={index}>
                                                    <Box
                                                        className="left-part"
                                                        onClick={() => selectMember(row.familyMemberId)}>
                                                        <Box>
                                                            <img
                                                                src={
                                                                    row.profileImageBase !== null
                                                                        ? row.profileImageBase
                                                                        : row.gender === "FEMALE"
                                                                            ? row.familyRelationName === "MOTHER"
                                                                                ? womanIcon
                                                                                : girlIcon
                                                                            : row.familyRelationName === "FATHER"
                                                                                ? manIcon
                                                                                : boyIcon
                                                                }
                                                                alt="logo"
                                                                loading="lazy"
                                                                className={
                                                                    row.gender === "FEMALE"
                                                                        ? "female-item"
                                                                        : "male-item"
                                                                }
                                                            />
                                                        </Box>
                                                        <Box>
                                                            <Typography className={row.gender === "FEMALE" ? "member-name female" : "member-name male"}>
                                                                {/* {row.firstName} */}
                                                                {row.familyRelationName === "MOTHER" ? ((row.isChildBorn === false || row.isTermination === false) ? row.firstName + ' (Expecting)' : row.firstName) : row.firstName}

                                                            </Typography>
                                                            <Typography className="member-detail">
                                                                {row.familyRelationName +
                                                                    " " +
                                                                    (row.birthDate === ""
                                                                        ? "(" + row.age + "y)"
                                                                        : convertInWords(row.birthDate))}
                                                            </Typography>
                                                        </Box>
                                                    </Box>
                                                    <Box className="right-part">
                                                        {/* <Box className="edit-box">
                                                                <IconButton
                                                                    aria-label="back"
                                                                    disableRipple
                                                                    onClick={() =>
                                                                        currMemberEdit(
                                                                            row.familyMemberId,
                                                                            row.familyRelationName
                                                                        )
                                                                    }>
                                                                    <EditRoundedIcon className="edit-icon" />
                                                                </IconButton>
                                                            </Box>
                                                            <Box className="delete-box">
                                                                {userContext.loginDetail.familyMemberId ===
                                                                    row.familyMemberId ? (
                                                                    <></>
                                                                ) : (
                                                                    <IconButton
                                                                        aria-label="back"
                                                                        disableRipple
                                                                        onClick={() =>
                                                                            currMemberDelete(row.familyMemberId)
                                                                        }>
                                                                        <DeleteRoundedIcon className="delete-icon" />
                                                                    </IconButton>
                                                                )}
                                                            </Box> */}
                                                    </Box>
                                                </Box>
                                            ))}
                                    </Box>)}
                            <Box className="add-member-item" onClick={addMemberClick}>
                                <img src={addUserIcon} alt="logo" loading="lazy" />
                                <Typography>Add member </Typography>
                            </Box>
                        </>
                        {/* )} */}
                    </a.div>
                </Box >
            ) : (<></>)}

            <Dialog
                maxWidth="md"
                fullWidth={true}
                open={memberDeleteModalOpen}
                onClose={closeDeleteModal}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                className="user-verify-dialog-box">
                <DialogContent>
                    <DialogContentText>
                        Are you sure want to delete this Member?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <LoadingButton
                        variant="contained"
                        onClick={() => deleteMember()}
                        className=""
                        loading={deleteLoading}>
                        Yes
                    </LoadingButton>
                    <Button
                        variant="outlined"
                        onClick={() => closeDeleteModal()}
                        className="">
                        No
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog
                maxWidth="md"
                fullWidth={true}
                open={isHelpDialog}
                onClose={handleCloseHelpDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                className="user-help-dialog-box">
                <Box className="help-main-box">
                    <Box className="user-close-box">
                        <Box className='close-box' onClick={handleCloseHelpDialog}>
                            <IconButton
                                edge="start"
                                color="inherit"
                                aria-label="close"
                                className='close-button'>
                                <CloseRoundedIcon />
                            </IconButton>
                        </Box>
                    </Box>
                    <Formik
                        enableReinitialize
                        initialValues={thoughtsData}
                        onSubmit={(values) => {
                            SubmitThoughts(values);
                        }}
                        validationSchema={valildationSchema}>
                        {(props) => {
                            return (
                                <Form>
                                    <Box className="user-content">
                                        Share your thoughts
                                        <TextField
                                            id="thoughts"
                                            multiline
                                            rows={4}
                                            onChange={(e: any) => {
                                                props.setFieldValue(
                                                    "thoughts",
                                                    e.target.value !== null
                                                        ? e.target.value
                                                        : "",
                                                    true
                                                );
                                            }}
                                            // onBlur={props.handleBlur}
                                            value={props.values.thoughts}
                                            error={
                                                props.values.thoughts === "" &&
                                                    props.errors.thoughts &&
                                                    props.touched.thoughts
                                                    ? true
                                                    : false
                                            }
                                            className={
                                                props.values.thoughts === "" &&
                                                    props.errors.thoughts &&
                                                    props.touched.thoughts
                                                    ? "text-input validation-message"
                                                    : "text-input"
                                            }
                                        />
                                        {props.values.thoughts === "" &&
                                            props.errors.thoughts &&
                                            props.touched.thoughts && (
                                                <Typography
                                                    className={
                                                        props.values.thoughts === "" &&
                                                            props.errors.thoughts &&
                                                            props.touched.thoughts
                                                            ? "validation-message"
                                                            : "validation-message"
                                                    }>
                                                    {props.errors.thoughts}
                                                </Typography>
                                            )}
                                    </Box>
                                    <Box className="btns-box">
                                        <LoadingButton
                                            variant="contained"
                                            type="submit"
                                            className="btn-submit">
                                            Submit
                                        </LoadingButton>
                                        {/* <Button
                                            variant="outlined"
                                            onClick={handleCloseHelpDialog}
                                            className="btn-cancel">
                                            Cancel
                                        </Button> */}
                                    </Box>
                                </Form>
                            )
                        }}
                    </Formik>
                </Box>
            </Dialog>

            <Dialog
                fullScreen
                open={openEmergencyConditionDialog}
                onClose={handleCloseEmergencyConditionDialog}
                TransitionComponent={Transition}
                className="custom-popup-header">
                {emergencyConditionData !== undefined && emergencyConditionData !== null && emergencyConditionData.map((row: any, index: any) => (
                    <React.Fragment key={index}>
                        <AppBar sx={{ position: 'relative' }} color='secondary'>
                            <Toolbar className='custom-popup-header' >
                                <Box className='header-empty-box'></Box>
                                <Box className='header-text-box'>
                                    <Typography variant="h6" component="div">
                                        {row.title}
                                    </Typography>
                                </Box>
                                <Box className='close-box'>
                                    <IconButton
                                        edge="start"
                                        color="inherit"
                                        onClick={handleCloseEmergencyConditionDialog}
                                        aria-label="close"
                                        className='close-button'>
                                        <CloseIcon />
                                    </IconButton>
                                </Box>
                            </Toolbar>
                        </AppBar>
                        <DialogContent>
                            {loading ?
                                <Stack spacing={1} sx={{ marginBottom: '15px' }}>
                                    <Skeleton animation="wave" variant="rounded" height={30} sx={{ borderRadius: '8px', margin: '10px', width: '95%' }} />
                                    <Skeleton animation="wave" variant="rounded" height={30} sx={{ borderRadius: '8px', margin: '10px', width: '95%' }} />
                                    <Skeleton animation="wave" variant="rounded" height={30} sx={{ borderRadius: '8px', margin: '10px', width: '95%' }} />
                                    <Skeleton animation="wave" variant="rounded" height={30} sx={{ borderRadius: '8px', margin: '10px', width: '95%' }} />
                                    <Skeleton animation="wave" variant="rounded" height={30} sx={{ borderRadius: '8px', margin: '10px', width: '95%' }} />
                                    <Skeleton animation="wave" variant="rounded" height={30} sx={{ borderRadius: '8px', margin: '10px', width: '95%' }} />
                                    <Skeleton animation="wave" variant="rounded" height={30} sx={{ borderRadius: '8px', margin: '10px', width: '45%' }} />
                                </Stack>
                                :
                                <CustomPopup descriptionData={row.description} />
                            }

                        </DialogContent>
                    </React.Fragment>
                ))}
            </Dialog>

            <Dialog
                maxWidth="md"
                fullWidth={true}
                open={openVerificationPopup}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                className="code-verify-dialog-box">
                <Box className='code-box'>
                    <TextField
                        id="name"
                        label="Code"
                        fullWidth
                        size="small"
                        onChange={(e: any) => {
                            setCode(e.target.value)
                        }}
                        onBlur={props.handleBlur}
                        value={code}
                        inputProps={{ maxLength: 200 }}
                        variant="outlined" />
                    {errorCode === true ?
                        <Typography className="validation-message">
                            Invalid code
                        </Typography> : <></>
                    }

                    <DialogActions className="code-action">
                        <LoadingButton
                            variant="contained"
                            onClick={() => goToSendNotification(code)}
                            className=""
                            loading={deleteLoading}>
                            Ok
                        </LoadingButton>
                        <Button
                            variant="outlined"
                            onClick={() => closeVerifyPopup()}
                            className="">
                            Cancel
                        </Button>
                    </DialogActions>
                </Box>

            </Dialog>
        </>
    )
}