import React, { useContext, useEffect, useState } from 'react';
import './OtpVerification.scss';
import { PreferencesContext } from "../../PreferenceContext";
import { Box, Button, Dialog, DialogActions, DialogContent, Fade, Slide, TextField, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import OtpInput from 'react-otp-input';
import { Form, Formik } from 'formik';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { TransitionProps } from '@mui/material/transitions';
import { MobileRegistrartion, OTPVerification } from '../../services/AuthService';
import { GetIsParentExist, GetMotherList } from '../../services/ParentService';
import { LoginDetail } from '../../services/models/Context';
import CustomButton from '../../components/CustomButton/CustomButton';
import logo from '../../assets/images/logo.png';
import RefreshRoundedIcon from '@mui/icons-material/RefreshRounded';
import ArrowForwardRoundedIcon from '@mui/icons-material/ArrowForwardRounded';
import { LoadingButton } from '@mui/lab';

const formInitialValues = {
    otp: "",
};

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function OtpVerification() {
    const { userContext, updateUserContext } = useContext(PreferencesContext);
    const [loading, setLoading] = useState(false);
    const [otp, setOtp] = useState(formInitialValues);
    const [inputlength, setInputLength] = useState(false);
    const [minutes, setMinutes] = useState(0);
    const [seconds, setSeconds] = useState(60);
    const [openModal, setModal] = useState(false);
    const [wrong, setwrong] = useState(false);
    const [userData, setUserData] = useState(false);
    const [isAdminToLogin, setIsAdminToLogin] = useState(false);
    const [isUserDataExist, setUserDataExist] = useState(false);
    const [openVerificationPopup, setOpenVerificationPopup] = useState(false);
    const [code, setCode] = useState('');
    const [errorCode, setErrorCode] = useState(false);
    const [adminOtpLoading, setAdminOtpLoading] = useState(false);
    const [isFocus, setFocus] = useState(false);
    const [isAdminOtpInvalid, setIsAdminOtpInvalid] = useState(false);
    const [showAlert, setAlertMessage] = useState({
        isSuccess: false,
        message: "",
    });
    const { detect } = require("detect-browser");
    const browser = detect();

    let navigate = useNavigate();

    function backClick() {
        navigate("/");
    }

    const goToBack = () => {
        navigate("/");
    }

    const closeVerifyPopup = () => {
        setOpenVerificationPopup(false);
        setCode('');
        setErrorCode(false);
        navigate("/");
    }

    async function checkMotherExist() {
        let motherDataParams = new URLSearchParams();
        motherDataParams.append("familyId", userContext.loginDetail.familyId.toString());
        motherDataParams.append("familyRelationName", "MOTHER");
        const isMotherAlreadyExist: any = await GetMotherList(motherDataParams);
        if (isMotherAlreadyExist.data.data.length > 0) {
            navigate("/maternal-detail");
        }
        else {
            navigate("/spouse");
        }
    }

    function goToStageSelection() {
        if (userContext.stage === "") {
            navigate("/stage-selection");
        }
        else if (userContext.loginDetail.familyId === 0) {
            navigate("/parent");
        }
        else if (userContext.loginDetail.familyId > 0 && userContext.stage === "EXPECTING") {

            if (userContext.isRegistrationComplete === true) {
                navigate("/dashboard");
            }
            else {
                checkMotherExist();
            }
        }
        else if (userContext.loginDetail.familyId > 0 && userContext.stage === "PARENTS") {
            if (userContext.isRegistrationComplete === true) {
                navigate("/dashboard");
            }
            else {
                navigate("/child-detail");
            }
        }
        else {
            navigate("/dashboard");
        }
    };

    async function goToOtpVerification(props: any) {
        setModal(false);
        if (props !== null) {
            props.setFieldValue(
                `otp`,
                "",
                true
            );
        } else {
            if (isAdminOtpInvalid === true) {
                navigate("/");
            }
        }
        // setMinutes(0);
        // setSeconds(0);
        setLoading(false);
    }

    async function checkParentExist(id: any, stage: any, isUserAlreadyExist: any) {
        setLoading(true);
        let deviceOs = browser.os;
        let osVersion = browser.version;
        let fullBrowserDetail2 = browser.name;
        // var fullBrowserDetail =
        //     fullBrowserDetail0 +
        //     " - " +
        //     fullBrowserDetail1 +
        //     " - " +
        //     fullBrowserDetail2;

        // let browserDetail = fullBrowserDetail;

        try {
            let params = new URLSearchParams();
            params.append("familyId", id);
            params.append("mobileNumber", userContext.mobileNumber.split("+")[1]);
            params.append("deviceOs", deviceOs);
            params.append("osVersion", osVersion);
            const isParentAlreadyExist: any = await GetIsParentExist(params);
            if (isParentAlreadyExist && isParentAlreadyExist.data !== null && isParentAlreadyExist.data.data.familyData !== null) {
                let loginDetail = new LoginDetail();
                loginDetail = {
                    userId: id,
                    familyId: isParentAlreadyExist.data.data.familyData.familyId,
                    familyMemberId: isParentAlreadyExist.data.data.familyData.familyMemberId,
                    familyRelationName: isParentAlreadyExist.data.data.familyData.familyRelationName,
                    birthDate: isParentAlreadyExist.data.data.familyData.birthDate,
                    firstName: isParentAlreadyExist.data.data.familyData.firstName,
                    lastName: isParentAlreadyExist.data.data.familyData.lastName,
                    profileImageBase: isParentAlreadyExist.data.data.familyData.profileImageBase,
                    gender: isParentAlreadyExist.data.data.familyData.gender,
                    familyName: isParentAlreadyExist.data.data.familyData.lastName + "'s Family"
                }
                updateUserContext({
                    ...userContext,
                    stage: stage,
                    expectingChildNo: isParentAlreadyExist.data.data.familyData.expectingChildNo,
                    loginDetail: loginDetail,
                    isRegistrationComplete: isUserAlreadyExist,
                    loginExpiryTime: isParentAlreadyExist.data.data.tokenExpiryTime,
                    token: isParentAlreadyExist.data.data.token
                });
                // setModal(true);
                // setwrong(false);
                // setUserDataExist(true);
                setUserData(true);
            }
            else {
                let loginDetail = new LoginDetail();
                loginDetail.userId = id;
                updateUserContext({
                    ...userContext,
                    loginDetail: loginDetail,
                    loginExpiryTime: isParentAlreadyExist.data.data.tokenExpiryTime,
                    token: isParentAlreadyExist.data.data.token,
                    stage: stage
                });
                // setModal(true);
                // setwrong(false);
                // setUserDataExist(true);
                setUserData(true);
            }
        }
        catch (error) {
            setLoading(false);
            if (error) {
                setAlertMessage({
                    isSuccess: true,
                    message: error as string,
                });
            }
        }
    }

    // get otp from....
    async function submitOtp(value: any) {
        setOtp(value);
        if (value !== null) {
            setLoading(true);
            const code = isAdminToLogin === true ? value : value.otp
            let deviceOs = browser.os;
            let osVersion = browser.version;
            let fullBrowserDetail2 = browser.name;

            try {
                let mobileNumber = userContext.mobileNumber.split("+")[1]
                const otpVerify: any = await OTPVerification({
                    mobileNumber: mobileNumber,
                    otp: code,
                    isAdmin: isAdminToLogin === true ? true : false,
                    // deviceOs: deviceOs,
                    // osVersion: osVersion,
                    // fullBrowserDetail2: fullBrowserDetail2
                });
                // setIsAdminToLogin(false);
                const search = window.location.search;
                const params = new URLSearchParams(search);
                const urlRemoveLocalStorage = params.get('Admin');
                if (urlRemoveLocalStorage !== null && urlRemoveLocalStorage === "1") {
                    setIsAdminOtpInvalid(true);
                    setLoading(false);
                }

                if (otpVerify.data != null) {
                    if (otpVerify.data.selectStage === "") {
                        setUserData(true);
                    }
                    else {
                         checkParentExist(otpVerify.data.userId, otpVerify.data.selectStage, otpVerify.data.isRegistrationComplete);
                    }
                }
                else {
                    if (otpVerify.data === null && otpVerify.message === 'Invalid otp!') {
                        setModal(true);
                        setwrong(true);
                    } else {
                        setUserData(true);
                         checkParentExist(otpVerify.data, otpVerify.data.selectStage, otpVerify.data.isRegistrationComplete);
                    }
                }              
            }
            catch (error) {
                setLoading(false);
                if (error) {
                    setAlertMessage({
                        isSuccess: true,
                        message: error as string,
                    });
                }
            }
        } else {
            setOpenVerificationPopup(true);
        }
    }

    // send request to get otp....
    async function resendOTP(props: any) {
        props.setFieldValue(
            `otp`,
            "",
            true
        );
        let mobileNumber = userContext.mobileNumber.split("+")[1]
        const user: any = await MobileRegistrartion({
            mobileNumber: mobileNumber,
            isDeleted: false
        });
        setMinutes(0);
        setSeconds(60);
    };

    useEffect(() => {
        const interval = setInterval(() => {
            if (seconds > 0) {
                setSeconds(seconds - 1);
            }

            if (seconds === 0) {
                if (minutes === 0) {
                    clearInterval(interval);
                    setInputLength(false);
                } else {
                    setSeconds(59);
                    setMinutes(minutes - 1);
                }
            }
        }, 1000);

        return () => {
            clearInterval(interval);
        };
    }, [seconds]);

    useEffect(() => {
        if (userData === true) {
            goToStageSelection();
        }
    }, [userData])

    useEffect(() => {
        const search = window.location.search;
        const params = new URLSearchParams(search);
        const urlRemoveLocalStorage = params.get('Admin');
        if (urlRemoveLocalStorage !== null && urlRemoveLocalStorage === "1") {
            setIsAdminToLogin(true);
            submitOtp(null);
        }
    }, [])

    return (
        <Fade in={true} timeout={{ enter: 500 }}>
            {isAdminToLogin === false ?
                <Box className="otp-main-box">
                    <Box className="img-box">
                        <img src={logo} alt="logo" />
                    </Box>
                    <Box className="middle-box">
                        <Box className="title-box">
                            <Typography className='subtitle-text'>We have sent a 6-digit verification code to<b>{userContext.countryCode} {userContext.phnNumber}.</b> Please enter the code to proceed further.</Typography>
                        </Box>
                        <Formik
                            initialValues={otp}
                            onSubmit={(values) => {
                                submitOtp(values);
                            }}>
                            {(props) => {
                                return (
                                    <Box className="form-box">
                                        <Form>
                                            <div id="otp"></div>
                                            <OtpInput
                                                shouldAutoFocus={true}
                                                onChange={(e: any) => {
                                                    // setFocus(true);
                                                    props.setFieldValue(
                                                        "otp",
                                                        e !== null ? e : "",
                                                        true
                                                    );

                                                    if (e.length === 6) {
                                                        setInputLength(true);
                                                    }
                                                    else {
                                                        setInputLength(false);
                                                    }
                                                }}
                                                shouldAutoFocus={true}
                                                value={props.values.otp}
                                                numInputs={6}
                                                inputType={'tel'}
                                                renderInput={(props, index) =>
                                                    <input {...props} />

                                                }
                                                containerStyle="otp-input"
                                                inputStyle='otp-input-style'
                                            />
                                            <Box className="bottom-text">
                                                {seconds > 0 || minutes > 0 ?
                                                    (
                                                        <Typography className='expire-time'>Retry in
                                                            <span> {minutes < 10 ? `0${minutes}` : minutes}:{seconds < 10 ? `0${seconds}` : seconds}</span>
                                                        </Typography>
                                                    )
                                                    : <Box className="resend-otp-box" onClick={() => resendOTP(props)}>
                                                        <RefreshRoundedIcon color="primary" />
                                                        <Typography color="primary"> Resend OTP</Typography>
                                                    </Box>
                                                }
                                            </Box>

                                            <CustomButton
                                                disabled={inputlength === true && (seconds > 0 || minutes > 0) ?
                                                    false
                                                    : true}
                                                class="otp-next-button"
                                                isLoading={loading}
                                                title='Submit'
                                            />
                                            <Typography className='instruction'>By clicking on verify, you agree to our Terms & Conditions</Typography>

                                            <Dialog
                                                maxWidth="xs"
                                                fullWidth={true}
                                                open={openModal}
                                                onClose={() => goToOtpVerification(props)}
                                                TransitionComponent={Transition}
                                                aria-labelledby="alert-dialog-title"
                                                aria-describedby="alert-dialog-description"
                                                className='user-verify-dialog-box'>
                                                <Box className="img-box">
                                                    {wrong ?
                                                        <ErrorOutlineIcon className='error-icon' /> :
                                                        <TaskAltIcon className='icon' />
                                                    }
                                                </Box>
                                                <DialogContent>
                                                    <Box id="alert-dialog-description" className="popup-text">
                                                        {wrong ?
                                                            <Typography className="popup-text1">Invalid Otp</Typography>
                                                            :
                                                            <Typography className="popup-text1">User is verified!</Typography>
                                                        }
                                                    </Box>
                                                </DialogContent>
                                                <DialogActions>
                                                    {wrong ?
                                                        <Button
                                                            variant='contained'
                                                            onClick={() => goToOtpVerification(props)}>
                                                            Ok
                                                        </Button>
                                                        : <Button
                                                            variant='contained'
                                                            onClick={goToStageSelection}>
                                                            Continue
                                                        </Button>
                                                    }
                                                </DialogActions>
                                            </Dialog>
                                        </Form>
                                    </Box>
                                )
                            }}
                        </Formik>


                        <Box className="bottom-box">
                            <Button color="primary"
                                className='register-button'
                                // endIcon={<ArrowForwardRoundedIcon />}
                                onClick={goToBack}>
                                Start again
                            </Button>
                        </Box>
                    </Box>
                    {/* <Box className="bottom-box-hidden"></Box>
                    <Box className="bottom-box">
                        <Button color="primary"
                            variant="contained" className='register-button'
                            endIcon={<ArrowForwardRoundedIcon />}
                            onClick={goToBack}>
                            Start again
                        </Button>
                    </Box> */}

                </Box> :
                <Box className="otp-main-box">
                    <Dialog
                        maxWidth="md"
                        fullWidth={true}
                        open={openVerificationPopup}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                        className="code-verify-dialog-box">
                        <Box className='code-box'>
                            <TextField
                                id="name"
                                label="Code"
                                fullWidth
                                size="small"
                                onChange={(e: any) => {
                                    setCode(e.target.value)
                                }}
                                // onBlur={props.handleBlur}
                                value={code}
                                inputProps={{ maxLength: 200 }}
                                variant="outlined" />
                            {errorCode === true ?
                                <Typography className="validation-message">
                                    Invalid code
                                </Typography> : <></>
                            }

                            <DialogActions className="code-action">
                                <LoadingButton
                                    variant="contained"
                                    onClick={() => submitOtp(code)}
                                    className=""
                                    loading={loading}>
                                    Ok
                                </LoadingButton>
                                <Button
                                    variant="outlined"
                                    onClick={() => closeVerifyPopup()}
                                    className="">
                                    Cancel
                                </Button>
                            </DialogActions>
                        </Box>
                    </Dialog>

                    <Dialog
                        maxWidth="xs"
                        fullWidth={true}
                        open={openModal}
                        onClose={() => goToOtpVerification(null)}
                        TransitionComponent={Transition}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                        className='user-verify-dialog-box'>
                        <Box className="img-box">
                            {wrong ?
                                <ErrorOutlineIcon className='error-icon' /> :
                                <TaskAltIcon className='icon' />
                            }
                        </Box>
                        <DialogContent>
                            <Box id="alert-dialog-description" className="popup-text">
                                {wrong ?
                                    <Typography className="popup-text1">Invalid Otp</Typography>
                                    :
                                    <Typography className="popup-text1">User is verified!</Typography>
                                }
                            </Box>
                        </DialogContent>
                        <DialogActions>
                            {wrong ?
                                <Button
                                    variant='contained'
                                    onClick={() => goToOtpVerification(null)}>
                                    Ok
                                </Button>
                                : <Button
                                    variant='contained'
                                    onClick={goToStageSelection}>
                                    Continue
                                </Button>
                            }
                        </DialogActions>
                    </Dialog>
                </Box>
            }
        </Fade>
    )
}
