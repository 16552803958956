export class Context {
    token: string = "";
    loginExpiryTime: string = "";
    mobileNumber: string = "";
    countryCode: string = "";
    phnNumber: string = "";
    stage: string = "";
    isThemeDark: boolean = false;
    expectingChildNo: number = 0;

    memberDetail: MemberDetail = new MemberDetail();
    moduleConfiguration: any = [];
    allFamilyMembersData: any = [];

    loginDetail: LoginDetail = new LoginDetail();
    spouseFamilyMemberId: number = 0;
    currFamilyMemberId: number = 0;
    isAlert: boolean = false;
    alertMessage: string = "";
    alertType: string = "success";

    curMemberSelected: string = "";
    curMaternalDetailId: number = -1;

    isGoToMaternalDetail: boolean = false;
    isComeFromStageSelection: boolean = false;
    moduleConfigurationLoading: boolean = false;
    memberDetailLoading: boolean = false;
    isRegistrationComplete: boolean = false;
    isAddMember: boolean = false;
    isAddMemberFromList: boolean = false;
    isEditMemberFromBottomSheet: boolean = false;
    isEditMaternalDetail: boolean = false;
    isAddMaternalDetail: boolean = false;
    isEditMemberFromList: boolean = false;
    isBabyDelivered: boolean = false;
    isAddChildDetail: boolean = false;
    isEdit: boolean = false
    isMotherExist: boolean = false;
    isUnauthorized: boolean = false;
    isWifePregnant: boolean = false;

    dateOfDelivery: string = "";
    symptomsCategoryCode: string = "";
    symptomsCategoryName: string = "";
    symptomsCode: string = "";
    symptomsName: string = "";
};

export class LoginDetail {
    userId: number = 0;
    familyId: number = 0;
    familyMemberId: number = 0;
    familyRelationName: string = "";
    birthDate: string = "";
    firstName: string = "";
    lastName: string = "";
    familyName: string = "";
    profileImageBase: string = "";
    gender: string = "";
}

export class MemberDetail {
    age: number = 0;
    birthDate: string = "";
    familyId: number = 0;
    familyMemberId: number = 0;
    familyRelationName: string = "";
    firstName: string = "";
    lastName: string = "";
    gender: string = "";
    isPrematureChild: boolean = false;
    isSpecialChild: boolean = false;
    profileImageBase: string = "";
    curMemberSelectedLanguage: string = "";
    maternalDetailId: number = 0;
    complicationsDetails: string = "";
    expectingChildNo: number = 0;
    expectingDateOfDelivery: string = "";
    gestationalAge: number = 0;
    gestationalAgeTime: string = "";
    isChildBorn: boolean = false;
    isTermination: boolean = false;
    isPragnentExpectingChild: boolean = false;
    lastManstrualPeriodDate: string = "";
    notificationCount: number = 0;
}