import React, { useContext, useEffect, useRef, useState } from "react";
import './VaccinationDetail.scss';
import { Box, Button, Collapse, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, Grid, IconButton, Skeleton, TextField, Typography } from "@mui/material";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';
import dayjs from "dayjs";
import backIcon from '../../assets/icons/back.svg';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import { useLocation, useNavigate } from "react-router-dom";
import { PreferencesContext } from "../../PreferenceContext";
import { AlertType } from "../../shared/AlertType";
import { MobileDatePicker } from "@mui/x-date-pickers";
import CustomButton from "../../components/CustomButton/CustomButton";
import FileUploadIcon from '@mui/icons-material/FileUpload';
import { CreateVaccine, GetListForVaccinationBrandNameSelect, GetListForVaccinationDoseNameSelect, GetListForVaccinationNameSelect, GetMemberVaccineDetailForEdit, GetMemberVaccineList } from "../../services/VaccinationService";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import { getSingleSelected } from "../../shared/CommonFuctions";
import { LoadingButton } from "@mui/lab";
import { TransitionGroup } from "react-transition-group";

interface IVaccine {
    familyId: string;
    familyMemberId: string;
    vaccineRowNo: number;
    memberVaccinationId: number;
    vaccinationId: string;
    vaccinationName: string;
    brandId: string;
    brandName: string;
    brandCode: string;
    doseId: string;
    doseName: string;
    doseCode: string;
    batchNumber: string;
    vaccineDocumentUpload: null;
    vaccineDocumentPreview: string;
    vaccineDocumentRaw: string;
    vaccineDocumentName: string;
    vaccineDocumentBase: string;
    serverImage: string;
    isCustom: boolean;
    isDeleted: boolean;
}

export default function VaccinationDetails() {
    const { userContext, updateUserContext } = useContext(PreferencesContext);
    const addButtonRef = useRef();
    const vaccineColl: IVaccine = {
        familyId: userContext.loginDetail.familyId.toString(),
        familyMemberId: userContext.memberDetail.familyMemberId.toString(),
        vaccineRowNo: 1,
        memberVaccinationId: 0,
        vaccinationId: '-1',
        vaccinationName: '',
        brandId: '-1',
        brandName: '',
        brandCode: '-1',
        doseId: '-1',
        doseName: '',
        doseCode: '-1',
        batchNumber: '',
        vaccineDocumentUpload: null,
        vaccineDocumentPreview: '',
        vaccineDocumentRaw: '',
        vaccineDocumentName: '',
        vaccineDocumentBase: '',
        serverImage: '',
        isCustom: false,
        isDeleted: false
    };
    interface vaccineDetail {
        memberVaccinationDetailId: number;
        sectorName: string;
        area: string;
        city: string;
        givenBy: string;
        givenOn: string;
        schedualOn: string;
        vaccinationData: [IVaccine];
    }
    const formInitialValues: vaccineDetail = {
        memberVaccinationDetailId: 0,
        sectorName: '',
        area: '',
        city: '',
        givenBy: '',
        givenOn: '',
        schedualOn: '',
        vaccinationData: [vaccineColl]
    };

    const [loading, setLoading] = useState(false);
    const [submitloading, setSubmitLoading] = useState(false);
    const [initiVaccinationData, setInitiVaccinationData] = useState(formInitialValues);
    const [openCreatevaccine, setOpenCreatevaccine] = useState(false);
    const [customVaccineIndex, setCustomVaccineIndex] = useState(0);
    const [openDeleteDocument, setOpenDeleteDocument] = useState(false);
    const [deleteLoading, setDeleteLoading] = useState(false);
    const [disabledErrors, setDisabledErrors] = useState(false);
    const [isDeleteEnabled, setIsDeleteEnabled] = useState(true);

    //  uploaded file name
    const [documentFileName, setDocumentFileName] = useState('');

    // file preview
    const [vaccineDocument, setVaccineDocument] = useState("");
    // const [vaccineDocument, setVaccineDocument] = useState({ preview: "", raw: "" });
    const [showAlert, setAlertMessage] = useState({
        isSuccess: false,
        message: "",
    });
    const location = useLocation();
    const [vaccinationNameList, setVaccinationNameList] = useState([]);
    const [vaccinationBrandNameList, setVaccinationBrandNameList] = useState([]);
    const [vaccinationDoseNameList, setVaccinationDoseNameList] = useState([]);
    const [deleteVaccineId, setDeleteVaccineId] = useState(-1);
    const [isLoading, setIsLoading] = useState(false);
    const [isDoseCustom, setIsDoseCustom] = useState(false);
    const [isShowDocumentPreview, setIsShowDocumentPreview] = useState(false);
    const [intSchedualedvaccineData, setIntSchedualedvaccineData] = useState([]);

    const sectorOptions = [
        { value: 'Private', label: 'Private' },
        { value: 'Government', label: 'Government' }
    ];

    const valildationSchema = Yup.object().shape({
        sectorName: Yup.string().required("Sector Name is required."),
        area: Yup.string().when("sectorName", {
            is: (sectorName: any) => (sectorName !== undefined && sectorName === "Government"),
            then: Yup.string(),
            otherwise: Yup.string().required("Area is required.")
        }),
        city: Yup.string().when("sectorName", {
            is: (sectorName) => (sectorName !== undefined && sectorName === "Government"),
            then: Yup.string(),
            otherwise: Yup.string().required("City is required.")
        }),
        givenBy: Yup.string().when("sectorName", {
            is: (sectorName) => (sectorName !== undefined && sectorName === "Government"),
            then: Yup.string(),
            otherwise: Yup.string().required("By whom dose given is required.")
        }),
        givenOn: Yup.date().required("Date is required."),
        vaccinationData: Yup.array().of(
            Yup.object().shape({
                vaccinationName: Yup.string().required("Vaccine name is required."),
                // brandName: Yup.string().required("Brand name is required."),
                doseName: Yup.string().required("Dose name is required."),
                // batchNumber: Yup.string().required("Batch number is required.")
            })
        ),
    });

    let navigate = useNavigate();

    function backClick() {
        navigate('/vaccination',
            { state: { activeTab: location.state.activeTab } }
        );
    }

    async function fetchVaccineName() {
        try {
            const vaccinationData: any = await GetListForVaccinationNameSelect();
            if (vaccinationData && vaccinationData.data !== null && vaccinationData.data.data !== null) {
                let vaccinationDataCollection = vaccinationData.data.data;
                if (location.state.subVaccinationId !== undefined && location.state.subVaccinationId !== null && location.state.subVaccinationId !== "") {
                    vaccinationDataCollection = [];
                    location.state.subVaccinationId.split(',').forEach(function (id) {
                        vaccinationDataCollection.push(vaccinationData.data.data.find(x => x.value === id));
                    });
                }
                setVaccinationNameList(vaccinationDataCollection);
            }
        }
        catch (error) {
            if (error) {
                updateUserContext({
                    ...userContext,
                    isAlert: true,
                    alertMessage: error as string,
                    alertType: AlertType.Error,
                });
            }
        }
    }

    async function fetchVaccineBrandName() {
        try {
            const vaccinationBrandData: any = await GetListForVaccinationBrandNameSelect();
            if (vaccinationBrandData && vaccinationBrandData.data !== null && vaccinationBrandData.data.data !== null) {
                let vaccinationDataCollection = vaccinationBrandData.data.data;
                setVaccinationBrandNameList(vaccinationDataCollection);
            }
        }
        catch (error) {
            if (error) {
                updateUserContext({
                    ...userContext,
                    isAlert: true,
                    alertMessage: error as string,
                    alertType: AlertType.Error,
                });
            }
        }
    }

    async function fetchVaccineDoseName() {
        try {
            const vaccinationDoseData: any = await GetListForVaccinationDoseNameSelect();
            if (vaccinationDoseData && vaccinationDoseData.data !== null && vaccinationDoseData.data.data !== null) {
                let vaccinationDataCollection = vaccinationDoseData.data.data;
                if (isDoseCustom === true) {
                    let customDoseData = vaccinationDataCollection.filter((x: any) => (+x.code === 0))
                    setVaccinationDoseNameList(customDoseData);
                }
                else {
                    setVaccinationDoseNameList(vaccinationDataCollection);
                }
                // setVaccinationDoseNameList(vaccinationDataCollection);
            }
        }
        catch (error) {
            if (error) {
                updateUserContext({
                    ...userContext,
                    isAlert: true,
                    alertMessage: error as string,
                    alertType: AlertType.Error,
                });
            }
        }
    }

    const vaccineCreateOption = (label: string, id: any) => ({
        label,
        value: id.toString(),
    });

    const brandCreateOption = (label: string, id: any) => ({
        label,
        value: id.toString(),
    });

    const doseCreateOption = (label: string, id: any) => ({
        label,
        value: id.toString(),
    });

    function handleVaccineCreate(inputValue: any, index: any, props: any) {
        setIsDoseCustom(true);
        let max: any = vaccinationNameList.reduce(function (prev: any, current: any) {
            if (+current.value > +prev.value) {
                return current;
            } else {
                return prev;
            }
        });

        let sendNewVaccineValue = max.value;
        let maxVaccineValue = sendNewVaccineValue++;

        const newOption = vaccineCreateOption(inputValue, sendNewVaccineValue);

        setIsLoading(false);
        setVaccinationNameList((prev: any) => [...prev, newOption]);
        props.setFieldValue(
            `vaccinationData[${index}].vaccinationId`,
            newOption.value,
            false
        );
        props.setFieldValue(
            `vaccinationData[${index}].vaccinationName`,
            newOption.label,
            true
        );
        props.setFieldValue(
            `vaccinationData[${index}].isCustom`,
            true,
            false
        );
        props.setFieldValue(
            `vaccinationData[${index}].brandId`,
            "-1",
            true
        );
        props.setFieldValue(
            `vaccinationData[${index}].doseId`,
            "-1",
            true
        );
        setCustomVaccineIndex(index);
        setOpenCreatevaccine(true);

    };

    const handleBrandCreate = (inputValue: any, index: any, props: any) => {
        setIsLoading(true);
        let max: any = vaccinationBrandNameList.reduce(function (prev: any, current: any) {
            if (+current.value > +prev.value) {
                return current;
            } else {
                return prev;
            }
        });

        let sendNewBrandValue = max.value
        let maxBrandValue = sendNewBrandValue++;

        const newOption = brandCreateOption(inputValue, sendNewBrandValue);
        setIsLoading(false);
        setVaccinationBrandNameList((prev: any) => [...prev, newOption]);
        props.setFieldValue(
            `vaccinationData[${index}].brandId`,
            newOption.value,
            true
        );
        props.setFieldValue(
            `vaccinationData[${index}].brandCode`,
            newOption.value,
            true
        );
        props.setFieldValue(
            `vaccinationData[${index}].brandName`,
            newOption.label,
            true
        );
    };

    const handleDoseCreate = (inputValue: any, index: any, props: any) => {
        setIsLoading(true);
        setIsDoseCustom(true);
        let max: any = vaccinationDoseNameList.reduce(function (prev: any, current: any) {
            if (+current.value > +prev.value) {
                return current;
            } else {
                return prev;
            }
        });
        let sendNewDoseValue = max.value;
        let maxDoseValue = sendNewDoseValue++;

        const newOption = doseCreateOption(inputValue, sendNewDoseValue);
        setIsLoading(false);
        setVaccinationDoseNameList((prev: any) => [...prev, newOption]);
        props.setFieldValue(
            `vaccinationData[${index}].doseId`,
            newOption.value,
            false
        );
        props.setFieldValue(
            `vaccinationData[${index}].doseCode`,
            newOption.value,
            false
        );
        props.setFieldValue(
            `vaccinationData[${index}].doseName`,
            newOption.label,
            false
        );
    };

    const handleDocumentUpload = (e: any, props: any, index: any) => {
        if (e.target.files.length) {
            var file = e.target.files[0];
            const fileSize = e.target.files.item(0).size;
            const fileMb = fileSize / 1024 ** 2;
            if (fileMb >= 2) {
                props.setFieldValue(
                    `vaccinationData[${index}].vaccineDocumentUpload`,
                    "",
                    true
                );
            }
            else {
                var reader = new FileReader();
                reader.onloadend = function () {
                    var Docraw: any = reader.result;
                    props.setFieldValue(
                        `vaccinationData[${index}].vaccineDocumentRaw`,
                        Docraw.split(",")[1],
                        true
                    );
                    props.setFieldValue(
                        `vaccinationData[${index}].vaccineDocumentBase`,
                        reader.result,
                        true
                    );
                }
                reader.readAsDataURL(file);
                props.setFieldValue(
                    `vaccinationData[${index}].vaccineDocumentPreview`,
                    URL.createObjectURL(e.target.files[0]),
                    true
                );
                props.setFieldValue(
                    `vaccinationData[${index}].vaccineDocumentName`,
                    e.target.files !== null ? e.target.files[0].name : "",
                    true
                );
            }
        }
    };

    const viewDocument = (docName: any, docPreview: any) => {
        setIsShowDocumentPreview(true);
        setDocumentFileName(docName);
        setVaccineDocument(docPreview);
    }

    const closeDocument = () => {
        setIsShowDocumentPreview(false);
    }
    // const downloadDocument = (downloadDoc) => {
    //     var content = downloadDoc;
    //     const downloadLink = document.createElement("a");
    //     downloadLink.href = content;
    //     downloadLink.download = documentFileName;
    //     downloadLink.click();
    // }

    const cancelDocument = (index: any) => {
        setOpenDeleteDocument(true);
        setDeleteVaccineId(index);
    }

    async function deleteDocument(props: any) {
        let detailColl: any = props.values.vaccinationData;
        detailColl[deleteVaccineId].vaccineDocumentUpload = null;
        detailColl[deleteVaccineId].vaccineDocumentName = "";
        detailColl[deleteVaccineId].vaccineDocumentBase = "";
        detailColl[deleteVaccineId].vaccineDocumentPreview = "";
        detailColl[deleteVaccineId].vaccineDocumentRaw = "";
        props.setFieldValue(
            `vaccinationData`,
            detailColl,
            false
        );
        setDeleteLoading(false);
        setOpenDeleteDocument(false);
    }

    const closeDeleteModal = () => {
        setOpenDeleteDocument(false);
    };

    const notShowingVaccine = [
        "DwPT/DaPT + Hep B + Hib + IPV",
        "Dose 1",
        "Dose 0"
    ];

    async function fetchData() {
        setLoading(true);
        if (location.state !== '' && location.state !== undefined && location.state !== null) {
            try {
                let params = new URLSearchParams();
                params.append("memberVaccinationDetailId", location.state.memberVaccinationDetailId);
                params.append("familyId", userContext.loginDetail.familyId.toString());
                params.append("familyMemberId", userContext.memberDetail.familyMemberId.toString());
                const result: any = await GetMemberVaccineDetailForEdit(params);
                let editDetails: any;
                editDetails = result.data.data;
                if (editDetails.vaccineDetails.length > 1) {
                    setIsDeleteEnabled(false);
                }
                let updatedValue: IVaccine;
                let detailColl: any = [];
                let colletion: any;
                let rowNo = 1;
                let newVaccineOption: any;

                editDetails.vaccineDetails.forEach((item: any, index: any, editValue: any) => {
                    if (location.state.subVaccinationId !== undefined && location.state.subVaccinationId !== null && location.state.subVaccinationId !== "") {
                        if (location.state.vaccinationName !== undefined && location.state.vaccinationName !== null && location.state.vaccinationName === editDetails.vaccineDetails[index].vaccinationName) {
                            newVaccineOption = {
                                label: editValue[index].vaccinationName,
                                value: editValue[index].vaccinationId.toString()
                            }
                            if (notShowingVaccine.indexOf(newVaccineOption.label) < 0) {
                                setVaccinationNameList((prev: any) => [...prev, newVaccineOption]);
                            }
                        }
                    }
                    else {
                        newVaccineOption = {
                            label: editValue[index].vaccinationName,
                            value: editValue[index].vaccinationId.toString()
                        }
                    }

                    const newVaccineBrandOption = {
                        label: editValue[index].brandName,
                        value: editValue[index].brandId.toString()
                    }

                    const newVaccineDoseOption = {
                        label: editValue[index].doseName,
                        value: editValue[index].doseId.toString()
                    }

                    if (editValue[index].isCustom === true) {
                        setVaccinationNameList((prev: any) => [...prev, newVaccineOption]);
                        setVaccinationBrandNameList((prev: any) => [...prev, newVaccineBrandOption]);
                        setVaccinationDoseNameList((prev: any) => [...prev, newVaccineDoseOption]);
                    }

                    updatedValue = {
                        memberVaccinationId: editValue[index].memberVaccinationId,
                        vaccineRowNo: rowNo++,
                        vaccinationId: editValue[index].vaccinationId,
                        vaccinationName: editValue[index].vaccinationName,
                        brandId: editValue[index].brandId,
                        brandName: editValue[index].brandName,
                        brandCode: editValue[index].brandCode,
                        doseId: editValue[index].doseId.toString(),
                        doseName: editValue[index].doseName,
                        doseCode: editValue[index].doseCode,
                        batchNumber: editValue[index].batchNumber,
                        vaccineDocumentBase: editValue[index].vaccineDocumentBase,
                        vaccineDocumentName: editValue[index].vaccineDocumentName,
                        serverImage: editValue[index].serverImage,
                        isDeleted: editValue[index].isDeleted
                    }
                    detailColl.push(updatedValue);
                });
                let fetchvalues: any = {
                    memberVaccinationDetailId: editDetails.memberVaccinationDetailId,
                    sectorName: editDetails.sectorName,
                    area: editDetails.area,
                    city: editDetails.city,
                    givenBy: editDetails.givenBy,
                    givenOn: editDetails.givenOn,
                    schedualOn: editDetails.schedualOn,
                    vaccinationData: detailColl
                }
                setInitiVaccinationData(fetchvalues);
            }
            catch (error) {
                updateUserContext({
                    ...userContext,
                    isAlert: true,
                    alertMessage: error.message,
                    alertType: AlertType.Error,
                });
            }
        }
        setLoading(false);
    }

    async function fetchMemberVaccineData() {
        setLoading(true);
        try {
            let params = new URLSearchParams();
            params.append("familyId", userContext.loginDetail.familyId.toString());
            params.append("familyMemberId", userContext.memberDetail.familyMemberId.toString());
            const result: any = await GetMemberVaccineList(params);
            let memberVaccineData = result.data.data;
            let schedualVaccine = memberVaccineData.vaccineCurrentDetail.filter((x: any) => (x.status === 'Overdue' || x.status === 'Current due'))
            setIntSchedualedvaccineData(schedualVaccine);
        } catch (error) {
            setLoading(false);
            if (error) {
                setAlertMessage({
                    isSuccess: true,
                    message: error as string,
                });
            }
        }
        setLoading(false);
    }

    const handleListAdd = (props: any) => {
        let detailColl = props.values.vaccinationData;
        const rowNos = detailColl.map((o) => {
            return o.vaccineRowNo;
        });
        const maxRowNo = Math.max(...rowNos);
        let newRowNo = maxRowNo + 1;

        let newDataRow: IVaccine = {
            memberVaccinationId: 0,
            vaccineRowNo: newRowNo,
            familyId: userContext.loginDetail.familyId.toString(),
            familyMemberId: userContext.memberDetail.familyMemberId.toString(),
            vaccinationId: '-1',
            vaccinationName: '',
            brandId: '-1',
            brandName: '',
            brandCode: '-1',
            doseId: '-1',
            doseName: '',
            doseCode: '-1',
            batchNumber: '',
            vaccineDocumentUpload: null,
            vaccineDocumentPreview: '',
            vaccineDocumentRaw: '',
            vaccineDocumentName: '',
            vaccineDocumentBase: '',
            serverImage: '',
            isDeleted: false
        };
        detailColl.push(newDataRow);
        props.setFieldValue(
            `vaccinationData`,
            detailColl,
            false
        );

        setTimeout(() => addButtonRef?.current?.scrollIntoView({ behavior: 'smooth' }), 500);
    }

    const handleDeleteVaccine = (rowNo, props, index) => {
        let detailColl = props.values.vaccinationData;
        let newData = [...detailColl.filter((i: any) => i.vaccineRowNo !== rowNo)]

        let data = detailColl.forEach((data: any) => {
            if (data.vaccineRowNo === rowNo) {
                props.setFieldValue(
                    `vaccinationData[${index}].isDeleted`,
                    true,
                    false
                );
            }
        })
    }

    async function submitVaccinationForm(value: any) {
        setSubmitLoading(true);
        const vaccinationDetailData = value;
        const arr1 = value.vaccinationData;
        // const arr2 = intSchedualedvaccineData;

        let recordsToUpdate = arr1.filter((e: any) => e.memberVaccinationId > 0);
        let recordsToInsert = arr1.filter((e: any) => e.memberVaccinationId === 0);

        //new
        // if (recordsToUpdate !== undefined && recordsToUpdate !== null) {
        //     console.log('recordsToUpdate', recordsToUpdate)
        // }
        // if (recordsToInsert !== undefined && recordsToInsert !== null) {
        //     console.log('recordsToInsert', recordsToInsert)
        // }

        const formData = new FormData();
        formData.append('memberVaccinationDetailId', vaccinationDetailData.memberVaccinationDetailId);
        formData.append('familyId', userContext.memberDetail.familyId.toString());
        formData.append('familyMemberId', userContext.memberDetail.familyMemberId.toString());
        formData.append('sectorName', vaccinationDetailData.sectorName);
        formData.append('area', vaccinationDetailData.area);
        formData.append('city', vaccinationDetailData.city);
        formData.append('givenBy', vaccinationDetailData.givenBy);
        formData.append('givenOn', dayjs(vaccinationDetailData.givenOn).format('MM/DD/YYYY HH:mm:ss'));
        formData.append('dataManagedBy', userContext.loginDetail.userId.toString());
        formData.append('vaccinationInsertData', recordsToInsert.length > 0 ? JSON.stringify(recordsToInsert) : "");
        formData.append('vaccinationUpdateData', recordsToUpdate.length > 0 ? JSON.stringify(recordsToUpdate) : "");

        try {
            const resultVaccineSave: any = await CreateVaccine(formData);
            if (resultVaccineSave.data === true) {
                setSubmitLoading(false);
                updateUserContext({
                    ...userContext,
                    isAlert: true,
                    alertMessage: resultVaccineSave.message,
                    alertType: AlertType.Success,
                });
                navigate('/vaccination',
                    { state: { activeTab: location.state.activeTab } }
                );
            } else {
                updateUserContext({
                    ...userContext,
                    isAlert: true,
                    alertMessage: resultVaccineSave.message,
                    alertType: AlertType.Error,
                });
            }
        }
        catch (error) {
            setSubmitLoading(false);
            if (error) {
                updateUserContext({
                    ...userContext,
                    isAlert: true,
                    alertMessage: error as string,
                    alertType: AlertType.Error,
                });
            }
        }
    }

    const goToCancelVaccineDetailPopup = () => {
        setInitiVaccinationData(formInitialValues);
        setOpenCreatevaccine(false);
    }

    useEffect(() => {
        if (userContext.memberDetail.familyMemberId > 0) {
            fetchVaccineName();
            fetchVaccineBrandName();
            fetchVaccineDoseName();
            if (location.state.memberVaccinationDetailId !== null && location.state.memberVaccinationDetailId > 0) {
                fetchData();
            }
        }
        if (userContext.memberDetail !== undefined && userContext.memberDetail.familyMemberId > 0) {
            fetchMemberVaccineData();
        }
    }, []);

    useEffect(() => {
        fetchVaccineDoseName();
    }, [isDoseCustom])

    return (
        <Box className="vaccination-main-box">
            <Box className="head-box">
                <Box className="back-box">
                    <IconButton aria-label="back" className='back-icon' disableRipple
                        onClick={() => backClick()}>
                        <img src={backIcon} alt='logo' />
                    </IconButton>
                </Box>
                <Box className="title-box">
                    <Typography className="title-text">Vaccination details</Typography>
                </Box>
                <Box className="skip-box">
                    <></>
                    {/* <Typography>skip</Typography> */}
                </Box>
            </Box>

            <Formik
                enableReinitialize
                initialValues={initiVaccinationData}
                onSubmit={(values) => {
                    submitVaccinationForm(values);
                }}
                validationSchema={valildationSchema}>
                {(props) => {
                    return (
                        <>
                            <Form>
                                <Box className="body-box">
                                    <Box className="single-data-box">
                                        <Grid container
                                            spacing={2}>
                                            <Grid item xs={12} zIndex={3}>
                                                <FormControl fullWidth>
                                                    {loading ?
                                                        <Skeleton animation="wave" variant="rounded" width='auto' height={45} /> :
                                                        <Select
                                                            id="sectorName"
                                                            classNamePrefix="select"
                                                            placeholder={"Sector Name"}
                                                            isClearable
                                                            isSearchable
                                                            theme={(theme) => ({
                                                                ...theme,
                                                                colors: {
                                                                    ...theme.colors,
                                                                    primary: "#0B7CAA",
                                                                    primary25: "#F1F6FE",
                                                                    primary50: "#e1dfea",
                                                                },
                                                            })}
                                                            className={
                                                                props.values.sectorName === "" &&
                                                                    props.errors.sectorName &&
                                                                    props.touched.sectorName
                                                                    ? "text-input"
                                                                    : "text-input"
                                                            }
                                                            styles={
                                                                props.values.sectorName === "" &&
                                                                    props.errors.sectorName &&
                                                                    props.touched.sectorName
                                                                    ? selectStyles
                                                                    : undefined
                                                            }
                                                            value={getSingleSelected(
                                                                props.values.sectorName,
                                                                sectorOptions
                                                            )}
                                                            onChange={(e, value) => {
                                                                props.setFieldValue(
                                                                    "sectorName",
                                                                    e !== null ? e.value : "",
                                                                    false
                                                                );

                                                                if (e !== null) {
                                                                    if (e.value === "Government") {
                                                                        setDisabledErrors(true);
                                                                    } else {
                                                                        setDisabledErrors(false);
                                                                    }
                                                                }
                                                            }}
                                                            options={sectorOptions}
                                                        />}
                                                    {props.values.sectorName === "" &&
                                                        props.errors.sectorName &&
                                                        props.touched.sectorName && (
                                                            <Typography
                                                                className={
                                                                    props.values.sectorName === "" &&
                                                                        props.errors.sectorName &&
                                                                        props.touched.sectorName
                                                                        ? "validation-message text-input"
                                                                        : "text-input"
                                                                }>
                                                                {props.errors.sectorName}
                                                            </Typography>
                                                        )}
                                                </FormControl>
                                            </Grid>

                                            <Grid item xs={12}>
                                                <FormControl fullWidth>
                                                    {loading ?
                                                        <Skeleton animation="wave" variant="rounded" width='auto' height={45} /> :
                                                        <TextField
                                                            // required
                                                            id="area"
                                                            label="Area"
                                                            fullWidth
                                                            size="small"
                                                            onChange={(e: any) => {
                                                                props.setFieldValue(
                                                                    "area",
                                                                    e.target.value !== null ? e.target.value : "",
                                                                    true
                                                                );
                                                            }}
                                                            onBlur={props.handleBlur}
                                                            value={props.values.area}
                                                            inputProps={{ maxLength: 200 }}
                                                            variant="outlined"
                                                            error={
                                                                (disabledErrors === true ? false :
                                                                    (props.values.area === "" &&
                                                                        props.errors.area &&
                                                                        props.touched.area
                                                                        ? true
                                                                        : false))
                                                            }
                                                            className={
                                                                props.values.area === "" &&
                                                                    props.errors.area &&
                                                                    props.touched.area
                                                                    ? "text-input validation-message"
                                                                    : "text-input"
                                                            }
                                                        />}
                                                    {disabledErrors === true ? <></> : (
                                                        props.values.area === "" && props.errors.area &&
                                                        props.touched.area && (
                                                            <Typography
                                                                className={
                                                                    props.values.area === "" &&
                                                                        props.errors.area &&
                                                                        props.touched.area
                                                                        ? "validation-message"
                                                                        : "validation-message"
                                                                }>
                                                                {props.errors.area}
                                                            </Typography>
                                                        )
                                                    )}
                                                </FormControl>
                                            </Grid>

                                            <Grid item xs={12}>
                                                <FormControl fullWidth>
                                                    {loading ?
                                                        <Skeleton animation="wave" variant="rounded" width='auto' height={45} /> :
                                                        <TextField
                                                            // required
                                                            id="city"
                                                            label="City"
                                                            fullWidth
                                                            size="small"
                                                            onChange={(e: any) => {
                                                                props.setFieldValue(
                                                                    "city",
                                                                    e.target.value !== null ? e.target.value : "",
                                                                    true
                                                                );
                                                            }}
                                                            onBlur={props.handleBlur}
                                                            value={props.values.city}
                                                            inputProps={{ maxLength: 200 }}
                                                            variant="outlined"
                                                            error={
                                                                (disabledErrors === true ? false : props.values.city === "" &&
                                                                    props.errors.city &&
                                                                    props.touched.city
                                                                    ? true
                                                                    : false)
                                                            }
                                                            className={
                                                                props.values.city === "" &&
                                                                    props.errors.city &&
                                                                    props.touched.city
                                                                    ? "text-input validation-message"
                                                                    : "text-input"
                                                            }
                                                        />}
                                                    {disabledErrors === true ? <></> : (props.values.city === "" && props.errors.city &&
                                                        props.touched.city && (
                                                            <Typography
                                                                className={
                                                                    props.values.city === "" &&
                                                                        props.errors.city &&
                                                                        props.touched.city
                                                                        ? "validation-message"
                                                                        : "validation-message"
                                                                }>{props.errors.city}
                                                            </Typography>
                                                        ))}
                                                </FormControl>
                                            </Grid>

                                            <Grid item xs={12}>
                                                <FormControl fullWidth>
                                                    {loading ?
                                                        <Skeleton animation="wave" variant="rounded" width='auto' height={45} /> :
                                                        <TextField
                                                            // required
                                                            id="givenBy"
                                                            label="Given By"
                                                            fullWidth
                                                            size="small"
                                                            onChange={(e: any) => {
                                                                props.setFieldValue(
                                                                    "givenBy",
                                                                    e.target.value !== null ? e.target.value : "",
                                                                    true
                                                                );
                                                            }}
                                                            onBlur={props.handleBlur}
                                                            value={props.values.givenBy}
                                                            inputProps={{ maxLength: 200 }}
                                                            variant="outlined"
                                                            error={
                                                                (disabledErrors === true ? false : props.values.city === "" && props.values.givenBy === "" &&
                                                                    props.errors.givenBy &&
                                                                    props.touched.givenBy
                                                                    ? true
                                                                    : false)
                                                            }
                                                            className={
                                                                props.values.givenBy === "" &&
                                                                    props.errors.givenBy &&
                                                                    props.touched.givenBy
                                                                    ? "text-input validation-message"
                                                                    : "text-input"
                                                            }
                                                        />}
                                                    {disabledErrors === true ? <></> : (props.values.givenBy === "" && props.errors.givenBy &&
                                                        props.touched.givenBy && (
                                                            <Typography
                                                                className={
                                                                    props.values.givenBy === "" &&
                                                                        props.errors.givenBy &&
                                                                        props.touched.givenBy
                                                                        ? "validation-message"
                                                                        : "validation-message"
                                                                }>
                                                                {props.errors.givenBy}
                                                            </Typography>
                                                        ))}
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={12}>
                                                {loading ?
                                                    <Skeleton animation="wave" variant="rounded" width='auto' height={45} /> :
                                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                        <DemoContainer components={['MobileDatePicker']}>
                                                            <MobileDatePicker
                                                                id="givenOn"
                                                                label="Vaccination Date"
                                                                className={
                                                                    props.values.givenOn === "" &&
                                                                        props.errors.givenOn &&
                                                                        props.touched.givenOn
                                                                        ? "datepicker validation-message"
                                                                        : "datepicker"
                                                                }
                                                                sx={{ width: '100%' }}
                                                                format='DD/MM/YYYY'
                                                                disableFuture={(location.state.customAdd !== null && location.state.customAdd === true) || location.state.missDoseLimit === "" ? true : false}
                                                                minDate={location.state.customAdd !== null && location.state.customAdd === true ? "" : dayjs(location.state.vaccineSchedualDate)}
                                                                maxDate={location.state.customAdd !== null && location.state.customAdd === true ? "" : dayjs(location.state.missDoseLimit)}
                                                                value={dayjs(props.values.givenOn)}
                                                                onChange={(e) =>
                                                                    props.setFieldValue(
                                                                        "givenOn",
                                                                        e !== null ? e.toDate() : "",
                                                                        true
                                                                    )} />
                                                        </DemoContainer>
                                                    </LocalizationProvider>}
                                                {props.values.givenOn === "" &&
                                                    props.errors.givenOn &&
                                                    props.touched.givenOn && (
                                                        <Typography
                                                            className={
                                                                props.values.givenOn === "" &&
                                                                    props.errors.givenOn &&
                                                                    props.touched.givenOn
                                                                    ? "validation-message text-input"
                                                                    : "text-input"
                                                            }>
                                                            {props.errors.givenOn}
                                                        </Typography>
                                                    )}
                                            </Grid>
                                        </Grid>
                                    </Box>

                                    <Box className="multiple-data-box">
                                        <TransitionGroup className="transition-group">
                                            {props.values.vaccinationData !== undefined && props.values.vaccinationData.map((dataRow: any, i: any) => {
                                                if (dataRow.isDeleted === false) {
                                                    return (
                                                        <Collapse timeout={500} key={dataRow.vaccineRowNo} className="transition-collapse">
                                                            <Grid container paddingRight={2} paddingBottom={2} spacing={2} className="sub-grid">
                                                                <Grid item xs={12} className="vaccine-num-grid">
                                                                    <Typography className="vaccine-num">Vaccine {i + 1}</Typography>
                                                                    {props.values.vaccinationData.length > 1 ?
                                                                        <Box className="close-box" onClick={() => handleDeleteVaccine(dataRow.vaccineRowNo, props, i)}>
                                                                            <CloseRoundedIcon className="close-icon" />
                                                                        </Box>
                                                                        : <></>}
                                                                </Grid>

                                                                <Grid item xs={12} zIndex={9}>
                                                                    <FormControl fullWidth>
                                                                        {loading ?
                                                                            <Skeleton animation="wave" variant="rounded" width='auto' height={45} /> :
                                                                            <CreatableSelect
                                                                                id={`vaccinationName ${i}`}
                                                                                // ref={selectInputRef}
                                                                                classNamePrefix="select"
                                                                                placeholder={"Generic Name"}
                                                                                isClearable
                                                                                isSearchable
                                                                                formatCreateLabel={() => `+ Add vaccine`}
                                                                                theme={(theme) => ({
                                                                                    ...theme,
                                                                                    colors: {
                                                                                        ...theme.colors,
                                                                                        primary: "#0B7CAA",
                                                                                        primary25: "#F1F6FE",
                                                                                        primary50: "#e1dfea",
                                                                                    },
                                                                                })}
                                                                                className={
                                                                                    props.values.vaccinationData[i] !== undefined && props.values.vaccinationData[i].vaccinationName === ""
                                                                                        ? "text-input"
                                                                                        : "text-input"
                                                                                }
                                                                                styles={
                                                                                    props.values.vaccinationData[i] !== undefined && props.values.vaccinationData[i].vaccinationName === "" &&
                                                                                        props.errors.vaccinationData && props.errors.vaccinationData[i] && props.errors.vaccinationData[i].vaccinationName &&
                                                                                        props.touched.vaccinationData && props.touched.vaccinationData[i] && props.touched.vaccinationData[i].vaccinationName
                                                                                        ? errorCreateSelectStyles
                                                                                        : normalOptionStyle
                                                                                }

                                                                                value={props.values.vaccinationData[i] !== undefined && props.values.vaccinationData[i].vaccinationId !== undefined ?
                                                                                    getSingleSelected(
                                                                                        props.values.vaccinationData[i].vaccinationId.toString(),
                                                                                        vaccinationNameList
                                                                                    ) : null}
                                                                                onChange={(e: any, opt) => {
                                                                                    props.setFieldValue(
                                                                                        `vaccinationData[${i}].vaccinationId`,
                                                                                        e !== null ? e.value : "",
                                                                                        false
                                                                                    );
                                                                                    props.setFieldValue(
                                                                                        `vaccinationData[${i}].vaccinationName`,
                                                                                        e !== null ? e.label : "",
                                                                                        true
                                                                                    );
                                                                                    props.setFieldValue(
                                                                                        `vaccinationData[${i}].brandId`,
                                                                                        e !== null ? "-1" : "0",
                                                                                        true
                                                                                    );
                                                                                    props.setFieldValue(
                                                                                        `vaccinationData[${i}].doseId`,
                                                                                        e !== null ? "-1" : "0",
                                                                                        true
                                                                                    );
                                                                                    // props.setFieldValue(
                                                                                    //     `vaccinationData[${i}].doseId`,
                                                                                    //     e !== null ? e.value : "0",
                                                                                    //     true
                                                                                    // );
                                                                                }}
                                                                                onCreateOption={(name) => handleVaccineCreate(name, i, props)}
                                                                                options={vaccinationNameList}
                                                                            />}
                                                                        {props.values.vaccinationData[i] !== undefined && props.values.vaccinationData[i].vaccinationName === "" &&
                                                                            props.errors.vaccinationData && props.errors.vaccinationData[i] && props.errors.vaccinationData[i].vaccinationName &&
                                                                            props.touched.vaccinationData && props.touched.vaccinationData[i] && props.touched.vaccinationData[i].vaccinationName &&

                                                                            (<Typography className={props.values.vaccinationData[i] !== undefined && props.values.vaccinationData[i].vaccinationName === "" &&
                                                                                props.errors.vaccinationData && props.errors.vaccinationData[i] && props.errors.vaccinationData[i].vaccinationName &&
                                                                                props.touched.vaccinationData && props.touched.vaccinationData[i] && props.touched.vaccinationData[i].vaccinationName
                                                                                ? "validation-message text-input"
                                                                                : "text-input"}>
                                                                                {props.errors.vaccinationData[i].vaccinationName}
                                                                            </Typography>
                                                                            )
                                                                        }
                                                                    </FormControl>
                                                                </Grid>
                                                                <Grid item xs={12} zIndex={7}>
                                                                    <FormControl fullWidth>
                                                                        {loading ?
                                                                            <Skeleton animation="wave" variant="rounded" width='auto' height={45} /> :
                                                                            <CreatableSelect
                                                                                id={`brandName ${i}`}
                                                                                classNamePrefix="select"
                                                                                placeholder={"Brand"}
                                                                                isClearable
                                                                                isSearchable
                                                                                formatCreateLabel={() => `+ Add brand`}
                                                                                theme={(theme) => ({
                                                                                    ...theme,
                                                                                    colors: {
                                                                                        ...theme.colors,
                                                                                        primary: "#0B7CAA",
                                                                                        primary25: "#F1F6FE",
                                                                                        primary50: "#e1dfea",
                                                                                    },
                                                                                })}
                                                                                className={
                                                                                    props.values.vaccinationData[i] !== undefined && props.values.vaccinationData[i].brandName === ""
                                                                                        ? "text-input"
                                                                                        : "text-input"
                                                                                }
                                                                                styles={normalOptionStyle}
                                                                                value={props.values.vaccinationData[i] !== undefined && props.values.vaccinationData[i].brandId !== undefined ? getSingleSelected(
                                                                                    props.values.vaccinationData[i].brandId.toString(),
                                                                                    vaccinationBrandNameList
                                                                                ) : null}
                                                                                onChange={(e, value) => {
                                                                                    props.setFieldValue(
                                                                                        `vaccinationData[${i}].brandId`,
                                                                                        e !== null ? e.value : '',
                                                                                        true
                                                                                    );
                                                                                    props.setFieldValue(
                                                                                        `vaccinationData[${i}].brandCode`,
                                                                                        e !== null ? e.code : '',
                                                                                        true
                                                                                    );
                                                                                    props.setFieldValue(
                                                                                        `vaccinationData[${i}].brandName`,
                                                                                        e !== null ? e.label : "",
                                                                                        true
                                                                                    );
                                                                                }}
                                                                                onCreateOption={(name) => handleBrandCreate(name, i, props)}
                                                                                options={props.values.vaccinationData[i] === undefined ? vaccinationBrandNameList
                                                                                    :
                                                                                    (props.values.vaccinationData[i].vaccinationId.toString() !== "0" && props.values.vaccinationData[i].brandId.toString() === "0" ?
                                                                                        vaccinationBrandNameList :
                                                                                        ((vaccinationBrandNameList.filter((item: any) =>
                                                                                            +item.code === +props.values.vaccinationData[i].vaccinationId
                                                                                        )))
                                                                                    )
                                                                                }
                                                                            />}
                                                                        {props.values.vaccinationData[i] !== undefined && props.values.vaccinationData[i].brandName === "" &&
                                                                            props.errors.vaccinationData && props.errors.vaccinationData[i] && props.errors.vaccinationData[i].brandName &&
                                                                            props.touched.vaccinationData && props.touched.vaccinationData[i] && props.touched.vaccinationData[i].brandName &&
                                                                            (<Typography className={
                                                                                props.values.vaccinationData[i] !== undefined && props.values.vaccinationData[i].brandName === "" &&
                                                                                    props.errors.vaccinationData && props.errors.vaccinationData[i] && props.errors.vaccinationData[i].brandName &&
                                                                                    props.touched.vaccinationData && props.touched.vaccinationData[i] && props.touched.vaccinationData[i].brandName
                                                                                    ? "validation-message text-input"
                                                                                    : "text-input"
                                                                            }>
                                                                                {props.errors.vaccinationData[i].brandName}
                                                                            </Typography>
                                                                            )}
                                                                    </FormControl>
                                                                </Grid>
                                                                <Grid item xs={12} zIndex={5}>
                                                                    <FormControl fullWidth>
                                                                        {loading ?
                                                                            <Skeleton animation="wave" variant="rounded" width='auto' height={45} /> :
                                                                            <CreatableSelect
                                                                                id={`doseName ${i}`}
                                                                                classNamePrefix="select"
                                                                                placeholder={"Dose"}
                                                                                isClearable
                                                                                isSearchable
                                                                                formatCreateLabel={() => `+ Add dose`}
                                                                                theme={(theme) => ({
                                                                                    ...theme,
                                                                                    colors: {
                                                                                        ...theme.colors,
                                                                                        primary: "#0B7CAA",
                                                                                        primary25: "#F1F6FE",
                                                                                        primary50: "#e1dfea",
                                                                                    },
                                                                                })}
                                                                                className={
                                                                                    props.values.vaccinationData[i] !== undefined && props.values.vaccinationData[i].doseName === ""
                                                                                        ? "text-input"
                                                                                        : "text-input"
                                                                                }
                                                                                // styles={
                                                                                //     props.values.vaccinationData[i] !== undefined && props.values.vaccinationData[i].doseName === ""
                                                                                //         ? selectStyles
                                                                                //         : undefined
                                                                                // }
                                                                                styles={
                                                                                    props.values.vaccinationData[i] !== undefined && props.values.vaccinationData[i].doseName === "" &&
                                                                                        props.errors.vaccinationData && props.errors.vaccinationData[i] && props.errors.vaccinationData[i].doseName &&
                                                                                        props.touched.vaccinationData && props.touched.vaccinationData[i] && props.touched.vaccinationData[i].doseName
                                                                                        ? errorCreateSelectStyles
                                                                                        : normalOptionStyle
                                                                                }
                                                                                value={props.values.vaccinationData[i] !== undefined && props.values.vaccinationData[i].doseId !== undefined ?
                                                                                    getSingleSelected(
                                                                                        props.values.vaccinationData[i].doseId,
                                                                                        vaccinationDoseNameList)
                                                                                    : null}

                                                                                onChange={(e, value) => {
                                                                                    props.setFieldValue(
                                                                                        `vaccinationData[${i}].doseId`,
                                                                                        e !== null ? e.value : "",
                                                                                        false
                                                                                    );
                                                                                    props.setFieldValue(
                                                                                        `vaccinationData[${i}].doseCode`,
                                                                                        e !== null ? e.name : "",
                                                                                        false
                                                                                    );
                                                                                    props.setFieldValue(
                                                                                        `vaccinationData[${i}].doseName`,
                                                                                        e !== null ? e.label : "",
                                                                                        false
                                                                                    );
                                                                                }}
                                                                                onCreateOption={(name) => handleDoseCreate(name, i, props)}
                                                                                options={props.values.vaccinationData[i] === undefined ?
                                                                                    vaccinationDoseNameList
                                                                                    :
                                                                                    (location.state.isEditVaccine !== undefined && location.state.isEditVaccine !== null && location.state.isEditVaccine === true && props.values.vaccinationData[i].vaccinationId !== undefined && props.values.vaccinationData[i].doseId !== undefined && +props.values.vaccinationData[i].doseId !== -1 ?
                                                                                        //edit currrent case
                                                                                        vaccinationDoseNameList.filter((item: any) =>
                                                                                            +item.value === +props.values.vaccinationData[i].vaccinationId) :
                                                                                        // add custom case
                                                                                        vaccinationDoseNameList.filter((cusitem: any) =>
                                                                                            +cusitem.code === +props.values.vaccinationData[i].vaccinationId
                                                                                        )
                                                                                    )
                                                                                }
                                                                            />}
                                                                        {props.values.vaccinationData[i] !== undefined && props.values.vaccinationData[i].doseName === "" &&
                                                                            props.errors.vaccinationData && props.errors.vaccinationData[i] && props.errors.vaccinationData[i].doseName &&
                                                                            props.touched.vaccinationData && props.touched.vaccinationData[i] && props.touched.vaccinationData[i].doseName &&
                                                                            (<Typography
                                                                                className={
                                                                                    props.values.vaccinationData[i] !== undefined && props.values.vaccinationData[i].doseName === "" &&
                                                                                        props.errors.vaccinationData && props.errors.vaccinationData[i] && props.errors.vaccinationData[i].doseName &&
                                                                                        props.touched.vaccinationData && props.touched.vaccinationData[i] && props.touched.vaccinationData[i].doseName
                                                                                        ? "validation-message text-input"
                                                                                        : "text-input"
                                                                                }>
                                                                                {props.errors.vaccinationData[i].doseName}
                                                                            </Typography>
                                                                            )}
                                                                    </FormControl>
                                                                </Grid>
                                                                <Grid item xs={12} md={12} sm={12}>
                                                                    <FormControl fullWidth>
                                                                        {loading ?
                                                                            <Skeleton animation="wave" variant="rounded" width='auto' height={45} /> :
                                                                            <TextField
                                                                                id={`batchNumber ${i}`}
                                                                                label="Batch"
                                                                                fullWidth
                                                                                size="small"
                                                                                onChange={(e: any) => {
                                                                                    props.setFieldValue(
                                                                                        `vaccinationData[${i}].batchNumber`,
                                                                                        // "batchNumber",
                                                                                        e.target.value !== null ? e.target.value : "",
                                                                                        true
                                                                                    );
                                                                                }}
                                                                                onBlur={props.handleBlur}
                                                                                value={props.values.vaccinationData[i] !== undefined ? props.values.vaccinationData[i].batchNumber.toUpperCase() : null}
                                                                                inputProps={{ maxLength: 200 }}
                                                                                variant="outlined"
                                                                                className={
                                                                                    props.values.vaccinationData[i] !== undefined && props.values.vaccinationData[i].batchNumber === ""
                                                                                        ? "text-input input-for-batch validation-message"
                                                                                        : "text-input input-for-batch"
                                                                                }
                                                                            />}
                                                                    </FormControl>
                                                                </Grid>
                                                                <Grid item xs={12} sm={12} md={12}>
                                                                    <FormControl fullWidth>
                                                                        {loading ?
                                                                            <Skeleton animation="wave" variant="rounded" width='auto' height={45} /> :
                                                                            <Box>
                                                                                {(props.values.vaccinationData[i].vaccineDocumentName === undefined || props.values.vaccinationData[i].vaccineDocumentName === null || props.values.vaccinationData[i].vaccineDocumentName === "") ?
                                                                                    <label htmlFor={`upload-photo ${i}`}>
                                                                                        <input
                                                                                            style={{ display: 'none' }}
                                                                                            id={`upload-photo ${i}`}
                                                                                            name="upload-photo"
                                                                                            type="file"
                                                                                            onChange={(e: any) => {
                                                                                                handleDocumentUpload(e, props, i);
                                                                                            }}
                                                                                        />
                                                                                        <Button className="uploade-document-buttom" variant="outlined" component="span" startIcon={<FileUploadIcon />}>
                                                                                            Upload document
                                                                                        </Button>
                                                                                    </label> : <></>}
                                                                                {props.values.vaccinationData[i] !== undefined && props.values.vaccinationData[i].vaccineDocumentName !== undefined && props.values.vaccinationData[i].vaccineDocumentName !== null && props.values.vaccinationData[i].vaccineDocumentName !== "" && props.values.vaccinationData[i].vaccineDocumentBase !== undefined && props.values.vaccinationData[i].vaccineDocumentBase !== null && props.values.vaccinationData[i].vaccineDocumentBase !== "" ?
                                                                                    <Box className="uploade-document-name">
                                                                                        <Box className="image-box" onClick={() => viewDocument(props.values.vaccinationData[i].vaccineDocumentName, props.values.vaccinationData[i].vaccineDocumentBase)}>
                                                                                            <img className='image-preview' alt="logo" src={props.values.vaccinationData[i].vaccineDocumentBase} />
                                                                                        </Box>
                                                                                        <Box className="document-name-box" onClick={() => viewDocument(props.values.vaccinationData[i].vaccineDocumentName, props.values.vaccinationData[i].vaccineDocumentBase)}>
                                                                                            <Typography className="doc-name">
                                                                                                {props.values.vaccinationData[i].vaccineDocumentName}
                                                                                            </Typography>
                                                                                        </Box>
                                                                                        <Box className="document-icons-main-box">
                                                                                            <Box className="delete-box" onClick={() => cancelDocument(i)}>
                                                                                                <DeleteRoundedIcon className="uploade-document-delete-icon" />
                                                                                            </Box>
                                                                                        </Box>
                                                                                    </Box>
                                                                                    :
                                                                                    (props.values.vaccinationData[i] !== undefined && props.values.vaccinationData[i].vaccineDocumentUpload === "" ? <Typography className="text-input validation-message">Please select a file less than 2MB.</Typography> : null)
                                                                                }
                                                                            </Box>
                                                                        }
                                                                    </FormControl>
                                                                </Grid>
                                                            </Grid>
                                                        </Collapse>
                                                    )
                                                }
                                            })}
                                        </ TransitionGroup>
                                        <Box className="multiple-vaccine-button" ref={addButtonRef}>
                                            {loading ?
                                                <Skeleton animation="wave" variant="rounded" width='100%' height={45} /> :
                                                <LoadingButton
                                                    className="add-multiple-vaccine"
                                                    variant="contained"
                                                    onClick={() => handleListAdd(props)}
                                                    startIcon={<AddRoundedIcon />}>
                                                    Add another vaccine
                                                </LoadingButton>
                                            }
                                        </Box>
                                    </Box>

                                    <Box className="bottom-box">
                                        <CustomButton
                                            disabled={false}
                                            class="add-button"
                                            isLoading={submitloading}
                                            title='Submit'
                                        />
                                    </Box>
                                </Box>

                                <Dialog
                                    maxWidth="md"
                                    fullWidth={true}
                                    open={openDeleteDocument}
                                    onClose={closeDeleteModal}
                                    aria-labelledby="alert-dialog-title"
                                    aria-describedby="alert-dialog-description"
                                    className="delete-dialog">
                                    <DialogContent className="delete-content-msg">
                                        <DialogContentText>
                                            Are you sure want to delete this File?
                                        </DialogContentText>
                                    </DialogContent>
                                    <DialogActions>
                                        <LoadingButton
                                            variant="contained"
                                            onClick={() => deleteDocument(props)}
                                            className="delete-dialog-button-yes"
                                            loading={deleteLoading}>
                                            Yes
                                        </LoadingButton>
                                        <Button
                                            variant="outlined"
                                            onClick={() => closeDeleteModal()}
                                            className="delete-dialog-button-no">
                                            No
                                        </Button>
                                    </DialogActions>
                                </Dialog>
                                <Dialog
                                    fullWidth={true}
                                    maxWidth={'lg'}
                                    open={openCreatevaccine}
                                    onClose={() => setOpenCreatevaccine(false)}
                                    aria-labelledby="alert-dialog-title"
                                    aria-describedby="alert-dialog-description"
                                    className="custom-vaccine-dialog">
                                    <Box className='close-main-box' onClick={() => setOpenCreatevaccine(false)}>
                                        <Box className='close-box'>
                                            <IconButton
                                                edge="start"
                                                color="inherit"
                                                aria-label="close"
                                                className='close-button'>
                                                <CloseRoundedIcon />
                                            </IconButton>
                                        </Box>
                                    </Box>
                                    <Box className="custom-vaccine-form">
                                        {props.values.vaccinationData && props.values.vaccinationData.map((dataRow: any, i: any) => {
                                            return (
                                                <Grid container paddingBottom={2} spacing={2} className="sub-grid" key={i}>
                                                    {customVaccineIndex === i ? <>
                                                        <Grid item xs={12} zIndex={9}>
                                                            <FormControl fullWidth>
                                                                {loading ?
                                                                    <Skeleton animation="wave" variant="rounded" width='auto' height={45} /> :
                                                                    <Select
                                                                        id={`vaccinationName`}
                                                                        classNamePrefix="select"
                                                                        placeholder={"Generic Name"}
                                                                        isClearable
                                                                        isSearchable
                                                                        // formatCreateLabel={() => `Add vaccine`}
                                                                        theme={(theme) => ({
                                                                            ...theme,
                                                                            colors: {
                                                                                ...theme.colors,
                                                                                primary: "#0B7CAA",
                                                                                primary25: "#F1F6FE",
                                                                                primary50: "#e1dfea",
                                                                            },
                                                                        })}
                                                                        className={
                                                                            props.values.vaccinationData[customVaccineIndex] !== undefined && props.values.vaccinationData[customVaccineIndex].vaccinationName === ""
                                                                                ? "text-input"
                                                                                : "text-input"
                                                                        }
                                                                        styles={
                                                                            props.values.vaccinationData[customVaccineIndex] !== undefined && props.values.vaccinationData[customVaccineIndex].vaccinationName === ""
                                                                                ? selectStyles
                                                                                : undefined
                                                                        }
                                                                        value={props.values.vaccinationData[customVaccineIndex] !== undefined && props.values.vaccinationData[customVaccineIndex].vaccinationId !== undefined ? getSingleSelected(
                                                                            props.values.vaccinationData[customVaccineIndex].vaccinationId.toString(),
                                                                            vaccinationNameList
                                                                        ) : null}
                                                                        onChange={(e: any, value) => {
                                                                            props.setFieldValue(
                                                                                `vaccinationData[${customVaccineIndex}].vaccinationId`,
                                                                                e !== null ? e.value : "",
                                                                                false
                                                                            );
                                                                            props.setFieldValue(
                                                                                `vaccinationData[${customVaccineIndex}].vaccinationName`,
                                                                                e !== null ? e.label : "",
                                                                                true
                                                                            );
                                                                            props.setFieldValue(
                                                                                `vaccinationData[${customVaccineIndex}].brandId`,
                                                                                e !== null ? "-1" : "0",
                                                                                true
                                                                            );
                                                                            props.setFieldValue(
                                                                                `vaccinationData[${customVaccineIndex}].doseId`,
                                                                                e !== null ? "-1" : "0",
                                                                                true
                                                                            );
                                                                        }}
                                                                        options={vaccinationNameList}
                                                                    />}
                                                                {props.values.vaccinationData[customVaccineIndex] !== undefined && props.values.vaccinationData[customVaccineIndex].vaccinationName === "" &&
                                                                    props.errors.vaccinationData && props.errors.vaccinationData[customVaccineIndex] && props.errors.vaccinationData[customVaccineIndex].vaccinationName &&
                                                                    props.touched.vaccinationData && props.touched.vaccinationData[customVaccineIndex] && props.touched.vaccinationData[customVaccineIndex].vaccinationName &&

                                                                    (<Typography className={props.values.vaccinationData[customVaccineIndex] !== undefined && props.values.vaccinationData[customVaccineIndex].vaccinationName === "" &&
                                                                        props.errors.vaccinationData && props.errors.vaccinationData[customVaccineIndex] && props.errors.vaccinationData[customVaccineIndex].vaccinationName &&
                                                                        props.touched.vaccinationData && props.touched.vaccinationData[customVaccineIndex] && props.touched.vaccinationData[customVaccineIndex].vaccinationName
                                                                        ? "validation-message text-input"
                                                                        : "text-input"}>
                                                                        {props.errors.vaccinationData[customVaccineIndex].vaccinationName}
                                                                    </Typography>
                                                                    )
                                                                }
                                                            </FormControl>
                                                        </Grid>
                                                        <Grid item xs={12} zIndex={7}>
                                                            <FormControl fullWidth>
                                                                {loading ?
                                                                    <Skeleton animation="wave" variant="rounded" width='auto' height={45} /> :
                                                                    <CreatableSelect
                                                                        id={`brandName`}
                                                                        classNamePrefix="select"
                                                                        placeholder={"Brand"}
                                                                        isClearable
                                                                        isSearchable
                                                                        formatCreateLabel={() => `+ Add brand`}
                                                                        theme={(theme) => ({
                                                                            ...theme,
                                                                            colors: {
                                                                                ...theme.colors,
                                                                                primary: "#0B7CAA",
                                                                                primary25: "#F1F6FE",
                                                                                primary50: "#e1dfea",
                                                                            },
                                                                        })}
                                                                        className={
                                                                            props.values.vaccinationData[customVaccineIndex] !== undefined && props.values.vaccinationData[customVaccineIndex].brandName === ""
                                                                                ? "text-input"
                                                                                : "text-input"
                                                                        }
                                                                        // styles={
                                                                        //     props.values.vaccinationData[i] !== undefined && props.values.vaccinationData[i].brandName === ""
                                                                        //         ? selectStyles
                                                                        //         : undefined
                                                                        // }
                                                                        styles={normalOptionStyle}
                                                                        value={props.values.vaccinationData[customVaccineIndex] !== undefined && props.values.vaccinationData[customVaccineIndex].brandId !== undefined ? getSingleSelected(
                                                                            props.values.vaccinationData[customVaccineIndex].brandId.toString(),
                                                                            vaccinationBrandNameList
                                                                        ) : null}
                                                                        onChange={(e, value) => {
                                                                            props.setFieldValue(
                                                                                `vaccinationData[${customVaccineIndex}].brandId`,
                                                                                e !== null ? e.value : '',
                                                                                true
                                                                            );
                                                                            props.setFieldValue(
                                                                                `vaccinationData[${customVaccineIndex}].brandCode`,
                                                                                e !== null ? e.code : '',
                                                                                true
                                                                            );
                                                                            props.setFieldValue(
                                                                                `vaccinationData[${customVaccineIndex}].brandName`,
                                                                                e !== null ? e.label : "",
                                                                                true
                                                                            );
                                                                        }}
                                                                        onCreateOption={(name) => handleBrandCreate(name, i, props)}
                                                                        options={props.values.vaccinationData[customVaccineIndex] === undefined ? vaccinationBrandNameList :
                                                                            (props.values.vaccinationData[customVaccineIndex].vaccinationId.toString() !== "0" && props.values.vaccinationData[customVaccineIndex].brandId.toString() === "0" ?
                                                                                vaccinationBrandNameList :
                                                                                // ((vaccinationBrandNameList.filter((item: any) =>
                                                                                //     item.code === props.values.vaccinationData[i].vaccinationId
                                                                                // )))
                                                                                vaccinationBrandNameList
                                                                            )
                                                                        }
                                                                    />}
                                                                {/* {props.values.vaccinationData[customVaccineIndex] !== undefined && props.values.vaccinationData[customVaccineIndex].brandName === "" &&
                                                                    props.errors.vaccinationData && props.errors.vaccinationData[customVaccineIndex] && props.errors.vaccinationData[customVaccineIndex].brandName &&
                                                                    props.touched.vaccinationData && props.touched.vaccinationData[customVaccineIndex] && props.touched.vaccinationData[customVaccineIndex].brandName &&
                                                                    (<Typography className={
                                                                        props.values.vaccinationData[i] !== undefined && props.values.vaccinationData[i].brandName === "" &&
                                                                            props.errors.vaccinationData && props.errors.vaccinationData[customVaccineIndex] && props.errors.vaccinationData[customVaccineIndex].brandName &&
                                                                            props.touched.vaccinationData && props.touched.vaccinationData[customVaccineIndex] && props.touched.vaccinationData[customVaccineIndex].brandName
                                                                            ? "validation-message text-input"
                                                                            : "text-input"
                                                                    }>
                                                                        {props.errors.vaccinationData[customVaccineIndex].brandName}
                                                                    </Typography>
                                                                    )} */}
                                                            </FormControl>
                                                        </Grid>
                                                        <Grid item xs={12} zIndex={5}>
                                                            <FormControl fullWidth>
                                                                {loading ?
                                                                    <Skeleton animation="wave" variant="rounded" width='auto' height={45} /> :
                                                                    <CreatableSelect
                                                                        id={`doseName`}
                                                                        classNamePrefix="select"
                                                                        placeholder={"Dose"}
                                                                        isClearable
                                                                        isSearchable
                                                                        formatCreateLabel={() => `+ Add dose`}
                                                                        theme={(theme) => ({
                                                                            ...theme,
                                                                            colors: {
                                                                                ...theme.colors,
                                                                                primary: "#0B7CAA",
                                                                                primary25: "#F1F6FE",
                                                                                primary50: "#e1dfea",
                                                                            },
                                                                        })}
                                                                        className={
                                                                            props.values.vaccinationData[customVaccineIndex] !== undefined && props.values.vaccinationData[customVaccineIndex].doseName === ""
                                                                                ? "text-input"
                                                                                : "text-input"
                                                                        }
                                                                        // styles={
                                                                        //     props.values.vaccinationData[i] !== undefined && props.values.vaccinationData[i].brandName === ""
                                                                        //         ? optionStyle
                                                                        //         : undefined
                                                                        // }
                                                                        styles={normalOptionStyle}
                                                                        value={props.values.vaccinationData[customVaccineIndex] !== undefined && props.values.vaccinationData[customVaccineIndex].doseId !== undefined ? getSingleSelected(
                                                                            props.values.vaccinationData[customVaccineIndex].doseId,
                                                                            vaccinationDoseNameList
                                                                        ) : null}
                                                                        onChange={(e, value) => {
                                                                            props.setFieldValue(
                                                                                `vaccinationData[${customVaccineIndex}].doseId`,
                                                                                e !== null ? e.value : "",
                                                                                false
                                                                            );
                                                                            props.setFieldValue(
                                                                                `vaccinationData[${customVaccineIndex}].doseCode`,
                                                                                e !== null ? e.name : "",
                                                                                false
                                                                            );
                                                                            props.setFieldValue(
                                                                                `vaccinationData[${customVaccineIndex}].doseName`,
                                                                                e !== null ? e.label : "",
                                                                                false
                                                                            );
                                                                        }}
                                                                        onCreateOption={(name) => handleDoseCreate(name, i, props)}
                                                                        options={props.values.vaccinationData[customVaccineIndex] === undefined ? vaccinationDoseNameList :
                                                                            ((vaccinationDoseNameList.filter((item: any) =>
                                                                                item.code === props.values.vaccinationData[customVaccineIndex].vaccinationId
                                                                            )))
                                                                        }
                                                                    />}
                                                            </FormControl>
                                                        </Grid>
                                                    </> : <></>}
                                                </Grid>
                                            )
                                        })}
                                    </Box>
                                    <DialogActions className="custom-vaccine-btns">
                                        <LoadingButton
                                            variant="contained"
                                            onClick={() => setOpenCreatevaccine(false)}>
                                            Create
                                        </LoadingButton>
                                        <LoadingButton
                                            variant="contained"
                                            onClick={() => goToCancelVaccineDetailPopup()}>
                                            Cancel
                                        </LoadingButton>
                                    </DialogActions>
                                </Dialog>
                            </Form>

                            <Box className="preview-box">
                                <Dialog
                                    fullScreen
                                    fullWidth={true}
                                    open={isShowDocumentPreview}
                                    onClose={closeDocument}
                                    aria-labelledby="alert-dialog-title"
                                    aria-describedby="alert-dialog-description"
                                    className="set-vaccine-document-box">
                                    <DialogTitle className="document-name">{documentFileName}</DialogTitle>
                                    <DialogContent className="document-container">
                                        <img className='img-preview' alt="logo" src={vaccineDocument} />
                                    </DialogContent>

                                    <DialogActions className="vaccine-document-close-box" onClick={() => closeDocument()}>
                                        <CloseRoundedIcon className="close-icon" />
                                        {/* <LoadingButton
                                            color="primary"
                                            fullWidth
                                            variant="contained"
                                            endIcon={<DownloadRoundedIcon />}
                                            className="download-button"
                                            onClick={() => downloadDocument(vaccineDocument)}>
                                            Download
                                        </LoadingButton> */}
                                    </DialogActions>
                                </Dialog>
                            </Box>
                        </>
                    )
                }}</Formik>
        </Box>
    );
}
const selectStyles = {
    control: (provided: any, state: any) => ({
        ...provided,
        border: state.isFocused ? "1px solid #0B7CAA" : "1px solid #d32f2f",
    }),
    placeholder: (provided: any, state: any) => ({
        ...provided,
        color: state.isFocused ? state.selectProps.color : "#d32f2f",
    }),
};

const errorCreateSelectStyles = {
    control: (provided: any, state: any) => ({
        ...provided,
        border: state.isFocused ? "1px solid #0B7CAA" : "1px solid #d32f2f",
    }),
    placeholder: (provided: any, state: any) => ({
        ...provided,
        color: state.isFocused ? state.selectProps.color : "#d32f2f",
    }),
    option: (provided, state) => ({
        ...provided,
        color: state.data.__isNew__ && "#0B7CAA"
    }),
};

const normalOptionStyle = {
    control: (provided: any, state: any) => ({
        ...provided,
        border: state.isFocused && "#0B7CAA"
    }),
    placeholder: (provided: any, state: any) => ({
        ...provided,
        color: state.isFocused && "1px solid #0B7CAA",
    }),
    option: (provided, state) => ({
        ...provided,
        color: state.data.__isNew__ && "#0B7CAA"
    }),
}