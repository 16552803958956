export function getSingleSelected(selectedValue: any, sourceList: any) {
  let result = sourceList.find(
    (o: { value: any }) => o.value === selectedValue
  );
  return result === undefined || result === "" ? null : result;
}
export function getNameSelected(selectedValue: any, sourceList: any) {
  let result = sourceList.find((o: { code: any }) => o.code === selectedValue);
  return result === undefined || result === "" ? "" : result.value;
}

export function convertInWords(inputDate: any) {
  // let years = Math.floor(totalDays / 365);
  // let remainingDays = totalDays % 365;
  // let months = Math.floor(remainingDays / 30);
  // let days = remainingDays % 30;
  if (inputDate !== null && inputDate !== undefined && inputDate !== "") {
    // var dateParts = inputDate.split(" ")[0].split("-");

    // month is 0-based, that's why we need dataParts[1] - 1
    // var birthday = new Date(+dateParts[2], dateParts[1] - 1, +dateParts[0]);
    var birthday = new Date(inputDate);

    const now = new Date();
    const timeDiff = Math.abs(now.getTime() - birthday.getTime());
    const days = Math.floor(timeDiff / (1000 * 3600 * 24));
    const weeks = Math.floor(days / 7);
    const years = Math.floor(days / 365);
    const remainingDays = days % 365;
    const months = Math.floor(remainingDays / 30);
    const remainingDaysInMonths = remainingDays % 30;
    const remainingDaysInWeek = days % 7;

    let data = "";

    if (years > 0) {
      if (remainingDaysInMonths > 0 && months > 0) {
        data =
          "(" + years + "y " + months + "m and " + remainingDaysInMonths + "d)";
      } else if (remainingDaysInMonths > 0 || months > 0) {
        if (remainingDaysInMonths > 0) {
          data = "(" + years + "y and " + remainingDaysInMonths + "d)";
        } else {
          data = "(" + years + "y " + months + "m)";
        }
      } else {
        data = "(" + years + "y)";
      }
    } else {
      if (months > 6) {
        if (remainingDaysInMonths > 0) {
          data = "(" + months + "m and " + remainingDaysInMonths + "d)";
        } else {
          data = "(" + months + "m)";
        }
      } else {
        if (weeks > 0) {
          if (remainingDaysInWeek > 0) {
            data = "(" + weeks + "w and " + remainingDaysInWeek + "d)";
          } else {
            data = "(" + weeks + "w)";
          }
        } else {
          data = "(" + days + "d)";
        }
      }
    }

    return data;
  }
  return "";
}

export function ageInDays(birthDate: any) {
  let birthday = new Date(birthDate);
  const now = new Date(
    Date.UTC(
      new Date().getUTCFullYear(),
      new Date().getUTCMonth(),
      new Date().getUTCDate(),
      new Date().getUTCHours(),
      new Date().getUTCMinutes(),
      new Date().getUTCSeconds()
    )
  );
  const timeDiff = Math.abs(now.getTime() - birthday.getTime());
  const days = Math.floor(timeDiff / (1000 * 3600 * 24));
  return days;
}

export function ageInMonths(birthDate: any) {
  let birthday = new Date(birthDate);
  const now = new Date(
    Date.UTC(
      new Date().getUTCFullYear(),
      new Date().getUTCMonth(),
      new Date().getUTCDate(),
      new Date().getUTCHours(),
      new Date().getUTCMinutes(),
      new Date().getUTCSeconds()
    )
  );
  const timeDiff = Math.abs(now.getTime() - birthday.getTime());
  const days = Math.floor(timeDiff / (1000 * 3600 * 24));
  const months = Math.floor(days / 30);
  return months;
}

export function ageInWeeks(lmpDate: any) {
  let lmpday = new Date(lmpDate);
  const now = new Date(
    Date.UTC(
      new Date().getUTCFullYear(),
      new Date().getUTCMonth(),
      new Date().getUTCDate(),
      new Date().getUTCHours(),
      new Date().getUTCMinutes(),
      new Date().getUTCSeconds()
    )
  );
  const timeDiff = Math.abs(now.getTime() - lmpday.getTime());
  const days = Math.floor(timeDiff / (1000 * 3600 * 24));
  const weeks = Math.floor(days / 7);
  return weeks;
}

export function getSingleSelectedForHealthAssitant(selectedValue: any, sourceList: any) {
  let result = sourceList.find(
    (o: { multiFieldValue: any }) => o.multiFieldValue === selectedValue
  );
  return result === undefined || result === "" ? null : result;
}