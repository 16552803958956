import { GET, POST } from "./common/http";
import { BASE_URL } from "./common/const";

export const GetHeightDetailForChart = (parmas: any) =>
    GET(`${BASE_URL}/api/PhysicalGrowth/GetHeightDetailForChart?` + parmas, null);

export const GetWeightDetailForChart = (parmas: any) =>
    GET(`${BASE_URL}/api/PhysicalGrowth/GetWeightDetailForChart?` + parmas, null);

export const GetHCDetailForChart = (parmas: any) =>
    GET(`${BASE_URL}/api/PhysicalGrowth/GetHCDetailForChart?` + parmas, null);

export const GetMACDetailForChart = (parmas: any) =>
    GET(`${BASE_URL}/api/PhysicalGrowth/GetMACDetailForChart?` + parmas, null);

export const GetBMIDetailForChart = (parmas: any) =>
    GET(`${BASE_URL}/api/PhysicalGrowth/GetBMIDetailForChart?` + parmas, null);

export const GetMemberHeightDetailList = (parmas: any) =>
    GET(`${BASE_URL}/api/PhysicalGrowth/GetMemberHeightDetailList?` + parmas, null);

export const CreateHeightDetail = (data: any) =>
    POST(`${BASE_URL}/api/PhysicalGrowth/CreateMemberHeightDetail`, data);

export const GetMemberWeightDetailList = (parmas: any) =>
    GET(`${BASE_URL}/api/PhysicalGrowth/GetMemberWeightDetailList?` + parmas, null);

export const CreateWeightDetail = (data: any) =>
    POST(`${BASE_URL}/api/PhysicalGrowth/CreateMemberWeightDetail`, data);

export const GetMemberHCDetailList = (parmas: any) =>
    GET(`${BASE_URL}/api/PhysicalGrowth/GetMemberHCDetailList?` + parmas, null);

export const CreateHCDetail = (data: any) =>
    POST(`${BASE_URL}/api/PhysicalGrowth/CreateMemberHCDetail`, data);

export const GetMemberMACDetailList = (parmas: any) =>
    GET(`${BASE_URL}/api/PhysicalGrowth/GetMemberMACDetailList?` + parmas, null);

export const CreateMACDetail = (data: any) =>
    POST(`${BASE_URL}/api/PhysicalGrowth/CreateMemberMACDetail`, data);

export const GetMemberBmiDetailList = (parmas: any) =>
    GET(`${BASE_URL}/api/PhysicalGrowth/GetMemberBmiDetailList?` + parmas, null);

export const CreateBmiDetail = (data: any) =>
    POST(`${BASE_URL}/api/PhysicalGrowth/CreateMemberBmiDetail`, data);

export const GetMemberPhysicalGrowthDetailList = (parmas: any) =>
    GET(`${BASE_URL}/api/PhysicalGrowth/GetMemberPhysicalGrowthDetailList?` + parmas, null);

export const GetHeightDetailSummary = (parmas: any) =>
    GET(`${BASE_URL}/api/PhysicalGrowth/GetHeightDetailSummary?` + parmas, null);

export const GetWeightDetailSummary = (parmas: any) =>
    GET(`${BASE_URL}/api/PhysicalGrowth/GetWeightDetailSummary?` + parmas, null);

export const GetBMIDetailSummary = (parmas: any) =>
    GET(`${BASE_URL}/api/PhysicalGrowth/GetBMIDetailSummary?` + parmas, null);



