import React, { useEffect, useState } from 'react';
import './CustomPopup.scss';
import { Box } from '@mui/material';
import { PhotoProvider, PhotoView } from 'react-photo-view';
import 'react-photo-view/dist/react-photo-view.css';

export default function CustomPopup(props: any) {
    const [upperElements, setUpperElements] = useState('');
    const [noDataAvailable, setNoDataAvailable] = useState(false);
    const [bootomElements, setBootomElements] = useState('');

    async function fetchdata() {
        if (props.descriptionData.includes("https://app.pediaverse.org/emergency-condition.png")) {
            let dataOne = props.descriptionData.split('<PhotoProvider>')[0];
            let dataTwo = props.descriptionData.split('</PhotoProvider>')[1];
            setUpperElements(dataOne);
            setBootomElements(dataTwo);
        } else {
            setNoDataAvailable(true);
        }
    }
    // function onImageClick(e) {
    //     console.log('eeeee=>>>', e)
    // }
    useEffect(() => {
        fetchdata();
    }, [])
    return (
        <Box className="custom-popup-main-box">
            {/* <h4> NOTE: This section is only for the management of critically ill child just to save the life till the medical facility is available. It is strictly prohibited to rely just on this management plan as a whole. So before starting this critical patient saving plan, be sure to contact nearby medical facility / Doctor / Hospital and try to keep the child stable till you get medical facility.</h4><ul><li>Critical conditions which need to be managed urgently:<ul><li>Unconscious child, Child with seizures, Child with high grade fever and history of febrile seizures</li></ul></li><li>In any critically sick person/child 2 things have to be kept in mind:<ul><li>We just have to maintain cardiac activity (Heartbeats) and respiration (breathing) till the nearby emergency medical facility is available.</li><li>Presence of mind shown during the medical emergency conditions can help very much to save the life of a person.</li></ul></li></ul> */}
            {/* <h3>How to prepare the feeds for your child</h3><h4>When you start giving complementary feeds to your child, always keep the following things in mind.</h4><ul ><li>Always wash your hands with soap water before preparation of food item.</li><li>Always used washed, dry, sanitized, and clean vessels for preparation of feeds.</li><li>Always prepare fresh feed every-time, The left-out feeds can be consumed by parents.</li><li>Make sure that food items that you are going to use is eatable, fresh, non-stinky and palatable.</li><li>Always use the regional food substances to prepare the feed of the child.</li><li>Always use the combination of the food ingredients for preparation of the feeds.<p>For example</p><ul><li>Cereals which is available in the region.</li><li>Pulses which is available in the region</li><li>Oil / Ghee as per availability to make the food palatable.</li><li>Fruits as per the regional and seasonal availability.</li><li>Vegetables as per the regional and seasonal availability</li></ul></li><li>Always keep the consistency of the feed as per the age of the child. Start with liquids first then thick liquids and reach up to semisolid consistency.</li><li>Do not give any hard items like whole peanuts / cashew nuts / wall nuts / almonds which needs crushing and grinding before swallowing. All of these can give rise to choking / blockage of respiratory tubes.</li></ul> */}

            <Box className="body-box">
                {noDataAvailable === true ?
                    <Box
                        dangerouslySetInnerHTML={{
                            __html: props.descriptionData
                        }}>
                    </Box>
                    :
                    <>
                        <Box
                            dangerouslySetInnerHTML={{
                                __html: upperElements
                            }}>
                        </Box>
                        <Box>
                            <PhotoProvider>
                                <PhotoView src='https://app.pediaverse.org/emergency-condition.png'>
                                    <img style={{ height: 'auto', width: '100%' }} src='https://app.pediaverse.org/emergency-condition.png' alt='' />
                                </PhotoView>
                            </PhotoProvider>
                        </Box>
                        <Box
                            dangerouslySetInnerHTML={{
                                __html: bootomElements
                            }}>
                        </Box>
                    </>
                }
                {/* <h4> NOTE: This section is only for the management of critically ill child just to save the life till the medical facility is available. It is strictly prohibited to rely just on this management plan as a whole. So before starting this critical patient saving plan, be sure to contact nearby medical facility / Doctor / Hospital and try to keep the child stable till you get medical facility.</h4><ul><li>Critical conditions which need to be managed urgently:<ul><li>Unconscious child, Child with seizures, Child with high grade fever and history of febrile seizures</li><PhotoProvider><PhotoView src='https://app.pediaverse.org/emergency-condition.png'><img style={{ height: 'auto', width: '100%' }} src='https://app.pediaverse.org/emergency-condition.png' alt='' /></PhotoView></PhotoProvider></ul></li><li>In any critically sick person/child 2 things have to be kept in mind:<ul><li>We just have to maintain cardiac activity (Heartbeats) and respiration (breathing) till the nearby emergency medical facility is available.</li><li>Presence of mind shown during the medical emergency conditions can help very much to save the life of a person.</li></ul></li></ul> */}
                {/* <h4> NOTE: This section is only for the management of critically ill child just to save the life till the medical facility is available. It is strictly prohibited to rely just on this management plan as a whole. So before starting this critical patient saving plan, be sure to contact nearby medical facility / Doctor / Hospital and try to keep the child stable till you get medical facility.</h4><ul><li>Critical conditions which need to be managed urgently:<ul><li>Unconscious child, Child with seizures, Child with high grade fever and history of febrile seizures</li><img onClick={(e) => onImageClick(e)} style={{ height: 'auto', width: '100%' }} src='https://app.pediaverse.org/emergency-condition.png' alt='' /></ul></li><li>In any critically sick person/child 2 things have to be kept in mind:<ul><li>We just have to maintain cardiac activity (Heartbeats) and respiration (breathing) till the nearby emergency medical facility is available.</li><li>Presence of mind shown during the medical emergency conditions can help very much to save the life of a person.</li></ul></li></ul> */}.

            </Box>

        </Box >
    );
}