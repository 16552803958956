import { GET, POST } from "./common/http";
import { BASE_URL } from "./common/const";



export const GetVaccinationDetail = (params: any) =>
    GET(`${BASE_URL}/api/VaccinationSchedule/GetVaccinationDetail?` + params, null);

// export const UpdateVaccination = (data: any) =>
//     POST(`${BASE_URL}/api/VaccinationSchedule/VaccinationScheduleUpdate`, data);

// export const SaveVaccination = (data: any) =>
//     POST(`${BASE_URL}/api/VaccinationSchedule/VaccinationScheduleCreate`, data);

// new structured

export const GetListForVaccinationNameSelect = () =>
    GET(`${BASE_URL}/api/VaccinationSchedule/GetListForVaccinationNameSelect`, null);

export const GetListForVaccinationBrandNameSelect = () =>
    GET(`${BASE_URL}/api/VaccinationSchedule/GetListForVaccinationBrandNameSelect`, null);

export const GetListForVaccinationDoseNameSelect = () =>
    GET(`${BASE_URL}/api/VaccinationSchedule/GetListForVaccinationDoseNameSelect`, null);

export const CreateVaccine = (data: any) =>
    POST(`${BASE_URL}/api/VaccinationSchedule/Create`, data, true);

export const UpdateVaccine = (data: any) =>
    POST(`${BASE_URL}/api/VaccinationSchedule/Update`, data, true);

export const GetMemberVaccineList = (parmas: any) =>
    GET(`${BASE_URL}/api/VaccinationSchedule/GetMemberVaccineList?` + parmas, null);

export const GetMemberVaccineDetailForEdit = (parmas: any) =>
    GET(`${BASE_URL}/api/VaccinationSchedule/GetDetail?` + parmas, null);