import { GET, POST } from "./common/http";
import { BASE_URL } from "./common/const";

export const CreateParent = (data: any) =>
  POST(`${BASE_URL}/api/FamilyMember/Create`, data, true);

export const UpdateParent = (data: any) =>
  POST(`${BASE_URL}/api/FamilyMember/Update`, data, true);

export const GetFamilyRelationList = () =>
  GET(`${BASE_URL}/api/FamilyMember/GetListForSelect`, null);

export const GetIsParentExist = (params: any) =>
  GET(`${BASE_URL}/api/FamilyMember/GetIsParentExist?` + params, null);

export const GetMotherList = (params: any) =>
  GET(`${BASE_URL}/api/FamilyMember/GetMotherListForSelect?` + params, null);

export const GetIsAlreadyParentExist = (params: any) =>
  GET(`${BASE_URL}/api/FamilyMember/GetIsAlreadyParentExist?` + params, null);

export const GetFamilyMemberDetail = (params: any) =>
  GET(`${BASE_URL}/api/FamilyMember/GetDetail?` + params, null);

export const GetFamilyMemberList = (params: any) =>
  GET(`${BASE_URL}/api/FamilyMember/GetFamilyMemberList?` + params, null);


export const GetCurMemberProfilePic = (params: any) =>
  GET(`${BASE_URL}/api/FamilyMember/GetCurMemberProfilePic?` + params, null);

export const GetMemberList = (params: any) =>
  GET(`${BASE_URL}/api/FamilyMember/GetList?` + params, null);

// export const GetFamilyMemberDetailForContext = () =>
//   GET(`${BASE_URL}/api/FamilyMember/GetListForContextSave?`, null);

export const DeleteFamilyMember = (data: any) =>
  POST(`${BASE_URL}/api/FamilyMember/Delete`, data);