import React, { useContext, useEffect, useState } from "react";
import "./MilestoneDevelopment.scss";
import { Alert, AlertTitle, AppBar, Box, Button, Dialog, DialogContent, Fade, IconButton, Toolbar, Typography } from "@mui/material";
import dayjs, { Dayjs } from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import ReactTimeAgo from "react-time-ago";
import TimeAgo from "javascript-time-ago";
import en from "javascript-time-ago/locale/en.json";
import { useNavigate } from "react-router-dom";
import backIcon from "../../assets/icons/back.svg";
import noData from "../../assets/icons/no-data.svg";
import { PreferencesContext } from "../../PreferenceContext";
import { GetMemberMilestoneSummaryList } from "../../services/MilestoneDevelopmentService";
import { LocalizationProvider } from "@mui/x-date-pickers";
import utc from "dayjs/plugin/utc";
import tz from 'dayjs/plugin/timezone'
import NoDataAvailable from "../../components/NoDataAvailable/NoDataAvailable";
import CloseIcon from '@mui/icons-material/Close';
dayjs.extend(utc);
dayjs.extend(tz);
const timeZone = dayjs.tz.guess()
TimeAgo.addDefaultLocale(en);

export default function MilestoneDevelopment() {
  const { userContext, updateUserContext } = useContext(PreferencesContext);
  const [loading, setLoading] = useState(false);
  const [memberDevelopmentData, setMemberDevelopmentData] = useState([]);
  const [openEmergencyConditionDialog, setOpenEmergencyConditionDialog] = useState(false);
  const [showAlert, setAlertMessage] = useState({
    isSuccess: false,
    message: "",
  });
  const navigate = useNavigate();

  function backClick() {
    navigate("/dashboard");
  }

  function addMilestoneDevelopmentData() {
    navigate("/milestone-questions");
  }

  async function fetchSummaryData() {
    setLoading(true);
    try {
      let params = new URLSearchParams();
      params.append("familyId", userContext.loginDetail.familyId.toString());
      params.append(
        "familyMemberId",
        userContext.memberDetail.familyMemberId.toString()
      );
      const result: any = await GetMemberMilestoneSummaryList(params);
      let summaryList = result.data.data;
      setMemberDevelopmentData(summaryList);
    } catch (error) {
      setLoading(false);
      if (error) {
        setAlertMessage({
          isSuccess: true,
          message: error as string,
        });
      }
    }
    setLoading(false);
  }

  const handleCloseEmergencyConditionDialog = () => {
    setOpenEmergencyConditionDialog(false);
  };

  useEffect(() => {
    if (
      userContext.memberDetail !== undefined &&
      userContext.memberDetail.familyMemberId > 0
    ) {
      fetchSummaryData();
    }
  }, []);

  useEffect(() => {
    // fetchSummData();
  });

  return (

    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Fade in={true} timeout={{ enter: 500 }}>
        <Box className="milestone-development-main-box">
          <Box className="head-box">
            <Box className="back-box">
              <IconButton
                aria-label="back"
                className="back-icon"
                disableRipple
                onClick={() => backClick()}
              >
                <img src={backIcon} alt="logo" />
              </IconButton>
            </Box>
            <Box className="title-box">
              <Typography className="title-text">
                Milestone Development{" "}
              </Typography>
            </Box>
            <Box className="profile-box">
              <></>
            </Box>
          </Box>
          {memberDevelopmentData.length > 0 ? (
            <Box className="middle-box" onClick={addMilestoneDevelopmentData}>
              <Button variant="contained" className="add-button">
                Add
              </Button>
            </Box>
          ) : (
            <></>
          )}
          <Box className="body-box">

            <Box sx={{ pt: '10px', width: '100%',mb:'10px' }} className="disclaimer-box">
              <Alert severity="warning">
                <AlertTitle>Disclaimer:</AlertTitle>
                We are just giving you the rough outline regarding the health, growth and development of your child just to sensitize you to seek the medical care earlier if needed. Every child has to be examined individually by a registered medical practitioner before confirmation of status of health, growth and development of any child and A Doctor is the Final authority to decide regarding further management and intervention needed as felt necessary by the Doctor.
                <Box className="source-link-ref-box-title" >Source: <span className="source-link-ref" onClick={() => setOpenEmergencyConditionDialog(true)}>https://iapindia.org/guidelines-for-parents/</span></Box>
              </Alert>
            </Box>
            {memberDevelopmentData.length > 0 ? (
              memberDevelopmentData.map((row: any, index: any) => (
                <React.Fragment key={index}>
                  {/* <Box className="history-box" sx={{ backgroundColor: row.backgroundColor }}>
                    <Box className="summary-box summary-text" sx={{ color: row.color }}
                      dangerouslySetInnerHTML={{
                        __html: row.summary
                      }}>
                    </Box>
                    <Box className="time-box">
                      <Typography className="time-text">
                        <ReactTimeAgo
                          date={new Date(dayjs(dayjs(row.entryDate).format('YYYY-MM-DDTHH:mm:ss[Z]')).format().split('+')[0])}
                        />
                      </Typography>
                    </Box>
                  </Box> */}




                  {row.summaryForYes !== undefined && row.summaryForYes !== null && row.summaryForYes !== "" ?
                    <Box className="history-box" sx={{ backgroundColor: row.backgroundColorForYes }}>
                      <Box className="summary-box summary-text" sx={{ color: row.colorForYes }}
                        dangerouslySetInnerHTML={{
                          __html: row.summaryForYes
                        }}>
                      </Box>
                      <Box className="time-box">
                        <Typography className="time-text">
                          <ReactTimeAgo
                            date={new Date(dayjs(dayjs(row.entryDate).format('YYYY-MM-DDTHH:mm:ss[Z]')).format().split('+')[0])}
                          />
                        </Typography>
                      </Box>
                    </Box>
                    : null}
                  {row.summaryForNotYet !== undefined && row.summaryForNotYet !== null && row.summaryForNotYet !== "" ?
                    <Box className="history-box" sx={{ backgroundColor: row.backgroundColorForNotYet }}>
                      <Box className="summary-box summary-text" sx={{ color: row.colorForNotYet }}
                        dangerouslySetInnerHTML={{
                          __html: row.summaryForNotYet
                        }}>
                      </Box>
                      <Box className="time-box">
                        <Typography className="time-text">
                          <ReactTimeAgo
                            date={new Date(dayjs(dayjs(row.entryDate).format('YYYY-MM-DDTHH:mm:ss[Z]')).format().split('+')[0])}
                          />
                        </Typography>
                      </Box>
                    </Box>
                    : null}
                  {row.summaryForNotSure !== undefined && row.summaryForNotSure !== null && row.summaryForNotSure !== "" ?
                    <Box className="history-box" sx={{ backgroundColor: row.backgroundColorForNotSure }}>
                      <Box className="summary-box summary-text" sx={{ color: row.colorForNotSure }}
                        dangerouslySetInnerHTML={{
                          __html: row.summaryForNotSure
                        }}>
                      </Box>
                      <Box className="time-box">
                        <Typography className="time-text">
                          <ReactTimeAgo
                            date={new Date(dayjs(dayjs(row.entryDate).format('YYYY-MM-DDTHH:mm:ss[Z]')).format().split('+')[0])}
                          />
                        </Typography>
                      </Box>
                    </Box>
                    : null}



                </React.Fragment>
              ))
            ) : (
              <>
                {/* <Box className="no-summary-box">
                  <Box className="upper-box"></Box>
                  <Box className="bottom-box">
                    <Box className="img-box">
                      <img src={noData} alt="logo" />
                    </Box>
                    <Box>
                      <Box className="no-data-box">
                        <Typography className="no-summary-text">
                          Track your{" "}
                          <span>{userContext.memberDetail.firstName}'s</span>{" "}
                          Developement
                        </Typography>
                        <Typography className="no-summary-subtext">
                          Please enter the the details of milestones achieved by your child as per the age of your child <span><b>{userContext.memberDetail.firstName}</b>.</span> It will help you to make sure that your child is achieving the developmental milestones on time and help you for early identification in case of any delay in development and finding out solutions for it.
                        </Typography>
                      </Box>
                      {memberDevelopmentData.length > 0 ? (
                        <></>
                      ) : (
                        <Box
                          className="start-button-box"
                          onClick={addMilestoneDevelopmentData}
                        >
                          <Button variant="contained" className="add-button">
                            Start Now
                          </Button>
                        </Box>
                      )}
                    </Box>
                  </Box>
                </Box> */}

                <NoDataAvailable
                  submoduleName={'milestone'}
                  memberName={userContext.memberDetail.firstName}
                  submoduleClick={addMilestoneDevelopmentData}
                  ageCount={-1}
                />
              </>
            )}
          </Box>

          <Dialog
            fullScreen
            open={openEmergencyConditionDialog}
            onClose={handleCloseEmergencyConditionDialog}
            className="custom-popup-header">
            <React.Fragment >
              <AppBar sx={{ position: 'relative' }} color='primary'>
                <Toolbar className='custom-popup-header' >
                  <Box className='header-empty-box'></Box>
                  <Box className='close-box'>
                    <IconButton
                      edge="start"
                      color="inherit"
                      onClick={handleCloseEmergencyConditionDialog}
                      aria-label="close"
                      className='close-button'>
                      <CloseIcon />
                    </IconButton>
                  </Box>
                </Toolbar>
              </AppBar>
              <DialogContent style={{ padding: 0 }}>
                <iframe width="100%" height="100%" src="https://iapindia.org/guidelines-for-parents/" title="Pediaverse" ></iframe>
              </DialogContent>
            </React.Fragment>
          </Dialog>

        </Box>
      </Fade>
    </LocalizationProvider >

  );
}
