import { GET, POST } from "./common/http";
import { BASE_URL } from "./common/const";

export const SendNotifications = (data: any) =>
    POST(`${BASE_URL}/api/NotificationManger/SendNotification`, data);

export const GetPageList = () =>
    GET(`${BASE_URL}/api/NotificationManger/GetListForSelect`, null);

export const GetNotificationList = (params: any) =>
    GET(`${BASE_URL}/api/NotificationManger/GetList?` + params, null);