import { GET, POST } from "./common/http";
import { BASE_URL } from "./common/const";



// export const CreateMaternalDetail = (data: any) =>
//     POST(`${BASE_URL}/api/MaternalDetail/Create`, data);

export const GetCommonDiseasesList = (params: any) =>
    GET(`${BASE_URL}/api/CommonDiseases/GetList?` + params,null);

    // export const GetCommonDiseasesList = (params: any) =>
    // GET(`${BASE_URL}/api/CommonDiseases/GetList?`,null);

