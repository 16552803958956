import { Context } from "../models/Context";
import { getStorage, getVersion, setStorage, setVersion } from "./storage";

let token = "";

export const POST = (url: string, data: any = null, isFormData: boolean = false, fileToken = "") =>

  new Promise((resolve, reject) => {
    let localStorage = getStorage();
    let curVersion = getVersion();
    if (localStorage) {
      token = localStorage && (localStorage.token ? localStorage.token : "");
    }
    else {
      token = "";
    }

    let options = {
      headers: {
      },
    };
    if (isFormData === true) {
      options.headers = {
        Authorization: token ? `Bearer ${token}` : "",
        Accept: "*/*"
      };
    } else {
      options.headers = {
        Authorization: token ? `Bearer ${token}` : "",
        Accept: "application/json",
        "Content-Type": "application/json",
      };
    }
    let postOptions = { ...options.headers };

    if (fileToken.length > 0) {
      postOptions = {
        ...options.headers,
        // Authorization: fileToken ? `Bearer ${fileToken}` : "",
      };
    }

    return (
      fetch(url, {
        method: "POST",
        headers: postOptions,
        body: (isFormData === false) ? JSON.stringify(data) : data,
      })
        .then((response) =>
          response.json().then((body) => (
            {
              ok: response.ok,
              status: response.status,
              statusText: response.statusText,
              header: response.headers,
              data: body,
            }))
        )
        // .then((responseJson) => {
        //   //resolve(JSON.parse(responseJson));
        //   if (!responseJson.ok) {
        //     if (responseJson.status === 400) {
        //       //400 = bad request
        //       if (responseJson.data && responseJson.data.message)
        //         throw responseJson.data.message;
        //       else throw responseJson.statusText;
        //     } else throw responseJson.statusText;
        //   } else resolve(responseJson.data);
        // })
        // .catch((error) => {
        //   reject(error);
        // })
        .then((responseJson) => {
          //resolve(JSON.parse(responseJson));
          let apiVersion: any = responseJson.header !== null && responseJson.header.get('X-Version')!.toString();
          let isLogout = responseJson.header !== null && responseJson.header.get('X-Access')!.toString();
          if (curVersion !== null && curVersion !== undefined && curVersion !== "") {
            if (+apiVersion !== +curVersion) {
              setVersion(responseJson.header.get('X-Version')!.toString());
              caches.keys().then((names) => {
                names.forEach((name) => {
                  caches.delete(name);
                });
              });
              // window.location.reload();
              if (isLogout === "true") {
                //remove local storage
                let curuserInfo: Context = new Context();
                setStorage(curuserInfo);
              }
            }
          } else {
            setVersion(apiVersion);
            caches.keys().then((names) => {
              names.forEach((name) => {
                caches.delete(name);
              });
            });
            // window.location.reload();
          }
          if (!responseJson.ok) {
            if (responseJson.status === 400) {
              //400 = bad request
              if (responseJson.data && responseJson.data.message)
                throw responseJson.data.message;
              else throw responseJson.statusText;
            } else throw responseJson.statusText;
          } else resolve(responseJson.data);
        })
        .catch((error) => {
          // window.location.href = MAINTENANCE_URL;
          reject(error);
        })
    );
  });

export const GET = (url: any, params: any) =>

  new Promise((resolve, reject) => {
    let localStorage = getStorage();
    let curVersion = getVersion();
    if (localStorage) {
      token = localStorage && (localStorage.token ? localStorage.token : "");
    }
    else {
      token = "";
    }

    let options = {
      headers: {

        Authorization: token ? `Bearer ${token}` : "",
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };
    return fetch(url, { method: "GET", ...options })
      .then((response) =>
        response.json().then((body) => ({
          ok: response.ok,
          status: response.status,
          statusText: response.statusText,
          header: response.headers,
          data: body,
        }))
      )
      // .then((responseJson) => {
      //   resolve(responseJson);

      //   if (!responseJson.ok) {
      //     if (responseJson.status === 400) {
      //       //400 = bad request
      //       if (responseJson.data && responseJson.data.message)
      //         throw responseJson.data.message;
      //       else throw responseJson.statusText;
      //     } else throw responseJson.statusText;
      //   } else resolve(responseJson.data);
      // })
      // .catch((error) => {
      //   reject(error);
      // });
      .then((responseJson) => {
        let apiVersion: any = responseJson.header !== null && responseJson.header.get('X-Version')!.toString();
        let isLogout = responseJson.header !== null && responseJson.header.get('X-Access')!.toString();
        if (curVersion !== null && curVersion !== undefined && curVersion !== "") {
          if (+apiVersion !== +curVersion) {
            setVersion(responseJson.header.get('X-Version')!.toString());
            caches.keys().then((names) => {
              names.forEach((name) => {
                caches.delete(name);
              });
            });
            // window.location.reload()
            if (isLogout === "true") {
              //remove local storage
              let curuserInfo: Context = new Context();
              setStorage(curuserInfo);
            }
          }
        } else {
          setVersion(apiVersion);
          caches.keys().then((names) => {
            names.forEach((name) => {
              caches.delete(name);
            });
          });
          // window.location.reload();
        }
        resolve(responseJson);

        if (!responseJson.ok) {
          if (responseJson.status === 400) {
            //400 = bad request
            if (responseJson.data && responseJson.data.message)
              throw responseJson.data.message;
            else throw responseJson.statusText;
          } else throw responseJson.statusText;
        } else resolve(responseJson.data);
      })
      .catch((error) => {
        // window.location.href = MAINTENANCE_URL;
        reject(error);
      });
  });

export const PUT = (url: string, data = {}, fileToken = "") =>

  new Promise((resolve, reject) => {
    let localStorage = getStorage();

    if (localStorage) {
      token = localStorage && (localStorage.token ? localStorage.token : "");
    }
    else {
      token = "";
    }

    let options = {
      headers: {
        Authorization: token ? `Bearer ${token}` : "",
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };

    let postOptions = { ...options.headers };

    if (fileToken.length > 0) {
      postOptions = {
        ...options.headers,
        // Authorization: fileToken ? `Bearer ${fileToken}` : "",
      };
    }

    return (
      fetch(url, {
        method: "PUT",
        headers: postOptions,
        body: JSON.stringify(data),
      })
        .then((response) =>
          response.json().then((body) => (
            {
              ok: response.ok,
              status: response.status,
              statusText: response.statusText,
              data: body,
            }))
        )
        .then((responseJson) => {
          //resolve(JSON.parse(responseJson));
          if (!responseJson.ok) {
            if (responseJson.status === 400) {
              //400 = bad request
              if (responseJson.data && responseJson.data.message)
                throw responseJson.data.message;
              else throw responseJson.statusText;
            } else throw responseJson.statusText;
          } else resolve(responseJson.data);
        })
        .catch((error) => {
          reject(error);
        })
    );
  });

export const DELETE = (url: string, data = {}, fileToken = "") =>

  new Promise((resolve, reject) => {
    let localStorage = getStorage();

    if (localStorage) {
      token = localStorage && (localStorage.token ? localStorage.token : "");
    }
    else {
      token = "";
    }

    let options = {
      headers: {
        Authorization: token ? `Bearer ${token}` : "",
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };

    let postOptions = { ...options.headers };

    if (fileToken.length > 0) {
      postOptions = {
        ...options.headers,
        // Authorization: fileToken ? `Bearer ${fileToken}` : "",
      };
    }

    return (
      fetch(url, {
        method: "DELETE",
        headers: postOptions,
        body: JSON.stringify(data),
      })
        .then((response) =>
          response.json().then((body) => (
            {
              ok: response.ok,
              status: response.status,
              statusText: response.statusText,
              data: body,
            }))
        )
        .then((responseJson) => {
          //resolve(JSON.parse(responseJson));
          if (!responseJson.ok) {
            if (responseJson.status === 400) {
              //400 = bad request
              if (responseJson.data && responseJson.data.message)
                throw responseJson.data.message;
              else throw responseJson.statusText;
            } else throw responseJson.statusText;
          } else resolve(responseJson.data);
        })
        .catch((error) => {
          reject(error);
        })
    );
  });