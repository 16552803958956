import React, { useContext, useState } from "react";
import "./PregnancyOutcome.scss";
import { useNavigate } from "react-router-dom";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import Select from 'react-select';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { MobileDatePicker } from "@mui/x-date-pickers";
import { Box, Button, Fade, FormControl, Grid, IconButton, Skeleton, TextField, Typography } from "@mui/material";
import backIcon from '../../assets/icons/back.svg';
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import FileUploadIcon from '@mui/icons-material/FileUpload';
import dayjs from "dayjs";
import { getSingleSelected } from "../../shared/CommonFuctions";
import CustomButton from "../../components/CustomButton/CustomButton";
import { PreferencesContext } from "../../PreferenceContext";
import { AlertType } from "../../shared/AlertType";
import { CreatePregnancyOutcome } from "../../services/PregnancyOutcomeService";
import { MemberDetail } from "../../services/models/Context";

const formInitialValues = {
    pregnancyOutcomeId: 0,
    outcome: "",
    typeOfDelivery: "",
    dateOfDelivery: "",
    dateOfTermination: "",
    reasonOfTermination: "",
    note: '',
    documentUpload: null,
    // documentPreview: '',
    // documentRaw: '',
    // documentName: '',
    // documentBase: '',
};

let intData: any = {
    preview: "", raw: ""
}

export default function PregnancyOutcome() {
    const { userContext, updateUserContext } = useContext(PreferencesContext);
    const [pregnancyOutcomeData, setPregnancyOutcomeData] = useState(formInitialValues);
    const [loading, setLoading] = useState(false);
    const [submitLoading, setSubmitLoading] = useState(false);
    const [documentFile, setDocumentFile] = useState<any>();
    const [document, setDocument] = useState(intData);
    const [serverDocument, setServerDocument] = useState('');
    const [documentName, setDocumentName] = useState('');
    const [showAlert, setAlertMessage] = useState({
        isSuccess: false,
        message: "",
    });
    const navigate = useNavigate();

    const pregnancyOutcomeOption = [
        { value: 'Delivery', label: 'Delivery' },
        { value: 'Termination', label: 'Termination' }
    ];

    const typeOfDeliveryOption = [
        { value: 'Normal', label: 'Normal' },
        { value: 'Cesarean ', label: 'Cesarean' }
    ];

    const valildationSchema = Yup.object().shape({
        outcome: Yup.string().required("Pregnancy Outcome is required."),

        typeOfDelivery: Yup.string().when("outcome", {
            is: (outcome: any) => (outcome !== undefined && outcome === 'Delivery'),
            then: Yup.string().required("Type Of Delivery is required."),
            otherwise: Yup.string()
        }),

        dateOfDelivery: Yup.string().when("outcome", {
            is: (outcome: any) => (outcome !== undefined && outcome === 'Delivery'),
            then: Yup.string().required("Date Of Delivery is required."),
            otherwise: Yup.string()
        }),

        dateOfTermination: Yup.string().when("outcome", {
            is: (outcome: any) => (outcome !== undefined && outcome === 'Termination'),
            then: Yup.string().required("Date Of Termination is required."),
            otherwise: Yup.string()
        }),

        reasonOfTermination: Yup.string().when("outcome", {
            is: (outcome: any) => (outcome !== undefined && outcome === 'Termination'),
            then: Yup.string().required("Reason Of Termination is required."),
            otherwise: Yup.string()

        }),
    });

    function backClick() {
        navigate('/dashboard');
    }

    const goToChild = () => {
        navigate('/child-detail');
    }
    const goToDashboard = () => {
        navigate('/dashboard');
    }

    const handleDocumentUpload = (e: any, props: any) => {
        if (e.target.files.length) {
            var file = e.target.files[0];
            const fileSize = e.target.files.item(0).size;
            const fileMb = fileSize / 1024 ** 2;
            if (fileMb >= 2) {
                props.setFieldValue(
                    `documentUpload`,
                    "",
                    true
                );
            } else {
                setDocument({
                    preview: URL.createObjectURL(e.target.files[0]),
                    raw: e.target.files[0]
                });
            }
        }
        setDocumentName(e.target.files[0].name);
        setDocumentFile(e.target.files[0]);
    };

    const viewDocument = (docName: any, docPreview: any) => {
        // setIsShowDocumentPreview(true);
        // setDocumentFileName(docName);
        // setVaccineDocument(docPreview);
    }

    const cancelDocument = (index: any) => {
        // setOpenDeleteDocument(true);
        // setDeleteVaccineId(index);
    }

    async function submitPregnancyOutcomeForm(value: any) {
         setSubmitLoading(true);
        const formData = new FormData();
        const pregnancyData = value;

        formData.append('maternalDetailId', userContext.memberDetail.maternalDetailId.toString());
        formData.append('familyId', userContext.memberDetail.familyId.toString());
        formData.append('familyMemberId', userContext.memberDetail.familyMemberId.toString());
        formData.append('pregnancyOutcomeId', pregnancyData.pregnancyOutcomeId.toString());
        formData.append('outcome', pregnancyData.outcome);
        formData.append('typeOfDelivery', pregnancyData.typeOfDelivery);
        formData.append('dateOfDelivery', pregnancyData.dateOfDelivery !== '' ? dayjs(pregnancyData.dateOfDelivery).format('MM/DD/YYYY') : '');
        formData.append('reasonOfTermination', pregnancyData.reasonOfTermination);
        formData.append('dateOfTermination', pregnancyData.dateOfTermination !== '' ? dayjs(pregnancyData.dateOfTermination).format('MM/DD/YYYY') : '');
        formData.append('note', pregnancyData.note);
        formData.append('document', documentFile);
        formData.append('documentName', documentName);
        formData.append('serverImage', serverDocument);
        formData.append('dataManagedBy', userContext.loginDetail.userId.toString());

        if (pregnancyData.pregnancyOutcomeId > 0) {
            try {

            } catch (error) {
                updateUserContext({
                    ...userContext,
                    isAlert: true,
                    alertMessage: error.response.message,
                    alertType: AlertType.Error,
                });
                setSubmitLoading(false);
            }
        } else {
            try {
                const resultSave: any = await CreatePregnancyOutcome(formData);
                let data = resultSave.data
                setSubmitLoading(false);

                if (data.outcome === 'Delivery') {
                    updateUserContext({
                        ...userContext,
                        currFamilyMemberId: data.familyMemberId,
                        dateOfDelivery: data.dateOfDelivery,
                        isBabyDelivered: true
                    });
                    goToChild();
                } else {
                    goToDashboard();
                }

            } catch (error) {
                setLoading(false);
                if (error) {
                    setAlertMessage({
                        isSuccess: true,
                        message: error as string,
                    });
                }
            }
        }
    }


    return (
        <Fade in={true} timeout={{ enter: 500 }}>
            <Box className="pregnancy-outcome-main-box">
                <Box className="head-box">
                    <Box className="back-box">
                        <IconButton aria-label="back" className='back-icon' disableRipple
                            onClick={() => backClick()}>
                            <img src={backIcon} alt='logo' />
                        </IconButton>
                    </Box>
                    <Box className="title-box">
                        <Typography className="title-text">Pregnancy outcome</Typography>
                    </Box>
                    <Box className="skip-box">
                        <></>
                    </Box>
                </Box>
                <Formik
                    enableReinitialize
                    initialValues={pregnancyOutcomeData}
                    onSubmit={(values) => {
                        submitPregnancyOutcomeForm(values);
                    }}
                    validationSchema={valildationSchema}>
                    {(props) => {
                        return (
                            <Form>
                                <Box className="body-box">
                                    <Grid
                                        container
                                        spacing={1}
                                        padding={2}>
                                        <Grid item xs={12} md={12} sm={12} zIndex={999}>
                                            <FormControl fullWidth style={{ width: '100%' }}>
                                                {loading ?
                                                    <Skeleton animation="wave" variant="rounded" width='auto' height={45} /> :
                                                    <Select
                                                        id="outcome"
                                                        classNamePrefix="select"
                                                        styles={
                                                            props.values.outcome === "" &&
                                                                props.errors.outcome &&
                                                                props.touched.outcome
                                                                ? selectStyles
                                                                : undefined
                                                        }
                                                        isClearable={true}
                                                        isSearchable={true}
                                                        placeholder={"Outcome"}
                                                        theme={(theme) => ({
                                                            ...theme,
                                                            colors: {
                                                                ...theme.colors,
                                                                primary: "#0B7CAA",
                                                                primary25: "#F1F6FE",
                                                                primary50: "#e1dfea",
                                                            },
                                                        })}
                                                        value={getSingleSelected(
                                                            props.values.outcome,
                                                            pregnancyOutcomeOption
                                                        )}
                                                        onChange={(e, value) => {
                                                            props.setFieldValue(
                                                                "outcome",
                                                                e !== null ? e.value : "",
                                                                false
                                                            );
                                                        }}
                                                        options={pregnancyOutcomeOption}
                                                        className={
                                                            props.values.outcome === "" &&
                                                                props.errors.outcome &&
                                                                props.touched.outcome
                                                                ? "text-input"
                                                                : "text-input"
                                                        }
                                                    />
                                                }
                                                {props.values.outcome === "" &&
                                                    props.errors.outcome &&
                                                    props.touched.outcome && (
                                                        <Typography
                                                            className={
                                                                props.values.outcome === "" &&
                                                                    props.errors.outcome &&
                                                                    props.touched.outcome
                                                                    ? "validation-message text-input"
                                                                    : "text-input"
                                                            }
                                                        >
                                                            {props.errors.outcome}
                                                        </Typography>
                                                    )}
                                            </FormControl>
                                        </Grid>

                                        {props.values.outcome === 'Delivery' ?
                                            <>
                                                <Grid item xs={12} md={12} sm={12} zIndex={99}>
                                                    <FormControl fullWidth style={{ width: '100%' }}>
                                                        {loading ?
                                                            <Skeleton animation="wave" variant="rounded" width='auto' height={45} /> :
                                                            <Select
                                                                id="typeOfDelivery"
                                                                classNamePrefix="select"
                                                                styles={
                                                                    props.values.typeOfDelivery === "" &&
                                                                        props.errors.typeOfDelivery &&
                                                                        props.touched.typeOfDelivery
                                                                        ? selectStyles
                                                                        : undefined
                                                                }
                                                                isClearable={true}
                                                                isSearchable={true}
                                                                placeholder={"Type of delivery"}
                                                                theme={(theme) => ({
                                                                    ...theme,
                                                                    colors: {
                                                                        ...theme.colors,
                                                                        primary: "#0B7CAA",
                                                                        primary25: "#F1F6FE",
                                                                        primary50: "#e1dfea",
                                                                    },
                                                                })}
                                                                value={getSingleSelected(
                                                                    props.values.typeOfDelivery.toString(),
                                                                    typeOfDeliveryOption
                                                                )}
                                                                onChange={(e, value) => {
                                                                    props.setFieldValue(
                                                                        "typeOfDelivery",
                                                                        e !== null ? e.value : "",
                                                                        false
                                                                    );
                                                                }}
                                                                options={typeOfDeliveryOption}
                                                                className={
                                                                    props.values.typeOfDelivery === "" &&
                                                                        props.errors.typeOfDelivery &&
                                                                        props.touched.typeOfDelivery
                                                                        ? "text-input "
                                                                        : "text-input"
                                                                }
                                                            />
                                                        }
                                                        {props.values.typeOfDelivery === "" &&
                                                            props.errors.typeOfDelivery &&
                                                            props.touched.typeOfDelivery && (
                                                                <Typography
                                                                    className={
                                                                        props.values.typeOfDelivery === "" &&
                                                                            props.errors.typeOfDelivery &&
                                                                            props.touched.typeOfDelivery
                                                                            ? "validation-message text-input"
                                                                            : "text-input"
                                                                    }
                                                                >
                                                                    {props.errors.typeOfDelivery}
                                                                </Typography>
                                                            )}
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={12} sm={12} md={12}>
                                                    {loading ?
                                                        <Skeleton animation="wave" variant="rounded" width='auto' height={45} /> :
                                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                            <DemoContainer components={['MobileDatePicker']}>
                                                                <MobileDatePicker
                                                                    id="dateOfDelivery"
                                                                    label="Date of Delivery"
                                                                    className={
                                                                        props.values.dateOfDelivery === "" &&
                                                                            props.errors.dateOfDelivery &&
                                                                            props.touched.dateOfDelivery
                                                                            ? "datepicker validation-message"
                                                                            : "datepicker"
                                                                    }
                                                                    sx={{ width: '100%' }}
                                                                    format='DD/MM/YYYY'
                                                                    disableFuture
                                                                    value={dayjs(props.values.dateOfDelivery)}
                                                                    onChange={(e: any) => {
                                                                        props.setFieldValue(
                                                                            "dateOfDelivery",
                                                                            e !== null ? e.toDate() : "",
                                                                            true
                                                                        );
                                                                        // setChildBirthDate(e.toDate());
                                                                        // handaleOpenBirthDatePopUp(props);

                                                                    }} />
                                                            </DemoContainer>
                                                        </LocalizationProvider>}
                                                    {props.values.dateOfDelivery === "" &&
                                                        props.errors.dateOfDelivery &&
                                                        props.touched.dateOfDelivery && (
                                                            <Typography
                                                                className={
                                                                    props.values.dateOfDelivery === "" &&
                                                                        props.errors.dateOfDelivery &&
                                                                        props.touched.dateOfDelivery
                                                                        ? "validation-message text-input"
                                                                        : "text-input"
                                                                }>
                                                                {props.errors.dateOfDelivery}
                                                            </Typography>
                                                        )}
                                                </Grid>
                                            </>
                                            : null}

                                        {props.values.outcome === 'Termination' ?
                                            <>
                                                <Grid item xs={12} sm={12} md={12}>
                                                    {loading ?
                                                        <Skeleton animation="wave" variant="rounded" width='auto' height={45} /> :
                                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                            <DemoContainer components={['MobileDatePicker']}>
                                                                <MobileDatePicker
                                                                    id="dateOfTermination"
                                                                    label="Date of Termination"
                                                                    className={
                                                                        props.values.dateOfTermination === "" &&
                                                                            props.errors.dateOfTermination &&
                                                                            props.touched.dateOfTermination
                                                                            ? "datepicker validation-message"
                                                                            : "datepicker"
                                                                    }
                                                                    sx={{ width: '100%' }}
                                                                    format='DD/MM/YYYY'
                                                                    disableFuture
                                                                    value={dayjs(props.values.dateOfTermination)}
                                                                    onChange={(e: any) => {
                                                                        props.setFieldValue(
                                                                            "dateOfTermination",
                                                                            e !== null ? e.toDate() : "",
                                                                            true
                                                                        );
                                                                        // setChildBirthDate(e.toDate());
                                                                        // handaleOpenBirthDatePopUp(props);

                                                                    }} />
                                                            </DemoContainer>
                                                        </LocalizationProvider>}
                                                    {props.values.dateOfTermination === "" &&
                                                        props.errors.dateOfTermination &&
                                                        props.touched.dateOfTermination && (
                                                            <Typography
                                                                className={
                                                                    props.values.dateOfTermination === "" &&
                                                                        props.errors.dateOfTermination &&
                                                                        props.touched.dateOfTermination
                                                                        ? "validation-message text-input"
                                                                        : "text-input"
                                                                }>
                                                                {props.errors.dateOfTermination}
                                                            </Typography>
                                                        )}
                                                </Grid>
                                                <Grid item xs={12} md={12} sm={12}>
                                                    <FormControl fullWidth>
                                                        {loading ?
                                                            <Skeleton animation="wave" variant="rounded" width='auto' height={90} /> :
                                                            <TextField
                                                                id="reasonOfTermination"
                                                                label="Reason of termination"
                                                                fullWidth
                                                                size="small"
                                                                multiline={true}
                                                                rows={3}
                                                                onChange={(e: any) => {
                                                                    props.setFieldValue(
                                                                        "reasonOfTermination",
                                                                        e.target.value !== null ? e.target.value : "",
                                                                        true
                                                                    );
                                                                }}
                                                                onBlur={props.handleBlur}
                                                                value={props.values.reasonOfTermination}
                                                                inputProps={{ maxLength: 200 }}
                                                                variant="outlined"
                                                                error={
                                                                    props.errors.reasonOfTermination &&
                                                                        props.touched.reasonOfTermination
                                                                        ? true
                                                                        : false
                                                                }
                                                                className={
                                                                    props.errors.reasonOfTermination &&
                                                                        props.touched.reasonOfTermination
                                                                        ? "text-input validation-message"
                                                                        : "text-input"
                                                                }
                                                            />}
                                                        {props.errors.reasonOfTermination &&
                                                            props.touched.reasonOfTermination && (
                                                                <Typography
                                                                    className={
                                                                        props.errors.reasonOfTermination &&
                                                                            props.touched.reasonOfTermination
                                                                            ? "validation-message"
                                                                            : "validation-message"
                                                                    }
                                                                >
                                                                    {props.errors.reasonOfTermination}
                                                                </Typography>
                                                            )}
                                                    </FormControl>
                                                </Grid>
                                            </>
                                            : null}
                                        <Grid item xs={12} md={12} sm={12}>
                                            <FormControl fullWidth>
                                                {loading ?
                                                    <Skeleton animation="wave" variant="rounded" width='auto' height={90} /> :
                                                    <TextField
                                                        id="note"
                                                        label="Notes (optional)"
                                                        fullWidth
                                                        size="small"
                                                        multiline={true}
                                                        rows={3}
                                                        onChange={(e: any) => {
                                                            props.setFieldValue(
                                                                "note",
                                                                e.target.value !== null ? e.target.value : "",
                                                                true
                                                            );
                                                        }}
                                                        onBlur={props.handleBlur}
                                                        value={props.values.note}
                                                        inputProps={{ maxLength: 200 }}
                                                        variant="outlined"
                                                        className="text-input"

                                                    />}
                                            </FormControl>
                                        </Grid>

                                        <Grid item xs={12} sm={12} md={12}>
                                            <FormControl fullWidth>
                                                {loading ?
                                                    <Skeleton animation="wave" variant="rounded" width='auto' height={45} /> :
                                                    <Box>
                                                        <label htmlFor={`upload-photo`}>
                                                            <input
                                                                style={{ display: 'none' }}
                                                                id={`upload-photo`}
                                                                name="upload-photo"
                                                                type="file"
                                                                onChange={(e: any) => {
                                                                    handleDocumentUpload(e, props);
                                                                }}
                                                            />
                                                            <Button className="uploade-document-buttom" variant="outlined" component="span" startIcon={<FileUploadIcon />}>
                                                                Obstetrics history
                                                            </Button>
                                                        </label>

                                                        {documentName !== undefined && documentName !== null && documentName !== "" ?
                                                            <Box className="uploade-document-name">
                                                                <Box className="image-box" onClick={() => viewDocument(documentName, document.preview)}>
                                                                    <img className='image-preview' alt="logo" src={document.preview} />
                                                                </Box>
                                                                <Box className="document-name-box" onClick={() => viewDocument(documentName, document.preview)}>
                                                                    <Typography className="doc-name">
                                                                        {documentName}
                                                                    </Typography>
                                                                </Box>
                                                                <Box className="document-icons-main-box">
                                                                    <Box className="delete-box">
                                                                        <DeleteRoundedIcon className="uploade-document-delete-icon" />
                                                                    </Box>
                                                                </Box>
                                                            </Box>
                                                            :
                                                            (props.values.documentUpload === "" ? <Typography className="text-input validation-message">Please select a file less than 2MB.</Typography> : null)
                                                        }
                                                    </Box>
                                                }
                                            </FormControl>
                                        </Grid>

                                        <Box className="bottom-box">
                                            <CustomButton
                                                disabled={false}
                                                class="add-button"
                                                isLoading={submitLoading}
                                                title='Save'
                                            />
                                        </Box>
                                    </Grid>
                                </Box>
                            </Form>)
                    }}</Formik>
            </Box>
        </Fade>
    )
}

const selectStyles = {
    control: (provided: any, state: any) => ({
        ...provided,
        border: state.isFocused ? "1px solid #0B7CAA" : "1px solid #d32f2f",
    }),
    placeholder: (provided: any, state: any) => ({
        ...provided,
        color: state.isFocused ? state.selectProps.color : "#d32f2f",
    }),
};