import React from 'react';
import './Settings.scss';
import { useNavigate } from "react-router-dom";
import { Box, Card, Fade, Grid, IconButton, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Typography } from '@mui/material';
import CustomBottomSheet from '../CustomBottomSheet/CustomBottomSheet';
import LanguageIcon from '@mui/icons-material/Language';
import ChevronRightRoundedIcon from '@mui/icons-material/ChevronRightRounded';
import { useTranslation } from 'react-i18next';

export default function Settings() {
    let navigate = useNavigate();

    const { i18n, t } = useTranslation();

    const goToLanguage = () => {
        navigate('/language')
    }

    return (
        <Fade in={true} timeout={{ enter: 500 }}>
            <Box className="setting-main-box">
                <CustomBottomSheet>
                    <Box className="setting-body">
                        <Box className="setting-box">
                            <Typography className="text-name">{t('Settings.Settings')}</Typography>
                        </Box>
                        <Box className="setting-list">
                            <Box className="setting-item" onClick={goToLanguage}>
                                <Box className="setting-item-left-box">
                                    <Box className="setting-left-icon-box"><LanguageIcon /></Box>
                                    <Box className="setting-text-box"><Typography className="setting-text">{t('Settings.Language')}</Typography></Box>
                                </Box>
                                <Box className="setting-item-right-box">
                                    <Box className="setting-right-icon-box">
                                        <ChevronRightRoundedIcon />
                                    </Box>

                                </Box>

                            </Box>

                        </Box>
                    </Box>
                </CustomBottomSheet>
            </Box >
        </Fade>
    );
}