import React, { useContext, useEffect, useState } from "react";
import "./NotificationSend.scss";
import { Box, Fade, FormControl, Grid, IconButton, Skeleton, TextField, Typography } from "@mui/material";
import backIcon from '../../assets/icons/back.svg';
import { useNavigate } from "react-router-dom";
import { PreferencesContext } from "../../PreferenceContext";
import CustomButton from "../../components/CustomButton/CustomButton";
import { Form, Formik } from "formik";
import Select from 'react-select';
import * as Yup from "yup";
import { AlertType } from "../../shared/AlertType";
import { GetPageList, SendNotifications } from "../../services/NotificationMangerService";
import { getSingleSelected } from "../../shared/CommonFuctions";

const formInitialValues = {
    notificationId: 0,
    ageStart: "",
    ageEnd: "",
    ageType: 'Days',
    title: "",
    body: "",
    urlPage: "/dashboard",
};

export default function NotificationSend() {
    const { userContext, updateUserContext } = useContext(PreferencesContext);
    const [loading, setLoading] = useState(false);
    const [submitLoading, setSubmitLoading] = useState(false);
    const [pageListData, setPageListData] = useState([]);
    const [notificationData, setNotificationData] = useState(formInitialValues);
    const [notificationCount, setNotificationCount] = useState(0);
    const [isFocused, setFocused] = useState(false);

    let navigate = useNavigate();

    function backClick() {
        updateUserContext({
            ...userContext,
            memberDetail: {
                ...userContext.memberDetail,
                notificationCount: +notificationCount
            }
        });
        navigate('/dashboard');
    }

    const valildationSchema = Yup.object().shape({
        ageStart: Yup.string().required("Age start is required."),
        ageEnd: Yup.string().required("Age end is required."),
        title: Yup.string().required("Title is required."),
        body: Yup.string().required("Body is required."),
        urlPage: Yup.string().required("URL/Page is required.")
    });

    async function submitNotificationForm(values: any) {
        let SendNotificationData = {
            notificationId: values.notificationId,
            ageStart: values.ageStart,
            ageEnd: values.ageEnd,
            ageType: values.ageType,
            title: values.title,
            body: values.body,
            urlPage: values.urlPage
        };
        try {
            setSubmitLoading(true);
            const resultData: any = await SendNotifications(SendNotificationData);
            let data = resultData.data;
            for (let i = 0; i < data.length; i++) {
                if (userContext !== undefined && userContext.memberDetail !== undefined && userContext.memberDetail !== null && userContext.memberDetail.familyId !== null) {
                    if (+userContext.memberDetail.familyId === +data[i].familyId && +userContext.memberDetail.familyMemberId === +data[i].familyMemberId) {
                        setNotificationCount(data[i].notificationCount);
                    }
                }
            };

            setSubmitLoading(false);
            updateUserContext({
                ...userContext,
                isAlert: true,
                alertMessage: "Notification sent",
                alertType: AlertType.Success,
            });
        }
        catch (e: any) {
            updateUserContext({
                ...userContext,
                isAlert: true,
                alertMessage: e.message,
                alertType: AlertType.Error,
            });
        }
    }

    async function fetchPageList() {
        setLoading(true);
        try {
            const result: any = await GetPageList();
            setPageListData(result.data.data);
        }
        catch (error) {
            if (error) {
                updateUserContext({
                    ...userContext,
                    isAlert: true,
                    alertMessage: 'Something went wrong',
                    // alertMessage: error.response.message,
                    alertType: AlertType.Error,
                });
            }
        }
        setLoading(false);
    }

    useEffect(() => {
        fetchPageList();
    }, []);

    return (
        <Fade in={true} timeout={{ enter: 500 }}>
            <Box className="notification-main-box">
                <Box className="head-box">
                    <Box className="back-box">

                        <IconButton aria-label="back" className='back-icon' disableRipple
                            onClick={() => backClick()}>
                            <img src={backIcon} alt='logo' />
                        </IconButton>
                    </Box>
                    <Box className="title-box">
                        <Typography className="title-text">Notification send</Typography>
                    </Box>
                    <Box className="skip-box">
                        <></>
                    </Box>
                </Box>

                <Formik
                    enableReinitialize
                    initialValues={notificationData}
                    onSubmit={(values) => {
                        submitNotificationForm(values);
                    }}
                    validationSchema={valildationSchema}>
                    {(props) => {
                        return (
                            <Form>
                                <Box className="body-box">
                                    <Grid
                                        container
                                        spacing={2}
                                        padding={2}>

                                        <Grid item xs={12} sm={12} md={12}>
                                            <FormControl fullWidth>
                                                {loading ?
                                                    <Skeleton animation="wave" variant="rounded" width='auto' height={45} /> :
                                                    <TextField
                                                        id="ageStart"
                                                        label="Age start (In days)"
                                                        fullWidth
                                                        type="number"
                                                        size="small"
                                                        onChange={(e: any) => {
                                                            props.setFieldValue(
                                                                "ageStart",
                                                                e.target.value !== null ? e.target.value : "",
                                                                true
                                                            );
                                                        }}
                                                        onBlur={props.handleBlur}
                                                        value={props.values.ageStart}
                                                        inputProps={{ maxLength: 200 }}
                                                        variant="outlined"
                                                        error={
                                                            props.values.ageStart === "" &&
                                                                props.errors.ageStart &&
                                                                props.touched.ageStart
                                                                ? true
                                                                : false
                                                        }
                                                        className={
                                                            props.values.ageStart === "" &&
                                                                props.errors.ageStart &&
                                                                props.touched.ageStart
                                                                ? "text-input validation-message"
                                                                : "text-input"
                                                        }
                                                    />
                                                }
                                                {props.values.ageStart === "" && props.errors.ageStart &&
                                                    props.touched.ageStart && (
                                                        <Typography
                                                            className={
                                                                props.values.ageStart === "" &&
                                                                    props.errors.ageStart &&
                                                                    props.touched.ageStart
                                                                    ? "validation-message"
                                                                    : "validation-message"
                                                            }
                                                        >
                                                            {props.errors.ageStart}
                                                        </Typography>
                                                    )}
                                            </FormControl>
                                        </Grid>

                                        <Grid item xs={12} sm={12} md={12}>
                                            <FormControl fullWidth>
                                                {loading ?
                                                    <Skeleton animation="wave" variant="rounded" width='auto' height={45} /> :
                                                    <TextField
                                                        id="ageEnd"
                                                        label="Age end (In days)"
                                                        type="number"
                                                        fullWidth
                                                        size="small"
                                                        onChange={(e: any) => {
                                                            props.setFieldValue(
                                                                "ageEnd",
                                                                e.target.value !== null ? e.target.value : "",
                                                                true
                                                            );
                                                        }}
                                                        onBlur={props.handleBlur}
                                                        value={props.values.ageEnd}
                                                        inputProps={{ maxLength: 200 }}
                                                        variant="outlined"
                                                        error={
                                                            props.values.ageEnd === "" &&
                                                                props.errors.ageEnd &&
                                                                props.touched.ageEnd
                                                                ? true
                                                                : false
                                                        }
                                                        className={
                                                            props.values.ageEnd === "" &&
                                                                props.errors.ageEnd &&
                                                                props.touched.ageEnd
                                                                ? "text-input validation-message"
                                                                : "text-input"
                                                        }
                                                    />
                                                }
                                                {props.values.ageEnd === "" && props.errors.ageEnd &&
                                                    props.touched.ageEnd && (
                                                        <Typography
                                                            className={
                                                                props.values.ageEnd === "" &&
                                                                    props.errors.ageEnd &&
                                                                    props.touched.ageEnd
                                                                    ? "validation-message"
                                                                    : "validation-message"
                                                            }>
                                                            {props.errors.ageEnd}
                                                        </Typography>
                                                    )}
                                            </FormControl>
                                        </Grid>

                                        <Grid item xs={12} sm={12} md={12}>
                                            <FormControl fullWidth>
                                                {loading ?
                                                    <Skeleton animation="wave" variant="rounded" width='auto' height={45} /> :
                                                    <TextField
                                                        id="title"
                                                        label="Title"
                                                        fullWidth
                                                        size="small"
                                                        onChange={(e: any) => {
                                                            props.setFieldValue(
                                                                "title",
                                                                e.target.value !== null ? e.target.value : "",
                                                                true
                                                            );
                                                        }}
                                                        onBlur={props.handleBlur}
                                                        value={props.values.title}
                                                        inputProps={{ maxLength: 200 }}
                                                        variant="outlined"
                                                        error={
                                                            props.values.title === "" &&
                                                                props.errors.title &&
                                                                props.touched.title
                                                                ? true
                                                                : false
                                                        }
                                                        className={
                                                            props.values.title === "" &&
                                                                props.errors.title &&
                                                                props.touched.title
                                                                ? "text-input validation-message"
                                                                : "text-input"
                                                        }
                                                    />
                                                }
                                                {props.values.title === "" && props.errors.title &&
                                                    props.touched.title && (
                                                        <Typography
                                                            className={
                                                                props.values.title === "" &&
                                                                    props.errors.title &&
                                                                    props.touched.title
                                                                    ? "validation-message"
                                                                    : "validation-message"
                                                            }
                                                        >
                                                            {props.errors.title}
                                                        </Typography>
                                                    )}
                                            </FormControl>
                                        </Grid>

                                        <Grid item xs={12} sm={12} md={12}>
                                            <FormControl fullWidth className="notifi-body">
                                                {loading ?
                                                    <Skeleton animation="wave" variant="rounded" width='auto' height={45} /> :
                                                    <TextField
                                                        id="body"
                                                        label="Body"
                                                        fullWidth
                                                        size="small"
                                                        multiline
                                                        rows={4}
                                                        onChange={(e: any) => {
                                                            props.setFieldValue(
                                                                "body",
                                                                e.target.value !== null ? e.target.value : "",
                                                                true
                                                            );
                                                        }}
                                                        onBlur={() => setFocused(false)}
                                                        onFocus={() => setFocused(true)}
                                                        value={props.values.body}
                                                        variant="outlined"
                                                        error={
                                                            props.values.body === "" &&
                                                                props.errors.body &&
                                                                props.touched.body
                                                                ? true
                                                                : false
                                                        }
                                                        className={
                                                            props.values.body === "" &&
                                                                props.errors.body &&
                                                                props.touched.body
                                                                ? "text-input validation-message"
                                                                : "text-input"
                                                        }
                                                    />
                                                }
                                                {isFocused && <Typography className="length-counter">{props.values.body !== "" ? props.values.body.length + '/' + 3000 : ''}</Typography>}
                                                {props.values.body === "" && props.errors.body &&
                                                    props.touched.body && (
                                                        <Typography
                                                            className={
                                                                props.values.body === "" &&
                                                                    props.errors.body &&
                                                                    props.touched.body
                                                                    ? "validation-message"
                                                                    : "validation-message"
                                                            }>
                                                            {props.errors.body}
                                                        </Typography>
                                                    )}
                                            </FormControl>
                                        </Grid>

                                        <Grid item xs={12} sm={12} md={12} zIndex={9}>
                                            <FormControl fullWidth style={{ width: '100%' }}>
                                                {loading ?
                                                    <Skeleton animation="wave" variant="rounded" width='auto' height={45} /> :
                                                    <Select
                                                        id="urlPage"
                                                        classNamePrefix="select"
                                                        styles={
                                                            props.values.urlPage === "" &&
                                                                props.errors.urlPage &&
                                                                props.touched.urlPage
                                                                ? selectStyles
                                                                : undefined
                                                        }
                                                        isClearable={true}
                                                        isSearchable={true}
                                                        placeholder={"Url/Page"}
                                                        theme={(theme) => ({
                                                            ...theme,
                                                            colors: {
                                                                ...theme.colors,
                                                                primary: "#0B7CAA",
                                                                primary25: "#F1F6FE",
                                                                primary50: "#e1dfea",
                                                            },
                                                        })}
                                                        value={getSingleSelected(
                                                            props.values.urlPage.toString(),
                                                            pageListData
                                                        )}
                                                        onChange={(e, value) => {
                                                            props.setFieldValue(
                                                                "urlPage",
                                                                e !== null ? e.value : "",
                                                                false
                                                            );
                                                        }}
                                                        options={pageListData}
                                                        className={
                                                            props.values.urlPage === "" &&
                                                                props.errors.urlPage &&
                                                                props.touched.urlPage
                                                                ? "text-input"
                                                                : "text-input"
                                                        } />
                                                }
                                                {props.values.urlPage === "" && props.errors.urlPage &&
                                                    props.touched.urlPage && (
                                                        <Typography
                                                            className={
                                                                props.values.urlPage === "" &&
                                                                    props.errors.urlPage &&
                                                                    props.touched.urlPage
                                                                    ? "validation-message"
                                                                    : "validation-message"
                                                            }>
                                                            {props.errors.urlPage}
                                                        </Typography>
                                                    )}
                                            </FormControl>
                                        </Grid>

                                        <Box className="bottom-box">
                                            <CustomButton
                                                disabled={false}
                                                class="add-button"
                                                isLoading={submitLoading}
                                                title='Send'
                                            />
                                        </Box>
                                    </Grid>
                                </Box>
                            </Form>)
                    }}
                </Formik>
            </Box>
        </Fade>
    )
}

const selectStyles = {
    control: (provided: any, state: any) => ({
        ...provided,
        border: state.isFocused ? "1px solid #0B7CAA" : "1px solid #d32f2f",
    }),
    placeholder: (provided: any, state: any) => ({
        ...provided,
        color: state.isFocused ? state.selectProps.color : "#d32f2f",
    }),
};