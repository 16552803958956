import React, { useContext, useEffect, useState } from "react";
import "./MedicalHistory.scss";
import { Alert, AlertTitle, AppBar, Box, Card, Dialog, DialogContent, Fade, Grid, IconButton, Toolbar, Typography } from "@mui/material";
import backIcon from '../../assets/icons/back.svg';
import healthAssistantIcon from "../../assets/icons/health assistant.svg";
import { useNavigate } from "react-router-dom";
import { PreferencesContext } from "../../PreferenceContext";
import CloseIcon from '@mui/icons-material/Close';

export default function MedicalHistory() {
    const { userContext, updateUserContext } = useContext(PreferencesContext);
    const [submoduleLoading, setSubmoduleLoading] = useState(false);
    const [openEmergencyConditionDialog, setOpenEmergencyConditionDialog] = useState(false);
    const [firstSubmoduleName, setFirstSubmoduleName] = useState({
        moduleName: '',
        moduleCode: ''
    });
    const [secondSubmoduleName, setSecondSubmoduleName] = useState({
        moduleName: '',
        moduleCode: ''
    });
    const navigate = useNavigate();

    function backClick() {
        navigate("/dashboard");
    }

    async function fetchSubModules() {
        setSubmoduleLoading(true);
        if (userContext.moduleConfiguration !== undefined) {
            let data = userContext.moduleConfiguration
            let curFirstSubmodule: any = userContext.moduleConfiguration.find(
                (x: any) => x.moduleCode === "COMMON_SYMPTOMS"
            );
            let curSecondSubmodule: any = userContext.moduleConfiguration.find(
                (x: any) => x.moduleCode === "HEALTH_ASSISTANT"
            );
            setFirstSubmoduleName({
                moduleName: curFirstSubmodule.moduleName,
                moduleCode: curFirstSubmodule.moduleCode
            });
            setSecondSubmoduleName(
                {
                    moduleName: curSecondSubmodule.moduleName,
                    moduleCode: curSecondSubmodule.moduleCode
                });
        }
    }

    function goToCommonSymptoms(moduleCode: any, moduleName: any) {
        updateUserContext({
            ...userContext,
            symptomsCategoryCode: moduleCode,
            symptomsCategoryName: moduleName
        });

        navigate('/symptoms',
            { state: { submoduleCode: moduleCode, submoduleName: moduleName } }
        );
    }

    const handleCloseEmergencyConditionDialog = () => {
        setOpenEmergencyConditionDialog(false);
    };

    function goToHealthAssitant(moduleCode: any, moduleName: any) {
        updateUserContext({
            ...userContext,
            symptomsCategoryCode: moduleCode,
            symptomsCategoryName: moduleName
        });
        navigate('/symptoms',
            { state: { submoduleCode: moduleCode, submoduleName: moduleName } }
        );
    }

    function goToMemberHistory(moduleCode: any, moduleName: any) {
        updateUserContext({
            ...userContext,
            symptomsCategoryCode: moduleCode,
            symptomsCategoryName: moduleName
        });
        navigate('/symptoms',
            { state: { submoduleCode: moduleCode, submoduleName: moduleName } }
        );
    }

    useEffect(() => {
        if (userContext.memberDetail !== undefined && userContext.memberDetail.familyMemberId > 0) {
            fetchSubModules();
        }
    }, []);

    return (
        <Fade in={true} timeout={{ enter: 500 }}>
            <Box className="medical-history-main-box">

                <Box className="head-box">
                    <Box className="back-box">
                        <IconButton aria-label="back" className='back-icon' disableRipple
                            onClick={() => backClick()}>
                            <img src={backIcon} alt='logo' />
                        </IconButton>
                    </Box>
                    <Box className="title-box">
                        <Typography className="title-text">Medical history</Typography>
                    </Box>
                    <Box className="profile-box">
                        <></>
                    </Box>
                </Box>

                <Box className="body-box">
                    {/* <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} className='main-container-grid'> */}
                    <Grid container spacing={2} className='main-container-grid' sx={{
                        display: 'flex',
                        flexWrap: {
                            xs: 'wrap', // Wrap on extra small screens
                            sm: 'wrap', // Wrap on small screens
                            md: 'nowrap', // No wrap on medium screens
                            lg: 'nowrap', // No wrap on large screens
                            xl: 'nowrap'  // No wrap on extra large screens
                        }
                    }}>
                        {/* commmon symptoms */}
                        {/* <Grid item xs={6} sm={4} md={4} className='subcontainer-grid'>
                            <Card className='inner-card' onClick={() => goToCommonSymptoms(firstSubmoduleName.moduleCode, firstSubmoduleName.moduleName)}>
                                <Box className='card-upper-box' ><Typography className='upper-text'>{firstSubmoduleName.moduleName}</Typography></Box>
                                <Box className='middle-img'><img src={healthAssistantIcon} alt='logo' /></Box>
                            </Card>
                        </Grid> */}

                        <Grid className='sub-item-grid'
                            item xs={6} sm={3} md={12} lg={12} xl={12}>
                            <Card className='inner-card' onClick={() => goToHealthAssitant(secondSubmoduleName.moduleCode, secondSubmoduleName.moduleName)}>
                                <Box className='card-upper-box' >
                                    <Typography className='upper-text' >{secondSubmoduleName.moduleName}</Typography>
                                </Box>
                                <Box className='middle-img'><img src={healthAssistantIcon} alt='logo' /></Box>
                            </Card>
                        </Grid>
                        {/* history */}
                        {/* <Grid item xs={6} sm={4} md={4} className='subcontainer-grid'>
                            <Card className='inner-card' onClick={() => goToMemberHistory('HISTORY', 'History')}>
                                <Box className='card-upper-box' >
                                    <Typography className='upper-text' >History</Typography>
                                </Box>
                                <Box className='middle-img'><img src={healthAssistantIcon} alt='logo' /></Box>
                            </Card>
                        </Grid> */}
                    </Grid>

                    <Box sx={{ p: '10px', width: '100%' }} className="disclaimer-box">
                        <Alert severity="warning">
                            <AlertTitle>Disclaimer:</AlertTitle>
                            We are just giving you the rough outline regarding the health, growth and development of your child just to sensitize you to seek the medical care earlier if needed. Every child has to be examined individually by a registered medical practitioner before confirmation of status of health, growth and development of any child and A Doctor is the Final authority to decide regarding further management and intervention needed as felt necessary by the Doctor.
                            <Box className="source-link-ref-box-title" >Source: <span className="source-link-ref" onClick={() => setOpenEmergencyConditionDialog(true)}>https://iapindia.org/guidelines-for-parents/</span></Box>
                        </Alert>
                    </Box>

                </Box>

                <Dialog
                    fullScreen
                    open={openEmergencyConditionDialog}
                    onClose={handleCloseEmergencyConditionDialog}
                    className="custom-popup-header">
                    <React.Fragment >
                        <AppBar sx={{ position: 'relative' }} color='primary'>
                            <Toolbar className='custom-popup-header' >
                                <Box className='header-empty-box'></Box>
                                <Box className='close-box'>
                                    <IconButton
                                        edge="start"
                                        color="inherit"
                                        onClick={handleCloseEmergencyConditionDialog}
                                        aria-label="close"
                                        className='close-button'>
                                        <CloseIcon />
                                    </IconButton>
                                </Box>
                            </Toolbar>
                        </AppBar>
                        <DialogContent style={{ padding: 0 }}>
                            <iframe width="100%" height="100%" src="https://iapindia.org/guidelines-for-parents/" title="Pediaverse" ></iframe>
                        </DialogContent>
                    </React.Fragment>
                </Dialog>

            </Box>
        </Fade>
    );
}
