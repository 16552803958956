import React, { useContext, useEffect, useState } from "react";
import "./BMI.scss";
import styles from "../PhysicalGrowth/BottomSheetStyles.module.css";
import { useNavigate } from "react-router-dom";
import { Alert, AlertTitle, Box, Fade, FormControl, Grid, IconButton, InputAdornment, InputLabel, OutlinedInput, Skeleton, Snackbar, SnackbarOrigin, Typography } from "@mui/material";
import backIcon from "../../assets/icons/back.svg";
import bmiIcon from '../../assets/icons/bmi.svg';
import CustomTabs from "../../components/CustomTabs/CustomTabs";
import { PreferencesContext } from "../../PreferenceContext";
import { CreateBmiDetail, GetBMIDetailForChart, GetMemberBmiDetailList } from "../../services/PhysicalGrowthService";
import { useDrag } from "@use-gesture/react";
import { a, useSpring, config } from "@react-spring/web";
import CustomButton from "../../components/CustomButton/CustomButton";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { AlertType } from "../../shared/AlertType";
import { ageInDays, ageInMonths } from "../../shared/CommonFuctions";

const formInitialValues = {
  bmiDetailId: 0,
  bmi: "",
};

interface State extends SnackbarOrigin {
  open: boolean;
}

export default function BMI() {
  const { userContext, updateUserContext } = useContext(PreferencesContext);
  const [loading, setLoading] = useState(false);
  const [bmiChartData, setBmiChartData] = useState([]);
  const [bmiData, setBmiData] = useState(formInitialValues);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [isBottomSheetOpen, setIsBottomSheetOpen] = useState(false);
  const [memberBmiDetailData, setMemberBmiDetailData] = useState([]);
  const [daysInAge, setDaysInAge] = useState(0);
  const [ageCountData, setAgeCountData] = useState(-1);
  const [showAlert, setAlertMessage] = useState({
    isSuccess: false,
    message: "",
  });

  const navigate = useNavigate();

  function backClick() {
    navigate("/physical-growth");
  }
  const valildationSchema = Yup.object().shape({
    bmi: Yup.number().required("BMI is required."),
  });

  const height = 175;

  const [{ y }, api] = useSpring(() => ({ y: height }));

  const openBMISheet = (data: any) => {
    // setIsBottomSheetOpen(true);
    // api.start({
    //     y: 0,
    //     immediate: false,
    // });
  };

  const close = (velocity = 0) => {
    setIsBottomSheetOpen(false);
    api.start({
      y: height,
      immediate: false,
      config: { ...config.stiff, velocity },
    });
  };

  const bind = useDrag(
    ({
      last,
      velocity: [, vy],
      direction: [, dy],
      offset: [, oy],
      cancel,
      canceled,
    }) => {
      // if the user drags up passed a threshold, then we cancel
      // the drag so that the sheet resets to its open position
      if (oy < -70) cancel();

      // when the user releases the sheet, we check whether it passed
      // the threshold for it to close, or if we reset it to its open positino
      if (last) {
        oy > height * 0.5 || (vy > 0.5 && dy > 0)
          ? close(vy)
          : openBMISheet({ canceled });
      }
      // when the user keeps dragging, we just move the sheet according to
      // the cursor position
      else api.start({ y: oy, immediate: true });
    },
    {
      from: () => [0, y.get()],
      filterTaps: true,
      bounds: { top: 0 },
      rubberband: true,
    }
  );

  const display = y.to((py) => (py < height ? "block" : "none"));

  async function SubmitBMI(value: any) {
    setSubmitLoading(true);
    const days = ageInDays(userContext.memberDetail.birthDate);
    try {
      let bmiInitData = value;
      let SaveUpdateHeightData = {
        bmiDetailId: bmiInitData.bmiDetailId,
        familyId: userContext.loginDetail.familyId,
        familyMemberId: userContext.memberDetail.familyMemberId,
        userData: bmiInitData.bmi,
        age: days,
        dataManagedBy: userContext.loginDetail.userId,
      };
      const resultParentSave: any = await CreateBmiDetail(SaveUpdateHeightData);
      setSubmitLoading(false);
      setIsBottomSheetOpen(false);
      updateUserContext({
        ...userContext,
        isAlert: true,
        alertMessage: resultParentSave.message,
        alertType: AlertType.Success,
      });
      fetchBmiData();
    } catch (e: any) {
      updateUserContext({
        ...userContext,
        isAlert: true,
        alertMessage: e.message,
        alertType: AlertType.Error,
      });
    }
  }

  async function fetchData() {
    // let days = ageInDays(userContext.memberDetail.birthDate);
    // let months = 0;
    // if (days > 1856) {
    //   months = ageInMonths(userContext.memberDetail.birthDate);
    //   days = 0;
    // }
    let days = ageInDays(userContext.memberDetail.birthDate);
    let months = 0;
    let country = userContext.countryCode === '+91' ? 'INDIA' : 'WHO';
    if (country === 'INDIA') {
      if (days >= 730) {
        days = 0
        months = ageInMonths(userContext.memberDetail.birthDate);
      } else {
        country = 'WHO'
      }
    } else {
      if (days > 1856) {
        months = ageInMonths(userContext.memberDetail.birthDate);
        days = 0;
      }
    }
    setDaysInAge(days);
    try {
      let params = new URLSearchParams();
      params.append("days", days.toString());
      params.append("months", months.toString());
      params.append("gender", userContext.memberDetail.gender);
      params.append("country", country);
      params.append("familyMemberId", userContext.memberDetail.familyMemberId.toString());
      const result: any = await GetBMIDetailForChart(params);
      let chartdata = result.data.data;
      if (userContext.memberDetail.age === 0) {
        setAgeCountData(0);
      }
      setBmiChartData(chartdata);
    } catch (error) {
      setLoading(false);
      if (error) {
        setAlertMessage({
          isSuccess: true,
          message: error as string,
        });
      }
    }
    setLoading(false);
  }

  const userBmiValues: any = bmiChartData.length !== null &&
    bmiChartData.length > 0 &&
    bmiChartData.map((row: any, index: any) => row.bmi).filter((bmi) => bmi !== null);

  let minBmi: any;
  let maxBmi: any;
  if (userBmiValues.length > 0) {
    minBmi = Math.min(...userBmiValues) - (-1);
    maxBmi = Math.max(...userBmiValues) + 10;
  }

  const bmiOptions = {
    // color: [

    //   // 91 to 99 - red
    //   "#FF0000", "#FF0000", "#FF0000", "#FF0000", "#FF0000", "#FF0000", "#FF0000", "#FF0000", "#FF0000",

    //   // 76 to 90 - orange
    //   "#FF8000", "#FF8000", "#FF8000", "#FF8000", "#FF8000", "#FF8000", "#FF8000", "#FF8000", "#FF8000",
    //   "#FF8000", "#FF8000", "#FF8000", "#FF8000", "#FF8000", "#FF8000",

    //   // 51 to 75 - lemon
    //   "#FFED00", "#FFED00", "#FFED00", "#FFED00", "#FFED00", "#FFED00", "#FFED00", "#FFED00", "#FFED00",
    //   "#FFED00", "#FFED00", "#FFED00", "#FFED00", "#FFED00", "#FFED00", "#FFED00", "#FFED00", "#FFED00",
    //   "#FFED00", "#FFED00", "#FFED00", "#FFED00", "#FFED00", "#FFED00", "#FFED00",

    //   // 26 to 50 - green
    //   "#16FF00", "#16FF00", "#16FF00", "#16FF00", "#16FF00", "#16FF00", "#16FF00", "#16FF00", "#16FF00",
    //   "#16FF00", "#16FF00", "#16FF00", "#16FF00", "#16FF00", "#16FF00", "#16FF00", "#16FF00", "#16FF00",
    //   "#16FF00", "#16FF00", "#16FF00", "#16FF00", "#16FF00", "#16FF00", "#16FF00",

    //   // 11 to 25 - lemon
    //   "#FFED00", "#FFED00", "#FFED00", "#FFED00", "#FFED00", "#FFED00", "#FFED00", "#FFED00", "#FFED00",
    //   "#FFED00", "#FFED00", "#FFED00", "#FFED00", "#FFED00", "#FFED00",

    //   // 4 to 10 - orange
    //   "#FF8000", "#FF8000", "#FF8000", "#FF8000", "#FF8000", "#FF8000", "#FF8000",

    //   // 1 to 3 - red
    //   // "#FFB3B3", "#FFB3B3", "#FFB3B3",
    //   "#FF0000", "#FF0000", "#FF0000",

    //   "#000000",
    // ],
    // tooltip: {
    //   trigger: "axis",
    //   axisPointer: {
    //     type: "cross",
    //     label: {
    //       backgroundColor: "#6a7985",
    //     },
    //   },
    // },
    // // grid: {
    // //   left: "3%",
    // //   right: "2%",
    // //   bottom: "3%",
    // //   containLabel: true,
    // // },
    // xAxis: [
    //   {
    //     type: "category",
    //     boundaryGap: false,
    //     name: daysInAge === 0 ? "Months" : "Days",
    //     nameLocation: "middle",
    //     nameGap: 30,
    //     data:
    //       bmiChartData.length > 2 &&
    //       bmiChartData.map((row: any, index: any) =>
    //         row.days === 0 ? row.months : row.days
    //       ),
    //   },
    // ],
    // yAxis: [
    //   {
    //     type: "value",
    //     name: "BMI in kg/m^2",
    //     nameGap: 30,
    //     axisLine: {
    //       onZero: false,
    //     },
    //     nameTextStyle: {
    //       padding: [0, 0, 0, 20],
    //     },
    //     min: "dataMin",
    //   },
    // ],
    // series: [
    //   // p91 to p99
    //   {
    //     name: "P99",
    //     type: "line",
    //     silent: true,
    //     smooth: true,
    //     lineStyle: {
    //       width: 0,
    //     },
    //     showSymbol: false,
    //     areaStyle: {
    //       opacity: 0.8,
    //     },
    //     emphasis: {
    //       focus: "series",
    //     },
    //     data:
    //       bmiChartData.length > 2 &&
    //       bmiChartData.map((row: any, index: any) => row.p99),
    //   },
    //   {
    //     name: "P98",
    //     type: "line",
    //     silent: true,
    //     smooth: true,
    //     lineStyle: {
    //       width: 0,
    //     },
    //     showSymbol: false,
    //     areaStyle: {
    //       opacity: 0.8,
    //     },
    //     emphasis: {
    //       focus: "series",
    //     },
    //     data:
    //       bmiChartData.length > 2 &&
    //       bmiChartData.map((row: any, index: any) => row.p98),
    //   },
    //   {
    //     name: "P97",
    //     type: "line",
    //     silent: true,
    //     smooth: true,
    //     lineStyle: {
    //       width: 0,
    //     },
    //     showSymbol: false,
    //     areaStyle: {
    //       opacity: 0.8,
    //     },
    //     emphasis: {
    //       focus: "series",
    //     },
    //     data:
    //       bmiChartData.length > 2 &&
    //       bmiChartData.map((row: any, index: any) => row.p97),
    //   },
    //   {
    //     name: "P96",
    //     type: "line",
    //     silent: true,
    //     smooth: true,
    //     lineStyle: {
    //       width: 0,
    //     },
    //     showSymbol: false,
    //     areaStyle: {
    //       opacity: 0.8,
    //     },
    //     emphasis: {
    //       focus: "series",
    //     },
    //     data:
    //       bmiChartData.length > 2 &&
    //       bmiChartData.map((row: any, index: any) => row.p96),
    //   },
    //   {
    //     name: "P95",
    //     type: "line",
    //     silent: true,
    //     smooth: true,
    //     lineStyle: {
    //       width: 0,
    //     },
    //     showSymbol: false,
    //     areaStyle: {
    //       opacity: 0.8,
    //     },
    //     emphasis: {
    //       focus: "series",
    //     },
    //     data:
    //       bmiChartData.length > 2 &&
    //       bmiChartData.map((row: any, index: any) => row.p95),
    //   },
    //   {
    //     name: "P94",
    //     type: "line",
    //     silent: true,
    //     smooth: true,
    //     lineStyle: {
    //       width: 0,
    //     },
    //     showSymbol: false,
    //     areaStyle: {
    //       opacity: 0.8,
    //     },
    //     emphasis: {
    //       focus: "series",
    //     },
    //     data:
    //       bmiChartData.length > 2 &&
    //       bmiChartData.map((row: any, index: any) => row.p94),
    //   },
    //   {
    //     name: "P93",
    //     type: "line",
    //     silent: true,
    //     smooth: true,
    //     lineStyle: {
    //       width: 0,
    //     },
    //     showSymbol: false,
    //     areaStyle: {
    //       opacity: 0.8,
    //     },
    //     emphasis: {
    //       focus: "series",
    //     },
    //     data:
    //       bmiChartData.length > 2 &&
    //       bmiChartData.map((row: any, index: any) => row.p93),
    //   },
    //   {
    //     name: "P92",
    //     type: "line",
    //     silent: true,
    //     smooth: true,
    //     lineStyle: {
    //       width: 0,
    //     },
    //     showSymbol: false,
    //     areaStyle: {
    //       opacity: 0.8,
    //     },
    //     emphasis: {
    //       focus: "series",
    //     },
    //     data:
    //       bmiChartData.length > 2 &&
    //       bmiChartData.map((row: any, index: any) => row.p92),
    //   },
    //   {
    //     name: "P91",
    //     type: "line",
    //     silent: true,
    //     smooth: true,
    //     lineStyle: {
    //       width: 0,
    //     },
    //     showSymbol: false,
    //     areaStyle: {
    //       opacity: 0.8,
    //     },
    //     emphasis: {
    //       focus: "series",
    //     },
    //     data:
    //       bmiChartData.length > 2 &&
    //       bmiChartData.map((row: any, index: any) => row.p91),
    //   },
    //   // p76 to p90
    //   {
    //     name: "P90",
    //     type: "line",
    //     silent: true,
    //     smooth: true,
    //     lineStyle: {
    //       width: 0,
    //     },
    //     showSymbol: false,
    //     areaStyle: {
    //       opacity: 0.8,
    //     },
    //     emphasis: {
    //       focus: "series",
    //     },
    //     data:
    //       bmiChartData.length > 2 &&
    //       bmiChartData.map((row: any, index: any) => row.p90),
    //   },
    //   {
    //     name: "P89",
    //     type: "line",
    //     silent: true,
    //     smooth: true,
    //     lineStyle: {
    //       width: 0,
    //     },
    //     showSymbol: false,
    //     areaStyle: {
    //       opacity: 0.8,
    //     },
    //     emphasis: {
    //       focus: "series",
    //     },
    //     data:
    //       bmiChartData.length > 2 &&
    //       bmiChartData.map((row: any, index: any) => row.p89),
    //   },
    //   {
    //     name: "P88",
    //     type: "line",
    //     silent: true,
    //     smooth: true,
    //     lineStyle: {
    //       width: 0,
    //     },
    //     showSymbol: false,
    //     areaStyle: {
    //       opacity: 0.8,
    //     },
    //     emphasis: {
    //       focus: "series",
    //     },
    //     data:
    //       bmiChartData.length > 2 &&
    //       bmiChartData.map((row: any, index: any) => row.p88),
    //   },
    //   {
    //     name: "P87",
    //     type: "line",
    //     silent: true,
    //     smooth: true,
    //     lineStyle: {
    //       width: 0,
    //     },
    //     showSymbol: false,
    //     areaStyle: {
    //       opacity: 0.8,
    //     },
    //     emphasis: {
    //       focus: "series",
    //     },
    //     data:
    //       bmiChartData.length > 2 &&
    //       bmiChartData.map((row: any, index: any) => row.p87),
    //   },
    //   {
    //     name: "P86",
    //     type: "line",
    //     silent: true,
    //     smooth: true,
    //     lineStyle: {
    //       width: 0,
    //     },
    //     showSymbol: false,
    //     areaStyle: {
    //       opacity: 0.8,
    //     },
    //     emphasis: {
    //       focus: "series",
    //     },
    //     data:
    //       bmiChartData.length > 2 &&
    //       bmiChartData.map((row: any, index: any) => row.p86),
    //   },
    //   {
    //     name: "P85",
    //     type: "line",
    //     silent: true,
    //     smooth: true,
    //     lineStyle: {
    //       width: 0,
    //     },
    //     showSymbol: false,
    //     areaStyle: {
    //       opacity: 0.8,
    //     },
    //     emphasis: {
    //       focus: "series",
    //     },
    //     data:
    //       bmiChartData.length > 2 &&
    //       bmiChartData.map((row: any, index: any) => row.p85),
    //   },
    //   {
    //     name: "P84",
    //     type: "line",
    //     silent: true,
    //     smooth: true,
    //     lineStyle: {
    //       width: 0,
    //     },
    //     showSymbol: false,
    //     areaStyle: {
    //       opacity: 0.8,
    //     },
    //     emphasis: {
    //       focus: "series",
    //     },
    //     data:
    //       bmiChartData.length > 2 &&
    //       bmiChartData.map((row: any, index: any) => row.p84),
    //   },
    //   {
    //     name: "P83",
    //     type: "line",
    //     silent: true,
    //     smooth: true,
    //     lineStyle: {
    //       width: 0,
    //     },
    //     showSymbol: false,
    //     areaStyle: {
    //       opacity: 0.8,
    //     },
    //     emphasis: {
    //       focus: "series",
    //     },
    //     data:
    //       bmiChartData.length > 2 &&
    //       bmiChartData.map((row: any, index: any) => row.p83),
    //   },
    //   {
    //     name: "P82",
    //     type: "line",
    //     silent: true,
    //     smooth: true,
    //     lineStyle: {
    //       width: 0,
    //     },
    //     showSymbol: false,
    //     areaStyle: {
    //       opacity: 0.8,
    //     },
    //     emphasis: {
    //       focus: "series",
    //     },
    //     data:
    //       bmiChartData.length > 2 &&
    //       bmiChartData.map((row: any, index: any) => row.p82),
    //   },
    //   {
    //     name: "P81",
    //     type: "line",
    //     silent: true,
    //     smooth: true,
    //     lineStyle: {
    //       width: 0,
    //     },
    //     showSymbol: false,
    //     areaStyle: {
    //       opacity: 0.8,
    //     },
    //     emphasis: {
    //       focus: "series",
    //     },
    //     data:
    //       bmiChartData.length > 2 &&
    //       bmiChartData.map((row: any, index: any) => row.p81),
    //   },
    //   {
    //     name: "P80",
    //     type: "line",
    //     silent: true,
    //     smooth: true,
    //     lineStyle: {
    //       width: 0,
    //     },
    //     showSymbol: false,
    //     areaStyle: {
    //       opacity: 0.8,
    //     },
    //     emphasis: {
    //       focus: "series",
    //     },
    //     data:
    //       bmiChartData.length > 2 &&
    //       bmiChartData.map((row: any, index: any) => row.p80),
    //   },
    //   {
    //     name: "P79",
    //     type: "line",
    //     silent: true,
    //     smooth: true,
    //     lineStyle: {
    //       width: 0,
    //     },
    //     showSymbol: false,
    //     areaStyle: {
    //       opacity: 0.8,
    //     },
    //     emphasis: {
    //       focus: "series",
    //     },
    //     data:
    //       bmiChartData.length > 2 &&
    //       bmiChartData.map((row: any, index: any) => row.p79),
    //   },
    //   {
    //     name: "P78",
    //     type: "line",
    //     silent: true,
    //     smooth: true,
    //     lineStyle: {
    //       width: 0,
    //     },
    //     showSymbol: false,
    //     areaStyle: {
    //       opacity: 0.8,
    //     },
    //     emphasis: {
    //       focus: "series",
    //     },
    //     data:
    //       bmiChartData.length > 2 &&
    //       bmiChartData.map((row: any, index: any) => row.p78),
    //   },
    //   {
    //     name: "P77",
    //     type: "line",
    //     silent: true,
    //     smooth: true,
    //     lineStyle: {
    //       width: 0,
    //     },
    //     showSymbol: false,
    //     areaStyle: {
    //       opacity: 0.8,
    //     },
    //     emphasis: {
    //       focus: "series",
    //     },
    //     data:
    //       bmiChartData.length > 2 &&
    //       bmiChartData.map((row: any, index: any) => row.p77),
    //   },
    //   {
    //     name: "P76",
    //     type: "line",
    //     silent: true,
    //     smooth: true,
    //     lineStyle: {
    //       width: 0,
    //     },
    //     showSymbol: false,
    //     areaStyle: {
    //       opacity: 0.8,
    //     },
    //     emphasis: {
    //       focus: "series",
    //     },
    //     data:
    //       bmiChartData.length > 2 &&
    //       bmiChartData.map((row: any, index: any) => row.p76),
    //   },

    //   // p51 to p75
    //   {
    //     name: "P75",
    //     type: "line",
    //     silent: true,
    //     smooth: true,
    //     lineStyle: {
    //       width: 0,
    //     },
    //     showSymbol: false,
    //     areaStyle: {
    //       opacity: 0.8,
    //     },
    //     emphasis: {
    //       focus: "series",
    //     },
    //     data:
    //       bmiChartData.length > 2 &&
    //       bmiChartData.map((row: any, index: any) => row.p75),
    //   },
    //   {
    //     name: "P74",
    //     type: "line",
    //     silent: true,
    //     smooth: true,
    //     lineStyle: {
    //       width: 0,
    //     },
    //     showSymbol: false,
    //     areaStyle: {
    //       opacity: 0.8,
    //     },
    //     emphasis: {
    //       focus: "series",
    //     },
    //     data:
    //       bmiChartData.length > 2 &&
    //       bmiChartData.map((row: any, index: any) => row.p74),
    //   },
    //   {
    //     name: "P73",
    //     type: "line",
    //     silent: true,
    //     smooth: true,
    //     lineStyle: {
    //       width: 0,
    //     },
    //     showSymbol: false,
    //     areaStyle: {
    //       opacity: 0.8,
    //     },
    //     emphasis: {
    //       focus: "series",
    //     },
    //     data:
    //       bmiChartData.length > 2 &&
    //       bmiChartData.map((row: any, index: any) => row.p73),
    //   },
    //   {
    //     name: "P72",
    //     type: "line",
    //     silent: true,
    //     smooth: true,
    //     lineStyle: {
    //       width: 0,
    //     },
    //     showSymbol: false,
    //     areaStyle: {
    //       opacity: 0.8,
    //     },
    //     emphasis: {
    //       focus: "series",
    //     },
    //     data:
    //       bmiChartData.length > 2 &&
    //       bmiChartData.map((row: any, index: any) => row.p72),
    //   },
    //   {
    //     name: "P71",
    //     type: "line",
    //     silent: true,
    //     smooth: true,
    //     lineStyle: {
    //       width: 0,
    //     },
    //     showSymbol: false,
    //     areaStyle: {
    //       opacity: 0.8,
    //     },
    //     emphasis: {
    //       focus: "series",
    //     },
    //     data:
    //       bmiChartData.length > 2 &&
    //       bmiChartData.map((row: any, index: any) => row.p71),
    //   },
    //   {
    //     name: "P70",
    //     type: "line",
    //     silent: true,
    //     smooth: true,
    //     lineStyle: {
    //       width: 0,
    //     },
    //     showSymbol: false,
    //     areaStyle: {
    //       opacity: 0.8,
    //     },
    //     emphasis: {
    //       focus: "series",
    //     },
    //     data:
    //       bmiChartData.length > 2 &&
    //       bmiChartData.map((row: any, index: any) => row.p70),
    //   },
    //   {
    //     name: "P69",
    //     type: "line",
    //     silent: true,
    //     smooth: true,
    //     lineStyle: {
    //       width: 0,
    //     },
    //     showSymbol: false,
    //     areaStyle: {
    //       opacity: 0.8,
    //     },
    //     emphasis: {
    //       focus: "series",
    //     },
    //     data:
    //       bmiChartData.length > 2 &&
    //       bmiChartData.map((row: any, index: any) => row.p69),
    //   },
    //   {
    //     name: "P68",
    //     type: "line",
    //     silent: true,
    //     smooth: true,
    //     lineStyle: {
    //       width: 0,
    //     },
    //     showSymbol: false,
    //     areaStyle: {
    //       opacity: 0.8,
    //     },
    //     emphasis: {
    //       focus: "series",
    //     },
    //     data:
    //       bmiChartData.length > 2 &&
    //       bmiChartData.map((row: any, index: any) => row.p68),
    //   },
    //   {
    //     name: "P67",
    //     type: "line",
    //     silent: true,
    //     smooth: true,
    //     lineStyle: {
    //       width: 0,
    //     },
    //     showSymbol: false,
    //     areaStyle: {
    //       opacity: 0.8,
    //     },
    //     emphasis: {
    //       focus: "series",
    //     },
    //     data:
    //       bmiChartData.length > 2 &&
    //       bmiChartData.map((row: any, index: any) => row.p67),
    //   },
    //   {
    //     name: "P66",
    //     type: "line",
    //     silent: true,
    //     smooth: true,
    //     lineStyle: {
    //       width: 0,
    //     },
    //     showSymbol: false,
    //     areaStyle: {
    //       opacity: 0.8,
    //     },
    //     emphasis: {
    //       focus: "series",
    //     },
    //     data:
    //       bmiChartData.length > 2 &&
    //       bmiChartData.map((row: any, index: any) => row.p66),
    //   },
    //   {
    //     name: "P65",
    //     type: "line",
    //     silent: true,
    //     smooth: true,
    //     lineStyle: {
    //       width: 0,
    //     },
    //     showSymbol: false,
    //     areaStyle: {
    //       opacity: 0.8,
    //     },
    //     emphasis: {
    //       focus: "series",
    //     },
    //     data:
    //       bmiChartData.length > 2 &&
    //       bmiChartData.map((row: any, index: any) => row.p65),
    //   },
    //   {
    //     name: "P64",
    //     type: "line",
    //     silent: true,
    //     smooth: true,
    //     lineStyle: {
    //       width: 0,
    //     },
    //     showSymbol: false,
    //     areaStyle: {
    //       opacity: 0.8,
    //     },
    //     emphasis: {
    //       focus: "series",
    //     },
    //     data:
    //       bmiChartData.length > 2 &&
    //       bmiChartData.map((row: any, index: any) => row.p64),
    //   },
    //   {
    //     name: "P63",
    //     type: "line",
    //     silent: true,
    //     smooth: true,
    //     lineStyle: {
    //       width: 0,
    //     },
    //     showSymbol: false,
    //     areaStyle: {
    //       opacity: 0.8,
    //     },
    //     emphasis: {
    //       focus: "series",
    //     },
    //     data:
    //       bmiChartData.length > 2 &&
    //       bmiChartData.map((row: any, index: any) => row.p63),
    //   },
    //   {
    //     name: "P62",
    //     type: "line",
    //     silent: true,
    //     smooth: true,
    //     lineStyle: {
    //       width: 0,
    //     },
    //     showSymbol: false,
    //     areaStyle: {
    //       opacity: 0.8,
    //     },
    //     emphasis: {
    //       focus: "series",
    //     },
    //     data:
    //       bmiChartData.length > 2 &&
    //       bmiChartData.map((row: any, index: any) => row.p62),
    //   },
    //   {
    //     name: "P61",
    //     type: "line",
    //     silent: true,
    //     smooth: true,
    //     lineStyle: {
    //       width: 0,
    //     },
    //     showSymbol: false,
    //     areaStyle: {
    //       opacity: 0.8,
    //     },
    //     emphasis: {
    //       focus: "series",
    //     },
    //     data:
    //       bmiChartData.length > 2 &&
    //       bmiChartData.map((row: any, index: any) => row.p61),
    //   },
    //   {
    //     name: "P60",
    //     type: "line",
    //     silent: true,
    //     smooth: true,
    //     lineStyle: {
    //       width: 0,
    //     },
    //     showSymbol: false,
    //     areaStyle: {
    //       opacity: 0.8,
    //     },
    //     emphasis: {
    //       focus: "series",
    //     },
    //     data:
    //       bmiChartData.length > 2 &&
    //       bmiChartData.map((row: any, index: any) => row.p60),
    //   },
    //   {
    //     name: "P59",
    //     type: "line",
    //     silent: true,
    //     smooth: true,
    //     lineStyle: {
    //       width: 0,
    //     },
    //     showSymbol: false,
    //     areaStyle: {
    //       opacity: 0.8,
    //     },
    //     emphasis: {
    //       focus: "series",
    //     },
    //     data:
    //       bmiChartData.length > 2 &&
    //       bmiChartData.map((row: any, index: any) => row.p59),
    //   },
    //   {
    //     name: "P58",
    //     type: "line",
    //     silent: true,
    //     smooth: true,
    //     lineStyle: {
    //       width: 0,
    //     },
    //     showSymbol: false,
    //     areaStyle: {
    //       opacity: 0.8,
    //     },
    //     emphasis: {
    //       focus: "series",
    //     },
    //     data:
    //       bmiChartData.length > 2 &&
    //       bmiChartData.map((row: any, index: any) => row.p58),
    //   },
    //   {
    //     name: "P57",
    //     type: "line",
    //     silent: true,
    //     smooth: true,
    //     lineStyle: {
    //       width: 0,
    //     },
    //     showSymbol: false,
    //     areaStyle: {
    //       opacity: 0.8,
    //     },
    //     emphasis: {
    //       focus: "series",
    //     },
    //     data:
    //       bmiChartData.length > 2 &&
    //       bmiChartData.map((row: any, index: any) => row.p57),
    //   },
    //   {
    //     name: "P56",
    //     type: "line",
    //     silent: true,
    //     smooth: true,
    //     lineStyle: {
    //       width: 0,
    //     },
    //     showSymbol: false,
    //     areaStyle: {
    //       opacity: 0.8,
    //     },
    //     emphasis: {
    //       focus: "series",
    //     },
    //     data:
    //       bmiChartData.length > 2 &&
    //       bmiChartData.map((row: any, index: any) => row.p56),
    //   },
    //   {
    //     name: "P55",
    //     type: "line",
    //     silent: true,
    //     smooth: true,
    //     lineStyle: {
    //       width: 0,
    //     },
    //     showSymbol: false,
    //     areaStyle: {
    //       opacity: 0.8,
    //     },
    //     emphasis: {
    //       focus: "series",
    //     },
    //     data:
    //       bmiChartData.length > 2 &&
    //       bmiChartData.map((row: any, index: any) => row.p55),
    //   },
    //   {
    //     name: "P54",
    //     type: "line",
    //     silent: true,
    //     smooth: true,
    //     lineStyle: {
    //       width: 0,
    //     },
    //     showSymbol: false,
    //     areaStyle: {
    //       opacity: 0.8,
    //     },
    //     emphasis: {
    //       focus: "series",
    //     },
    //     data:
    //       bmiChartData.length > 2 &&
    //       bmiChartData.map((row: any, index: any) => row.p54),
    //   },
    //   {
    //     name: "P53",
    //     type: "line",
    //     silent: true,
    //     smooth: true,
    //     lineStyle: {
    //       width: 0,
    //     },
    //     showSymbol: false,
    //     areaStyle: {
    //       opacity: 0.8,
    //     },
    //     emphasis: {
    //       focus: "series",
    //     },
    //     data:
    //       bmiChartData.length > 2 &&
    //       bmiChartData.map((row: any, index: any) => row.p53),
    //   },
    //   {
    //     name: "P52",
    //     type: "line",
    //     silent: true,
    //     smooth: true,
    //     lineStyle: {
    //       width: 0,
    //     },
    //     showSymbol: false,
    //     areaStyle: {
    //       opacity: 0.8,
    //     },
    //     emphasis: {
    //       focus: "series",
    //     },
    //     data:
    //       bmiChartData.length > 2 &&
    //       bmiChartData.map((row: any, index: any) => row.p52),
    //   },
    //   {
    //     name: "P51",
    //     type: "line",
    //     silent: true,
    //     smooth: true,
    //     lineStyle: {
    //       width: 0,
    //     },
    //     showSymbol: false,
    //     areaStyle: {
    //       opacity: 0.8,
    //     },
    //     emphasis: {
    //       focus: "series",
    //     },
    //     data:
    //       bmiChartData.length > 2 &&
    //       bmiChartData.map((row: any, index: any) => row.p51),
    //   },

    //   // p26 to p50
    //   {
    //     name: "P50",
    //     type: "line",
    //     silent: true,
    //     smooth: true,
    //     lineStyle: {
    //       width: 0,
    //     },
    //     showSymbol: false,
    //     areaStyle: {
    //       opacity: 0.8,
    //     },
    //     emphasis: {
    //       focus: "series",
    //     },
    //     data:
    //       bmiChartData.length > 2 &&
    //       bmiChartData.map((row: any, index: any) => row.p50),
    //   },
    //   {
    //     name: "P49",
    //     type: "line",
    //     silent: true,
    //     smooth: true,
    //     lineStyle: {
    //       width: 0,
    //     },
    //     showSymbol: false,
    //     areaStyle: {
    //       opacity: 0.8,
    //     },
    //     emphasis: {
    //       focus: "series",
    //     },
    //     data:
    //       bmiChartData.length > 2 &&
    //       bmiChartData.map((row: any, index: any) => row.p49),
    //   },
    //   {
    //     name: "P48",
    //     type: "line",
    //     silent: true,
    //     smooth: true,
    //     lineStyle: {
    //       width: 0,
    //     },
    //     showSymbol: false,
    //     areaStyle: {
    //       opacity: 0.8,
    //     },
    //     emphasis: {
    //       focus: "series",
    //     },
    //     data:
    //       bmiChartData.length > 2 &&
    //       bmiChartData.map((row: any, index: any) => row.p48),
    //   },
    //   {
    //     name: "P47",
    //     type: "line",
    //     silent: true,
    //     smooth: true,
    //     lineStyle: {
    //       width: 0,
    //     },
    //     showSymbol: false,
    //     areaStyle: {
    //       opacity: 0.8,
    //     },
    //     emphasis: {
    //       focus: "series",
    //     },
    //     data:
    //       bmiChartData.length > 2 &&
    //       bmiChartData.map((row: any, index: any) => row.p47),
    //   },
    //   {
    //     name: "P46",
    //     type: "line",
    //     silent: true,
    //     smooth: true,
    //     lineStyle: {
    //       width: 0,
    //     },
    //     showSymbol: false,
    //     areaStyle: {
    //       opacity: 0.8,
    //     },
    //     emphasis: {
    //       focus: "series",
    //     },
    //     data:
    //       bmiChartData.length > 2 &&
    //       bmiChartData.map((row: any, index: any) => row.p46),
    //   },
    //   {
    //     name: "P45",
    //     type: "line",
    //     silent: true,
    //     smooth: true,
    //     lineStyle: {
    //       width: 0,
    //     },
    //     showSymbol: false,
    //     areaStyle: {
    //       opacity: 0.8,
    //     },
    //     emphasis: {
    //       focus: "series",
    //     },
    //     data:
    //       bmiChartData.length > 2 &&
    //       bmiChartData.map((row: any, index: any) => row.p45),
    //   },
    //   {
    //     name: "P44",
    //     type: "line",
    //     silent: true,
    //     smooth: true,
    //     lineStyle: {
    //       width: 0,
    //     },
    //     showSymbol: false,
    //     areaStyle: {
    //       opacity: 0.8,
    //     },
    //     emphasis: {
    //       focus: "series",
    //     },
    //     data:
    //       bmiChartData.length > 2 &&
    //       bmiChartData.map((row: any, index: any) => row.p44),
    //   },
    //   {
    //     name: "P43",
    //     type: "line",
    //     silent: true,
    //     smooth: true,
    //     lineStyle: {
    //       width: 0,
    //     },
    //     showSymbol: false,
    //     areaStyle: {
    //       opacity: 0.8,
    //     },
    //     emphasis: {
    //       focus: "series",
    //     },
    //     data:
    //       bmiChartData.length > 2 &&
    //       bmiChartData.map((row: any, index: any) => row.p43),
    //   },
    //   {
    //     name: "P42",
    //     type: "line",
    //     silent: true,
    //     smooth: true,
    //     lineStyle: {
    //       width: 0,
    //     },
    //     showSymbol: false,
    //     areaStyle: {
    //       opacity: 0.8,
    //     },
    //     emphasis: {
    //       focus: "series",
    //     },
    //     data:
    //       bmiChartData.length > 2 &&
    //       bmiChartData.map((row: any, index: any) => row.p42),
    //   },
    //   {
    //     name: "P41",
    //     type: "line",
    //     silent: true,
    //     smooth: true,
    //     lineStyle: {
    //       width: 0,
    //     },
    //     showSymbol: false,
    //     areaStyle: {
    //       opacity: 0.8,
    //     },
    //     emphasis: {
    //       focus: "series",
    //     },
    //     data:
    //       bmiChartData.length > 2 &&
    //       bmiChartData.map((row: any, index: any) => row.p41),
    //   },
    //   {
    //     name: "P40",
    //     type: "line",
    //     silent: true,
    //     smooth: true,
    //     lineStyle: {
    //       width: 0,
    //     },
    //     showSymbol: false,
    //     areaStyle: {
    //       opacity: 0.8,
    //     },
    //     emphasis: {
    //       focus: "series",
    //     },
    //     data:
    //       bmiChartData.length > 2 &&
    //       bmiChartData.map((row: any, index: any) => row.p40),
    //   },
    //   {
    //     name: "P39",
    //     type: "line",
    //     silent: true,
    //     smooth: true,
    //     lineStyle: {
    //       width: 0,
    //     },
    //     showSymbol: false,
    //     areaStyle: {
    //       opacity: 0.8,
    //     },
    //     emphasis: {
    //       focus: "series",
    //     },
    //     data:
    //       bmiChartData.length > 2 &&
    //       bmiChartData.map((row: any, index: any) => row.p39),
    //   },
    //   {
    //     name: "P38",
    //     type: "line",
    //     silent: true,
    //     smooth: true,
    //     lineStyle: {
    //       width: 0,
    //     },
    //     showSymbol: false,
    //     areaStyle: {
    //       opacity: 0.8,
    //     },
    //     emphasis: {
    //       focus: "series",
    //     },
    //     data:
    //       bmiChartData.length > 2 &&
    //       bmiChartData.map((row: any, index: any) => row.p38),
    //   },
    //   {
    //     name: "P37",
    //     type: "line",
    //     silent: true,
    //     smooth: true,
    //     lineStyle: {
    //       width: 0,
    //     },
    //     showSymbol: false,
    //     areaStyle: {
    //       opacity: 0.8,
    //     },
    //     emphasis: {
    //       focus: "series",
    //     },
    //     data:
    //       bmiChartData.length > 2 &&
    //       bmiChartData.map((row: any, index: any) => row.p37),
    //   },
    //   {
    //     name: "P36",
    //     type: "line",
    //     silent: true,
    //     smooth: true,
    //     lineStyle: {
    //       width: 0,
    //     },
    //     showSymbol: false,
    //     areaStyle: {
    //       opacity: 0.8,
    //     },
    //     emphasis: {
    //       focus: "series",
    //     },
    //     data:
    //       bmiChartData.length > 2 &&
    //       bmiChartData.map((row: any, index: any) => row.p36),
    //   },
    //   {
    //     name: "P35",
    //     type: "line",
    //     silent: true,
    //     smooth: true,
    //     lineStyle: {
    //       width: 0,
    //     },
    //     showSymbol: false,
    //     areaStyle: {
    //       opacity: 0.8,
    //     },
    //     emphasis: {
    //       focus: "series",
    //     },
    //     data:
    //       bmiChartData.length > 2 &&
    //       bmiChartData.map((row: any, index: any) => row.p35),
    //   },
    //   {
    //     name: "P34",
    //     type: "line",
    //     silent: true,
    //     smooth: true,
    //     lineStyle: {
    //       width: 0,
    //     },
    //     showSymbol: false,
    //     areaStyle: {
    //       opacity: 0.8,
    //     },
    //     emphasis: {
    //       focus: "series",
    //     },
    //     data:
    //       bmiChartData.length > 2 &&
    //       bmiChartData.map((row: any, index: any) => row.p34),
    //   },
    //   {
    //     name: "P33",
    //     type: "line",
    //     silent: true,
    //     smooth: true,
    //     lineStyle: {
    //       width: 0,
    //     },
    //     showSymbol: false,
    //     areaStyle: {
    //       opacity: 0.8,
    //     },
    //     emphasis: {
    //       focus: "series",
    //     },
    //     data:
    //       bmiChartData.length > 2 &&
    //       bmiChartData.map((row: any, index: any) => row.p33),
    //   },
    //   {
    //     name: "P32",
    //     type: "line",
    //     silent: true,
    //     smooth: true,
    //     lineStyle: {
    //       width: 0,
    //     },
    //     showSymbol: false,
    //     areaStyle: {
    //       opacity: 0.8,
    //     },
    //     emphasis: {
    //       focus: "series",
    //     },
    //     data:
    //       bmiChartData.length > 2 &&
    //       bmiChartData.map((row: any, index: any) => row.p32),
    //   },
    //   {
    //     name: "P31",
    //     type: "line",
    //     silent: true,
    //     smooth: true,
    //     lineStyle: {
    //       width: 0,
    //     },
    //     showSymbol: false,
    //     areaStyle: {
    //       opacity: 0.8,
    //     },
    //     emphasis: {
    //       focus: "series",
    //     },
    //     data:
    //       bmiChartData.length > 2 &&
    //       bmiChartData.map((row: any, index: any) => row.p31),
    //   },
    //   {
    //     name: "P30",
    //     type: "line",
    //     silent: true,
    //     smooth: true,
    //     lineStyle: {
    //       width: 0,
    //     },
    //     showSymbol: false,
    //     areaStyle: {
    //       opacity: 0.8,
    //     },
    //     emphasis: {
    //       focus: "series",
    //     },
    //     data:
    //       bmiChartData.length > 2 &&
    //       bmiChartData.map((row: any, index: any) => row.p30),
    //   },
    //   {
    //     name: "P29",
    //     type: "line",
    //     silent: true,
    //     smooth: true,
    //     lineStyle: {
    //       width: 0,
    //     },
    //     showSymbol: false,
    //     areaStyle: {
    //       opacity: 0.8,
    //     },
    //     emphasis: {
    //       focus: "series",
    //     },
    //     data:
    //       bmiChartData.length > 2 &&
    //       bmiChartData.map((row: any, index: any) => row.p29),
    //   },
    //   {
    //     name: "P28",
    //     type: "line",
    //     silent: true,
    //     smooth: true,
    //     lineStyle: {
    //       width: 0,
    //     },
    //     showSymbol: false,
    //     areaStyle: {
    //       opacity: 0.8,
    //     },
    //     emphasis: {
    //       focus: "series",
    //     },
    //     data:
    //       bmiChartData.length > 2 &&
    //       bmiChartData.map((row: any, index: any) => row.p28),
    //   },
    //   {
    //     name: "P27",
    //     type: "line",
    //     silent: true,
    //     smooth: true,
    //     lineStyle: {
    //       width: 0,
    //     },
    //     showSymbol: false,
    //     areaStyle: {
    //       opacity: 0.8,
    //     },
    //     emphasis: {
    //       focus: "series",
    //     },
    //     data:
    //       bmiChartData.length > 2 &&
    //       bmiChartData.map((row: any, index: any) => row.p27),
    //   },
    //   {
    //     name: "P26",
    //     type: "line",
    //     silent: true,
    //     smooth: true,
    //     lineStyle: {
    //       width: 0,
    //     },
    //     showSymbol: false,
    //     areaStyle: {
    //       opacity: 0.8,
    //     },
    //     emphasis: {
    //       focus: "series",
    //     },
    //     data:
    //       bmiChartData.length > 2 &&
    //       bmiChartData.map((row: any, index: any) => row.p26),
    //   },

    //   // p11 to p25
    //   {
    //     name: "P25",
    //     type: "line",
    //     silent: true,
    //     smooth: true,
    //     lineStyle: {
    //       width: 0,
    //     },
    //     showSymbol: false,
    //     label: {
    //       show: true,
    //       position: "top",
    //     },
    //     areaStyle: {
    //       opacity: 0.8,
    //     },
    //     emphasis: {
    //       focus: "series",
    //     },
    //     data:
    //       bmiChartData.length > 2 &&
    //       bmiChartData.map((row: any, index: any) => row.p25),
    //   },
    //   {
    //     name: "P24",
    //     type: "line",
    //     silent: true,
    //     smooth: true,
    //     lineStyle: {
    //       width: 0,
    //     },
    //     showSymbol: false,
    //     label: {
    //       show: true,
    //       position: "top",
    //     },
    //     areaStyle: {
    //       opacity: 0.8,
    //     },
    //     emphasis: {
    //       focus: "series",
    //     },
    //     data:
    //       bmiChartData.length > 2 &&
    //       bmiChartData.map((row: any, index: any) => row.p24),
    //   },
    //   {
    //     name: "P23",
    //     type: "line",
    //     silent: true,
    //     smooth: true,
    //     lineStyle: {
    //       width: 0,
    //     },
    //     showSymbol: false,
    //     label: {
    //       show: true,
    //       position: "top",
    //     },
    //     areaStyle: {
    //       opacity: 0.8,
    //     },
    //     emphasis: {
    //       focus: "series",
    //     },
    //     data:
    //       bmiChartData.length > 2 &&
    //       bmiChartData.map((row: any, index: any) => row.p23),
    //   },
    //   {
    //     name: "P22",
    //     type: "line",
    //     silent: true,
    //     smooth: true,
    //     lineStyle: {
    //       width: 0,
    //     },
    //     showSymbol: false,
    //     label: {
    //       show: true,
    //       position: "top",
    //     },
    //     areaStyle: {
    //       opacity: 0.8,
    //     },
    //     emphasis: {
    //       focus: "series",
    //     },
    //     data:
    //       bmiChartData.length > 2 &&
    //       bmiChartData.map((row: any, index: any) => row.p22),
    //   },
    //   {
    //     name: "P21",
    //     type: "line",
    //     silent: true,
    //     smooth: true,
    //     lineStyle: {
    //       width: 0,
    //     },
    //     showSymbol: false,
    //     label: {
    //       show: true,
    //       position: "top",
    //     },
    //     areaStyle: {
    //       opacity: 0.8,
    //     },
    //     emphasis: {
    //       focus: "series",
    //     },
    //     data:
    //       bmiChartData.length > 2 &&
    //       bmiChartData.map((row: any, index: any) => row.p21),
    //   },
    //   {
    //     name: "P20",
    //     type: "line",
    //     silent: true,
    //     smooth: true,
    //     lineStyle: {
    //       width: 0,
    //     },
    //     showSymbol: false,
    //     label: {
    //       show: true,
    //       position: "top",
    //     },
    //     areaStyle: {
    //       opacity: 0.8,
    //     },
    //     emphasis: {
    //       focus: "series",
    //     },
    //     data:
    //       bmiChartData.length > 2 &&
    //       bmiChartData.map((row: any, index: any) => row.p20),
    //   },
    //   {
    //     name: "P19",
    //     type: "line",
    //     silent: true,
    //     smooth: true,
    //     lineStyle: {
    //       width: 0,
    //     },
    //     showSymbol: false,
    //     label: {
    //       show: true,
    //       position: "top",
    //     },
    //     areaStyle: {
    //       opacity: 0.8,
    //     },
    //     emphasis: {
    //       focus: "series",
    //     },
    //     data:
    //       bmiChartData.length > 2 &&
    //       bmiChartData.map((row: any, index: any) => row.p19),
    //   },
    //   {
    //     name: "P18",
    //     type: "line",
    //     silent: true,
    //     smooth: true,
    //     lineStyle: {
    //       width: 0,
    //     },
    //     showSymbol: false,
    //     label: {
    //       show: true,
    //       position: "top",
    //     },
    //     areaStyle: {
    //       opacity: 0.8,
    //     },
    //     emphasis: {
    //       focus: "series",
    //     },
    //     data:
    //       bmiChartData.length > 2 &&
    //       bmiChartData.map((row: any, index: any) => row.p18),
    //   },
    //   {
    //     name: "P17",
    //     type: "line",
    //     silent: true,
    //     smooth: true,
    //     lineStyle: {
    //       width: 0,
    //     },
    //     showSymbol: false,
    //     label: {
    //       show: true,
    //       position: "top",
    //     },
    //     areaStyle: {
    //       opacity: 0.8,
    //     },
    //     emphasis: {
    //       focus: "series",
    //     },
    //     data:
    //       bmiChartData.length > 2 &&
    //       bmiChartData.map((row: any, index: any) => row.p17),
    //   },
    //   {
    //     name: "P16",
    //     type: "line",
    //     silent: true,
    //     smooth: true,
    //     lineStyle: {
    //       width: 0,
    //     },
    //     showSymbol: false,
    //     label: {
    //       show: true,
    //       position: "top",
    //     },
    //     areaStyle: {
    //       opacity: 0.8,
    //     },
    //     emphasis: {
    //       focus: "series",
    //     },
    //     data:
    //       bmiChartData.length > 2 &&
    //       bmiChartData.map((row: any, index: any) => row.p16),
    //   },
    //   {
    //     name: "P15",
    //     type: "line",
    //     silent: true,
    //     smooth: true,
    //     lineStyle: {
    //       width: 0,
    //     },
    //     showSymbol: false,
    //     label: {
    //       show: true,
    //       position: "top",
    //     },
    //     areaStyle: {
    //       opacity: 0.8,
    //     },
    //     emphasis: {
    //       focus: "series",
    //     },
    //     data:
    //       bmiChartData.length > 2 &&
    //       bmiChartData.map((row: any, index: any) => row.p15),
    //   },
    //   {
    //     name: "P14",
    //     type: "line",
    //     silent: true,
    //     smooth: true,
    //     lineStyle: {
    //       width: 0,
    //     },
    //     showSymbol: false,
    //     label: {
    //       show: true,
    //       position: "top",
    //     },
    //     areaStyle: {
    //       opacity: 0.8,
    //     },
    //     emphasis: {
    //       focus: "series",
    //     },
    //     data:
    //       bmiChartData.length > 2 &&
    //       bmiChartData.map((row: any, index: any) => row.p14),
    //   },
    //   {
    //     name: "P13",
    //     type: "line",
    //     silent: true,
    //     smooth: true,
    //     lineStyle: {
    //       width: 0,
    //     },
    //     showSymbol: false,
    //     label: {
    //       show: true,
    //       position: "top",
    //     },
    //     areaStyle: {
    //       opacity: 0.8,
    //     },
    //     emphasis: {
    //       focus: "series",
    //     },
    //     data:
    //       bmiChartData.length > 2 &&
    //       bmiChartData.map((row: any, index: any) => row.p13),
    //   },
    //   {
    //     name: "P12",
    //     type: "line",
    //     silent: true,
    //     smooth: true,
    //     lineStyle: {
    //       width: 0,
    //     },
    //     showSymbol: false,
    //     label: {
    //       show: true,
    //       position: "top",
    //     },
    //     areaStyle: {
    //       opacity: 0.8,
    //     },
    //     emphasis: {
    //       focus: "series",
    //     },
    //     data:
    //       bmiChartData.length > 2 &&
    //       bmiChartData.map((row: any, index: any) => row.p12),
    //   },
    //   {
    //     name: "P11",
    //     type: "line",
    //     silent: true,
    //     smooth: true,
    //     lineStyle: {
    //       width: 0,
    //     },
    //     showSymbol: false,
    //     label: {
    //       show: true,
    //       position: "top",
    //     },
    //     areaStyle: {
    //       opacity: 0.8,
    //     },
    //     emphasis: {
    //       focus: "series",
    //     },
    //     data:
    //       bmiChartData.length > 2 &&
    //       bmiChartData.map((row: any, index: any) => row.p11),
    //   },

    //   //p4 to p10
    //   {
    //     name: "P10",
    //     type: "line",
    //     silent: true,
    //     smooth: true,
    //     lineStyle: {
    //       width: 0,
    //     },
    //     showSymbol: false,
    //     label: {
    //       show: true,
    //       position: "top",
    //     },
    //     areaStyle: {
    //       opacity: 0.8,
    //     },
    //     emphasis: {
    //       focus: "series",
    //     },
    //     data:
    //       bmiChartData.length > 2 &&
    //       bmiChartData.map((row: any, index: any) => row.p10),
    //   },
    //   {
    //     name: "P9",
    //     type: "line",
    //     silent: true,
    //     smooth: true,
    //     lineStyle: {
    //       width: 0,
    //     },
    //     showSymbol: false,
    //     label: {
    //       show: true,
    //       position: "top",
    //     },
    //     areaStyle: {
    //       opacity: 0.8,
    //     },
    //     emphasis: {
    //       focus: "series",
    //     },
    //     data:
    //       bmiChartData.length > 2 &&
    //       bmiChartData.map((row: any, index: any) => row.p9),
    //   },
    //   {
    //     name: "P8",
    //     type: "line",
    //     silent: true,
    //     smooth: true,
    //     lineStyle: {
    //       width: 0,
    //     },
    //     showSymbol: false,
    //     label: {
    //       show: true,
    //       position: "top",
    //     },
    //     areaStyle: {
    //       opacity: 0.8,
    //     },
    //     emphasis: {
    //       focus: "series",
    //     },
    //     data:
    //       bmiChartData.length > 2 &&
    //       bmiChartData.map((row: any, index: any) => row.p8),
    //   },
    //   {
    //     name: "P7",
    //     type: "line",
    //     silent: true,
    //     smooth: true,
    //     lineStyle: {
    //       width: 0,
    //     },
    //     showSymbol: false,
    //     label: {
    //       show: true,
    //       position: "top",
    //     },
    //     areaStyle: {
    //       opacity: 0.8,
    //     },
    //     emphasis: {
    //       focus: "series",
    //     },
    //     data:
    //       bmiChartData.length > 2 &&
    //       bmiChartData.map((row: any, index: any) => row.p7),
    //   },
    //   {
    //     name: "P6",
    //     type: "line",
    //     silent: true,
    //     smooth: true,
    //     lineStyle: {
    //       width: 0,
    //     },
    //     showSymbol: false,
    //     label: {
    //       show: true,
    //       position: "top",
    //     },
    //     areaStyle: {
    //       opacity: 0.8,
    //     },
    //     emphasis: {
    //       focus: "series",
    //     },
    //     data:
    //       bmiChartData.length > 2 &&
    //       bmiChartData.map((row: any, index: any) => row.p6),
    //   },
    //   {
    //     name: "P5",
    //     type: "line",
    //     silent: true,
    //     smooth: true,
    //     lineStyle: {
    //       width: 0,
    //     },
    //     showSymbol: false,
    //     label: {
    //       show: true,
    //       position: "top",
    //     },
    //     areaStyle: {
    //       opacity: 0.8,
    //     },
    //     emphasis: {
    //       focus: "series",
    //     },
    //     data:
    //       bmiChartData.length > 2 &&
    //       bmiChartData.map((row: any, index: any) => row.p5),
    //   },
    //   {
    //     name: "P4",
    //     type: "line",
    //     silent: true,
    //     smooth: true,
    //     lineStyle: {
    //       width: 0,
    //     },
    //     showSymbol: false,
    //     label: {
    //       show: true,
    //       position: "top",
    //     },
    //     areaStyle: {
    //       opacity: 0.8,
    //     },
    //     emphasis: {
    //       focus: "series",
    //     },
    //     data:
    //       bmiChartData.length > 2 &&
    //       bmiChartData.map((row: any, index: any) => row.p4),
    //   },

    //   //p1 to p3
    //   {
    //     name: "P3",
    //     type: "line",
    //     silent: true,
    //     smooth: true,
    //     lineStyle: {
    //       width: 0,
    //     },
    //     showSymbol: false,
    //     label: {
    //       show: true,
    //       position: "top",
    //     },
    //     areaStyle: {
    //       opacity: 0.8,
    //     },
    //     emphasis: {
    //       focus: "series",
    //     },
    //     data:
    //       bmiChartData.length > 2 &&
    //       bmiChartData.map((row: any, index: any) => row.p3),
    //   },
    //   {
    //     name: "P2",
    //     type: "line",
    //     silent: true,
    //     smooth: true,
    //     lineStyle: {
    //       width: 0,
    //     },
    //     showSymbol: false,
    //     label: {
    //       show: true,
    //       position: "top",
    //     },
    //     areaStyle: {
    //       opacity: 0.8,
    //     },
    //     emphasis: {
    //       focus: "series",
    //     },
    //     data:
    //       bmiChartData.length > 2 &&
    //       bmiChartData.map((row: any, index: any) => row.p2),
    //   },
    //   {
    //     name: "P1",
    //     type: "line",
    //     silent: true,
    //     smooth: true,
    //     lineStyle: {
    //       width: 0,
    //     },
    //     showSymbol: false,
    //     label: {
    //       show: true,
    //       position: "top",
    //     },
    //     areaStyle: {
    //       opacity: 0.8,
    //     },
    //     emphasis: {
    //       focus: "series",
    //     },
    //     data:
    //       bmiChartData.length > 2 &&
    //       bmiChartData.map((row: any, index: any) => row.p1),
    //   },

    //   {
    //     name: "user",
    //     type: "line",
    //     lineStyle: {
    //       width: 2,
    //     },
    //     label: {
    //       show: true,
    //       position: "top",
    //     },
    //     emphasis: {
    //       focus: "series",
    //     },
    //     data:
    //       bmiChartData.length > 2 &&
    //       bmiChartData.map((row: any, index: any) => row.bmi === 0 ? null : row.bmi),
    //   },
    // ],
    color: [

      // 1 to 3 - red
      "#FF0000", "#FF0000", "#FF0000",

      // 4 to 10 - orange
      "#FF8000", "#FF8000", "#FF8000", "#FF8000", "#FF8000", "#FF8000", "#FF8000",

      // 11 to 25 - lemon
      "#FFED00", "#FFED00", "#FFED00", "#FFED00", "#FFED00", "#FFED00", "#FFED00", "#FFED00", "#FFED00",
      "#FFED00", "#FFED00", "#FFED00", "#FFED00", "#FFED00", "#FFED00",

      // 26 to 50 - green
      "#16FF00", "#16FF00", "#16FF00", "#16FF00", "#16FF00", "#16FF00", "#16FF00", "#16FF00", "#16FF00",
      "#16FF00", "#16FF00", "#16FF00", "#16FF00", "#16FF00", "#16FF00", "#16FF00", "#16FF00", "#16FF00",
      "#16FF00", "#16FF00", "#16FF00", "#16FF00", "#16FF00", "#16FF00", "#16FF00",

      // 51 to 75 - lemon
      "#FFED00", "#FFED00", "#FFED00", "#FFED00", "#FFED00", "#FFED00", "#FFED00", "#FFED00", "#FFED00",
      "#FFED00", "#FFED00", "#FFED00", "#FFED00", "#FFED00", "#FFED00", "#FFED00", "#FFED00", "#FFED00",
      "#FFED00", "#FFED00", "#FFED00", "#FFED00", "#FFED00", "#FFED00", "#FFED00",

      // 76 to 90 - orange
      "#FF8000", "#FF8000", "#FF8000", "#FF8000", "#FF8000", "#FF8000", "#FF8000", "#FF8000", "#FF8000",
      "#FF8000", "#FF8000", "#FF8000", "#FF8000", "#FF8000", "#FF8000",

      // 91 to 99 - red
      "#FF0000", "#FF0000", "#FF0000", "#FF0000", "#FF0000", "#FF0000", "#FF0000", "#FF0000", "#FF0000",


      "#000000",
    ],
    // tooltip: {
    //   trigger: "axis",
    //   axisPointer: {
    //     type: "cross",
    //     label: {
    //       backgroundColor: "#6a7985",
    //     },
    //   },
    // },
    grid: {
      // left: "2%",
      // right: "2%",
      // bottom: "2%",
      // containLabel: true,
      left: "2%",
      containLabel: true,
      bottom: 20,
      top: 20,
      right: "2%"
    },
    xAxis: [
      {
        type: "category",
        boundaryGap: false,
        // name: daysInAge === 0 ? "Months" : "Days",
        // nameLocation: "middle",
        // nameGap: 15,
        data:
          bmiChartData.length !== null &&
          bmiChartData.length > 2 &&
          bmiChartData.map((row: any, index: any) =>
            row.days === 0 ? row.months : row.days
          ),
      },
    ],
    yAxis: [
      {
        type: "value",
        // interval: 5,
        textStyle: {
          fontSize: '12',
        },
        // name: "BMI in kg/m^2",
        // nameGap: 30,
        axisLine: {
          onZero: true,
        },
        nameTextStyle: {
          padding: [0, 0, 0, 20],
        },
        min: userBmiValues.length >= 3 ? minBmi : null,
        max: userBmiValues.length >= 3 ? maxBmi : null
        // min: bmiChartData.length !== null &&
        //   bmiChartData.length > 0 ?
        //   Math.floor(bmiChartData[0].p1) : 0,
        // },
      }

    ],
    series: [
      // p1 to P3
      {
        name: "P1",
        type: "line",
        silent: true, stack: 'Total',
        smooth: true,
        lineStyle: {
          width: 0,
        },
        showSymbol: false,
        label: {
          show: true,
          position: "top",
        },
        areaStyle: {
          opacity: 0.8,
        },
        emphasis: {
          focus: "series",
        },
        data:
          bmiChartData.length !== null &&
          bmiChartData.length > 2 &&
          bmiChartData.map((row: any, index: any) => row.p1),
      },
      {
        name: "P2",
        type: "line",
        silent: true, stack: 'Total',
        smooth: true,
        lineStyle: {
          width: 0,
        },
        showSymbol: false,
        label: {
          show: true,
          position: "top",
        },
        areaStyle: {
          opacity: 0.8,
        },
        emphasis: {
          focus: "series",
        },
        data:
          bmiChartData.length !== null &&
          bmiChartData.length > 2 &&
          bmiChartData.map((row: any, index: any) => row.p2),
      },
      {
        name: "P3",
        type: "line",
        silent: true, stack: 'Total',
        smooth: true,
        lineStyle: {
          width: 0,
        },
        showSymbol: false,
        label: {
          show: true,
          position: "top",
        },
        areaStyle: {
          opacity: 0.8,
        },
        emphasis: {
          focus: "series",
        },
        data:
          bmiChartData.length !== null &&
          bmiChartData.length > 2 &&
          bmiChartData.map((row: any, index: any) => row.p3),
      },

      // p4 to p10
      {
        name: "P4",
        type: "line",
        silent: true, stack: 'Total',
        smooth: true,
        lineStyle: {
          width: 0,
        },
        showSymbol: false,
        label: {
          show: true,
          position: "top",
        },
        areaStyle: {
          opacity: 0.8,
        },
        emphasis: {
          focus: "series",
        },
        data:
          bmiChartData.length !== null &&
          bmiChartData.length > 2 &&
          bmiChartData.map((row: any, index: any) => row.p4),
      },
      {
        name: "P5",
        type: "line",
        silent: true, stack: 'Total',
        smooth: true,
        lineStyle: {
          width: 0,
        },
        showSymbol: false,
        label: {
          show: true,
          position: "top",
        },
        areaStyle: {
          opacity: 0.8,
        },
        emphasis: {
          focus: "series",
        },
        data:
          bmiChartData.length !== null &&
          bmiChartData.length > 2 &&
          bmiChartData.map((row: any, index: any) => row.p5),
      },
      {
        name: "P6",
        type: "line",
        silent: true, stack: 'Total',
        smooth: true,
        lineStyle: {
          width: 0,
        },
        showSymbol: false,
        label: {
          show: true,
          position: "top",
        },
        areaStyle: {
          opacity: 0.8,
        },
        emphasis: {
          focus: "series",
        },
        data:
          bmiChartData.length !== null &&
          bmiChartData.length > 2 &&
          bmiChartData.map((row: any, index: any) => row.p6),
      },
      {
        name: "P7",
        type: "line",
        silent: true, stack: 'Total',
        smooth: true,
        lineStyle: {
          width: 0,
        },
        showSymbol: false,
        label: {
          show: true,
          position: "top",
        },
        areaStyle: {
          opacity: 0.8,
        },
        emphasis: {
          focus: "series",
        },
        data:
          bmiChartData.length !== null &&
          bmiChartData.length > 2 &&
          bmiChartData.map((row: any, index: any) => row.p7),
      },
      {
        name: "P8",
        type: "line",
        silent: true, stack: 'Total',
        smooth: true,
        lineStyle: {
          width: 0,
        },
        showSymbol: false,
        label: {
          show: true,
          position: "top",
        },
        areaStyle: {
          opacity: 0.8,
        },
        emphasis: {
          focus: "series",
        },
        data:
          bmiChartData.length !== null &&
          bmiChartData.length > 2 &&
          bmiChartData.map((row: any, index: any) => row.p8),
      },
      {
        name: "P9",
        type: "line",
        silent: true, stack: 'Total',
        smooth: true,
        lineStyle: {
          width: 0,
        },
        showSymbol: false,
        label: {
          show: true,
          position: "top",
        },
        areaStyle: {
          opacity: 0.8,
        },
        emphasis: {
          focus: "series",
        },
        data:
          bmiChartData.length !== null &&
          bmiChartData.length > 2 &&
          bmiChartData.map((row: any, index: any) => row.p9),
      },
      {
        name: "P10",
        type: "line",
        silent: true, stack: 'Total',
        smooth: true,
        lineStyle: {
          width: 0,
        },
        showSymbol: false,
        label: {
          show: true,
          position: "top",
        },
        areaStyle: {
          opacity: 0.8,
        },
        emphasis: {
          focus: "series",
        },
        data:
          bmiChartData.length !== null &&
          bmiChartData.length > 2 &&
          bmiChartData.map((row: any, index: any) => row.p10),
      },

      // p11 to p25
      {
        name: "P11",
        type: "line",
        silent: true, stack: 'Total',
        smooth: true,
        lineStyle: {
          width: 0,
        },
        showSymbol: false,
        label: {
          show: true,
          position: "top",
        },
        areaStyle: {
          opacity: 0.8,
        },
        emphasis: {
          focus: "series",
        },
        data:
          bmiChartData.length !== null &&
          bmiChartData.length > 2 &&
          bmiChartData.map((row: any, index: any) => row.p11),
      },
      {
        name: "P12",
        type: "line",
        silent: true, stack: 'Total',
        smooth: true,
        lineStyle: {
          width: 0,
        },
        showSymbol: false,
        label: {
          show: true,
          position: "top",
        },
        areaStyle: {
          opacity: 0.8,
        },
        emphasis: {
          focus: "series",
        },
        data:
          bmiChartData.length !== null &&
          bmiChartData.length > 2 &&
          bmiChartData.map((row: any, index: any) => row.p12),
      },
      {
        name: "P13",
        type: "line",
        silent: true, stack: 'Total',
        smooth: true,
        lineStyle: {
          width: 0,
        },
        showSymbol: false,
        label: {
          show: true,
          position: "top",
        },
        areaStyle: {
          opacity: 0.8,
        },
        emphasis: {
          focus: "series",
        },
        data:
          bmiChartData.length !== null &&
          bmiChartData.length > 2 &&
          bmiChartData.map((row: any, index: any) => row.p13),
      },
      {
        name: "P14",
        type: "line",
        silent: true, stack: 'Total',
        smooth: true,
        lineStyle: {
          width: 0,
        },
        showSymbol: false,
        label: {
          show: true,
          position: "top",
        },
        areaStyle: {
          opacity: 0.8,
        },
        emphasis: {
          focus: "series",
        },
        data:
          bmiChartData.length !== null &&
          bmiChartData.length > 2 &&
          bmiChartData.map((row: any, index: any) => row.p14),
      },
      {
        name: "P15",
        type: "line",
        silent: true, stack: 'Total',
        smooth: true,
        lineStyle: {
          width: 0,
        },
        showSymbol: false,
        label: {
          show: true,
          position: "top",
        },
        areaStyle: {
          opacity: 0.8,
        },
        emphasis: {
          focus: "series",
        },
        data:
          bmiChartData.length !== null &&
          bmiChartData.length > 2 &&
          bmiChartData.map((row: any, index: any) => row.p15),
      },
      {
        name: "P16",
        type: "line",
        silent: true, stack: 'Total',
        smooth: true,
        lineStyle: {
          width: 0,
        },
        showSymbol: false,
        label: {
          show: true,
          position: "top",
        },
        areaStyle: {
          opacity: 0.8,
        },
        emphasis: {
          focus: "series",
        },
        data:
          bmiChartData.length !== null &&
          bmiChartData.length > 2 &&
          bmiChartData.map((row: any, index: any) => row.p16),
      },
      {
        name: "P17",
        type: "line",
        silent: true, stack: 'Total',
        smooth: true,
        lineStyle: {
          width: 0,
        },
        showSymbol: false,
        label: {
          show: true,
          position: "top",
        },
        areaStyle: {
          opacity: 0.8,
        },
        emphasis: {
          focus: "series",
        },
        data:
          bmiChartData.length !== null &&
          bmiChartData.length > 2 &&
          bmiChartData.map((row: any, index: any) => row.p17),
      },
      {
        name: "P18",
        type: "line",
        silent: true, stack: 'Total',
        smooth: true,
        lineStyle: {
          width: 0,
        },
        showSymbol: false,
        label: {
          show: true,
          position: "top",
        },
        areaStyle: {
          opacity: 0.8,
        },
        emphasis: {
          focus: "series",
        },
        data:
          bmiChartData.length !== null &&
          bmiChartData.length > 2 &&
          bmiChartData.map((row: any, index: any) => row.p18),
      },
      {
        name: "P19",
        type: "line",
        silent: true, stack: 'Total',
        smooth: true,
        lineStyle: {
          width: 0,
        },
        showSymbol: false,
        label: {
          show: true,
          position: "top",
        },
        areaStyle: {
          opacity: 0.8,
        },
        emphasis: {
          focus: "series",
        },
        data:
          bmiChartData.length !== null &&
          bmiChartData.length > 2 &&
          bmiChartData.map((row: any, index: any) => row.p19),
      },
      {
        name: "P20",
        type: "line",
        silent: true, stack: 'Total',
        smooth: true,
        lineStyle: {
          width: 0,
        },
        showSymbol: false,
        label: {
          show: true,
          position: "top",
        },
        areaStyle: {
          opacity: 0.8,
        },
        emphasis: {
          focus: "series",
        },
        data:
          bmiChartData.length !== null &&
          bmiChartData.length > 2 &&
          bmiChartData.map((row: any, index: any) => row.p20),
      },
      {
        name: "P21",
        type: "line",
        silent: true, stack: 'Total',
        smooth: true,
        lineStyle: {
          width: 0,
        },
        showSymbol: false,
        label: {
          show: true,
          position: "top",
        },
        areaStyle: {
          opacity: 0.8,
        },
        emphasis: {
          focus: "series",
        },
        data:
          bmiChartData.length !== null &&
          bmiChartData.length > 2 &&
          bmiChartData.map((row: any, index: any) => row.p21),
      },
      {
        name: "P22",
        type: "line",
        silent: true, stack: 'Total',
        smooth: true,
        lineStyle: {
          width: 0,
        },
        showSymbol: false,
        label: {
          show: true,
          position: "top",
        },
        areaStyle: {
          opacity: 0.8,
        },
        emphasis: {
          focus: "series",
        },
        data:
          bmiChartData.length !== null &&
          bmiChartData.length > 2 &&
          bmiChartData.map((row: any, index: any) => row.p22),
      },
      {
        name: "P23",
        type: "line",
        silent: true, stack: 'Total',
        smooth: true,
        lineStyle: {
          width: 0,
        },
        showSymbol: false,
        label: {
          show: true,
          position: "top",
        },
        areaStyle: {
          opacity: 0.8,
        },
        emphasis: {
          focus: "series",
        },
        data:
          bmiChartData.length !== null &&
          bmiChartData.length > 2 &&
          bmiChartData.map((row: any, index: any) => row.p23),
      },
      {
        name: "P24",
        type: "line",
        silent: true, stack: 'Total',
        smooth: true,
        lineStyle: {
          width: 0,
        },
        showSymbol: false,
        label: {
          show: true,
          position: "top",
        },
        areaStyle: {
          opacity: 0.8,
        },
        emphasis: {
          focus: "series",
        },
        data:
          bmiChartData.length !== null &&
          bmiChartData.length > 2 &&
          bmiChartData.map((row: any, index: any) => row.p24),
      },
      {
        name: "P25",
        type: "line",
        silent: true, stack: 'Total',
        smooth: true,
        lineStyle: {
          width: 0,
        },
        showSymbol: false,
        label: {
          show: true,
          position: "top",
        },
        areaStyle: {
          opacity: 0.8,
        },
        emphasis: {
          focus: "series",
        },
        data:
          bmiChartData.length !== null &&
          bmiChartData.length > 2 &&
          bmiChartData.map((row: any, index: any) => row.p25),
      },

      // p26 to p50
      {
        name: "P26",
        type: "line",
        silent: true, stack: 'Total',
        smooth: true,
        lineStyle: {
          width: 0,
        },
        showSymbol: false,
        areaStyle: {
          opacity: 0.8,
        },
        emphasis: {
          focus: "series",
        },
        data:
          bmiChartData.length !== null &&
          bmiChartData.length > 2 &&
          bmiChartData.map((row: any, index: any) => row.p26),
      },
      {
        name: "P27",
        type: "line",
        silent: true, stack: 'Total',
        smooth: true,
        lineStyle: {
          width: 0,
        },
        showSymbol: false,
        areaStyle: {
          opacity: 0.8,
        },
        emphasis: {
          focus: "series",
        },
        data:
          bmiChartData.length !== null &&
          bmiChartData.length > 2 &&
          bmiChartData.map((row: any, index: any) => row.p27),
      },
      {
        name: "P28",
        type: "line",
        silent: true, stack: 'Total',
        smooth: true,
        lineStyle: {
          width: 0,
        },
        showSymbol: false,
        areaStyle: {
          opacity: 0.8,
        },
        emphasis: {
          focus: "series",
        },
        data:
          bmiChartData.length !== null &&
          bmiChartData.length > 2 &&
          bmiChartData.map((row: any, index: any) => row.p28),
      },
      {
        name: "P29",
        type: "line",
        silent: true, stack: 'Total',
        smooth: true,
        lineStyle: {
          width: 0,
        },
        showSymbol: false,
        areaStyle: {
          opacity: 0.8,
        },
        emphasis: {
          focus: "series",
        },
        data:
          bmiChartData.length !== null &&
          bmiChartData.length > 2 &&
          bmiChartData.map((row: any, index: any) => row.p29),
      },
      {
        name: "P30",
        type: "line",
        silent: true, stack: 'Total',
        smooth: true,
        lineStyle: {
          width: 0,
        },
        showSymbol: false,
        areaStyle: {
          opacity: 0.8,
        },
        emphasis: {
          focus: "series",
        },
        data:
          bmiChartData.length !== null &&
          bmiChartData.length > 2 &&
          bmiChartData.map((row: any, index: any) => row.p30),
      },
      {
        name: "P31",
        type: "line",
        silent: true, stack: 'Total',
        smooth: true,
        lineStyle: {
          width: 0,
        },
        showSymbol: false,
        areaStyle: {
          opacity: 0.8,
        },
        emphasis: {
          focus: "series",
        },
        data:
          bmiChartData.length !== null &&
          bmiChartData.length > 2 &&
          bmiChartData.map((row: any, index: any) => row.p31),
      },
      {
        name: "P32",
        type: "line",
        silent: true, stack: 'Total',
        smooth: true,
        lineStyle: {
          width: 0,
        },
        showSymbol: false,
        areaStyle: {
          opacity: 0.8,
        },
        emphasis: {
          focus: "series",
        },
        data:
          bmiChartData.length !== null &&
          bmiChartData.length > 2 &&
          bmiChartData.map((row: any, index: any) => row.p32),
      },
      {
        name: "P33",
        type: "line",
        silent: true, stack: 'Total',
        smooth: true,
        lineStyle: {
          width: 0,
        },
        showSymbol: false,
        areaStyle: {
          opacity: 0.8,
        },
        emphasis: {
          focus: "series",
        },
        data:
          bmiChartData.length !== null &&
          bmiChartData.length > 2 &&
          bmiChartData.map((row: any, index: any) => row.p33),
      },
      {
        name: "P34",
        type: "line",
        silent: true, stack: 'Total',
        smooth: true,
        lineStyle: {
          width: 0,
        },
        showSymbol: false,
        areaStyle: {
          opacity: 0.8,
        },
        emphasis: {
          focus: "series",
        },
        data:
          bmiChartData.length !== null &&
          bmiChartData.length > 2 &&
          bmiChartData.map((row: any, index: any) => row.p34),
      },
      {
        name: "P35",
        type: "line",
        silent: true, stack: 'Total',
        smooth: true,
        lineStyle: {
          width: 0,
        },
        showSymbol: false,
        areaStyle: {
          opacity: 0.8,
        },
        emphasis: {
          focus: "series",
        },
        data:
          bmiChartData.length !== null &&
          bmiChartData.length > 2 &&
          bmiChartData.map((row: any, index: any) => row.p35),
      },
      {
        name: "P36",
        type: "line",
        silent: true, stack: 'Total',
        smooth: true,
        lineStyle: {
          width: 0,
        },
        showSymbol: false,
        areaStyle: {
          opacity: 0.8,
        },
        emphasis: {
          focus: "series",
        },
        data:
          bmiChartData.length !== null &&
          bmiChartData.length > 2 &&
          bmiChartData.map((row: any, index: any) => row.p36),
      },
      {
        name: "P37",
        type: "line",
        silent: true, stack: 'Total',
        smooth: true,
        lineStyle: {
          width: 0,
        },
        showSymbol: false,
        areaStyle: {
          opacity: 0.8,
        },
        emphasis: {
          focus: "series",
        },
        data:
          bmiChartData.length !== null &&
          bmiChartData.length > 2 &&
          bmiChartData.map((row: any, index: any) => row.p37),
      },
      {
        name: "P38",
        type: "line",
        silent: true, stack: 'Total',
        smooth: true,
        lineStyle: {
          width: 0,
        },
        showSymbol: false,
        areaStyle: {
          opacity: 0.8,
        },
        emphasis: {
          focus: "series",
        },
        data:
          bmiChartData.length !== null &&
          bmiChartData.length > 2 &&
          bmiChartData.map((row: any, index: any) => row.p38),
      },
      {
        name: "P39",
        type: "line",
        silent: true, stack: 'Total',
        smooth: true,
        lineStyle: {
          width: 0,
        },
        showSymbol: false,
        areaStyle: {
          opacity: 0.8,
        },
        emphasis: {
          focus: "series",
        },
        data:
          bmiChartData.length !== null &&
          bmiChartData.length > 2 &&
          bmiChartData.map((row: any, index: any) => row.p39),
      },
      {
        name: "P40",
        type: "line",
        silent: true, stack: 'Total',
        smooth: true,
        lineStyle: {
          width: 0,
        },
        showSymbol: false,
        areaStyle: {
          opacity: 0.8,
        },
        emphasis: {
          focus: "series",
        },
        data:
          bmiChartData.length !== null &&
          bmiChartData.length > 2 &&
          bmiChartData.map((row: any, index: any) => row.p40),
      },
      {
        name: "P41",
        type: "line",
        silent: true, stack: 'Total',
        smooth: true,
        lineStyle: {
          width: 0,
        },
        showSymbol: false,
        areaStyle: {
          opacity: 0.8,
        },
        emphasis: {
          focus: "series",
        },
        data:
          bmiChartData.length !== null &&
          bmiChartData.length > 2 &&
          bmiChartData.map((row: any, index: any) => row.p41),
      },
      {
        name: "P42",
        type: "line",
        silent: true, stack: 'Total',
        smooth: true,
        lineStyle: {
          width: 0,
        },
        showSymbol: false,
        areaStyle: {
          opacity: 0.8,
        },
        emphasis: {
          focus: "series",
        },
        data:
          bmiChartData.length !== null &&
          bmiChartData.length > 2 &&
          bmiChartData.map((row: any, index: any) => row.p42),
      },
      {
        name: "P43",
        type: "line",
        silent: true, stack: 'Total',
        smooth: true,
        lineStyle: {
          width: 0,
        },
        showSymbol: false,
        areaStyle: {
          opacity: 0.8,
        },
        emphasis: {
          focus: "series",
        },
        data:
          bmiChartData.length !== null &&
          bmiChartData.length > 2 &&
          bmiChartData.map((row: any, index: any) => row.p43),
      },
      {
        name: "P44",
        type: "line",
        silent: true, stack: 'Total',
        smooth: true,
        lineStyle: {
          width: 0,
        },
        showSymbol: false,
        areaStyle: {
          opacity: 0.8,
        },
        emphasis: {
          focus: "series",
        },
        data:
          bmiChartData.length !== null &&
          bmiChartData.length > 2 &&
          bmiChartData.map((row: any, index: any) => row.p44),
      },
      {
        name: "P45",
        type: "line",
        silent: true, stack: 'Total',
        smooth: true,
        lineStyle: {
          width: 0,
        },
        showSymbol: false,
        areaStyle: {
          opacity: 0.8,
        },
        emphasis: {
          focus: "series",
        },
        data:
          bmiChartData.length !== null &&
          bmiChartData.length > 2 &&
          bmiChartData.map((row: any, index: any) => row.p45),
      },
      {
        name: "P46",
        type: "line",
        silent: true, stack: 'Total',
        smooth: true,
        lineStyle: {
          width: 0,
        },
        showSymbol: false,
        areaStyle: {
          opacity: 0.8,
        },
        emphasis: {
          focus: "series",
        },
        data:
          bmiChartData.length !== null &&
          bmiChartData.length > 2 &&
          bmiChartData.map((row: any, index: any) => row.p46),
      },
      {
        name: "P47",
        type: "line",
        silent: true, stack: 'Total',
        smooth: true,
        lineStyle: {
          width: 0,
        },
        showSymbol: false,
        areaStyle: {
          opacity: 0.8,
        },
        emphasis: {
          focus: "series",
        },
        data:
          bmiChartData.length !== null &&
          bmiChartData.length > 2 &&
          bmiChartData.map((row: any, index: any) => row.p47),
      },
      {
        name: "P48",
        type: "line",
        silent: true, stack: 'Total',
        smooth: true,
        lineStyle: {
          width: 0,
        },
        showSymbol: false,
        areaStyle: {
          opacity: 0.8,
        },
        emphasis: {
          focus: "series",
        },
        data:
          bmiChartData.length !== null &&
          bmiChartData.length > 2 &&
          bmiChartData.map((row: any, index: any) => row.p48),
      },
      {
        name: "P49",
        type: "line",
        silent: true, stack: 'Total',
        smooth: true,
        lineStyle: {
          width: 0,
        },
        showSymbol: false,
        areaStyle: {
          opacity: 0.8,
        },
        emphasis: {
          focus: "series",
        },
        data:
          bmiChartData.length !== null &&
          bmiChartData.length > 2 &&
          bmiChartData.map((row: any, index: any) => row.p49),
      },
      {
        name: "P50",
        type: "line",
        silent: true, stack: 'Total',
        smooth: true,
        lineStyle: {
          width: 0,
        },
        showSymbol: false,
        areaStyle: {
          opacity: 0.8,
        },
        emphasis: {
          focus: "series",
        },
        data:
          bmiChartData.length !== null &&
          bmiChartData.length > 2 &&
          bmiChartData.map((row: any, index: any) => row.p50),
      },


      // p51 to p75
      {
        name: "P51",
        type: "line",
        silent: true, stack: 'Total',
        smooth: true,
        lineStyle: {
          width: 0,
        },
        showSymbol: false,
        areaStyle: {
          opacity: 0.8,
        },
        emphasis: {
          focus: "series",
        },
        data:
          bmiChartData.length !== null &&
          bmiChartData.length > 2 &&
          bmiChartData.map((row: any, index: any) => row.p51),
      },
      {
        name: "P52",
        type: "line",
        silent: true, stack: 'Total',
        smooth: true,
        lineStyle: {
          width: 0,
        },
        showSymbol: false,
        areaStyle: {
          opacity: 0.8,
        },
        emphasis: {
          focus: "series",
        },
        data:
          bmiChartData.length !== null &&
          bmiChartData.length > 2 &&
          bmiChartData.map((row: any, index: any) => row.p52),
      },
      {
        name: "P53",
        type: "line",
        silent: true, stack: 'Total',
        smooth: true,
        lineStyle: {
          width: 0,
        },
        showSymbol: false,
        areaStyle: {
          opacity: 0.8,
        },
        emphasis: {
          focus: "series",
        },
        data:
          bmiChartData.length !== null &&
          bmiChartData.length > 2 &&
          bmiChartData.map((row: any, index: any) => row.p53),
      },
      {
        name: "P54",
        type: "line",
        silent: true, stack: 'Total',
        smooth: true,
        lineStyle: {
          width: 0,
        },
        showSymbol: false,
        areaStyle: {
          opacity: 0.8,
        },
        emphasis: {
          focus: "series",
        },
        data:
          bmiChartData.length !== null &&
          bmiChartData.length > 2 &&
          bmiChartData.map((row: any, index: any) => row.p54),
      },
      {
        name: "P55",
        type: "line",
        silent: true, stack: 'Total',
        smooth: true,
        lineStyle: {
          width: 0,
        },
        showSymbol: false,
        areaStyle: {
          opacity: 0.8,
        },
        emphasis: {
          focus: "series",
        },
        data:
          bmiChartData.length !== null &&
          bmiChartData.length > 2 &&
          bmiChartData.map((row: any, index: any) => row.p55),
      },
      {
        name: "P56",
        type: "line",
        silent: true, stack: 'Total',
        smooth: true,
        lineStyle: {
          width: 0,
        },
        showSymbol: false,
        areaStyle: {
          opacity: 0.8,
        },
        emphasis: {
          focus: "series",
        },
        data:
          bmiChartData.length !== null &&
          bmiChartData.length > 2 &&
          bmiChartData.map((row: any, index: any) => row.p56),
      },
      {
        name: "P57",
        type: "line",
        silent: true, stack: 'Total',
        smooth: true,
        lineStyle: {
          width: 0,
        },
        showSymbol: false,
        areaStyle: {
          opacity: 0.8,
        },
        emphasis: {
          focus: "series",
        },
        data:
          bmiChartData.length !== null &&
          bmiChartData.length > 2 &&
          bmiChartData.map((row: any, index: any) => row.p57),
      },
      {
        name: "P58",
        type: "line",
        silent: true, stack: 'Total',
        smooth: true,
        lineStyle: {
          width: 0,
        },
        showSymbol: false,
        areaStyle: {
          opacity: 0.8,
        },
        emphasis: {
          focus: "series",
        },
        data:
          bmiChartData.length !== null &&
          bmiChartData.length > 2 &&
          bmiChartData.map((row: any, index: any) => row.p58),
      },
      {
        name: "P59",
        type: "line",
        silent: true, stack: 'Total',
        smooth: true,
        lineStyle: {
          width: 0,
        },
        showSymbol: false,
        areaStyle: {
          opacity: 0.8,
        },
        emphasis: {
          focus: "series",
        },
        data:
          bmiChartData.length !== null &&
          bmiChartData.length > 2 &&
          bmiChartData.map((row: any, index: any) => row.p59),
      },
      {
        name: "P60",
        type: "line",
        silent: true, stack: 'Total',
        smooth: true,
        lineStyle: {
          width: 0,
        },
        showSymbol: false,
        areaStyle: {
          opacity: 0.8,
        },
        emphasis: {
          focus: "series",
        },
        data:
          bmiChartData.length !== null &&
          bmiChartData.length > 2 &&
          bmiChartData.map((row: any, index: any) => row.p60),
      },
      {
        name: "P61",
        type: "line",
        silent: true, stack: 'Total',
        smooth: true,
        lineStyle: {
          width: 0,
        },
        showSymbol: false,
        areaStyle: {
          opacity: 0.8,
        },
        emphasis: {
          focus: "series",
        },
        data:
          bmiChartData.length !== null &&
          bmiChartData.length > 2 &&
          bmiChartData.map((row: any, index: any) => row.p61),
      },
      {
        name: "P62",
        type: "line",
        silent: true, stack: 'Total',
        smooth: true,
        lineStyle: {
          width: 0,
        },
        showSymbol: false,
        areaStyle: {
          opacity: 0.8,
        },
        emphasis: {
          focus: "series",
        },
        data:
          bmiChartData.length !== null &&
          bmiChartData.length > 2 &&
          bmiChartData.map((row: any, index: any) => row.p62),
      },
      {
        name: "P63",
        type: "line",
        silent: true, stack: 'Total',
        smooth: true,
        lineStyle: {
          width: 0,
        },
        showSymbol: false,
        areaStyle: {
          opacity: 0.8,
        },
        emphasis: {
          focus: "series",
        },
        data:
          bmiChartData.length !== null &&
          bmiChartData.length > 2 &&
          bmiChartData.map((row: any, index: any) => row.p63),
      },
      {
        name: "P64",
        type: "line",
        silent: true, stack: 'Total',
        smooth: true,
        lineStyle: {
          width: 0,
        },
        showSymbol: false,
        areaStyle: {
          opacity: 0.8,
        },
        emphasis: {
          focus: "series",
        },
        data:
          bmiChartData.length !== null &&
          bmiChartData.length > 2 &&
          bmiChartData.map((row: any, index: any) => row.p64),
      },
      {
        name: "P65",
        type: "line",
        silent: true, stack: 'Total',
        smooth: true,
        lineStyle: {
          width: 0,
        },
        showSymbol: false,
        areaStyle: {
          opacity: 0.8,
        },
        emphasis: {
          focus: "series",
        },
        data:
          bmiChartData.length !== null &&
          bmiChartData.length > 2 &&
          bmiChartData.map((row: any, index: any) => row.p65),
      },
      {
        name: "P66",
        type: "line",
        silent: true, stack: 'Total',
        smooth: true,
        lineStyle: {
          width: 0,
        },
        showSymbol: false,
        areaStyle: {
          opacity: 0.8,
        },
        emphasis: {
          focus: "series",
        },
        data:
          bmiChartData.length !== null &&
          bmiChartData.length > 2 &&
          bmiChartData.map((row: any, index: any) => row.p66),
      },
      {
        name: "P67",
        type: "line",
        silent: true, stack: 'Total',
        smooth: true,
        lineStyle: {
          width: 0,
        },
        showSymbol: false,
        areaStyle: {
          opacity: 0.8,
        },
        emphasis: {
          focus: "series",
        },
        data:
          bmiChartData.length !== null &&
          bmiChartData.length > 2 &&
          bmiChartData.map((row: any, index: any) => row.p67),
      },
      {
        name: "P68",
        type: "line",
        silent: true, stack: 'Total',
        smooth: true,
        lineStyle: {
          width: 0,
        },
        showSymbol: false,
        areaStyle: {
          opacity: 0.8,
        },
        emphasis: {
          focus: "series",
        },
        data:
          bmiChartData.length !== null &&
          bmiChartData.length > 2 &&
          bmiChartData.map((row: any, index: any) => row.p68),
      },
      {
        name: "P69",
        type: "line",
        silent: true, stack: 'Total',
        smooth: true,
        lineStyle: {
          width: 0,
        },
        showSymbol: false,
        areaStyle: {
          opacity: 0.8,
        },
        emphasis: {
          focus: "series",
        },
        data:
          bmiChartData.length !== null &&
          bmiChartData.length > 2 &&
          bmiChartData.map((row: any, index: any) => row.p69),
      },
      {
        name: "P70",
        type: "line",
        silent: true, stack: 'Total',
        smooth: true,
        lineStyle: {
          width: 0,
        },
        showSymbol: false,
        areaStyle: {
          opacity: 0.8,
        },
        emphasis: {
          focus: "series",
        },
        data:
          bmiChartData.length !== null &&
          bmiChartData.length > 2 &&
          bmiChartData.map((row: any, index: any) => row.p70),
      },
      {
        name: "P71",
        type: "line",
        silent: true, stack: 'Total',
        smooth: true,
        lineStyle: {
          width: 0,
        },
        showSymbol: false,
        areaStyle: {
          opacity: 0.8,
        },
        emphasis: {
          focus: "series",
        },
        data:
          bmiChartData.length !== null &&
          bmiChartData.length > 2 &&
          bmiChartData.map((row: any, index: any) => row.p71),
      },
      {
        name: "P72",
        type: "line",
        silent: true, stack: 'Total',
        smooth: true,
        lineStyle: {
          width: 0,
        },
        showSymbol: false,
        areaStyle: {
          opacity: 0.8,
        },
        emphasis: {
          focus: "series",
        },
        data:
          bmiChartData.length !== null &&
          bmiChartData.length > 2 &&
          bmiChartData.map((row: any, index: any) => row.p72),
      },
      {
        name: "P73",
        type: "line",
        silent: true, stack: 'Total',
        smooth: true,
        lineStyle: {
          width: 0,
        },
        showSymbol: false,
        areaStyle: {
          opacity: 0.8,
        },
        emphasis: {
          focus: "series",
        },
        data:
          bmiChartData.length !== null &&
          bmiChartData.length > 2 &&
          bmiChartData.map((row: any, index: any) => row.p73),
      },
      {
        name: "P74",
        type: "line",
        silent: true, stack: 'Total',
        smooth: true,
        lineStyle: {
          width: 0,
        },
        showSymbol: false,
        areaStyle: {
          opacity: 0.8,
        },
        emphasis: {
          focus: "series",
        },
        data:
          bmiChartData.length !== null &&
          bmiChartData.length > 2 &&
          bmiChartData.map((row: any, index: any) => row.p74),
      },
      {
        name: "P75",
        type: "line",
        silent: true, stack: 'Total',
        smooth: true,
        lineStyle: {
          width: 0,
        },
        showSymbol: false,
        areaStyle: {
          opacity: 0.8,
        },
        emphasis: {
          focus: "series",
        },
        data:
          bmiChartData.length !== null &&
          bmiChartData.length > 2 &&
          bmiChartData.map((row: any, index: any) => row.p75),
      },

      // p76 to 90

      {
        name: "P76",
        type: "line",
        silent: true, stack: 'Total',
        smooth: true,
        lineStyle: {
          width: 0,
        },
        showSymbol: false,
        areaStyle: {
          opacity: 0.8,
        },
        emphasis: {
          focus: "series",
        },
        data:
          bmiChartData.length > 2 &&
          bmiChartData.map((row: any, index: any) => row.p76),
      },
      {
        name: "P77",
        type: "line",
        silent: true, stack: 'Total',
        smooth: true,
        lineStyle: {
          width: 0,
        },
        showSymbol: false,
        areaStyle: {
          opacity: 0.8,
        },
        emphasis: {
          focus: "series",
        },
        data:
          bmiChartData.length > 2 &&
          bmiChartData.map((row: any, index: any) => row.p77),
      },
      {
        name: "P78",
        type: "line",
        silent: true, stack: 'Total',
        smooth: true,
        lineStyle: {
          width: 0,
        },
        showSymbol: false,
        areaStyle: {
          opacity: 0.8,
        },
        emphasis: {
          focus: "series",
        },
        data:
          bmiChartData.length > 2 &&
          bmiChartData.map((row: any, index: any) => row.p78),
      },
      {
        name: "P79",
        type: "line",
        silent: true, stack: 'Total',
        smooth: true,
        lineStyle: {
          width: 0,
        },
        showSymbol: false,
        areaStyle: {
          opacity: 0.8,
        },
        emphasis: {
          focus: "series",
        },
        data:
          bmiChartData.length > 2 &&
          bmiChartData.map((row: any, index: any) => row.p79),
      },
      {
        name: "P80",
        type: "line",
        silent: true, stack: 'Total',
        smooth: true,
        lineStyle: {
          width: 0,
        },
        showSymbol: false,
        areaStyle: {
          opacity: 0.8,
        },
        emphasis: {
          focus: "series",
        },
        data:
          bmiChartData.length > 2 &&
          bmiChartData.map((row: any, index: any) => row.p80),
      },
      {
        name: "P81",
        type: "line",
        silent: true, stack: 'Total',
        smooth: true,
        lineStyle: {
          width: 0,
        },
        showSymbol: false,
        areaStyle: {
          opacity: 0.8,
        },
        emphasis: {
          focus: "series",
        },
        data:
          bmiChartData.length > 2 &&
          bmiChartData.map((row: any, index: any) => row.p81),
      },
      {
        name: "P82",
        type: "line",
        silent: true, stack: 'Total',
        smooth: true,
        lineStyle: {
          width: 0,
        },
        showSymbol: false,
        areaStyle: {
          opacity: 0.8,
        },
        emphasis: {
          focus: "series",
        },
        data:
          bmiChartData.length > 2 &&
          bmiChartData.map((row: any, index: any) => row.p82),
      },
      {
        name: "P83",
        type: "line",
        silent: true, stack: 'Total',
        smooth: true,
        lineStyle: {
          width: 0,
        },
        showSymbol: false,
        areaStyle: {
          opacity: 0.8,
        },
        emphasis: {
          focus: "series",
        },
        data:
          bmiChartData.length > 2 &&
          bmiChartData.map((row: any, index: any) => row.p83),
      },
      {
        name: "P84",
        type: "line",
        silent: true, stack: 'Total',
        smooth: true,
        lineStyle: {
          width: 0,
        },
        showSymbol: false,
        areaStyle: {
          opacity: 0.8,
        },
        emphasis: {
          focus: "series",
        },
        data:
          bmiChartData.length > 2 &&
          bmiChartData.map((row: any, index: any) => row.p84),
      },
      {
        name: "P85",
        type: "line",
        silent: true, stack: 'Total',
        smooth: true,
        lineStyle: {
          width: 0,
        },
        showSymbol: false,
        areaStyle: {
          opacity: 0.8,
        },
        emphasis: {
          focus: "series",
        },
        data:
          bmiChartData.length > 2 &&
          bmiChartData.map((row: any, index: any) => row.p85),
      },
      {
        name: "P86",
        type: "line",
        silent: true, stack: 'Total',
        smooth: true,
        lineStyle: {
          width: 0,
        },
        showSymbol: false,
        areaStyle: {
          opacity: 0.8,
        },
        emphasis: {
          focus: "series",
        },
        data:
          bmiChartData.length > 2 &&
          bmiChartData.map((row: any, index: any) => row.p86),
      },
      {
        name: "P87",
        type: "line",
        silent: true, stack: 'Total',
        smooth: true,
        lineStyle: {
          width: 0,
        },
        showSymbol: false,
        areaStyle: {
          opacity: 0.8,
        },
        emphasis: {
          focus: "series",
        },
        data:
          bmiChartData.length > 2 &&
          bmiChartData.map((row: any, index: any) => row.p87),
      },
      {
        name: "P88",
        type: "line",
        silent: true, stack: 'Total',
        smooth: true,
        lineStyle: {
          width: 0,
        },
        showSymbol: false,
        areaStyle: {
          opacity: 0.8,
        },
        emphasis: {
          focus: "series",
        },
        data:
          bmiChartData.length > 2 &&
          bmiChartData.map((row: any, index: any) => row.p88),
      },
      {
        name: "P89",
        type: "line",
        silent: true, stack: 'Total',
        smooth: true,
        lineStyle: {
          width: 0,
        },
        showSymbol: false,
        areaStyle: {
          opacity: 0.8,
        },
        emphasis: {
          focus: "series",
        },
        data:
          bmiChartData.length > 2 &&
          bmiChartData.map((row: any, index: any) => row.p89),
      },
      {
        name: "P90",
        type: "line",
        silent: true, stack: 'Total',
        smooth: true,
        lineStyle: {
          width: 0,
        },
        showSymbol: false,
        areaStyle: {
          opacity: 0.8,
        },
        emphasis: {
          focus: "series",
        },
        data:
          bmiChartData.length > 2 &&
          bmiChartData.map((row: any, index: any) => row.p90),
      },

      // p91 to p97      

      {
        name: "P91",
        type: "line",
        silent: true, stack: 'Total',
        smooth: true,
        lineStyle: {
          width: 0,
        },
        showSymbol: false,
        areaStyle: {
          opacity: 0.8,
        },
        emphasis: {
          focus: "series",
        },
        data:
          bmiChartData.length !== null &&
          bmiChartData.length > 2 &&
          bmiChartData.map((row: any, index: any) => row.p91),
      },
      {
        name: "P92",
        type: "line",
        silent: true, stack: 'Total',
        smooth: true,
        lineStyle: {
          width: 0,
        },
        showSymbol: false,
        areaStyle: {
          opacity: 0.8,
        },
        emphasis: {
          focus: "series",
        },
        data:
          bmiChartData.length !== null &&
          bmiChartData.length > 2 &&
          bmiChartData.map((row: any, index: any) => row.p92),
      },
      {
        name: "P93",
        type: "line",
        silent: true, stack: 'Total',
        smooth: true,
        lineStyle: {
          width: 0,
        },
        showSymbol: false,
        areaStyle: {
          opacity: 0.8,
        },
        emphasis: {
          focus: "series",
        },
        data:
          bmiChartData.length !== null &&
          bmiChartData.length > 2 &&
          bmiChartData.map((row: any, index: any) => row.p93),
      },
      {
        name: "P94",
        type: "line",
        silent: true, stack: 'Total',
        smooth: true,
        lineStyle: {
          width: 0,
        },
        showSymbol: false,
        areaStyle: {
          opacity: 0.8,
        },
        emphasis: {
          focus: "series",
        },
        data:
          bmiChartData.length !== null &&
          bmiChartData.length > 2 &&
          bmiChartData.map((row: any, index: any) => row.p94),
      },
      {
        name: "P95",
        type: "line",
        silent: true, stack: 'Total',
        smooth: true,
        lineStyle: {
          width: 0,
        },
        showSymbol: false,
        areaStyle: {
          opacity: 0.8,
        },
        emphasis: {
          focus: "series",
        },
        data:
          bmiChartData.length !== null &&
          bmiChartData.length > 2 &&
          bmiChartData.map((row: any, index: any) => row.p95),
      },
      {
        name: "P96",
        type: "line",
        silent: true, stack: 'Total',
        smooth: true,
        lineStyle: {
          width: 0,
        },
        showSymbol: false,
        areaStyle: {
          opacity: 0.8,
        },
        emphasis: {
          focus: "series",
        },
        data:
          bmiChartData.length !== null &&
          bmiChartData.length > 2 &&
          bmiChartData.map((row: any, index: any) => row.p96),
      },
      {
        name: "P97",
        type: "line",
        silent: true, stack: 'Total',
        smooth: true,
        lineStyle: {
          width: 0,
        },
        showSymbol: false,
        areaStyle: {
          opacity: 0.8,
        },
        emphasis: {
          focus: "series",
        },
        data:
          bmiChartData.length !== null &&
          bmiChartData.length > 2 &&
          bmiChartData.map((row: any, index: any) => row.p97),
      },

      // p98 to p99

      {
        name: "P98",
        type: "line",
        silent: true, stack: 'Total',
        smooth: true,
        lineStyle: {
          width: 0,
        },
        showSymbol: false,
        areaStyle: {
          opacity: 0.8,
        },
        emphasis: {
          focus: "series",
        },
        data:
          bmiChartData.length !== null &&
          bmiChartData.length > 2 &&
          bmiChartData.map((row: any, index: any) => row.p98),
      },
      {
        name: "P99",
        type: "line",
        silent: true, stack: 'Total',
        smooth: true,
        lineStyle: {
          width: 0,
        },
        showSymbol: false,
        areaStyle: {
          opacity: 0.8,
        },
        emphasis: {
          focus: "series",
        },
        data:
          bmiChartData.length !== null &&
          bmiChartData.length > 2 &&
          bmiChartData.map((row: any, index: any) => row.p99),
      },

      {
        name: "user",
        type: "line",
        connectNulls: true,
        lineStyle: {
          width: 2,
        },
        label: {
          show: true,
          position: "top",
        },
        emphasis: {
          focus: "series",
        },
        data:
          bmiChartData.length !== null &&
          bmiChartData.length > 0 &&
          bmiChartData.map((row: any, index: any) => row.bmi),
      },
    ],
  };

  async function fetchBmiData() {
    setLoading(true);
    try {
      let params = new URLSearchParams();
      params.append("familyId", userContext.loginDetail.familyId.toString());
      params.append("familyMemberId", userContext.memberDetail.familyMemberId.toString());
      const result: any = await GetMemberBmiDetailList(params);
      let memberBmiData = result.data.data;
      if (memberBmiData.length > 0) {
        setMemberBmiDetailData(memberBmiData);
      }
    } catch (error) {
      setLoading(false);
      if (error) {
        setAlertMessage({
          isSuccess: true,
          message: error as string,
        });
      }
    }
    setLoading(false);
  }

  useEffect(() => {
    if (userContext.memberDetail !== undefined && userContext.memberDetail.familyMemberId > 0) {
      fetchBmiData();
    }
  }, []);

  useEffect(() => {
    if (userContext.memberDetail !== undefined && userContext.memberDetail.familyMemberId > 0) {
      fetchData();
    }
  }, [memberBmiDetailData]);


  const [state, setState] = useState<State>({
    open: true,
    vertical: 'top',
    horizontal: 'right',
  });
  const { vertical, horizontal, open } = state;

  const handleClose = () => {
    setState({ ...state, open: false });
  };

  return (
    <Fade in={true} timeout={{ enter: 500 }}>
      <Box className="bmi-main-box">
        <Box className="head-box">
          <Box className="back-box">
            <IconButton
              aria-label="back"
              className="back-icon"
              disableRipple
              onClick={() => backClick()}>
              <img src={backIcon} alt="logo" />
            </IconButton>
          </Box>
          <Box className="title-box">
            <Typography className="title-text">BMI</Typography>
          </Box>
          <Box className="skip-box">
            <></>
            {/* <Typography>skip</Typography> */}
          </Box>
        </Box>
        <Box className="body-box">
          {memberBmiDetailData !== undefined && memberBmiDetailData !== null && memberBmiDetailData.length > 0 ?
            <Box sx={{ pb: '10px' }}>
              {/* <Alert severity="warning">
            <AlertTitle>Disclaimer:
            </AlertTitle>
            We are just giving you the rough outline regarding the health, growth and development of your child just to sensitize you to seek the medical care earlier if needed. Every child has to be examined individually by a registered medical practitioner before confirmation of status of health, growth and development of any child and A Doctor is the Final authority to decide regarding further management and intervention needed as felt necessary by the Doctor.
          </Alert> */}
              {/* <Snackbar open={open} autoHideDuration={6000} onClose={handleClose} anchorOrigin={{ vertical, horizontal }} key={vertical + horizontal}>
                <Alert
                  onClose={handleClose}
                  severity="warning"
                  variant="filled"
                  sx={{ width: '100%' }}
                >
                  <AlertTitle>Disclaimer:
                  </AlertTitle>
                  We are just giving you the rough outline regarding the health, growth and development of your child just to sensitize you to seek the medical care earlier if needed. Every child has to be examined individually by a registered medical practitioner before confirmation of status of health, growth and development of any child and A Doctor is the Final authority to decide regarding further management and intervention needed as felt necessary by the Doctor.
                </Alert>
              </Snackbar> */}
            </Box>
            : <></>}
          <CustomTabs
            icon={bmiIcon}
            option={bmiOptions}
            growthData={memberBmiDetailData}
            onClick={openBMISheet}
            dataLength={bmiChartData.length}
            ageCount={ageCountData}
            page="BMI"
            yAxisLabel='BMI in kg/m^2'
            xAxisLabel={daysInAge === 0 ? "Months" : "Days"}
          />

        </Box>

        {isBottomSheetOpen === true ? (
          <Box className="bmi-bottom-sheet-box" style={{ overflow: "hidden" }}>
            <a.div
              // onClick={() => close()}
              className={styles.sheet}
              {...bind()}
              style={{ display, bottom: `calc(-100vh + ${height}px)`, y }}>
              <Box className="top-bar"></Box>
              <Formik
                enableReinitialize
                initialValues={bmiData}
                onSubmit={(values) => {
                  SubmitBMI(values);
                }}
                validationSchema={valildationSchema}>
                {(props) => {
                  return (
                    <Form>
                      <Box className="form-box">
                        <Grid container spacing={2} padding={2}>
                          <Grid item xs={12} md={12} sm={12}>
                            <Box className="input-box">
                              <FormControl fullWidth>
                                {submitLoading ? (
                                  <Skeleton
                                    animation="wave"
                                    variant="rounded"
                                    width="auto"
                                    height={45}
                                  />
                                ) : (
                                  <>
                                    <InputLabel htmlFor="outlined-adornment-password">
                                      BMI
                                    </InputLabel>
                                    <OutlinedInput
                                      id="bmi"
                                      type="tel"
                                      fullWidth
                                      endAdornment={
                                        <InputAdornment position="end">
                                          <Typography>cm</Typography>
                                        </InputAdornment>
                                      }
                                      onChange={(e: any) => {
                                        props.setFieldValue(
                                          "bmi",
                                          e.target.value !== null
                                            ? e.target.value
                                            : "",
                                          true
                                        );
                                      }}
                                      onBlur={props.handleBlur}
                                      value={props.values.bmi}
                                      inputProps={{ maxLength: 200 }}
                                      // variant="outlined"
                                      error={
                                        props.values.bmi === "" &&
                                          props.errors.bmi &&
                                          props.touched.bmi
                                          ? true
                                          : false
                                      }
                                      className={
                                        props.values.bmi === "" &&
                                          props.errors.bmi &&
                                          props.touched.bmi
                                          ? "text-input validation-message"
                                          : "text-input"
                                      }
                                    />
                                    {props.values.bmi === "" &&
                                      props.errors.bmi &&
                                      props.touched.bmi && (
                                        <Typography
                                          className={
                                            props.values.bmi === "" &&
                                              props.errors.bmi &&
                                              props.touched.bmi
                                              ? "validation-message"
                                              : "validation-message"
                                          }>
                                          {props.errors.bmi}
                                        </Typography>
                                      )}
                                  </>
                                )}
                              </FormControl>
                            </Box>
                          </Grid>
                          {/* <Grid item xs={12} md={12} sm={12}>
                                                        <Typography>CM</Typography>
                                                    </Grid> */}

                          <Grid
                            item
                            xs={12}
                            md={12}
                            sm={12}
                            className="button-grid">
                            <Box className="bottom-box">
                              <CustomButton
                                disabled={false}
                                class="add-button"
                                isLoading={submitLoading}
                                title="Add" />
                            </Box>
                          </Grid>
                        </Grid>
                      </Box>
                    </Form>
                  );
                }}
              </Formik>
            </a.div>
          </Box>
        ) : (
          <></>
        )}
      </Box>
    </Fade>
  );
}
