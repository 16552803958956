import { GET, POST } from "./common/http";
import { BASE_URL } from "./common/const";

export const GetMilestoneQuestionsDetail = (parmas: any) =>
    GET(`${BASE_URL}/api/MilestoneDevelopment/GetMilestoneQuestions?` + parmas, null);

export const GetMemberMilestoneSummaryList = (parmas: any) =>
    GET(`${BASE_URL}/api/MilestoneDevelopment/GetMemberMilestoneSummaryList?` + parmas, null);

export const CreateMilestoneSummary = (data: any) =>
    POST(`${BASE_URL}/api/MilestoneDevelopment/CreateMilestoneSummary`, data);


