import React, { useContext, useEffect, useState } from 'react';
import './Spouse.scss';
import { Avatar, Box, Fade, FormControl, Grid, IconButton, Skeleton, TextField, Typography, styled } from '@mui/material';
import { useNavigate } from "react-router-dom";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import Select from 'react-select';
import cameraIcon from '../../assets/images/camera.svg';
import backIcon from '../../assets/icons/back.svg';
import { CreateParent, GetFamilyMemberDetail, GetFamilyRelationList, UpdateParent } from '../../services/ParentService';
import { PreferencesContext } from '../../PreferenceContext';
import { AlertType } from '../../shared/AlertType';
import { getNameSelected, getSingleSelected } from '../../shared/CommonFuctions';
import CustomButton from '../../components/CustomButton/CustomButton';

const SmallAvatar = styled(Avatar)(({ theme }) => ({
    width: 38,
    height: 38,
    cursor: 'pointer'
}));

export default function Spouse() {
    const { userContext, updateUserContext } = useContext(PreferencesContext);

    let formInitialValues = {
        // profileImage: null,
        familyMemberId: 0,
        familyRelationName: "",
        familyRelationCode: "",
        firstName: "",
        lastName: "",
        age: "",
        // isDeleted: 0
    };

    const [loading, setLoading] = useState(false);
    const [submitLoading, setSubmitLoading] = useState(false);
    const [spouseData, setSpouseData] = useState(formInitialValues);
    const [profileImageFile, setProfileImageFile] = useState<any>();
    const [image, setImage] = useState({ preview: "", raw: "" });
    const [serverImage, setServerImage] = useState('');
    const [profileImageName, setProfileImageName] = useState('')
    const [familyRelation, setFamilyRelation] = useState([]);
    const [showAlert, setAlertMessage] = useState({
        isSuccess: false,
        message: "",
    });

    const postedList = [
        "Guardian",
        "Son",
        "Daughter"
    ];

    const filteredList = familyRelation.filter(
        ({ label }) => !postedList.includes(label)
    );

    const navigate = useNavigate();

    function backClick() {
        if (userContext.loginDetail !== null && userContext.loginDetail.userId > 0 && userContext.isAddMember === true) {
            navigate("/stage-selection");
        } else {
            navigate("/parent");
        }
    }

    function goToSkip() {
        if (userContext.stage === 'EXPECTING') {
            goToMaternalDetail();
        } else if (userContext.stage === 'PARENTS') {
            navigate("/child-detail");
        }
    }

    const handleChange = (e: any) => {

        if (e.target.files.length) {
            setImage({
                preview: URL.createObjectURL(e.target.files[0]),
                raw: e.target.files[0]
            });
        }
        setProfileImageName(e.target.files[0].name);
        setProfileImageFile(e.target.files[0])
    };

    const setDefaultRole = () => {
        if (userContext.loginDetail.familyRelationName === "FATHER") {
            let motherInitialData = {
                // profileImage: null,
                familyMemberId: 0,
                familyRelationName: "2",
                familyRelationCode: "MOTHER",
                firstName: "",
                lastName: userContext.loginDetail.lastName,
                age: "",
                // isDeleted: 0
            };
            setSpouseData(motherInitialData);
        }
        else if (userContext.loginDetail.familyRelationName === "MOTHER") {
            let fatherInitialData = {
                // profileImage: null,
                familyMemberId: 0,
                familyRelationName: "1",
                familyRelationCode: "FATHER",
                firstName: "",
                lastName: userContext.loginDetail.lastName,
                age: "",
                // isDeleted: 0
            };
            setSpouseData(fatherInitialData);
        }
    }

    const valildationSchema = Yup.object().shape({
        familyRelationName: Yup.number().required("Relation is required."),
        firstName: Yup.string().required("First Name is required."),
        lastName: Yup.string().required("Last Name is required."),
        age: Yup.string().required("Age is required.")
    });

    async function fetchFamilyRelation() {
        try {
            const famailyrelationData: any = await GetFamilyRelationList();
            if (famailyrelationData && famailyrelationData.data !== null && famailyrelationData.data.data !== null) {
                let famailyrelationCollection = famailyrelationData.data.data;
                setFamilyRelation(famailyrelationCollection);
            }
        }
        catch (error) {
            if (error) {
                updateUserContext({
                    ...userContext,
                    isAlert: true,
                    alertMessage: error as string,
                    alertType: AlertType.Error,
                });
            }
        }
    }


    const goToMaternalDetail = () => {
        navigate("/maternal-detail");
    };

    async function fetchData() {
        setLoading(true);
        try {
            let params = new URLSearchParams();
            params.append("familyMemberId", userContext.spouseFamilyMemberId.toString());
            const result: any = await GetFamilyMemberDetail(params);
            let editDetails = result.data.data;
            let updatedValue = {
                familyMemberId: editDetails.familyMemberId,
                // familyRelationName: editDetails.familyRelationName.toString(),
                familyRelationName: getNameSelected(
                    editDetails.familyRelationName,
                    familyRelation
                ),
                familyRelationCode: editDetails.familyRelationName,
                firstName: editDetails.firstName,
                lastName: editDetails.lastName,
                age: editDetails.age,
            };
            setServerImage(editDetails.serverImage)
            setProfileImageName(editDetails.profileName)
            setImage({ preview: editDetails.profileImageBase });
            setSpouseData(updatedValue);
        }
        catch (error) {
            updateUserContext({
                ...userContext,
                isAlert: true,
                // alertMessage: 'Something went wrong',
                alertMessage: error.message,
                alertType: AlertType.Error,
            });
        }
        setLoading(false);
    }

    async function SubmitSpouse(value: any) {
        setSubmitLoading(true);
        const formData = new FormData();
        const spouseData = value;
        let firstFamilyMember = userContext.loginDetail.lastName === '' && userContext.loginDetail.firstName === '' ? true : false;
        formData.append('familyMemberId', spouseData.familyMemberId);
        formData.append('familyRelationName', spouseData.familyRelationCode);
        formData.append('familyRelationCode', spouseData.familyRelationCode);
        formData.append('firstName', spouseData.firstName);
        formData.append('lastName', spouseData.lastName);
        formData.append('age', spouseData.age);
        formData.append('gender', spouseData.familyRelationCode === 'FATHER' ? 'MALE' : spouseData.familyRelationCode === 'MOTHER' ? 'FEMALE' : 'MALE');
        formData.append('familyId', userContext.loginDetail.userId.toString());
        formData.append('dataManagedBy', userContext.loginDetail.userId.toString());
        formData.append('profileImage', profileImageFile);
        formData.append('profileName', profileImageName);
        formData.append('serverImage', serverImage);
        formData.append('firstFamilyMember', firstFamilyMember);

        if (spouseData.familyMemberId > 0) {
            const resultSpouseSave: any = await UpdateParent(formData);
            try {
                if (resultSpouseSave.data !== null) {
                    // let loginDetail = userContext.loginDetail;
                    setSubmitLoading(false);
                    // loginDetail.familyId = resultSpouseSave.data.familyId;
                    // loginDetail.familyMemberId = resultSpouseSave.data.familyMemberId;
                    // loginDetail.familyRelationName = resultSpouseSave.data.familyRelationName;

                    updateUserContext({
                        ...userContext,
                        spouseFamilyMemberId: resultSpouseSave.data.familyMemberId,
                        isAlert: true,
                        alertMessage: resultSpouseSave.message,
                        alertType: AlertType.Success,
                    });
                    setSubmitLoading(false);
                    if (userContext.stage === "PARENTS") {
                        navigate("/child-detail");
                    }
                    else {
                        goToMaternalDetail();
                    }

                } else {
                    updateUserContext({
                        ...userContext,
                        isAlert: true,
                        alertMessage: resultSpouseSave.message,
                        alertType: AlertType.Error,
                    });
                }
            } catch (e: any) {
                updateUserContext({
                    ...userContext,
                    isAlert: true,
                    alertMessage: e.response.message,
                    alertType: AlertType.Error,
                });
            }
        }
        else {
            try {
                const resultSpouseSave: any = await CreateParent(formData);
                if (resultSpouseSave && resultSpouseSave.data !== null) {
                    let familyData = resultSpouseSave.data.familyData;
                    updateUserContext({
                        ...userContext,
                        spouseFamilyMemberId: familyData.familyMemberId,
                        currFamilyMemberId: familyData.familyRelationName === 'MOTHER' ? familyData.familyMemberId : userContext.currFamilyMemberId,
                        isAlert: true,
                        alertMessage: resultSpouseSave.message,
                        alertType: AlertType.Success,
                    });
                    setSubmitLoading(false);
                    if (userContext.stage === "PARENTS") {
                        navigate("/child-detail");
                    }
                    else {
                        goToMaternalDetail();
                    }
                }
                else {
                    setSubmitLoading(false);
                    if (userContext.stage === "PARENTS") {
                        navigate("/child-detail");
                    }
                    else {
                        goToMaternalDetail();
                    }
                }
            } catch (error) {
                setSubmitLoading(false);
                if (error) {
                    updateUserContext({
                        ...userContext,
                        isAlert: true,
                        alertMessage: error as string,
                        alertType: AlertType.Error,
                    });
                }
            }
        }
    }

    useEffect(() => {
        fetchFamilyRelation();
        setDefaultRole();
    }, []);

    useEffect(() => {
        if (userContext.loginDetail !== undefined && userContext.spouseFamilyMemberId > 0) {
            fetchData();
        }
    }, [familyRelation]);

    return (
        <Fade in={true} timeout={{ enter: 500 }}>
            <Box className="spouse-main-box">
                <Box className="head-box">
                    <Box className="back-box">
                        <IconButton aria-label="back" className='back-icon' disableRipple
                            onClick={() => backClick()}>
                            <img src={backIcon} alt='logo' />
                        </IconButton>
                    </Box>
                    <Box className="title-box">
                        <Typography className="title-text">Spouse</Typography>
                    </Box>
                    <Box className="skip-box">
                        {userContext.loginDetail.familyRelationName === 'FATHER' ?
                            <></> :
                            userContext.isAddMember === true ?
                                <></> : <Typography onClick={() => goToSkip()}>skip</Typography>
                        }

                    </Box>

                </Box>
                <Formik
                    enableReinitialize
                    initialValues={spouseData}
                    onSubmit={(values) => {
                        SubmitSpouse(values);
                    }}
                    validationSchema={valildationSchema}
                >
                    {(props) => {
                        return (
                            <Form>
                                <Box className="body-box">
                                    <Grid
                                        container
                                        spacing={2}
                                        padding={2}>

                                        <Grid item xs={12} className='profile-grid'>
                                            {loading ?
                                                <Skeleton animation="wave" variant="rounded" width={160} height={160} sx={{ borderRadius: "50%" }} /> :
                                                <Box className="img-box">
                                                    <Box className="set-img-box">
                                                        <Avatar className='img-preview' alt="logo" src={image.preview} />
                                                    </Box>
                                                    <Box className='icon-box'>
                                                        <input
                                                            type="file"
                                                            id="upload-button"
                                                            style={{ display: "none" }}
                                                            onChange={handleChange}
                                                        />
                                                        <label htmlFor="upload-button" className='img-lable'>
                                                            <SmallAvatar alt="logo" src={cameraIcon} className='camera-icon' />
                                                        </label>
                                                    </Box>
                                                </Box>}
                                        </Grid>

                                        <Grid item xs={12} md={12} sm={12} zIndex={9}>
                                            <FormControl fullWidth style={{ width: '100%' }}>
                                                {loading ?
                                                    <Skeleton animation="wave" variant="rounded" width='auto' height={45} /> :
                                                    <Select
                                                        // isDisabled={userContext.loginDetail.familyRelationName === 'FATHER' && userContext.stage === 'EXPECTING' ? true : false}
                                                        isDisabled={true}
                                                        id="familyRelationName"
                                                        classNamePrefix="select"
                                                        styles={
                                                            props.values.familyRelationName === "" &&
                                                                props.errors.familyRelationName &&
                                                                props.touched.familyRelationName
                                                                ? selectStyles
                                                                : undefined
                                                        }
                                                        isClearable={true}
                                                        isSearchable={true}
                                                        placeholder={"Role"}
                                                        theme={(theme) => ({
                                                            ...theme,
                                                            colors: {
                                                                ...theme.colors,
                                                                primary: "#0B7CAA",
                                                                primary25: "#F1F6FE",
                                                                primary50: "#e1dfea",
                                                            },
                                                        })}

                                                        value={getSingleSelected(
                                                            props.values.familyRelationName,
                                                            familyRelation
                                                        )}
                                                        onChange={(e, value) => {
                                                            props.setFieldValue(
                                                                "familyRelationName",
                                                                e !== null ? e.value : "",
                                                                false
                                                            );
                                                            props.setFieldValue(
                                                                "familyRelationCode",
                                                                e.code,
                                                                true
                                                            );
                                                        }}
                                                        options={filteredList}
                                                        className={
                                                            props.values.familyRelationName === "" &&
                                                                props.errors.familyRelationName &&
                                                                props.touched.familyRelationName
                                                                ? "text-input"
                                                                : "text-input"
                                                        }
                                                    />}
                                                {props.values.familyRelationName === "" &&
                                                    props.errors.familyRelationName &&
                                                    props.touched.familyRelationName && (
                                                        <Typography
                                                            className={
                                                                props.values.familyRelationName === "" &&
                                                                    props.errors.familyRelationName &&
                                                                    props.touched.familyRelationName
                                                                    ? "validation-message text-input"
                                                                    : "text-input"
                                                            }
                                                        >
                                                            {props.errors.familyRelationName}
                                                        </Typography>
                                                    )}
                                            </FormControl>
                                        </Grid>

                                        <Grid item xs={12} md={12} sm={12}>
                                            <FormControl fullWidth>
                                                {loading ?
                                                    <Skeleton animation="wave" variant="rounded" width='auto' height={45} /> :
                                                    <TextField
                                                        // required
                                                        id="firstName"
                                                        label="First Name"
                                                        fullWidth
                                                        size="small"
                                                        onChange={(e: any) => {
                                                            props.setFieldValue(
                                                                "firstName",
                                                                e.target.value !== null ? e.target.value : "",
                                                                true
                                                            );
                                                        }}
                                                        onBlur={props.handleBlur}
                                                        value={props.values.firstName}
                                                        inputProps={{ maxLength: 200 }}
                                                        variant="outlined"
                                                        error={
                                                            props.values.firstName === "" &&
                                                                props.errors.firstName &&
                                                                props.touched.firstName
                                                                ? true
                                                                : false
                                                        }
                                                        className={
                                                            props.values.firstName === "" &&
                                                                props.errors.firstName &&
                                                                props.touched.firstName
                                                                ? "text-input validation-message"
                                                                : "text-input"
                                                        }
                                                    />}
                                                {props.values.firstName === "" && props.errors.firstName &&
                                                    props.touched.firstName && (
                                                        <Typography
                                                            className={
                                                                props.values.firstName === "" &&
                                                                    props.errors.firstName &&
                                                                    props.touched.firstName
                                                                    ? "validation-message"
                                                                    : "validation-message"
                                                            }
                                                        >
                                                            {props.errors.firstName}
                                                        </Typography>
                                                    )}
                                            </FormControl>
                                        </Grid>

                                        <Grid item xs={12} md={12} sm={12}>
                                            <FormControl fullWidth>
                                                {loading ?
                                                    <Skeleton animation="wave" variant="rounded" width='auto' height={45} /> :
                                                    <TextField
                                                        // required
                                                        id="lastName"
                                                        label="Last Name"
                                                        fullWidth
                                                        size="small"
                                                        onChange={(e: any) => {
                                                            props.setFieldValue(
                                                                "lastName",
                                                                e.target.value !== null ? e.target.value : "",
                                                                true
                                                            );
                                                        }}
                                                        onBlur={props.handleBlur}
                                                        value={props.values.lastName}
                                                        inputProps={{ maxLength: 200 }}
                                                        variant="outlined"
                                                        error={
                                                            props.values.lastName === "" &&
                                                                props.errors.lastName &&
                                                                props.touched.lastName
                                                                ? true
                                                                : false
                                                        }
                                                        className={
                                                            props.values.lastName === "" &&
                                                                props.errors.lastName &&
                                                                props.touched.lastName
                                                                ? "text-input validation-message"
                                                                : "text-input"
                                                        }
                                                    />}
                                                {props.values.lastName === "" && props.errors.lastName &&
                                                    props.touched.lastName && (
                                                        <Typography
                                                            className={
                                                                props.values.lastName === "" &&
                                                                    props.errors.lastName &&
                                                                    props.touched.lastName
                                                                    ? "validation-message"
                                                                    : "validation-message"
                                                            }
                                                        >
                                                            {props.errors.lastName}
                                                        </Typography>
                                                    )}
                                            </FormControl>
                                        </Grid>

                                        <Grid item xs={12} md={12} sm={12}>
                                            <FormControl fullWidth>
                                                {loading ?
                                                    <Skeleton animation="wave" variant="rounded" width='auto' height={45} /> :
                                                    <TextField
                                                        // required
                                                        id="age"
                                                        type="number"
                                                        label="Age"
                                                        fullWidth
                                                        size="small"
                                                        onChange={(e: any) => {
                                                            props.setFieldValue(
                                                                "age",
                                                                e.target.value !== null ? e.target.value : "",
                                                                true
                                                            );
                                                        }}
                                                        onBlur={props.handleBlur}
                                                        value={props.values.age}
                                                        inputProps={{ maxLength: 200 }}
                                                        variant="outlined"
                                                        error={
                                                            props.values.age === "" &&
                                                                props.errors.age &&
                                                                props.touched.age
                                                                ? true
                                                                : false
                                                        }
                                                        className={
                                                            props.values.age === "" && props.errors.age &&
                                                                props.touched.age
                                                                ? "text-input validation-message"
                                                                : "text-input"
                                                        }
                                                    />}
                                                {props.values.age === "" && props.errors.age &&
                                                    props.touched.age && (
                                                        <Typography
                                                            className={
                                                                props.values.age === "" &&
                                                                    props.errors.age &&
                                                                    props.touched.age
                                                                    ? "validation-message"
                                                                    : "validation-message"
                                                            }
                                                        >
                                                            {props.errors.age}
                                                        </Typography>
                                                    )}
                                            </FormControl>
                                        </Grid>

                                        <Box className="bottom-box">
                                            <CustomButton
                                                disabled={false}
                                                class="add-button"
                                                isLoading={submitLoading}
                                                title='Save'
                                            />
                                        </Box>

                                    </Grid>
                                </Box>
                            </Form>
                        )
                    }}
                </Formik>
            </Box>
        </Fade>
    );
}

const selectStyles = {
    control: (provided: any, state: any) => ({
        ...provided,
        border: state.isFocused ? "1px solid #0B7CAA" : "1px solid #d32f2f",
    }),
    placeholder: (provided, state) => ({
        ...provided,
        color: state.isFocused ? state.selectProps.color : "#d32f2f",
    }),
};