import { GET, POST } from "./common/http";
import { BASE_URL } from "./common/const";

export const GetFetalWeightDetailForChart = (parmas: any) =>
    GET(`${BASE_URL}/api/FetalGrowth/GetFetalWeightDetailForChart?` + parmas, null);

export const CreateFetalWeightDetail = (data: any) =>
    POST(`${BASE_URL}/api/FetalGrowth/CreateMemberFetalWeightDetail`, data);

export const GetMemberFetalWeightDetailList = (parmas: any) =>
    GET(`${BASE_URL}/api/FetalGrowth/GetMemberFetalWeightDetailList?` + parmas, null);
