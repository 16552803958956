import React, { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import './Vaccination.scss';
import { Box, IconButton, Tab, Tabs, Typography, styled, Link, Button, Skeleton, Stack } from '@mui/material';
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { PreferencesContext } from '../../PreferenceContext';
import { GetMemberVaccineList } from '../../services/VaccinationService';
import { LocalizationProvider } from '@mui/x-date-pickers';
import dayjs, { Dayjs } from "dayjs";
import injectionIcon from '../../assets/icons/injection.svg';
import backIcon from '../../assets/icons/back.svg';
import noData from "../../assets/icons/no-data.svg";
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import utc from "dayjs/plugin/utc";
import ReactTimeAgo from "react-time-ago";
import TimeAgo from "javascript-time-ago";
import en from "javascript-time-ago/locale/en.json";
import _ from 'lodash';
import NoDataAvailable from '../../components/NoDataAvailable/NoDataAvailable';
dayjs.extend(utc);
TimeAgo.addDefaultLocale(en);

interface StyledTabsProps {
    children?: React.ReactNode;
    value: number;
    onChange: (event: React.SyntheticEvent, newValue: number) => void;
}


const StyledTabs = styled((props: StyledTabsProps) => (
    <Tabs
        {...props}
        // TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
        TabIndicatorProps={{
            children: <span className="MuiTabs-indicatorSpan" />,
            style: { bottom: '8px', height: '35px', borderRadius: '8px', zIndex: 1 },
            sx: {
                height: 5,
            },
        }}
    />
))({
    "& .MuiTabs-indicator": {
        // display: "flex",
        // justifyContent: "center",
        // backgroundColor: "transparent",
    },
    "& .MuiTabs-indicatorSpan": {
        // maxWidth: 40,
        // width: "100%",
        // backgroundColor: "",
    },
});

function tabProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}
const StyledTab = styled((props: StyledTabProps) => (
    <Tab disableRipple {...props} />
))(({ theme }) => ({
    textTransform: "none",
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.pxToRem(15),
    minHeight: "35px",
    color: "#000",
    transition: ' all 350ms',
    marginLeft: '5px',
    marginRight: '5px',
    "&.Mui-selected": {
        color: "#fff",
    },
    "&.MuiTabs-indicator": {
        backgroundColor: "#0B7CAA",
    },
    "&.Mui-focusVisible": {
        backgroundColor: "rgba(0, 0, 0, 0)",
    },
}));

interface StyledTabProps {
    label: string;
}

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function VaccinationTabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
            style={{ width: "100%" }}>
            {value === index && (
                <Box className="selected-tab">
                    {children}
                </Box>
            )}
        </div>
    );
}

export default function Vaccination() {
    const [loading, setLoading] = useState(false);
    const { userContext, updateUserContext } = useContext(PreferencesContext);
    const [resultData, setResultData] = useState({
        schedualedVaccineData: [],
        historyResultData: []
    });
    const [memberHistoryData, setMemberHistoryData] = useState([]);
    const [intSchedualedvaccineData, setIntSchedualedvaccineData] = useState([]);
    const [value, setValue] = useState(0);
    const [showAlert, setAlertMessage] = useState({
        isSuccess: false,
        message: "",
    });
    const location = useLocation();

    let navigate = useNavigate();
    function backClick() {
        navigate("/dashboard");
    }

    function editClick(props: any, isSchedual: any) {
        let vaccinationName = props.vaccinationName.split('-')[0];
        // const vaccinationName: any = [];
        // let splitname: any;
        // let vaccineArr: any = props.vaccinationName.split(',').forEach(function (data: any, index: any) {
        //     splitname = data.split('-')[0];
        //     vaccinationName.push(splitname);
        // });

        if (isSchedual) {
            if (props.status === 'Current due' || props.status === 'Overdue') {
                navigate('/vaccination-detail',
                    { state: { memberVaccinationDetailId: props.memberVaccinationDetailId, vaccinationName: vaccinationName, subVaccinationId: props.subVaccinationId, activeTab: value, isSchedual, missDoseLimit: props.missDoseLimit, vaccineSchedualDate: props.schedualOn, isEditVaccine: isSchedual } }
                );
            }
        }
        else {
            navigate('/vaccination-detail',
                { state: { memberVaccinationDetailId: props.memberVaccinationDetailId, vaccinationName: vaccinationName, subVaccinationId: props.subVaccinationId, activeTab: value, isSchedual, missDoseLimit: props.missDoseLimit, vaccineSchedualDate: props.schedualOn, isEditVaccine: isSchedual } }
            );
        }
    }

    function saveClick() {
        navigate('/vaccination-detail',
            { state: { activeTab: value, customAdd: true } }
        );
    }
    const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };


    async function fetchMemberVaccineData() {
        setLoading(true);
        try {
            let params = new URLSearchParams();
            params.append("familyId", userContext.loginDetail.familyId.toString());
            params.append("familyMemberId", userContext.memberDetail.familyMemberId.toString());
            const result: any = await GetMemberVaccineList(params);
            let memberVaccineData = result.data.data;

            setResultData({
                ...resultData,
                schedualedVaccineData: memberVaccineData.vaccineCurrentDetail,
                historyResultData: memberVaccineData.vaccineHistoryDetail,
            });
            setIntSchedualedvaccineData(memberVaccineData.vaccineCurrentDetail);
            setMemberHistoryData(memberVaccineData.vaccineHistoryDetail);
        } catch (error) {
            setLoading(false);
            if (error) {
                setAlertMessage({
                    isSuccess: true,
                    message: error as string,
                });
            }
        }
        setLoading(false);
    }

    useEffect(() => {
        if (userContext.memberDetail !== undefined && userContext.memberDetail.familyMemberId > 0) {
            fetchMemberVaccineData();
        }
        if (location.state !== '' && location.state !== undefined && location.state !== null) {
        }

        const search = window.location.search;
        const params = new URLSearchParams(search);
        const urlRemoveLocalStorage = params.get('Add');
        if (urlRemoveLocalStorage !== null && urlRemoveLocalStorage === "1") {
            saveClick();
        }
    }, []);

    const parseDateString = (dateString: any) => {
        const [datePart, timePart] = dateString.split(' ');
        const [year, month, day] = datePart.split('-').map(Number);
        const [hour, minute, second] = timePart.split(':').map(Number);
        return new Date(year, month - 1, day, hour, minute, second);
    };

    // const formatDateToDDMMYYYY = (date) => {
    //     console.log('date',date)
    //     const day = String(date.getDate()).padStart(2, '0');
    //     const month = String(date.getMonth() + 1).padStart(2, '0');
    //     const year = date.getFullYear();
    //     return `${day}/${month}/${year}`;
    // };

    useEffect(() => {
        setIntSchedualedvaccineData(resultData.schedualedVaccineData);
        setMemberHistoryData(resultData.historyResultData);
    }, [value])

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Box className="vaccination-diseases-main-box">
                <Box className="main-head-box sticky">
                    <Box className="head-box">
                        <Box className="back-box">
                            <IconButton aria-label="back" className='back-icon' disableRipple
                                onClick={() => backClick()}>
                                <img src={backIcon} alt='logo' />
                            </IconButton>
                        </Box>
                        <Box className="title-box">
                            <Typography className="title-text">Vaccination </Typography>
                        </Box>
                        {memberHistoryData.length > 0 ?
                            <Box className="add-box" onClick={saveClick}>
                                <IconButton
                                    color="inherit"
                                    className="icon-button"
                                    disableRipple >
                                    <AddOutlinedIcon color="primary" />
                                </IconButton>
                                <Typography
                                    variant="body2"
                                    color='primary'
                                    className="add-text">
                                    Add
                                </Typography>
                            </Box> : <Box className="add-box"></Box>}
                    </Box>

                </Box>

                <Box className="vaccination-tabs-main-box">
                    <Box className="tabs-head-box">
                        <StyledTabs
                            value={value}
                            onChange={handleTabChange}
                            aria-label="styled tabs example">
                            <StyledTab label="Current" {...tabProps(0)} />
                            <StyledTab label="History" {...tabProps(1)} />
                        </StyledTabs>
                    </Box>
                    <Box className="tabs-body-box">

                        <VaccinationTabPanel value={value} index={0}>
                            {intSchedualedvaccineData !== undefined && intSchedualedvaccineData.length > 0 ?
                                (intSchedualedvaccineData.map((item: any, index: any) => (
                                    <Box key={index} className={item.status === 'Overdue' ? "current-main-box border-box" : ((item.status === 'Current due' || item.status === 'Overdue') ? 'current-main-box' : "current-main-box disabled-box")}
                                        onClick={() => editClick(item, true)}>
                                        <Box className="left-part-box"><img src={injectionIcon} alt='logo' /></Box>
                                        <Box className="right-part-box">
                                            <Box className="upper-part-box">
                                                <Box className="upper-box">
                                                    {item.doseName !== '' ?
                                                        <Typography className="upper-text">
                                                            {(item.vaccinationName + " - " + item.doseName).includes(null) ? (item.vaccinationName + " - " + item.doseName).replace(/null/g, '').replace(/-$/, '') : item.vaccinationName + " - " + item.doseName}
                                                        </Typography>
                                                        : <Typography className="upper-text">
                                                            {item.vaccinationName} - {item.status}
                                                        </Typography>}
                                                </Box>
                                            </Box>
                                            <Box className="lower-part-box">
                                                <Box className="date-part-box">
                                                    <Typography className="lower-date-text" >
                                                        {/* {new Date(item.schedualOn).toLocaleDateString('en-GB')} */}
                                                        {!isNaN(parseDateString(item.schedualOn)) ? (parseDateString(item.schedualOn).toLocaleDateString('en-GB', {
                                                            year: 'numeric',
                                                            month: '2-digit',
                                                            day: '2-digit',
                                                            // hour: '2-digit',
                                                            // minute: '2-digit',
                                                            // second: '2-digit',
                                                        }).replace(',', ' ')) : 'invalid'}
                                                    </Typography>
                                                </Box>
                                                <Box className={item.status === 'Overdue' ?
                                                    'status-box due-box' :
                                                    (item.status === 'Upcoming'
                                                        ? 'status-box upcoming-box'
                                                        : (item.status === 'Current due' ?
                                                            'status-box current-due-box' :
                                                            (item.status === 'Next due' ? 'status-box next-due-box' : 'status-box')))}>
                                                    <Typography
                                                        className={
                                                            item.status === 'Overdue' ?
                                                                'lower-status-text due-text' :
                                                                (item.status === 'Upcoming' ?
                                                                    'lower-status-text upcoming-text' :
                                                                    (item.status === 'Current due' ?
                                                                        'lower-status-text current-due-text'
                                                                        : (item.status === 'Next due' ? 'lower-status-text next-due-text' : 'lower-status-text')))}>
                                                        {item.status}</Typography>
                                                </Box>
                                            </Box>
                                        </Box>
                                    </Box>
                                )))
                                :
                                (_.times(3, (i) => (
                                    <Stack spacing={1} key={i} sx={{ marginBottom: '15px', width: '100%' }}>
                                        <Skeleton animation="wave" variant="rounded" width='100%' height={65} />
                                    </Stack>
                                )))
                            }
                        </VaccinationTabPanel>

                        <VaccinationTabPanel value={value} index={1}>
                            {memberHistoryData !== undefined && memberHistoryData.length > 0 ?
                                (memberHistoryData.map((item: any, index: any) => (
                                    <Box className="main-history-box" key={index}
                                        onClick={() => editClick(item, false)}>
                                        <Box className="left-part-box">
                                            <img src={injectionIcon} alt='logo' />
                                        </Box>
                                        <Box className="right-part-box">
                                            <Box className="upper-part-box">
                                                <Box className="upper-box">
                                                    <Typography className='upper-text'>{item.vaccinationName}</Typography>
                                                </Box>
                                            </Box>
                                            <Box className="lower-part-box">
                                                <Box className="date-part-box">
                                                    <Typography className='lower-date-text'>
                                                        {/* {item.givenOn === "" ? "" : new Date(item.givenOn).toLocaleDateString('en-GB')} */}

                                                        {item.givenOn === "" ? "" : (!isNaN(parseDateString(item.givenOn)) ? (parseDateString(item.givenOn).toLocaleDateString('en-GB', {
                                                            year: 'numeric',
                                                            month: '2-digit',
                                                            day: '2-digit',
                                                            // hour: '2-digit',
                                                            // minute: '2-digit',
                                                            // second: '2-digit',
                                                        }).replace(',', ' ')) : 'invalid')}
                                                    </Typography></Box>
                                                <Box className="time-part-box">
                                                    <ReactTimeAgo className='lower-time-text'
                                                        date={new Date(dayjs.utc(item.modifiedDate === "" ? item.createdDate : item.modifiedDate).format())}
                                                        future={false}
                                                        // now={now}
                                                        locale="en-US" />
                                                </Box>
                                            </Box>
                                        </Box>
                                    </Box>)))
                                : (
                                    <NoDataAvailable
                                        submoduleName={'Vaccine'}
                                        memberName={userContext.memberDetail.firstName}
                                        submoduleClick={saveClick}
                                        ageCount={-1}
                                    />
                                )
                            }
                        </VaccinationTabPanel>

                    </Box>
                </Box>
            </Box>
        </LocalizationProvider>
    )
}