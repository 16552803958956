import React, { useContext, useEffect, useState } from "react";
import "./MidArmCircumference.scss";
import styles from "../PhysicalGrowth/BottomSheetStyles.module.css";
import { useNavigate } from "react-router-dom";
import {
  Backdrop,
  Box,
  Fade,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  Link,
  OutlinedInput,
  Skeleton,
  Typography,
} from "@mui/material";
import backIcon from "../../assets/icons/back.svg";
import heightIcon from "../../assets/icons/physical-growth.svg";
import { PreferencesContext } from "../../PreferenceContext";
import {
  CreateMACDetail,
  GetMACDetailForChart,
  GetMemberMACDetailList,
} from "../../services/PhysicalGrowthService";
import { useDrag } from "@use-gesture/react";
import { a, useSpring, config } from "@react-spring/web";
import CustomButton from "../../components/CustomButton/CustomButton";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { AlertType } from "../../shared/AlertType";
import CustomTabs from "../../components/CustomTabs/CustomTabs";
import { ageInDays } from "../../shared/CommonFuctions";

const formInitialValues = {
  macDetailId: 0,
  mac: "",
};
export default function MidArmCircumference() {
  const { userContext, updateUserContext } = useContext(PreferencesContext);
  const [loading, setLoading] = useState(false);
  const [macChartData, setMacChartData] = useState([]);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [isBottomSheetOpen, setIsBottomSheetOpen] = useState(false);
  const [memberMacChartData, setMemberMACChartData] = useState([]);
  const [macData, setMACData] = useState(formInitialValues);
  const [memberMacDetailData, setMemberMACDetailData] = useState([]);
  const [showAlert, setAlertMessage] = useState({
    isSuccess: false,
    message: "",
  });

  const navigate = useNavigate();

  function backClick() {
    navigate("/physical-growth");
  }
  const midOptions = {
    color: [
      "#FF0000",
      "#FF8000",
      "#FFED00",
      "#16FF00",
      "#FFED00",
      "#FF8000",
      "#FF0000",
      "#000000",
    ],
    tooltip: {
      trigger: "axis",
      axisPointer: {
        type: "cross",
        label: {
          backgroundColor: "#6a7985",
        },
      },
    },
    grid: {
      // left: "3%",
      // right: "4%",
      // bottom: "3%",
      // containLabel: true,
      left: "2%",
      containLabel: true,
      bottom: 20,
      top: 20,
      right: "2%"
    },

    xAxis: [
      {
        type: "category",
        boundaryGap: false,
        // name: "Days",
        // nameLocation: "middle",
        // nameGap: 15,
        data:
          macChartData.length > 0 &&
          macChartData.map((row: any, index: any) => row.days),
      },
    ],
    yAxis: [
      {
        type: "value",
        // name: "MAC in cm",
        // nameGap: 30,
        axisLine: {
          onZero: false,
        },
        nameTextStyle: {
          padding: [0, 0, 0, 20],
        },
        min: "dataMin",
      },
    ],
    series: [
      {
        name: "P97",
        type: "line",
        silent: true,
        smooth: true,
        lineStyle: {
          width: 0,
        },
        showSymbol: false,
        areaStyle: {
          opacity: 0.8,
        },
        emphasis: {
          focus: "series",
        },
        data:
          macChartData.length > 0 &&
          macChartData.map((row: any, index: any) => row.p97),
      },
      {
        name: "P90",
        type: "line",
        silent: true,
        smooth: true,
        lineStyle: {
          width: 0,
        },
        showSymbol: false,
        areaStyle: {
          opacity: 0.8,
        },
        emphasis: {
          focus: "series",
        },
        data:
          macChartData.length > 0 &&
          macChartData.map((row: any, index: any) => row.p90),
      },
      {
        name: "P75",
        type: "line",
        silent: true,
        smooth: true,
        lineStyle: {
          width: 0,
        },
        showSymbol: false,
        areaStyle: {
          opacity: 0.8,
        },
        emphasis: {
          focus: "series",
        },
        data:
          macChartData.length > 0 &&
          macChartData.map((row: any, index: any) => row.p75),
      },
      {
        name: "P50",
        type: "line",
        silent: true,
        smooth: true,
        lineStyle: {
          width: 0,
        },
        showSymbol: false,
        areaStyle: {
          opacity: 0.8,
        },
        emphasis: {
          focus: "series",
        },
        data:
          macChartData.length > 0 &&
          macChartData.map((row: any, index: any) => row.p50),
      },
      {
        name: "P25",
        type: "line",
        silent: true,
        smooth: true,
        lineStyle: {
          width: 0,
        },
        showSymbol: false,
        label: {
          show: true,
          position: "top",
        },
        areaStyle: {
          opacity: 0.8,
        },
        emphasis: {
          focus: "series",
        },
        data:
          macChartData.length > 0 &&
          macChartData.map((row: any, index: any) => row.p25),
      },
      {
        name: "P10",
        type: "line",
        silent: true,
        smooth: true,
        lineStyle: {
          width: 0,
        },
        showSymbol: false,
        label: {
          show: true,
          position: "top",
        },
        areaStyle: {
          opacity: 0.8,
        },
        emphasis: {
          focus: "series",
        },
        data:
          macChartData.length > 0 &&
          macChartData.map((row: any, index: any) => row.p10),
      },
      {
        name: "P3",
        type: "line",
        silent: true,
        smooth: true,
        lineStyle: {
          width: 0,
        },
        showSymbol: false,
        label: {
          show: true,
          position: "top",
        },
        areaStyle: {
          opacity: 0.8,
        },
        emphasis: {
          focus: "series",
        },
        data:
          macChartData.length > 0 &&
          macChartData.map((row: any, index: any) => row.p3),
      },
      {
        name: "user",
        type: "line",
        lineStyle: {
          width: 2,
        },
        label: {
          show: true,
          position: "top",
        },

        emphasis: {
          focus: "series",
        },
        data:
          macChartData.length > 0 &&
          macChartData.map((row: any, index: any) => row.mac),
      },
    ],
  };

  const valildationSchema = Yup.object().shape({
    mac: Yup.number().required("Mid Arm Circumference is required."),
  });

  const height = 175;

  const [{ y }, api] = useSpring(() => ({ y: height }));

  const openMACSheet = () => {
    setIsBottomSheetOpen(true);
    // when cancel is true, it means that the user passed the upwards threshold
    // so we change the spring config to create a nice wobbly effect
    api.start({
      y: 0,
      immediate: false,
      // config: canceled ? config.wobbly : config.stiff
    });
  };

  const close = (velocity = 0) => {
    setIsBottomSheetOpen(false);
    api.start({
      y: height,
      immediate: false,
      config: { ...config.stiff, velocity },
    });
  };

  const bind = useDrag(
    ({
      last,
      velocity: [, vy],
      direction: [, dy],
      offset: [, oy],
      cancel,
      canceled,
    }) => {
      // if the user drags up passed a threshold, then we cancel
      // the drag so that the sheet resets to its open position
      if (oy < -70) cancel();

      // when the user releases the sheet, we check whether it passed
      // the threshold for it to close, or if we reset it to its open positino
      if (last) {
        oy > height * 0.5 || (vy > 0.5 && dy > 0)
          ? close(vy)
          : openMACSheet({ canceled });
      }
      // when the user keeps dragging, we just move the sheet according to
      // the cursor position
      else api.start({ y: oy, immediate: true });
    },
    {
      from: () => [0, y.get()],
      filterTaps: true,
      bounds: { top: 0 },
      rubberband: true,
    }
  );

  const display = y.to((py) => (py < height ? "block" : "none"));

  async function SubmitMAC(value: any) {
    setSubmitLoading(true);
    const days = ageInDays(userContext.memberDetail.birthDate);

    try {
      let macInitData = value;
      let SaveUpdateHeightData = {
        macDetailId: macInitData.macDetailId,
        familyId: userContext.loginDetail.familyId,
        familyMemberId: userContext.memberDetail.familyMemberId,
        userData: macInitData.mac,
        age: days,
        dataManagedBy: userContext.loginDetail.userId,
      };
      const resultParentSave: any = await CreateMACDetail(SaveUpdateHeightData);
      setSubmitLoading(false);
      setIsBottomSheetOpen(false);
      updateUserContext({
        ...userContext,
        isAlert: true,
        alertMessage: "Mid arm circumference Added",
        alertType: AlertType.Success,
      });
      fetchMACData();
    } catch (e: any) {
      updateUserContext({
        ...userContext,
        isAlert: true,
        alertMessage: e.message,
        alertType: AlertType.Error,
      });
    }
  }

  async function fetchData() {
    let days = ageInDays(userContext.memberDetail.birthDate);
    const months = 0;
    try {
      let params = new URLSearchParams();
      params.append("days", days.toString());
      params.append("months", months.toString());
      params.append("gender", userContext.memberDetail.gender);
      params.append("familyMemberId", userContext.memberDetail.familyMemberId.toString());
      const result: any = await GetMACDetailForChart(params);
      let chartdata = result.data.data;
      setMacChartData(chartdata);
    } catch (error) {
      setLoading(false);
      if (error) {
        setAlertMessage({
          isSuccess: true,
          message: error as string,
        });
      }
    }
    setLoading(false);
  }

  async function fetchMACData() {
    setLoading(true);
    try {
      let params = new URLSearchParams();
      params.append("familyId", userContext.loginDetail.familyId.toString());
      params.append(
        "familyMemberId",
        userContext.memberDetail.familyMemberId.toString()
      );
      const result: any = await GetMemberMACDetailList(params);
      let memberMacData = result.data.data;
      setMemberMACDetailData(memberMacData);
    } catch (error) {
      setLoading(false);
      if (error) {
        setAlertMessage({
          isSuccess: true,
          message: error as string,
        });
      }
    }
    setLoading(false);
  }

  useEffect(() => {
    if (
      userContext.memberDetail !== undefined &&
      userContext.memberDetail.familyMemberId > 0
    ) {
      fetchMACData();
    }
  }, []);

  useEffect(() => {
    if (userContext.memberDetail !== undefined && userContext.memberDetail.familyMemberId > 0) {
      fetchData();
    }
  }, [memberMacDetailData]);

  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: 4 }}
        invisible={true}
        open={isBottomSheetOpen}
        onClick={() => close()}
      ></Backdrop>
      <Fade in={true} timeout={{ enter: 500 }}>
        <Box className="mac-main-box">
          <Box className="head-box">
            <Box className="back-box">
              <IconButton
                aria-label="back"
                className="back-icon"
                disableRipple
                onClick={() => backClick()}
              >
                <img src={backIcon} alt="logo" />
              </IconButton>
            </Box>
            <Box className="title-box">
              <Typography className="title-text">
                Mid Arm Circumference
              </Typography>
            </Box>
            <Box className="add-box">
              {memberMacDetailData.length > 0 ? (
                <Link
                  component="button"
                  variant="body2"
                  className="add-text"
                  onClick={openMACSheet}
                >
                  Add
                </Link>
              ) : (
                <></>
              )}
            </Box>
          </Box>
          <Box className="body-box">
            <CustomTabs
              icon={heightIcon}
              option={midOptions}
              growthData={memberMacDetailData}
              onClick={openMACSheet}
              dataLength={macChartData.length}
              page="MAC"
              yAxisLabel='MAC in cm'
              xAxisLabel='Days'
            />
          </Box>

          {isBottomSheetOpen === true ? (
            <Box className="mac-bottom-sheet-box" style={{ overflow: "hidden" }}>
              <a.div
                // onClick={() => close()}
                className={styles.sheet}
                {...bind()}
                style={{ display, bottom: `calc(-100vh + ${height}px)`, y }}
              >
                <Box className="top-bar"></Box>

                <Formik
                  enableReinitialize
                  initialValues={macData}
                  onSubmit={(values) => {
                    SubmitMAC(values);
                  }}
                  validationSchema={valildationSchema}
                >
                  {(props) => {
                    return (
                      <Form>
                        <Box className="form-box">
                          <Grid container spacing={2} padding={2}>
                            <Grid item xs={12} md={12} sm={12}>
                              <Box className="input-box">
                                <FormControl fullWidth>
                                  {submitLoading ? (
                                    <Skeleton
                                      animation="wave"
                                      variant="rounded"
                                      width="auto"
                                      height={45}
                                    />
                                  ) : (
                                    <>
                                      <InputLabel htmlFor="outlined-adornment-password">
                                        Mid Arm Circumference
                                      </InputLabel>
                                      <OutlinedInput
                                        id="mac"
                                        fullWidth
                                        type="number"
                                        endAdornment={
                                          <InputAdornment position="end">
                                            <Typography>cm</Typography>
                                          </InputAdornment>
                                        }
                                        onChange={(e: any) => {
                                          props.setFieldValue(
                                            "mac",
                                            e.target.value !== null
                                              ? e.target.value
                                              : "",
                                            true
                                          );
                                        }}
                                        onBlur={props.handleBlur}
                                        value={props.values.mac}
                                        inputProps={{ maxLength: 200 }}
                                        variant="outlined"
                                        error={
                                          props.values.mac === "" &&
                                            props.errors.mac &&
                                            props.touched.mac
                                            ? true
                                            : false
                                        }
                                        className={
                                          props.values.mac === "" &&
                                            props.errors.mac &&
                                            props.touched.mac
                                            ? "text-input validation-message"
                                            : "text-input"
                                        }
                                      />

                                      {props.values.mac === "" &&
                                        props.errors.mac &&
                                        props.touched.mac && (
                                          <Typography
                                            className={
                                              props.values.mac === "" &&
                                                props.errors.mac &&
                                                props.touched.mac
                                                ? "validation-message"
                                                : "validation-message"
                                            }
                                          >
                                            {props.errors.mac}
                                          </Typography>
                                        )}
                                    </>
                                  )}
                                </FormControl>
                              </Box>
                            </Grid>
                            {/* <Grid item xs={12} md={12} sm={12}>
                                                        <Typography>CM</Typography>
                                                    </Grid> */}

                            <Grid
                              item
                              xs={12}
                              md={12}
                              sm={12}
                              className="button-grid"
                            >
                              <Box className="bottom-box">
                                <CustomButton
                                  disabled={false}
                                  class="add-button"
                                  isLoading={submitLoading}
                                  title="Add"
                                />
                              </Box>
                            </Grid>
                          </Grid>
                        </Box>
                      </Form>
                    );
                  }}
                </Formik>
              </a.div>
            </Box>
          ) : (
            <></>
          )}
        </Box>
      </Fade>
    </>
  );
}
