import React from 'react';
import './AboutUs.scss';
import { useNavigate } from "react-router-dom";
import { Box, Card, Fade, Grid, IconButton, Typography } from '@mui/material';
import CustomBottomSheet from '../CustomBottomSheet/CustomBottomSheet';
import InfoRoundedIcon from '@mui/icons-material/InfoRounded';

export default function AboutUs() {

    let navigate = useNavigate();

    function openDeclaimer() {
        navigate('/declaimer');
    }

    function openPolicy() {
        navigate('/privacy-policy');
    }

    function openTerms() {
        navigate('/terms-condition');
    }

    return (
        <Fade in={true} timeout={{ enter: 500 }}>
            <Box className="about-main-box">
                <CustomBottomSheet>
                    <Box className="about-body">
                        <Box className="about-box">
                            <Typography className="text-name">About us</Typography>
                        </Box>
                        <Box className="about-list">
                            <Box className="about-item">
                                {/* <Grid container spacing={2} className='main-container-grid'>
                                    <Grid item xs={12} sm={3} md={4} className='subcontainer-grid'>
                                        <Card className='inner-card' onClick={() => openDeclaimer()}>
                                            <Box className="img-box">
                                                <IconButton
                                                    color="inherit"
                                                    aria-label="open drawer">
                                                    <InfoRoundedIcon />
                                                </IconButton>
                                            </Box>
                                            <Box className='card-upper-box' ><Typography className='upper-text'>Declaimer</Typography></Box>
                                        </Card>
                                    </Grid>
                                    <Grid item xs={12} sm={4} md={4} className='subcontainer-grid'>
                                        <Card className='inner-card' onClick={() => openPolicy()}>
                                            <Box className="img-box">
                                                <IconButton
                                                    color="inherit"
                                                    aria-label="open drawer">
                                                    <InfoRoundedIcon />
                                                </IconButton>
                                            </Box>
                                            <Box className='card-upper-box' ><Typography className='upper-text'>Privacy policy</Typography></Box>
                                        </Card>
                                    </Grid>
                                    <Grid item xs={12} sm={4} md={4} className='subcontainer-grid'>
                                        <Card className='inner-card' onClick={() => openTerms()}>
                                            <Box className="img-box">
                                                <IconButton
                                                    color="inherit"
                                                    aria-label="open drawer">
                                                    <InfoRoundedIcon />
                                                </IconButton>
                                            </Box>
                                            <Box className='card-upper-box' ><Typography className='upper-text'>Terms and condition</Typography></Box>
                                        </Card>
                                    </Grid>
                                </Grid> */}

                                <Grid container spacing={2} className='main-container-grid'>
                                    <Grid item xs={12} sm={6} md={4} className='subcontainer-grid'>
                                        <Card className='inner-card' onClick={() => openDeclaimer()}>
                                            <Box className="img-box">
                                                <IconButton
                                                    color="inherit"
                                                    aria-label="open drawer">
                                                    <InfoRoundedIcon />
                                                </IconButton>
                                            </Box>
                                            <Box className='card-upper-box' ><Typography className='upper-text'>Declaimer</Typography></Box>
                                        </Card>
                                    </Grid>

                                    <Grid item xs={12} sm={6} md={4} className='subcontainer-grid'>
                                        <Card className='inner-card' onClick={() => openPolicy()}>
                                            <Box className="img-box">
                                                <IconButton
                                                    color="inherit"
                                                    aria-label="open drawer">
                                                    <InfoRoundedIcon />
                                                </IconButton>
                                            </Box>
                                            <Box className='card-upper-box' ><Typography className='upper-text'>Privacy policy</Typography></Box>
                                        </Card>
                                    </Grid>

                                    <Grid item xs={12} sm={6} md={4} className='subcontainer-grid'>
                                        <Card className='inner-card' onClick={() => openTerms()}>
                                            <Box className="img-box">
                                                <IconButton
                                                    color="inherit"
                                                    aria-label="open drawer">
                                                    <InfoRoundedIcon />
                                                </IconButton>
                                            </Box>
                                            <Box className='card-upper-box' ><Typography className='upper-text'>Terms and condition</Typography></Box>
                                        </Card>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Box>
                    </Box>
                </CustomBottomSheet>
            </Box >
        </Fade>
    );
}