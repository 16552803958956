import React, { useEffect, useState } from "react";
import "./CustomSummaryBinding.scss";
import { useDrag } from "@use-gesture/react";
import { a, useSpring, config } from "@react-spring/web";
import { Alert, Box, Collapse, Fade, Skeleton, Typography } from "@mui/material";
import styles from "./SummaryStyles.module.css";

export default function CustomSummaryBinding(props: any) {
    const [isSummaryBoxOpen, setIsSummaryBoxOpen] = useState(true);
    const [summaryVisible, setSummaryVisible] = useState(true);
    const [loading, setLoading] = useState(false);

    const height = 170;

    const [{ y }, api] = useSpring(() => ({ y: height }));

    const display = y.to((py) => (py < height ? "block" : "none"));

    const openHeightSheet = () => {
        setIsSummaryBoxOpen(true);
        api.start({
            y: 0,
            immediate: false,
        });
    };

    useEffect(() => {
        if (props.summaryLoading === true) {
            setLoading(true);
        }
        else {
            setLoading(false);
        }
        if (props.summaryData !== undefined && props.summaryData !== null && props.summaryData !== '') {
            props.summaryData.forEach(data => {
                if (data.color !== '' && data.description !== '') {
                    openHeightSheet();
                    setSummaryVisible(true);
                }
            });
        }
    }, [props.summaryLoading])
    return (
        <Fade in={true} timeout={{ enter: 500 }}>
            {/* {loading ? <Skeleton animation="wave" variant="rounded" width='100%' height={300} /> :
                 ----- bottomsheet -----
                 <Box className="custom-summary-main-box"
                    style={{ overflow: "hidden" }}>
                    <a.div
                        className={styles.sheet}
                        style={{ display, bottom: `calc(-100vh + ${height}px)`, y }}>

                        <Box className="top-box-heading">
                            <Typography className="heading-text">Summary</Typography>
                        </Box>
                        {props.summaryData !== undefined && props.summaryData !== null && props.summaryData.map((row: any, index: any) => (
                            <Box className="summary-data-box" sx={{ border: '1px solid' + row.color }} key={index}>
                                <Typography className="summary-text" sx={{ color: row.color }}>{row.description}</Typography>
                            </Box>
                        ))}
                    </a.div >
                </Box>


                ------ old data -------
                <Box className="custom-summary-main-box">
                    <Box className="top-box-heading">
                        <Typography className="heading-text">Summary</Typography>
                    </Box>
                    {props.summaryData !== undefined && props.summaryData !== null && props.summaryData.map((row: any, index: any) => (
                        <Box className="summary-data-box" key={index}>
                            <Alert severity={row.color}>{row.description}</Alert>
                        </Box>
                    ))}
                </Box> */}
            <Box className="custom-summary-main-box">
                {/* {summaryVisible ?
                        (props.summaryData !== undefined && props.summaryData !== null && props.summaryData.map((row: any, index: any) => (
                            <>
                                <Box className="top-box-heading">
                                    {row.color !== '' && row.description !== '' ?
                                        <Typography className="heading-text">Summary</Typography> : <></>}
                                </Box>
                                <Box className="summary-data-box" key={index}>
                                    <Alert severity={row.color}>{row.description}</Alert>
                                </Box>
                            </>
                        )))
                        : <></>} */}
                {loading ? (<Skeleton animation="wave" variant="rounded" width='95%' height={100} />) :
                    (summaryVisible ?
                        (props.summaryData !== undefined && props.summaryData !== null && props.summaryData.map((row: any, index: any) => (
                            <>
                                <Box className="top-box-heading">
                                    {row.color !== '' && row.description !== '' ?
                                        <Typography className="heading-text">Summary</Typography> : <></>}
                                </Box>
                                <Box className="summary-data-box" key={index}>
                                    <Alert severity={row.color}>{row.description}</Alert>
                                </Box>
                            </>
                        )))
                        : <></>)
                }
            </Box>
            {/* } */}
        </Fade>
    )
}