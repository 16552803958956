import React from 'react';
import { useNavigate } from "react-router-dom";
import './Declaimer.scss';
import { Box, IconButton, List, ListItem, ListItemText, Typography } from '@mui/material';
import backIcon from "../../assets/icons/back.svg";

export default function Declaimer() {
    const navigate = useNavigate();

    function backClick() {
        navigate('/about-us');
    }

    function openPolicy() {
        navigate('/privacy-policy');
    }

    function openTerms() {
        navigate('/terms-condition');
    }

    return (
        <Box className='declaimer-main-box'>
            <Box className="head-box">
                <Box className="back-box">
                    <IconButton
                        aria-label="back"
                        className="back-icon"
                        disableRipple
                        onClick={() => backClick()}>
                        <img src={backIcon} alt="logo" />
                    </IconButton>
                </Box>
                <Box className="title-box">
                    <Typography className="title-text">Declaimer</Typography>
                </Box>
                <Box className="profile-box">
                    <></>
                </Box>
            </Box>
            <Box className="body-box">
                <Box className='part-one-head-box'>
                    <Typography className='head-text'>LINKS TO OTHER WEBSITES / INFORMATION ON THIS WEBSITE</Typography>
                </Box>
                <Box className='part-one-body-box'>
                    <Typography className='body-text'>The Website may contain links to other websites that are owned and operated by
                        third parties. Even if the third party is affiliated with us, we have no control over these
                        linked websites. PEDIAVERSE cannot guarantee or be responsible for any information
                        provided on such websites.
                        Any information or links contained in this website do not constitute to professional
                        advice.
                        Any information contained in this website is subject to change without notice.
                        Readers of this website are advised to seek their own professional advice before
                        taking any course of action or decision, for which they (the readers) are entirely
                        responsible, based on the contents of this website.
                        The Information and content displayed in this website have been prepared under the
                        guidance of health care professionals in the respected field however one formula
                        doesn’t apply to all, so it is advisable to seek face to face opinion of a health care
                        professionals/experts in the respected field and to take individualized opinion for your
                        problem.
                        An advertisement/promotion of any third party i.e. any product/service/person
                        displayed in this website is the sole responsibility of that respective
                        product/service/person. The PEDIAVERSE is not responsible for any disputes between
                        customer and the third party. Kindly verify customer support of the third party before
                        dealing with them.</Typography>
                </Box>
                <Box className='part-two-head-box'>
                    <Typography className='head-text'>COPYRIGHTS/iNTELECTUAL PROPERTY RIGHTS</Typography>
                </Box>
                <Box className='part-two-body-box'>
                    <Typography className='body-text'>If you believe that your work has been copied in a way that constitutes copyright
                        infringement, or that your intellectual property rights have been otherwise violated,
                        please provide us with the following information in order to take action:</Typography>

                    <List className='points'>
                        <ListItem className='li-points'>
                            <ListItemText>a description of the copyrighted work or other intellectual property that you
                                claim has been infringed</ListItemText>
                        </ListItem>
                        <ListItem className='li-points'>
                            <ListItemText>a description of where the material that you claim is infringing is located, with
                                enough detail that we may find it.</ListItemText></ListItem>
                        <ListItem className='li-points'>
                            <ListItemText>your name, address, telephone number, and email address.</ListItemText></ListItem>

                    </List>

                    <Typography className='body-text'>The contents provided in this website is taken from the standard textbooks/materials
                        and have been prepared under the guidance of health care professionals. However,
                        the information displayed in the website can have difference of opinion depending on
                        the region, customs, ethnicity, habits, environmental factors and individual factors.
                        We just try to keep our customers aware to improve the health of the children. It is
                        strongly recommended to seek advice of healthcare professionals in your region,
                        whenever there is doubt in taking decision regarding any health issues.</Typography>

                </Box>
            </Box>

            <Box className="bottom-part">
                <Typography className="left-part">Copyrights &copy; {new Date().getFullYear()} - PEDIAVERSE SOLUTIONS PRIVATE LIMITED</Typography>
                <Typography className="right-part" onClick={() => openTerms()}>Terms of Service &nbsp; <span>Terms and condition</span></Typography>
                <Typography className="right-part" onClick={() => openPolicy()}>Terms of Service &nbsp;<span>Privacy Policy</span></Typography>
            </Box>
        </Box >
    );
}
