import * as React from "react";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert, { AlertProps } from "@mui/material/Alert";
import { PreferencesContext } from "../PreferenceContext";
import { useContext } from "react";

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function Alerts(props: {
  isOpen: boolean;
  displayMessage: string;
  type: string;
}) {
  const { userContext, updateUserContext } = useContext(PreferencesContext);

  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    updateUserContext({
      ...userContext,
      isAlert: false,
      alertMessage: "",
      alertType: "success",
    });
  };

  return (
    <>
      {/* {props.type.toLowerCase() === 'success' ? */}
      {props.type === "success" ? (
        <Snackbar
          open={props.isOpen}
          autoHideDuration={4000}
          onClose={handleClose}
          sx={{ bottom: "35px" }}
        >
          <Alert
            onClose={handleClose}
            severity={"success"}
            sx={{ width: "100%" }}
          >
            {props.displayMessage}
          </Alert>
        </Snackbar>
      ) : null}
      {/* {props.type.toLowerCase() === 'error' ? */}
      {props.type === "error" ? (
        <Snackbar
          open={props.isOpen}
          autoHideDuration={4000}
          onClose={handleClose}
          sx={{ bottom: "35px" }}
        >
          <Alert
            onClose={handleClose}
            severity={"error"}
            sx={{ width: "100%" }}
          >
            {props.displayMessage}
          </Alert>
        </Snackbar>
      ) : null}
      {/* {props.type.toLowerCase() === 'warning' ? */}
      {props.type === "warning" ? (
        <Snackbar
          open={props.isOpen}
          autoHideDuration={4000}
          onClose={handleClose}
          sx={{ bottom: "35px" }}
        >
          <Alert
            onClose={handleClose}
            severity={"warning"}
            sx={{ width: "100%" }}
          >
            {props.displayMessage}
          </Alert>
        </Snackbar>
      ) : null}
      {/* {props.type.toLowerCase() === 'info' ? */}
      {props.type === "info" ? (
        <Snackbar
          open={props.isOpen}
          autoHideDuration={4000}
          onClose={handleClose}
          sx={{ bottom: "35px" }}
        >
          <Alert onClose={handleClose} severity={"info"} sx={{ width: "100%" }}>
            {props.displayMessage}
          </Alert>
        </Snackbar>
      ) : null}
    </>
  );
}
