import React from 'react';
import './NoDataAvailable.scss';
import LoadingButton from '@mui/lab/LoadingButton';
import { Box, Button, Typography } from '@mui/material';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import noData from "../../assets/icons/no-data.svg";

export default function NoDataAvailable(props: any) {
    return (
        <Box className="no-available-main-box">
            <Box className="no-data-box">
                <Box className="upper-box"></Box>
                <Box className="bottom-box">
                    <Box className={props.submoduleName === 'Vaccine' ? "vaccine-img-box" : (props.submoduleName === 'milestone' ? "milestone-img-box" : "img-box")}>
                        <img src={noData} alt="logo" />
                    </Box>

                    {props.submoduleName === 'BMI' ?
                        <Box className="no-summary-box">
                            <Typography className="no-summary-text">
                                Height and Weight not added for{" "}
                                <span>{props.memberName}</span>
                            </Typography>

                            {props.ageCount === 0 ? <></> : <Typography className="no-summary-subtext">
                                BMI is automatically calculated from weight and height of your child, it helps you to compare the weight and height gain of your child and early identification of growth faltering.
                            </Typography>}
                        </Box> :
                        (props.submoduleName === 'Vaccine' ?
                            <Box className="vaccine-no-summary-box">
                                <Typography className="no-summary-text">

                                </Typography>
                                <Typography className="no-summary-subtext">
                                    Please enter the details of vaccination given to your child <span><b>{props.memberName}</b>,</span> It will help you to get regular reminders for upcoming and due vaccines to make your child immunized against deadly vaccine preventable diseases and enable him/her to lead a healthy life..
                                </Typography>
                            </Box>
                            :

                            (props.submoduleName === 'milestone' ?
                                <Box className="milestone-no-summary-box">
                                    <Typography className="no-summary-text">
                                        Track your{" "}
                                        <span>{props.memberName}'s</span>{" "}
                                        Developement
                                    </Typography>
                                    <Typography className="no-summary-subtext">
                                        Please enter the the details of milestones achieved by your child as per the age of your child <span><b>{props.memberName}</b>.</span> It will help you to make sure that your child is achieving the developmental milestones on time and help you for early identification in case of any delay in development and finding out solutions for it.
                                    </Typography>
                                </Box>
                                :
                                (<Box className="no-summary-box">
                                    {props.ageCount === 0 ?
                                        <Typography className="no-summary-text">
                                            You Can't Track your{" "}
                                            <span>{props.memberName}</span>{"'s "}{props.submoduleName}
                                        </Typography> :
                                        <Typography className="no-summary-text">
                                            Track your{" "}
                                            <span>{props.memberName}'s</span>{" "}{props.submoduleName}
                                        </Typography>}
                                    {props.ageCount === 0 ? <></> : <Typography className="no-summary-subtext">
                                        {props.submoduleName === "Weight" ? 'Enter the exact weight of your child (in 00.000 kg) to track the physical growth of your child and early identification of growth faltering.' : null}
                                        {(props.submoduleName === "Height" || props.submoduleName === "Length/Height" || props.submoduleName === "Length") ? 'Enter the exact height of your child (in 000.0 cm) to track the physical growth of your child and early identification of growth faltering.' : null}
                                        {props.submoduleName === "HC" ? 'Head Circumference is measured at the horizontal line encircling the head at the level of most prominent point at the back of head. This measurement is just to give a rough idea about head size of your child. Please consult your pediatrician for further assistance.' : null}
                                    </Typography>}
                                </Box>)))
                    }

                    <Box className={props.submoduleName === 'Vaccine' ? "vaccine-start-button-box" : (props.submoduleName === 'milestone' ? "milestone-start-button-box" : "start-button-box")}>
                        {props.ageCount === 0 ? <></> :
                            (props.submoduleName === 'Vaccine' || props.submoduleName === 'milestone' ?
                                <Button
                                    variant="contained"
                                    className="add-button"
                                    onClick={props.submoduleClick}>
                                    Start now
                                </Button> :
                                (props.submoduleName === 'BMI' ? <></> :
                                    <LoadingButton
                                        variant="contained"
                                        className="add-button"
                                        startIcon={<AddOutlinedIcon />}
                                        onClick={props.submoduleClick}>
                                        {props.submoduleName}
                                    </LoadingButton>
                                )
                            )
                        }
                    </Box>
                </Box>
            </Box>
        </Box >
    )
}