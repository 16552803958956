import React, { useContext, useEffect, useState } from "react";
import "./CustomTabs.scss";
import { Alert, AlertTitle, Box, Skeleton, Tab, Tabs, Typography, styled, Button, Slide, Dialog, AppBar, Toolbar, IconButton, DialogContent, Stack } from "@mui/material";
import ReactECharts from "echarts-for-react";
import { PreferencesContext } from "../../PreferenceContext";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import ReactTimeAgo from "react-time-ago";
import dayjs, { Dayjs } from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers";
import utc from "dayjs/plugin/utc";
import { GetBMIDetailSummary, GetHeightDetailSummary, GetWeightDetailSummary } from "../../services/PhysicalGrowthService";
import { ageInDays, ageInMonths } from "../../shared/CommonFuctions";
import CustomSummaryBinding from "../CustomSummaryBinding/CustomSummaryBinding";
import NoDataAvailable from "../NoDataAvailable/NoDataAvailable";
import { TransitionProps } from "@mui/material/transitions";
import { Link } from "react-router-dom";
import CloseIcon from '@mui/icons-material/Close';
import OpenBMIRefrenceLink from "../OpenBMIRefrenceLink/OpenBMIRefrenceLink";
dayjs.extend(utc);

interface StyledTabsProps {
  children?: React.ReactNode;
  value: number;
  onChange: (event: React.SyntheticEvent, newValue: number) => void;
}

const StyledTabs = styled((props: StyledTabsProps) => (
  <Tabs
    {...props}
    // TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
    TabIndicatorProps={{
      children: <span className="MuiTabs-indicatorSpan" />,
      style: { bottom: '8px', height: '35px', borderRadius: '8px', zIndex: 1 },
      sx: {
        height: 5,
      },
    }}
  />
))({
  "& .MuiTabs-indicator": {
    // display: "flex",
    // justifyContent: "center",
    // backgroundColor: "transparent",
  },
  "& .MuiTabs-indicatorSpan": {
    // maxWidth: 40,
    // width: "100%",
    // backgroundColor: "",
  },
});

interface StyledTabProps {
  label: string;
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, page, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
      style={{ width: "100%" }}
    >
      {value === index && <Box className="selected-tab">{children}</Box>}
    </div>
  );
}

function tabProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const StyledTab = styled((props: StyledTabProps) => (
  <Tab disableRipple {...props} />
))(({ theme }) => ({
  textTransform: "none",
  fontWeight: theme.typography.fontWeightRegular,
  fontSize: theme.typography.pxToRem(15),
  minHeight: "35px",
  color: "#000",
  transition: ' all 350ms',
  marginLeft: '5px',
  marginRight: '5px',
  "&.Mui-selected": {
    color: "#fff",
  },
  "&.MuiTabs-indicator": {
    backgroundColor: "#0B7CAA",
  },
  "&.Mui-focusVisible": {
    backgroundColor: "rgba(0, 0, 0, 0)",
  },
}));

export default function CustomTabs(props: any) {
  const { userContext, updateUserContext } = useContext(PreferencesContext);
  const [value, setValue] = useState(0);
  const [loading, setLoading] = useState(false);
  const [isChartLoad, setIsChartLoad] = useState(true);
  const [dataLength, setDataLength] = useState(0);
  const [chartIsVisible, setChartIsVisible] = useState(false);
  const [openEmergencyConditionDialog, setOpenEmergencyConditionDialog] = useState(false);
  const [summaryData, setSummaryData] = useState([]);
  const [showAlert, setAlertMessage] = useState({
    isSuccess: false,
    message: "",
  });

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  async function fetchoption() {
    props.option.series.forEach(data => {
      if (data.data === false) {
        setChartIsVisible(false);
      } else {
        setChartIsVisible(true);
        setIsChartLoad(false);
      }
    });
  }

  async function fetchSummaryData() {
    setLoading(true);
    let country = userContext.countryCode === '+91' ? 'INDIA' : 'WHO';
    // let country = 'WHO';
    let days = ageInDays(userContext.memberDetail.birthDate);
    let months = 0;
    if (country === 'INDIA') {
      if (days >= 730) {
        days = 0
        months = ageInMonths(userContext.memberDetail.birthDate);
      }
    } else {
      if (days >= 1825) {
        months = ageInMonths(userContext.memberDetail.birthDate);
        days = 0;
      }
    }
    if (props.page === 'Height') {
      try {
        let params = new URLSearchParams();
        params.append("familyId", userContext.memberDetail.familyId.toString());
        params.append("familyMemberId", userContext.memberDetail.familyMemberId.toString());
        params.append("gender", userContext.memberDetail.gender);
        params.append("country", country);
        const result: any = await GetHeightDetailSummary(params);
        // let summaryData = result.data.data[0];
        let summaryData = result.data.data;
        setSummaryData(summaryData);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        if (error) {
          setAlertMessage({
            isSuccess: true,
            message: error as string,
          });
        }
      }
    }

    if (props.page === 'Weight') {
      try {
        let params = new URLSearchParams();
        params.append("familyId", userContext.memberDetail.familyId.toString());
        params.append("familyMemberId", userContext.memberDetail.familyMemberId.toString());
        params.append("gender", userContext.memberDetail.gender);
        params.append("country", country);
        const result: any = await GetWeightDetailSummary(params);
        // let summaryData = result.data.data[0];
        let summaryData = result.data.data;
        setSummaryData(summaryData);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        if (error) {
          setAlertMessage({
            isSuccess: true,
            message: error as string,
          });
        }
      }
    }

    if (props.page === 'BMI') {
      try {
        let params = new URLSearchParams();
        params.append("familyId", userContext.memberDetail.familyId.toString());
        params.append("familyMemberId", userContext.memberDetail.familyMemberId.toString());
        params.append("gender", userContext.memberDetail.gender);
        params.append("country", country);
        const result: any = await GetBMIDetailSummary(params);
        let summaryData = result.data.data;
        setSummaryData(summaryData);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        if (error) {
          setAlertMessage({
            isSuccess: true,
            message: error as string,
          });
        }
      }
    }
  }

  useEffect(() => {
    setDataLength(props.dataLength)
    fetchoption();
    fetchSummaryData();
  }, [props.option])

  const handleCloseEmergencyConditionDialog = () => {
    setOpenEmergencyConditionDialog(false);
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Box className="custom-tabs-main-box">
        <Box className="tabs-head-box">
          {props.growthData !== undefined && props.growthData.length > 0 ?
            <StyledTabs
              value={value}
              onChange={handleTabChange}
              aria-label="styled tabs example">
              <StyledTab label="Chart" {...tabProps(0)} />
              <StyledTab label="History" {...tabProps(1)} />
            </StyledTabs> : <></>}
        </Box>

        {props.page === 'BMI' && props.growthData !== undefined && props.growthData.length > 0 ?
          <Box sx={{ pt: '10px', width: '100%' }}>
            <Alert severity="warning">
              <AlertTitle>Disclaimer:</AlertTitle>
              We are just giving you the rough outline regarding the health, growth and development of your child just to sensitize you to seek the medical care earlier if needed. Every child has to be examined individually by a registered medical practitioner before confirmation of status of health, growth and development of any child and A Doctor is the Final authority to decide regarding further management and intervention needed as felt necessary by the Doctor.
              <Box className="source-link-ref-box-title" >Source: <span className="source-link-ref"
                  onClick={() => setOpenEmergencyConditionDialog(true)}>https://www.cdc.gov/growthcharts/percentile_data_files.htm</span></Box>
            </Alert>
          </Box> : <></>}
        <Box className="tabs-body-box">
          <CustomTabPanel value={value} index={0}>
            {props.growthData !== undefined && props.growthData.length > 0 ?
              props.ageCount === 0 ?
                <NoDataAvailable
                  submoduleName={props.page}
                  memberName={userContext.memberDetail.firstName}
                  submoduleClick={props.onClick}
                  ageCount={props.ageCount}
                /> :
                ((isChartLoad ?
                  <Skeleton animation="wave" variant="rounded" height={300} sx={{ borderRadius: '8px', width: '100%' }} /> :
                  (<>
                    <Typography className="y-axis-label">{props.yAxisLabel}</Typography>
                    <ReactECharts
                      option={props.option
                      }
                      notMerge={true}
                      lazyUpdate={true}
                      theme={"theme_name"}
                      className="custom-react-chart" />
                    <Typography className="x-axis-label">{props.xAxisLabel}</Typography>
                  </>)))
              :
              (<NoDataAvailable
                submoduleName={props.page}
                memberName={userContext.memberDetail.firstName}
                submoduleClick={props.onClick}
                ageCount={props.ageCount}
              />)
            }
            {summaryData !== undefined && summaryData !== null && summaryData.length !== 0 ?
              (props.page !== 'BMI' ? <CustomSummaryBinding summaryData={summaryData} summaryLoading={loading} /> : <></>)
              :
              <></>}
          </CustomTabPanel>

          <CustomTabPanel value={value} index={1}>
            {props.growthData !== undefined && props.growthData.length > 0 && (
              props.growthData.map((row: any, index: any) => (
                <Box className="history-main-box" key={index}>
                  <Box className="left-img-part">
                    <img src={props.icon} alt="logo" />
                  </Box>
                  <Box className="right-text-part">
                    <Box className="upper-text-box">
                      {row.unit === null ? (
                        <Typography className="upper-text">
                          {row.userData} kg/m
                          <span className="upper-super-script">2</span>
                        </Typography>
                      ) : (
                        <Typography className="upper-text">
                          {row.userData + " " + row.unit}
                        </Typography>
                      )}

                      <Typography className="upper-right-text">
                        <ReactTimeAgo
                          date={new Date(dayjs.utc(row.entryDate).format())}
                          locale="en-US"
                        />
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              ))
            )}
          </CustomTabPanel>
        </Box>
      </Box >
      <Dialog
        fullScreen
        open={openEmergencyConditionDialog}
        onClose={handleCloseEmergencyConditionDialog}
        className="custom-popup-header"
      >
        <React.Fragment >
          <AppBar sx={{ position: 'relative' }} color='primary'>
            <Toolbar className='custom-popup-header' >
              <Box className='header-empty-box'></Box>
              <Box className='close-box'>
                <IconButton
                  edge="start"
                  color="inherit"
                  onClick={handleCloseEmergencyConditionDialog}
                  aria-label="close"
                  className='close-button'>
                  <CloseIcon />
                </IconButton>
              </Box>
            </Toolbar>
          </AppBar>
          <DialogContent style={{ padding: 0 }}>
            <iframe width="100%" height="100%" src="https://www.cdc.gov/growthcharts/percentile_data_files.htm" title="Pediaverse" ></iframe>
          </DialogContent>
        </React.Fragment>
      </Dialog>
    </LocalizationProvider >
  );
}
