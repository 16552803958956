import { POST, GET } from "./common/http";
import { BASE_URL } from "./common/const";

export const AlreadyRegister = (data: any) =>
    POST(`${BASE_URL}/api/User/IsAlreadyRegister`, data);

export const CreateUser = (data: any) =>
    POST(`${BASE_URL}/api/User/create`, data);

export const SendNotification = (data: any) =>
    POST(`${BASE_URL}/api/NotificationManger/Create`, data);