import React, { useContext, useState } from "react";
import "./Dashboard.scss";
import { Box, Card, Fade, Skeleton, Typography, } from "@mui/material";
import _ from 'lodash';
import { PreferencesContext } from "../../PreferenceContext";
import { useNavigate } from "react-router-dom";
import { GetModuleConfiguration, GetMotherModuleConfiguration } from "../../services/DashboardService";
import CustomBottomSheet from "../../components/CustomBottomSheet/CustomBottomSheet";
import milestoneDevelopmentIcon from "../../assets/icons/milestone-development.svg";
import dietAndNutritionIcon from "../../assets/icons/diet-nutritionIcon-outer.svg";
import healthAssistantIcon from "../../assets/icons/health assistant.svg";
import childVaccinationIcon from "../../assets/icons/child-vaccination.svg";
import babyIcon from "../../assets/images/new-born.svg";
import physicalGrowthIcon from "../../assets/icons/height-outer.svg";
import wombIcon from "../../assets/icons/womb.svg";
import questionIcon from "../../assets/icons/question.svg";

export default function Dashboard() {
  const { userContext, updateUserContext } = useContext(PreferencesContext);
  const [visibleDashboardCardData, setVisibleDashboardCardData] = useState([]);
  const [visibleNotoficationData, setVisibleNotoficationData] = useState([]);
  const [showAlert, setAlertMessage] = useState({
    isSuccess: false,
    message: "",
  });

  const navigate = useNavigate();

  const openFetalGrowth = () => {
    navigate("/fetal-growth");
  }

  const openPregnancyOutcome = () => {
    navigate("/pregnancy-outcome");
  }

  const openPhysicalGrowth = () => {
    navigate("/physical-growth");
  };

  const openDietAndNutrition = () => {
    navigate("/diet-nutrition");
  }

  const openMidParentalHeight = () => {
    navigate("/mid-parental-height");
  }

  const openCareOfNewborn = () => {
    navigate("/born-care");
  };

  const openMilestoneDevelopment = () => {
    navigate("/milestone-development");
  };

  const openCommonDiseases = () => {
    navigate("/common-diseases");
  };

  const openMedicalHistory = () => {
    navigate('/medical-history');
  }

  const openVaccination = () => {
    navigate("/vaccination");
  };

  async function fetchMotherRegardingModule() {
    try {
      updateUserContext({
        ...userContext,
        moduleConfigurationLoading: true
      });
      let params = new URLSearchParams();
      params.append("lmpDate", userContext.memberDetail.lastManstrualPeriodDate);
      params.append("gender", userContext.memberDetail.gender);
      const result: any = await GetMotherModuleConfiguration(params);
      let dashboardCard = result.data.data
      setVisibleDashboardCardData(dashboardCard.sort());
      let moduleConfiguration = userContext.moduleConfiguration;
      moduleConfiguration = dashboardCard;
      updateUserContext({
        ...userContext,
        moduleConfiguration: moduleConfiguration,
        moduleConfigurationLoading: false
      });
    } catch (error: any) {
      if (error) {
        setAlertMessage({
          isSuccess: true,
          message: error as string,
        });
      }
    }
  }

  async function fetchModule() {
    try {
      updateUserContext({
        ...userContext,
        moduleConfigurationLoading: true
      });
      let params = new URLSearchParams();
      params.append("birthDate", userContext.memberDetail.birthDate);
      params.append("gender", userContext.memberDetail.gender);
      params.append("language", userContext.memberDetail.curMemberSelectedLanguage);
      const result: any = await GetModuleConfiguration(params);
      let dashboardCard = result.data.data;
      setVisibleDashboardCardData(dashboardCard.sort());
      // let moduleConfiguration = userContext.moduleConfiguration;
      let moduleConfiguration = userContext.moduleConfiguration;
      moduleConfiguration = dashboardCard;
      updateUserContext({
        ...userContext,
        moduleConfiguration: moduleConfiguration,
        moduleConfigurationLoading: false
      });
    }
    catch (error: any) {
      if (error) {
        setAlertMessage({
          isSuccess: true,
          message: error as string,
        });
      }
    }
  }

  // useEffect(() => {
  //   if (userContext.memberDetail !== undefined && userContext.memberDetail.birthDate !== '') {
  //     let week = ageInDays(userContext.memberDetail.birthDate);
  //     fetchModule();
  //   }
  //   if (userContext.memberDetail !== undefined && userContext.memberDetail.lastManstrualPeriodDate !== null && userContext.memberDetail.lastManstrualPeriodDate !== '') {
  //     let week = ageInWeeks(userContext.memberDetail.lastManstrualPeriodDate);
  //     if (+week > 13) {
  //       fetchMotherRegardingModule();
  //     }
  //   }
  // }, [userContext.memberDetail])

  return (
    <Fade in={true} timeout={{ enter: 500 }}>
      <Box className="dashboard-main-box">
        <CustomBottomSheet>
          <Box className="dashboard-body">
            <Box className="selected-name">
              {userContext.memberDetailLoading === true ?
                <Skeleton animation="wave" variant="rounded" width={400} height={30} />
                :
                <Typography className="text-name">
                  Track {userContext.curMemberSelected !== undefined && userContext.curMemberSelected !== null && userContext.curMemberSelected !== "" ? userContext.curMemberSelected.replace(/\s+$/, '') : userContext.memberDetail.firstName.replace(/\s+$/, '')}'s daily activity
                </Typography>}
            </Box>

            {/* {visibleNotoficationData.length > 0 ?
              <Box className="notification-scroll-box">
                <Box className="notification-header-box">
                  <Typography className="text-name">Notifications</Typography>
                  <Link className="link-name">View All</Link>
                </Box>
              </Box>
              : <></>} */}

            <Box className="health-scroll-box">
              {userContext.memberDetail.familyRelationName === "SON" ||
                userContext.memberDetail.familyRelationName === "DAUGHTER" ? (
                <>
                  <Typography className="text-name">Let's get started</Typography>
                  <Box className="card-slide-main">
                    <Box className="card-slide">


                      {userContext.moduleConfigurationLoading === true ?
                        (_.times(4, (i) => (
                          <Card
                            key={i}
                            className="health-card-loading"
                            onClick={openMedicalHistory}>
                            <Box className="card-img-box">
                              <Skeleton animation="wave" variant="circular" width={60} height={60} />
                            </Box>
                            <Box className="card-text-box">
                              <Skeleton variant="text" width={100} sx={{ fontSize: '14px' }} />
                              <Skeleton variant="text" width={50} sx={{ fontSize: '14px' }} />
                            </Box>
                          </Card>
                        ))) : <>
                          {userContext.moduleConfiguration !== '' && userContext.moduleConfiguration.length > 0 &&
                            userContext.moduleConfiguration.map((row: any, index: any) => (
                              (row.moduleCode === 'CARE_OF_NEWBORN' ?

                                <Card
                                  key={index}
                                  className="health-card six"
                                  onClick={openCareOfNewborn}>
                                  <Box className="card-img-box">
                                    <img
                                      src={babyIcon}
                                      alt="logo"
                                      className="baby-img"
                                    />
                                  </Box>
                                  <Box className="card-text-box">
                                    <Typography variant="h5" className="card-text six">
                                      {row.moduleName}
                                    </Typography>
                                  </Box>
                                </Card>
                                : null
                              )
                            ))}
                          {userContext.moduleConfiguration !== '' && userContext.moduleConfiguration.length > 0 &&
                            userContext.moduleConfiguration.map((row: any, index: any) => (
                              (row.moduleCode === 'PHYSICAL_GROWTH' ?

                                <Card
                                  key={index}
                                  className="health-card one"
                                  onClick={openPhysicalGrowth}>
                                  <Box className="card-img-box">
                                    <img
                                      src={physicalGrowthIcon}
                                      alt="logo"
                                      className="growth-img"
                                    />
                                  </Box>
                                  <Box className="card-text-box">
                                    <Typography variant="h5" className="card-text one">
                                      {row.moduleName}
                                    </Typography>
                                  </Box>
                                </Card>
                                : null
                              )
                            ))}
                          {userContext.moduleConfiguration !== '' && userContext.moduleConfiguration.length > 0 &&
                            userContext.moduleConfiguration.map((row: any, index: any) => (
                              (row.moduleCode === 'VACCINATION' ?

                                <Card
                                  key={index}
                                  className="health-card four"
                                  onClick={openVaccination}>
                                  <Box className="card-img-box">
                                    <img
                                      src={childVaccinationIcon}
                                      alt="logo"
                                      className="vaccinantion-img"
                                    />
                                  </Box>
                                  <Box className="card-text-box">
                                    <Typography variant="h5" className="card-text four">
                                      {row.moduleName}
                                    </Typography>
                                  </Box>
                                </Card>
                                : null
                              )
                            ))}
                          {userContext.moduleConfiguration !== '' && userContext.moduleConfiguration.length > 0 &&
                            userContext.moduleConfiguration.map((row: any, index: any) => (
                              (row.moduleCode === 'MILESTONE_DEVELOPMENT' ?

                                <Card
                                  key={index}
                                  className="health-card two"
                                  onClick={openMilestoneDevelopment}>
                                  <Box className="card-img-box">
                                    <img
                                      src={milestoneDevelopmentIcon}
                                      alt="logo"
                                      className="growth-img"
                                    />
                                  </Box>
                                  <Box className="card-text-box">
                                    <Typography variant="h5" className="card-text two">
                                      {row.moduleName}
                                    </Typography>
                                  </Box>
                                </Card>
                                : null
                              )
                            ))}
                          {userContext.moduleConfiguration !== '' && userContext.moduleConfiguration.length > 0 &&
                            userContext.moduleConfiguration.map((row: any, index: any) => (
                              (row.moduleCode === 'DIET_NUTRITION' ?

                                <Card key={index} className="health-card three" onClick={openDietAndNutrition}>
                                  <Box className="card-img-box">
                                    <img
                                      src={dietAndNutritionIcon}
                                      alt="logo"
                                      className="diet-img"
                                    />
                                  </Box>
                                  <Box className="card-text-box">
                                    <Typography variant="h5" className="card-text three">
                                      {row.moduleName}
                                    </Typography>
                                  </Box>
                                </Card>

                                : null
                              )
                            ))}
                          {userContext.moduleConfiguration !== '' && userContext.moduleConfiguration.length > 0 &&
                            userContext.moduleConfiguration.map((row: any, index: any) => (
                              (row.moduleCode === 'MID_PARENTAL_HEIGHT' ?
                                <Card
                                  key={index}
                                  className="health-card one"
                                  onClick={openMidParentalHeight}>
                                  <Box className="card-img-box">
                                    <img
                                      src={physicalGrowthIcon}
                                      alt="logo"
                                      className="growth-img"
                                    />
                                  </Box>
                                  <Box className="card-text-box">
                                    <Typography variant="h5" className="card-text one">
                                      {row.moduleName}
                                    </Typography>
                                  </Box>
                                </Card>
                                : null
                              )
                            ))}

                          {userContext.moduleConfiguration !== '' && userContext.moduleConfiguration.length > 0 &&
                            userContext.moduleConfiguration.map((row: any, index: any) => (
                              (row.moduleCode === 'MEDICAL_HISTORY' ?
                                <Card
                                  key={index}
                                  className="health-card five"
                                  onClick={openMedicalHistory}>
                                  <Box className="card-img-box">
                                    <img
                                      src={healthAssistantIcon}
                                      alt="logo"
                                      className="diseases-img"
                                    />
                                  </Box>
                                  <Box className="card-text-box">
                                    <Typography variant="h5" className="card-text five">
                                      {row.moduleName}
                                    </Typography>
                                  </Box>
                                </Card>
                                : null
                              )
                            ))}</>}
                    </Box>
                  </Box>
                </>
              ) : (
                <>
                  {userContext.moduleConfigurationLoading === true ? <Skeleton variant="text" width={150} sx={{ fontSize: '18px' }} /> :
                    <Typography className="text-name">{userContext.moduleConfiguration !== '' && userContext.moduleConfiguration.length > 0 ? "Let's Get Started" : ""}</Typography>
                  }
                  <Box className="card-slide-main">
                    <Box className="card-slide">
                      {userContext.moduleConfigurationLoading === true ?
                        (_.times(4, (i) => (
                          <Card
                            key={i}
                            className="health-card-loading"
                            onClick={openMedicalHistory}>
                            <Box className="card-img-box">
                              <Skeleton animation="wave" variant="circular" width={60} height={60} />
                            </Box>
                            <Box className="card-text-box">
                              <Skeleton variant="text" width={100} sx={{ fontSize: '14px' }} />
                              <Skeleton variant="text" width={50} sx={{ fontSize: '14px' }} />
                            </Box>
                          </Card>))) :
                        <>
                          {userContext.moduleConfiguration !== '' && userContext.moduleConfiguration.length > 0 &&
                            userContext.moduleConfiguration.map((row: any, index: any) => (
                              (row.moduleCode === 'FETAL_GROWTH' ?
                                <Card
                                  key={index}
                                  className="health-card mother-one"
                                  onClick={openFetalGrowth}>
                                  <Box className="card-img-box">
                                    <img
                                      src={wombIcon}
                                      alt="logo"
                                      className="growth-img"
                                    />
                                  </Box>
                                  <Box className="card-text-box">
                                    <Typography variant="h5" className="card-text mother-one">
                                      {row.moduleName}
                                    </Typography>
                                  </Box>
                                </Card>
                                : null
                              )
                            ))}
                          {userContext.moduleConfiguration !== '' && userContext.moduleConfiguration.length > 0 &&
                            userContext.moduleConfiguration.map((row: any, index: any) => (
                              (row.moduleCode === 'PREGNANCY_OUTCOME' ?
                                <Card
                                  key={index}
                                  className="health-card mother-two"
                                  onClick={openPregnancyOutcome}>
                                  <Box className="card-img-box">
                                    <img
                                      src={questionIcon}
                                      alt="logo"
                                      className="growth-img"
                                    />
                                  </Box>
                                  <Box className="card-text-box">
                                    <Typography variant="h5" className="card-text mother-two">
                                      {row.moduleName}
                                    </Typography>
                                  </Box>
                                </Card>

                                : null
                              )
                            ))}
                        </>}
                    </Box></Box>
                </>
              )}
            </Box>
          </Box>
        </CustomBottomSheet>
      </Box>
    </Fade>
  );
}
