import * as React from "react";
import { useNavigate } from "react-router-dom";
import { Box, IconButton, Typography } from "@mui/material";
import "./TermsAndConditions.scss";
import backIcon from "../../assets/icons/back.svg";

export default function TermsAndConditions(props: any) {
    const navigate = useNavigate();

    function backClick() {
        navigate('/about-us');
    }

    function openPolicy() {
        navigate('/privacy-policy');
    }

    function openDeclaimer() {
        navigate('/declaimer');
    }

    return (
        <Box className='terms-condition-main-box'>
            <Box className='head-box'>
                <Box className="back-box">
                    <IconButton
                        aria-label="back"
                        className="back-icon"
                        disableRipple
                        onClick={() => backClick()}>
                        <img src={backIcon} alt="logo" />
                    </IconButton>
                </Box>
                <Box className="title-box">
                    <Typography className="title-text">Terms and conditions</Typography>
                </Box>
                <Box className="profile-box">
                    <></>
                </Box>
            </Box>
            <Box className='body-box'>
                <Box className='body-head'>
                    {/* <h1>Terms of Service</h1> */}
                    <Typography variant="h4">Terms of Service</Typography>
                </Box>
                <Box>
                    Effective from – January 01, 2024
                </Box>
                <Box>
                    <p>By clicking on the acceptance of the terms and conditions as mentioned below; the same shall form a contractual
                        Agreement between Software and our users. You are therefore requested to read and understand them carefully before
                        accessing or using our Software. In consideration of the mutual promises contained in this Agreement, you and
                        Software unconditionally agree as follows:</p>

                    <Box id="el1" className="title">
                        <h4>1. DEFINITIONS</h4>
                    </Box>

                    <p>"Agreement" - refers to these Terms of Service (the "Terms"), our Privacy Policy, and all other operating rules,
                        policies, and procedures that we may publish periodically on the Website.</p>

                    <p>The term "Software", "we" and "us" shall refer to PEDIAVERSE SOLUTIONS PRIVATE LIMITED, Office No. 417-418, 4th
                        Floor, Tower B, Navratna Corporate Park, Opp Jayantilal Park, Iscon-Ambli Road, Ambli, Ahmedabad - 380058, Gujarat
                        State, India, as well as our Agents, Affiliates, Subsidiaries, Contractors, Licensors, Officers and employees.</p>

                    <p>"Service" - refers to the Website and online products provided by Software including Mobile Application.</p>

                    <p>"Website" - refers to our official company’s website, all subdomains and all content, services, and products
                        provided by us therefrom.</p>

                    <p>"User", "you" and "your" - refers to the individual person, company, or organization that has visited or is
                        using the services developed by PEDIAVERSE SOLUTIONS PRIVATE LIMITED.</p>

                    <p>"Authorized Users" - refers to the User per se as well as their employees/personnel who are authorized to have
                        an access our software.</p>

                    <p>"Subscription" - is (a) an online order placed by you with us through our Website/Mobile Application or (b)
                        another written agreement or purchase order for the services accepted by you.</p>

                    <p>"Subscription Date" – shall refer to the date on which you submit your online order through our Website/Mobile
                        Application and/or (b) the date on which you accept this agreement.</p>

                    <Box id="el2" className="title">
                        <h4>2. DESCRIPTION</h4>
                    </Box>
                    <p>The Software has been prepared to deal with a seamless health care services and features relating to remote access of the same
                        in order to cater with selected health care issues of a child / adolescent / any person. (Here you may add the technical description
                        of the software, version, other features, etc.). The mission for developing the software is to make it simple without any kind
                        of complications taking into consideration various algorithms and standard textbooks and standard data and standard research modules
                        as well as standard SOP followed worldwide/in the specific region whenever necessary. Reposts and presentations can be generated at
                        regular interval so as to give an extremely clear-cut current position of the various parameters that have been assessed.
                        Features and functionality are subjected to latest versions as per the need and development of the software which
                        the customer may choose to opt based on the various data they feed. Based on ease of using the software, the same can be operated
                        and/or accessed vide a computer network and/or on the cell phone (having Android or Apple configuration) through the authorized
                        personnel of the customer. Therefore, by using or accessing any part of our Service, you are representing that you have the capacity
                        and authority to enter into this agreement and are consenting on behalf of yourself and/or as an authorized representative of your
                        family / children, as applicable, to be bound by this agreement.</p>

                    <Box id="el3" className="title">
                        <h4>3. ACCOUNT TERMS</h4>
                    </Box>
                    <p>(A) Access to Services: Since the software developed by PEDIAVERSE SOLUTIONS PRIVATE LIMITED is available online; the customer shall be solely responsible for
                        providing, installing, and maintaining at their own expense any and/or all equipment(s), facilities, and services necessary to enable
                        their authorized users to access and use of the software through the interface, including, without limitation all computer hardware,
                        software and Internet access.</p>

                    <p>(B) User Account Security: The customer is solely responsible for tracking and for ensuring the security as well as confidentiality of
                        all user identifiers and passwords including the number of personnel who are given an authorized access to use the software. It is
                        clarified by PEDIAVERSE SOLUTIONS PRIVATE LIMITED that the software has no liability with respect to any use or misuse of such identifiers or passwords and
                        that any use other than as provided in this Agreement will be considered a breach of this Agreement by the customer. No financial
                        responsibility of any nature, amount, consideration, etc. shall be borned by PEDIAVERSE SOLUTIONS PRIVATE LIMITED under any circumstances of the customer.
                        The software does not represent that it meets the standards for operational compliance or certification in any specific area. </p>

                    <Box id="el4" className="title">
                        <h4>4. ACCEPTABLE USE</h4>
                    </Box>
                    <p>(A) Compliance with Laws and Regulations: It shall be the customers’ responsibility to ensure, through proper instructions and
                        enforcement actions, that all access to the use and access of the software services is permitted only to the authorized personnel
                        and that your facilities, equipment, identifiers or passwords will conform to this Agreement and will be made and used solely for
                        proper and legal purposes only and will be conducted in a manner that does not violate any of the laws of the land and shall be in
                        conformity with the rights of any third party and this Agreement.</p>

                    <p>(B) Conduct Restrictions: No provision of this Agreement includes the right to do the following (and that the customer shall
                        not either directly or indirectly undertake to):</p>

                    <ul>
                        <li>
                            Attempt to interfere with and/or compromise the system integrity or security or decipher any transmissions to or from the servers
                            running the services provided to the customer.
                        </li>
                        <li>
                            Take any action that imposes or may impose an unreasonable or disproportionately large load on the software infrastructure.
                        </li>
                        <li>
                            Knowingly upload invalid data, viruses, worms, or other software agents through the services provided to the customer.
                        </li>
                        <li>
                            Enable any person or entity other than Authorized Users to access and use the services and/or technology.
                        </li>
                        <li>
                            Modify or create any derivative work based upon the services provided.
                        </li>
                        <li>
                            Engage or permit or suffer to continue any copying or distribution of the services provided.
                        </li>
                        <li>
                            Reverse engineer, disassemble or decompile any and/or all portion of the software or attempt to discover or recreate the
                            source code for any software that is part of the services provided to the customer.
                        </li>
                        <li>
                            Access the service in order to build a competitive solution or to assist any third party to build a competitive solution
                            which is detrimental to the business interests of PEDIAVERSE SOLUTIONS PRIVATE LIMITED.
                        </li>
                        <li>
                            The services used by the customer shall not be used by them in order to gain any commercial advantage to them through any medium of network.
                        </li>
                        <li>
                            Remove, obscure and/or alter any proprietary notice related to the services provided to the customer.
                        </li>
                        <li>
                            Engage in or permit or suffer to continue any use or other activity that is not expressly authorized under this
                            Agreement by any person or entity within their control.
                        </li>
                        <li>
                            In the event the customer is found violating any of the terms mentioned hereinabove, in addition to any other remedies available
                            at law or in equity; PEDIAVERSE SOLUTIONS PRIVATE LIMITED will have the right, in its discretion, to immediately suspend the customer including their
                            authorized users to the services and to claim damages including any and/or all costs related to the claims.
                        </li>
                    </ul>

                    <Box id="el5" className="title">
                        <h4>5. SERVICE PERIOD</h4>
                    </Box>
                    <p>(A) This Agreement will commence on from the date of download and starting operation of the software/application by the customer
                        for the period till the same is terminated early in accordance with the terms and conditions mentioned under this Agreement
                        including the termination clause in which case the time frame for the service period shall be based on the number of days the
                        services are used by the customer till the date of termination of the services.</p>

                    <p>(B) In case a customer wishes to opt for a renewal period of the services; the same may be opted by them after the payment of
                        the renewal fee (if any) structure in which case the service period shall get automatically renewed for the successive renewal
                        period.</p>

                    <Box id="el6" className="title">
                        <h4>6. PAYMENT</h4>
                    </Box>
                    <p>(A) Pricing: All fees/charges; etc. excluding taxes for the usage of the software will be published and/or made available on
                        official the Website of PEDIAVERSE SOLUTIONS PRIVATE LIMITED as well as on the software application.</p>

                    <p>(B) Revision: The pricing structure may be revised based on the upgrade(s) made to the software. In such circumstances, if the
                        customer wishes to opt for the upgraded version during the existing term of the software, no refund including adjustment in
                        the revised price; of any amount(s) shall be made to the customer for discontinuation of the older version of the software.</p>

                    <p>(C) Usage based on number of people: The fees shall be charged and payable based on the number of users of the customer and/or number
                        of synchronized repositories and/or number of executed workflows and/or automations and/or duration of data retention.</p>

                    <p>(D) Advance Payment: All the payments shall be made by the customer in full and in advance after which the software shall be
                        activated by PEDIAVERSE SOLUTIONS PRIVATE LIMITED. In case of any technical glitch for activation, on the part of PEDIAVERSE SOLUTIONS PRIVATE LIMITED, the payment shall be
                        reduced pro-rata based on the number of days the glitch persists and the proportionate amount shall be refunded back to
                        the customer by PEDIAVERSE SOLUTIONS PRIVATE LIMITED on an immediate basis once the software has been activated.</p>

                    <p>(E) Responsibility and Authorization for payment: The customer agrees that they are authorized to use the payment method opted by
                        them while making the payment to PEDIAVERSE SOLUTIONS PRIVATE LIMITED. Any changes made by the customer for the payment method shall be notified by them
                        to PEDIAVERSE SOLUTIONS PRIVATE LIMITED who shall otherwise not be responsible for any financial loss/losses which occurs to the customer based
                        on non-submission of any change in the financial information including the change of the payment method.</p>

                    <p>(F) Suspension: If any payment is not made when due, the services shall be immediately suspended by PEDIAVERSE SOLUTIONS PRIVATE LIMITED which may lead to any
                        loss of data on the part of the customer for which PEDIAVERSE SOLUTIONS PRIVATE LIMITED shall not be responsible for such a loss. All prepayments for the
                        usage of the services provided by PEDIAVERSE SOLUTIONS PRIVATE LIMITED are non-refundable.</p>

                    <p>(G) Missed payments: Any amount not paid when due will bear an interest at the rate of 6% per month or the maximum rate permitted by
                        applicable law, whichever is less, computed and compounded daily from the date due until the date paid. In case of an event of any
                        action(s) initiated by PEDIAVERSE SOLUTIONS PRIVATE LIMITED for collection of any amount(s); if any; not paid when due, the customer shall bear and/or pay
                        and/or reimburse the costs of collection which have accrued to PEDIAVERSE SOLUTIONS PRIVATE LIMITED including, without limitation, any attorneys' fees and
                        court costs.</p>

                    <p>(H) Pricing changes: All changes in the pricing structure shall be notified in advance either through the ongoing services and/or by
                        an email before 30 days of the term of the subscription of the services. In case the customer does not wish to continue with the
                        services, an intimation to that effect may be given by the customer well in advance and before the completion of 30 working days;
                        and in case of a failure to notify; it shall be presumed that the customer does not wish to go ahead with the services and that
                        the same shall be terminated as per the period of the same.</p>

                    <Box id="el7" className="title">
                        <h4>7. CANCELLATION AND TERMINATION</h4>
                    </Box>
                    <p>The customer can cancel their usage and get the services terminated which have been availed by them. In that eventuality,
                        any and all rights granted to the customer with respect to the services and the software with respect to the data will be
                        terminated on the effective date of termination and that the customer agrees to return any and/or all Confidential Information
                        of software in their possession and/or control. PEDIAVERSE SOLUTIONS PRIVATE LIMITED will have no obligation to provide the services to the customer and/or
                        their authorized users after the effective date of the termination. PEDIAVERSE SOLUTIONS PRIVATE LIMITED shall promptly delete and/or destroy and information
                        that has been stored in their service database with respect to any electronic information of the customer transmitted or received by
                        it relating to the customer as well as its authorized users on the effective date of termination. In addition, the customer
                        acknowledges that although information in the service database will be deleted from its transaction servers, PEDIAVERSE SOLUTIONS PRIVATE LIMITED may
                        retain such information stored on automatic backup archiving systems during the period such backup or archived materials are
                        retained under PEDIAVERSE’S customary procedures and policies. Further, PEDIAVERSE SOLUTIONS PRIVATE LIMITED may terminate this Agreement immediately
                        upon notice to the customer if the customer has caused any breach to any of the provisions contained in this Agreement, in
                        order to comply with applicable laws or regulations or if there has been any default on the part of the customer in the timely
                        payment of any amounts due for the services.</p>

                    <Box id="el8" className="title">
                        <h4>8. SERVICE COMMITMENT AND SUPPORT</h4>
                    </Box>
                    <p>Excluding the scheduled maintenance windows, PEDIAVERSE SOLUTIONS PRIVATE LIMITED will use commercially reasonable efforts to maintain 99.8% availability
                        of the hosted portion of the Service for each month during the term of this Agreement. The service provided by PEDIAVERSE SOLUTIONS PRIVATE LIMITED will
                        be deemed "available" so long as the customer as well as their authorized users are able to login to the service interface and
                        access data. E-mail support shall be provided by PEDIAVERSE SOLUTIONS PRIVATE LIMITED although no response times are guaranteed; PEDIAVERSE SOLUTIONS PRIVATE LIMITED will use
                        commercially reasonable efforts to respond to such support requests within working 48 hours. PEDIAVERSE SOLUTIONS PRIVATE LIMITED may or may not delegate
                        the performance of certain portions of the support to third parties but will remain responsible to the customer for delivery
                        of the timely solutions. In the event any support/maintenance is not performed with reasonable skill, care, and diligence,
                        PEDIAVERSE will re-perform the support/maintenance to the extent necessary to correct the defective performance, and the
                        customer acknowledges that the re-performance will be the sole and exclusive remedy for any defective performance for
                        the customer.</p>

                    <p>Subject to this Agreement, PEDIAVERSE SOLUTIONS PRIVATE LIMITED will make the Service available to the customer and their authorized users during the
                        term of the services as agreed upon and PEDIAVERSE SOLUTIONS PRIVATE LIMITED hereby grants to the customer, during the service period, a non-exclusive,
                        non-transferable, limited right to enable its authorized users to access and use the services provided through the interface,
                        Website and mobile application in support of the internal business use of the customer only.</p>

                    <p>Consequently, the customer acknowledges that they and each of their authorized user's access and use of the services are subject to
                        PEDIAVERSE SOLUTIONS PRIVATE LIMITED's Privacy Policy which is incorporated into this Agreement.</p>

                    <Box id="el9" className="title">
                        <h4>9. NONUSE AND NONDISCLOSURE OF CONFIDENTIAL INFORMATION</h4>
                    </Box>
                    <p>As used in this Agreement, "Confidential Information" means any information that is proprietary or confidential to the Discloser
                        or that the Discloser is obligated to keep confidential. Confidential Information may be of a technical, business, or other nature.
                        However, Confidential Information does not include any information that:</p>
                    <ul>
                        <li>Was known to the Recipient prior to receiving the same from the Discloser in connection with this Agreement.</li>
                        <li>Is independently developed by the Recipient.</li>
                        <li>Is acquired by the Recipient from another source without restriction as to use or disclosure.</li>
                        <li>Is or becomes part of the public domain through no fault or action of the Recipient.</li>
                        <li>Each party reserves any and all right, title, and interest (including any intellectual property rights) that it may have in or to
                            any Confidential Information that it may disclose to the other party under this Agreement. The party that receives any
                            Confidential Information (the "Recipient") of the other party (the "Discloser") will protect Confidential Information of the
                            Discloser against any unauthorized Use or disclosure to the same extent that the Recipient protects its own Confidential
                            Information of a similar nature against Unauthorized Use or disclosure, but in no event will use less than a reasonable
                            standard of care to protect such Confidential Information; provided that the Confidential Information of the Discloser is
                            conspicuously marked or otherwise identified as confidential or proprietary upon receipt by the Recipient or the Recipient
                            otherwise knows or has reason to know that the same is Confidential Information of the Discloser.</li>
                        <li>The Recipient will use any Confidential Information of the Discloser solely for the purposes for which the Discloser provides it.</li>
                    </ul>

                    <p>This section will not be interpreted or construed to prohibit:</p>
                    <ul>
                        <li>Any use or disclosure which is necessary or appropriate in connection with the Recipient's performance of its obligations or exercise of
                            its rights under this Agreement or any other agreement between the parties.</li>
                        <li>Any use or disclosure required by applicable law, provided that the Recipient uses reasonable efforts to give the Discloser
                            reasonable advance notice to afford the Discloser an opportunity to intervene and seek an order or other appropriate relief for
                            the protection of its Confidential Information.</li>
                        <li>Any use or disclosure made with the consent of the Discloser.</li>
                    </ul>
                    <p>In the event of any breach or threatened breach by the Recipient of its obligations under this section, the Discloser will be entitled
                        to injunctive and other equitable relief to enforce such obligations. The obligations of confidentiality will survive expiration or
                        termination of this Agreement.</p>

                    <Box id="el10" className="title">
                        <h4>10. CUSTOMER DATA</h4>
                    </Box>
                    <p>(A) Ownership of the Data:<br />
                        The customer retains ownership, right, and responsibility to all text, document, audio, video, images, or other content that the
                        customer and/or any authorized user run on or through the services, subject only to the limited rights expressly granted in this
                        Agreement and intellectual property rights. The customer is solely responsible, and PEDIAVERSE SOLUTIONS PRIVATE LIMITED assumes no liability for the Customer
                        Data that authorized users or other third parties post, send, or otherwise make available over or through the services.</p>

                    <p>(B) License Grant to PEDIAVERSE SOLUTIONS PRIVATE LIMITED<br />
                        In order to provide and support the services for the benefit of the customer, the customer hereby grant PEDIAVERSE SOLUTIONS PRIVATE LIMITED a worldwide,
                        non-exclusive, royalty-free license during the Service Period to use, reproduce, electronically distribute, transmit, have
                        transmitted, perform, display, store, and archive the Customer Data. The customer agrees that, so long as no Confidential
                        Information is publicly disclosed, PEDIAVERSE SOLUTIONS PRIVATE LIMITED may:</p>
                    <ul>
                        <li>Use the Customer Data to refine, supplement, or test their services, in case of need.</li>
                        <li>Include aggregated and anonymized data in any publicly available reports, analyses, and promotional materials.</li>
                        <li>Retain anonymized, non-attributable data following any termination of this Agreement for use in connection with the above.</li>
                        <li>List the customer and use their name and logo on the official Website for publicly available customer lists and in media releases during the Service Period.</li>
                    </ul>

                    <Box id="el11" className="title">
                        <h4>11. INTELLECTUAL PROPERTY</h4>
                    </Box>
                    <p>As used in this Agreement, "Technology" means any and all know-how, processes, methodologies, specifications, designs, inventions,
                        functionality, graphics, techniques, methods, applications, computer programs, libraries, user manuals, documentation, products
                        or other technology and materials of any kind, or any enhancement thereto, used by PEDIAVERSE SOLUTIONS PRIVATE LIMITED in connection with the performance
                        of the services and support provided to the customer and their authorized users. Without limiting the above, Technology includes
                        the products, services, and technology made available through the Website, Service interface and any Software branded or co-branded
                        websites (including sub-domains, widgets, and/or mobile versions).</p>

                    <p>The Service and Technology constitute or otherwise involve valuable intellectual property rights of Software, and Software will own
                        all right, title, and interest in and to the above. No title to or ownership of the intellectual property rights associated therewith,
                        is transferred to the customer or any of their authorized users or any third party; if any; under this Agreement. Under no set of
                        circumstances will the customer be entitled to either access and/or review any object code or source code of PEDIAVERSE SOLUTIONS PRIVATE LIMITED and that
                        PEDIAVERSE SOLUTIONS PRIVATE LIMITED specifically reserves any and/or all rights to the Service and Technology not otherwise expressly granted herein.</p>

                    <Box id="el12" className="title">
                        <h4>12. LIMITATIONS OF LIABILITY</h4>
                    </Box>
                    <p>The customer understands and agrees that PEDIAVERSE SOLUTIONS PRIVATE LIMITED will not be liable to them or any third party for any damage, injury or loss of
                        profits, use, goodwill, or data or for any incidental, indirect, indirect, special, consequential or exemplary damages arising resulting from:</p>
                    <ul>
                        <li>The use, disclosure, or display of customers’ data.</li>
                        <li>For the usage or inability to use the services by the customer.</li>
                        <li>Any modification in price change, suspension, interruption, cessation of transmission, or discontinuance of the services.</li>
                        <li>The software or the systems that make the services available to the customer.</li>
                        <li>Unauthorized access to or alterations of the transmission or data of the customer.</li>
                        <li>Statements or conduct of any third party for the services.</li>
                        <li>Any other user interactions that the customer either provide or receive through the use of the services.</li>
                        <li>Hacking, tampering or other unauthorized access or use of the services pertaining to the accounting information of the customer.</li>
                        <li>Errors, mistakes, or inaccuracies of data of the customer.</li>
                        <li>Personal injury or property damage of the customer of whatsoever nature resulting from the access of the customer or their authorized users.</li>
                        <li>Any other matter relating to the services provided to the customer.</li>
                    </ul>
                    <p>The liability of PEDIAVERSE SOLUTIONS PRIVATE LIMITED is limited in nature; whether or not they have been informed of the possibility of such damages even if a
                        remedy described in this Agreement is found to have failed of its essential purpose. PEDIAVERSE SOLUTIONS PRIVATE LIMITED will have no liability for any failure
                        or delay due to matters beyond their reasonable control or due to any act of nature including pandemic situation.<br />

                        These limitations will however not apply to damages arising out of a party's failure to comply with its confidentiality obligations,
                        indemnification obligations or payment obligations for the services used or provided. Except for their respective indemnity and
                        confidentiality obligations, in no event will PEDIAVERSE SOLUTIONS PRIVATE LIMITED, their affiliates, directors, employees or licensors, if any; be liable
                        to the other party or their authorized users for any claims, proceedings, liabilities, obligations, damages, losses or costs in
                        an amount exceeding the payment made for the services availed by the customer.<br />

                        This limitation of liability section applies whether the alleged liability is based on contract, tort, negligence, strict liability,
                        or any other basis, even if the non-breaching party has been advised of the possibility of such damage. The above limitations of
                        liability will apply to the fullest extent permitted by law in the applicable jurisdiction as mentioned below.</p>

                    <Box id="el13" className="title">
                        <h4>13. REPRESENTATIONS AND INDEMNITIES</h4>
                    </Box>
                    <p>The customer hereby represents and warrants to PEDIAVERSE SOLUTIONS PRIVATE LIMITED that they have the authority to enter into and perform this Agreement and
                        that performance of the Agreement's obligations and exercise of its rights do not and will not violate any applicable laws,
                        regulations, or orders. The customer hereby represents, warrants, and covenants that:</p>

                    <ul>
                        <li>The customer owns all right, title, and interest in and to their data.</li>
                        <li>The customer has all rights to over their data necessary to grant the rights contemplated by this Agreement.</li>
                        <li>The customer has obtained the necessary third-party approvals, including without limitations applicable vendors and licensors
                            in relation to third-party content to be used by them in connection with the services prior to the use of the software and its
                            related services under this Agreement.</li>
                    </ul>

                    <p>PEDIAVERSE SOLUTIONS PRIVATE LIMITED hereby represents, warrants, and covenants to the customer that:</p>
                    <ul>
                        <li>The services provided to the customer and used in accordance with this Agreement will not infringe on any intellectual property
                            right or other right of any other person or entity.</li>
                        <li>That PEDIAVERSE SOLUTIONS PRIVATE LIMITED has the ownership and proprietorship rights in the software and for its services contemplated by this Agreement.</li>
                        <li>The customer agrees to defend, indemnify, and hold harmless PEDIAVERSE SOLUTIONS PRIVATE LIMITED and its employees, contractors, agents, officers and
                            directors from and against any and/or all claims, damages, obligations, losses, liabilities, costs or debt and expenses
                            (including without limitation attorneys' fees) arising out of or related to:
                            <ul>
                                <li>Use by the customer or their authorized user to the services provided to them.</li>
                                <li>Violation of any term of this Agreement.</li>
                                <li>Violation of any third-party right including without limitation any right of privacy, publicity rights or intellectual
                                    property rights.</li>
                                <li>Violation of any law, rule or regulation framed by either the Central Government, State Government, Quasi-judicial
                                    authorities, etc.</li>
                                <li>Any claim for damages that arise as a result of any data of the customer.</li>
                                <li>Any other party's access and use of the services with provided identifier(s) and password(s).</li>
                            </ul>
                        </li>
                    </ul>
                    <p>Consequently, PEDIAVERSE SOLUTIONS PRIVATE LIMITED agrees to defend, indemnify, and hold harmless the customer from and against any and all claims, damages,
                        obligations, losses, liabilities, costs or debt and expenses (including without limitation attorneys' fees) arising out of or
                        related to the breach of any representation, warranty or obligation in this Agreement or for any violation of any law, rule or
                        regulation done by them.</p>
                    <p>In case any infringement of any of the Intellectual Property Rights of PEDIAVERSE SOLUTIONS PRIVATE LIMITED is brought to the notice of the customer, they
                        shall inform about the same accordingly based on which PEDIAVERSE SOLUTIONS PRIVATE LIMITED shall initiate the necessary legal action(s) against such
                        infringer and shall bear the costs and expenses arising out of the same failing which no kind of any claims, penalties, damages,
                        costs, etc. arising out of such unlawful activities shall be paid, reimbursed or adjusted by PEDIAVERSE SOLUTIONS PRIVATE LIMITED with the services provided
                        to the customer under any set of circumstances.</p>
                    <p>PEDIAVERSE SOLUTIONS PRIVATE LIMITED will have no liability for any infringement claim to the extent it:</p>
                    <ul>
                        <li>Is based on modification of the services based on any other software used by the customer.</li>
                        <li>Results from failure to use any updated version of software provided to the customer.</li>
                        <li>Is based on the combination or use of the software with any other software, program or device not provided by PEDIAVERSE SOLUTIONS PRIVATE LIMITED wherein
                            such infringement would not have arisen but for such use or combination.</li>
                        <li>Results from non-compliance with the designs, plans or specifications furnished by the customer for the services to be made
                            operational.</li>
                        <li>Results from wrong operation of the software in a manner that is inconsistent with its intended use.</li>
                    </ul>

                    <Box id="el14" className="title">
                        <h4>14. MISCELLANEOUS</h4>
                    </Box>
                    <p>(A) Non-Assignability by customer:<br />
                        The customer shall not delegate, assign, or transfer this Agreement or any of its rights and obligations under this Agreement
                        and any attempt to do so will be void.</p>

                    <p>(B) Governing Law and Jurisdiction:<br />
                        Except to the extent applicable law provides otherwise, this Agreement between the parties for the services provided to the customer
                        is governed by the Laws in India including the rules, regulations, notices, circulars, etc. passed by either the Central
                        Government and/or State Government and/or Quasi-judicial authorities, Corporations, etc. and as amended from time to time.
                        Both the parties agree to place any dispute, issue, etc. to the exclusive jurisdiction of the Hon’ble Courts situated in
                        Ahmedabad, Gujarat, India only. The same shall include the Arbitration proceedings guided as per The Arbitration and
                        Conciliation Act, 1996 which shall also be initiated in Ahmedabad, Gujarat, India.</p>

                    <p>(C) Use of the services in India and abroad:<br />
                        The services provided by PEDIAVERSE SOLUTIONS PRIVATE LIMITED under this Agreement are strictly restricted to the business activities of the customer
                        which are situated in the territory of India as well as across the globe. In connection thereto, the customer shall avail the
                        necessary permissions, licenses, hardware, etc. in order to enable them to utilize the services provided to them. No kind of
                        any unlawful activities, deals, business, trading, import, export, etc. shall be carried out by the customer through the
                        services provided to them and for which PEDIAVERSE SOLUTIONS PRIVATE LIMITED shall not be liable for any action(s), liabilities (financial or otherwise),
                        costs, expenses, etc. arising out of the same.</p>

                    <p>(D) Obligations of Parties<br />
                        The parties expressly understand and agree that their relationship is that of independent contractors. Nothing in this Agreement
                        will constitute one party as an employee, agent, joint venture partner, or servant of another.<br />
                        PEDIAVERSE SOLUTIONS PRIVATE LIMITED will have no liability to the customer or to its authorized users or third parties for any failure and/or delay in
                        performing any obligation(s) under this Agreement due to circumstances beyond its reasonable control including without limitation
                        acts of God or nature, pandemics, fires, floods, strikes, civil disturbances or terrorism or interruptions in power, communications,
                        satellites, the Internet or any other network that are beyond its control.</p>

                    <p>(E) Communications<br />
                        Intimations, communications, notices, circulars, instructions, etc. shall be provided by either of the party to the other on the
                        addresses mentioned at the time of entering into this Agreement. However, intimations through any form of online communication
                        including email correspondence shall be acceptable to either of the parties. However, no kind of communication shall be made
                        over any kind of web based mobile applications and that the same shall not be treated as any communication. Non-communication
                        or failure of response from either party of for the communication sent by the other, specifically directing a response shall
                        be construed as a breach of the terms and conditions of this Agreement.</p>

                    <p>(F) Severability, No Waiver:<br />
                        If any part of this Agreement is held invalid or unenforceable, that portion of the Agreement will be construed to reflect the
                        parties' original intent. The remainder of the Agreement will remain in full force and in full effect. Any failure on the part
                        of PEDIAVERSE SOLUTIONS PRIVATE LIMITED to enforce any provision of this Agreement will not be considered a waiver of our right to enforce such provision
                        and therefore the rights under this Agreement will survive even after termination of this Agreement.</p>

                    <p>(G) Complete Agreement<br />
                        The terms and conditions mentioned hereinabove including Privacy Policy represents the complete and exclusive between the customer
                        and PEDIAVERSE SOLUTIONS PRIVATE LIMITED and that the same shall supersede any proposal or prior agreements, oral or written and any other communications
                        between the customer and PEDIAVERSE SOLUTIONS PRIVATE LIMITED relating to the subject matter of these terms.</p>
                </Box>
            </Box>

            <Box className="bottom-part">
                <Typography className="left-part">Copyrights &copy; {new Date().getFullYear()} - PEDIAVERSE SOLUTIONS PRIVATE LIMITED</Typography>
                <Typography className="right-part" onClick={() => openPolicy()}>Terms of Service &nbsp;<span>Privacy Policy</span></Typography>
                <Typography className="right-part" onClick={() => openDeclaimer()}><span>Declaimer</span></Typography>
            </Box>
        </Box >
    );
}
