import React, { useContext, useEffect, useState } from "react";
import "./SymptomsCategory.scss";
import { AppBar, Box, Card, Dialog, DialogContent, Fade, Grid, IconButton, Slide, Toolbar, Typography } from "@mui/material";
import backIcon from '../../assets/icons/back.svg';
import healthAssistantIcon from "../../assets/icons/health assistant.svg";
import noData from "../../assets/icons/no-data.svg";
import { useLocation, useNavigate } from "react-router-dom";
import { PreferencesContext } from "../../PreferenceContext";
import { GetMemberMedicalHistoryList } from "../../services/MedicalHistoryService";
import dayjs from "dayjs";
import CloseIcon from '@mui/icons-material/Close';
import { GetInstruction } from '../../services/InstructionService';
import { TransitionProps } from "@mui/material/transitions";
import CustomPopup from "../CustomPopup/CustomPopup";

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

let init: any;
export default function SymptomsCategory() {
    const { userContext, updateUserContext } = useContext(PreferencesContext);
    const [loading, setLoading] = useState(false);
    const [submoduleLoading, setSubmoduleLoading] = useState(false);
    const [headerName, setHeaderName] = useState('');
    const [submoduleData, setSubmoduleData] = useState([]);
    const [memberHistoryData, setMemberHistoryData] = useState([]);
    const [emergencyConditionData, setEmergencyConditionData] = useState(init);
    const [showAlert, setAlertMessage] = useState({
        isSuccess: false,
        message: "",
    });
    const [openEmergencyConditionDialog, setOpenEmergencyConditionDialog] = useState(false);

    const navigate = useNavigate();
    const location = useLocation();

    function backClick() {
        navigate("/medical-history");
    }

    function getCurDateData(entryDate: any) {
        var date = "";
        if (entryDate !== undefined && entryDate !== null && entryDate !== "") {
            date = dayjs(entryDate).format('DD/MM/YYYY');
        }
        return date;
    }

    async function fetchSubModules() {
        setSubmoduleLoading(true);
        if (userContext.moduleConfiguration !== undefined) {
            let data = userContext.moduleConfiguration
            if (location.state.submoduleCode !== undefined && location.state.submoduleCode !== null && location.state.submoduleName !== undefined && location.state.submoduleName !== null) {
                let curSubmodule: any = userContext.moduleConfiguration.find((x: any) => x.moduleCode === location.state.submoduleCode);
                if (curSubmodule !== undefined) {
                    setSubmoduleData(curSubmodule.submodule.sort())
                    setHeaderName(location.state.submoduleName);
                }
                else {
                    setHeaderName(location.state.submoduleName);
                    let params = new URLSearchParams();
                    params.append("familyId", userContext.loginDetail.familyId.toString());
                    params.append("familyMemberId", userContext.memberDetail.familyMemberId.toString());
                    const memberMedicalHistoryData: any = await GetMemberMedicalHistoryList(params);
                    setMemberHistoryData(memberMedicalHistoryData.data.data)
                }
            }
        }
    }
    async function fetchInstruction(descriptionCode: any) {
        setLoading(true);
        try {
            let params = new URLSearchParams();
            params.append("code", descriptionCode)
            const result: any = await GetInstruction(params);
            let emergencyConditionDetail = result.data.data;
            setEmergencyConditionData(emergencyConditionDetail);
            setOpenEmergencyConditionDialog(true);
        } catch (error) {
            if (error) {
                setAlertMessage({
                    isSuccess: true,
                    message: error as string,
                });
            }
        }
        setLoading(false);
    }
    function goToSubmoduleDetail(data: any) {
        if (data.submoduleCode === "MANAGEMENT_IN_EMERGENCY_CONDITIONS") {
            fetchInstruction(data.submoduleCode);
        } else {
            updateUserContext({
                ...userContext,
                symptomsCode: data.submoduleCode,
                symptomsName: data.submoduleName
            });
            navigate('/symptoms-list',
                { state: { symptomsCode: data.submoduleCode, symptomsName: data.submoduleName, symptomsId: [] } }
            );
        }

    }

    const handleCloseEmergencyConditionDialog = () => {
        setOpenEmergencyConditionDialog(false);
    };

    function goToSymtomsDetail(memberSymptomsId: any) {
        navigate('/symptoms-questions',
            { state: { symptomsId: [], memberSymptomsId: memberSymptomsId } }
        );
    }

    useEffect(() => {
        if (userContext.memberDetail !== undefined && userContext.memberDetail.familyMemberId > 0) {
            fetchSubModules();
        }
    }, []);

    return (
        <>
            <Fade in={true} timeout={{ enter: 500 }}>
                <Box className="symptoms-category-main-box">
                    <Box className="head-box">
                        <Box className="back-box">
                            <IconButton aria-label="back" className='back-icon' disableRipple
                                onClick={() => backClick()}>
                                <img src={backIcon} alt='logo' />
                            </IconButton>
                        </Box>
                        <Box className="title-box">
                            <Typography className="title-text">{headerName}</Typography>
                        </Box>
                        <Box className="profile-box">
                            <></>
                        </Box>
                    </Box>

                    <Box className="body-box">
                        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} className='main-container-grid'>
                            {memberHistoryData !== undefined && memberHistoryData.length > 0 ?
                                (memberHistoryData.map((row: any, index: any) => (
                                    <Grid key={index} item xs={6} sm={6} md={12} lg={12} xl={12}className='subcontainer-grid'>
                                        <Card className='inner-card' onClick={() => goToSymtomsDetail(row.memberSymptomsId)}>
                                            <Box className='middle-img'><img src={healthAssistantIcon} alt='logo' /></Box>
                                            <Box className='card-upper-box' ><Typography className='upper-text'>{getCurDateData(row.entryDate)}</Typography></Box>
                                        </Card>
                                    </Grid>
                                ))) :
                                (submoduleData.length > 0 ?
                                    submoduleData.map((row: any, index: any) => (
                                        <Grid key={index} item xs={6} sm={6} md={12} lg={12} xl={12} className='subcontainer-grid'>
                                            {row.submoduleCode === "SYSTEM_WISE_DISORDERS" ? <></> :
                                                <Card className='inner-card' onClick={() => goToSubmoduleDetail(row)}>
                                                    <Box className='middle-img'><img src={healthAssistantIcon} alt='logo' /></Box>
                                                    <Box className='card-upper-box' ><Typography className='upper-text'>{row.submoduleName}</Typography></Box>
                                                </Card>
                                                }
                                        </Grid>
                                    )) : <Box className="no-data-box">
                                        <Box className="upper-box"></Box>
                                        <Box className="bottom-box">
                                            <Box className="img-box">
                                                <img src={noData} alt="logo" />
                                            </Box>
                                            <Box className="no-summary-box">
                                                <Typography className="no-summary-text">
                                                    No data available
                                                </Typography>
                                            </Box>
                                        </Box>
                                    </Box>)
                                    }
                        </Grid>
                    </Box>
                </Box>


            </Fade >
            <Dialog
                fullScreen
                open={openEmergencyConditionDialog}
                onClose={handleCloseEmergencyConditionDialog}
                TransitionComponent={Transition}
                className="custom-popup-symptoms-category">
                {emergencyConditionData !== undefined && emergencyConditionData !== null && emergencyConditionData.map((row: any, index: any) => (
                    <React.Fragment key={index}>
                        <AppBar sx={{ position: 'relative' }} color='secondary'>
                            <Toolbar className='custom-popup-header'>

                                <Box className='header-empty-box'></Box>
                                <Box className='header-text-box'>
                                    <Typography variant="h6" component="div">
                                        {row.title}
                                    </Typography>
                                </Box>
                                <Box className='close-box'>
                                    <IconButton
                                        edge="start"
                                        color="inherit"
                                        onClick={handleCloseEmergencyConditionDialog}
                                        aria-label="close"
                                        className='close-button'>
                                        <CloseIcon />
                                    </IconButton>
                                </Box>
                            </Toolbar>
                        </AppBar>
                        <DialogContent>
                            <CustomPopup descriptionData={row.description} />
                        </DialogContent>
                    </React.Fragment>
                ))}
            </Dialog>
        </>
    );
}
