import React, { useContext, useEffect, useRef, useState } from 'react';
import "./SymptomsQuestions.scss";
import { Box, Button, Checkbox, Collapse, Fade, FormControl, FormControlLabel, Grid, IconButton, InputAdornment, OutlinedInput, Popover, Skeleton, TextField, Typography, styled, useTheme } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { CreateSymptomsQuestionAnswer, GetSymptomsDetail } from '../../services/MedicalHistoryService';
import LinearProgress, { linearProgressClasses } from "@mui/material/LinearProgress";
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateCalendar, MobileDatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import backIcon from '../../assets/icons/back.svg';
import { PreferencesContext } from '../../PreferenceContext';
import Slider from "react-slick";
import Select from 'react-select';
import { TransitionGroup } from "react-transition-group";
import { LoadingButton } from '@mui/lab';
import { AlertType } from '../../shared/AlertType';
import { ageInDays, getSingleSelectedForHealthAssitant } from '../../shared/CommonFuctions';
import { Calendar } from 'react-date-range';
import CalendarMonthRoundedIcon from '@mui/icons-material/CalendarMonthRounded';

interface RenderQuestionStack {
    row: any;
}

interface SymptomsAnswers {
    symptomsId: number;
    fieldType: string;
    fieldId: number;
    answer: string;
    symptomsFieldDisplayOrder: number;
}
let initData: any;

export default function SymptomsQuestions() {
    const { userContext, updateUserContext } = useContext(PreferencesContext);
    const [loading, setLoading] = useState(false);
    const [symptomsDetail, setSymptomsDetail] = useState([]);
    const [symptomsFieldData, setSymptomsFieldData] = useState([]);
    const [symptomsAnswers, setSymptomsAnswers] = useState(initData);
    const [enablePrev, setEnablePrev] = useState(false);
    const [enableNext, setEnableNext] = useState(false);
    const [showNextButton, setShowNextButton] = useState(false);
    const [symptomsDataLength, setSymptomsDataLength] = useState(0);
    const [progressBar, setProgressBar] = useState(0);
    const [curIndex, setCurIndex] = useState(1);
    const [nextSlide, setNextSlide] = useState(0);
    const [illnessDays, setIllnessDays] = useState(initData);
    const [showAlert, setAlertMessage] = useState({
        isSuccess: false,
        message: "",
    });

    const [isCalenderOpen, setIsCalenderOpen] = useState<HTMLButtonElement | null>(null);
    const mainTheme = useTheme();
    const navigate = useNavigate();
    const location = useLocation();
    let tempValue: any;
    let sliderRef: any = useRef(null);

    let SliderSettings = {
        dots: false,
        infinite: false,
        slidesToShow: 1,
        autoplay: false,
        arrows: false,
        slidesToScroll: 1,
        swipe: false,
        beforeChange: (current, next) => beforeChange(current, next),
    };

    const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
        height: 8,
        borderRadius: 5,
        [`&.${linearProgressClasses.colorPrimary}`]: {
            backgroundColor:
                theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
        },
        [`& .${linearProgressClasses.bar}`]: {
            borderRadius: 5,
            backgroundColor: "#BC3965",
        },
    }));

    function backClick() {
        if (location.state.memberSymptomsId === 0) {
            navigate('/symptoms-list',
                { state: { symptomsCode: userContext.symptomsCode, symptomsName: userContext.symptomsName, symptomsId: location.state.symptomsId } }
            );
        } else {
            navigate('/symptoms',
                { state: { submoduleCode: userContext.symptomsCategoryCode, submoduleName: userContext.symptomsCategoryName } }
            );
        }
    }

    const clickToOpenCalender = (
        event: React.MouseEvent<HTMLButtonElement>
    ) => {
        setIsCalenderOpen(event.currentTarget);
    };

    const clickToCloseCalender = () => {
        setIsCalenderOpen(null);
    };

    function chooseDate(selected: any) {
        let days: any;
        days = ageInDays(dayjs(selected));
        setIllnessDays(days);
        setIsCalenderOpen(null);
    }

    function renderQuestion({ row }: RenderQuestionStack) {
        return (
            <Grid container className="question-box">
                {/* {row.fieldType === "DATE" ?
                    <Grid item xs={12} sx={{ width: '100%' }}>
                        <OutlinedInput
                            id={row.fieldDisplayName}
                            fullWidth
                            size="small"
                            type='text'
                            label={row.fieldDisplayName}
                            placeholder={row.fieldDisplayName}
                            disabled={location.state.memberSymptomsId !== 0 ? true : false}
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        color='primary'
                                        aria-label="toggle password visibility"
                                        onClick={location.state.memberSymptomsId !== 0 ? clickToCloseCalender : clickToOpenCalender}
                                        edge="end">
                                        <CalendarMonthRoundedIcon />
                                    </IconButton>
                                </InputAdornment>
                            }
                            value={row.answer !== null && row.answer !== "" ? row.answer : tempValue}
                            onClick={row.answer !== null && row.answer !== "" ? handleChange(tempValue, row.symptomsId, row.fieldId, row.fieldType, row.symptomsFieldDisplayOrder) : tempValue}
                        />
                        <Popover
                            open={Boolean(isCalenderOpen)}
                            anchorEl={isCalenderOpen}
                            onClose={clickToCloseCalender}
                            anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "left",
                            }}
                            transformOrigin={{
                                vertical: "top",
                                horizontal: "center",
                            }}>
                            <Box>
                                <Calendar
                                    date={new Date()}
                                    onChange={(e: any) => handleChange(e, row.symptomsId, row.fieldId, row.fieldType, row.symptomsFieldDisplayOrder)}
                                    color={mainTheme.palette.primary.main}
                                    maxDate={new Date()} />
                            </Box>
                        </Popover>
                    </Grid>
                    : <></>} */}

                {row.fieldType === "SINGLE_SELECT" ?
                    <Grid item xs={12} sx={{ width: '100%' }}>
                        {/* <Typography>{row.fieldDisplayName}</Typography> */}
                        <FormControl fullWidth style={{ width: '100%' }}>
                            <Select
                                id={row.fieldDisplayName}
                                isDisabled={location.state.memberSymptomsId !== 0 ? true : false}
                                placeholder={row.fieldDisplayName}
                                classNamePrefix={row.fieldDisplayName}
                                styles={row.fieldDisplayName === "Days" ? selectStylesForDropdownDays : selectStyles}
                                theme={(theme) => ({
                                    ...theme,
                                    colors: {
                                        ...theme.colors,
                                        primary: "#0B7CAA",
                                        primary25: "#ecebef",
                                        primary50: "#e1dfea",
                                    },
                                })}
                                onChange={(e: any, value) => handleChange(e, row.symptomsId, row.fieldId, row.fieldType, row.symptomsFieldDisplayOrder)}
                                value={row.answer !== null && row.answer !== "" ?
                                    getSingleSelectedForHealthAssitant(
                                        row.answer,
                                        symptomsFieldData.filter((x: any) => x.fieldCode === row.fieldCode)
                                    ) : tempValue}
                                getOptionLabel={option => option.multiFieldLabel}
                                getOptionValue={option => option.multiFieldValue}
                                options={symptomsFieldData.filter((x: any) => x.fieldCode === row.fieldCode)}
                            />
                        </FormControl>
                    </Grid>
                    : <></>}

                {row.fieldType === "TEXTBOX" ?
                    <Grid item xs={12} sx={{ width: '100%' }}>
                        <TextField
                            id={row.fieldDisplayName}
                            label={row.fieldDisplayName}
                            disabled={location.state.memberSymptomsId !== 0 ? true : false}
                            fullWidth
                            size="small"
                            variant="outlined"
                            value={row.answer !== null && row.answer !== "" ? row.answer : tempValue}
                            onChange={(e: any) => handleChange(e, row.symptomsId, row.fieldId, row.fieldType, row.symptomsFieldDisplayOrder)}
                        />
                    </Grid>
                    : <></>}

                {row.fieldType === "MULTI_CHECKBOX" ?
                    <Grid item xs={12} sx={{ width: '100%' }}>
                        <FormControlLabel
                            value={row.fieldDisplayName}
                            disabled={location.state.memberSymptomsId !== 0 ? true : false}
                            control={<Checkbox
                                onChange={(e: any) =>
                                    handleChange(e, row.symptomsId, row.fieldId, row.fieldType, row.symptomsFieldDisplayOrder)
                                }
                            // checked={row.answer !== "" ? true : false}
                            />}
                            label={row.fieldDisplayName}
                            labelPlacement="end" />
                    </Grid>
                    : <></>}

                {row.fieldType === "TEXTAREA" ?
                    <Grid item xs={12} sx={{ width: '100%' }}>
                        <TextField
                            id={row.fieldDisplayName}
                            label={row.fieldDisplayName}
                            disabled={location.state.memberSymptomsId !== 0 ? true : false}
                            fullWidth
                            multiline
                            rows={4}
                            size="small"
                            variant="outlined"
                            value={row.answer !== null && row.answer !== "" ? row.answer : tempValue}
                            onChange={(e: any) => handleChange(e, row.symptomsId, row.fieldId, row.fieldType, row.symptomsFieldDisplayOrder)}
                        />
                    </Grid>
                    : <></>}

                {row.fieldType === "LABEL" ?
                    <Grid item xs={12} sx={{ width: '100%' }}>
                        <Typography>{row.fieldDisplayName}</Typography>
                    </Grid>
                    : <></>}
            </Grid>
        );
    }

    const handleChange = (e: any, symptomsId, fieldId, fieldType, symptomsFieldDisplayOrder) => {
        let days: any;
        if (fieldType === "DATE") {
            days = ageInDays(dayjs(e));
            setIsCalenderOpen(null);
        }
        let allSymptomsAnswers = symptomsAnswers;
        let answer: SymptomsAnswers = {
            symptomsId: symptomsId,
            fieldType: fieldType,
            fieldId: fieldId,
            answer: (fieldType === "TEXTBOX" || fieldType === "TEXTAREA") ? e.target.value : fieldType === "MULTI_CHECKBOX" ? e.target.value : fieldType === "DATE" ? days.toString() : e.multiFieldValue,
            symptomsFieldDisplayOrder: symptomsFieldDisplayOrder
        };
        if (allSymptomsAnswers !== undefined) {
            let index = allSymptomsAnswers.findIndex(x => x.symptomsId === symptomsId && x.fieldId === fieldId);
            if (index >= 0) {
                allSymptomsAnswers[index].answer = (fieldType === "TEXTBOX" || fieldType === "TEXTAREA") ? e.target.value : fieldType === "MULTI_CHECKBOX" ? e.target.value : fieldType === "DATE" ? days.toString() : e.multiFieldValue;
                setSymptomsAnswers(allSymptomsAnswers);
            } else {
                setSymptomsAnswers([...symptomsAnswers, answer]);
            }

        } else {
            setSymptomsAnswers([answer]);
        }
    };

    const previousSymtoms = () => {
        sliderRef.slickPrev();
        setCurIndex(curIndex - 1)
    }

    const nextSymtoms = () => {
        sliderRef.slickNext();
        setCurIndex(curIndex + 1)
    };

    const beforeChange = (prev, next) => {
        setNextSlide(next)
        if (symptomsDataLength === next) {

            setEnableNext(true);
        }
        else {
            setEnableNext(false);
        }
        if (prev === 1 && next === 0) {
            setEnablePrev(true);
            setShowNextButton(false);
        } else {
            setEnablePrev(false);
            setShowNextButton(false);
        }
    };

    async function submitSymptomsQuestionAnswer() {
        if (location.state.memberSymptomsId !== 0) {
            setShowNextButton(true);
        } else {
            let data = {
                familyId: userContext.memberDetail.familyId,
                familyMemberId: userContext.memberDetail.familyMemberId,
                symptomsAnswer: symptomsAnswers,
                isDeleted: false,
                dataManagedBy: userContext.loginDetail.userId,
            };
            try {
                const resultSymptomsQuestionAnswerDataSave: any = await CreateSymptomsQuestionAnswer(data);
                if (resultSymptomsQuestionAnswerDataSave.data === true) {
                    navigate("/dashboard");
                }
            } catch (error) {
                if (error) {
                    updateUserContext({
                        ...userContext,
                        isAlert: true,
                        alertMessage: error as string,
                        alertType: AlertType.Error,
                    });
                }
            }
        }
    }

    async function fetchSymptomsQuestions(id: any, memberSymptomsId: any) {
        setLoading(true);
        let symptomsIds: any
        if (id.length !== 0) {
            symptomsIds = id.map((symptomsId: any) => symptomsId).join(",")
        } else {
            symptomsIds = 0
        }
        try {
            let params = new URLSearchParams();
            params.append("symptomsId", symptomsIds);
            params.append("memberSymptomsId", memberSymptomsId);
            const result: any = await GetSymptomsDetail(params);
            let symptomsData = result.data.data.symptomsData
            let fieldData = result.data.data.fieldData
            let tem: any = [];
            if (id.length !== 0) {
                // for initial
                id.forEach(el => {
                    let arr: any = [];
                    for (let i = 0; i < symptomsData.length; i++) {
                        if (symptomsData[i].symptomsId === +el) {
                            arr.push(symptomsData[i]);
                        }
                    }
                    tem.push(arr);
                });
            } else {
                // for fetch data
                let curData = symptomsData.map(item => item.symptomsId).filter((value: any, index: any, self: any) => self.indexOf(value) === index)
                curData.forEach(el => {
                    let arr: any = [];
                    for (let i = 0; i < symptomsData.length; i++) {
                        if (symptomsData[i].symptomsId === +el) {
                            arr.push(symptomsData[i]);
                        }
                    }
                    tem.push(arr);
                });
            }
            setSymptomsDataLength(tem.length - 1);
            setSymptomsDetail(tem);
            setSymptomsFieldData(fieldData);
        } catch (error) {
            if (error) {
                setAlertMessage({
                    isSuccess: true,
                    message: error as string,
                });
            }
        }
        setLoading(false);
    }

    useEffect(() => {
        if (location.state.symptomsId !== undefined && location.state.symptomsId !== null && location.state.memberSymptomsId !== undefined && location.state.memberSymptomsId !== null) {
            fetchSymptomsQuestions(location.state.symptomsId, location.state.memberSymptomsId);
            setProgressBar(100 / location.state.symptomsId.length)
        }
        setEnablePrev(true);
    }, []);

    return (
        <Fade in={true} timeout={{ enter: 500 }}>
            <Box className="symptoms-question-main-box">
                <Box className="head-box">
                    <Box className="back-box">
                        <IconButton aria-label="back" className='back-icon' disableRipple
                            onClick={() => backClick()}>
                            <img src={backIcon} alt='logo' />
                        </IconButton>
                    </Box>
                    <Box className="title-box">
                        {/* <Typography className="title-text">{headerName}</Typography> */}
                    </Box>
                    <Box className="profile-box">
                        <></>
                    </Box>
                </Box>

                <Box className="body-box">
                    <Box className="question-title-box">
                        <BorderLinearProgress
                            variant="determinate"
                            value={progressBar * curIndex} />
                    </Box>
                    <Slider
                        ref={(slider) => {
                            sliderRef = slider;
                        }}
                        {...SliderSettings}>
                        {loading === true ?
                            <Skeleton animation="wave" variant="rounded" width={100} height={300} />
                            :
                            (symptomsDetail.length > 0 && symptomsDetail.map((mainItem: any, index) => (
                                <React.Fragment key={index}>
                                    <Box className="question-main-box">
                                        {mainItem.map((row: any, ind: any) => (
                                            <Box key={ind} sx={{ zIndex: 10 }} className="inner-box">
                                                {row.fieldType === "SINGLE_CHECKBOX" ? <Typography className="title-text">{row.fieldDisplayName}</Typography> : <></>}
                                                <TransitionGroup className="question-group">
                                                    <Collapse timeout={500}>
                                                        {renderQuestion({
                                                            row,
                                                        })}
                                                    </Collapse>
                                                </TransitionGroup>
                                            </Box>
                                        ))}
                                    </Box>
                                    <Box className="bottom-buttons">
                                        <LoadingButton
                                            variant='contained'
                                            disabled={enablePrev === true ? true : false}
                                            onClick={previousSymtoms}
                                            className='prev-btn'>Previous</LoadingButton>
                                        {showNextButton ? <></> :
                                            <LoadingButton
                                                variant='contained'
                                                className='next-btn'
                                                // disabled={enableNext === false ? true : false}
                                                onClick={enableNext ? submitSymptomsQuestionAnswer : () => nextSymtoms()}>
                                                {enableNext === true ? (location.state.memberSymptomsId !== 0 ? "Next" : "Submit") : "Next"}
                                            </LoadingButton>}
                                    </Box>
                                </React.Fragment>
                            )))}
                    </Slider>
                </Box>
            </Box>
        </Fade>
    )
}

const selectStylesForDropdownDays = {
    menuPortal: base => ({ ...base, zIndex: 999 })
}

const selectStyles = {
    menuPortal: base => ({ ...base, zIndex: 99 })
};