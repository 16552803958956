import React, { useContext, useEffect, useState } from "react";
import './Notifications.scss'
import { useNavigate } from "react-router-dom";
import { Box, Button, Fade, IconButton, Skeleton, Typography } from "@mui/material";
import { PreferencesContext } from "../../PreferenceContext";
import { GetNotificationList } from "../../services/NotificationMangerService";
import backIcon from '../../assets/icons/back.svg';
import noNotification from "../../assets/images/no-notification.svg";

export default function Notifications() {
    const { userContext, updateUserContext } = useContext(PreferencesContext);
    const [loading, setLoading] = useState(false);
    const [notificationDataList, setNotificationDataList] = useState([]);
    const [showAlert, setAlertMessage] = useState({
        isSuccess: false,
        message: "",
    });
    const navigate = useNavigate();

    const goToHome = () => {
        navigate("/dashboard");
    }

    const goToPage = (redirectPage: any) => {
        // navigate(redirectPage);
        let curSubmodule: any

        if (redirectPage === '/born-care') {
            curSubmodule = userContext.moduleConfiguration.find(
                (x: any) => x.moduleCode === "CARE_OF_NEWBORN"
            );
        }
        else if (redirectPage === '/diet-nutrition') {
            curSubmodule = userContext.moduleConfiguration.find(
                (x: any) => x.moduleCode === "DIET_NUTRITION"
            );
        }
        else if (redirectPage === '/milestone-development') {
            curSubmodule = userContext.moduleConfiguration.find(
                (x: any) => x.moduleCode === "MILESTONE_DEVELOPMENT"
            );
        }
        else if (redirectPage === '/vaccination') {
            curSubmodule = userContext.moduleConfiguration.find(
                (x: any) => x.moduleCode === "VACCINATION"
            );
        }
        else if (redirectPage === '/physical-growth') {
            curSubmodule = userContext.moduleConfiguration.find(
                (x: any) => x.moduleCode === "PHYSICAL_GROWTH"
            );
        }
        else if (redirectPage === '/mid-parental-height') {
            curSubmodule = userContext.moduleConfiguration.find(
                (x: any) => x.moduleCode === "PHYSICAL_GROWTH" && x.submodule.find((y: any) => y.submoduleCode === "MID_PARENTAL_HEIGHT")
            );
        }
        else if (redirectPage === '/bmi') {
            curSubmodule = userContext.moduleConfiguration.find(
                (x: any) => x.moduleCode === "PHYSICAL_GROWTH" && x.submodule.find((y: any) => y.submoduleCode === "BMI")
            );
        }
        else if (redirectPage === '/head-circumference') {
            curSubmodule = userContext.moduleConfiguration.find(
                (x: any) => x.moduleCode === "PHYSICAL_GROWTH" && x.submodule.find((y: any) => y.submoduleCode === "HEAD_CIRCUMFERENCE")
            );
        }
        else if (redirectPage === '/height') {
            curSubmodule = userContext.moduleConfiguration.find(
                (x: any) => x.moduleCode === "PHYSICAL_GROWTH" && x.submodule.find((y: any) => y.submoduleCode === "HEIGHT")
            );
        }
        else if (redirectPage === '/weight') {
            curSubmodule = userContext.moduleConfiguration.find(
                (x: any) => x.moduleCode === "PHYSICAL_GROWTH" && x.submodule.find((y: any) => y.submoduleCode === "WEIGHT")
            );
        }
        else if (redirectPage === '/vaccination?Add=1') {
            curSubmodule = userContext.moduleConfiguration.find(
                (x: any) => x.moduleCode === "VACCINATION"
            );
        }
        else if (redirectPage === '/milestone-questions') {
            curSubmodule = userContext.moduleConfiguration.find(
                (x: any) => x.moduleCode === "MILESTONE_DEVELOPMENT"
            );
        }
        else if (redirectPage === '/temperature') {
            curSubmodule = userContext.moduleConfiguration.find(
                (x: any) => x.moduleCode === "CARE_OF_NEWBORN" && x.submodule.find((y: any) => y.submoduleCode === "TEMPERATURE")
            );
        }
        else if (redirectPage === '/feeding') {
            curSubmodule = userContext.moduleConfiguration.find(
                (x: any) => x.moduleCode === "CARE_OF_NEWBORN" && x.submodule.find((y: any) => y.submoduleCode === "FEEDING")
            );
        }
        else if (redirectPage === '/immunity') {
            curSubmodule = userContext.moduleConfiguration.find(
                (x: any) => x.moduleCode === "CARE_OF_NEWBORN" && x.submodule.find((y: any) => y.submoduleCode === "IMMUNITY")
            );
        }
        else if (redirectPage === '/special-senses') {
            curSubmodule = userContext.moduleConfiguration.find(
                (x: any) => x.moduleCode === "CARE_OF_NEWBORN" && x.submodule.find((y: any) => y.submoduleCode === "SPECIAL_SENSES")
            );
        }
        else if (redirectPage === '/care-of-skin') {
            curSubmodule = userContext.moduleConfiguration.find(
                (x: any) => x.moduleCode === "CARE_OF_NEWBORN" && x.submodule.find((y: any) => y.submoduleCode === "CARE_OF_SKIN")
            );
        }
        else if (redirectPage === '/care-of-premature') {
            curSubmodule = userContext.moduleConfiguration.find(
                (x: any) => x.moduleCode === "CARE_OF_NEWBORN" && x.submodule.find((y: any) => y.submoduleCode === "CARE_OF_PREMATURE")
            );
        }
        else if (redirectPage === '/normal-findings-newborns') {
            curSubmodule = userContext.moduleConfiguration.find(
                (x: any) => x.moduleCode === "CARE_OF_NEWBORN" && x.submodule.find((y: any) => y.submoduleCode === "NORMAL_FINDINGS_NEWBORNS")
            );
        }
        else if (redirectPage === '/red-alert-signs-newborn') {
            curSubmodule = userContext.moduleConfiguration.find(
                (x: any) => x.moduleCode === "CARE_OF_NEWBORN" && x.submodule.find((y: any) => y.submoduleCode === "RED_ALERT_SIGNS_NEWBORN")
            );
        }
        else if (redirectPage === '/safe-practices') {
            curSubmodule = userContext.moduleConfiguration.find(
                (x: any) => x.moduleCode === "CARE_OF_NEWBORN" && x.submodule.find((y: any) => y.submoduleCode === "SAFE_PRACTICES")
            );
        }
        else if (redirectPage === '/food-item') {
            curSubmodule = userContext.moduleConfiguration.find(
                (x: any) => x.moduleCode === "DIET_NUTRITION" && x.submodule.find((y: any) => y.submoduleCode === "FOOD_ITEM")
            );
        }
        else if (redirectPage === '/expected-weight-gain') {
            curSubmodule = userContext.moduleConfiguration.find(
                (x: any) => x.moduleCode === "DIET_NUTRITION" && x.submodule.find((y: any) => y.submoduleCode === "EXPECTED_WEIGHT_GAIN")
            );
        }
        else if (redirectPage === '/expected-height-gain') {
            curSubmodule = userContext.moduleConfiguration.find(
                (x: any) => x.moduleCode === "DIET_NUTRITION" && x.submodule.find((y: any) => y.submoduleCode === "EXPECTED_HEIGHT_GAIN")
            );
        }
        else if (redirectPage === '/what-to-be-given') {
            curSubmodule = userContext.moduleConfiguration.find(
                (x: any) => x.moduleCode === "DIET_NUTRITION" && x.submodule.find((y: any) => y.submoduleCode === "WHAT_TO_BE_GIVEN")
            );
        }
        else if (redirectPage === '/what-should-not-be-given') {
            curSubmodule = userContext.moduleConfiguration.find(
                (x: any) => x.moduleCode === "DIET_NUTRITION" && x.submodule.find((y: any) => y.submoduleCode === "WHAT_SHOULD_NOT_BE_GIVEN")
            );
        }
        else if (redirectPage === '/food-restricted') {
            curSubmodule = userContext.moduleConfiguration.find(
                (x: any) => x.moduleCode === "DIET_NUTRITION" && x.submodule.find((y: any) => y.submoduleCode === "FOOD_RESTRICTED")
            );
        }
        else if (redirectPage === '/alert') {
            curSubmodule = userContext.moduleConfiguration.find(
                (x: any) => x.moduleCode === "DIET_NUTRITION" && x.submodule.find((y: any) => y.submoduleCode === "ALERT")
            );
        }
        else if (redirectPage === '/special-concern') {
            curSubmodule = userContext.moduleConfiguration.find(
                (x: any) => x.moduleCode === "DIET_NUTRITION" && x.submodule.find((y: any) => y.submoduleCode === "SPECIAL_CONCERN")
            );
        }
        else {
            navigate(redirectPage);
        }

        if (curSubmodule !== undefined) {
            navigate(redirectPage);
        } else {
            navigate("/dashboard");
        }
    }

    async function fetchData() {
        setLoading(true);
        try {
            let params = new URLSearchParams();
            params.append("familyId", userContext.loginDetail.familyId.toString());
            params.append("familyMemberId", userContext.memberDetail.familyMemberId.toString());
            const result: any = await GetNotificationList(params);
            let notificationCollection = result.data.data;
            setNotificationDataList(notificationCollection);

            updateUserContext({
                ...userContext,
                memberDetail: {
                    ...userContext.memberDetail,
                    notificationCount: 0
                }
            });

        } catch (error) {
            setLoading(false);
            if (error) {
                setAlertMessage({
                    isSuccess: true,
                    message: error as string,
                });
            }
        }
        setLoading(false);
    }

    useEffect(() => {
        if (userContext.loginDetail !== undefined && userContext.loginDetail.userId > 0) {
            fetchData();
        }
    }, []);

    return (
        <Fade in={true} timeout={{ enter: 500 }}>
            <Box className="notification-list-main-box">
                <Box className="head-box">
                    <Box className="back-box">
                        <IconButton aria-label="back" className='back-icon' disableRipple
                            onClick={() => goToHome()}>
                            <img src={backIcon} alt='logo' />
                        </IconButton>
                    </Box>
                    <Box className="title-box">
                        <Typography className="title-text">Notifications</Typography>
                    </Box>
                    <Box className="profile-box">
                        <></>
                    </Box>
                </Box>
                <Box className="notification-list-body">
                    <Box className="notification-list">
                        {loading === true ?
                            (_.times(3, (i) => (
                                <Box key={i} sx={{ margin: '10px' }}>
                                    <Skeleton animation="wave" variant="rounded" width={'100%'} height={80} />
                                </Box>
                            )))
                            :
                            (notificationDataList.length > 0 ?
                                notificationDataList.map((row: any, index: any) => (
                                    <Box className="notification-item" key={index} onClick={() => goToPage(row.urlPage)}>
                                        <Box className='left-part'>
                                            <Typography className="title-text">{row.title}
                                            </Typography>
                                            <Typography className="body-detail">
                                                {row.body}
                                            </Typography>
                                        </Box>
                                        {+row.isRead === 0 &&
                                            <Box className="right-part">
                                                <Typography className="status-text">
                                                    New
                                                </Typography>
                                            </Box>}
                                    </Box>
                                )) :
                                <Box className='no-notification-main-box'>
                                    <Box className="text-main-box"><Typography className="head-text">No Notification yet!</Typography>
                                        <Typography className="detail-text">We' ll notify you when something arrives!</Typography></Box>
                                    <Box className='bottom-box'>
                                        <Box className='img-box'>
                                            <img src={noNotification} alt="logo" />
                                        </Box>
                                        <Box className='button-box' onClick={goToHome}>
                                            <Button variant="contained" className="home-button">Go to Home</Button>
                                        </Box>
                                    </Box>
                                </Box>
                            )}
                    </Box>
                </Box>
            </Box>
        </Fade>
    )
}