import React, { useContext, useEffect, useState } from "react";
import "./MidParentalHeight.scss";
import styles from "../PhysicalGrowth/BottomSheetStyles.module.css";
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { useDrag } from "@use-gesture/react";
import { a, useSpring, config } from "@react-spring/web";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { Alert, AlertTitle, Box, Button, Dialog, DialogActions, DialogContent, Fade, FormControl, Grid, IconButton, InputAdornment, InputLabel, OutlinedInput, Skeleton, Slide, Typography } from "@mui/material";
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import backIcon from "../../assets/icons/back.svg";
import noData from "../../assets/icons/no-data.svg";
import { useNavigate } from "react-router-dom";
import { LoadingButton } from "@mui/lab";
import { PreferencesContext } from "../../PreferenceContext";
import CustomButton from "../../components/CustomButton/CustomButton";
import { AlertType } from "../../shared/AlertType";
import { GetMphDetailById, GetMphSummaryDetail, UpdateMidParentalHeight } from "../../services/MidParentalHeightService";
import { ageInMonths } from "../../shared/CommonFuctions";
import { GetMemberHeightDetailList } from "../../services/PhysicalGrowthService";
import { TransitionProps } from "@mui/material/transitions";

const formInitialValues = {
    parentalDetailId: 0,
    fatherHeight: "",
    motherHeight: "",
};

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});


export default function MidParentalHeight() {
    const { userContext, updateUserContext } = useContext(PreferencesContext);
    const [loading, setLoading] = useState(false);
    const [submitLoading, setSubmitLoading] = useState(false);
    const [parentHeightData, setParentHeightData] = useState(formInitialValues);
    const [summaryData, setSummaryData] = useState([]);
    const [dataAvailable, setDataAvailable] = useState(false);
    const [isBottomSheetOpen, setIsBottomSheetOpen] = useState(false);
    const [childHeightAdded, setChildHeightAdded] = useState(false);
    const [openWarningDialong, setOpenWarningDialong] = useState(false);
    const [showAlert, setAlertMessage] = useState({
        isSuccess: false,
        message: "",
    });
    const navigate = useNavigate();

    function backClick() {
        navigate("/physical-growth");
    }

    const valildationSchema = Yup.object().shape({
        fatherHeight: Yup.number().required("Father Height is required."),
        motherHeight: Yup.number().required("Mother Height is required.")
    });

    const height = 275;

    const [{ y }, api] = useSpring(() => ({ y: height }));

    const openHeightSheet = (data: any) => {

        if (childHeightAdded === false) {
            setOpenWarningDialong(true);
        } else {
            setIsBottomSheetOpen(true);
            api.start({
                y: 0,
                immediate: false,
            });
            fetchData();
        }
    };

    const close = (velocity = 0) => {
        setIsBottomSheetOpen(false);
        api.start({
            y: height,
            immediate: false,
            config: { ...config.stiff, velocity },
        });
    };

    const bind = useDrag(
        ({
            last,
            velocity: [, vy],
            direction: [, dy],
            offset: [, oy],
            cancel,
            canceled,
        }) => {
            // if the user drags up passed a threshold, then we cancel
            // the drag so that the sheet resets to its open position
            if (oy < -70) cancel();

            // when the user releases the sheet, we check whether it passed
            // the threshold for it to close, or if we reset it to its open positino
            if (last) {
                oy > height * 0.5 || (vy > 0.5 && dy > 0)
                    ? close(vy)
                    : openHeightSheet({ canceled });
            }
            // when the user keeps dragging, we just move the sheet according to
            // the cursor position
            else api.start({ y: oy, immediate: true });
        },
        {
            from: () => [0, y.get()],
            filterTaps: true,
            bounds: { top: 0 },
            rubberband: true,
        }
    );

    const display = y.to((py) => (py < height ? "block" : "none"));

    async function SubmitParentHeight(values: any) {
        // debugger
        setSubmitLoading(true);
        let midParentalData = {
            familyId: userContext.loginDetail.familyId,
            familyMemberId: userContext.memberDetail.familyMemberId,
            parentalDetailId: +values.parentalDetailId,
            fatherHeight: +values.fatherHeight,
            motherHeight: +values.motherHeight,
            gender: userContext.memberDetail.gender,
            dataManagedBy: userContext.loginDetail.userId,
        }
        try {
            const resultData: any = await UpdateMidParentalHeight(midParentalData);
            setSubmitLoading(false);
            setIsBottomSheetOpen(false);
            fetchMphSummary();
        } catch (e: any) {
            updateUserContext({
                ...userContext,
                isAlert: true,
                alertMessage: e.message,
                alertType: AlertType.Error,
            });
        }
        setSubmitLoading(false);
    }

    async function fetchMphSummary() {
        //  debugger
        setLoading(true);
        try {
            let month = ageInMonths(userContext.memberDetail.birthDate);
            let params = new URLSearchParams();
            params.append("month", month.toString());
            params.append("familyId", userContext.loginDetail.familyId.toString());
            params.append("familyMemberId", userContext.memberDetail.familyMemberId.toString());
            params.append("gender", userContext.memberDetail.gender);
            const result: any = await GetMphSummaryDetail(params);
            if (result.data.data !== undefined && result.data.data !== null) {
                setSummaryData(result.data.data);
                result.data.data.forEach(data => {
                    if (data.expectedChildHeight !== null && +data.expectedChildHeight !== 0) {
                        setSummaryData(result.data.data);
                        setDataAvailable(true);
                    } else {
                        setDataAvailable(false);
                    }
                });

            }
            setLoading(false);
        }
        catch (error) {
            setLoading(false);
            if (error) {
                setAlertMessage({
                    isSuccess: true,
                    message: error as string,
                });
            }
        }
    }

    async function fetchData() {
        try {
            let params = new URLSearchParams();
            params.append("familyId", userContext.loginDetail.familyId.toString());
            params.append("familyMemberId", userContext.memberDetail.familyMemberId.toString());
            const result: any = await GetMphDetailById(params);
            if (result.data.data !== undefined && result.data.data !== null) {
                let data = {
                    parentalDetailId: result.data.data[0].parentalDetailId,
                    fatherHeight: result.data.data[0].fatherHeight,
                    motherHeight: result.data.data[0].motherHeight,
                }
                setParentHeightData(data);
            }
            setLoading(false);
        }
        catch (error) {
            setLoading(false);
            if (error) {
                setAlertMessage({
                    isSuccess: true,
                    message: error as string,
                });
            }
        }
    }

    async function fetchChildHeightData() {
        setLoading(true);
        try {
            let params = new URLSearchParams();
            params.append("familyId", userContext.loginDetail.familyId.toString());
            params.append("familyMemberId", userContext.memberDetail.familyMemberId.toString());
            const result: any = await GetMemberHeightDetailList(params);
            let memberHeightData = result.data.data;
            if (memberHeightData.length > 0) {
                setChildHeightAdded(true);
            }
        } catch (error) {
            setLoading(false);
            if (error) {
                setAlertMessage({
                    isSuccess: true,
                    message: error as string,
                });
            }
        }
        setLoading(false);
    }

    useEffect(() => {
        fetchChildHeightData();
        fetchMphSummary();
    }, []);

    // useEffect(() => {
    //     if (childHeightAdded === false) {
    //         setOpenWarningDialong(true)
    //     }
    // }, [childHeightAdded])


    return (
        <Fade in={true} timeout={{ enter: 500 }}>
            <Box className="parental-main-box">
                <Box className="head-box">
                    <Box className="back-box">
                        <IconButton
                            aria-label="back"
                            className="back-icon"
                            disableRipple
                            onClick={() => backClick()}>
                            <img src={backIcon} alt="logo" />
                        </IconButton>
                    </Box>
                    <Box className="title-box">
                        <Typography className="title-text">Mid Parental Height</Typography>
                    </Box>
                    <Box className="add-box">
                        {dataAvailable === true ? (
                            <Box onClick={openHeightSheet} className="right-header-main-box">
                                <IconButton
                                    color="inherit"
                                    className="icon-button"
                                    disableRipple >
                                    <AddOutlinedIcon color="primary" />
                                </IconButton>
                                <Typography
                                    variant="body2"
                                    color='primary'
                                    className="add-text">
                                    Edit
                                </Typography>
                            </Box>
                        ) : (
                            <></>
                        )}
                    </Box>
                </Box>
                <Box className="body-box">
                    {loading ?
                        <Skeleton animation="wave" variant="rounded" width={'100%'} height={300} sx={{ borderRadius: '8px' }
                        } />
                        :
                        (dataAvailable === true ?
                            <>
                                <Box className='summary-main-box'>
                                    {summaryData.map((row: any, index: any) => (
                                        <Box key={index}>
                                            <Box sx={{ pb: '10px' }}>
                                                <Alert severity="warning">
                                                    <AlertTitle>Disclaimer:
                                                    </AlertTitle>
                                                    This chart is to be interpreted only by a Pediatrician as apart from height, puberty status of the child should also be kept in mind before accurate interpretation. Parents should consult the Pediatrician to assess the puberty of the child. *You can get information about puberty status of the child in puberty section.*
                                                </Alert>
                                            </Box>
                                            <Box key={index} className="sub-box">
                                                {/* {row.summary !== undefined && row.summary !== null && row.summary !== '' ? <Box className='upper-box'>
                                                <Typography className='text-heading'>Summary: </Typography>
                                                <Box className='text-detail'
                                                    dangerouslySetInnerHTML={{
                                                        __html: row.summary
                                                    }}></Box>
                                            </Box> : <></>} */}

                                                {row.expectedChildHeight !== undefined && row.expectedChildHeight !== null && row.expectedChildHeight !== '' ? <Box className='middle-box'><Typography className='text-heading'>Expected Height of your Child: </Typography><Typography className='text-detail'>{row.expectedChildHeight}</Typography></Box> : <></>}
                                                <Box className='bottom-box'><Typography className='text-heading'>Estimated Height of your Child: </Typography><Typography className='text-detail'>{row.estimatedChildHeight !== 0 ? row.estimatedChildHeight : 'Kindly check with your Pediatrician'}</Typography></Box>
                                            </Box>
                                        </Box>
                                    ))}
                                </Box>
                                {isBottomSheetOpen === true ?
                                    <Box
                                        className="parental-height-bottom-sheet-box"
                                        style={{ overflow: "hidden" }}>
                                        <a.div
                                            className={styles.sheet}
                                            {...bind()}
                                            style={{ display, bottom: `calc(-100vh + ${height}px)`, y }}>
                                            <Box className="top-bar"></Box>

                                            <Formik
                                                enableReinitialize
                                                initialValues={parentHeightData}
                                                onSubmit={(values) => {
                                                    SubmitParentHeight(values);
                                                }}
                                                validationSchema={valildationSchema}>
                                                {(props) => {
                                                    return (
                                                        <Form>
                                                            <Box className="form-box">
                                                                <Grid container spacing={2} padding={2}>
                                                                    <Grid item xs={12} md={12} sm={12}>
                                                                        <Box className="input-box">
                                                                            <FormControl fullWidth>
                                                                                {submitLoading ? (
                                                                                    <Skeleton
                                                                                        animation="wave"
                                                                                        variant="rounded"
                                                                                        width="auto"
                                                                                        height={45}
                                                                                    />
                                                                                ) : (
                                                                                    <>
                                                                                        <InputLabel htmlFor="outlined-adornment-password" className={
                                                                                            props.values.fatherHeight === "" &&
                                                                                                props.errors.fatherHeight &&
                                                                                                props.touched.fatherHeight
                                                                                                ? "validation-message"
                                                                                                : ""
                                                                                        }>
                                                                                            Father Height
                                                                                        </InputLabel>
                                                                                        <OutlinedInput
                                                                                            id="fatherHeight"
                                                                                            label="Father Height"
                                                                                            type="number"
                                                                                            // placeholder="Father Height"
                                                                                            fullWidth
                                                                                            endAdornment={
                                                                                                <InputAdornment position="end">
                                                                                                    <Typography>cm</Typography>
                                                                                                </InputAdornment>
                                                                                            }
                                                                                            onChange={(e: any) => {
                                                                                                props.setFieldValue(
                                                                                                    "fatherHeight",
                                                                                                    e.target.value !== null
                                                                                                        ? e.target.value
                                                                                                        : "",
                                                                                                    true
                                                                                                );
                                                                                            }}
                                                                                            onBlur={props.handleBlur}
                                                                                            value={props.values.fatherHeight}
                                                                                            inputProps={{ maxLength: 200 }}
                                                                                            // variant="outlined"
                                                                                            error={
                                                                                                props.values.fatherHeight === "" &&
                                                                                                    props.errors.fatherHeight &&
                                                                                                    props.touched.fatherHeight
                                                                                                    ? true
                                                                                                    : false
                                                                                            }
                                                                                            className={
                                                                                                props.values.fatherHeight === "" &&
                                                                                                    props.errors.fatherHeight &&
                                                                                                    props.touched.fatherHeight
                                                                                                    ? "text-input validation-message"
                                                                                                    : "text-input"
                                                                                            }
                                                                                        />
                                                                                        {props.values.fatherHeight === "" &&
                                                                                            props.errors.fatherHeight &&
                                                                                            props.touched.fatherHeight && (
                                                                                                <Typography
                                                                                                    className={
                                                                                                        props.values.fatherHeight === "" &&
                                                                                                            props.errors.fatherHeight &&
                                                                                                            props.touched.fatherHeight
                                                                                                            ? "validation-message"
                                                                                                            : "validation-message"
                                                                                                    }>
                                                                                                    {props.errors.fatherHeight}
                                                                                                </Typography>
                                                                                            )}
                                                                                    </>
                                                                                )}
                                                                            </FormControl>
                                                                        </Box>
                                                                    </Grid>
                                                                    <Grid item xs={12} md={12} sm={12}>
                                                                        <Box className="input-box">
                                                                            <FormControl fullWidth>
                                                                                {submitLoading ? (
                                                                                    <Skeleton
                                                                                        animation="wave"
                                                                                        variant="rounded"
                                                                                        width="auto"
                                                                                        height={45}
                                                                                    />
                                                                                ) : (
                                                                                    <>
                                                                                        <InputLabel htmlFor="outlined-adornment-password" className={
                                                                                            props.values.motherHeight === "" &&
                                                                                                props.errors.motherHeight &&
                                                                                                props.touched.motherHeight
                                                                                                ? "validation-message"
                                                                                                : ""
                                                                                        }>
                                                                                            Mother Height
                                                                                        </InputLabel>
                                                                                        <OutlinedInput
                                                                                            id="motherHeight"
                                                                                            label="Mother Height"
                                                                                            placeholder="Mother Height"
                                                                                            type="number"
                                                                                            fullWidth
                                                                                            endAdornment={
                                                                                                <InputAdornment position="end">
                                                                                                    <Typography>cm</Typography>
                                                                                                </InputAdornment>
                                                                                            }
                                                                                            onChange={(e: any) => {
                                                                                                props.setFieldValue(
                                                                                                    "motherHeight",
                                                                                                    e.target.value !== null
                                                                                                        ? e.target.value
                                                                                                        : "",
                                                                                                    true
                                                                                                );
                                                                                            }}
                                                                                            onBlur={props.handleBlur}
                                                                                            value={props.values.motherHeight}
                                                                                            inputProps={{ maxLength: 200 }}
                                                                                            // variant="outlined"
                                                                                            error={
                                                                                                props.values.motherHeight === "" &&
                                                                                                    props.errors.motherHeight &&
                                                                                                    props.touched.motherHeight
                                                                                                    ? true
                                                                                                    : false
                                                                                            }
                                                                                            className={
                                                                                                props.values.motherHeight === "" &&
                                                                                                    props.errors.motherHeight &&
                                                                                                    props.touched.motherHeight
                                                                                                    ? "text-input validation-message"
                                                                                                    : "text-input"
                                                                                            }
                                                                                        />
                                                                                        {props.values.motherHeight === "" &&
                                                                                            props.errors.motherHeight &&
                                                                                            props.touched.motherHeight && (
                                                                                                <Typography
                                                                                                    className={
                                                                                                        props.values.motherHeight === "" &&
                                                                                                            props.errors.motherHeight &&
                                                                                                            props.touched.motherHeight
                                                                                                            ? "validation-message"
                                                                                                            : "validation-message"
                                                                                                    }>
                                                                                                    {props.errors.motherHeight}
                                                                                                </Typography>
                                                                                            )}
                                                                                    </>
                                                                                )}
                                                                            </FormControl>
                                                                        </Box>
                                                                    </Grid>

                                                                    <Grid
                                                                        item
                                                                        xs={12}
                                                                        md={12}
                                                                        sm={12}
                                                                        className="button-grid">
                                                                        <Box className="bottom-box">
                                                                            <CustomButton
                                                                                disabled={false}
                                                                                class="add-button"
                                                                                isLoading={submitLoading}
                                                                                title="Add"
                                                                            />
                                                                        </Box>
                                                                    </Grid>
                                                                </Grid>
                                                            </Box>
                                                        </Form>
                                                    );
                                                }}
                                            </Formik>
                                        </a.div>
                                    </Box>

                                    : <></>}
                            </>
                            :
                            <>
                                <Box className="no-data-box">
                                    <Box className="upper-box"></Box>
                                    <Box className="bottom-box">
                                        <Box className="img-box">
                                            <img src={noData} alt="logo" />
                                        </Box>
                                        <Box className="no-summary-box">
                                            <Typography className="no-summary-subtext">
                                                Enter the Child's height (if not entered in height section) and Parent's height to know the Mid Parental height (Expected Adult height) and estimated adult height of your child and identify growth faltering of your child earlier if any, to help him/her achieving normal height.
                                            </Typography>
                                        </Box>
                                        <Box className="start-button-box">
                                            <LoadingButton
                                                variant="contained"
                                                className="add-button"
                                                startIcon={<AddOutlinedIcon />}
                                                onClick={openHeightSheet}
                                            >Start now
                                            </LoadingButton>
                                        </Box>
                                    </Box>
                                </Box>
                                {isBottomSheetOpen === true ?
                                    <Box
                                        className="parental-height-bottom-sheet-box"
                                        style={{ overflow: "hidden" }}>
                                        <a.div
                                            className={styles.sheet}
                                            {...bind()}
                                            style={{ display, bottom: `calc(-100vh + ${height}px)`, y }}>
                                            <Box className="top-bar"></Box>

                                            <Formik
                                                enableReinitialize
                                                initialValues={parentHeightData}
                                                onSubmit={(values) => {
                                                    SubmitParentHeight(values);
                                                }}
                                                validationSchema={valildationSchema}>
                                                {(props) => {
                                                    return (
                                                        <Form>
                                                            <Box className="form-box">
                                                                <Grid container spacing={2} padding={2}>
                                                                    <Grid item xs={12} md={12} sm={12}>
                                                                        <Box className="input-box">
                                                                            <FormControl fullWidth>
                                                                                {submitLoading ? (
                                                                                    <Skeleton
                                                                                        animation="wave"
                                                                                        variant="rounded"
                                                                                        width="auto"
                                                                                        height={45}
                                                                                    />
                                                                                ) : (
                                                                                    <>
                                                                                        <InputLabel htmlFor="outlined-adornment-password" className={
                                                                                            props.values.fatherHeight === "" &&
                                                                                                props.errors.fatherHeight &&
                                                                                                props.touched.fatherHeight
                                                                                                ? "validation-message"
                                                                                                : ""
                                                                                        }>
                                                                                            Father Height
                                                                                        </InputLabel>
                                                                                        <OutlinedInput
                                                                                            id="fatherHeight"
                                                                                            label="Father Height"
                                                                                            type="number"
                                                                                            // placeholder="Father Height"
                                                                                            fullWidth
                                                                                            endAdornment={
                                                                                                <InputAdornment position="end">
                                                                                                    <Typography>cm</Typography>
                                                                                                </InputAdornment>
                                                                                            }
                                                                                            onChange={(e: any) => {
                                                                                                props.setFieldValue(
                                                                                                    "fatherHeight",
                                                                                                    e.target.value !== null
                                                                                                        ? e.target.value
                                                                                                        : "",
                                                                                                    true
                                                                                                );
                                                                                            }}
                                                                                            onBlur={props.handleBlur}
                                                                                            value={props.values.fatherHeight}
                                                                                            inputProps={{ maxLength: 200 }}
                                                                                            // variant="outlined"
                                                                                            error={
                                                                                                props.values.fatherHeight === "" &&
                                                                                                    props.errors.fatherHeight &&
                                                                                                    props.touched.fatherHeight
                                                                                                    ? true
                                                                                                    : false
                                                                                            }
                                                                                            className={
                                                                                                props.values.fatherHeight === "" &&
                                                                                                    props.errors.fatherHeight &&
                                                                                                    props.touched.fatherHeight
                                                                                                    ? "text-input validation-message"
                                                                                                    : "text-input"
                                                                                            }
                                                                                        />
                                                                                        {props.values.fatherHeight === "" &&
                                                                                            props.errors.fatherHeight &&
                                                                                            props.touched.fatherHeight && (
                                                                                                <Typography
                                                                                                    className={
                                                                                                        props.values.fatherHeight === "" &&
                                                                                                            props.errors.fatherHeight &&
                                                                                                            props.touched.fatherHeight
                                                                                                            ? "validation-message"
                                                                                                            : "validation-message"
                                                                                                    }>
                                                                                                    {props.errors.fatherHeight}
                                                                                                </Typography>
                                                                                            )}
                                                                                    </>
                                                                                )}
                                                                            </FormControl>
                                                                        </Box>
                                                                    </Grid>
                                                                    <Grid item xs={12} md={12} sm={12}>
                                                                        <Box className="input-box">
                                                                            <FormControl fullWidth>
                                                                                {submitLoading ? (
                                                                                    <Skeleton
                                                                                        animation="wave"
                                                                                        variant="rounded"
                                                                                        width="auto"
                                                                                        height={45}
                                                                                    />
                                                                                ) : (
                                                                                    <>
                                                                                        <InputLabel htmlFor="outlined-adornment-password" className={
                                                                                            props.values.motherHeight === "" &&
                                                                                                props.errors.motherHeight &&
                                                                                                props.touched.motherHeight
                                                                                                ? "validation-message"
                                                                                                : ""
                                                                                        }>
                                                                                            Mother Height
                                                                                        </InputLabel>
                                                                                        <OutlinedInput
                                                                                            id="motherHeight"
                                                                                            label="Mother Height"
                                                                                            placeholder="Mother Height"
                                                                                            type="number"
                                                                                            fullWidth
                                                                                            endAdornment={
                                                                                                <InputAdornment position="end">
                                                                                                    <Typography>cm</Typography>
                                                                                                </InputAdornment>
                                                                                            }
                                                                                            onChange={(e: any) => {
                                                                                                props.setFieldValue(
                                                                                                    "motherHeight",
                                                                                                    e.target.value !== null
                                                                                                        ? e.target.value
                                                                                                        : "",
                                                                                                    true
                                                                                                );
                                                                                            }}
                                                                                            onBlur={props.handleBlur}
                                                                                            value={props.values.motherHeight}
                                                                                            inputProps={{ maxLength: 200 }}
                                                                                            // variant="outlined"
                                                                                            error={
                                                                                                props.values.motherHeight === "" &&
                                                                                                    props.errors.motherHeight &&
                                                                                                    props.touched.motherHeight
                                                                                                    ? true
                                                                                                    : false
                                                                                            }
                                                                                            className={
                                                                                                props.values.motherHeight === "" &&
                                                                                                    props.errors.motherHeight &&
                                                                                                    props.touched.motherHeight
                                                                                                    ? "text-input validation-message"
                                                                                                    : "text-input"
                                                                                            }
                                                                                        />
                                                                                        {props.values.motherHeight === "" &&
                                                                                            props.errors.motherHeight &&
                                                                                            props.touched.motherHeight && (
                                                                                                <Typography
                                                                                                    className={
                                                                                                        props.values.motherHeight === "" &&
                                                                                                            props.errors.motherHeight &&
                                                                                                            props.touched.motherHeight
                                                                                                            ? "validation-message"
                                                                                                            : "validation-message"
                                                                                                    }>
                                                                                                    {props.errors.motherHeight}
                                                                                                </Typography>
                                                                                            )}
                                                                                    </>
                                                                                )}
                                                                            </FormControl>
                                                                        </Box>
                                                                    </Grid>

                                                                    <Grid
                                                                        item
                                                                        xs={12}
                                                                        md={12}
                                                                        sm={12}
                                                                        className="button-grid">
                                                                        <Box className="bottom-box">
                                                                            <CustomButton
                                                                                disabled={false}
                                                                                class="add-button"
                                                                                isLoading={submitLoading}
                                                                                title="Add"
                                                                            />
                                                                        </Box>
                                                                    </Grid>
                                                                </Grid>
                                                            </Box>
                                                        </Form>
                                                    );
                                                }}
                                            </Formik>
                                        </a.div>
                                    </Box>

                                    : <></>}
                            </>
                        )
                    }
                </Box>

                <Dialog
                    maxWidth="xs"
                    fullWidth={true}
                    open={openWarningDialong}
                    onClose={() => setOpenWarningDialong(false)}
                    TransitionComponent={Transition}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    className='mph-verify-dialog-box'>
                    <Box className="img-box">
                        <ErrorOutlineIcon className='error-icon' />
                    </Box>
                    <DialogContent>
                        <Box id="alert-dialog-description" className="popup-text">
                            <Typography className="popup-text1">To know the Mid Parental height first Kindly add your child's Height</Typography>
                        </Box>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            variant='contained'
                            onClick={() => setOpenWarningDialong(false)}>
                            Ok
                        </Button>

                    </DialogActions>
                </Dialog>
            </Box>




        </Fade>
    )
}