import React, { useContext, useState } from 'react';
import './SignIn.scss';
import { Box, Button, Dialog, DialogActions, Fade, TextField, Typography } from '@mui/material';
import logo from '../../assets/images/logo.png';
import { useNavigate } from "react-router-dom";
import { MuiTelInput } from 'mui-tel-input';
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { MobileRegistrartion, OTPVerification } from '../../services/AuthService';
import { PreferencesContext } from '../../PreferenceContext';
import CustomButton from '../../components/CustomButton/CustomButton';
import ArrowForwardRoundedIcon from '@mui/icons-material/ArrowForwardRounded';
import { LoadingButton } from '@mui/lab';

const formInitialValues = {
    phone: "",
};

export default function SignIn() {
    const { userContext, updateUserContext } = useContext(PreferencesContext);
    const [loading, setLoading] = useState(false);
    const [phoneValue, setphoneValue] = useState(formInitialValues);
    const [countTap, setCountTap] = useState(0);
    const [errorCode, setErrorCode] = useState(false);

    const [showAlert, setAlertMessage] = useState({
        isError: false,
        message: "",
    });

    let navigate = useNavigate();
    const phoneRegExp = /^\+[1-9]{2}[0-9]{10}$/

    const valildationSchema = Yup.object().shape({
        phone: Yup.string().matches(phoneRegExp, 'Phone number is not valid').required("Phone number is required.")
    });

    const goToOtpVerification = () => {
        navigate("/otp-verification");
    };

    const goToRegister = () => {
        navigate("/sign-up");
    }


    // registration
    async function submitLoginForm(count: any, value: any, isWithoutSubmit: any) {
        setphoneValue(value);

        if (isWithoutSubmit === true) {
            let phoneCheckIsValid: any;
            let phnValid: any;

            // yup valildation check without form...
            phoneCheckIsValid = valildationSchema.validate({
                phone: value.values.phone
            }).then(function (valid) {
                phnValid = valid;
                if (+count < 6) {
                    setCountTap(+count);
                    if (+count === 5) {
                        let currMobileNumber = value.values.phone.length - 10;
                        let CountryCode = value.values.phone.substr(0, currMobileNumber)
                        let PhnNumber = value.values.phone.substr(currMobileNumber, value.values.phone.length)
                        updateUserContext({
                            ...userContext,
                            mobileNumber: value.values.phone,
                            countryCode: CountryCode,
                            phnNumber: PhnNumber
                        });
                        navigate("/otp-verification?Admin=1");
                    }
                }
            }).catch(function (error) {
                setErrorCode(true);
            });
        } else {
            setLoading(true);
            try {
                const mobileNumber = value.phone;
                const user: any = await MobileRegistrartion({
                    mobileNumber: mobileNumber,
                    isDeleted: false
                });
                let currMobileNumber = mobileNumber.length - 10;
                let CountryCode = mobileNumber.substr(0, currMobileNumber)
                let PhnNumber = mobileNumber.substr(currMobileNumber, mobileNumber.length)
                if (user && user.data !== null) {
                    updateUserContext({
                        ...userContext,
                        mobileNumber: mobileNumber,
                        countryCode: CountryCode,
                        phnNumber: PhnNumber
                    });
                    goToOtpVerification();
                    setLoading(false);
                }
            } catch (error) {
                setLoading(false);
                if (error) {
                    setAlertMessage({
                        isError: true,
                        message: error as string,
                    });
                }
            }
        }
    }

    return (
        <Fade in={true} timeout={{ enter: 500 }}>
            <Box className="signin-main-box">
                <Box className="img-box">
                    <img src={logo} alt="logo" />
                </Box>
                <Box className="middle-box">
                    <Box className="title-box">
                        <Typography className='title-text'>Login</Typography>
                        <Typography className='subtitle-text'>Enter your phone number to proceed further</Typography>
                    </Box>
                    <Formik
                        initialValues={phoneValue}
                        onSubmit={(values) => {
                            submitLoginForm(0, values, false);
                        }}
                        validationSchema={valildationSchema}>
                        {(props) => {
                            return (
                                <Box className="form-box">
                                    <Form>
                                        <MuiTelInput
                                            id="phone"
                                            fullWidth
                                            defaultCountry="IN"
                                            onChange={(e: any) => {
                                                props.setFieldValue(
                                                    "phone",
                                                    e !== null ? e : "",
                                                    true
                                                );

                                                if (errorCode === true) {
                                                    setErrorCode(false);
                                                }
                                            }}
                                            value={props.values.phone}
                                            forceCallingCode
                                            disableDropdown
                                            disableFormatting
                                            className="textfield-input"
                                            error={
                                                props.values.phone === "" &&
                                                    props.errors.phone && props.touched.phone
                                                    ? true
                                                    : false
                                            }
                                        />
                                        {((props.errors.phone && props.touched.phone) || errorCode === true) ?
                                            <Typography className="validation-message" >
                                                {props.errors.phone}
                                            </Typography> : <></>}
                                        <CustomButton
                                            disabled={false}
                                            class="next-button"
                                            isLoading={loading}
                                            title='Sign In'
                                        />

                                        <Box className='hidden-box' onClick={() => submitLoginForm(countTap + 1, props, true)}>
                                        </Box>
                                    </Form>
                                </Box>
                            )
                        }}
                    </Formik>

                    <Box className="bottom-box">
                        <Typography gutterBottom align="center" className='bottom-text'>
                            Don't have an account?&nbsp;
                        </Typography>
                        <Typography color="primary"
                            className='register-button'
                            // endIcon={<ArrowForwardRoundedIcon />}
                            onClick={goToRegister}>
                            Get started
                        </Typography>
                    </Box>
                </Box>


                {/* <Box className="bottom-box-hidden"></Box> */}
                {/* <Box className="bottom-box">
                    <Typography gutterBottom align="center" className='bottom-text'>
                        Don't have an account?&nbsp;
                    </Typography>
                    <Button color="primary"
                        variant="contained" className='register-button'
                        endIcon={<ArrowForwardRoundedIcon />}
                        onClick={goToRegister}>
                        Get started
                    </Button>
                </Box> */}


            </Box>
        </Fade>
    );
}