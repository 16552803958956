import React, { useContext, useEffect, useRef, useState } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./MilestoneDevelopmentQuestions.scss";
import { Box, Button, Collapse, Fade, IconButton, ListItem, ListItemText, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { PreferencesContext } from "../../PreferenceContext";
import { AlertType } from "../../shared/AlertType";
import { CreateMilestoneSummary, GetMilestoneQuestionsDetail } from "../../services/MilestoneDevelopmentService";
import { TransitionGroup } from "react-transition-group";
import { styled } from "@mui/material/styles";
import LinearProgress, { linearProgressClasses } from "@mui/material/LinearProgress";
import Slider from "react-slick";
import LoadingButton from "@mui/lab/LoadingButton/LoadingButton";
import { ageInDays } from "../../shared/CommonFuctions";
import backIcon from "../../assets/icons/back.svg";
import previousIcon from "../../assets/icons/previous-icon.svg";
import nextIcon from "../../assets/icons/next-icon.svg";

interface RenderQuestionStack {
  item: any;
}

interface Answers {
  milestone: string;
  id: number;
  answer?: number;
}

let initData: any;

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 8,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: "#BC3965",
  },
}));

let SliderSettings = {
  dots: false,
  infinite: false,
  slidesToShow: 1,
  autoplay: false,
  arrows: false,
  slidesToScroll: 1,
  swipe: false,
};

export default function MilestoneDevelopmentQuestions() {
  const headerArray: any = [
    { id: 1, headerName: "Gross Motor", visible: true },
    { id: 2, headerName: "Fine Motor", visible: true },
    { id: 3, headerName: "Social", visible: true },
    { id: 4, headerName: "Language", visible: true },
    { id: 5, headerName: "Cognitive", visible: true },
  ];

  function renderGrossQuestion({ item }: RenderQuestionStack) {
    return (
      <Box className="question-box">
        <ListItem className="question">
          <ListItemText className="question-text" primary={item.questions} />
        </ListItem>
        <Box className="button-box">
          <Button
            className={
              getSelected(grossMotorAnswers, item.grossMotorDetailId, 1)
                ? "button-answer selected"
                : "button-answer"
            }
            onClick={() => handleGrossAnswerQuestion(item, 1)}
          >
            Yes
          </Button>
          <Button
            className={
              getSelected(grossMotorAnswers, item.grossMotorDetailId, 0)
                ? "button-answer selected"
                : "button-answer"
            }
            onClick={() => handleGrossAnswerQuestion(item, 0)}
          >
            Not yet
          </Button>
          <Button
            className={
              getSelected(grossMotorAnswers, item.grossMotorDetailId, 2)
                ? "button-answer selected"
                : "button-answer"
            }
            onClick={() => handleGrossAnswerQuestion(item, 2)}
          >
            Not sure
          </Button>
        </Box>
      </Box>
    );
  }

  function renderFineQuestion({ item }: RenderQuestionStack) {
    return (
      <Box className="question-box">
        <ListItem className="question">
          <ListItemText className="question-text" primary={item.questions} />
        </ListItem>
        <Box className="button-box">
          <Button
            className={
              getSelected(fineMotorAnswers, item.fineMotorDetailId, 1)
                ? "button-answer selected"
                : "button-answer"
            }
            onClick={() => handleFineAnswerQuestion(item, 1)}
          >
            Yes
          </Button>
          <Button
            className={
              getSelected(fineMotorAnswers, item.fineMotorDetailId, 0)
                ? "button-answer selected"
                : "button-answer"
            }
            onClick={() => handleFineAnswerQuestion(item, 0)}
          >
            Not yet
          </Button>
          <Button
            className={
              getSelected(fineMotorAnswers, item.fineMotorDetailId, 2)
                ? "button-answer selected"
                : "button-answer"
            }
            onClick={() => handleFineAnswerQuestion(item, 2)}
          >
            Not sure
          </Button>
        </Box>
      </Box>
    );
  }

  function renderSocialQuestion({ item }: RenderQuestionStack) {
    return (
      <Box className="question-box">
        <ListItem className="question">
          <ListItemText className="question-text" primary={item.questions} />
        </ListItem>
        <Box className="button-box">
          <Button
            className={
              getSelected(socialAnswers, item.socialDetailId, 1)
                ? "button-answer selected"
                : "button-answer"
            }
            onClick={() => handleSocialAnswerQuestion(item, 1)}
          >
            Yes
          </Button>
          <Button
            className={
              getSelected(socialAnswers, item.socialDetailId, 0)
                ? "button-answer selected"
                : "button-answer"
            }
            onClick={() => handleSocialAnswerQuestion(item, 0)}
          >
            Not yet
          </Button>
          <Button
            className={
              getSelected(socialAnswers, item.socialDetailId, 2)
                ? "button-answer selected"
                : "button-answer"
            }
            onClick={() => handleSocialAnswerQuestion(item, 2)}
          >
            Not sure
          </Button>
        </Box>
      </Box>
    );
  }

  function renderLanguageQuestion({ item }: RenderQuestionStack) {
    return (
      <Box className="question-box">
        <ListItem className="question">
          <ListItemText className="question-text" primary={item.questions} />
        </ListItem>
        <Box className="button-box">
          <Button
            className={
              getSelected(languageAnswers, item.languageDetailId, 1)
                ? "button-answer selected"
                : "button-answer"
            }
            onClick={() => handleLanguageAnswerQuestion(item, 1)}
          >
            Yes
          </Button>
          <Button
            className={
              getSelected(languageAnswers, item.languageDetailId, 0)
                ? "button-answer selected"
                : "button-answer"
            }
            onClick={() => handleLanguageAnswerQuestion(item, 0)}
          >
            Not yet
          </Button>
          <Button
            className={
              getSelected(languageAnswers, item.languageDetailId, 2)
                ? "button-answer selected"
                : "button-answer"
            }
            onClick={() => handleLanguageAnswerQuestion(item, 2)}
          >
            Not sure
          </Button>
        </Box>
      </Box>
    );
  }

  function renderCognitiveQuestion({ item }: RenderQuestionStack) {
    return (
      <Box className="question-box">
        <ListItem className="question">
          <ListItemText className="question-text" primary={item.questions} />
        </ListItem>
        <Box className="button-box">
          <Button
            className={
              getSelected(cognitiveAnswers, item.cognitiveDetailId, 1)
                ? "button-answer selected"
                : "button-answer"
            }
            onClick={() => handleCognitiveAnswerQuestion(item, 1)}
          >
            Yes
          </Button>
          <Button
            className={
              getSelected(cognitiveAnswers, item.cognitiveDetailId, 0)
                ? "button-answer selected"
                : "button-answer"
            }
            onClick={() => handleCognitiveAnswerQuestion(item, 0)}
          >
            Not yet
          </Button>
          <Button
            className={
              getSelected(cognitiveAnswers, item.cognitiveDetailId, 2)
                ? "button-answer selected"
                : "button-answer"
            }
            onClick={() => handleCognitiveAnswerQuestion(item, 2)}
          >
            Not sure
          </Button>
        </Box>
      </Box>
    );
  }

  const { userContext, updateUserContext } = useContext(PreferencesContext);
  const [loading, setLoading] = useState(false);

  const [isNextButtonEnabled, setNextButtonEnabled] = useState(false);
  const [isPreviousButtonEnabled, setPreviousButtonEnabled] = useState(false);
  const [isSubmitButton, setIsSubmitButton] = useState(false);

  const [grossMotorData, setGrossMotorData] = useState(initData);
  const [grossMotorDataInStack, setGrossMotorDataInStack] = useState(initData);
  const [grossMotorAnswers, setGrossMotorAnswers] = useState(initData);

  const [fineMotorData, setFineMotorData] = useState(initData);
  const [fineMotorDataInStack, setFineMotorDataInStack] = useState(initData);
  const [fineMotorAnswers, setFineMotorAnswers] = useState(initData);

  const [socialData, setSocialData] = useState(initData);
  const [socialDataInStack, setSocialDataInStack] = useState(initData);
  const [socialAnswers, setSocialAnswers] = useState(initData);

  const [languageData, setLanguageData] = useState(initData);
  const [languageDataInStack, setLanguageDataInStack] = useState(initData);
  const [languageAnswers, setLanguageAnswers] = useState(initData);

  const [cognitiveData, setCognitiveData] = useState(initData);
  const [cognitiveDataInStack, setCognitiveDataInStack] = useState(initData);
  const [cognitiveAnswers, setCognitiveAnswers] = useState(initData);

  const [headerIndex, setHeaderIndex] = useState(0);
  const [arrayData, setArrayData] = useState(headerArray);

  const navigate = useNavigate();
  let sliderRef: any = useRef(null);

  function backClick() {
    navigate("/milestone-development");
  }

  function goToPrevious() {
    if (headerIndex === 4) {
      if (languageData.length > 0) {
        sliderRef.slickGoTo(3);
        setHeaderIndex(3);
        setIsSubmitButton(false);
        setNextButtonEnabled(true);
      } else {
        sliderRef.slickGoTo(2);
        setHeaderIndex(2);
      }
    } else if (headerIndex === 3) {
      if (socialData.length > 0) {
        sliderRef.slickGoTo(2);
        setHeaderIndex(2);
        setIsSubmitButton(false);
        if (socialAnswers !== undefined) {
          setNextButtonEnabled(true);
        }
      } else {
        sliderRef.slickGoTo(1);
        setHeaderIndex(1);
      }
    } else if (headerIndex === 2) {
      if (fineMotorData.length > 0) {
        sliderRef.slickGoTo(1);
        setHeaderIndex(1);
        setIsSubmitButton(false);
        if (fineMotorAnswers !== undefined) {
          setNextButtonEnabled(true);
        }
      } else {
        sliderRef.slickGoTo(0);
        setHeaderIndex(0);
      }
    } else if (headerIndex === 1) {
      if (grossMotorData.length > 0) {
        sliderRef.slickGoTo(0);
        setHeaderIndex(0);
        setPreviousButtonEnabled(false);
        setIsSubmitButton(false);
        if (grossMotorAnswers !== undefined) {
          setNextButtonEnabled(true);
        }
      } else {
        setPreviousButtonEnabled(false);
      }
    }
    sliderRef.slickPrev()
  }

  function goToNext() {
    if (headerIndex === 0) {
      if (fineMotorData.length > 0) {
        sliderRef.slickGoTo(1);
        setHeaderIndex(1);
        setPreviousButtonEnabled(false);
        setNextButtonEnabled(true);
      } else {
        sliderRef.slickGoTo(2);
        setHeaderIndex(2);
        setPreviousButtonEnabled(false);
        setNextButtonEnabled(true);
      }
    } else if (headerIndex === 1) {
      if (socialData.length > 0) {
        sliderRef.slickGoTo(2);
        setHeaderIndex(2);
        setPreviousButtonEnabled(false);
        setNextButtonEnabled(true);
      } else {
        sliderRef.slickGoTo(3);
        setHeaderIndex(3);
        setPreviousButtonEnabled(false);
        setNextButtonEnabled(true);
      }
    } else if (headerIndex === 2) {
      if (languageData.length > 0) {
        sliderRef.slickGoTo(3);
        setHeaderIndex(3);
        setPreviousButtonEnabled(false);
        setNextButtonEnabled(true);
        setIsSubmitButton(true);
      } else {
        sliderRef.slickGoTo(4);
        setHeaderIndex(4);
        setPreviousButtonEnabled(false);
        setNextButtonEnabled(true);
        setIsSubmitButton(true);
      }
    } else if (headerIndex === 3) {
      if (cognitiveData.length > 0) {
        sliderRef.slickGoTo(4);
        setHeaderIndex(4);
        setIsSubmitButton(true);
        setPreviousButtonEnabled(false);
        setNextButtonEnabled(true);
      } else {
        setIsSubmitButton(true);
        setPreviousButtonEnabled(false);
        setNextButtonEnabled(true);
        setIsSubmitButton(true);
      }
    }
  }

  async function fetchQuestionsData() {
    setLoading(true);
    try {
      let params = new URLSearchParams();
      params.append("birthDate", userContext.memberDetail.birthDate);
      const result: any = await GetMilestoneQuestionsDetail(params);
      let data = result.data.data;
      let grossMotorDetailData = data.grossMotorDetail;
      let fineMotorDetailData = data.fineMotorDetail;
      let socialDetailData = data.socialDetail;
      let languageDetailData = data.languageDetail;
      let cognitiveDetailData = data.cognitiveDetail;
      setGrossMotorData(grossMotorDetailData);
      if (grossMotorDetailData.length > 0) {
        setGrossMotorDataInStack([grossMotorDetailData[0]]);
      } else {
        // {
        arrayData[0].visible = false;
        setArrayData([...arrayData]);
        // }
      }

      setFineMotorData(fineMotorDetailData);
      if (fineMotorDetailData.length > 0) {
        setFineMotorDataInStack([fineMotorDetailData[0]]);
      } else {
        // {
        arrayData[1].visible = false;
        setArrayData([...arrayData]);
        // }
      }

      setSocialData(socialDetailData);
      if (socialDetailData.length > 0) {
        setSocialDataInStack([socialDetailData[0]]);
      } else {
        // {
        arrayData[2].visible = false;
        setArrayData([...arrayData]);
        // }
      }

      setLanguageData(languageDetailData);
      if (languageDetailData.length > 0) {
        setLanguageDataInStack([languageDetailData[0]]);
      } else {
        arrayData[3].visible = false;
        setArrayData([...arrayData]);
      }
      setCognitiveData(cognitiveDetailData);
      if (cognitiveDetailData.length > 0) {
        setCognitiveDataInStack([cognitiveDetailData[0]]);
      } else {
        arrayData[4].visible = false;
        setArrayData([...arrayData]);
      }
    } catch (error) {
      updateUserContext({
        ...userContext,
        isAlert: true,
        // alertMessage: 'Something went wrong',
        alertMessage: error.message,
        alertType: AlertType.Error,
      });
    }
    setLoading(false);
  }

  function getSelected(answers: any, id: any, answer: number) {
    if (answers !== null && answers !== undefined) {
      let curAns = answers.find((x) => x.id === id && x.answer === answer);
      if (curAns !== null && curAns !== undefined) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  const handleGrossAnswerQuestion = (curQuestion: any, ans: number) => {
    const curQuestionIndex = grossMotorData.indexOf(curQuestion);
    let allGrossAnswers = grossMotorAnswers;
    let curAns =
      allGrossAnswers !== undefined
        ? allGrossAnswers
          .map((e) => e.id)
          .indexOf(+curQuestion.grossMotorDetailId)
        : -1;
    if (curAns > -1) {
      setGrossMotorAnswers(
        grossMotorAnswers.map((item) => {
          if (item.id === +curQuestion.grossMotorDetailId) {
            item.answer = ans;
          }
          return item;
        })
      );
      // // now add
      // if (curQuestionIndex > -1 && grossMotorData.length !== (curQuestionIndex + 1)) {
      //     setGrossMotorDataInStack(questions => [grossMotorData[curQuestionIndex + 1], ...questions]);
      // }
    } else {
      let answer: Answers = {
        milestone: "Gross Motor",
        id: +curQuestion.grossMotorDetailId,
        answer: ans,
      };
      if (allGrossAnswers !== undefined) {
        setGrossMotorAnswers((answers) => [...answers, answer]);
      } else {
        setGrossMotorAnswers([answer]);
      }
      if (ans === 1) {
        if (headerArray[1].visible) {
          if (fineMotorData.length > 0) {
            sliderRef.slickGoTo(1);
          } else {
            if (socialData.length > 0) {
              setNextButtonEnabled(false);
              setIsSubmitButton(false);
            }
            sliderRef.slickGoTo(2);
          }
          setHeaderIndex(1);
        } else {
          sliderRef.slickGoTo(2);
          setHeaderIndex(2);
        }
      } else {
        if (
          curQuestionIndex > -1 &&
          grossMotorData.length !== curQuestionIndex + 1
        ) {
          setGrossMotorDataInStack((questions) => [
            grossMotorData[curQuestionIndex + 1],
            ...questions,
          ]);
        } else {
          if (headerArray[1].visible) {
            if (fineMotorData.length > 0) {
              sliderRef.slickGoTo(1);
            } else {
              if (socialData.length > 0) {
                setNextButtonEnabled(false);
              }
              sliderRef.slickGoTo(2);
            }
            setHeaderIndex(1);
          } else {
            sliderRef.slickGoTo(2);
            setHeaderIndex(2);
          }
        }
      }
    }
  };

  const handleFineAnswerQuestion = (curQuestion: any, ans: number) => {
    const curQuestionIndex = fineMotorData.indexOf(curQuestion);
    let allFineAnswers = fineMotorAnswers;
    let curAns =
      allFineAnswers !== undefined
        ? allFineAnswers
          .map((e) => e.id)
          .indexOf(+curQuestion.fineMotorDetailId)
        : -1;
    if (curAns > -1) {
      setFineMotorAnswers(
        fineMotorAnswers.map((item) => {
          if (item.id === +curQuestion.fineMotorDetailId) {
            item.answer = ans;
          }
          return item;
        })
      );
      // // now add
      // if (curQuestionIndex > -1 && fineMotorData.length !== (curQuestionIndex + 1)) {
      //     setFineMotorDataInStack(questions => [fineMotorData[curQuestionIndex + 1], ...questions]);
      // }
    } else {
      let answer: Answers = {
        milestone: "Fine Motor",
        id: +curQuestion.fineMotorDetailId,
        answer: ans,
      };
      if (allFineAnswers !== undefined) {
        setFineMotorAnswers((answers) => [...answers, answer]);
      } else {
        setFineMotorAnswers([answer]);
      }
      if (ans === 1) {
        if (headerArray[2].visible) {
          if (socialData.length > 0) {
            sliderRef.slickGoTo(2);
          } else {
            if (cognitiveData.length > 0) {
              setNextButtonEnabled(false);
            } else {
              setNextButtonEnabled(true);
            }

            sliderRef.slickGoTo(3);
          }
          setHeaderIndex(2);
        } else {
          sliderRef.slickGoTo(3);
          setHeaderIndex(3);
        }
      } else {
        if (
          curQuestionIndex > -1 &&
          fineMotorData.length !== curQuestionIndex + 1
        ) {
          setFineMotorDataInStack((questions) => [
            fineMotorData[curQuestionIndex + 1],
            ...questions,
          ]);
        } else {
          if (headerArray[2].visible) {
            if (socialData.length > 0) {
              sliderRef.slickGoTo(2);
            } else {
              if (cognitiveData.length > 0) {
                setNextButtonEnabled(false);
              } else {
                setNextButtonEnabled(true);
              }

              sliderRef.slickGoTo(3);
            }
            setHeaderIndex(2);
          } else {
            sliderRef.slickGoTo(3);
            setHeaderIndex(3);
          }
        }
      }
    }
  };

  const handleSocialAnswerQuestion = (curQuestion: any, ans: number) => {
    const curQuestionIndex = socialData.indexOf(curQuestion);
    let allSocialAnswers = socialAnswers;
    let curAns =
      allSocialAnswers !== undefined
        ? allSocialAnswers.map((e) => e.id).indexOf(+curQuestion.socialDetailId)
        : -1;
    if (curAns > -1) {
      setSocialAnswers(
        socialAnswers.map((item) => {
          if (item.id === +curQuestion.socialDetailId) {
            item.answer = ans;
          }
          return item;
        })
      );
      // // now add
      // if (curQuestionIndex > -1 && socialData.length !== (curQuestionIndex + 1)) {
      //     setSocialDataInStack(questions => [socialData[curQuestionIndex + 1], ...questions]);
      // }
    } else {
      let answer: Answers = {
        milestone: "Social",
        id: +curQuestion.socialDetailId,
        answer: ans,
      };
      if (allSocialAnswers !== undefined) {
        setSocialAnswers((answers) => [...answers, answer]);
      } else {
        setSocialAnswers([answer]);
      }
      if (ans === 1) {
        if (headerArray[2].visible) {
          if (languageData.length > 0) {
            sliderRef.slickGoTo(3);
          } else {
            setNextButtonEnabled(false);
            sliderRef.slickGoTo(4);
          }
          setHeaderIndex(2);
        } else {
          sliderRef.slickGoTo(3);
          setHeaderIndex(3);
        }
      } else {
        if (
          curQuestionIndex > -1 &&
          socialData.length !== curQuestionIndex + 1
        ) {
          setSocialDataInStack((questions) => [
            socialData[curQuestionIndex + 1],
            ...questions,
          ]);
        } else {
          if (headerArray[2].visible) {
            if (languageData.length > 0) {
              sliderRef.slickGoTo(3);
            } else {
              setNextButtonEnabled(false);
              sliderRef.slickGoTo(4);
            }
            setHeaderIndex(2);
          } else {
            sliderRef.slickGoTo(3);
            setHeaderIndex(3);
          }
        }
      }
    }
  };

  const handleLanguageAnswerQuestion = (curQuestion: any, ans: number) => {
    const curQuestionIndex = languageData.indexOf(curQuestion);
    let allLanguageAnswers = languageAnswers;
    let curAns =
      allLanguageAnswers !== undefined
        ? allLanguageAnswers
          .map((e) => e.id)
          .indexOf(+curQuestion.languageDetailId)
        : -1;
    if (curAns > -1) {
      setLanguageAnswers(
        languageAnswers.map((item) => {
          if (item.id === +curQuestion.languageDetailId) {
            item.answer = ans;
          }
          return item;
        })
      );
      // // now add
      // if (curQuestionIndex > -1 && languageData.length !== (curQuestionIndex + 1)) {
      //     setLanguageDataInStack(questions => [languageData[curQuestionIndex + 1], ...questions]);
      // }
    } else {
      let answer: Answers = {
        milestone: "Language",
        id: +curQuestion.languageDetailId,
        answer: ans,
      };
      if (allLanguageAnswers !== undefined) {
        setLanguageAnswers((answers) => [...answers, answer]);
      } else {
        setLanguageAnswers([answer]);
      }
      if (ans === 1) {
        if (headerArray[4].visible) {
          if (cognitiveData.length > 0) {
            sliderRef.slickGoTo(5);
          } else {
            setNextButtonEnabled(true);
            setIsSubmitButton(true);
          }
          setHeaderIndex(4);
        }
      } else {
        if (
          curQuestionIndex > -1 &&
          languageData.length !== curQuestionIndex + 1
        ) {
          setLanguageDataInStack((questions) => [
            languageData[curQuestionIndex + 1],
            ...questions,
          ]);
        } else {
          if (headerArray[4].visible) {
            if (cognitiveData.length > 0) {
              sliderRef.slickGoTo(5);
            } else {
              setNextButtonEnabled(true);
              setIsSubmitButton(true);
            }
            setHeaderIndex(4);
          }
        }
      }
    }
  };

  const handleCognitiveAnswerQuestion = (curQuestion: any, ans: number) => {
    const curQuestionIndex = cognitiveData.indexOf(curQuestion);
    let allCognitiveAnswers = cognitiveAnswers;
    let curAns =
      allCognitiveAnswers !== undefined
        ? allCognitiveAnswers
          .map((e) => e.id)
          .indexOf(+curQuestion.cognitiveDetailId)
        : -1;
    if (curAns > -1) {
      setCognitiveAnswers(
        cognitiveAnswers.map((item) => {
          if (item.id === +curQuestion.cognitiveDetailId) {
            item.answer = ans;
          }
          return item;
        })
      );
      // // now add
      // if (curQuestionIndex > -1 && cognitiveData.length !== (curQuestionIndex + 1)) {
      //     setCognitiveDataInStack(questions => [cognitiveData[curQuestionIndex + 1], ...questions]);

      // }
    } else {
      let answer: Answers = {
        milestone: "Cognitive",
        id: +curQuestion.cognitiveDetailId,
        answer: ans,
      };
      if (allCognitiveAnswers !== undefined) {
        setCognitiveAnswers((answers) => [...answers, answer]);
      } else {
        setCognitiveAnswers([answer]);
      }
      if (ans === 1) {
        // if (headerArray[4].visible) {
        //     if (cognitiveData.length > 0) {
        //         sliderRef.slickGoTo(5);
        //     }
        //     setHeaderIndex(4);
        // } else {
        //     sliderRef.slickGoTo(3);
        //     setHeaderIndex(3);
        // }
        setNextButtonEnabled(true);
        setIsSubmitButton(true);
      } else {
        if (
          curQuestionIndex > -1 &&
          cognitiveData.length !== curQuestionIndex + 1
        ) {
          setCognitiveDataInStack((questions) => [
            cognitiveData[curQuestionIndex + 1],
            ...questions,
          ]);
        } else {
          setNextButtonEnabled(true);
          setIsSubmitButton(true);
        }
      }
    }
  };

  async function submitMilestoneData() {
    let grossSummaryDetail =
      grossMotorAnswers !== undefined ? grossMotorAnswers : [];
    let fineSummaryDetail =
      fineMotorAnswers !== undefined ? fineMotorAnswers : [];
    let socialSummaryDetail = socialAnswers !== undefined ? socialAnswers : [];
    let languageSummaryDetail =
      languageAnswers !== undefined ? languageAnswers : [];
    let congnitiveSummaryDetail =
      cognitiveAnswers !== undefined ? cognitiveAnswers : [];

    let summaryDetail = [
      ...grossSummaryDetail,
      ...fineSummaryDetail,
      ...socialSummaryDetail,
      ...languageSummaryDetail,
      ...congnitiveSummaryDetail,
    ];
    const days = ageInDays(userContext.memberDetail.birthDate);
    let data = {
      familyId: userContext.memberDetail.familyId,
      familyMemberId: userContext.memberDetail.familyMemberId,
      age: days,
      summary: summaryDetail,
      isDeleted: false,
      dataManagedBy: userContext.loginDetail.userId,
    };
    try {
      const resultMilestoneDataSave: any = await CreateMilestoneSummary(data);
      if (resultMilestoneDataSave.data === true) {
        navigate("/milestone-development");
      }
    } catch (error) {
      if (error) {
        updateUserContext({
          ...userContext,
          isAlert: true,
          alertMessage: error as string,
          alertType: AlertType.Error,
        });
      }
    }
  }

  useEffect(() => {
    if (
      userContext.loginDetail !== undefined &&
      userContext.loginDetail.familyMemberId > 0 &&
      userContext.memberDetail.familyMemberId > 0 &&
      userContext.memberDetail.birthDate !== ""
    ) {
      fetchQuestionsData();
    }
  }, []);

  return (
    <Fade in={true} timeout={{ enter: 500 }}>
      <Box className="milestone-questions-main-box">
        <Box className="head-box">
          <Box className="back-box">
            <IconButton
              aria-label="back"
              className="back-icon"
              disableRipple
              onClick={() => backClick()}>
              <img src={backIcon} alt="logo" />
            </IconButton>
          </Box>
          <Box className="title-box">
            <></>
          </Box>
          <Box className="profile-box">
            <></>
          </Box>
        </Box>

        <Box className="body-box">
          <Box className="question-title-box">
            <BorderLinearProgress
              variant="determinate"
              value={headerArray[headerIndex].id * 20} />
          </Box>
          <Slider
            ref={(slider) => {
              sliderRef = slider;
            }}
            {...SliderSettings}>
            {arrayData.map((mainItem) => (
              <Box className="question-main-box" key={mainItem.id}>
                {mainItem.visible ? (
                  <Typography className="title-text">
                    {" "}
                    {mainItem.headerName}
                  </Typography>
                ) : (
                  <></>
                )}
                {mainItem.id === 1 && mainItem.visible ? (
                  <TransitionGroup className="question-group">
                    {grossMotorDataInStack !== undefined &&
                      grossMotorDataInStack !== null &&
                      grossMotorDataInStack.map((item) => (
                        <Collapse timeout={500} key={item.level}>
                          {renderGrossQuestion({
                            item,
                          })}
                        </Collapse>
                      ))}
                  </TransitionGroup>
                ) : (
                  <></>
                )}
                {mainItem.id === 2 && mainItem.visible ? (
                  <TransitionGroup className="question-group">
                    {fineMotorDataInStack !== undefined &&
                      fineMotorDataInStack !== null &&
                      fineMotorDataInStack.map((item) => (
                        <Collapse timeout={800} key={item.level}>
                          {renderFineQuestion({
                            item,
                          })}
                        </Collapse>
                      ))}
                  </TransitionGroup>
                ) : (
                  <></>
                )}
                {mainItem.id === 3 && mainItem.visible ? (
                  <TransitionGroup className="question-group">
                    {socialDataInStack !== undefined &&
                      socialDataInStack !== null &&
                      socialDataInStack.map((item) => (
                        <Collapse timeout={800} key={item.level}>
                          {renderSocialQuestion({
                            item,
                          })}
                        </Collapse>
                      ))}
                  </TransitionGroup>
                ) : (
                  <></>
                )}

                {mainItem.id === 4 && mainItem.visible ? (
                  <TransitionGroup className="question-group">
                    {languageDataInStack !== undefined &&
                      languageDataInStack !== null &&
                      languageDataInStack.map((item) => (
                        <Collapse timeout={800} key={item.level}>
                          {renderLanguageQuestion({
                            item,
                          })}
                        </Collapse>
                      ))}
                  </TransitionGroup>
                ) : (
                  <></>
                )}

                {mainItem.id === 5 && mainItem.visible ? (
                  <TransitionGroup className="question-group">
                    {cognitiveDataInStack !== undefined &&
                      cognitiveDataInStack !== null &&
                      cognitiveDataInStack.map((item) => (
                        <Collapse timeout={800} key={item.level}>
                          {renderCognitiveQuestion({
                            item,
                          })}
                        </Collapse>
                      ))}
                  </TransitionGroup>
                ) : (
                  <></>
                )}
              </Box>
            ))}
          </Slider>
          <Box className="bottom-box">
            <LoadingButton
              color="primary"
              fullWidth
              variant="contained"
              startIcon={
                isNextButtonEnabled ? (
                  <img className="start-icon" src={previousIcon} alt="logo" />
                ) : (
                  <img className="start-icon" src={backIcon} alt="logo" />
                )
              }
              // loading={loading}
              disabled={arrayData[headerIndex].id === 1 ? true : false}
              className="add-button"
              onClick={goToPrevious}>
              Previous
            </LoadingButton>
            <LoadingButton
              color="primary"
              fullWidth
              variant="contained"
              endIcon={
                isNextButtonEnabled ? (
                  <img className="end-icon" src={nextIcon} alt="logo" />
                ) : (
                  <img className="end-icon" src={backIcon} alt="logo" />
                )
              }
              loading={loading}
              disabled={isNextButtonEnabled ? false : true}
              className="add-button"
              onClick={isSubmitButton ? submitMilestoneData : goToNext}>
              {/* onClick={submitMilestoneData}> */}
              {isSubmitButton === true ? 'Submit' : 'Next'}
            </LoadingButton>
          </Box>
        </Box>
      </Box>
    </Fade>
  );
}
