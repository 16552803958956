import React, { useContext, useEffect, useState } from "react";
import './FamilyMemberList.scss'
import { Backdrop, Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, Fade, IconButton, Skeleton, Typography } from "@mui/material";
import _ from 'lodash';
import { useNavigate } from "react-router-dom";
import { PreferencesContext } from "../../PreferenceContext";
import CustomBottomSheet from "../../components/CustomBottomSheet/CustomBottomSheet";
import { DeleteFamilyMember, GetMemberList } from "../../services/ParentService";
import { convertInWords } from "../../shared/CommonFuctions";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import womanIcon from "../../assets/images/woman.svg";
import girlIcon from "../../assets/images/girl.svg";
import manIcon from "../../assets/images/man.svg";
import boyIcon from "../../assets/images/boy.svg";
import { LoadingButton } from "@mui/lab";
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import { useTranslation } from "react-i18next";

let intData: any = {
    preview: "",
    raw: ""
}
export default function FamilyMemberList() {
    const { userContext, updateUserContext } = useContext(PreferencesContext);
    const [loading, setLoading] = useState(false);
    const [familyMemberDetail, setFamilyMemberDetail] = useState([]);
    const [memberDeleteId, setMemberDeleteId] = useState(0);
    const [memberDeleteModalOpen, setMemberDeleteModalOpen] = useState(false);
    const [deleteLoading, setDeleteLoading] = useState(false);
    const [isImagePreview, setIsImagePreview] = useState(false);
    const [memberImage, setMemberImage] = useState(intData);
    const [showAlert, setAlertMessage] = useState({
        isSuccess: false,
        message: "",
    });

    const { i18n, t } = useTranslation();

    const navigate = useNavigate();

    async function fetchData(isDeleted: any) {
        setLoading(true);
        // if (isDeleted === true) {
            try {
                let params = new URLSearchParams();
                params.append("familyId", userContext.loginDetail.familyId.toString());
                const result: any = await GetMemberList(params);
                let familyMemberCollection = result.data.data.familyMemberCollection;
                setFamilyMemberDetail(familyMemberCollection);
                updateUserContext({
                    ...userContext,
                    allFamilyMembersData: familyMemberCollection
                })
            } catch (error) {
                setLoading(false);
                if (error) {
                    setAlertMessage({
                        isSuccess: true,
                        message: error as string,
                    });
                }
            }

        // }
        setLoading(false);
    }

    const currMemberEdit = (id: any, familyRelationName: any) => {
        updateUserContext({
            ...userContext,
            currFamilyMemberId: id,
            isEditMemberFromBottomSheet: false,
            isAddMemberFromList: false,
            isAddMaternalDetail: false,
            isEditMemberFromList: true,
            isEdit: true
        });

        if (familyRelationName === "SON" || familyRelationName === "DAUGHTER") {
            navigate("/child-detail");
        } else {
            navigate("/parent");
        }
    };

    const showImage = (image, relation, gender) => {
        setIsImagePreview(true);
        var memberImg = image !== null
            ? image
            : gender === "FEMALE"
                ? relation === "MOTHER"
                    ? womanIcon
                    : girlIcon
                : relation === "FATHER"
                    ? manIcon
                    : boyIcon
        setMemberImage({ preview: memberImg });
    }

    const closeImage = () => {
        setIsImagePreview(false);
    }

    const currMemberDelete = (id: any) => {
        setMemberDeleteId(id);
        setMemberDeleteModalOpen(true);
    };

    const closeDeleteModal = () => {
        setMemberDeleteModalOpen(false);
    };

    const addMemberClick = () => {
        updateUserContext({
            ...userContext,
            currFamilyMemberId: 0,
            isAddMemberFromList: true,
            isAddMaternalDetail: false,
            isAddMember: false,
            isEdit: false
        });
        navigate("/parent");
    };

    async function deleteMember() {
        setDeleteLoading(true);
        try {
            let DeleteData = {
                familyMemberId: memberDeleteId.toString(),
                familyId: userContext.loginDetail.familyId,
                isDeleted: true,
                dataManagedBy: userContext.loginDetail.userId,
            };
            const result: any = await DeleteFamilyMember(DeleteData);
            let memberDetail: any = {
                age: 0,
                birthDate: "",
                familyId: userContext.loginDetail.familyId,
                familyMemberId: 0,
                familyRelationName: "",
                firstName: "",
                lastName: "",
                gender: "",
                isPrematureChild: false,
                isSpecialChild: false,
                maternalDetailId: 0,
                complicationsDetails: "",
                expectingChildNo: 0,
                expectingDateOfDelivery: "",
                gestationalAge: 0,
                gestationalAgeTime: "",
                isChildBorn: false,
                isPragnentExpectingChild: false,
                lastManstrualPeriodDate: "",
                profileImageBase: "",
            };
            updateUserContext({
                ...userContext,
                memberDetail: memberDetail,
            });
            fetchData(true);
        } catch (error) {
            setDeleteLoading(false);
            if (error) {
                setAlertMessage({
                    isSuccess: true,
                    message: error as string,
                });
            }
        }
        setDeleteLoading(false);
        setMemberDeleteModalOpen(false);
    }

    useEffect(() => {
        updateUserContext({
            ...userContext,
            isEditMemberFromList: false,
            isAddMemberFromList: false,
            isAddMaternalDetail: false,
            isEditMaternalDetail: false,
            isAddMember: false,
            isBabyDelivered: false,
            currFamilyMemberId: 0,
            curMaternalDetailId: -1,
        });
    }, []);

    useEffect(() => {
        if (userContext.loginDetail !== undefined && userContext.loginDetail.userId > 0) {
            fetchData(false);
        }
    }, [])
    return (
        <Fade in={true} timeout={{ enter: 500 }}>
            <Box className="member-main-box">
                <Backdrop
                    sx={{ color: "transparent", zIndex: 4 }}
                    open={isImagePreview}></Backdrop>

                <CustomBottomSheet>
                    <Box className="member-body">
                        <Box className="member-box">
                            <Typography className="text-name">{t('Family members.Family members')}</Typography>
                            <Box className="add-member-item">
                                <LoadingButton className="add-button" variant="contained" startIcon={<AddRoundedIcon />} onClick={addMemberClick}>{t('Family members.Add')}</LoadingButton>
                            </Box>
                        </Box>

                        <Box className="member-list">
                            {loading === true ?
                                (_.times(3, (i) => (
                                    <Box key={i} sx={{ margin: '10px' }}>
                                        <Skeleton animation="wave" variant="rounded" width={'100%'} height={80} />
                                    </Box>
                                )))
                                : (
                                    userContext.allFamilyMembersData.length > 0 &&
                                    userContext.allFamilyMembersData.map((row: any, index: any) => (
                                        <Box className="member-item" key={index}>
                                            <Box className="left-part">
                                                <Box className="img-box"
                                                    onClick={() => showImage(row.profileImageBase, row.familyRelationName, row.gender)}>
                                                    <img
                                                        src={row.profileImageBase !== null
                                                            ? row.profileImageBase
                                                            : row.gender === "FEMALE"
                                                                ? row.familyRelationName === "MOTHER"
                                                                    ? womanIcon
                                                                    : girlIcon
                                                                : row.familyRelationName === "FATHER"
                                                                    ? manIcon
                                                                    : boyIcon}
                                                        alt="logo"
                                                        className={
                                                            row.gender === "FEMALE"
                                                                ? "female-item"
                                                                : "male-item"
                                                        } />
                                                </Box>
                                            </Box>
                                            <Box className='center-part' onClick={() =>
                                                currMemberEdit(
                                                    row.familyMemberId,
                                                    row.familyRelationName
                                                )}>
                                                <Typography className={row.gender === "FEMALE" ? "member-name female" : "member-name male"}>
                                                    {row.familyRelationName === "MOTHER" ? ((row.isChildBorn === false || row.isTermination === false || row.maternalCount > 0) ? row.firstName + ' (Expecting)' : row.firstName) : row.firstName}
                                                </Typography>
                                                <Typography className="member-detail">
                                                    {(userContext.loginDetail.familyMemberId ===
                                                        row.familyMemberId ? "Me" : row.familyRelationName) +
                                                        " " +
                                                        (row.birthDate === ""
                                                            ? "(" + row.age + "y)"
                                                            : convertInWords(row.birthDate))}
                                                </Typography>
                                            </Box>
                                            <Box className="right-part">
                                                <Box className="edit-box" onClick={() =>
                                                    currMemberEdit(
                                                        row.familyMemberId,
                                                        row.familyRelationName
                                                    )
                                                }>
                                                    {/* <IconButton
                                                        aria-label="back"
                                                        disableRipple
                                                        onClick={() =>
                                                            currMemberEdit(
                                                                row.familyMemberId,
                                                                row.familyRelationName
                                                            )
                                                        }
                                                    >
                                                        <EditRoundedIcon className="edit-icon" />
                                                    </IconButton> */}
                                                </Box>
                                                <Box className="delete-box">
                                                    {userContext.loginDetail.familyMemberId ===
                                                        row.familyMemberId ? (<></>) : (
                                                        <IconButton
                                                            aria-label="back"
                                                            disableRipple
                                                            onClick={() =>
                                                                currMemberDelete(row.familyMemberId)
                                                            }>
                                                            <DeleteRoundedIcon className="delete-icon" />
                                                        </IconButton>
                                                    )}
                                                </Box>
                                            </Box>
                                        </Box>
                                    )))}
                        </Box>
                    </Box>
                </CustomBottomSheet>

                <Dialog
                    maxWidth="md"
                    fullWidth={true}
                    open={memberDeleteModalOpen}
                    onClose={closeDeleteModal}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    className="user-verify-dialog-box"
                >
                    <DialogContent>
                        <DialogContentText>
                            Are you sure want to delete this Member?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <LoadingButton
                            variant="contained"
                            onClick={() => deleteMember()}
                            className=""
                            loading={deleteLoading}
                        >
                            Yes
                        </LoadingButton>
                        <Button
                            variant="outlined"
                            onClick={() => closeDeleteModal()}
                            className=""
                        >
                            No
                        </Button>
                    </DialogActions>
                </Dialog>

                <Box className="preview-box">
                    <Dialog
                        fullWidth={true}
                        open={isImagePreview}
                        onClose={closeImage}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                        className="set-member-image-box">
                        <Box className="image-container">
                            <img className='img-preview' alt="logo" src={memberImage.preview} />
                        </Box>
                        {/* <DialogActions className="member-image-close-box" onClick={() => closeImage()}> */}
                        {/* <CloseRoundedIcon className="close-icon" /> */}
                        {/* </DialogActions> */}
                    </Dialog>
                </Box>
            </Box>
        </Fade>
    );
}