import React, { useContext, useEffect, useState } from "react";
import "./HeadCircumference.scss";
import styles from "../PhysicalGrowth/BottomSheetStyles.module.css";
import { useNavigate } from "react-router-dom";
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import {
  Backdrop,
  Box,
  Fade,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  Link,
  OutlinedInput,
  Skeleton,
  Typography,
} from "@mui/material";
import backIcon from "../../assets/icons/back.svg";
import hcIcon from '../../assets/icons/baby-hc.svg';
import CustomTabs from "../../components/CustomTabs/CustomTabs";
import { PreferencesContext } from "../../PreferenceContext";
import {
  CreateHCDetail,
  GetHCDetailForChart,
  GetMemberHCDetailList,
} from "../../services/PhysicalGrowthService";
import CustomButton from "../../components/CustomButton/CustomButton";
import { useDrag } from "@use-gesture/react";
import { a, useSpring, config } from "@react-spring/web";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { AlertType } from "../../shared/AlertType";
import { ageInDays, ageInMonths } from "../../shared/CommonFuctions";

var colorPalette = ["red", "green", "orange"];

const formInitialValues = {
  hcDetailId: 0,
  hc: "",
};

export default function HeadCircumference() {
  const { userContext, updateUserContext } = useContext(PreferencesContext);
  const [loading, setLoading] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [isBottomSheetOpen, setIsBottomSheetOpen] = useState(false);
  const [hcData, setHCData] = useState(formInitialValues);
  const [memberHCDetailData, setMemberHCDetailData] = useState([]);
  const [hcChartData, setHCChartData] = useState([]);
  const [ageCountData, setAgeCountData] = useState(-1);
  const [showAlert, setAlertMessage] = useState({
    isSuccess: false,
    message: "",
  });
  const navigate = useNavigate();

  function backClick() {
    navigate("/physical-growth");
  }

  const headCircumferenceOptions = {
    // color: [
    //   // 91 to 99 - red
    //   "#FF0000", "#FF0000", "#FF0000", "#FF0000", "#FF0000", "#FF0000", "#FF0000", "#FF0000", "#FF0000",

    //   // 76 to 90 - orange
    //   "#FF8000", "#FF8000", "#FF8000", "#FF8000", "#FF8000", "#FF8000", "#FF8000", "#FF8000", "#FF8000",
    //   "#FF8000", "#FF8000", "#FF8000", "#FF8000", "#FF8000", "#FF8000",

    //   // 51 to 75 - lemon
    //   "#FFED00", "#FFED00", "#FFED00", "#FFED00", "#FFED00", "#FFED00", "#FFED00", "#FFED00", "#FFED00",
    //   "#FFED00", "#FFED00", "#FFED00", "#FFED00", "#FFED00", "#FFED00", "#FFED00", "#FFED00", "#FFED00",
    //   "#FFED00", "#FFED00", "#FFED00", "#FFED00", "#FFED00", "#FFED00", "#FFED00",

    //   // 26 to 50 - green
    //   "#16FF00", "#16FF00", "#16FF00", "#16FF00", "#16FF00", "#16FF00", "#16FF00", "#16FF00", "#16FF00",
    //   "#16FF00", "#16FF00", "#16FF00", "#16FF00", "#16FF00", "#16FF00", "#16FF00", "#16FF00", "#16FF00",
    //   "#16FF00", "#16FF00", "#16FF00", "#16FF00", "#16FF00", "#16FF00", "#16FF00",

    //   // 11 to 25 - lemon
    //   "#FFED00", "#FFED00", "#FFED00", "#FFED00", "#FFED00", "#FFED00", "#FFED00", "#FFED00", "#FFED00",
    //   "#FFED00", "#FFED00", "#FFED00", "#FFED00", "#FFED00", "#FFED00",

    //   // 4 to 10 - orange
    //   "#FF8000", "#FF8000", "#FF8000", "#FF8000", "#FF8000", "#FF8000", "#FF8000",

    //   // 1 to 3 - red
    //   // "#FFB3B3", "#FFB3B3", "#FFB3B3",
    //   "#FF0000", "#FF0000", "#FF0000",

    //   "#000000",
    // ],
    // tooltip: {
    //   trigger: "axis",
    //   axisPointer: {
    //     type: "cross",
    //     label: {
    //       backgroundColor: "#6a7985",
    //     },
    //   },
    // },
    // // grid: {
    // //     left: '3%',
    // //     right: '4%',
    // //     bottom: '3%',
    // //     containLabel: true
    // // },
    // xAxis: [
    //   {
    //     type: "category",
    //     boundaryGap: false,
    //     name: "Days",
    //     nameLocation: "middle",
    //     nameGap: 30,
    //     data:
    //       hcChartData !== undefined &&
    //       hcChartData.length > 0 &&
    //       hcChartData.map((row: any, index: any) => row.days),
    //   },
    // ],
    // yAxis: [
    //   {
    //     type: "value",
    //     name: "HC in cm",
    //     nameGap: 30,
    //     axisLine: {
    //       onZero: false,
    //     },
    //     nameTextStyle: {
    //       padding: [0, 0, 0, 20],
    //     },
    //     min: "dataMin",
    //   },
    // ],
    // series: [
    //   // p91 to p99
    //   {
    //     name: "P99",
    //     type: "line",
    //     silent: true,
    //     smooth: true,
    //     lineStyle: {
    //       width: 0,
    //     },
    //     showSymbol: false,
    //     areaStyle: {
    //       opacity: 0.8,
    //     },
    //     emphasis: {
    //       focus: "series",
    //     },
    //     data:
    //       hcChartData.length > 0 &&
    //       hcChartData.map((row: any, index: any) => row.p99),
    //   },
    //   {
    //     name: "P98",
    //     type: "line",
    //     silent: true,
    //     smooth: true,
    //     lineStyle: {
    //       width: 0,
    //     },
    //     showSymbol: false,
    //     areaStyle: {
    //       opacity: 0.8,
    //     },
    //     emphasis: {
    //       focus: "series",
    //     },
    //     data:
    //       hcChartData.length > 0 &&
    //       hcChartData.map((row: any, index: any) => row.p98),
    //   },
    //   {
    //     name: "P97",
    //     type: "line",
    //     silent: true,
    //     smooth: true,
    //     lineStyle: {
    //       width: 0,
    //     },
    //     showSymbol: false,
    //     areaStyle: {
    //       opacity: 0.8,
    //     },
    //     emphasis: {
    //       focus: "series",
    //     },
    //     data:
    //       hcChartData.length > 0 &&
    //       hcChartData.map((row: any, index: any) => row.p97),
    //   },
    //   {
    //     name: "P96",
    //     type: "line",
    //     silent: true,
    //     smooth: true,
    //     lineStyle: {
    //       width: 0,
    //     },
    //     showSymbol: false,
    //     areaStyle: {
    //       opacity: 0.8,
    //     },
    //     emphasis: {
    //       focus: "series",
    //     },
    //     data:
    //       hcChartData.length > 0 &&
    //       hcChartData.map((row: any, index: any) => row.p96),
    //   },
    //   {
    //     name: "P95",
    //     type: "line",
    //     silent: true,
    //     smooth: true,
    //     lineStyle: {
    //       width: 0,
    //     },
    //     showSymbol: false,
    //     areaStyle: {
    //       opacity: 0.8,
    //     },
    //     emphasis: {
    //       focus: "series",
    //     },
    //     data:
    //       hcChartData.length > 0 &&
    //       hcChartData.map((row: any, index: any) => row.p95),
    //   },
    //   {
    //     name: "P94",
    //     type: "line",
    //     silent: true,
    //     smooth: true,
    //     lineStyle: {
    //       width: 0,
    //     },
    //     showSymbol: false,
    //     areaStyle: {
    //       opacity: 0.8,
    //     },
    //     emphasis: {
    //       focus: "series",
    //     },
    //     data:
    //       hcChartData.length > 0 &&
    //       hcChartData.map((row: any, index: any) => row.p94),
    //   },
    //   {
    //     name: "P93",
    //     type: "line",
    //     silent: true,
    //     smooth: true,
    //     lineStyle: {
    //       width: 0,
    //     },
    //     showSymbol: false,
    //     areaStyle: {
    //       opacity: 0.8,
    //     },
    //     emphasis: {
    //       focus: "series",
    //     },
    //     data:
    //       hcChartData.length > 0 &&
    //       hcChartData.map((row: any, index: any) => row.p93),
    //   },
    //   {
    //     name: "P92",
    //     type: "line",
    //     silent: true,
    //     smooth: true,
    //     lineStyle: {
    //       width: 0,
    //     },
    //     showSymbol: false,
    //     areaStyle: {
    //       opacity: 0.8,
    //     },
    //     emphasis: {
    //       focus: "series",
    //     },
    //     data:
    //       hcChartData.length > 0 &&
    //       hcChartData.map((row: any, index: any) => row.p92),
    //   },
    //   {
    //     name: "P91",
    //     type: "line",
    //     silent: true,
    //     smooth: true,
    //     lineStyle: {
    //       width: 0,
    //     },
    //     showSymbol: false,
    //     areaStyle: {
    //       opacity: 0.8,
    //     },
    //     emphasis: {
    //       focus: "series",
    //     },
    //     data:
    //       hcChartData.length > 0 &&
    //       hcChartData.map((row: any, index: any) => row.p91),
    //   },

    //   // p76 to p90
    //   {
    //     name: "P90",
    //     type: "line",
    //     silent: true,
    //     smooth: true,
    //     lineStyle: {
    //       width: 0,
    //     },
    //     showSymbol: false,
    //     areaStyle: {
    //       opacity: 0.8,
    //     },
    //     emphasis: {
    //       focus: "series",
    //     },
    //     data:
    //       hcChartData.length > 0 &&
    //       hcChartData.map((row: any, index: any) => row.p90),
    //   },
    //   {
    //     name: "P89",
    //     type: "line",
    //     silent: true,
    //     smooth: true,
    //     lineStyle: {
    //       width: 0,
    //     },
    //     showSymbol: false,
    //     areaStyle: {
    //       opacity: 0.8,
    //     },
    //     emphasis: {
    //       focus: "series",
    //     },
    //     data:
    //       hcChartData.length > 0 &&
    //       hcChartData.map((row: any, index: any) => row.p89),
    //   },
    //   {
    //     name: "P88",
    //     type: "line",
    //     silent: true,
    //     smooth: true,
    //     lineStyle: {
    //       width: 0,
    //     },
    //     showSymbol: false,
    //     areaStyle: {
    //       opacity: 0.8,
    //     },
    //     emphasis: {
    //       focus: "series",
    //     },
    //     data:
    //       hcChartData.length > 0 &&
    //       hcChartData.map((row: any, index: any) => row.p88),
    //   },
    //   {
    //     name: "P87",
    //     type: "line",
    //     silent: true,
    //     smooth: true,
    //     lineStyle: {
    //       width: 0,
    //     },
    //     showSymbol: false,
    //     areaStyle: {
    //       opacity: 0.8,
    //     },
    //     emphasis: {
    //       focus: "series",
    //     },
    //     data:
    //       hcChartData.length > 0 &&
    //       hcChartData.map((row: any, index: any) => row.p87),
    //   },
    //   {
    //     name: "P86",
    //     type: "line",
    //     silent: true,
    //     smooth: true,
    //     lineStyle: {
    //       width: 0,
    //     },
    //     showSymbol: false,
    //     areaStyle: {
    //       opacity: 0.8,
    //     },
    //     emphasis: {
    //       focus: "series",
    //     },
    //     data:
    //       hcChartData.length > 0 &&
    //       hcChartData.map((row: any, index: any) => row.p86),
    //   },
    //   {
    //     name: "P85",
    //     type: "line",
    //     silent: true,
    //     smooth: true,
    //     lineStyle: {
    //       width: 0,
    //     },
    //     showSymbol: false,
    //     areaStyle: {
    //       opacity: 0.8,
    //     },
    //     emphasis: {
    //       focus: "series",
    //     },
    //     data:
    //       hcChartData.length > 0 &&
    //       hcChartData.map((row: any, index: any) => row.p85),
    //   },
    //   {
    //     name: "P84",
    //     type: "line",
    //     silent: true,
    //     smooth: true,
    //     lineStyle: {
    //       width: 0,
    //     },
    //     showSymbol: false,
    //     areaStyle: {
    //       opacity: 0.8,
    //     },
    //     emphasis: {
    //       focus: "series",
    //     },
    //     data:
    //       hcChartData.length > 0 &&
    //       hcChartData.map((row: any, index: any) => row.p84),
    //   },
    //   {
    //     name: "P83",
    //     type: "line",
    //     silent: true,
    //     smooth: true,
    //     lineStyle: {
    //       width: 0,
    //     },
    //     showSymbol: false,
    //     areaStyle: {
    //       opacity: 0.8,
    //     },
    //     emphasis: {
    //       focus: "series",
    //     },
    //     data:
    //       hcChartData.length > 0 &&
    //       hcChartData.map((row: any, index: any) => row.p83),
    //   },
    //   {
    //     name: "P82",
    //     type: "line",
    //     silent: true,
    //     smooth: true,
    //     lineStyle: {
    //       width: 0,
    //     },
    //     showSymbol: false,
    //     areaStyle: {
    //       opacity: 0.8,
    //     },
    //     emphasis: {
    //       focus: "series",
    //     },
    //     data:
    //       hcChartData.length > 0 &&
    //       hcChartData.map((row: any, index: any) => row.p82),
    //   },
    //   {
    //     name: "P81",
    //     type: "line",
    //     silent: true,
    //     smooth: true,
    //     lineStyle: {
    //       width: 0,
    //     },
    //     showSymbol: false,
    //     areaStyle: {
    //       opacity: 0.8,
    //     },
    //     emphasis: {
    //       focus: "series",
    //     },
    //     data:
    //       hcChartData.length > 0 &&
    //       hcChartData.map((row: any, index: any) => row.p81),
    //   },
    //   {
    //     name: "P80",
    //     type: "line",
    //     silent: true,
    //     smooth: true,
    //     lineStyle: {
    //       width: 0,
    //     },
    //     showSymbol: false,
    //     areaStyle: {
    //       opacity: 0.8,
    //     },
    //     emphasis: {
    //       focus: "series",
    //     },
    //     data:
    //       hcChartData.length > 0 &&
    //       hcChartData.map((row: any, index: any) => row.p80),
    //   },
    //   {
    //     name: "P79",
    //     type: "line",
    //     silent: true,
    //     smooth: true,
    //     lineStyle: {
    //       width: 0,
    //     },
    //     showSymbol: false,
    //     areaStyle: {
    //       opacity: 0.8,
    //     },
    //     emphasis: {
    //       focus: "series",
    //     },
    //     data:
    //       hcChartData.length > 0 &&
    //       hcChartData.map((row: any, index: any) => row.p79),
    //   },
    //   {
    //     name: "P78",
    //     type: "line",
    //     silent: true,
    //     smooth: true,
    //     lineStyle: {
    //       width: 0,
    //     },
    //     showSymbol: false,
    //     areaStyle: {
    //       opacity: 0.8,
    //     },
    //     emphasis: {
    //       focus: "series",
    //     },
    //     data:
    //       hcChartData.length > 0 &&
    //       hcChartData.map((row: any, index: any) => row.p78),
    //   },
    //   {
    //     name: "P77",
    //     type: "line",
    //     silent: true,
    //     smooth: true,
    //     lineStyle: {
    //       width: 0,
    //     },
    //     showSymbol: false,
    //     areaStyle: {
    //       opacity: 0.8,
    //     },
    //     emphasis: {
    //       focus: "series",
    //     },
    //     data:
    //       hcChartData.length > 0 &&
    //       hcChartData.map((row: any, index: any) => row.p77),
    //   },
    //   {
    //     name: "P76",
    //     type: "line",
    //     silent: true,
    //     smooth: true,
    //     lineStyle: {
    //       width: 0,
    //     },
    //     showSymbol: false,
    //     areaStyle: {
    //       opacity: 0.8,
    //     },
    //     emphasis: {
    //       focus: "series",
    //     },
    //     data:
    //       hcChartData.length > 0 &&
    //       hcChartData.map((row: any, index: any) => row.p76),
    //   },


    //   // p51 to p75
    //   {
    //     name: "P75",
    //     type: "line",
    //     silent: true,
    //     smooth: true,
    //     lineStyle: {
    //       width: 0,
    //     },
    //     showSymbol: false,
    //     areaStyle: {
    //       opacity: 0.8,
    //     },
    //     emphasis: {
    //       focus: "series",
    //     },
    //     data:
    //       hcChartData.length > 0 &&
    //       hcChartData.map((row: any, index: any) => row.p75),
    //   },
    //   {
    //     name: "P74",
    //     type: "line",
    //     silent: true,
    //     smooth: true,
    //     lineStyle: {
    //       width: 0,
    //     },
    //     showSymbol: false,
    //     areaStyle: {
    //       opacity: 0.8,
    //     },
    //     emphasis: {
    //       focus: "series",
    //     },
    //     data:
    //       hcChartData.length > 0 &&
    //       hcChartData.map((row: any, index: any) => row.p74),
    //   },
    //   {
    //     name: "P73",
    //     type: "line",
    //     silent: true,
    //     smooth: true,
    //     lineStyle: {
    //       width: 0,
    //     },
    //     showSymbol: false,
    //     areaStyle: {
    //       opacity: 0.8,
    //     },
    //     emphasis: {
    //       focus: "series",
    //     },
    //     data:
    //       hcChartData.length > 0 &&
    //       hcChartData.map((row: any, index: any) => row.p73),
    //   },
    //   {
    //     name: "P72",
    //     type: "line",
    //     silent: true,
    //     smooth: true,
    //     lineStyle: {
    //       width: 0,
    //     },
    //     showSymbol: false,
    //     areaStyle: {
    //       opacity: 0.8,
    //     },
    //     emphasis: {
    //       focus: "series",
    //     },
    //     data:
    //       hcChartData.length > 0 &&
    //       hcChartData.map((row: any, index: any) => row.p72),
    //   },
    //   {
    //     name: "P71",
    //     type: "line",
    //     silent: true,
    //     smooth: true,
    //     lineStyle: {
    //       width: 0,
    //     },
    //     showSymbol: false,
    //     areaStyle: {
    //       opacity: 0.8,
    //     },
    //     emphasis: {
    //       focus: "series",
    //     },
    //     data:
    //       hcChartData.length > 0 &&
    //       hcChartData.map((row: any, index: any) => row.p71),
    //   },
    //   {
    //     name: "P70",
    //     type: "line",
    //     silent: true,
    //     smooth: true,
    //     lineStyle: {
    //       width: 0,
    //     },
    //     showSymbol: false,
    //     areaStyle: {
    //       opacity: 0.8,
    //     },
    //     emphasis: {
    //       focus: "series",
    //     },
    //     data:
    //       hcChartData.length > 0 &&
    //       hcChartData.map((row: any, index: any) => row.p70),
    //   },
    //   {
    //     name: "P69",
    //     type: "line",
    //     silent: true,
    //     smooth: true,
    //     lineStyle: {
    //       width: 0,
    //     },
    //     showSymbol: false,
    //     areaStyle: {
    //       opacity: 0.8,
    //     },
    //     emphasis: {
    //       focus: "series",
    //     },
    //     data:
    //       hcChartData.length > 0 &&
    //       hcChartData.map((row: any, index: any) => row.p69),
    //   },
    //   {
    //     name: "P68",
    //     type: "line",
    //     silent: true,
    //     smooth: true,
    //     lineStyle: {
    //       width: 0,
    //     },
    //     showSymbol: false,
    //     areaStyle: {
    //       opacity: 0.8,
    //     },
    //     emphasis: {
    //       focus: "series",
    //     },
    //     data:
    //       hcChartData.length > 0 &&
    //       hcChartData.map((row: any, index: any) => row.p68),
    //   },
    //   {
    //     name: "P67",
    //     type: "line",
    //     silent: true,
    //     smooth: true,
    //     lineStyle: {
    //       width: 0,
    //     },
    //     showSymbol: false,
    //     areaStyle: {
    //       opacity: 0.8,
    //     },
    //     emphasis: {
    //       focus: "series",
    //     },
    //     data:
    //       hcChartData.length > 0 &&
    //       hcChartData.map((row: any, index: any) => row.p67),
    //   },
    //   {
    //     name: "P66",
    //     type: "line",
    //     silent: true,
    //     smooth: true,
    //     lineStyle: {
    //       width: 0,
    //     },
    //     showSymbol: false,
    //     areaStyle: {
    //       opacity: 0.8,
    //     },
    //     emphasis: {
    //       focus: "series",
    //     },
    //     data:
    //       hcChartData.length > 0 &&
    //       hcChartData.map((row: any, index: any) => row.p66),
    //   },
    //   {
    //     name: "P65",
    //     type: "line",
    //     silent: true,
    //     smooth: true,
    //     lineStyle: {
    //       width: 0,
    //     },
    //     showSymbol: false,
    //     areaStyle: {
    //       opacity: 0.8,
    //     },
    //     emphasis: {
    //       focus: "series",
    //     },
    //     data:
    //       hcChartData.length > 0 &&
    //       hcChartData.map((row: any, index: any) => row.p65),
    //   },
    //   {
    //     name: "P64",
    //     type: "line",
    //     silent: true,
    //     smooth: true,
    //     lineStyle: {
    //       width: 0,
    //     },
    //     showSymbol: false,
    //     areaStyle: {
    //       opacity: 0.8,
    //     },
    //     emphasis: {
    //       focus: "series",
    //     },
    //     data:
    //       hcChartData.length > 0 &&
    //       hcChartData.map((row: any, index: any) => row.p64),
    //   },
    //   {
    //     name: "P63",
    //     type: "line",
    //     silent: true,
    //     smooth: true,
    //     lineStyle: {
    //       width: 0,
    //     },
    //     showSymbol: false,
    //     areaStyle: {
    //       opacity: 0.8,
    //     },
    //     emphasis: {
    //       focus: "series",
    //     },
    //     data:
    //       hcChartData.length > 0 &&
    //       hcChartData.map((row: any, index: any) => row.p63),
    //   },
    //   {
    //     name: "P62",
    //     type: "line",
    //     silent: true,
    //     smooth: true,
    //     lineStyle: {
    //       width: 0,
    //     },
    //     showSymbol: false,
    //     areaStyle: {
    //       opacity: 0.8,
    //     },
    //     emphasis: {
    //       focus: "series",
    //     },
    //     data:
    //       hcChartData.length > 0 &&
    //       hcChartData.map((row: any, index: any) => row.p62),
    //   },
    //   {
    //     name: "P61",
    //     type: "line",
    //     silent: true,
    //     smooth: true,
    //     lineStyle: {
    //       width: 0,
    //     },
    //     showSymbol: false,
    //     areaStyle: {
    //       opacity: 0.8,
    //     },
    //     emphasis: {
    //       focus: "series",
    //     },
    //     data:
    //       hcChartData.length > 0 &&
    //       hcChartData.map((row: any, index: any) => row.p61),
    //   },
    //   {
    //     name: "P60",
    //     type: "line",
    //     silent: true,
    //     smooth: true,
    //     lineStyle: {
    //       width: 0,
    //     },
    //     showSymbol: false,
    //     areaStyle: {
    //       opacity: 0.8,
    //     },
    //     emphasis: {
    //       focus: "series",
    //     },
    //     data:
    //       hcChartData.length > 0 &&
    //       hcChartData.map((row: any, index: any) => row.p60),
    //   },
    //   {
    //     name: "P59",
    //     type: "line",
    //     silent: true,
    //     smooth: true,
    //     lineStyle: {
    //       width: 0,
    //     },
    //     showSymbol: false,
    //     areaStyle: {
    //       opacity: 0.8,
    //     },
    //     emphasis: {
    //       focus: "series",
    //     },
    //     data:
    //       hcChartData.length > 0 &&
    //       hcChartData.map((row: any, index: any) => row.p59),
    //   },
    //   {
    //     name: "P58",
    //     type: "line",
    //     silent: true,
    //     smooth: true,
    //     lineStyle: {
    //       width: 0,
    //     },
    //     showSymbol: false,
    //     areaStyle: {
    //       opacity: 0.8,
    //     },
    //     emphasis: {
    //       focus: "series",
    //     },
    //     data:
    //       hcChartData.length > 0 &&
    //       hcChartData.map((row: any, index: any) => row.p58),
    //   },
    //   {
    //     name: "P57",
    //     type: "line",
    //     silent: true,
    //     smooth: true,
    //     lineStyle: {
    //       width: 0,
    //     },
    //     showSymbol: false,
    //     areaStyle: {
    //       opacity: 0.8,
    //     },
    //     emphasis: {
    //       focus: "series",
    //     },
    //     data:
    //       hcChartData.length > 0 &&
    //       hcChartData.map((row: any, index: any) => row.p57),
    //   },
    //   {
    //     name: "P56",
    //     type: "line",
    //     silent: true,
    //     smooth: true,
    //     lineStyle: {
    //       width: 0,
    //     },
    //     showSymbol: false,
    //     areaStyle: {
    //       opacity: 0.8,
    //     },
    //     emphasis: {
    //       focus: "series",
    //     },
    //     data:
    //       hcChartData.length > 0 &&
    //       hcChartData.map((row: any, index: any) => row.p56),
    //   },
    //   {
    //     name: "P55",
    //     type: "line",
    //     silent: true,
    //     smooth: true,
    //     lineStyle: {
    //       width: 0,
    //     },
    //     showSymbol: false,
    //     areaStyle: {
    //       opacity: 0.8,
    //     },
    //     emphasis: {
    //       focus: "series",
    //     },
    //     data:
    //       hcChartData.length > 0 &&
    //       hcChartData.map((row: any, index: any) => row.p55),
    //   },
    //   {
    //     name: "P54",
    //     type: "line",
    //     silent: true,
    //     smooth: true,
    //     lineStyle: {
    //       width: 0,
    //     },
    //     showSymbol: false,
    //     areaStyle: {
    //       opacity: 0.8,
    //     },
    //     emphasis: {
    //       focus: "series",
    //     },
    //     data:
    //       hcChartData.length > 0 &&
    //       hcChartData.map((row: any, index: any) => row.p54),
    //   },
    //   {
    //     name: "P53",
    //     type: "line",
    //     silent: true,
    //     smooth: true,
    //     lineStyle: {
    //       width: 0,
    //     },
    //     showSymbol: false,
    //     areaStyle: {
    //       opacity: 0.8,
    //     },
    //     emphasis: {
    //       focus: "series",
    //     },
    //     data:
    //       hcChartData.length > 0 &&
    //       hcChartData.map((row: any, index: any) => row.p53),
    //   },
    //   {
    //     name: "P52",
    //     type: "line",
    //     silent: true,
    //     smooth: true,
    //     lineStyle: {
    //       width: 0,
    //     },
    //     showSymbol: false,
    //     areaStyle: {
    //       opacity: 0.8,
    //     },
    //     emphasis: {
    //       focus: "series",
    //     },
    //     data:
    //       hcChartData.length > 0 &&
    //       hcChartData.map((row: any, index: any) => row.p52),
    //   },
    //   {
    //     name: "P51",
    //     type: "line",
    //     silent: true,
    //     smooth: true,
    //     lineStyle: {
    //       width: 0,
    //     },
    //     showSymbol: false,
    //     areaStyle: {
    //       opacity: 0.8,
    //     },
    //     emphasis: {
    //       focus: "series",
    //     },
    //     data:
    //       hcChartData.length > 0 &&
    //       hcChartData.map((row: any, index: any) => row.p51),
    //   },

    //   // p26 to p50
    //   {
    //     name: "P50",
    //     type: "line",
    //     silent: true,
    //     smooth: true,
    //     lineStyle: {
    //       width: 0,
    //     },
    //     showSymbol: false,
    //     label: {
    //       show: true,
    //       position: "top",
    //     },
    //     areaStyle: {
    //       opacity: 0.8,
    //     },
    //     emphasis: {
    //       focus: "series",
    //     },
    //     data:
    //       hcChartData.length > 0 &&
    //       hcChartData.map((row: any, index: any) => row.p50),
    //   },
    //   {
    //     name: "P49",
    //     type: "line",
    //     silent: true,
    //     smooth: true,
    //     lineStyle: {
    //       width: 0,
    //     },
    //     showSymbol: false,
    //     label: {
    //       show: true,
    //       position: "top",
    //     },
    //     areaStyle: {
    //       opacity: 0.8,
    //     },
    //     emphasis: {
    //       focus: "series",
    //     },
    //     data:
    //       hcChartData.length > 0 &&
    //       hcChartData.map((row: any, index: any) => row.p49),
    //   },
    //   {
    //     name: "P48",
    //     type: "line",
    //     silent: true,
    //     smooth: true,
    //     lineStyle: {
    //       width: 0,
    //     },
    //     showSymbol: false,
    //     label: {
    //       show: true,
    //       position: "top",
    //     },
    //     areaStyle: {
    //       opacity: 0.8,
    //     },
    //     emphasis: {
    //       focus: "series",
    //     },
    //     data:
    //       hcChartData.length > 0 &&
    //       hcChartData.map((row: any, index: any) => row.p48),
    //   },
    //   {
    //     name: "P47",
    //     type: "line",
    //     silent: true,
    //     smooth: true,
    //     lineStyle: {
    //       width: 0,
    //     },
    //     showSymbol: false,
    //     label: {
    //       show: true,
    //       position: "top",
    //     },
    //     areaStyle: {
    //       opacity: 0.8,
    //     },
    //     emphasis: {
    //       focus: "series",
    //     },
    //     data:
    //       hcChartData.length > 0 &&
    //       hcChartData.map((row: any, index: any) => row.p47),
    //   },
    //   {
    //     name: "P46",
    //     type: "line",
    //     silent: true,
    //     smooth: true,
    //     lineStyle: {
    //       width: 0,
    //     },
    //     showSymbol: false,
    //     label: {
    //       show: true,
    //       position: "top",
    //     },
    //     areaStyle: {
    //       opacity: 0.8,
    //     },
    //     emphasis: {
    //       focus: "series",
    //     },
    //     data:
    //       hcChartData.length > 0 &&
    //       hcChartData.map((row: any, index: any) => row.p46),
    //   },
    //   {
    //     name: "P45",
    //     type: "line",
    //     silent: true,
    //     smooth: true,
    //     lineStyle: {
    //       width: 0,
    //     },
    //     showSymbol: false,
    //     label: {
    //       show: true,
    //       position: "top",
    //     },
    //     areaStyle: {
    //       opacity: 0.8,
    //     },
    //     emphasis: {
    //       focus: "series",
    //     },
    //     data:
    //       hcChartData.length > 0 &&
    //       hcChartData.map((row: any, index: any) => row.p45),
    //   },
    //   {
    //     name: "P44",
    //     type: "line",
    //     silent: true,
    //     smooth: true,
    //     lineStyle: {
    //       width: 0,
    //     },
    //     showSymbol: false,
    //     label: {
    //       show: true,
    //       position: "top",
    //     },
    //     areaStyle: {
    //       opacity: 0.8,
    //     },
    //     emphasis: {
    //       focus: "series",
    //     },
    //     data:
    //       hcChartData.length > 0 &&
    //       hcChartData.map((row: any, index: any) => row.p44),
    //   },
    //   {
    //     name: "P43",
    //     type: "line",
    //     silent: true,
    //     smooth: true,
    //     lineStyle: {
    //       width: 0,
    //     },
    //     showSymbol: false,
    //     label: {
    //       show: true,
    //       position: "top",
    //     },
    //     areaStyle: {
    //       opacity: 0.8,
    //     },
    //     emphasis: {
    //       focus: "series",
    //     },
    //     data:
    //       hcChartData.length > 0 &&
    //       hcChartData.map((row: any, index: any) => row.p43),
    //   },
    //   {
    //     name: "P42",
    //     type: "line",
    //     silent: true,
    //     smooth: true,
    //     lineStyle: {
    //       width: 0,
    //     },
    //     showSymbol: false,
    //     label: {
    //       show: true,
    //       position: "top",
    //     },
    //     areaStyle: {
    //       opacity: 0.8,
    //     },
    //     emphasis: {
    //       focus: "series",
    //     },
    //     data:
    //       hcChartData.length > 0 &&
    //       hcChartData.map((row: any, index: any) => row.p42),
    //   },
    //   {
    //     name: "P41",
    //     type: "line",
    //     silent: true,
    //     smooth: true,
    //     lineStyle: {
    //       width: 0,
    //     },
    //     showSymbol: false,
    //     label: {
    //       show: true,
    //       position: "top",
    //     },
    //     areaStyle: {
    //       opacity: 0.8,
    //     },
    //     emphasis: {
    //       focus: "series",
    //     },
    //     data:
    //       hcChartData.length > 0 &&
    //       hcChartData.map((row: any, index: any) => row.p41),
    //   },
    //   {
    //     name: "P40",
    //     type: "line",
    //     silent: true,
    //     smooth: true,
    //     lineStyle: {
    //       width: 0,
    //     },
    //     showSymbol: false,
    //     label: {
    //       show: true,
    //       position: "top",
    //     },
    //     areaStyle: {
    //       opacity: 0.8,
    //     },
    //     emphasis: {
    //       focus: "series",
    //     },
    //     data:
    //       hcChartData.length > 0 &&
    //       hcChartData.map((row: any, index: any) => row.p40),
    //   },
    //   {
    //     name: "P39",
    //     type: "line",
    //     silent: true,
    //     smooth: true,
    //     lineStyle: {
    //       width: 0,
    //     },
    //     showSymbol: false,
    //     label: {
    //       show: true,
    //       position: "top",
    //     },
    //     areaStyle: {
    //       opacity: 0.8,
    //     },
    //     emphasis: {
    //       focus: "series",
    //     },
    //     data:
    //       hcChartData.length > 0 &&
    //       hcChartData.map((row: any, index: any) => row.p39),
    //   },
    //   {
    //     name: "P38",
    //     type: "line",
    //     silent: true,
    //     smooth: true,
    //     lineStyle: {
    //       width: 0,
    //     },
    //     showSymbol: false,
    //     label: {
    //       show: true,
    //       position: "top",
    //     },
    //     areaStyle: {
    //       opacity: 0.8,
    //     },
    //     emphasis: {
    //       focus: "series",
    //     },
    //     data:
    //       hcChartData.length > 0 &&
    //       hcChartData.map((row: any, index: any) => row.p38),
    //   },
    //   {
    //     name: "P37",
    //     type: "line",
    //     silent: true,
    //     smooth: true,
    //     lineStyle: {
    //       width: 0,
    //     },
    //     showSymbol: false,
    //     label: {
    //       show: true,
    //       position: "top",
    //     },
    //     areaStyle: {
    //       opacity: 0.8,
    //     },
    //     emphasis: {
    //       focus: "series",
    //     },
    //     data:
    //       hcChartData.length > 0 &&
    //       hcChartData.map((row: any, index: any) => row.p37),
    //   },
    //   {
    //     name: "P36",
    //     type: "line",
    //     silent: true,
    //     smooth: true,
    //     lineStyle: {
    //       width: 0,
    //     },
    //     showSymbol: false,
    //     label: {
    //       show: true,
    //       position: "top",
    //     },
    //     areaStyle: {
    //       opacity: 0.8,
    //     },
    //     emphasis: {
    //       focus: "series",
    //     },
    //     data:
    //       hcChartData.length > 0 &&
    //       hcChartData.map((row: any, index: any) => row.p36),
    //   },
    //   {
    //     name: "P35",
    //     type: "line",
    //     silent: true,
    //     smooth: true,
    //     lineStyle: {
    //       width: 0,
    //     },
    //     showSymbol: false,
    //     label: {
    //       show: true,
    //       position: "top",
    //     },
    //     areaStyle: {
    //       opacity: 0.8,
    //     },
    //     emphasis: {
    //       focus: "series",
    //     },
    //     data:
    //       hcChartData.length > 0 &&
    //       hcChartData.map((row: any, index: any) => row.p35),
    //   },
    //   {
    //     name: "P34",
    //     type: "line",
    //     silent: true,
    //     smooth: true,
    //     lineStyle: {
    //       width: 0,
    //     },
    //     showSymbol: false,
    //     label: {
    //       show: true,
    //       position: "top",
    //     },
    //     areaStyle: {
    //       opacity: 0.8,
    //     },
    //     emphasis: {
    //       focus: "series",
    //     },
    //     data:
    //       hcChartData.length > 0 &&
    //       hcChartData.map((row: any, index: any) => row.p34),
    //   },
    //   {
    //     name: "P33",
    //     type: "line",
    //     silent: true,
    //     smooth: true,
    //     lineStyle: {
    //       width: 0,
    //     },
    //     showSymbol: false,
    //     label: {
    //       show: true,
    //       position: "top",
    //     },
    //     areaStyle: {
    //       opacity: 0.8,
    //     },
    //     emphasis: {
    //       focus: "series",
    //     },
    //     data:
    //       hcChartData.length > 0 &&
    //       hcChartData.map((row: any, index: any) => row.p33),
    //   },
    //   {
    //     name: "P32",
    //     type: "line",
    //     silent: true,
    //     smooth: true,
    //     lineStyle: {
    //       width: 0,
    //     },
    //     showSymbol: false,
    //     label: {
    //       show: true,
    //       position: "top",
    //     },
    //     areaStyle: {
    //       opacity: 0.8,
    //     },
    //     emphasis: {
    //       focus: "series",
    //     },
    //     data:
    //       hcChartData.length > 0 &&
    //       hcChartData.map((row: any, index: any) => row.p32),
    //   },
    //   {
    //     name: "P31",
    //     type: "line",
    //     silent: true,
    //     smooth: true,
    //     lineStyle: {
    //       width: 0,
    //     },
    //     showSymbol: false,
    //     label: {
    //       show: true,
    //       position: "top",
    //     },
    //     areaStyle: {
    //       opacity: 0.8,
    //     },
    //     emphasis: {
    //       focus: "series",
    //     },
    //     data:
    //       hcChartData.length > 0 &&
    //       hcChartData.map((row: any, index: any) => row.p31),
    //   },
    //   {
    //     name: "P30",
    //     type: "line",
    //     silent: true,
    //     smooth: true,
    //     lineStyle: {
    //       width: 0,
    //     },
    //     showSymbol: false,
    //     label: {
    //       show: true,
    //       position: "top",
    //     },
    //     areaStyle: {
    //       opacity: 0.8,
    //     },
    //     emphasis: {
    //       focus: "series",
    //     },
    //     data:
    //       hcChartData.length > 0 &&
    //       hcChartData.map((row: any, index: any) => row.p30),
    //   },
    //   {
    //     name: "P29",
    //     type: "line",
    //     silent: true,
    //     smooth: true,
    //     lineStyle: {
    //       width: 0,
    //     },
    //     showSymbol: false,
    //     label: {
    //       show: true,
    //       position: "top",
    //     },
    //     areaStyle: {
    //       opacity: 0.8,
    //     },
    //     emphasis: {
    //       focus: "series",
    //     },
    //     data:
    //       hcChartData.length > 0 &&
    //       hcChartData.map((row: any, index: any) => row.p29),
    //   },
    //   {
    //     name: "P28",
    //     type: "line",
    //     silent: true,
    //     smooth: true,
    //     lineStyle: {
    //       width: 0,
    //     },
    //     showSymbol: false,
    //     label: {
    //       show: true,
    //       position: "top",
    //     },
    //     areaStyle: {
    //       opacity: 0.8,
    //     },
    //     emphasis: {
    //       focus: "series",
    //     },
    //     data:
    //       hcChartData.length > 0 &&
    //       hcChartData.map((row: any, index: any) => row.p28),
    //   },
    //   {
    //     name: "P27",
    //     type: "line",
    //     silent: true,
    //     smooth: true,
    //     lineStyle: {
    //       width: 0,
    //     },
    //     showSymbol: false,
    //     label: {
    //       show: true,
    //       position: "top",
    //     },
    //     areaStyle: {
    //       opacity: 0.8,
    //     },
    //     emphasis: {
    //       focus: "series",
    //     },
    //     data:
    //       hcChartData.length > 0 &&
    //       hcChartData.map((row: any, index: any) => row.p27),
    //   },
    //   {
    //     name: "P26",
    //     type: "line",
    //     silent: true,
    //     smooth: true,
    //     lineStyle: {
    //       width: 0,
    //     },
    //     showSymbol: false,
    //     label: {
    //       show: true,
    //       position: "top",
    //     },
    //     areaStyle: {
    //       opacity: 0.8,
    //     },
    //     emphasis: {
    //       focus: "series",
    //     },
    //     data:
    //       hcChartData.length > 0 &&
    //       hcChartData.map((row: any, index: any) => row.p26),
    //   },

    //   // p11 to p25

    //   {
    //     name: "P25",
    //     type: "line",
    //     silent: true,
    //     smooth: true,
    //     lineStyle: {
    //       width: 0,
    //     },
    //     showSymbol: false,
    //     label: {
    //       show: true,
    //       position: "top",
    //     },
    //     areaStyle: {
    //       opacity: 0.8,
    //     },
    //     emphasis: {
    //       focus: "series",
    //     },
    //     data:
    //       hcChartData.length > 0 &&
    //       hcChartData.map((row: any, index: any) => row.p25),
    //   },
    //   {
    //     name: "P24",
    //     type: "line",
    //     silent: true,
    //     smooth: true,
    //     lineStyle: {
    //       width: 0,
    //     },
    //     showSymbol: false,
    //     label: {
    //       show: true,
    //       position: "top",
    //     },
    //     areaStyle: {
    //       opacity: 0.8,
    //     },
    //     emphasis: {
    //       focus: "series",
    //     },
    //     data:
    //       hcChartData.length > 0 &&
    //       hcChartData.map((row: any, index: any) => row.p24),
    //   },
    //   {
    //     name: "P23",
    //     type: "line",
    //     silent: true,
    //     smooth: true,
    //     lineStyle: {
    //       width: 0,
    //     },
    //     showSymbol: false,
    //     label: {
    //       show: true,
    //       position: "top",
    //     },
    //     areaStyle: {
    //       opacity: 0.8,
    //     },
    //     emphasis: {
    //       focus: "series",
    //     },
    //     data:
    //       hcChartData.length > 0 &&
    //       hcChartData.map((row: any, index: any) => row.p23),
    //   },
    //   {
    //     name: "P22",
    //     type: "line",
    //     silent: true,
    //     smooth: true,
    //     lineStyle: {
    //       width: 0,
    //     },
    //     showSymbol: false,
    //     label: {
    //       show: true,
    //       position: "top",
    //     },
    //     areaStyle: {
    //       opacity: 0.8,
    //     },
    //     emphasis: {
    //       focus: "series",
    //     },
    //     data:
    //       hcChartData.length > 0 &&
    //       hcChartData.map((row: any, index: any) => row.p22),
    //   },
    //   {
    //     name: "P21",
    //     type: "line",
    //     silent: true,
    //     smooth: true,
    //     lineStyle: {
    //       width: 0,
    //     },
    //     showSymbol: false,
    //     label: {
    //       show: true,
    //       position: "top",
    //     },
    //     areaStyle: {
    //       opacity: 0.8,
    //     },
    //     emphasis: {
    //       focus: "series",
    //     },
    //     data:
    //       hcChartData.length > 0 &&
    //       hcChartData.map((row: any, index: any) => row.p21),
    //   },
    //   {
    //     name: "P20",
    //     type: "line",
    //     silent: true,
    //     smooth: true,
    //     lineStyle: {
    //       width: 0,
    //     },
    //     showSymbol: false,
    //     label: {
    //       show: true,
    //       position: "top",
    //     },
    //     areaStyle: {
    //       opacity: 0.8,
    //     },
    //     emphasis: {
    //       focus: "series",
    //     },
    //     data:
    //       hcChartData.length > 0 &&
    //       hcChartData.map((row: any, index: any) => row.p20),
    //   },
    //   {
    //     name: "P19",
    //     type: "line",
    //     silent: true,
    //     smooth: true,
    //     lineStyle: {
    //       width: 0,
    //     },
    //     showSymbol: false,
    //     label: {
    //       show: true,
    //       position: "top",
    //     },
    //     areaStyle: {
    //       opacity: 0.8,
    //     },
    //     emphasis: {
    //       focus: "series",
    //     },
    //     data:
    //       hcChartData.length > 0 &&
    //       hcChartData.map((row: any, index: any) => row.p19),
    //   },
    //   {
    //     name: "P18",
    //     type: "line",
    //     silent: true,
    //     smooth: true,
    //     lineStyle: {
    //       width: 0,
    //     },
    //     showSymbol: false,
    //     label: {
    //       show: true,
    //       position: "top",
    //     },
    //     areaStyle: {
    //       opacity: 0.8,
    //     },
    //     emphasis: {
    //       focus: "series",
    //     },
    //     data:
    //       hcChartData.length > 0 &&
    //       hcChartData.map((row: any, index: any) => row.p18),
    //   },
    //   {
    //     name: "P17",
    //     type: "line",
    //     silent: true,
    //     smooth: true,
    //     lineStyle: {
    //       width: 0,
    //     },
    //     showSymbol: false,
    //     label: {
    //       show: true,
    //       position: "top",
    //     },
    //     areaStyle: {
    //       opacity: 0.8,
    //     },
    //     emphasis: {
    //       focus: "series",
    //     },
    //     data:
    //       hcChartData.length > 0 &&
    //       hcChartData.map((row: any, index: any) => row.p17),
    //   },
    //   {
    //     name: "P16",
    //     type: "line",
    //     silent: true,
    //     smooth: true,
    //     lineStyle: {
    //       width: 0,
    //     },
    //     showSymbol: false,
    //     label: {
    //       show: true,
    //       position: "top",
    //     },
    //     areaStyle: {
    //       opacity: 0.8,
    //     },
    //     emphasis: {
    //       focus: "series",
    //     },
    //     data:
    //       hcChartData.length > 0 &&
    //       hcChartData.map((row: any, index: any) => row.p16),
    //   },
    //   {
    //     name: "P15",
    //     type: "line",
    //     silent: true,
    //     smooth: true,
    //     lineStyle: {
    //       width: 0,
    //     },
    //     showSymbol: false,
    //     label: {
    //       show: true,
    //       position: "top",
    //     },
    //     areaStyle: {
    //       opacity: 0.8,
    //     },
    //     emphasis: {
    //       focus: "series",
    //     },
    //     data:
    //       hcChartData.length > 0 &&
    //       hcChartData.map((row: any, index: any) => row.p15),
    //   },
    //   {
    //     name: "P14",
    //     type: "line",
    //     silent: true,
    //     smooth: true,
    //     lineStyle: {
    //       width: 0,
    //     },
    //     showSymbol: false,
    //     label: {
    //       show: true,
    //       position: "top",
    //     },
    //     areaStyle: {
    //       opacity: 0.8,
    //     },
    //     emphasis: {
    //       focus: "series",
    //     },
    //     data:
    //       hcChartData.length > 0 &&
    //       hcChartData.map((row: any, index: any) => row.p14),
    //   },
    //   {
    //     name: "P13",
    //     type: "line",
    //     silent: true,
    //     smooth: true,
    //     lineStyle: {
    //       width: 0,
    //     },
    //     showSymbol: false,
    //     label: {
    //       show: true,
    //       position: "top",
    //     },
    //     areaStyle: {
    //       opacity: 0.8,
    //     },
    //     emphasis: {
    //       focus: "series",
    //     },
    //     data:
    //       hcChartData.length > 0 &&
    //       hcChartData.map((row: any, index: any) => row.p13),
    //   },
    //   {
    //     name: "P12",
    //     type: "line",
    //     silent: true,
    //     smooth: true,
    //     lineStyle: {
    //       width: 0,
    //     },
    //     showSymbol: false,
    //     label: {
    //       show: true,
    //       position: "top",
    //     },
    //     areaStyle: {
    //       opacity: 0.8,
    //     },
    //     emphasis: {
    //       focus: "series",
    //     },
    //     data:
    //       hcChartData.length > 0 &&
    //       hcChartData.map((row: any, index: any) => row.p12),
    //   },
    //   {
    //     name: "P11",
    //     type: "line",
    //     silent: true,
    //     smooth: true,
    //     lineStyle: {
    //       width: 0,
    //     },
    //     showSymbol: false,
    //     label: {
    //       show: true,
    //       position: "top",
    //     },
    //     areaStyle: {
    //       opacity: 0.8,
    //     },
    //     emphasis: {
    //       focus: "series",
    //     },
    //     data:
    //       hcChartData.length > 0 &&
    //       hcChartData.map((row: any, index: any) => row.p11),
    //   },

    //   //p4 to p10

    //   {
    //     name: "P10",
    //     type: "line",
    //     silent: true,
    //     smooth: true,
    //     lineStyle: {
    //       width: 0,
    //     },
    //     showSymbol: false,
    //     label: {
    //       show: true,
    //       position: "top",
    //     },
    //     areaStyle: {
    //       opacity: 0.8,
    //     },
    //     emphasis: {
    //       focus: "series",
    //     },
    //     data:
    //       hcChartData.length > 0 &&
    //       hcChartData.map((row: any, index: any) => row.p10),
    //   },
    //   {
    //     name: "P9",
    //     type: "line",
    //     silent: true,
    //     smooth: true,
    //     lineStyle: {
    //       width: 0,
    //     },
    //     showSymbol: false,
    //     label: {
    //       show: true,
    //       position: "top",
    //     },
    //     areaStyle: {
    //       opacity: 0.8,
    //     },
    //     emphasis: {
    //       focus: "series",
    //     },
    //     data:
    //       hcChartData.length > 0 &&
    //       hcChartData.map((row: any, index: any) => row.p9),
    //   },
    //   {
    //     name: "P8",
    //     type: "line",
    //     silent: true,
    //     smooth: true,
    //     lineStyle: {
    //       width: 0,
    //     },
    //     showSymbol: false,
    //     label: {
    //       show: true,
    //       position: "top",
    //     },
    //     areaStyle: {
    //       opacity: 0.8,
    //     },
    //     emphasis: {
    //       focus: "series",
    //     },
    //     data:
    //       hcChartData.length > 0 &&
    //       hcChartData.map((row: any, index: any) => row.p8),
    //   },
    //   {
    //     name: "P7",
    //     type: "line",
    //     silent: true,
    //     smooth: true,
    //     lineStyle: {
    //       width: 0,
    //     },
    //     showSymbol: false,
    //     label: {
    //       show: true,
    //       position: "top",
    //     },
    //     areaStyle: {
    //       opacity: 0.8,
    //     },
    //     emphasis: {
    //       focus: "series",
    //     },
    //     data:
    //       hcChartData.length > 0 &&
    //       hcChartData.map((row: any, index: any) => row.p7),
    //   },
    //   {
    //     name: "P6",
    //     type: "line",
    //     silent: true,
    //     smooth: true,
    //     lineStyle: {
    //       width: 0,
    //     },
    //     showSymbol: false,
    //     label: {
    //       show: true,
    //       position: "top",
    //     },
    //     areaStyle: {
    //       opacity: 0.8,
    //     },
    //     emphasis: {
    //       focus: "series",
    //     },
    //     data:
    //       hcChartData.length > 0 &&
    //       hcChartData.map((row: any, index: any) => row.p6),
    //   },
    //   {
    //     name: "P5",
    //     type: "line",
    //     silent: true,
    //     smooth: true,
    //     lineStyle: {
    //       width: 0,
    //     },
    //     showSymbol: false,
    //     label: {
    //       show: true,
    //       position: "top",
    //     },
    //     areaStyle: {
    //       opacity: 0.8,
    //     },
    //     emphasis: {
    //       focus: "series",
    //     },
    //     data:
    //       hcChartData.length > 0 &&
    //       hcChartData.map((row: any, index: any) => row.p5),
    //   },
    //   {
    //     name: "P4",
    //     type: "line",
    //     silent: true,
    //     smooth: true,
    //     lineStyle: {
    //       width: 0,
    //     },
    //     showSymbol: false,
    //     label: {
    //       show: true,
    //       position: "top",
    //     },
    //     areaStyle: {
    //       opacity: 0.8,
    //     },
    //     emphasis: {
    //       focus: "series",
    //     },
    //     data:
    //       hcChartData.length > 0 &&
    //       hcChartData.map((row: any, index: any) => row.p4),
    //   },

    //   // p1 to p3

    //   {
    //     name: "P3",
    //     type: "line",
    //     silent: true,
    //     smooth: true,
    //     lineStyle: {
    //       width: 0,
    //     },
    //     showSymbol: false,
    //     label: {
    //       show: true,
    //       position: "top",
    //     },
    //     areaStyle: {
    //       opacity: 0.8,
    //     },
    //     emphasis: {
    //       focus: "series",
    //     },
    //     data:
    //       hcChartData.length > 0 &&
    //       hcChartData.map((row: any, index: any) => row.p3),
    //   },
    //   {
    //     name: "P2",
    //     type: "line",
    //     silent: true,
    //     smooth: true,
    //     lineStyle: {
    //       width: 0,
    //     },
    //     showSymbol: false,
    //     label: {
    //       show: true,
    //       position: "top",
    //     },
    //     areaStyle: {
    //       opacity: 0.8,
    //     },
    //     emphasis: {
    //       focus: "series",
    //     },
    //     data:
    //       hcChartData.length > 0 &&
    //       hcChartData.map((row: any, index: any) => row.p2),
    //   },
    //   {
    //     name: "P1",
    //     type: "line",
    //     silent: true,
    //     smooth: true,
    //     lineStyle: {
    //       width: 0,
    //     },
    //     showSymbol: false,
    //     label: {
    //       show: true,
    //       position: "top",
    //     },
    //     areaStyle: {
    //       opacity: 0.8,
    //     },
    //     emphasis: {
    //       focus: "series",
    //     },
    //     data:
    //       hcChartData.length > 0 &&
    //       hcChartData.map((row: any, index: any) => row.p1),
    //   },

    //   {
    //     name: "user",
    //     type: "line",
    //     lineStyle: {
    //       width: 2,
    //     },
    //     label: {
    //       show: true,
    //       position: "top",
    //     },

    //     emphasis: {
    //       focus: "series",
    //     },
    //     data:
    //       hcChartData.length > 0 &&
    //       hcChartData.map((row: any, index: any) => row.weight),
    //   },
    // ],
    color: [

      // 1 to 3 - red
      "#FF0000", "#FF0000", "#FF0000",

      // 4 to 10 - orange
      "#FF8000", "#FF8000", "#FF8000", "#FF8000", "#FF8000", "#FF8000", "#FF8000",

      // 11 to 25 - lemon
      "#FFED00", "#FFED00", "#FFED00", "#FFED00", "#FFED00", "#FFED00", "#FFED00", "#FFED00", "#FFED00",
      "#FFED00", "#FFED00", "#FFED00", "#FFED00", "#FFED00", "#FFED00",

      // 26 to 50 - green
      "#16FF00", "#16FF00", "#16FF00", "#16FF00", "#16FF00", "#16FF00", "#16FF00", "#16FF00", "#16FF00",
      "#16FF00", "#16FF00", "#16FF00", "#16FF00", "#16FF00", "#16FF00", "#16FF00", "#16FF00", "#16FF00",
      "#16FF00", "#16FF00", "#16FF00", "#16FF00", "#16FF00", "#16FF00", "#16FF00",

      // 51 to 75 - lemon
      "#FFED00", "#FFED00", "#FFED00", "#FFED00", "#FFED00", "#FFED00", "#FFED00", "#FFED00", "#FFED00",
      "#FFED00", "#FFED00", "#FFED00", "#FFED00", "#FFED00", "#FFED00", "#FFED00", "#FFED00", "#FFED00",
      "#FFED00", "#FFED00", "#FFED00", "#FFED00", "#FFED00", "#FFED00", "#FFED00",

      // 76 to 90 - orange
      "#FF8000", "#FF8000", "#FF8000", "#FF8000", "#FF8000", "#FF8000", "#FF8000", "#FF8000", "#FF8000",
      "#FF8000", "#FF8000", "#FF8000", "#FF8000", "#FF8000", "#FF8000",

      // 91 to 99 - red
      "#FF0000", "#FF0000", "#FF0000", "#FF0000", "#FF0000", "#FF0000", "#FF0000", "#FF0000", "#FF0000",


      "#000000",
    ],
    // tooltip: {
    //   trigger: "axis",
    //   axisPointer: {
    //     type: "cross",
    //     label: {
    //       backgroundColor: "#6a7985",
    //     },
    //   },
    // },
    grid: {
      // left: "2%",
      // right: "2%",
      // bottom: "2%",
      // containLabel: true,
      left: "2%",
      containLabel: true,
      bottom: 20,
      top: 20,
      right: "2%"
    },
    xAxis: [
      {
        type: "category",
        boundaryGap: false,
        // name: "Days",
        // nameLocation: "middle",
        // nameGap: 15,
        data:
          hcChartData.length !== null &&
          hcChartData.length > 0 &&
          hcChartData.map((row: any, index: any) =>
            row.days === 0 ? row.months : row.days
          ),
      },
    ],
    yAxis: [
      {
        type: "value",
        interval: 2,
        // name: "HC in cm",
        // nameGap: 30,
        axisLine: {
          onZero: true,
        },
        nameTextStyle: {
          padding: [0, 0, 0, 20],
        },
        min: hcChartData.length !== null &&
          hcChartData.length > 0 ?
          Math.floor(hcChartData[0].p1) : 0
        ,
      },
    ],
    series: [
      // p1 to P3
      {
        name: "P1",
        type: "line",
        silent: true, stack: 'Total',
        smooth: true,
        lineStyle: {
          width: 0,
        },
        showSymbol: false,
        label: {
          show: true,
          position: "top",
        },
        areaStyle: {
          opacity: 0.8,
        },
        emphasis: {
          focus: "series",
        },
        data:
          hcChartData.length !== null &&
          hcChartData.length > 0 &&
          hcChartData.map((row: any, index: any) => row.p1),
      },
      {
        name: "P2",
        type: "line",
        silent: true, stack: 'Total',
        smooth: true,
        lineStyle: {
          width: 0,
        },
        showSymbol: false,
        label: {
          show: true,
          position: "top",
        },
        areaStyle: {
          opacity: 0.8,
        },
        emphasis: {
          focus: "series",
        },
        data:
          hcChartData.length !== null &&
          hcChartData.length > 0 &&
          hcChartData.map((row: any, index: any) => row.p2),
      },
      {
        name: "P3",
        type: "line",
        silent: true, stack: 'Total',
        smooth: true,
        lineStyle: {
          width: 0,
        },
        showSymbol: false,
        label: {
          show: true,
          position: "top",
        },
        areaStyle: {
          opacity: 0.8,
        },
        emphasis: {
          focus: "series",
        },
        data:
          hcChartData.length !== null &&
          hcChartData.length > 0 &&
          hcChartData.map((row: any, index: any) => row.p3),
      },

      // p4 to p10
      {
        name: "P4",
        type: "line",
        silent: true, stack: 'Total',
        smooth: true,
        lineStyle: {
          width: 0,
        },
        showSymbol: false,
        label: {
          show: true,
          position: "top",
        },
        areaStyle: {
          opacity: 0.8,
        },
        emphasis: {
          focus: "series",
        },
        data:
          hcChartData.length !== null &&
          hcChartData.length > 0 &&
          hcChartData.map((row: any, index: any) => row.p4),
      },
      {
        name: "P5",
        type: "line",
        silent: true, stack: 'Total',
        smooth: true,
        lineStyle: {
          width: 0,
        },
        showSymbol: false,
        label: {
          show: true,
          position: "top",
        },
        areaStyle: {
          opacity: 0.8,
        },
        emphasis: {
          focus: "series",
        },
        data:
          hcChartData.length !== null &&
          hcChartData.length > 0 &&
          hcChartData.map((row: any, index: any) => row.p5),
      },
      {
        name: "P6",
        type: "line",
        silent: true, stack: 'Total',
        smooth: true,
        lineStyle: {
          width: 0,
        },
        showSymbol: false,
        label: {
          show: true,
          position: "top",
        },
        areaStyle: {
          opacity: 0.8,
        },
        emphasis: {
          focus: "series",
        },
        data:
          hcChartData.length !== null &&
          hcChartData.length > 0 &&
          hcChartData.map((row: any, index: any) => row.p6),
      },
      {
        name: "P7",
        type: "line",
        silent: true, stack: 'Total',
        smooth: true,
        lineStyle: {
          width: 0,
        },
        showSymbol: false,
        label: {
          show: true,
          position: "top",
        },
        areaStyle: {
          opacity: 0.8,
        },
        emphasis: {
          focus: "series",
        },
        data:
          hcChartData.length !== null &&
          hcChartData.length > 0 &&
          hcChartData.map((row: any, index: any) => row.p7),
      },
      {
        name: "P8",
        type: "line",
        silent: true, stack: 'Total',
        smooth: true,
        lineStyle: {
          width: 0,
        },
        showSymbol: false,
        label: {
          show: true,
          position: "top",
        },
        areaStyle: {
          opacity: 0.8,
        },
        emphasis: {
          focus: "series",
        },
        data:
          hcChartData.length !== null &&
          hcChartData.length > 0 &&
          hcChartData.map((row: any, index: any) => row.p8),
      },
      {
        name: "P9",
        type: "line",
        silent: true, stack: 'Total',
        smooth: true,
        lineStyle: {
          width: 0,
        },
        showSymbol: false,
        label: {
          show: true,
          position: "top",
        },
        areaStyle: {
          opacity: 0.8,
        },
        emphasis: {
          focus: "series",
        },
        data:
          hcChartData.length !== null &&
          hcChartData.length > 0 &&
          hcChartData.map((row: any, index: any) => row.p9),
      },
      {
        name: "P10",
        type: "line",
        silent: true, stack: 'Total',
        smooth: true,
        lineStyle: {
          width: 0,
        },
        showSymbol: false,
        label: {
          show: true,
          position: "top",
        },
        areaStyle: {
          opacity: 0.8,
        },
        emphasis: {
          focus: "series",
        },
        data:
          hcChartData.length !== null &&
          hcChartData.length > 0 &&
          hcChartData.map((row: any, index: any) => row.p10),
      },

      // p11 to p25
      {
        name: "P11",
        type: "line",
        silent: true, stack: 'Total',
        smooth: true,
        lineStyle: {
          width: 0,
        },
        showSymbol: false,
        label: {
          show: true,
          position: "top",
        },
        areaStyle: {
          opacity: 0.8,
        },
        emphasis: {
          focus: "series",
        },
        data:
          hcChartData.length !== null &&
          hcChartData.length > 0 &&
          hcChartData.map((row: any, index: any) => row.p11),
      },
      {
        name: "P12",
        type: "line",
        silent: true, stack: 'Total',
        smooth: true,
        lineStyle: {
          width: 0,
        },
        showSymbol: false,
        label: {
          show: true,
          position: "top",
        },
        areaStyle: {
          opacity: 0.8,
        },
        emphasis: {
          focus: "series",
        },
        data:
          hcChartData.length !== null &&
          hcChartData.length > 0 &&
          hcChartData.map((row: any, index: any) => row.p12),
      },
      {
        name: "P13",
        type: "line",
        silent: true, stack: 'Total',
        smooth: true,
        lineStyle: {
          width: 0,
        },
        showSymbol: false,
        label: {
          show: true,
          position: "top",
        },
        areaStyle: {
          opacity: 0.8,
        },
        emphasis: {
          focus: "series",
        },
        data:
          hcChartData.length !== null &&
          hcChartData.length > 0 &&
          hcChartData.map((row: any, index: any) => row.p13),
      },
      {
        name: "P14",
        type: "line",
        silent: true, stack: 'Total',
        smooth: true,
        lineStyle: {
          width: 0,
        },
        showSymbol: false,
        label: {
          show: true,
          position: "top",
        },
        areaStyle: {
          opacity: 0.8,
        },
        emphasis: {
          focus: "series",
        },
        data:
          hcChartData.length !== null &&
          hcChartData.length > 0 &&
          hcChartData.map((row: any, index: any) => row.p14),
      },
      {
        name: "P15",
        type: "line",
        silent: true, stack: 'Total',
        smooth: true,
        lineStyle: {
          width: 0,
        },
        showSymbol: false,
        label: {
          show: true,
          position: "top",
        },
        areaStyle: {
          opacity: 0.8,
        },
        emphasis: {
          focus: "series",
        },
        data:
          hcChartData.length !== null &&
          hcChartData.length > 0 &&
          hcChartData.map((row: any, index: any) => row.p15),
      },
      {
        name: "P16",
        type: "line",
        silent: true, stack: 'Total',
        smooth: true,
        lineStyle: {
          width: 0,
        },
        showSymbol: false,
        label: {
          show: true,
          position: "top",
        },
        areaStyle: {
          opacity: 0.8,
        },
        emphasis: {
          focus: "series",
        },
        data:
          hcChartData.length !== null &&
          hcChartData.length > 0 &&
          hcChartData.map((row: any, index: any) => row.p16),
      },
      {
        name: "P17",
        type: "line",
        silent: true, stack: 'Total',
        smooth: true,
        lineStyle: {
          width: 0,
        },
        showSymbol: false,
        label: {
          show: true,
          position: "top",
        },
        areaStyle: {
          opacity: 0.8,
        },
        emphasis: {
          focus: "series",
        },
        data:
          hcChartData.length !== null &&
          hcChartData.length > 0 &&
          hcChartData.map((row: any, index: any) => row.p17),
      },
      {
        name: "P18",
        type: "line",
        silent: true, stack: 'Total',
        smooth: true,
        lineStyle: {
          width: 0,
        },
        showSymbol: false,
        label: {
          show: true,
          position: "top",
        },
        areaStyle: {
          opacity: 0.8,
        },
        emphasis: {
          focus: "series",
        },
        data:
          hcChartData.length !== null &&
          hcChartData.length > 0 &&
          hcChartData.map((row: any, index: any) => row.p18),
      },
      {
        name: "P19",
        type: "line",
        silent: true, stack: 'Total',
        smooth: true,
        lineStyle: {
          width: 0,
        },
        showSymbol: false,
        label: {
          show: true,
          position: "top",
        },
        areaStyle: {
          opacity: 0.8,
        },
        emphasis: {
          focus: "series",
        },
        data:
          hcChartData.length !== null &&
          hcChartData.length > 0 &&
          hcChartData.map((row: any, index: any) => row.p19),
      },
      {
        name: "P20",
        type: "line",
        silent: true, stack: 'Total',
        smooth: true,
        lineStyle: {
          width: 0,
        },
        showSymbol: false,
        label: {
          show: true,
          position: "top",
        },
        areaStyle: {
          opacity: 0.8,
        },
        emphasis: {
          focus: "series",
        },
        data:
          hcChartData.length !== null &&
          hcChartData.length > 0 &&
          hcChartData.map((row: any, index: any) => row.p20),
      },
      {
        name: "P21",
        type: "line",
        silent: true, stack: 'Total',
        smooth: true,
        lineStyle: {
          width: 0,
        },
        showSymbol: false,
        label: {
          show: true,
          position: "top",
        },
        areaStyle: {
          opacity: 0.8,
        },
        emphasis: {
          focus: "series",
        },
        data:
          hcChartData.length !== null &&
          hcChartData.length > 0 &&
          hcChartData.map((row: any, index: any) => row.p21),
      },
      {
        name: "P22",
        type: "line",
        silent: true, stack: 'Total',
        smooth: true,
        lineStyle: {
          width: 0,
        },
        showSymbol: false,
        label: {
          show: true,
          position: "top",
        },
        areaStyle: {
          opacity: 0.8,
        },
        emphasis: {
          focus: "series",
        },
        data:
          hcChartData.length !== null &&
          hcChartData.length > 0 &&
          hcChartData.map((row: any, index: any) => row.p22),
      },
      {
        name: "P23",
        type: "line",
        silent: true, stack: 'Total',
        smooth: true,
        lineStyle: {
          width: 0,
        },
        showSymbol: false,
        label: {
          show: true,
          position: "top",
        },
        areaStyle: {
          opacity: 0.8,
        },
        emphasis: {
          focus: "series",
        },
        data:
          hcChartData.length !== null &&
          hcChartData.length > 0 &&
          hcChartData.map((row: any, index: any) => row.p23),
      },
      {
        name: "P24",
        type: "line",
        silent: true, stack: 'Total',
        smooth: true,
        lineStyle: {
          width: 0,
        },
        showSymbol: false,
        label: {
          show: true,
          position: "top",
        },
        areaStyle: {
          opacity: 0.8,
        },
        emphasis: {
          focus: "series",
        },
        data:
          hcChartData.length !== null &&
          hcChartData.length > 0 &&
          hcChartData.map((row: any, index: any) => row.p24),
      },
      {
        name: "P25",
        type: "line",
        silent: true, stack: 'Total',
        smooth: true,
        lineStyle: {
          width: 0,
        },
        showSymbol: false,
        label: {
          show: true,
          position: "top",
        },
        areaStyle: {
          opacity: 0.8,
        },
        emphasis: {
          focus: "series",
        },
        data:
          hcChartData.length !== null &&
          hcChartData.length > 0 &&
          hcChartData.map((row: any, index: any) => row.p25),
      },

      // p26 to p50
      {
        name: "P26",
        type: "line",
        silent: true, stack: 'Total',
        smooth: true,
        lineStyle: {
          width: 0,
        },
        showSymbol: false,
        areaStyle: {
          opacity: 0.8,
        },
        emphasis: {
          focus: "series",
        },
        data:
          hcChartData.length !== null &&
          hcChartData.length > 0 &&
          hcChartData.map((row: any, index: any) => row.p26),
      },
      {
        name: "P27",
        type: "line",
        silent: true, stack: 'Total',
        smooth: true,
        lineStyle: {
          width: 0,
        },
        showSymbol: false,
        areaStyle: {
          opacity: 0.8,
        },
        emphasis: {
          focus: "series",
        },
        data:
          hcChartData.length !== null &&
          hcChartData.length > 0 &&
          hcChartData.map((row: any, index: any) => row.p27),
      },
      {
        name: "P28",
        type: "line",
        silent: true, stack: 'Total',
        smooth: true,
        lineStyle: {
          width: 0,
        },
        showSymbol: false,
        areaStyle: {
          opacity: 0.8,
        },
        emphasis: {
          focus: "series",
        },
        data:
          hcChartData.length !== null &&
          hcChartData.length > 0 &&
          hcChartData.map((row: any, index: any) => row.p28),
      },
      {
        name: "P29",
        type: "line",
        silent: true, stack: 'Total',
        smooth: true,
        lineStyle: {
          width: 0,
        },
        showSymbol: false,
        areaStyle: {
          opacity: 0.8,
        },
        emphasis: {
          focus: "series",
        },
        data:
          hcChartData.length !== null &&
          hcChartData.length > 0 &&
          hcChartData.map((row: any, index: any) => row.p29),
      },
      {
        name: "P30",
        type: "line",
        silent: true, stack: 'Total',
        smooth: true,
        lineStyle: {
          width: 0,
        },
        showSymbol: false,
        areaStyle: {
          opacity: 0.8,
        },
        emphasis: {
          focus: "series",
        },
        data:
          hcChartData.length !== null &&
          hcChartData.length > 0 &&
          hcChartData.map((row: any, index: any) => row.p30),
      },
      {
        name: "P31",
        type: "line",
        silent: true, stack: 'Total',
        smooth: true,
        lineStyle: {
          width: 0,
        },
        showSymbol: false,
        areaStyle: {
          opacity: 0.8,
        },
        emphasis: {
          focus: "series",
        },
        data:
          hcChartData.length !== null &&
          hcChartData.length > 0 &&
          hcChartData.map((row: any, index: any) => row.p31),
      },
      {
        name: "P32",
        type: "line",
        silent: true, stack: 'Total',
        smooth: true,
        lineStyle: {
          width: 0,
        },
        showSymbol: false,
        areaStyle: {
          opacity: 0.8,
        },
        emphasis: {
          focus: "series",
        },
        data:
          hcChartData.length !== null &&
          hcChartData.length > 0 &&
          hcChartData.map((row: any, index: any) => row.p32),
      },
      {
        name: "P33",
        type: "line",
        silent: true, stack: 'Total',
        smooth: true,
        lineStyle: {
          width: 0,
        },
        showSymbol: false,
        areaStyle: {
          opacity: 0.8,
        },
        emphasis: {
          focus: "series",
        },
        data:
          hcChartData.length !== null &&
          hcChartData.length > 0 &&
          hcChartData.map((row: any, index: any) => row.p33),
      },
      {
        name: "P34",
        type: "line",
        silent: true, stack: 'Total',
        smooth: true,
        lineStyle: {
          width: 0,
        },
        showSymbol: false,
        areaStyle: {
          opacity: 0.8,
        },
        emphasis: {
          focus: "series",
        },
        data:
          hcChartData.length !== null &&
          hcChartData.length > 0 &&
          hcChartData.map((row: any, index: any) => row.p34),
      },
      {
        name: "P35",
        type: "line",
        silent: true, stack: 'Total',
        smooth: true,
        lineStyle: {
          width: 0,
        },
        showSymbol: false,
        areaStyle: {
          opacity: 0.8,
        },
        emphasis: {
          focus: "series",
        },
        data:
          hcChartData.length !== null &&
          hcChartData.length > 0 &&
          hcChartData.map((row: any, index: any) => row.p35),
      },
      {
        name: "P36",
        type: "line",
        silent: true, stack: 'Total',
        smooth: true,
        lineStyle: {
          width: 0,
        },
        showSymbol: false,
        areaStyle: {
          opacity: 0.8,
        },
        emphasis: {
          focus: "series",
        },
        data:
          hcChartData.length !== null &&
          hcChartData.length > 0 &&
          hcChartData.map((row: any, index: any) => row.p36),
      },
      {
        name: "P37",
        type: "line",
        silent: true, stack: 'Total',
        smooth: true,
        lineStyle: {
          width: 0,
        },
        showSymbol: false,
        areaStyle: {
          opacity: 0.8,
        },
        emphasis: {
          focus: "series",
        },
        data:
          hcChartData.length !== null &&
          hcChartData.length > 0 &&
          hcChartData.map((row: any, index: any) => row.p37),
      },
      {
        name: "P38",
        type: "line",
        silent: true, stack: 'Total',
        smooth: true,
        lineStyle: {
          width: 0,
        },
        showSymbol: false,
        areaStyle: {
          opacity: 0.8,
        },
        emphasis: {
          focus: "series",
        },
        data:
          hcChartData.length !== null &&
          hcChartData.length > 0 &&
          hcChartData.map((row: any, index: any) => row.p38),
      },
      {
        name: "P39",
        type: "line",
        silent: true, stack: 'Total',
        smooth: true,
        lineStyle: {
          width: 0,
        },
        showSymbol: false,
        areaStyle: {
          opacity: 0.8,
        },
        emphasis: {
          focus: "series",
        },
        data:
          hcChartData.length !== null &&
          hcChartData.length > 0 &&
          hcChartData.map((row: any, index: any) => row.p39),
      },
      {
        name: "P40",
        type: "line",
        silent: true, stack: 'Total',
        smooth: true,
        lineStyle: {
          width: 0,
        },
        showSymbol: false,
        areaStyle: {
          opacity: 0.8,
        },
        emphasis: {
          focus: "series",
        },
        data:
          hcChartData.length !== null &&
          hcChartData.length > 0 &&
          hcChartData.map((row: any, index: any) => row.p40),
      },
      {
        name: "P41",
        type: "line",
        silent: true, stack: 'Total',
        smooth: true,
        lineStyle: {
          width: 0,
        },
        showSymbol: false,
        areaStyle: {
          opacity: 0.8,
        },
        emphasis: {
          focus: "series",
        },
        data:
          hcChartData.length !== null &&
          hcChartData.length > 0 &&
          hcChartData.map((row: any, index: any) => row.p41),
      },
      {
        name: "P42",
        type: "line",
        silent: true, stack: 'Total',
        smooth: true,
        lineStyle: {
          width: 0,
        },
        showSymbol: false,
        areaStyle: {
          opacity: 0.8,
        },
        emphasis: {
          focus: "series",
        },
        data:
          hcChartData.length !== null &&
          hcChartData.length > 0 &&
          hcChartData.map((row: any, index: any) => row.p42),
      },
      {
        name: "P43",
        type: "line",
        silent: true, stack: 'Total',
        smooth: true,
        lineStyle: {
          width: 0,
        },
        showSymbol: false,
        areaStyle: {
          opacity: 0.8,
        },
        emphasis: {
          focus: "series",
        },
        data:
          hcChartData.length !== null &&
          hcChartData.length > 0 &&
          hcChartData.map((row: any, index: any) => row.p43),
      },
      {
        name: "P44",
        type: "line",
        silent: true, stack: 'Total',
        smooth: true,
        lineStyle: {
          width: 0,
        },
        showSymbol: false,
        areaStyle: {
          opacity: 0.8,
        },
        emphasis: {
          focus: "series",
        },
        data:
          hcChartData.length !== null &&
          hcChartData.length > 0 &&
          hcChartData.map((row: any, index: any) => row.p44),
      },
      {
        name: "P45",
        type: "line",
        silent: true, stack: 'Total',
        smooth: true,
        lineStyle: {
          width: 0,
        },
        showSymbol: false,
        areaStyle: {
          opacity: 0.8,
        },
        emphasis: {
          focus: "series",
        },
        data:
          hcChartData.length !== null &&
          hcChartData.length > 0 &&
          hcChartData.map((row: any, index: any) => row.p45),
      },
      {
        name: "P46",
        type: "line",
        silent: true, stack: 'Total',
        smooth: true,
        lineStyle: {
          width: 0,
        },
        showSymbol: false,
        areaStyle: {
          opacity: 0.8,
        },
        emphasis: {
          focus: "series",
        },
        data:
          hcChartData.length !== null &&
          hcChartData.length > 0 &&
          hcChartData.map((row: any, index: any) => row.p46),
      },
      {
        name: "P47",
        type: "line",
        silent: true, stack: 'Total',
        smooth: true,
        lineStyle: {
          width: 0,
        },
        showSymbol: false,
        areaStyle: {
          opacity: 0.8,
        },
        emphasis: {
          focus: "series",
        },
        data:
          hcChartData.length !== null &&
          hcChartData.length > 0 &&
          hcChartData.map((row: any, index: any) => row.p47),
      },
      {
        name: "P48",
        type: "line",
        silent: true, stack: 'Total',
        smooth: true,
        lineStyle: {
          width: 0,
        },
        showSymbol: false,
        areaStyle: {
          opacity: 0.8,
        },
        emphasis: {
          focus: "series",
        },
        data:
          hcChartData.length !== null &&
          hcChartData.length > 0 &&
          hcChartData.map((row: any, index: any) => row.p48),
      },
      {
        name: "P49",
        type: "line",
        silent: true, stack: 'Total',
        smooth: true,
        lineStyle: {
          width: 0,
        },
        showSymbol: false,
        areaStyle: {
          opacity: 0.8,
        },
        emphasis: {
          focus: "series",
        },
        data:
          hcChartData.length !== null &&
          hcChartData.length > 0 &&
          hcChartData.map((row: any, index: any) => row.p49),
      },
      {
        name: "P50",
        type: "line",
        silent: true, stack: 'Total',
        smooth: true,
        lineStyle: {
          width: 0,
        },
        showSymbol: false,
        areaStyle: {
          opacity: 0.8,
        },
        emphasis: {
          focus: "series",
        },
        data:
          hcChartData.length !== null &&
          hcChartData.length > 0 &&
          hcChartData.map((row: any, index: any) => row.p50),
      },


      // p51 to p75
      {
        name: "P51",
        type: "line",
        silent: true, stack: 'Total',
        smooth: true,
        lineStyle: {
          width: 0,
        },
        showSymbol: false,
        areaStyle: {
          opacity: 0.8,
        },
        emphasis: {
          focus: "series",
        },
        data:
          hcChartData.length !== null &&
          hcChartData.length > 0 &&
          hcChartData.map((row: any, index: any) => row.p51),
      },
      {
        name: "P52",
        type: "line",
        silent: true, stack: 'Total',
        smooth: true,
        lineStyle: {
          width: 0,
        },
        showSymbol: false,
        areaStyle: {
          opacity: 0.8,
        },
        emphasis: {
          focus: "series",
        },
        data:
          hcChartData.length !== null &&
          hcChartData.length > 0 &&
          hcChartData.map((row: any, index: any) => row.p52),
      },
      {
        name: "P53",
        type: "line",
        silent: true, stack: 'Total',
        smooth: true,
        lineStyle: {
          width: 0,
        },
        showSymbol: false,
        areaStyle: {
          opacity: 0.8,
        },
        emphasis: {
          focus: "series",
        },
        data:
          hcChartData.length !== null &&
          hcChartData.length > 0 &&
          hcChartData.map((row: any, index: any) => row.p53),
      },
      {
        name: "P54",
        type: "line",
        silent: true, stack: 'Total',
        smooth: true,
        lineStyle: {
          width: 0,
        },
        showSymbol: false,
        areaStyle: {
          opacity: 0.8,
        },
        emphasis: {
          focus: "series",
        },
        data:
          hcChartData.length !== null &&
          hcChartData.length > 0 &&
          hcChartData.map((row: any, index: any) => row.p54),
      },
      {
        name: "P55",
        type: "line",
        silent: true, stack: 'Total',
        smooth: true,
        lineStyle: {
          width: 0,
        },
        showSymbol: false,
        areaStyle: {
          opacity: 0.8,
        },
        emphasis: {
          focus: "series",
        },
        data:
          hcChartData.length !== null &&
          hcChartData.length > 0 &&
          hcChartData.map((row: any, index: any) => row.p55),
      },
      {
        name: "P56",
        type: "line",
        silent: true, stack: 'Total',
        smooth: true,
        lineStyle: {
          width: 0,
        },
        showSymbol: false,
        areaStyle: {
          opacity: 0.8,
        },
        emphasis: {
          focus: "series",
        },
        data:
          hcChartData.length !== null &&
          hcChartData.length > 0 &&
          hcChartData.map((row: any, index: any) => row.p56),
      },
      {
        name: "P57",
        type: "line",
        silent: true, stack: 'Total',
        smooth: true,
        lineStyle: {
          width: 0,
        },
        showSymbol: false,
        areaStyle: {
          opacity: 0.8,
        },
        emphasis: {
          focus: "series",
        },
        data:
          hcChartData.length !== null &&
          hcChartData.length > 0 &&
          hcChartData.map((row: any, index: any) => row.p57),
      },
      {
        name: "P58",
        type: "line",
        silent: true, stack: 'Total',
        smooth: true,
        lineStyle: {
          width: 0,
        },
        showSymbol: false,
        areaStyle: {
          opacity: 0.8,
        },
        emphasis: {
          focus: "series",
        },
        data:
          hcChartData.length !== null &&
          hcChartData.length > 0 &&
          hcChartData.map((row: any, index: any) => row.p58),
      },
      {
        name: "P59",
        type: "line",
        silent: true, stack: 'Total',
        smooth: true,
        lineStyle: {
          width: 0,
        },
        showSymbol: false,
        areaStyle: {
          opacity: 0.8,
        },
        emphasis: {
          focus: "series",
        },
        data:
          hcChartData.length !== null &&
          hcChartData.length > 0 &&
          hcChartData.map((row: any, index: any) => row.p59),
      },
      {
        name: "P60",
        type: "line",
        silent: true, stack: 'Total',
        smooth: true,
        lineStyle: {
          width: 0,
        },
        showSymbol: false,
        areaStyle: {
          opacity: 0.8,
        },
        emphasis: {
          focus: "series",
        },
        data:
          hcChartData.length !== null &&
          hcChartData.length > 0 &&
          hcChartData.map((row: any, index: any) => row.p60),
      },
      {
        name: "P61",
        type: "line",
        silent: true, stack: 'Total',
        smooth: true,
        lineStyle: {
          width: 0,
        },
        showSymbol: false,
        areaStyle: {
          opacity: 0.8,
        },
        emphasis: {
          focus: "series",
        },
        data:
          hcChartData.length !== null &&
          hcChartData.length > 0 &&
          hcChartData.map((row: any, index: any) => row.p61),
      },
      {
        name: "P62",
        type: "line",
        silent: true, stack: 'Total',
        smooth: true,
        lineStyle: {
          width: 0,
        },
        showSymbol: false,
        areaStyle: {
          opacity: 0.8,
        },
        emphasis: {
          focus: "series",
        },
        data:
          hcChartData.length !== null &&
          hcChartData.length > 0 &&
          hcChartData.map((row: any, index: any) => row.p62),
      },
      {
        name: "P63",
        type: "line",
        silent: true, stack: 'Total',
        smooth: true,
        lineStyle: {
          width: 0,
        },
        showSymbol: false,
        areaStyle: {
          opacity: 0.8,
        },
        emphasis: {
          focus: "series",
        },
        data:
          hcChartData.length !== null &&
          hcChartData.length > 0 &&
          hcChartData.map((row: any, index: any) => row.p63),
      },
      {
        name: "P64",
        type: "line",
        silent: true, stack: 'Total',
        smooth: true,
        lineStyle: {
          width: 0,
        },
        showSymbol: false,
        areaStyle: {
          opacity: 0.8,
        },
        emphasis: {
          focus: "series",
        },
        data:
          hcChartData.length !== null &&
          hcChartData.length > 0 &&
          hcChartData.map((row: any, index: any) => row.p64),
      },
      {
        name: "P65",
        type: "line",
        silent: true, stack: 'Total',
        smooth: true,
        lineStyle: {
          width: 0,
        },
        showSymbol: false,
        areaStyle: {
          opacity: 0.8,
        },
        emphasis: {
          focus: "series",
        },
        data:
          hcChartData.length !== null &&
          hcChartData.length > 0 &&
          hcChartData.map((row: any, index: any) => row.p65),
      },
      {
        name: "P66",
        type: "line",
        silent: true, stack: 'Total',
        smooth: true,
        lineStyle: {
          width: 0,
        },
        showSymbol: false,
        areaStyle: {
          opacity: 0.8,
        },
        emphasis: {
          focus: "series",
        },
        data:
          hcChartData.length !== null &&
          hcChartData.length > 0 &&
          hcChartData.map((row: any, index: any) => row.p66),
      },
      {
        name: "P67",
        type: "line",
        silent: true, stack: 'Total',
        smooth: true,
        lineStyle: {
          width: 0,
        },
        showSymbol: false,
        areaStyle: {
          opacity: 0.8,
        },
        emphasis: {
          focus: "series",
        },
        data:
          hcChartData.length !== null &&
          hcChartData.length > 0 &&
          hcChartData.map((row: any, index: any) => row.p67),
      },
      {
        name: "P68",
        type: "line",
        silent: true, stack: 'Total',
        smooth: true,
        lineStyle: {
          width: 0,
        },
        showSymbol: false,
        areaStyle: {
          opacity: 0.8,
        },
        emphasis: {
          focus: "series",
        },
        data:
          hcChartData.length !== null &&
          hcChartData.length > 0 &&
          hcChartData.map((row: any, index: any) => row.p68),
      },
      {
        name: "P69",
        type: "line",
        silent: true, stack: 'Total',
        smooth: true,
        lineStyle: {
          width: 0,
        },
        showSymbol: false,
        areaStyle: {
          opacity: 0.8,
        },
        emphasis: {
          focus: "series",
        },
        data:
          hcChartData.length !== null &&
          hcChartData.length > 0 &&
          hcChartData.map((row: any, index: any) => row.p69),
      },
      {
        name: "P70",
        type: "line",
        silent: true, stack: 'Total',
        smooth: true,
        lineStyle: {
          width: 0,
        },
        showSymbol: false,
        areaStyle: {
          opacity: 0.8,
        },
        emphasis: {
          focus: "series",
        },
        data:
          hcChartData.length !== null &&
          hcChartData.length > 0 &&
          hcChartData.map((row: any, index: any) => row.p70),
      },
      {
        name: "P71",
        type: "line",
        silent: true, stack: 'Total',
        smooth: true,
        lineStyle: {
          width: 0,
        },
        showSymbol: false,
        areaStyle: {
          opacity: 0.8,
        },
        emphasis: {
          focus: "series",
        },
        data:
          hcChartData.length !== null &&
          hcChartData.length > 0 &&
          hcChartData.map((row: any, index: any) => row.p71),
      },
      {
        name: "P72",
        type: "line",
        silent: true, stack: 'Total',
        smooth: true,
        lineStyle: {
          width: 0,
        },
        showSymbol: false,
        areaStyle: {
          opacity: 0.8,
        },
        emphasis: {
          focus: "series",
        },
        data:
          hcChartData.length !== null &&
          hcChartData.length > 0 &&
          hcChartData.map((row: any, index: any) => row.p72),
      },
      {
        name: "P73",
        type: "line",
        silent: true, stack: 'Total',
        smooth: true,
        lineStyle: {
          width: 0,
        },
        showSymbol: false,
        areaStyle: {
          opacity: 0.8,
        },
        emphasis: {
          focus: "series",
        },
        data:
          hcChartData.length !== null &&
          hcChartData.length > 0 &&
          hcChartData.map((row: any, index: any) => row.p73),
      },
      {
        name: "P74",
        type: "line",
        silent: true, stack: 'Total',
        smooth: true,
        lineStyle: {
          width: 0,
        },
        showSymbol: false,
        areaStyle: {
          opacity: 0.8,
        },
        emphasis: {
          focus: "series",
        },
        data:
          hcChartData.length !== null &&
          hcChartData.length > 0 &&
          hcChartData.map((row: any, index: any) => row.p74),
      },
      {
        name: "P75",
        type: "line",
        silent: true, stack: 'Total',
        smooth: true,
        lineStyle: {
          width: 0,
        },
        showSymbol: false,
        areaStyle: {
          opacity: 0.8,
        },
        emphasis: {
          focus: "series",
        },
        data:
          hcChartData.length !== null &&
          hcChartData.length > 0 &&
          hcChartData.map((row: any, index: any) => row.p75),
      },

      // p76 to 90

      {
        name: "P76",
        type: "line",
        silent: true, stack: 'Total',
        smooth: true,
        lineStyle: {
          width: 0,
        },
        showSymbol: false,
        areaStyle: {
          opacity: 0.8,
        },
        emphasis: {
          focus: "series",
        },
        data:
          hcChartData.length > 0 &&
          hcChartData.map((row: any, index: any) => row.p76),
      },
      {
        name: "P77",
        type: "line",
        silent: true, stack: 'Total',
        smooth: true,
        lineStyle: {
          width: 0,
        },
        showSymbol: false,
        areaStyle: {
          opacity: 0.8,
        },
        emphasis: {
          focus: "series",
        },
        data:
          hcChartData.length > 0 &&
          hcChartData.map((row: any, index: any) => row.p77),
      },
      {
        name: "P78",
        type: "line",
        silent: true, stack: 'Total',
        smooth: true,
        lineStyle: {
          width: 0,
        },
        showSymbol: false,
        areaStyle: {
          opacity: 0.8,
        },
        emphasis: {
          focus: "series",
        },
        data:
          hcChartData.length > 0 &&
          hcChartData.map((row: any, index: any) => row.p78),
      },
      {
        name: "P79",
        type: "line",
        silent: true, stack: 'Total',
        smooth: true,
        lineStyle: {
          width: 0,
        },
        showSymbol: false,
        areaStyle: {
          opacity: 0.8,
        },
        emphasis: {
          focus: "series",
        },
        data:
          hcChartData.length > 0 &&
          hcChartData.map((row: any, index: any) => row.p79),
      },
      {
        name: "P80",
        type: "line",
        silent: true, stack: 'Total',
        smooth: true,
        lineStyle: {
          width: 0,
        },
        showSymbol: false,
        areaStyle: {
          opacity: 0.8,
        },
        emphasis: {
          focus: "series",
        },
        data:
          hcChartData.length > 0 &&
          hcChartData.map((row: any, index: any) => row.p80),
      },
      {
        name: "P81",
        type: "line",
        silent: true, stack: 'Total',
        smooth: true,
        lineStyle: {
          width: 0,
        },
        showSymbol: false,
        areaStyle: {
          opacity: 0.8,
        },
        emphasis: {
          focus: "series",
        },
        data:
          hcChartData.length > 0 &&
          hcChartData.map((row: any, index: any) => row.p81),
      },
      {
        name: "P82",
        type: "line",
        silent: true, stack: 'Total',
        smooth: true,
        lineStyle: {
          width: 0,
        },
        showSymbol: false,
        areaStyle: {
          opacity: 0.8,
        },
        emphasis: {
          focus: "series",
        },
        data:
          hcChartData.length > 0 &&
          hcChartData.map((row: any, index: any) => row.p82),
      },
      {
        name: "P83",
        type: "line",
        silent: true, stack: 'Total',
        smooth: true,
        lineStyle: {
          width: 0,
        },
        showSymbol: false,
        areaStyle: {
          opacity: 0.8,
        },
        emphasis: {
          focus: "series",
        },
        data:
          hcChartData.length > 0 &&
          hcChartData.map((row: any, index: any) => row.p83),
      },
      {
        name: "P84",
        type: "line",
        silent: true, stack: 'Total',
        smooth: true,
        lineStyle: {
          width: 0,
        },
        showSymbol: false,
        areaStyle: {
          opacity: 0.8,
        },
        emphasis: {
          focus: "series",
        },
        data:
          hcChartData.length > 0 &&
          hcChartData.map((row: any, index: any) => row.p84),
      },
      {
        name: "P85",
        type: "line",
        silent: true, stack: 'Total',
        smooth: true,
        lineStyle: {
          width: 0,
        },
        showSymbol: false,
        areaStyle: {
          opacity: 0.8,
        },
        emphasis: {
          focus: "series",
        },
        data:
          hcChartData.length > 0 &&
          hcChartData.map((row: any, index: any) => row.p85),
      },
      {
        name: "P86",
        type: "line",
        silent: true, stack: 'Total',
        smooth: true,
        lineStyle: {
          width: 0,
        },
        showSymbol: false,
        areaStyle: {
          opacity: 0.8,
        },
        emphasis: {
          focus: "series",
        },
        data:
          hcChartData.length > 0 &&
          hcChartData.map((row: any, index: any) => row.p86),
      },
      {
        name: "P87",
        type: "line",
        silent: true, stack: 'Total',
        smooth: true,
        lineStyle: {
          width: 0,
        },
        showSymbol: false,
        areaStyle: {
          opacity: 0.8,
        },
        emphasis: {
          focus: "series",
        },
        data:
          hcChartData.length > 0 &&
          hcChartData.map((row: any, index: any) => row.p87),
      },
      {
        name: "P88",
        type: "line",
        silent: true, stack: 'Total',
        smooth: true,
        lineStyle: {
          width: 0,
        },
        showSymbol: false,
        areaStyle: {
          opacity: 0.8,
        },
        emphasis: {
          focus: "series",
        },
        data:
          hcChartData.length > 0 &&
          hcChartData.map((row: any, index: any) => row.p88),
      },
      {
        name: "P89",
        type: "line",
        silent: true, stack: 'Total',
        smooth: true,
        lineStyle: {
          width: 0,
        },
        showSymbol: false,
        areaStyle: {
          opacity: 0.8,
        },
        emphasis: {
          focus: "series",
        },
        data:
          hcChartData.length > 0 &&
          hcChartData.map((row: any, index: any) => row.p89),
      },
      {
        name: "P90",
        type: "line",
        silent: true, stack: 'Total',
        smooth: true,
        lineStyle: {
          width: 0,
        },
        showSymbol: false,
        areaStyle: {
          opacity: 0.8,
        },
        emphasis: {
          focus: "series",
        },
        data:
          hcChartData.length > 0 &&
          hcChartData.map((row: any, index: any) => row.p90),
      },

      // p91 to p97      

      {
        name: "P91",
        type: "line",
        silent: true, stack: 'Total',
        smooth: true,
        lineStyle: {
          width: 0,
        },
        showSymbol: false,
        areaStyle: {
          opacity: 0.8,
        },
        emphasis: {
          focus: "series",
        },
        data:
          hcChartData.length !== null &&
          hcChartData.length > 0 &&
          hcChartData.map((row: any, index: any) => row.p91),
      },
      {
        name: "P92",
        type: "line",
        silent: true, stack: 'Total',
        smooth: true,
        lineStyle: {
          width: 0,
        },
        showSymbol: false,
        areaStyle: {
          opacity: 0.8,
        },
        emphasis: {
          focus: "series",
        },
        data:
          hcChartData.length !== null &&
          hcChartData.length > 0 &&
          hcChartData.map((row: any, index: any) => row.p92),
      },
      {
        name: "P93",
        type: "line",
        silent: true, stack: 'Total',
        smooth: true,
        lineStyle: {
          width: 0,
        },
        showSymbol: false,
        areaStyle: {
          opacity: 0.8,
        },
        emphasis: {
          focus: "series",
        },
        data:
          hcChartData.length !== null &&
          hcChartData.length > 0 &&
          hcChartData.map((row: any, index: any) => row.p93),
      },
      {
        name: "P94",
        type: "line",
        silent: true, stack: 'Total',
        smooth: true,
        lineStyle: {
          width: 0,
        },
        showSymbol: false,
        areaStyle: {
          opacity: 0.8,
        },
        emphasis: {
          focus: "series",
        },
        data:
          hcChartData.length !== null &&
          hcChartData.length > 0 &&
          hcChartData.map((row: any, index: any) => row.p94),
      },
      {
        name: "P95",
        type: "line",
        silent: true, stack: 'Total',
        smooth: true,
        lineStyle: {
          width: 0,
        },
        showSymbol: false,
        areaStyle: {
          opacity: 0.8,
        },
        emphasis: {
          focus: "series",
        },
        data:
          hcChartData.length !== null &&
          hcChartData.length > 0 &&
          hcChartData.map((row: any, index: any) => row.p95),
      },
      {
        name: "P96",
        type: "line",
        silent: true, stack: 'Total',
        smooth: true,
        lineStyle: {
          width: 0,
        },
        showSymbol: false,
        areaStyle: {
          opacity: 0.8,
        },
        emphasis: {
          focus: "series",
        },
        data:
          hcChartData.length !== null &&
          hcChartData.length > 0 &&
          hcChartData.map((row: any, index: any) => row.p96),
      },
      {
        name: "P97",
        type: "line",
        silent: true, stack: 'Total',
        smooth: true,
        lineStyle: {
          width: 0,
        },
        showSymbol: false,
        areaStyle: {
          opacity: 0.8,
        },
        emphasis: {
          focus: "series",
        },
        data:
          hcChartData.length !== null &&
          hcChartData.length > 0 &&
          hcChartData.map((row: any, index: any) => row.p97),
      },

      // p98 to p99

      {
        name: "P98",
        type: "line",
        silent: true, stack: 'Total',
        smooth: true,
        lineStyle: {
          width: 0,
        },
        showSymbol: false,
        areaStyle: {
          opacity: 0.8,
        },
        emphasis: {
          focus: "series",
        },
        data:
          hcChartData.length !== null &&
          hcChartData.length > 0 &&
          hcChartData.map((row: any, index: any) => row.p98),
      },
      {
        name: "P99",
        type: "line",
        silent: true, stack: 'Total',
        smooth: true,
        lineStyle: {
          width: 0,
        },
        showSymbol: false,
        areaStyle: {
          opacity: 0.8,
        },
        emphasis: {
          focus: "series",
        },
        data:
          hcChartData.length !== null &&
          hcChartData.length > 0 &&
          hcChartData.map((row: any, index: any) => row.p99),
      },

      {
        name: "user",
        type: "line",
        connectNulls: true,
        lineStyle: {
          width: 2,
        },
        label: {
          show: true,
          position: "top",
        },
        emphasis: {
          focus: "series",
        },
        data:
          hcChartData.length !== null &&
          hcChartData.length > 0 &&
          hcChartData.map((row: any, index: any) => row.hc),
      },
    ],
  };

  const valildationSchema = Yup.object().shape({
    hc: Yup.number().required("Head Circumference is required."),
  });

  const height = 175;

  const [{ y }, api] = useSpring(() => ({ y: height }));

  const openHCSheet = () => {
    setIsBottomSheetOpen(true);
    // when cancel is true, it means that the user passed the upwards threshold
    // so we change the spring config to create a nice wobbly effect
    api.start({
      y: 0,
      immediate: false,
      // config: canceled ? config.wobbly : config.stiff
    });
  };

  const close = (velocity = 0) => {
    setIsBottomSheetOpen(false);
    api.start({
      y: height,
      immediate: false,
      config: { ...config.stiff, velocity },
    });
  };

  const bind = useDrag(
    ({
      last,
      velocity: [, vy],
      direction: [, dy],
      offset: [, oy],
      cancel,
      canceled,
    }) => {
      // if the user drags up passed a threshold, then we cancel
      // the drag so that the sheet resets to its open position
      if (oy < -70) cancel();

      // when the user releases the sheet, we check whether it passed
      // the threshold for it to close, or if we reset it to its open positino
      if (last) {
        oy > height * 0.5 || (vy > 0.5 && dy > 0)
          ? close(vy)
          : openHCSheet({ canceled });
      }
      // when the user keeps dragging, we just move the sheet according to
      // the cursor position
      else api.start({ y: oy, immediate: true });
    },
    {
      from: () => [0, y.get()],
      filterTaps: true,
      bounds: { top: 0 },
      rubberband: true,
    }
  );

  const display = y.to((py) => (py < height ? "block" : "none"));

  async function fetchHCData() {
    setLoading(true);
    try {
      let params = new URLSearchParams();
      params.append("familyId", userContext.loginDetail.familyId.toString());
      params.append(
        "familyMemberId",
        userContext.memberDetail.familyMemberId.toString()
      );
      const result: any = await GetMemberHCDetailList(params);
      let memberHCData = result.data.data;
      if (memberHCData.length > 0) {
        setMemberHCDetailData(memberHCData);
      }
    } catch (error) {
      setLoading(false);
      if (error) {
        setAlertMessage({
          isSuccess: true,
          message: error as string,
        });
      }
    }
    setLoading(false);
  }

  async function SubmitHC(value: any) {
    setSubmitLoading(true);
    const days = ageInDays(userContext.memberDetail.birthDate);

    try {
      let hcInitData = value;
      let SaveUpdateHCData = {
        hcDetailId: hcInitData.hcDetailId,
        familyId: userContext.loginDetail.familyId,
        familyMemberId: userContext.memberDetail.familyMemberId,
        userData: hcInitData.hc,
        age: days,
        dataManagedBy: userContext.loginDetail.userId,
      };
      const resultParentSave: any = await CreateHCDetail(SaveUpdateHCData);
      setSubmitLoading(false);
      setIsBottomSheetOpen(false);
      updateUserContext({
        ...userContext,
        isAlert: true,
        alertMessage: "Head circumference Added",
        alertType: AlertType.Success,
      });
      fetchHCData();
    } catch (e: any) {
      updateUserContext({
        ...userContext,
        isAlert: true,
        alertMessage: e.message,
        alertType: AlertType.Error,
      });
    }
  }

  async function fetchData() {
    setLoading(true);
    let days = ageInDays(userContext.memberDetail.birthDate);
    let months = 0;
    let country = 'WHO'
    try {
      let params = new URLSearchParams();
      params.append("days", days.toString());
      params.append("months", months.toString());
      params.append("gender", userContext.memberDetail.gender);
      params.append("familyMemberId", userContext.memberDetail.familyMemberId.toString());
      params.append("country", country);
      const result: any = await GetHCDetailForChart(params);
      let chartdata = result.data.data;
      if (userContext.memberDetail.age === 0) {
        setAgeCountData(0);
      }
      setHCChartData(chartdata);
    } catch (error) {
      setLoading(false);
      if (error) {
        setAlertMessage({
          isSuccess: true,
          message: error as string,
        });
      }
    }
    setLoading(false);
  }

  useEffect(() => {
    if (
      userContext.memberDetail !== undefined &&
      userContext.memberDetail.familyMemberId > 0
    ) {
      fetchHCData();
    }
  }, []);

  useEffect(() => {
    if (userContext.memberDetail !== undefined && userContext.memberDetail.familyMemberId > 0) {
      fetchData();
    }
  }, [memberHCDetailData]);

  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: 4 }}
        invisible={true}
        open={isBottomSheetOpen}
        onClick={() => close()}
      ></Backdrop>
      <Fade in={true} timeout={{ enter: 500 }}>
        <Box className="HC-main-box">
          <Box className="head-box">
            <Box className="back-box">
              <IconButton
                aria-label="back"
                className="back-icon"
                disableRipple
                onClick={() => backClick()}
              >
                <img src={backIcon} alt="logo" />
              </IconButton>
            </Box>
            <Box className="title-box">
              <Typography className="title-text">Head Circumference</Typography>
            </Box>
            <Box className="add-box">
              {memberHCDetailData.length > 0 ? (
                <Box onClick={openHCSheet} className="right-header-main-box">
                  <IconButton
                    color="inherit"
                    className="icon-button"
                    disableRipple >
                    <AddOutlinedIcon color="primary" />
                  </IconButton>
                  <Typography
                    variant="body2"
                    color='primary'
                    className="add-text">
                    Add
                  </Typography>
                </Box>
              ) : (
                <></>
              )}
            </Box>

          </Box>
          <Box className="body-box">
            <CustomTabs
              icon={hcIcon}
              option={headCircumferenceOptions}
              growthData={memberHCDetailData}
              onClick={openHCSheet}
              dataLength={hcChartData.length}
              ageCount={ageCountData}
              page="HC"
              yAxisLabel='HC in cm'
              xAxisLabel='Days'
            />
          </Box>

          {isBottomSheetOpen === true ? (
            <Box className="hc-bottom-sheet-box" style={{ overflow: "hidden" }}>
              <a.div
                // onClick={() => close()}
                className={styles.sheet}
                {...bind()}
                style={{ display, bottom: `calc(-100vh + ${height}px)`, y }}
              >
                <Box className="top-bar"></Box>

                <Formik
                  enableReinitialize
                  initialValues={hcData}
                  onSubmit={(values) => {
                    SubmitHC(values);
                  }}
                  validationSchema={valildationSchema}
                >
                  {(props) => {
                    return (
                      <Form>
                        <Box className="form-box">
                          <Grid container spacing={2} padding={2}>
                            <Grid item xs={12} md={12} sm={12}>
                              <Box className="input-box">
                                <FormControl fullWidth>
                                  {submitLoading ? (
                                    <Skeleton
                                      animation="wave"
                                      variant="rounded"
                                      width="auto"
                                      height={45}
                                    />
                                  ) : (
                                    <>
                                      <InputLabel htmlFor="outlined-adornment-password" className={
                                        props.values.hc === "" &&
                                          props.errors.hc &&
                                          props.touched.hc
                                          ? "validation-message"
                                          : ""
                                      }>
                                        Head Circumference
                                      </InputLabel>
                                      <OutlinedInput
                                        id="hc"
                                        label="Head Circumference"
                                        fullWidth
                                        type="number"
                                        endAdornment={
                                          <InputAdornment position="end">
                                            <Typography>cm</Typography>
                                          </InputAdornment>
                                        }
                                        onChange={(e: any) => {
                                          props.setFieldValue(
                                            "hc",
                                            e.target.value !== null
                                              ? e.target.value
                                              : "",
                                            true
                                          );
                                        }}
                                        onBlur={props.handleBlur}
                                        value={props.values.hc}
                                        inputProps={{ maxLength: 200 }}
                                        // variant="outlined"
                                        error={
                                          props.values.hc === "" &&
                                            props.errors.hc &&
                                            props.touched.hc
                                            ? true
                                            : false
                                        }
                                        className={
                                          props.values.hc === "" &&
                                            props.errors.hc &&
                                            props.touched.hc
                                            ? "text-input validation-message"
                                            : "text-input"
                                        }
                                      />

                                      {props.values.hc === "" &&
                                        props.errors.hc &&
                                        props.touched.hc && (
                                          <Typography
                                            className={
                                              props.values.hc === "" &&
                                                props.errors.hc &&
                                                props.touched.hc
                                                ? "validation-message"
                                                : "validation-message"
                                            }
                                          >
                                            {props.errors.hc}
                                          </Typography>
                                        )}
                                    </>
                                  )}
                                </FormControl>
                              </Box>
                            </Grid>
                            {/* <Grid item xs={12} md={12} sm={12}>
                                                        <Typography>CM</Typography>
                                                    </Grid> */}

                            <Grid
                              item
                              xs={12}
                              md={12}
                              sm={12}
                              className="button-grid"
                            >
                              <Box className="bottom-box">
                                <CustomButton
                                  disabled={false}
                                  class="add-button"
                                  isLoading={submitLoading}
                                  title="Add"
                                />
                              </Box>
                            </Grid>
                          </Grid>
                        </Box>
                      </Form>
                    );
                  }}
                </Formik>
              </a.div>
            </Box>
          ) : (
            <></>
          )}
        </Box>
      </Fade>
    </>
  );
}
