
import React, { useContext, useEffect, useState } from 'react';
import './NewBornCare.scss';
import { Box, Collapse, IconButton, Skeleton, Stack, Typography } from '@mui/material';
import backIcon from '../../assets/icons/back.svg';
import noData from "../../assets/icons/no-data.svg";
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import KeyboardArrowUpRoundedIcon from '@mui/icons-material/KeyboardArrowUpRounded';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { PreferencesContext } from '../../PreferenceContext';
import { GetNewBornDetailList } from '../../services/CareOfNewBornService';
import _ from 'lodash';

let init: any;
export default function NewBornCare() {
    const { userContext, updateUserContext } = useContext(PreferencesContext);
    const [loading, setLoading] = useState(false);
    const [moduleName, setModuleName] = useState();
    const [newBornCareData, setNewBornCareData] = useState(init);
    const [showAlert, setAlertMessage] = useState({
        isSuccess: false,
        message: "",
    });

    const navigate = useNavigate();
    const location = useLocation();
    function backClick() {
        navigate("/born-care");
    }
    function onNormalCareTabClick(index, type) {
        let newData: any = newBornCareData;
        let data: any = newData[index];
        if (type === 'N') {
            data.isExpanded = !data.isExpanded;
        }
        newData[index] = data;
        setNewBornCareData([...newData]);
    }

    // function onIssueSolutionTabClick(index, type, bornId) {
    //     let newData: any = newBornCareData
    //     let data: any = newData.find((x) => x.bornId === bornId);
    //     let dataindex: any = newData.findIndex((x) => x.bornId === bornId);
    //     if (type === 'I') {
    //         data.submodule[index].isIssueExpanded = !data.submodule[index].isIssueExpanded;
    //     }
    //     if (type === 'S') {
    //         data.submodule[index].isSolutionExpanded = !data.submodule[index].isSolutionExpanded;
    //     }
    //     newData[dataindex] = data;
    //     setNewBornCareData([...newData]);
    // }


    async function fetchData(page: any) {
        setLoading(true);
        let pageName: any;
        if (page.includes('-')) {
            pageName = page.replace(/-/g, "_");
        } else {
            pageName = page;
        }
        try {
            let params = new URLSearchParams();
            let curModule = userContext.moduleConfiguration.find(
                (x: any) => x.moduleCode === "CARE_OF_NEWBORN" && x.submodule.find((y: any) => y.submoduleCode === pageName.toUpperCase())
            );
            if (curModule !== null && curModule !== undefined) {
                let curSubmodule = curModule.submodule.find((y: any) => y.submoduleCode === pageName.toUpperCase());
                setModuleName(curSubmodule.submoduleName);
                params.append("submoduleId", curSubmodule.submoduleId);
                params.append("subModuleName", curSubmodule.submoduleCode);
                params.append("language", userContext.memberDetail.curMemberSelectedLanguage);
                const result: any = await GetNewBornDetailList(params);
                let bornCare: any;
                bornCare = result.data.data;
                let newData: any = bornCare;
                let data: any = newData[0];
                data.isExpanded = !data.isExpanded;
                newData[0] = data;
                setNewBornCareData([...newData]);
            }
        }
        catch (error) {
            if (error) {
                setAlertMessage({
                    isSuccess: true,
                    message: error as string,
                });
            }
        }

        setLoading(false);
    }

    useEffect(() => {
        if (userContext.memberDetail !== undefined && userContext.memberDetail.familyMemberId > 0) {
            const pageName = window.location.pathname.replace('/', '')
            if (pageName !== '' && pageName !== undefined && pageName !== null) {

                fetchData(pageName)
            }
        }
    }, []);

    return (
        <Box className="born-care-main-box">
            <Box className="head-box">
                <Box className="back-box">
                    <IconButton
                        aria-label="back"
                        className="back-icon"
                        disableRipple
                        onClick={() => backClick()}>
                        <img src={backIcon} alt="logo" />
                    </IconButton>
                </Box>
                <Box className="title-box">
                    <Typography className="title-text">{moduleName}</Typography>
                </Box>
                <Box className="profile-box">
                    <></>
                </Box>
            </Box>
            <Box className="body-box">
                {loading ?
                    (_.times(2, (i) => (
                        <Stack spacing={1} key={i} sx={{ marginBottom: '15px' }}>
                            <Skeleton animation="wave" variant="rounded" width='auto' height={45} />
                            <Skeleton animation="wave" variant="rounded" width='auto' height={75} />
                        </Stack>
                    )))
                    :
                    (newBornCareData !== undefined && newBornCareData.length > 0 ?
                        newBornCareData.map((row: any, index: any) => (
                            <Box key={row.bornId} className="main-container">
                                <Box className="normal-care-body-container" >
                                    <Box className={row.isExpanded === true ? "header-box" : "header-box header-box-close"} onClick={() => onNormalCareTabClick(index, 'N')}>
                                        {row.headerTitle !== undefined && row.headerTitle !== null ? <Typography className="header-text">{row.headerTitle}</Typography> : <></>}
                                        <IconButton disableRipple>{row.isExpanded === true ? <KeyboardArrowUpRoundedIcon /> : <KeyboardArrowDownRoundedIcon />}</IconButton>
                                    </Box>
                                    <Box className="detail-box">
                                        <Collapse in={row.isExpanded}>
                                            <Box className='info-box'>
                                                <Typography className='info-text'>{row.bodyDetail} </Typography>
                                                {row.submodule !== undefined && row.submodule.length > 0 &&
                                                    row.submodule.map((subrow: any, i: any) => (
                                                        <React.Fragment key={i}>
                                                            <Typography className='info-text text-heading'
                                                                dangerouslySetInnerHTML={{
                                                                    __html: subrow.subHeader !== undefined && subrow.subHeader !== null && subrow.subHeader
                                                                }}></Typography>
                                                            <Typography className='info-text'
                                                                dangerouslySetInnerHTML={{
                                                                    __html: subrow.subDetail !== undefined && subrow.subDetail !== null && subrow.subDetail
                                                                }}></Typography>
                                                        </React.Fragment>
                                                    ))}
                                            </Box>
                                        </Collapse>
                                    </Box>
                                </Box>
                            </Box>
                        )) :

                        <Box className="no-summary-box">
                            <Box className="upper-box"></Box>
                            <Box className="bottom-box">
                                <Box className="img-box">
                                    <img src={noData} alt="logo" />
                                </Box>
                                <Box>
                                    <Box className="no-data-box">
                                        <Typography className="no-summary-text">
                                            No data available
                                        </Typography>
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                    )}
            </Box>
        </Box>
    );
}