import React, { useContext, useEffect, useState } from 'react';
import './StageSelection.scss';
import { Alert, Box, Card, CardContent, Dialog, DialogActions, DialogContent, DialogContentText, Fade, Grid, IconButton, Radio, Typography } from '@mui/material';
import weAreExpecting from '../../assets/images/we_are_expecting.svg';
import weAreParents from '../../assets/images/we_are_parent.svg';
import PregnantIcon from '../../assets/icons/Pregnant-2.svg';
import Pragnent from '../../assets/icons/Pragnent.svg';
import familyIcon from '../../assets/icons/family.svg';
import backIcon from '../../assets/icons/back.svg';
import { useNavigate } from 'react-router-dom';
import { Form, Formik } from 'formik';
import { PreferencesContext } from '../../PreferenceContext';
import { CreateUser } from '../../services/UserService';
import { GetIsUserExist } from '../../services/AuthService';
import { GetIsAlreadyParentExist } from '../../services/ParentService';
import { GetIsMaternalDetailExist } from '../../services/MaternalDetailService';
import { LoginDetail } from '../../services/models/Context';
import CustomButton from '../../components/CustomButton/CustomButton';
import { LoadingButton } from '@mui/lab';

const formInitialValues = {
    selectStage: "",
};

export default function StageSelection() {
    const { userContext, updateUserContext } = useContext(PreferencesContext);
    const [loading, setLoading] = useState(false);
    // const [selectedStageName, setselectedStageName] = useState(formInitialValues);
    const [selectedValue, setSelectedValue] = useState(true);
    const [selectedStageName, setselectedStageName] = useState('EXPECTING');
    const [isAlreadyExpectedPopUp, setIsAlreadyExpectedPopUp] = useState(false);
    const [parentsProcedureDetailsShow, setParentsProcedureDetailsShow] = useState(false);
    const [expectiongProcedureDetailsShow, setExpectiongProcedureDetailsShow] = useState(false);
    const [showAlert, setAlertMessage] = useState({
        isError: false,
        message: "",
    });
    const { detect } = require("detect-browser");
    const browser = detect();

    let navigate = useNavigate();

    const goToparent = () => {
        navigate("/parent");
    };

    const goToDashboard = () => {
        setIsAlreadyExpectedPopUp(false);
        navigate('/dashboard');
    }

    const selectedFirstCard = () => {
        setSelectedValue(true);
        setselectedStageName('EXPECTING');
        setExpectiongProcedureDetailsShow(true);
        setParentsProcedureDetailsShow(false);
        if (userContext.isWifePregnant === true) {
            setIsAlreadyExpectedPopUp(true);
        }
    }

    const selectedSecondCard = () => {
        setSelectedValue(false);
        setselectedStageName('PARENTS');
        setParentsProcedureDetailsShow(true);
        setExpectiongProcedureDetailsShow(false);
    }

    function backClick() {
        if (userContext.loginDetail !== null && userContext.loginDetail.userId > 0) {
            navigate("/dashboard");
        } else {
            navigate("/");
        }
    }

    // stage selection and user entry

    async function submitStageSelection(value: any) {
        setLoading(true);
        let deviceOs = browser.os;
        let osVersion = browser.version;
        let fullBrowserDetail2 = browser.name;

        if (selectedStageName === "EXPECTING" && userContext.isWifePregnant === true) {
            setIsAlreadyExpectedPopUp(true);
        } else {
            try {
                let loginDetail = new LoginDetail();
                let data = {
                    userId: userContext.loginDetail.userId,
                    mobileNumber: userContext.mobileNumber.split("+")[1],
                    selectStage: selectedStageName,
                    isDeleted: false,
                    deviceOs: deviceOs,
                    osVersion: osVersion
                }

                // check is user already exist...

                const userExist: any = await GetIsUserExist(data)

                if (userExist.data === null) {
                    // user entry...
                    const user: any = await CreateUser(data);

                    loginDetail.userId = user.data;
                    loginDetail.familyId = user.data;
                    updateUserContext({
                        ...userContext,
                        stage: selectedStageName,
                        loginDetail: loginDetail
                    });
                    goToparent();
                }
                else {
                    // user exist...
                    loginDetail = userContext.loginDetail;
                    loginDetail.userId = userExist.data.userId;
                    loginDetail.familyId = userExist.data.familyId;

                    updateUserContext({
                        ...userContext,
                        stage: selectedStageName,
                        loginDetail: loginDetail
                    });

                    // in case of we are expecting...
                    if (selectedStageName === "EXPECTING") {
                        let params = new URLSearchParams();

                        // params.append("familyId", loginDetail.familyId.toString());
                        params.append("familyId", userContext.loginDetail.familyId.toString());
                        params.append("selectStage", selectedStageName.toString());
                        params.append("mode", 'M');
                        // check parent exist...
                        const result: any = await GetIsAlreadyParentExist(params);
                        let familymemberDetail = result.data.data;
                        if (result.ok === true) {
                            if (familymemberDetail === null || familymemberDetail.familyRelationName === 'FATHER') {
                                if (userContext.loginDetail.familyMemberId > 0) {
                                    // mother not exist...
                                    navigate("/spouse");
                                } else {
                                    goToparent();
                                }
                            }
                            else {
                                // mother exist but maternal detail not exist...
                                let params = new URLSearchParams();
                                params.append("familyId", familymemberDetail.familyId.toString());
                                params.append("familyMemberId", familymemberDetail.familyMemberId.toString());
                                const maternalDetailExist: any = await GetIsMaternalDetailExist(params);
                                updateUserContext({
                                    ...userContext,
                                    spouseFamilyMemberId: 0,
                                    stage: selectedStageName,
                                    isComeFromStageSelection: true
                                });
                                navigate("/maternal-detail");
                            }
                        }
                    }
                    // in case of we are parents...
                    else {

                        loginDetail.userId = userExist.data.userId;
                        loginDetail.familyId = userExist.data.familyId

                        // updateUserContext({
                        //     ...userContext,
                        //     stage: selectedStageName,
                        //     loginDetail: loginDetail
                        // });
                        let params = new URLSearchParams();
                        params.append("familyId", loginDetail.familyId.toString());
                        params.append("mode", 'null');
                        params.append("selectStage", selectedStageName.toString());

                        // check parent exist...
                        const result: any = await GetIsAlreadyParentExist(params);
                        if (result.data.data !== null) {
                            updateUserContext({
                                ...userContext,
                                stage: selectedStageName,
                                loginDetail: loginDetail,
                                isComeFromStageSelection: true,
                            });
                            navigate("/child-detail");
                        }
                        else {
                            goToparent();
                        }
                    }
                }
            }
            catch (error) {
                setLoading(false);
                if (error) {
                    setAlertMessage({
                        isError: true,
                        message: error as string,
                    });
                }
            }
        }
    }

    useEffect(() => {
        if (userContext.stage !== undefined && userContext.stage !== null) {
            // fetchData();
            if (userContext.stage === "PARENTS") {
                setSelectedValue(false);
                setselectedStageName('PARENTS');
                setExpectiongProcedureDetailsShow(false);
                setParentsProcedureDetailsShow(true);
            }
            else {
                setSelectedValue(true);
                setselectedStageName('EXPECTING');
                setExpectiongProcedureDetailsShow(true);
                setParentsProcedureDetailsShow(false);
            }
        }
    }, []);

    return (
        <Fade in={true} timeout={{ enter: 500 }}>
            <Box className="stage-selection-main-box">
                <Box className="head-box">
                    <Box className="back-box">
                        <IconButton aria-label="back" className='back-icon' disableRipple
                            onClick={() => backClick()}>
                            <img src={backIcon} alt='logo' />
                        </IconButton>
                    </Box>
                    <Box className="title-box">
                        <Typography className="title-text">We are</Typography>
                    </Box>
                    <Box className="skip-box">
                        <></>
                        {/* <Typography>skip</Typography> */}
                    </Box>
                </Box>
                {/* <Box className="bottom-box-hidden"></Box> */}
                <Formik
                    initialValues={formInitialValues}
                    onSubmit={(values) => {
                        submitStageSelection(values);
                    }}>
                    {(props) => {
                        return (
                            <Form>
                                <Box className="middle-box">
                                    <Box className="subheading-box">
                                        {/* <Typography className="subheading-text">Please select your pregnancy stage.</Typography> */}
                                    </Box>
                                    <Box className="selection-box">
                                        <Grid container spacing={2} p={1} className='container-grid'>
                                            <Grid item xs={6} sm={6} md={6} lg={6} className='item-grid'>
                                                <Card elevation={0} className={selectedValue === true ? "card-box selected" : "card-box"} onClick={selectedFirstCard}>
                                                    <Radio
                                                        className='radio-selection'
                                                        checked={selectedValue === true}
                                                        value={true}
                                                        name="radio-buttons"
                                                        inputProps={{ 'aria-label': 'A' }}
                                                    />
                                                    {/* <img src={weAreExpecting} alt="logo" /> */}
                                                    {/* <img src={Pragnent} alt="logo" /> */}
                                                    <img src={PregnantIcon} alt="logo" />
                                                    <CardContent>
                                                        <Typography variant="h5" className={selectedValue === true ? 'card-text card-text-selected' : 'card-text'}>
                                                            Expecting a Child
                                                        </Typography>
                                                    </CardContent>
                                                </Card>
                                            </Grid>

                                            <Grid item xs={6} sm={6} md={6} lg={6} className='item-grid'>
                                                <Card elevation={0} sx={{ padding: '20px' }} className={selectedValue === false ? "card-box selected" : "card-box"} onClick={selectedSecondCard}>
                                                    <Radio
                                                        className='radio-selection'
                                                        checked={selectedValue === false}
                                                        value={false}
                                                        name="radio-buttons"
                                                        inputProps={{ 'aria-label': 'B' }}
                                                    />
                                                    {/* <img src={weAreParents} alt="logo" /> */}
                                                    <img src={familyIcon} alt="logo" />
                                                    <CardContent>
                                                        <Typography variant="h5" className={selectedValue === false ? 'card-text card-text-selected' : 'card-text'}>
                                                            Already parents
                                                        </Typography>
                                                    </CardContent>
                                                </Card>
                                            </Grid>
                                        </Grid>
                                    </Box>

                                    <Box className="instruction-box">
                                        <Grid container spacing={2} p={1} className='instruction-container-grid'>
                                            <Grid item xs={12}>
                                                {parentsProcedureDetailsShow === true ?
                                                    <Alert severity="info">
                                                        Enter the details of your child to monitor his/her growth, and development. Optimize his/her diet, nutrition, health, fitness, care during illness, maintain health and vaccination records.
                                                    </Alert>
                                                    :
                                                    <></>
                                                }
                                                {expectiongProcedureDetailsShow === true ?
                                                    <Alert severity="info">
                                                        Enter your/ and your spouse’s details regarding the current pregnancy and you will be able to monitor the growth and development of your upcoming child as well as we will help you to minimize the antenatal, perinatal, and post-natal complications in mother and child both.
                                                    </Alert>
                                                    :
                                                    <></>}
                                            </Grid>
                                        </Grid>
                                    </Box>
                                    <Box className="bottom-box">
                                        <CustomButton
                                            disabled={false}
                                            class="next-button"
                                            isLoading={loading}
                                            title='Next'
                                        />
                                    </Box>
                                </Box>

                            </Form>)
                    }}
                </Formik>
                <Dialog
                    maxWidth="md"
                    open={isAlreadyExpectedPopUp}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    className="conceived-dialog-box">
                    <Box className="conceived-box">
                        <DialogContent className="dialog-subcontent">
                            <DialogContentText
                                id="alert-dialog-description"
                                className="conceived-text">
                                Your wife is already conceived.
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions className="dialog-action-button">
                            <LoadingButton
                                color="primary"
                                disableRipple
                                variant="contained"
                                className="confirm-btn"
                                onClick={() => goToDashboard()}>
                                Ok
                            </LoadingButton>
                        </DialogActions>
                    </Box>
                </Dialog>
            </Box>

        </Fade>
    )
}