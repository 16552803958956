import React, { useContext, useEffect, useState } from 'react';
import './Parent.scss';
import { Avatar, Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, Fade, FormControl, Grid, IconButton, Skeleton, TextField, Typography, styled } from '@mui/material';
import { useNavigate } from "react-router-dom";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import Select from 'react-select';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import cameraIcon from '../../assets/images/camera.svg';
import backIcon from '../../assets/icons/back.svg';
import { CreateParent, GetFamilyMemberDetail, GetFamilyRelationList, UpdateParent } from '../../services/ParentService';
import { PreferencesContext } from '../../PreferenceContext';
import { AlertType } from '../../shared/AlertType';
import { ageInDays, getNameSelected, getSingleSelected } from '../../shared/CommonFuctions';
import CustomButton from '../../components/CustomButton/CustomButton';
import { MemberDetail } from '../../services/models/Context';
import { LoadingButton } from '@mui/lab';

const SmallAvatar = styled(Avatar)(({ theme }) => ({
    width: 38,
    height: 38,
    cursor: 'pointer'
}));

export default function Parent() {
    const { userContext, updateUserContext } = useContext(PreferencesContext);
    const formInitialValues = {
        familyMemberId: 0,
        familyRelationName: "",
        familyRelationCode: "",
        firstName: "",
        lastName: "",
        age: "",
        maternalMotherId: 0,
        isChildBorn: false,
        isTermination: false

        // isDeleted: 0
    };
    const [loading, setLoading] = useState(false);
    const [submitLoading, setSubmitLoading] = useState(false);
    const [parentData, setParentData] = useState(formInitialValues);
    const [profileImageFile, setProfileImageFile] = useState<any>();
    const [image, setImage] = useState({ preview: "", raw: "" });
    const [serverImage, setServerImage] = useState('');
    const [profileImageName, setProfileImageName] = useState('');
    const [familyRelation, setFamilyRelation] = useState([]);
    const [showAlert, setAlertMessage] = useState({
        isSuccess: false,
        message: "",
    });
    const [isMaternityAddPopup, setIsMaternityAddPopup] = useState(false);
    const [isChildAddPopup, setIsChildAddPopup] = useState(false);
    const navigate = useNavigate();

    const { detect } = require("detect-browser");
    const browser = detect();

    const stage = userContext.stage !== undefined && userContext.stage !== null && userContext.stage !== "" ?
        userContext.stage === "EXPECTING" ? true : false : false;

    const valildationSchema = Yup.object().shape({
        familyRelationName: Yup.number().required("Role is required."),
        firstName: Yup.string().required("First Name is required."),
        lastName: Yup.string().required("Last Name is required."),
        age: Yup.string().required("Age is required.")
    });

    async function fetchFamilyRelation() {
        try {
            const famailyrelationData: any = await GetFamilyRelationList();
            if (famailyrelationData && famailyrelationData.data !== null && famailyrelationData.data.data !== null) {
                let famailyrelationCollection = famailyrelationData.data.data;
                setFamilyRelation(famailyrelationCollection);
            }
        }
        catch (error) {
            if (error) {
                updateUserContext({
                    ...userContext,
                    isAlert: true,
                    alertMessage: error as string,
                    alertType: AlertType.Error,
                });
            }
        }
    }

    const expectingList = [
        "Guardian",
        "Son",
        "Daughter"
    ];

    const parentList = [
        "Son",
        "Daughter"
    ];

    const expectingFilteredList = familyRelation.filter(
        ({ label }) => !expectingList.includes(label)
    );

    const parntFilteredList = familyRelation.filter(
        ({ label }) => !parentList.includes(label)
    );

    const gotoDashboard = () => {
        if (userContext.isEditMaternalDetail === true) {
            updateUserContext({
                ...userContext,
                isEditMaternalDetail: false
            });
        }
        navigate('/dashboard');
    }
    const goToFamilyMemberList = () => {
        if (userContext.isEditMaternalDetail === true) {
            updateUserContext({
                ...userContext,
                isEditMaternalDetail: false
            });
        }
        navigate('/member-list');
    }
    const goToSpouse = () => {
        navigate('/spouse');
    }
    const gotoChild = (isAdd: any) => {
        if (isAdd) {
            updateUserContext({
                ...userContext,
                isAddChildDetail: true
            });
        }
        navigate('/child-detail');
    }

    const gotoStageSelection = () => {
        navigate('/stage-selection');
    }

    const goToMaternalDetails = (isAdd: any, id: any) => {
        if (isAdd) {
            updateUserContext({
                ...userContext,
                isAddMaternalDetail: true
            });
        } else {
            updateUserContext({
                ...userContext,
                isEditMaternalDetail: true
            });
        }
        navigate('/maternal-detail');
    }

    const goToClosePopup = (isAddMaternity: any) => {
        if (isAddMaternity) {
            setIsMaternityAddPopup(false);
            setIsChildAddPopup(true);
        } else {
            setIsChildAddPopup(false)
            goToFamilyMemberList();
        }

    }
    function backClick() {
        if (userContext.isAddMemberFromList === true) {
            goToFamilyMemberList();
        } else if (userContext.isAddMember === true) {
            gotoStageSelection();
        } else if (userContext.isEditMemberFromList === true) {
            goToFamilyMemberList();
        } else if (userContext.isEditMemberFromBottomSheet === true) {
            gotoDashboard();
        }
        else {
            gotoStageSelection();
        }
    }

    const handleChange = (e: any) => {
        if (e.target.files.length) {
            setImage({
                preview: URL.createObjectURL(e.target.files[0]),
                raw: e.target.files[0]
            });
        }
        setProfileImageName(e.target.files[0].name);
        setProfileImageFile(e.target.files[0])
    };

    // parent detail get...
    async function fetchData() {
        setLoading(true);
        try {
            let params = new URLSearchParams();
            if (userContext.isEdit === true) {
                if (userContext.isEditMemberFromBottomSheet === true) {
                    params.append("familyMemberId", userContext.currFamilyMemberId.toString());
                } else if (userContext.isEditMemberFromList === true) {
                    params.append("familyMemberId", userContext.currFamilyMemberId.toString());
                } else {
                    params.append("familyMemberId", userContext.loginDetail.familyMemberId.toString());
                }
            } else {
                params.append("familyMemberId", userContext.loginDetail.familyMemberId.toString());
            }
            const result: any = await GetFamilyMemberDetail(params);
            let editDetails: any;
            editDetails = result.data.data;
            let updatedValue = {
                familyMemberId: editDetails.familyMemberId,
                familyRelationName: getNameSelected(
                    editDetails.familyRelationName,
                    familyRelation
                ),
                familyRelationCode: editDetails.familyRelationName,
                firstName: editDetails.firstName,
                lastName: editDetails.lastName,
                age: editDetails.age,
                maternalMotherId: editDetails.maternalMotherId,
                isChildBorn: editDetails.isChildBorn,
                isTermination: editDetails.isTermination
            };
            setServerImage(editDetails.serverImage)
            setProfileImageName(editDetails.profileName)
            setImage({ preview: editDetails.profileImageBase });
            setParentData(updatedValue);
            updateUserContext({
                ...userContext,
                spouseFamilyMemberId: editDetails.maternalMotherId,
                curMaternalDetailId: editDetails.maternalDetailId
            });
        }
        catch (error) {
            updateUserContext({
                ...userContext,
                isAlert: true,
                // alertMessage: 'Something went wrong',
                alertMessage: error.message,
                alertType: AlertType.Error,
            });
        }
        setLoading(false);
    }

    // submit parent detail...
    async function SubmitParent(value: any) {
        setSubmitLoading(true);
        const formData = new FormData();
        let firstFamilyMember = userContext.loginDetail.lastName === '' && userContext.loginDetail.firstName === '' ? true : false;
        let deviceOs = browser.os;
        let osVersion = browser.version;

        const parentData = value;
        formData.append('familyMemberId', parentData.familyMemberId);
        formData.append('familyRelationName', parentData.familyRelationCode);
        formData.append('firstName', parentData.firstName);
        formData.append('lastName', parentData.lastName);
        formData.append('age', parentData.age);
        formData.append('gender', parentData.familyRelationCode === 'FATHER' ? 'MALE' : parentData.familyRelationCode === 'MOTHER' ? 'FEMALE' : 'MALE');
        formData.append('familyId', userContext.loginDetail.userId.toString());
        formData.append('dataManagedBy', userContext.loginDetail.userId.toString());
        formData.append('profileImage', profileImageFile);
        formData.append('profileName', profileImageName);
        formData.append('serverImage', serverImage);
        formData.append('firstFamilyMember', firstFamilyMember);
        formData.append('userId', userContext.loginDetail.userId.toString());
        formData.append('selectedStageName', userContext.stage);
        formData.append('mobileNumber', userContext.mobileNumber.split("+")[1]);
        formData.append('deviceOs', deviceOs);
        formData.append('osVersion', osVersion);

        // parent exist than edit ....
        if (parentData.familyMemberId > 0) {
            try {
                const resultParentSave: any = await UpdateParent(formData);
                if (userContext.loginDetail.familyMemberId === parentData.familyMemberId) {

                    let loginDetail = userContext.loginDetail;
                    setSubmitLoading(false);
                    loginDetail.familyId = resultParentSave.data.familyId;
                    loginDetail.familyMemberId = resultParentSave.data.familyMemberId;
                    loginDetail.familyRelationName = resultParentSave.data.familyRelationName;
                    loginDetail.birthDate = resultParentSave.data.birthDate;
                    loginDetail.gender = resultParentSave.data.gender;
                    loginDetail.firstName = resultParentSave.data.firstName;
                    loginDetail.lastName = resultParentSave.data.lastName;
                    loginDetail.profileImageBase = resultParentSave.data.profileImageBase;
                    loginDetail.familyName = resultParentSave.data.lastName + "'s Family";
                    updateUserContext({
                        ...userContext,
                        loginDetail: loginDetail,
                        isAlert: true,
                        alertMessage: resultParentSave.message,
                        alertType: AlertType.Success,
                    });

                    if (userContext.stage === "PARENTS") {
                        if (resultParentSave.data.familyRelationName === "GUARDIAN") {
                            gotoChild(false);
                        }
                        else {
                            if (userContext.isAddMember === false) {
                                if (userContext.isAddMemberFromList === false) {
                                    if (userContext.isEditMemberFromBottomSheet === false) {
                                        if (userContext.isEditMemberFromList === false) {
                                            goToSpouse();
                                        } else {
                                            goToFamilyMemberList();
                                        }
                                    }
                                    else {
                                        goToFamilyMemberList();
                                    }
                                } else {
                                    goToFamilyMemberList();
                                }

                            } else {
                                gotoDashboard();
                            }
                        }
                    }
                    else {
                        if (userContext.isAddMember === false) {
                            if (userContext.isAddMemberFromList === false) {
                                if (userContext.isEditMemberFromBottomSheet === false) {
                                    if (userContext.isEditMemberFromList === false) {
                                        goToSpouse();
                                    } else {
                                        goToFamilyMemberList();
                                    }
                                }
                                else {
                                    goToFamilyMemberList();
                                }
                            } else {
                                goToFamilyMemberList();
                            }

                        } else {
                            gotoDashboard();
                        }
                    }
                }
                else {
                    if (userContext.isEditMemberFromBottomSheet === true) {
                        if (userContext.currFamilyMemberId === userContext.memberDetail.familyMemberId) {
                            let memberDetail = new MemberDetail();
                            memberDetail.age = resultParentSave.data.birthDate !== "" ? ageInDays(resultParentSave.data.birthDate) : resultParentSave.data.age;
                            memberDetail.birthDate = resultParentSave.data.birthDate;
                            memberDetail.familyId = resultParentSave.data.familyId;
                            memberDetail.familyMemberId = resultParentSave.data.familyMemberId;
                            memberDetail.familyRelationName = resultParentSave.data.familyRelationName;
                            memberDetail.firstName = resultParentSave.data.firstName;
                            memberDetail.lastName = resultParentSave.data.lastName;
                            memberDetail.gender = resultParentSave.data.gender;
                            memberDetail.isPrematureChild = resultParentSave.data.isPrematureChild;
                            memberDetail.isSpecialChild = resultParentSave.data.isSpecialChild;
                            memberDetail.maternalDetailId = resultParentSave.data.maternalDetailId;
                            memberDetail.complicationsDetails = resultParentSave.data.complicationsDetails;
                            memberDetail.expectingChildNo = resultParentSave.data.expectingChildNo;
                            memberDetail.expectingDateOfDelivery = resultParentSave.data.expectingDateOfDelivery;
                            memberDetail.gestationalAge = resultParentSave.data.gestationalAge;
                            memberDetail.gestationalAgeTime = resultParentSave.data.gestationalAgeTime;
                            memberDetail.isChildBorn = resultParentSave.data.isChildBorn;
                            memberDetail.isPragnentExpectingChild = resultParentSave.data.isPragnentExpectingChild;
                            memberDetail.lastManstrualPeriodDate = resultParentSave.data.lastManstrualPeriodDate;
                            memberDetail.profileImageBase = resultParentSave.data.profileImageBase;
                            updateUserContext({
                                ...userContext,
                                memberDetail: memberDetail,
                                curMemberSelected: resultParentSave.data.firstName
                            });
                        }
                    }
                }
                if (userContext.stage === "PARENTS") {
                    if (resultParentSave.data.familyRelationName === "GUARDIAN") {
                        gotoChild(false);
                    }
                    else {
                        if (userContext.isEditMemberFromBottomSheet === true) {
                            gotoDashboard();
                        } else if (userContext.isEditMemberFromList === true) {
                            goToFamilyMemberList();
                        }
                    }
                }
                else {
                    if (userContext.isEditMemberFromBottomSheet === true) {
                        gotoDashboard();
                    } else if (userContext.isEditMemberFromList === true) {
                        goToFamilyMemberList();
                    }
                }

            } catch (e: any) {
                updateUserContext({
                    ...userContext,
                    isAlert: true,
                    alertMessage: e.response.message,
                    alertType: AlertType.Error,
                });
            }
        }
        else {
            // create parent...
            try {
                const resultParentSave: any = await CreateParent(formData);
                if (resultParentSave && resultParentSave.data.familyData !== null) {
                    let familyData = resultParentSave.data.familyData;
                    if (userContext.isAddMemberFromList === false) {
                        let loginDetail = userContext.loginDetail;
                        setSubmitLoading(false);
                        loginDetail.familyId = familyData.familyId;
                        loginDetail.familyMemberId = familyData.familyMemberId;
                        loginDetail.familyRelationName = familyData.familyRelationName;
                        loginDetail.birthDate = familyData.birthDate;
                        loginDetail.gender = familyData.gender;
                        loginDetail.firstName = familyData.firstName;
                        loginDetail.lastName = familyData.lastName;
                        loginDetail.profileImageBase = familyData.profileImageBase;
                        loginDetail.familyName = familyData.lastName + "'s Family";
                        updateUserContext({
                            ...userContext,
                            loginDetail: loginDetail,
                            currFamilyMemberId: familyData.familyMemberId,
                            isAlert: true,
                            token: resultParentSave.data.token,
                            alertMessage: resultParentSave.message,
                            alertType: AlertType.Success,
                        });
                    }
                    setSubmitLoading(false);

                    if (userContext.isAddMemberFromList === true) {
                        if (familyData.familyRelationName === "MOTHER") {
                            updateUserContext({
                                ...userContext,
                                currFamilyMemberId: familyData.familyMemberId,
                            });

                            setIsMaternityAddPopup(true);
                        } else {
                            goToFamilyMemberList();
                        }
                    } else {
                        if (userContext.stage === "PARENTS") {
                            if (familyData.familyRelationName === "GUARDIAN") {
                                gotoChild(false);
                            } else {
                                if (userContext.isAddMember === false) {
                                    if (userContext.isEditMemberFromBottomSheet === false) {
                                        if (userContext.isEditMemberFromList === false) {
                                            goToSpouse();
                                        } else {
                                            goToFamilyMemberList();
                                        }
                                    } else {
                                        gotoDashboard();
                                    }
                                } else {
                                    gotoDashboard();
                                }
                            }
                        }
                        else {
                            if (userContext.isAddMember === false) {
                                if (userContext.isEditMemberFromBottomSheet === false) {
                                    if (userContext.isEditMemberFromList === false) {
                                        goToSpouse();
                                    } else {
                                        goToFamilyMemberList();
                                    }
                                } else {
                                    gotoDashboard();
                                }
                            } else {
                                gotoDashboard();
                            }
                        }
                    }
                }
                else {
                    // console.log('resultParentSave.data null che')
                    // console.log('else', userContext.isAddMemberFromList);
                    setSubmitLoading(false)
                    let loginDetail = userContext.loginDetail;
                    loginDetail.familyRelationName = parentData.familyRelationCode
                    loginDetail.firstName = parentData.firstName;
                    loginDetail.lastName = parentData.lastName;
                    // setLoading(false);
                    updateUserContext({
                        ...userContext,
                        loginDetail: loginDetail,
                        isAlert: true,
                        alertMessage: resultParentSave.message,
                        alertType: AlertType.Success,
                    });

                    if (userContext.stage === "PARENTS") {
                        // console.log('stage parents che')
                        // goToMaternalDetail();
                        if (userContext.isAddMember === false) {
                            // console.log('member add nathi')
                            if (userContext.isEditMemberFromBottomSheet === true) {
                                // console.log('member edit bottom sheet')
                                gotoDashboard();
                            }
                            else {
                                if (userContext.isAddMemberFromList === true) {
                                    // console.log('member add list')
                                    goToFamilyMemberList();
                                } else {
                                    // console.log('member add list nathi')
                                    goToSpouse();
                                }

                            }
                        }
                        else {
                            // console.log('member add kare che')
                            gotoDashboard();
                        }
                    }
                    else {
                        // console.log('stage expecting che')
                        gotoDashboard();
                    }
                }
            }
            catch (error) {
                setSubmitLoading(false);
                if (error) {
                    updateUserContext({
                        ...userContext,
                        isAlert: true,
                        alertMessage: error as string,
                        alertType: AlertType.Error,
                    });
                }
            }
        }
    }
    useEffect(() => {
        fetchFamilyRelation();
    }, []);

    useEffect(() => {
        if (userContext.isAddMemberFromList === false) {
            if (userContext.loginDetail !== undefined && userContext.loginDetail.familyMemberId > 0 && userContext.isEdit === true) {
                fetchData();
            }
            else {
                if (userContext.isEditMemberFromList === true) {
                    fetchData();
                }
                else if (userContext.isEditMemberFromBottomSheet === true) {
                    fetchData();
                }
                else {
                    if (userContext.loginDetail !== undefined && userContext.loginDetail.familyMemberId > 0) {
                        fetchData();
                    }
                }
            }
        }
        else {
            setParentData({ ...parentData, lastName: userContext.loginDetail.lastName });
        }

    }, [familyRelation]);

    return (
        <Fade in={true} timeout={{ enter: 500 }}>
            <Box className="parent-main-box">
                <Box className="head-box">
                    <Box className="back-box">
                        <IconButton aria-label="back" className='back-icon' disableRipple
                            onClick={() => backClick()}>
                            <img src={backIcon} alt='logo' />
                        </IconButton>
                    </Box>
                    <Box className="title-box">
                        <Typography className="title-text">{userContext.isAddMemberFromList === true ? "Add member" : (userContext.isEditMemberFromBottomSheet === true || userContext.isEditMemberFromList === true) ? "Edit member" : "Yourself"}</Typography>
                    </Box>
                    <Box className="skip-box">
                        <></>
                        {/* <Typography>skip</Typography> */}
                    </Box>
                </Box>
                <Formik
                    enableReinitialize
                    initialValues={parentData}
                    onSubmit={(values) => {
                        SubmitParent(values);
                    }}
                    validationSchema={valildationSchema}
                >
                    {(props) => {
                        return (
                            <Form>
                                <Box className="body-box">
                                    <Grid
                                        container
                                        spacing={2}
                                        padding={2}>

                                        <Grid item xs={12} className='profile-grid'>
                                            {loading ?
                                                <Skeleton animation="wave" variant="rounded" width={160} height={160} sx={{ borderRadius: "50%" }} /> :
                                                <Box className="img-box">
                                                    <Box className="set-img-box">
                                                        <Avatar className='img-preview' alt="logo" src={image.preview} />
                                                    </Box>
                                                    <Box className='icon-box'>
                                                        <input
                                                            type="file"
                                                            id="upload-button"
                                                            style={{ display: "none" }}
                                                            onChange={handleChange}
                                                        />
                                                        <label htmlFor="upload-button" className='img-lable'>
                                                            <SmallAvatar alt="logo" src={cameraIcon} className='camera-icon' />
                                                        </label>
                                                    </Box>
                                                </Box>
                                            }
                                        </Grid>

                                        <Grid item xs={12} md={12} sm={12} zIndex={9}>
                                            <FormControl fullWidth style={{ width: '100%' }}>
                                                {loading ?
                                                    <Skeleton animation="wave" variant="rounded" width='auto' height={45} /> :
                                                    <Select
                                                        id="familyRelationName"
                                                        isDisabled={userContext.isEdit === true ? true : false}
                                                        classNamePrefix="select"
                                                        styles={
                                                            props.values.familyRelationName === "" &&
                                                                props.errors.familyRelationName &&
                                                                props.touched.familyRelationName
                                                                ? selectStyles
                                                                : undefined
                                                        }
                                                        isClearable={true}
                                                        isSearchable={true}
                                                        placeholder={"Role"}
                                                        theme={(theme) => ({
                                                            ...theme,
                                                            colors: {
                                                                ...theme.colors,
                                                                primary: "#0B7CAA",
                                                                primary25: "#F1F6FE",
                                                                primary50: "#e1dfea",
                                                            },
                                                        })}
                                                        value={getSingleSelected(
                                                            props.values.familyRelationName,
                                                            familyRelation
                                                        )}
                                                        onChange={(e, value) => {
                                                            props.setFieldValue(
                                                                "familyRelationName",
                                                                e !== null ? e.value : "",
                                                                false
                                                            );
                                                            props.setFieldValue(
                                                                "familyRelationCode",
                                                                e.code,
                                                                true
                                                            );
                                                        }}
                                                        options={stage === true ? expectingFilteredList : parntFilteredList}
                                                        // options={familyRelation}
                                                        className={
                                                            props.values.familyRelationName === "" &&
                                                                props.errors.familyRelationName &&
                                                                props.touched.familyRelationName
                                                                ? "text-input"
                                                                : "text-input"
                                                        }
                                                    />}
                                                {props.values.familyRelationName === "" &&
                                                    props.errors.familyRelationName &&
                                                    props.touched.familyRelationName && (
                                                        <Typography
                                                            className={
                                                                props.values.familyRelationName === "" &&
                                                                    props.errors.familyRelationName &&
                                                                    props.touched.familyRelationName
                                                                    ? "validation-message text-input"
                                                                    : "text-input"
                                                            }
                                                        >
                                                            {props.errors.familyRelationName}
                                                        </Typography>
                                                    )}
                                            </FormControl>
                                        </Grid>

                                        <Grid item xs={12} md={12} sm={12}>
                                            <FormControl fullWidth>
                                                {loading ?
                                                    <Skeleton animation="wave" variant="rounded" width='auto' height={45} /> :
                                                    <TextField
                                                        // required
                                                        id="firstName"
                                                        label="First Name"
                                                        fullWidth
                                                        size="small"
                                                        onChange={(e: any) => {
                                                            props.setFieldValue(
                                                                "firstName",
                                                                e.target.value !== null ? e.target.value : "",
                                                                true
                                                            );
                                                        }}
                                                        onBlur={props.handleBlur}
                                                        value={props.values.firstName}
                                                        inputProps={{ maxLength: 200 }}
                                                        variant="outlined"
                                                        error={
                                                            props.values.firstName === "" &&
                                                                props.errors.firstName &&
                                                                props.touched.firstName
                                                                ? true
                                                                : false
                                                        }
                                                        className={
                                                            props.values.firstName === "" &&
                                                                props.errors.firstName &&
                                                                props.touched.firstName
                                                                ? "text-input validation-message"
                                                                : "text-input"
                                                        }
                                                    />}
                                                {props.values.firstName === "" && props.errors.firstName &&
                                                    props.touched.firstName && (
                                                        <Typography
                                                            className={
                                                                props.values.firstName === "" &&
                                                                    props.errors.firstName &&
                                                                    props.touched.firstName
                                                                    ? "validation-message"
                                                                    : "validation-message"
                                                            }>
                                                            {props.errors.firstName}
                                                        </Typography>
                                                    )}
                                            </FormControl>
                                        </Grid>

                                        <Grid item xs={12} md={12} sm={12}>
                                            <FormControl fullWidth>
                                                {loading ?
                                                    <Skeleton animation="wave" variant="rounded" width='auto' height={45} /> :
                                                    <TextField
                                                        // required
                                                        id="lastName"
                                                        label="Last Name"
                                                        fullWidth
                                                        size="small"
                                                        onChange={(e: any) => {
                                                            props.setFieldValue(
                                                                "lastName",
                                                                e.target.value !== null ? e.target.value : "",
                                                                true
                                                            );
                                                        }}
                                                        onBlur={props.handleBlur}
                                                        value={props.values.lastName}
                                                        inputProps={{ maxLength: 200 }}
                                                        variant="outlined"
                                                        error={
                                                            props.values.lastName === "" &&
                                                                props.errors.lastName &&
                                                                props.touched.lastName
                                                                ? true
                                                                : false
                                                        }
                                                        className={
                                                            props.values.lastName === "" &&
                                                                props.errors.lastName &&
                                                                props.touched.lastName
                                                                ? "text-input validation-message"
                                                                : "text-input"
                                                        }
                                                    />}
                                                {props.values.lastName === "" && props.errors.lastName &&
                                                    props.touched.lastName && (
                                                        <Typography
                                                            className={
                                                                props.values.lastName === "" &&
                                                                    props.errors.lastName &&
                                                                    props.touched.lastName
                                                                    ? "validation-message"
                                                                    : "validation-message"
                                                            }
                                                        >
                                                            {props.errors.lastName}
                                                        </Typography>
                                                    )}
                                            </FormControl>
                                        </Grid>

                                        <Grid item xs={12} md={12} sm={12}>
                                            <FormControl fullWidth>
                                                {loading ?
                                                    <Skeleton animation="wave" variant="rounded" width='auto' height={45} /> :
                                                    <TextField
                                                        // required
                                                        id="age"
                                                        type="number"
                                                        label="Age"
                                                        fullWidth
                                                        size="small"
                                                        onChange={(e: any) => {
                                                            props.setFieldValue(
                                                                "age",
                                                                e.target.value !== null ? e.target.value : "",
                                                                true
                                                            );
                                                        }}
                                                        onBlur={props.handleBlur}
                                                        value={props.values.age}
                                                        inputProps={{ maxLength: 200 }}
                                                        variant="outlined"
                                                        error={
                                                            props.values.age === "" &&
                                                                props.errors.age &&
                                                                props.touched.age
                                                                ? true
                                                                : false
                                                        }
                                                        className={
                                                            props.values.age === "" && props.errors.age &&
                                                                props.touched.age
                                                                ? "text-input validation-message"
                                                                : "text-input"
                                                        }
                                                    />}
                                                {props.values.age === "" && props.errors.age &&
                                                    props.touched.age && (
                                                        <Typography
                                                            className={
                                                                props.values.age === "" &&
                                                                    props.errors.age &&
                                                                    props.touched.age
                                                                    ? "validation-message"
                                                                    : "validation-message"
                                                            }
                                                        >
                                                            {props.errors.age}
                                                        </Typography>
                                                    )}
                                            </FormControl>
                                        </Grid>

                                        <Grid item xs={12} md={12} sm={12}>

                                            {props.values.familyRelationName === "2" && props.values.maternalMotherId > 0 && ((props.values.isChildBorn === true && props.values.isTermination === false) || (props.values.isChildBorn === false && props.values.isTermination === true)) ?
                                                <Box onClick={() => goToMaternalDetails(true, props.values.maternalMotherId)} className="right-header-main-box">
                                                    <IconButton
                                                        color="inherit"
                                                        className="icon-button"
                                                        disableRipple >
                                                        <AddOutlinedIcon color="primary" />
                                                    </IconButton>
                                                    <Typography className='edit-details-text' color='primary'>
                                                        Add maternity details
                                                    </Typography>
                                                </Box> : null}
                                            {props.values.familyRelationName === "2" && props.values.maternalMotherId === 0 && props.values.isTermination === false && props.values.isChildBorn === false ?
                                                <Box onClick={() => goToMaternalDetails(true, props.values.maternalMotherId)} className="right-header-main-box">
                                                    <IconButton
                                                        color="inherit"
                                                        className="icon-button"
                                                        disableRipple >
                                                        <AddOutlinedIcon color="primary" />
                                                    </IconButton>
                                                    <Typography className='edit-details-text' color='primary'>
                                                        Add maternity details
                                                    </Typography>
                                                </Box> : null}

                                            {props.values.familyRelationName === "2" && props.values.maternalMotherId > 0 && props.values.isChildBorn === false && props.values.isTermination === false ?
                                                <Box onClick={() => goToMaternalDetails(false, props.values.maternalMotherId)} className="right-header-main-box">
                                                    <IconButton
                                                        color="inherit"
                                                        className="icon-button"
                                                        disableRipple >
                                                        <AddOutlinedIcon color="primary" />
                                                    </IconButton>
                                                    <Typography className='edit-details-text' color='primary'>
                                                        Edit maternity details
                                                    </Typography>
                                                </Box> : null}
                                        </Grid>
                                        <Box className="bottom-box">
                                            <CustomButton
                                                disabled={false}
                                                class="add-button"
                                                isLoading={submitLoading}
                                                title='Save' />
                                        </Box>
                                    </Grid>
                                </Box>
                            </Form>
                        )
                    }}
                </Formik>

                <Dialog
                    maxWidth="md"
                    fullWidth={true}
                    open={isMaternityAddPopup}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    className="user-verify-dialog-box">
                    <DialogContent>
                        <DialogContentText>
                            Do you want to add maternity details?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <LoadingButton
                            variant="contained"
                            onClick={() => goToMaternalDetails(true, 0)}
                            className="">
                            Yes
                        </LoadingButton>
                        <Button
                            variant="outlined"
                            onClick={() => goToClosePopup(true)}
                            className="">
                            No
                        </Button>
                    </DialogActions>
                </Dialog>
                <Dialog
                    maxWidth="md"
                    fullWidth={true}
                    open={isChildAddPopup}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    className="user-verify-dialog-box">
                    <DialogContent>
                        <DialogContentText>
                            Do you want to add child details?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <LoadingButton
                            variant="contained"
                            onClick={() => gotoChild(true)}
                            className="">
                            Yes
                        </LoadingButton>
                        <Button
                            variant="outlined"
                            onClick={() => goToClosePopup(false)}
                            className="">
                            No
                        </Button>
                    </DialogActions>
                </Dialog>
            </Box>
        </Fade>
    );
}

const selectStyles = {
    control: (provided: any, state: any) => ({
        ...provided,
        border: state.isFocused ? "1px solid #0B7CAA" : "1px solid #d32f2f",
    }),
    placeholder: (provided, state) => ({
        ...provided,
        color: state.isFocused ? state.selectProps.color : "#d32f2f",
    }),
};