import { POST, GET } from "./common/http";
import { BASE_URL } from "./common/const";


export const GetLanguageListForTranslation = () =>
    GET(`${BASE_URL}/api/Auth/GetListForSelect`, null);

export const MobileRegistrartion = (data: any) =>
    POST(`${BASE_URL}/api/Auth/SendOtp`, data);

export const OTPVerification = (data: any) =>
    POST(`${BASE_URL}/api/Auth/VerifyOtp`, data);

export const GetIsUserExist = (data: any) =>
    POST(`${BASE_URL}/api/Auth/GetIsUserExist`, data);

export const UserRegistrationComplete = (params: any) =>
    POST(`${BASE_URL}/api/Auth/UserRegistrationComplete?` + params);

export const UpdateCurMemberLanguage = (data: any) =>
    POST(`${BASE_URL}/api/Auth/UpdateMemberLanguage`, data);