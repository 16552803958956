import i18n from 'i18next';
import i18nBackend from "i18next-http-backend";
import { initReactI18next } from 'react-i18next';
import { DEVELOPMENT_MODE, UI_APP_URL } from "../src/services/common/const";

const getCurrentHost = DEVELOPMENT_MODE === 'LIVE' ? UI_APP_URL : UI_APP_URL

i18n
    .use(i18nBackend)
    .use(initReactI18next)
    .init({
        fallbackLng: 'en',
        lng: 'en',
        interpolation: {
            escapeValue: false
        },
        backend: {
            loadPath: `${getCurrentHost}/i18n/{{lng}}.json`,
        }
    });

export default i18n;
